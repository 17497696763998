import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
import ExportModal from "./exportModal";
const ItemButtons = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  return (
    <Row>
      <Col className="iitem">
        <div>
          <h5>All Purchase Orders</h5>
        </div>
        <div className="itembtn">
          <Link to="/addpurchaseorder">
            <button className="newbtn">
              {" "}
              <i class="bx bx-plus"></i> New
            </button>
          </Link>
          <button>
            {" "}
            <i class="bx bxs-cog"></i>
          </button>
          {/* <button>
          
          </button> */}
          <Dropdown
            className="d-inline-block"
            isOpen={socialDrp}
            toggle={() => {
              setsocialDrp(!socialDrp);
            }}
          >
            <DropdownToggle
              className="btn header-item waves-effect ddtoggle"
              tag="button"
            >
              <button>
                {" "}
                <i class="bx bx-menu"></i>
              </button>
            </DropdownToggle>
            <DropdownMenu right>
              {/* <DropdownItem tag="a" href="#">
                {" "}
                <i className="bx bx-pencil font-size-16 align-middle mr-1"></i>
                {props.t("Edit Item")}{" "}
              </DropdownItem> */}
              <DropdownItem tag="a" href="">
                {" "}
                <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                {props.t("Import Sales Price List")}{" "}
              </DropdownItem>
              <DropdownItem tag="a" href="">
                {" "}
                <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                {props.t("Import Purchase Price List")}{" "}
              </DropdownItem>
              <hr />
              <DropdownItem tag="a" href="">
                {" "}
                <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                {props.t("Export Sales Price List")}{" "}
              </DropdownItem>
              <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                {" "}
                <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                {props.t("Export Purchase Price List")}{" "}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <Modal className="export-modal" isOpen={modalIsOpen}>
          <ExportModal isClose={() => setModalIsOpen(false)} />
        </Modal>
      </Col>
    </Row>
  );
};

export default withTranslation()(ItemButtons);
