import React, { useMemo, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";

import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
// import Table from "./Table";
import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import FilterComponent from "./FilterComponent";
import { downloadPdf } from "./downloadPdf";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function OnlineFormList() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [loadingData, setLoadingData] = useState(true);
  function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }

  function toPages(pages) {
    const results = [];

    for (let i = 1; i < pages; i++) {
      results.push(i);
    }

    return results;
  }

  const columns = [
    {
      name: "Order Date",
      selector: (data) => data.orderDate,
      sortable: true,
      // right: true,
    },
    {
      name: "Order Number",
      selector: (data) => data.orderNumber,
      sortable: true,
    },
    {
      name: "Email",
      selector: (data) => data.orderCustomerEmail,
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (data) => data.orderCustomerName,
      sortable: true,
    },
    // {
    //   name: "Warehouse Name",
    //   selector: (data) => data.orderWarehouseName,
    //   sortable: true,
    //   // right: true,
    // },
    // {
    //   name: "Phone Number",
    //   selector: (data) => data.orderCustomerPhone,
    //   sortable: true,
    //   // right: true,
    // },
    // {
    //   name: "Customer Email",
    //   selector: (data) => data.orderCustomerEmail,
    //   sortable: true,
    //   // right: true,
    // },
    {
      name: "Order Status",
      selector: (data) => (
        <div
          className={`${
            data.orderStatus == 0
              ? "orange"
              : data.orderStatus == 1
              ? "green"
              : data.orderStatus == 2
              ? "red"
              : data.orderStatus == 3
              ? "orange"
              : data.orderStatus == 4
              ? "green"
              : data.orderStatus == 5
              ? "red"
              : ""
          }`}

          // disabled={!data.startDate == new Date()}
        >
          {data.orderStatus == 0
            ? "New"
            : data.orderStatus == 1
            ? "Approved"
            : data.orderStatus == 2
            ? "Rejected"
            : data.orderStatus == 3
            ? "Customer Approval"
            : data.orderStatus == 4
            ? "Converted"
            : data.orderStatus == 5
            ? "Order Closed"
            : ""}
        </div>
      ),
      sortable: true,
      // right: true,
    },
    {
      name: "Order Customer Status",
      selector: (data) => (
        <div
          className={`${
            data.orderCustomerStatus == 0
              ? "orange"
              : data.orderCustomerStatus == 1
              ? "green"
              : data.orderCustomerStatus == 2
              ? "red"
              : data.orderCustomerStatus == 3
              ? "orange"
              : data.orderCustomerStatus == 5
              ? "red"
              : ""
          }`}

          // disabled={!data.startDate == new Date()}
        >
          {data.orderCustomerStatus == 0
            ? "New"
            : data.orderCustomerStatus == 1
            ? "Approved"
            : data.orderCustomerStatus == 2
            ? "Rejected"
            : data.orderCustomerStatus == 3
            ? "Waiting for approval"
            : data.orderCustomerStatus == 5
            ? "Order Closed"
            : ""}
        </div>
      ),
      sortable: true,
      // right: true,
    },
    {
      name: "Order Type",
      selector: (data) => (
        <>{data ? (data.orderType == 1 ? "Check-In" : "Check-Out") : ""}</>
      ),
      sortable: true,
      // right: true,
    },
    {
      button: true,
      cell: (data) => (
        <div
          className="tablebtn mr-2"
          // onClick={() => handleDownloadPdf(data.orderPdfUrl)}
        >
          <button
            onClick={() => handlePdf(data.orderId, data.orderNumber)}
            disabled={data.orderStatus !== 4 || isLoading}
          >
            Download PDF
          </button>
        </div>
      ),
    },
  ];

  const handlePdf = (orderId, orderNumber) => {
    setIsLoading(true);
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/online_order/order_pdf/${orderId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setIsLoading(false);
      console.log(resp);
      if (resp.response.data.status === "Success") {
        downloadPdf(resp.response.data.data, orderNumber);
      } else if (resp.response.data.status === "Failed") {
        toast.error(`${resp.response.data.data}`);
      } else {
        toast.error(
          `${resp.response.data.message} ${resp.response.data.status}`
        );
      }
    });
  };
  const getData = async (page) => {
    setLoading(true);
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/online_order/org?org_id=${organisationId}&page=${page}&size=${perPage}&sort=orderId,desc&searchText=${filterText}`;

    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setTotalRows(resp.response.data.data.totalElements);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    getData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/online_order/org?org_id=${organisationId}&page=${page}&size=${newPerPage}&sort=orderId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setPerPage(newPerPage);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData(1);
  }, [filterText]);
  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleRowClick = (row) => {
    history.push(`/vieworderform/${row.orderId}`);
  };
  return (
    <div className="App">
      <ToastContainer
        position="bottom-center"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="card">
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows
          subHeader
          subHeaderComponent={subHeaderComponent}
          subHeaderAlign="left"
          onRowClicked={handleRowClick}
          highlightOnHover
          pointerOnHover
        />
      </div>
    </div>
  );
}
export default OnlineFormList;
