import React, { useState, useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Button } from "reactstrap";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import {
  registerUser,
  apiError,
  registerUserFailed,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";
import Google from "../../assets/acc-images/google.svg";
import { BaseUrl } from "../../components/config/BaseUrl";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Register = (props) => {
  const history = useHistory();
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    pass: "",
    phone: "",
  });

  // handleValidSubmit
  function handleValidSubmit(e) {
    const Url = BaseUrl.apiUrl.baseUrl + `api/public/v1/signup`;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        password: data.pass,
        mobile: data.phone,
      }),
    };
    fetch(Url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        switch (data.code) {
          case 200:
            toast.success(data.data);
            setTimeout(() => {
              history.push("/login");
              // useHistory().push("/customerlist");
            }, 1000);
            break;

          default:
            toast.error(data.data);
        }
      });
    // let body = {
    //   firstname: data.firstname,
    //   lastname: data.lastname,
    //   email: data.email,
    //   password: data.pass,
    //   mobile: data.phone,
    // };
    // let resp = Axios.post(Url, body);
    // resp.then((resp) => {
    //   // switch (resp.response.data.code) {
    //   //   case 200:
    //   //     toast.success(resp.response.data.data);
    //   //     setTimeout(() => {
    //   //       history.push("/login");
    //   //       // useHistory().push("/customerlist");
    //   //     }, 1000);
    //   //     break;

    //   //   default:
    //   //     toast.error(resp.response.data.data);
    //   // }
    // });
  }

  useEffect(() => {
    props.registerUserFailed("");
  });

  function handle(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;

    setData(newData);
  }
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="container-fluid">
        <Row>
          <Col md="3 fp-left">
            <div className="fp-left-container">
              <Row>
                <Col>
                  {" "}
                  <h6 className=""> Accounting Application</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3 className="">
                    Accounting made <br />
                    Simple
                  </h3>
                </Col>
              </Row>
              <Row>
                {/* <Col>
                  Lorem ipsum dolor sit amet <br />
                  consectetur adipising elit set duo eius mod tempr
                </Col> */}
              </Row>
              <Row>
                <Col>
                  <p className="">Powered by Opine</p>
                </Col>
              </Row>
            </div>
          </Col>
          <div className="col-md-8 fp-right">
            <div className="formChange">
              Already have an account? <Link to="/login">Sign In</Link>
            </div>
            <Row className="fp-form">
              <Col>
                <h5>Register</h5>
                <h6>Tell us more about you to get started</h6>
                <p className="">
                  Please fill out the following form so that we know more about
                  you.
                </p>
                <AvForm onValidSubmit={(e) => handleValidSubmit(e)}>
                  <Row>
                    <Col>
                      <label htmlFor="username" className="">
                        First name
                      </label>
                      <AvField
                        name="firstname"
                        type="input"
                        onChange={(e) => handle(e)}
                        value={data.firstname}
                        required
                      ></AvField>
                    </Col>
                    <Col>
                      <label htmlFor="username" className="">
                        Last name
                      </label>
                      <AvField
                        name="lastname"
                        type="input"
                        value={data.lastname}
                        onChange={(e) => handle(e)}
                        required
                      ></AvField>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="">Email address</label>
                      <AvField
                        name="email"
                        type="email"
                        value={data.email}
                        onChange={(e) => handle(e)}
                        required
                      ></AvField>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="">Phone Number</label>
                      <AvField
                        name="phone"
                        type="tel"
                        value={data.phone}
                        onChange={(e) => handle(e)}
                        required
                      ></AvField>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="">Password</label>
                      <AvField
                        name="pass"
                        type="password"
                        value={data.pass}
                        onChange={(e) => handle(e)}
                        required
                      ></AvField>
                    </Col>
                  </Row>

                  {/* <a href="" className="fp-link">
                    Forgot your password ?
                  </a> */}
                  <Button type="submit" className="btn-full-main">
                    Register
                  </Button>
                </AvForm>
                <hr className="hr" />
                <Button className="btn-full-gray ">
                  <img src={Google} alt="" className="btn-icon-main" />
                  Continue using Google
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register);
