import React, { useState } from "react";
import { Row, Col } from "reactstrap";

import { Link } from "react-router-dom";
import EstimateTable from "./estimateTable";
import TermsAndConditions from "./termsAndConditions";
import { apiGet, apiPost } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import loader from "../../assets/loader/load.gif";
import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { parse } from "dotenv";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { IoIosAddCircle } from "react-icons/io";
import { AvField } from "availity-reactstrap-validation";

//i18n

const AddEstimateForm = (props) => {
  // const [itemRate, setItemRate] = useState("");
  const [rowsData, setRowsData] = useState([
    {
      itemDescription: "",
      itemQuantity: "",
      itemRate: "",
      itemDiscountType: 0,
      itemDiscountValue: "",
      itemTaxId: 0,
      itemName: "",
      itemUnit: "",
      itemAmount: 0,
    },
  ]);
  const [reload, setReload] = useState(false);
  const [subTotalAmount, setSubTotalAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [shipChrg, setShipChrg] = useState(0);
  const [adjus, setAdjus] = useState(0);
  const [custData, setCustData] = useState([]);
  const [formSuccess, setFormSuccess] = useState(false);
  const [itemData, setItemData] = useState([]);
  // const [salespersonData, setSalespersonData] = useState([]);
  // const [projectData, setProjectData] = useState([]);
  const [state, setState] = useState([]);
  // const [tcsList, setTcsData] = useState([]);
  // const [branchList, setBranchData] = useState([]);
  const [values, setValues] = useState({
    mode: "1",
    addretinvoice: false,
    custName: "",
    branch: "",
    pos: 0,
    estimate: "",
    reference: "",
    estDate: "",
    expDate: "",
    salesPerson: "",
    proName: "",
    subject: "",
    customernotes: "",
    terms: "",
    invoicePercentageToBeCollected: "",
    shippingCharge: 0,
    adjustment: 0,
    tcsTaxId: 0,
  });
  const [tcsRate, setTcsRate] = useState(null);
  const [tcsTax, setTcsTax] = useState(null);
  const [tcsAmount, setTcsAmount] = useState(0);

  function changeStatus(e) {
    //setTcs(e.target.value);
    //console.log("tcs:", tcs);
    var index = e.nativeEvent.target.selectedIndex;
    var id = e.nativeEvent.target[index].value;
    console.log("selected text0", e.nativeEvent.target[index].value);
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tcs/${id}`;
    let resp = apiGet(Url);
    var taxrate = 0;
    resp.then((resp) => {
      console.log("tcs data", resp.response.data.data);

      taxrate = resp.response.data.data.taxRate;
      setTcsRate(taxrate);
      setTcsTax(resp.response.data.data.taxName);
      const rowsInput = [...rowsData];

      const sum = rowsInput
        .map((item) => parseInt(item.itemAmount))
        .reduce((prev, curr) => prev + curr, 0);
      setSubTotalAmount(sum);
      const tax = (sum * (taxrate || 0)) / 100;
      setTcsAmount(tax.toFixed(2));
      setTotalAmount(
        parseInt(subTotalAmount ? subTotalAmount : 0) +
          parseInt(shipChrg ? shipChrg : 0) +
          parseInt(adjus ? adjus : 0) +
          parseFloat(tax ? tax.toFixed(2) : 0)
      );
    });
  }

  useEffect(() => {
    function getData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setCustData(resp.response.data.data);
      });
    }
    getData();
  }, []);

  useEffect(() => {
    function getData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/states/101`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setState(resp.response.data.data);
      });
    }
    getData();
  }, []);

  //to get item list
  useEffect(() => {
    function getItemData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setItemData(resp.response.data.data);
      });
    }
    getItemData();
  }, []);

  // useEffect(() => {
  //   function getSalesPersonData() {
  //     const organisationId = localStorage.getItem("organisationId");
  //     const Url = BaseUrl.apiUrl.baseUrl + `api/v1/users/role/SALESPERSON`;
  //     let resp = apiGet(Url);
  //     resp.then((resp) => {
  //       setSalespersonData(resp.response.data.data);
  //     });
  //   }
  //   getSalesPersonData();
  // }, []);

  // useEffect(() => {
  //   function getProjectData() {
  //     const organisationId = localStorage.getItem("organisationId");
  //     const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/project`;
  //     let resp = apiGet(Url);
  //     resp.then((resp) => {
  //       setProjectData(resp.response.data.data);
  //     });
  //   }
  //   getProjectData();
  // }, []);

  // useEffect(() => {
  //   function getTcsData() {
  //     const organisationId = localStorage.getItem("organisationId");
  //     const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tcs`;
  //     let resp = apiGet(Url);
  //     resp.then((resp) => {
  //       setTcsData(resp.response?.data?.data);
  //       console.log("prjd dta", resp.response?.data?.data);
  //     });
  //   }
  //   getTcsData();
  // }, []);

  // useEffect(() => {
  //   function getBranchList() {
  //     const organisationId = localStorage.getItem("organisationId");
  //     const Url =
  //       BaseUrl.apiUrl.baseUrl +
  //       `api/v1/settings/organization_branch/org/${organisationId}`;
  //     let resp = apiGet(Url);
  //     resp.then((resp) => {
  //       setBranchData(resp.response.data.data);
  //     });
  //   }
  //   getBranchList();
  // }, []);

  const addTableRows = () => {
    const rowsInput = {
      itemDescription: "",
      itemQuantity: "",
      itemRate: "",
      itemDiscountType: 0,
      itemDiscountValue: "",
      itemTaxId: 0,
      itemUnit: "",
      itemName: "",
      itemAmount: 0,
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    const sum = rows
      .map((item) => parseInt(item.estItemAmount))
      .reduce((prev, curr) => prev + curr, 0);
    setSubTotalAmount(sum);
    const tax = (sum * (tcsRate || 0)) / 100;
    setTcsAmount(tax.toFixed(2));
    setTotalAmount(
      parseInt(subTotalAmount) +
        parseInt(shipChrg) +
        parseInt(adjus) +
        parseFloat(tax.toFixed(2))
    );
  };

  const handleTableChange = (index, evnt) => {
    if (evnt.target) {
      const { name, value } = evnt.target;
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;

      if (rowsInput[index]["itemDiscountType"] == 0) {
        // Calculate item amount with percentage discount
        rowsInput[index]["itemAmount"] =
          rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

        rowsInput[index]["itemAmount"] =
          rowsInput[index]["itemAmount"] -
          ((rowsInput[index]["itemAmount"] || 0) *
            (rowsInput[index]["itemDiscountValue"] || 0)) /
            100;
      }
      if (rowsInput[index]["itemDiscountType"] == 1) {
        // Calculate item amount with fixed amount discount
        rowsInput[index]["itemAmount"] =
          rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

        rowsInput[index]["itemAmount"] =
          rowsInput[index]["itemAmount"] -
          (rowsInput[index]["itemDiscountValue"] || 0);
      }
      // Update the state with the modified data
      setRowsData(rowsInput);

      // Calculate subtotal amount by summing up item amounts
      const sum = rowsInput
        .map((item) => parseInt(item.itemAmount))
        .reduce((prev, curr) => prev + curr, 0);
      setSubTotalAmount(sum);

      // Calculate tax amount based on the subtotal and tax rate
      const tax = (sum * (tcsRate || 0)) / 100;
      setTcsAmount(tax.toFixed(2));

      // Calculate the total amount by adding up all components
      setTotalAmount(
        parseInt(subTotalAmount ? subTotalAmount : 0) +
          parseInt(shipChrg ? shipChrg : 0) +
          parseInt(adjus ? adjus : 0) +
          parseFloat(tcsAmount ? tcsAmount : 0)
      );

      if (name === "itemName") {
        // If the item ID is changed, update the item rate
        const selectedItem = itemData.find((item) => item.itemId === value);
        if (selectedItem) {
          rowsInput[index]["itemRate"] = selectedItem.itemSalesSellingPrice;
        }
      } else if (evnt.value) {
        // Update the item ID
        const rowsInput = [...rowsData];
        rowsInput[index]["itemName"] = evnt.value;
        setRowsData(rowsInput);
      }
    }
  };
  const onHandleBlur = (index, e) => {
    const rowsInput = [...rowsData];
    if (rowsInput[index]["itemDiscountType"] == 0) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        ((rowsInput[index]["itemAmount"] || 0) *
          (rowsInput[index]["itemDiscountValue"] || 0)) /
          100;
    }
    if (rowsInput[index]["itemDiscountType"] == 1) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        (rowsInput[index]["itemDiscountValue"] || 0);
    }
    setRowsData(rowsInput);
    setTotalAmount(
      parseInt(subTotalAmount) +
        parseInt(shipChrg) +
        parseInt(adjus) +
        parseFloat(tcsAmount ? tcsAmount : 0)
    );
  };
  const history = useHistory();
  const handleCancel = () => {
    setTimeout(() => {
      history.push("/estimates");
    }, 1000);
  };

  const custOptions = custData?.map((cust) => ({
    value: cust.customerId,
    label: cust.customerDisplayName,
  }));

  // custom add button in Select
  const CustomButton = ({ onClick }) => (
    <Link to="/customer/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Customer
      </button>
    </Link>
  );

  const DropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomButton onClick={console.log("clicked")} />
      </div>
    );
  };

  const customComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: DropdownMenu, // Use your custom dropdown menu
  };

  // item custom button
  const CustomItemButton = ({ onClick }) => (
    <Link to="/additems/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Item
      </button>
    </Link>
  );
  const ItemDropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomItemButton onClick={console.log("clicked")} />
      </div>
    );
  };
  const customItemComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: ItemDropdownMenu, // Use your custom dropdown menu
  };
  const reactSelectOptions = itemData?.map((items) => ({
    value: items.itemId,
    label: items.itemName,
  }));
  const handleSubmit = () => {
    setReload(true);
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/estimates";
    let body = {
      estimateCustomerId: values.custName,
      estimateSendEmail: 0,
      estimateEmailTo: "",
      estimateBillingAddressId: 0,
      estimateShippingAddressId: 0,
      estimateCustomerRemarks: "no",
      estimateGstTreatmentId: 0,
      estimateSourceOfSupplyId: values.pos,
      estimateReferenceNumber: values.reference,
      estimateDate: values.estDate,
      estimateExpiryDate: values.expDate,
      estimateSalesPersonId: 0,
      estimateProject: values.proName,
      estimateIsTaxPayAgreed: 0,
      estimateSubject: values.subject,
      estimateSubTotal: subTotalAmount,
      estimateShippigCharges: values.shippingCharge,
      estimateAdjustment: values.adjustment,
      estimateRoundOff: 0,
      estimateTcsTaxId: values.tcsTaxId,
      estimateTcsTaxPercent: tcsRate || "",
      estimateTcsTaxAmount: tcsAmount,
      estimateTcsTax: tcsTax || "",
      estimateTotal: totalAmount,
      estimateCustomerNotes: values.customernotes,
      estimateTermsAndConditions: values.terms,
      estimateCustomerName: "",
      estimateCustomerShippingAddress: "",
      estimateCustomerBillingAddress: "",
      estimateCustomerContactNumber: "",
      organizationId: orgId,
      branchId: values.branch,
      addedByUser: 1,
      editByUser: 1,
      estimateItemsList: rowsData,
    };
    console.log("body", body);
    let resp = apiPost(url, body);
    console.log("body", body);
    resp.then((resp) => {
      setReload(false);
      switch (resp.response.data.code) {
        case 200:
          setFormSuccess(true);
          toast.success(resp.response.data.data);
          setTimeout(() => {
            history.push("/estimates");
          }, 1000);
          break;
        default:
          toast.error(resp.response.data.data);
      }
    });
  };
  function handleChange(e) {
    const newData = { ...values };
    newData[e.target.name] = e.target.value;
    setValues(newData);
  }
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        handleChange
      />

      <form className="formik" id="addEstimateForm">
        <Row>
          <Col md={6}>
            <Row>
              <Col md={6}>
                <label> Customer Name</label>
                {/* </Col>
              <Col md={6}> */}
                {/* <AvField
                  className="formik-input"
                  as="select"
                  name="custName"
                  onChange={handleChange}
                  value={values.custName}
                  required
                  >
                  <option value="" selected disabled>
                  select a Customer
                  </option>
                  {custData.map((customer) => (
                    <option>
                    {customer.customerFirstname +
                      " " +
                      customer.customerLastname}
                    </option>
                    ))}
                  </AvField> */}

                {/*<div>
                  <i className="bx bx-search"></i>
                </div>*/}
                <Select
                  options={custOptions}
                  components={customComponents}
                  name="custName"
                  value={
                    custOptions
                      ? custOptions.find(
                          (option) => option.value === values.custName
                        )
                      : ""
                  }
                  onChange={(option) => {
                    setValues({ ...values, custName: option.value });
                    // if (option.value == "add-customer") {
                    //   console.log(true);
                    //   history.push("/customer/add");
                    //   // setPushToCustomer(true);
                    // }
                  }}
                  // components={customComponents}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* {null != values.custName ? (
          <Row>
            <Col md={6}>
              <label className="formik-label"> Place of Supply</label>
              </Col>
            <Col md={10} className="formik-input-search"> 
              <AvField
                className="formik-input"
                as="select"
                name="pos"
                onChange={handleChange}
                value={values.pos}
                required
              >
                <option value=""> Select place of supply</option>
                {state?.map((st) => (
                  <option value={st.stateId}>{st.stateName}</option>
                ))}
              </AvField>
            </Col>
          </Row>
        ) : (
          ""
        )} */}
        {/* <Row>
              <Col md={2}>
                <label className="formik-label"> Branch</label>
              </Col>
              <Col md={10}>
                <AvField
                  className="formik-input"
                  as="select"
                  name="branch"
                  onChange={handleChange}
                  value={values.branch}
                >
                  <option value=""> Select Branch</option>
                  {branchList?.map((br) => (
                    <option value={br.branchId}>{br.branchName}</option>
                  ))}
                </AvField>
                <div>
                </div>
              </Col>
            </Row> */}
        {/*<Row>
              <Col md={2}>
                <label className="formik-label"> Estimate#</label>
              </Col>
              <Col md={10} className="formik-input-search">
                <AvField
                  className="formik-input"
                  type="text"
                  name="estimate"
                  onChange={handleChange}
                  value={values.estimate}
                  required
                ></AvField>
             
              </Col>
            </Row>*/}
        <Row>
          <Col md={3}>
            <label className="formik-label"> Reference#</label>
            {/* </Col>
            <Col md={10}> */}
            <AvField
              // className="formik-input"
              type="number"
              name="reference"
              onChange={handleChange}
              value={values.reference}
            ></AvField>
          </Col>

          {/* </Row>
        <Row> */}

          <Col md={4}>
            <label className="formik-label"> Estimate Date</label>
            {/* </Col>
              <Col md={8}> */}
            <AvField
              className="formik-input formik-inputrow"
              type="date"
              name="estDate"
              onChange={handleChange}
              value={values.estDate}
              required
            ></AvField>
            <div>
              {/*<span>
                        To create transaction dated before 01/07/2017,{" "}
                        <Link>click here</Link>
              </span>*/}
            </div>
          </Col>

          <Col md={4}>
            <label className="formik-label"> Expiry Date</label>
            {/* </Col>
              <Col md={8}> */}
            <AvField
              className="formik-input formik-inputrow"
              type="date"
              name="expDate"
              onChange={handleChange}
              value={values.expDate}
              min={values.estDate}
            ></AvField>
          </Col>
        </Row>
        {/* <hr /> */}
        {/* <Row>
              <Col md={2}>
                <label className="formik-label"> Salesperson</label>
              </Col>
              <Col md={10}>
                <AvField
                  className="formik-input"
                  as="select"
                  name="salesPerson"
                  onChange={handleChange}
                  value={values.salesPerson}
                >
                  <option value="">Select Salesperson</option>
                  {salespersonData?.map((sp) => (
                    <option value={sp.id}>
                      {sp.firstname + " " + sp.lastname}
                    </option>
                  ))}
                  
                </AvField>
              </Col>
            </Row> */}
        {/* <Row>
              <Col md={2}>
                <label className="formik-label"> Project Name</label>
              </Col>
              <Col md={10}>
                <AvField
                  className="formik-input"
                  as="select"
                  name="proName"
                  onChange={handleChange}
                  value={values.proName}
                >
                  <option value="" selected disabled>
                    Select a project
                  </option>
                  {projectData?.map((pr) => (
                    <option value={pr.id}>{pr.project}</option>
                  ))}
                </AvField>
                <div>
                  {!values.custName ? (
                    <span>Select a customer to associate a project.</span>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row> */}

        <hr />

        <Row>
          <Col md={12}>
            <label className="formik-label"> Subject</label>
            {/* </Col>
          <Col md={10}> */}
            <AvField
              className="formik-textarea"
              as="textarea"
              name="subject"
              onChange={handleChange}
              value={values.subject}
            ></AvField>
          </Col>
        </Row>
        {/* <hr /> */}
        <div className="row clearfix">
          <div className="col-md-12 column">
            <table
              className="table table-bordered invoice-table"
              id="tab_logic"
            >
              <thead>
                <tr>
                  <th className="text-center"> # </th>
                  <th className="text-left"> ITEM DETAILS</th>
                  <th className="text-right"> QUANTITY</th>
                  <th className="text-right"> RATE</th>
                  <th className="text-right"> DISCOUNT </th>
                  <th className="text-right"> ITEM UNIT</th>
                  <th className="text-right"> AMOUNT</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {rowsData?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <input
                          name="itemName"
                          placeholder="Item Name"
                          value={data.itemName}
                          className="form-control"
                          onChange={(evnt) => handleTableChange(index, evnt)}
                        >
                          {/* <option selected>Select Item</option>
                              {itemData.map((items, index) => (
                                <option value={items.itemId}>
                                  {items.itemName}
                                </option>
                              ))} */}
                        </input>
                        {/* <Select
                              required
                              name="invItemId"
                              components={customItemComponents}
                              onChange={(selectedOption) => {
                                // Extract the selected item's ID from the selectedOption
                                const selectedItemId = selectedOption.value;
                                const rowsInput = [...rowsData];
                                rowsInput[index]["itemId"] = selectedItemId;
                                setRowsData(rowsInput);
                              }}
                              value={reactSelectOptions.find(
                                (option) =>
                                  option.value ==
                                  (rowsData[index]
                                    ? rowsData[index]["itemId"]
                                    : "")
                              )}
                              options={reactSelectOptions}
                            /> */}
                      </td>
                      <td>
                        <input
                          type="text"
                          name="itemQuantity"
                          className="form-control"
                          placeholder="1.00"
                          value={data?.itemQuantity}
                          onChange={(evnt) => handleTableChange(index, evnt)}
                          onBlur={(e) => onHandleBlur(index, e)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="itemRate"
                          className="form-control"
                          placeholder="0.00"
                          value={data?.itemRate}
                          onChange={(evnt) => handleTableChange(index, evnt)}
                          onBlur={(e) => onHandleBlur(index, e)}
                        />
                      </td>
                      <td width="10%">
                        <div className="table-inputs d-flex">
                          {/* <div> */}{" "}
                          <input
                            type="text"
                            name="itemDiscountValue"
                            className="form-control"
                            placeholder="0"
                            value={data?.itemDiscountValue}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                          />
                          {/* </div>
                          <div> */}
                          <AvField
                            name="itemDiscountType"
                            id="perc"
                            style={{ width: "5em" }}
                            type="select"
                            value={data?.itemDiscountType}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                          >
                            <option value="0">%</option>
                            <option value="1">Rs.</option>
                          </AvField>
                          {/* </div> */}
                        </div>
                      </td>
                      <td>
                        <AvField
                          name="itemUnit"
                          type="select"
                          id="tax"
                          // disabled
                          value={data?.itemUnit}
                          onChange={(evnt) => handleTableChange(index, evnt)}
                        >
                          <option value="" disabled selected>
                            Select a Unit
                          </option>
                          <option value="CBM">CBM</option>
                          <option value="SQM">SQM</option>
                        </AvField>
                      </td>
                      <td width="15%">
                        <input
                          type="text"
                          disabled
                          name="itemAmount"
                          className="form-control"
                          value={data?.itemAmount}
                          onChange={(evnt) => handleTableChange(index, evnt)}
                        />
                      </td>
                      <td className="text-center">
                        <span
                          className="btn btn-outline-danger"
                          onClick={(e) => deleteTableRows(index, e)}
                        >
                          x
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Row>
              <Col md={6} className="table-left">
                <div>
                  <div
                    onClick={addTableRows}
                    className=" col-md-4 btn btn-default pull-left add-tablerow"
                  >
                    <i class="bx bxs-plus-circle"></i>
                    <span>Add Item</span>
                  </div>
                  {/* <button
                      onClick={this.handleRemoveRow}
                      className="pull-right btn btn-default table-btn"
                    >
                      Clear all rows
                    </button> */}
                </div>
                <div className="customernotes">
                  <span>Customer Notes</span>
                  <div>
                    <AvField
                      name="customernotes"
                      // rows="4"
                      // cols="40"
                      type="textarea"
                      placeholder="Looking forward for your business"
                      onChange={handleChange}
                      value={values.customernotes}
                    ></AvField>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="table-right">
                  <Row>
                    <Col className="details">
                      <div>
                        <span className="detail-head">Sub Total</span>
                      </div>
                      <div>
                        <span>{subTotalAmount}</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <div>
                        <span className="detail-head">Shipping Charges</span>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className="input-tooltip">
                        <AvField
                          type="text"
                          name="shippingCharge"
                          onChange={handleChange}
                          onBlur={(e) => {
                            // handleBlur(e);
                            setShipChrg(values.shippingCharge);
                            let sum =
                              parseInt(subTotalAmount) +
                              parseInt(values.shippingCharge) +
                              parseInt(values.adjustment) +
                              parseFloat(tcsAmount ? tcsAmount : 0);
                            setTotalAmount(sum);
                          }}
                          value={values.shippingCharge}
                        />
                        <i class="bx bx-help-circle"></i>
                      </div>
                    </Col>
                    <Col md={3} className="text-right">
                      <div>
                        <span>{values.shippingCharge}</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <div>
                        <span className="detail-head">Adjustment</span>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className="input-tooltip">
                        <AvField
                          type="text"
                          name="adjustment"
                          onChange={handleChange}
                          value={values.adjustment}
                          onBlur={(e) => {
                            // handleBlur(e);
                            setAdjus(values.adjustment);
                            let sum =
                              parseInt(subTotalAmount) +
                              parseInt(values.shippingCharge) +
                              parseInt(values.adjustment) +
                              parseFloat(tcsAmount ? tcsAmount : 0);
                            setTotalAmount(sum);
                          }}
                        />
                        <i class="bx bx-help-circle"></i>
                      </div>
                    </Col>
                    <Col md={3} className="text-right">
                      <div>
                        <span>{values.adjustment}</span>
                      </div>
                    </Col>
                  </Row>
                  {/* <Row>
                        <Col md={4}>
                          <div>
                            <span className="detail-head">Tcs</span>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="input-tooltip">
                            <select
                              name="tcsTaxId"
                              placeholder="select a
                              tax"
                              onChange={(e) => {
                                changeStatus(e);
                                handleChange(e);
                              }}
                              value={values.tcsTaxId}
                            >
                              <option value="" selected>
                                Select a Tax
                              </option>
                              {tcsList?.map((tcs) => (
                                <option value={tcs.id} key={tcs.id}>
                                  {tcs.taxName} - {tcs.taxRate} %
                                </option>
                              ))}
                            </select>
                            <i class="bx bx-help-circle"></i>
                          </div>
                        </Col>
                        <Col md={3} className="text-right">
                          <div>
                            <span>{tcsAmount}</span>
                          </div>
                        </Col>
                      </Row> */}
                  <Row>
                    <Col className="details">
                      <div>
                        <span className="total">Total</span>
                      </div>

                      <div>
                        <span className="total">{totalAmount}</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <hr />
        <Row>
          <Col md={12}>
            <div className="row clearfix">
              <div className="col-md-8 termsandcondition">
                <span>Terms & Conditions</span>
                <div className="mt-2">
                  <AvField
                    className="formik-textarea terms-textarea"
                    as="textarea"
                    name="terms"
                    onChange={handleChange}
                    value={values.terms}
                  ></AvField>
                </div>
              </div>
              {/* <div className="col-md-4 attachfiles">
                <span>Attach File(s) to Estimate</span>
                <div className="attach">
                  <AvField name="import" type="file"></AvField>
                </div>
                <div>
                  <span style={{ fontSize: "10px" }}>
                    You can upload a maximum of 5 files, 5MB each
                  </span>
                </div>
              </div> */}
            </div>
            {/* <hr /> */}
            {/* <label className="retinvoice">
              <AvField
                type="checkbox"
                name="addretinvoice"
                label="Create a retainer invoice for this estimate automatically"
              />
               <span className="ml-2">
                Create a retainer invoice for this estimate automatically{" "}
              </span> 
            </label> */}
            {/* {values.addretinvoice == true ? (
              <>
                <div className="mt-3">
                  <span>Percentage to be collected</span>
                  <div className="mt-2">
                    <AvField
                      className="formik-input"
                      type="text"
                      name="invoicePercentageToBeCollected"
                      onChange={handleChange}
                      value={values.invoicePercentageToBeCollected}
                    ></AvField>
                  </div>
                </div>
                <div className="mt-3">
                  <span>Want to get paid faster?</span>
                  <div className="mt-2">
                    Configure payment gateways and receive payments online. Set
                    up Payment Gateway
                  </div>
                </div>
              </>
            ) : (
              ""
            )} */}
          </Col>
        </Row>
        {/* <Row>
              <Col md={12}>
                <span>Attach File(s) to inventory adjustment</span>
              </Col>
              <Col md={4}>
                <div className="attach">
                  <AvField name="import" type="file"></AvField>
                </div>
                <div>
                  <span style={{ fontSize: "10px" }}>
                    You can upload a maximum of 5 files, 5MB each
                  </span>
                </div>
              </Col>
            </Row> */}
      </form>
      <div className="bottom-bar">
        <Row>
          <Col md={4}>
            <Row>
              <Col md={4}>
                <button className="btn btn-left ml-3" onClick={handleCancel}>
                  Cancel
                </button>
              </Col>
              <Col md={4}>
                <button
                  type="reset"
                  className="btn btn-left"
                  form="estimateSubmit"
                >
                  Clear
                </button>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="printView">
            <span className="print">Make Recurring | Print or Preview</span>
          </Col>
          <Col md={4}>
            <button
              form="estimateSubmit"
              className="btn btn-right float-right mr-3"
              disabled={formSuccess}
              onClick={handleSubmit}
            >
              {reload ? (
                <img className="loader" src={loader} alt="loading..." />
              ) : (
                "Save"
              )}
            </button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default AddEstimateForm;
