import React from "react";
import { render } from "react-dom";
import { Row, Col } from "reactstrap";
import { AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
class AddTable extends React.Component {
  state = {
    rows: [],
  };
  handleChange = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    rows[idx] = {
      [name]: value,
    };
    this.setState({
      rows,
    });
  };
  handleAddRow = () => {
    const item = {
      pDetails: "",
      quantity: "",
      rate: "",
      discount: "",
      amount: "",
    };
    this.setState({
      rows: [...this.state.rows, item],
    });
  };
  handleRemoveRow = () => {
    this.setState({
      rows: this.state.rows.slice(0, 0),
    });
  };

  render() {
    return (
      <div>
        <div className="container">
          <div className="row clearfix">
            <div className="col-md-12 column">
              <table
                className="table table-bordered invoice-table"
                id="tab_logic"
              >
                <thead>
                  <tr>
                    <th className="text-center"> # </th>
                    <th className="text-left"> PRODUCT DETAILS</th>
                    <th className="text-right"> QUANTITY </th>
                    <th className="text-right"> RATE</th>
                    <th className="text-right"> DISCOUNT </th>
                    <th className="text-right"> AMOUNT </th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.rows.map((item, idx) => (
                    <tr id="addr0" key={idx}>
                      <td>{idx}</td>
                      <td>
                        <input
                          type="text"
                          name="pdetails"
                          value={this.state.rows[idx].pDetails}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="quantity"
                          value={this.state.rows[idx].quantity}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="rate"
                          value={this.state.rows[idx].rate}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="discount"
                          value={this.state.rows[idx].discount}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="amount"
                          value={this.state.rows[idx].amount}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <i class="far fa-trash-alt"></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Row>
                <Col md={6}>
                  <div>
                    <button
                      onClick={this.handleAddRow}
                      className="btn btn-default pull-left table-btn"
                    >
                      Add Row
                    </button>
                    <button
                      onClick={this.handleRemoveRow}
                      className="pull-right btn btn-default table-btn"
                    >
                      Clear all rows
                    </button>
                  </div>
                  <div className="attach">
                    <label>Attach files to invoice</label>
                    <AvField
                      name="email"
                      type="file"
                      placeholder="Enter first name"
                    ></AvField>
                  </div>
                </Col>
                <Col md={6} className="inv-details text-right">
                  <Row className="mb-3">
                    <Col md={7}>Subtotal</Col>
                    <Col md={5} className="">
                      Rs.2200
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={7}>Adjustments</Col>
                    <Col md={5}>
                      <input type="text" className="adj" />
                    </Col>
                  </Row>
                  <Row className="totals">
                    <Col md={7}>
                      <span className="total">Total</span>{" "}
                    </Col>
                    <Col md={5} className="">
                      <span className="tdesc"> Rs.2200</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AddTable;
