// @flow
import {
  all,
  call,
  fork,
  takeEvery,
  put,
  takeLatest,
} from "redux-saga/effects";

import {
  ADD_COMMODITY_TABLE_DATA,
  REQUEST_CHECKOUT_DATA_BY_NUMBER,
  SET_CHECKOUT_TABLE_DATA,
  REQUEST_WAREHOUSE_BY_ID,
  SET_WAREHOUSE_DATA,
  ADD_COMMODITY_TABLE_OTHER_CHARGES,
  SET_COMMODITY_TABLE_DATA,
  SET_COMMODITY_TABLE_OTHER_CHARGES,
} from "./actionTypes";
import {
  setCommodityTableData,
  setCommodityTableOtherCharges,
  setInvalidCheckInMessage,
} from "./actions";
import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";

/**
 * Show the rightsidebar
 */

function* setCommodityTableItem({ payload: data }) {
  try {
    yield put(setCommodityTableData(data.target.defaultValue));
  } catch (e) {
    console.log("error", e);
  }
}
function* getCheckoutItem({ payload: data }) {
  try {
    let savedCheckoutItem = {};
    let invalidNumber = "";
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/cargo_checkin_items/${data}`;
    let resp = apiGet(Url);
    yield resp.then((resp) => {
      if (resp.response.data.status === "Success") {
        console.log("success");
        savedCheckoutItem = resp.response.data.data;
      }
      if (resp.response.data.status === "Failed") {
        console.log("Failed");
        invalidNumber = resp.response.data.data;
      }
    });
    yield put(setInvalidCheckInMessage(invalidNumber));
    yield put({
      type: "SET_CHECKOUT_TABLE_DATA",
      payload: savedCheckoutItem,
    });
  } catch (error) {}
}
function* getWarehouseData({ payload: data }) {
  try {
    let savedWarehouseData = {};
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/warehouse/${data}`;
    let resp = apiGet(Url);
    yield resp.then((resp) => {
      savedWarehouseData = resp.response.data.data;
    });
    yield put({
      type: "SET_WAREHOUSE_DATA",
      payload: savedWarehouseData,
    });
  } catch (error) {}
}
function* setCommodityTableOtherChargeItem({ payload: data }) {
  yield put(setCommodityTableOtherCharges(data.target.defaultValue));
}
/**
 * Watchers
 */

export function* watchsetCommodityTableItem() {
  yield takeLatest(ADD_COMMODITY_TABLE_DATA, setCommodityTableItem);
}
export function* watchgetCheckoutItem() {
  yield takeEvery(REQUEST_CHECKOUT_DATA_BY_NUMBER, getCheckoutItem);
}
export function* watchgetWarehouseData() {
  yield takeEvery(REQUEST_WAREHOUSE_BY_ID, getWarehouseData);
}
export function* watchsetCommodityTableOtherChargeItem() {
  yield takeEvery(
    ADD_COMMODITY_TABLE_OTHER_CHARGES,
    setCommodityTableOtherChargeItem
  );
}
function* CommodityTableSaga() {
  yield all([
    fork(watchsetCommodityTableItem),
    fork(watchgetCheckoutItem),
    fork(watchgetWarehouseData),
    fork(watchsetCommodityTableOtherChargeItem),
  ]);
}

export default CommodityTableSaga;
