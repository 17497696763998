import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import classnames from "classnames";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//i18n
import { withTranslation } from "react-i18next";
import Switch from "react-switch";

const UpdatePriceList = (props) => {
  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  return (
    <>
      <Row>
        <Col md={6}>
          <h5>Update Custom Rates in Bulk</h5>
          {checked ? (
            <p>
              Export your items in an XLS file, add the custom rate for each
              item and import the same file in CSV or XLS format.
            </p>
          ) : (
            <p>Add custom rates for each item to be saved as a price list.</p>
          )}
        </Col>
        <Col md={6} className="switch">
          <span>Import Price List for Items</span>
          <Switch
            onChange={handleChange}
            checked={checked}
            className="react-switch"
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={40}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {checked ? (
            <div className="pricecustomimport">
              <p>Note :</p>
              <ul>
                <li>
                  In the import file, there are two fields:{" "}
                  <span>Item Name</span> and
                  <span> PriceList Rate</span> whose column names should be
                  specifically in English.
                </li>
              </ul>
              <Row>
                <Col className="pricebtns">
                  <div className="btn-link">
                    <span className="cursor-pointer">
                      <i className="bx bx-export"></i>
                      <span>Export Items</span>
                    </span>
                  </div>
                  <div className="btn-link">
                    <span className="cursor-pointer">
                      <i className="bx bx-import"></i>
                      <span>Import Custom Rates</span>
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          ) : props.pricing == 2 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th style={{ width: "60%" }}>
                    Item Details{" "}
                    {/* <span>
              <i className="bx bx-search"></i>
            </span> */}
                  </th>
                  <th>Sales Rate (INR)</th>
                  <th>Start Quantity</th>
                  <th>End Quantity</th>
                  <th>Custome Rate (INR)</th>
                  <th>Discount (%)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Tax clone</td>
                  <td>₹1,000.00 </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>RAM</td>
                  <td>₹3,000.00 </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Goods</td>
                  <td>₹66,000.00 </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th style={{ width: "50%" }}>Item Details </th>
                  <th>Sales Rate (INR)</th>
                  <th>Custome Rate (INR)</th>
                  <th>Discount (%)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Tax clone</td>
                  <td>₹1,000.00 </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>RAM</td>
                  <td>₹3,000.00 </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Goods</td>
                  <td>₹66,000.00 </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </>
  );
};

export default withTranslation()(UpdatePriceList);
