import React from "react";
import { render } from "react-dom";
import { Row, Col } from "reactstrap";
import { AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
class ContactPersons extends React.Component {
  state = {
    rows: [0],
  };
  handleChange = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    rows[idx] = {
      [name]: value,
    };
    this.setState({
      rows,
    });
  };
  handleAddRow = () => {
    const item = {
      salutation: "",
      fname: "",
      lname: "",
      email: "",
      wphone: "",
      mobile: "",
    };
    this.setState({
      rows: [...this.state.rows, item],
    });
  };
  handleRemoveRow = () => {
    this.setState({
      rows: this.state.rows.slice(0, 0),
    });
  };

  render() {
    return (
      <div>
        <div className="row clearfix">
          <div className="col-md-12 column">
            <table
              className="table table-bordered invoice-table"
              id="tab_logic"
            >
              <thead>
                <tr>
                  <th className="text-left"> Salutation </th>
                  <th className="text-left"> First Name</th>
                  <th className="text-left"> Last Name</th>
                  <th className="text-right"> Email Address</th>
                  <th className="text-right"> Work Phone</th>
                  <th className="text-right"> Mobile </th>
                </tr>
              </thead>
              <tbody>
                {this.state.rows.map((item, idx) => (
                  <tr id="addr0" key={idx}>
                    <td>
                      <select
                        name="salutation"
                        id="tax"
                        value={this.state.rows[idx].salutation}
                        onChange={this.handleChange(idx)}
                      >
                        <option value="%" selected>
                          Select
                        </option>
                        <option value="Mr">Mr.</option>
                        <option value="Mrs">Mrs.</option>
                        <option value="Ms">Ms.</option>
                        <option value="Miss">Miss.</option>
                        <option value="Dr">Dr.</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="fname"
                        value={this.state.rows[idx].fname}
                        onChange={this.handleChange(idx)}
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="lname"
                        value={this.state.rows[idx].lname}
                        onChange={this.handleChange(idx)}
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="email"
                        value={this.state.rows[idx].email}
                        onChange={this.handleChange(idx)}
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="wphone"
                        value={this.state.rows[idx].wphone}
                        onChange={this.handleChange(idx)}
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="mobile"
                        value={this.state.rows[idx].mobile}
                        onChange={this.handleChange(idx)}
                        className="form-control"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Row>
              <Col md={6} className="table-left">
                <div>
                  <button
                    onClick={this.handleAddRow}
                    className="btn btn-default pull-left add-tablerow"
                  >
                    <i class="bx bxs-plus-circle"></i>
                    <span>Add Contact Person</span>
                  </button>
                  {/* <button
                      onClick={this.handleRemoveRow}
                      className="pull-right btn btn-default table-btn"
                    >
                      Clear all rows
                    </button> */}
                </div>
                {/* <div className="customernotes">
                    <span>Customer Notes</span>
                    <div>
                      <textarea
                        name="customernotes"
                        rows="4"
                        cols="50"
                        placeholder="Looking forward for your business"
                      ></textarea>
                    </div>
                  </div> */}
              </Col>
              {/* <Col md={6}>
                  <div className="table-right">
                    <Row>
                      <Col className="details">
                        <div>
                          <span className="detail-head">Sub Total</span>
                        </div>
                        <div>
                          <span>0.00</span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <div>
                          <span className="detail-head">Shipping Charges</span>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="input-tooltip">
                          <input type="text" />
                          <i class="bx bx-help-circle"></i>
                        </div>
                      </Col>
                      <Col md={3} className="text-right">
                        <div>
                          <span>0.00</span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <div>
                          <span className="detail-head">Adjustment</span>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="input-tooltip">
                          <input type="text" />
                          <i class="bx bx-help-circle"></i>
                        </div>
                      </Col>
                      <Col md={3} className="text-right">
                        <div>
                          <span>0.00</span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <div>
                          <span className="detail-head">Tcs</span>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="input-tooltip">
                          <select name="selecttax" placeholder="select a tax">
                            <option value="%">Select a Tax</option>
                            <option value="rs">Rs.</option>
                          </select>
                          <i class="bx bx-help-circle"></i>
                        </div>
                      </Col>
                      <Col md={3} className="text-right">
                        <div>
                          <span>0.00</span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="details">
                        <div>
                          <span className="total">Total</span>
                        </div>

                        <div>
                          <span className="total">0.00</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col> */}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactPersons;
