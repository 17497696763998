export const FETCH_DASHBOARD_DATA = "FETCH_DASHBOARD_DATA";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILURE = "FETCH_DASHBOARD_FAILURE";
export const FETCH_DASHBOARD_REVENUE_GRAPH = "FETCH_DASHBOARD_REVENUE_GRAPH";
export const FETCH_DASHBOARD_REVENUE_GRAPH_SUCCESS =
  "FETCH_DASHBOARD_REVENUE_GRAPH_SUCCESS";
export const FETCH_DASHBOARD_REVENUE_GRAPH_FAILURE =
  "FETCH_DASHBOARD_REVENUE_GRAPH_FAILURE";
export const FETCH_DASHBOARD_RECENT_CHECKIN = "FETCH_DASHBOARD_RECENT_CHECKIN";
export const FETCH_DASHBOARD_RECENT_CHECKOUT =
  "FETCH_DASHBOARD_RECENT_CHECKOUT";
export const FETCH_DASHBOARD_CHECKIN_CLOSE = "FETCH_DASHBOARD_CHECKIN_CLOSE";
export const FETCH_DASHBOARD_CHECKIN_CLOSE_SUCCESS =
  "FETCH_DASHBOARD_CHECKIN_CLOSE_SUCCESS";
export const FETCH_DASHBOARD_CHECKIN_CLOSE_FAILURE =
  "FETCH_DASHBOARD_CHECKIN_CLOSE_FAILURE";
export const FETCH_DASHBOARD_CHECKOUT_CLOSE = "FETCH_DASHBOARD_CHECKOUT_CLOSE";
export const FETCH_DASHBOARD_CHECKOUT_CLOSE_SUCCESS =
  "FETCH_DASHBOARD_CHECKOUT_CLOSE_SUCCESS";
export const FETCH_DASHBOARD_CHECKOUT_CLOSE_FAILURE =
  "FETCH_DASHBOARD_CHECKOUT_CLOSE_FAILURE";
export const FETCH_DASHBOARD_SPACE_GRAPH = "FETCH_DASHBOARD_SPACE_GRAPH";
export const FETCH_DASHBOARD_SPACE_SUCCESS = "FETCH_DASHBOARD_SPACE_SUCCESS";
export const FETCH_DASHBOARD_SPACE_FAILURE = "FETCH_DASHBOARD_SPACE_FAILURE";
