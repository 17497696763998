import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import Select from "react-select";
import { connect } from "react-redux";
import loader from "../../assets/loader/load.gif";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet, apiPost, apiPut } from "../../components/config/apiConfig";

//import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomCheckbox from "../../components/Features/customcheckbox";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { requestCheckoutDataByNumber } from "../../store/actions";
const AddPutAway = (props) => {
  var formType = props.match.params.type;
  var id = props.match.params.id;
  const form = useRef(null);
  const [uncheck, setuncheck] = useState([]);
  const history = useHistory();
  const [isrentable, setisrentable] = useState(false);
  const [requireFacility, setRequireFacility] = useState(false);
  const [binLocationList, setBinLocationList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [checkInList, setCheckInList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const { checkbox, setcheckboxdata, checkboxdata, facilities } =
    CustomCheckbox({ setRequireFacility });
  const [reload, setReload] = useState(false);
  const [warehousedata, setwarehousedata] = useState({
    id: 0,
    itemId: 0,
    binLocationId: 0,
    putawayQuantity: "",
    putawayUnits: 0,
    warehouseId: 0,
    checkInId: 0,
  });

  function getBinLocationList() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/bin_location`;
    let resp = apiGet(Url);
    console.log("aisle list", resp);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setBinLocationList(resp.response.data.data);
      }
    });
  }
  function getWarehouseList() {
    const organisationId = localStorage.getItem("organisationId");

    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/warehouse/org/${organisationId}`;
    let resp = apiGet(Url);
    console.log("warehouse list", resp);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setWarehouseList(resp.response.data.data);
      }
    });
  }
  function getCheckInList() {
    const organisationId = localStorage.getItem("organisationId");

    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/cargo_checkin/org/${organisationId}`;
    let resp = apiGet(Url);
    console.log("checkin list", resp);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setCheckInList(resp.response.data.data);
      }
    });
  }
  // function getItemList() {
  //   const organisationId = localStorage.getItem("organisationId");

  //   const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/org/${organisationId}`;
  //   let resp = apiGet(Url);
  //   console.log("item list", resp);
  //   resp.then((resp) => {
  //     if (resp.response.data.code == 200) {
  //       setItemList(resp.response.data.data);
  //     }
  //   });
  // }

  function isRentable() {
    setisrentable(!isrentable);
  }

  const handle = (e) => {
    setwarehousedata({
      ...warehousedata,
      [e.target.name]: e.target.value,
    });
  };

  function createPutAway() {
    setReload(true);
    let orgId = localStorage.getItem("organisationId");
    let warehouseId = localStorage.getItem("warehouseId");
    console.log("orgid", orgId);
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/put_away";
    let body = {
      warehouseId: warehouseId,
      checkInId: warehousedata.checkInId,
      itemList: [
        {
          itemId: parseInt(warehousedata.itemId),
          binLocationId: parseInt(warehousedata.binLocationId),
          putawayQuantity: warehousedata.putawayQuantity,
          putawayUnits: warehousedata.putawayUnits,
        },
      ],
      organizationId: orgId,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
    };
    let resp = apiPost(url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            setReload(false);
            history.push("/put-away");
            // useHistory().push("/customerlist");
          }, 1000);
          break;

        default:
          setReload(false);
          toast.error(resp.response.data.data);
      }
    });
  }
  function updatePutAway() {
    setReload(true);
    let orgId = localStorage.getItem("organisationId");
    let warehouseId = localStorage.getItem("warehouseId");
    let url = BaseUrl.apiUrl.baseUrl + `api/v1/put_away/${id}`;
    let body = {
      warehouseId: warehouseId,
      checkInId: warehousedata.checkInId,
      itemList: [
        {
          itemId: parseInt(warehousedata.itemId),
          binLocationId: parseInt(warehousedata.binLocationId),
          putawayQuantity: warehousedata.putawayQuantity,
          putawayUnits: warehousedata.putawayUnits,
        },
      ],
      organizationId: orgId,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
    };
    let resp = apiPut(url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            setReload(false);
            history.push("/put-away");
            // useHistory().push("/customerlist");
          }, 1000);
          break;

        default:
          setReload(false);
          toast.error(resp.response.data.data);
      }
    });
  }
  function getPutAwayDataById() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/put_away/${id}`;
    let resp = apiGet(Url);
    resp.then(async (resp) => {
      await props.requestCheckoutDataByNumber(
        resp.response.data.data.checkInNumber
      );
      var datas = resp.response.data.data;
      console.log("datas put away-", resp);
      setwarehousedata({
        id: resp.response.data.data.itemList[0]?.id,
        itemId: resp.response.data.data.itemList[0]?.itemId,
        binLocationId: resp.response.data.data.itemList[0]?.binLocationId,
        putawayQuantity: resp.response.data.data.itemList[0]?.putawayQuantity,
        putawayUnits: resp.response.data.data.itemList[0]?.putawayUnits,
        warehouseId: resp.response.data.data.warehouseId,
        checkInId: resp.response.data.data.checkInId,
      });
      // setcheckboxdata((data) => {
      //   return data.map((item) => {
      //     /*if (datas.warehouseFacilities.includes(item.name)) {
      //       return { ...item, checked: true };
      //     }*/
      //     return item;
      //   });
      // });
    });
  }
  useEffect(() => {
    setItemList(props.checkOutTableData);
  }, [props.checkOutTableData]);

  const checkInField =
    checkInList &&
    checkInList.map((item) => {
      return {
        value: item.checkInId,
        label: item.checkInNumber,
      };
    });
  const itemField = useMemo(() => {
    return (
      itemList &&
      itemList?.map((item) => {
        return {
          value: item.itemCargoCheckInItemId,
          label: item.itemCommodityName,
        };
      })
    );
  }, [itemList, warehousedata.itemId]);

  const binLocationField =
    binLocationList &&
    binLocationList.map((item) => {
      return {
        value: item.id,
        label: item.binLocation,
      };
    });
  function handleSubmit(e) {
    if (formType == "add") {
      createPutAway();
    } else {
      updatePutAway();
    }
  }

  const clearForm = () => {
    form.current.reset(); //this will reset all the inputs in the form
    setuncheck(
      checkboxdata.map((items, index) => {
        return (items.checked = false);
      })
    );
  };
  useEffect(() => {
    // getItemList();
    getBinLocationList();
    getCheckInList();
    getWarehouseList();
  }, [facilities]);

  useEffect(() => {
    if (formType == "edit") {
      getPutAwayDataById();
      // getItemList();
      getBinLocationList();
      getCheckInList();
      getWarehouseList();
    }
  }, [formType]);

  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>
                {formType == "add" ? "ADD NEW PUT AWAY" : "EDIT PUT AWAY"}
              </h6>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className="form"
                    onValidSubmit={(e) => handleSubmit(e)}
                    ref={form}
                    // model={data}
                  >
                    <Row>
                      <Col md={12} className="formLeft">
                        <Row>
                          {/* <Col md={6}>
                            <label>Warehouse *</label>
                            <AvField
                              name="warehouseId"
                              type="select"
                              onChange={(e) => handle(e)}
                              value={warehousedata.warehouseId}
                              required
                            >
                              <option value="" selected disabled>
                                Select Warehouse
                              </option>
                              {warehouseList.map((wh) => (
                                <option value={wh.warehouseId}>
                                  {wh.warehouseName}
                                </option>
                              ))}
                            </AvField>
                          </Col> */}
                          <Col md={6}>
                            <label>Check-In # *</label>
                            <Select
                              name="checkInId"
                              onChange={(e) => {
                                props.requestCheckoutDataByNumber(e.label);
                                setwarehousedata((prevState) => ({
                                  ...prevState,
                                  checkInId: e.value,
                                }));
                              }}
                              options={checkInField}
                              value={checkInField?.filter(
                                (option) =>
                                  option.value === warehousedata.checkInId
                              )}
                              required
                              // styles={warehouserequired ? warestyle : ""}
                              // isDisabled={formType == "edit"}
                            />
                            {/* <AvField
                              name="checkInId"
                              type="select"
                              onChange={(e) => {
                                const selectedCheckInNumber =
                                  e.target.options[e.target.selectedIndex].text;
                                console.log(
                                  "checkin change",
                                  selectedCheckInNumber
                                );
                                props.requestCheckoutDataByNumber(
                                  selectedCheckInNumber
                                );

                                handle(e);
                              }}
                              value={warehousedata.checkInId}
                              required
                            >
                              <option value="" selected disabled>
                                Select Check-In
                              </option>
                              {checkInList.map((ck) => (
                                <option
                                  key={ck.checkInId}
                                  value={ck.checkInId}
                                  label={ck.checkInNumber}
                                >
                                  {ck.checkInNumber}
                                </option>
                              ))}
                            </AvField> */}
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <label>Item *</label>
                            <Select
                              name="itemId"
                              onChange={(e) => {
                                setwarehousedata((prevState) => ({
                                  ...prevState,
                                  itemId: e.value,
                                }));
                              }}
                              options={itemField}
                              value={itemField?.filter(
                                (option) => option.value == warehousedata.itemId
                              )}
                              required
                              // styles={warehouserequired ? warestyle : ""}
                              // isDisabled={formType == "edit"}
                            />
                            {/* <AvField
                              name="itemId"
                              type="select"
                              onChange={(e) => handle(e)}
                              value={warehousedata.itemId}
                              required
                            >
                              <option value={0} selected>
                                Select Item
                              </option>
                              {itemList?.map((it) => (
                                <option value={it.itemCargoCheckInItemId}>
                                  {it.itemCommodityName}
                                </option>
                              ))}
                            </AvField> */}
                          </Col>
                          <Col md={6}>
                            <label>Bin Location *</label>
                            <Select
                              name="binLocationId"
                              onChange={(e) => {
                                setwarehousedata((prevState) => ({
                                  ...prevState,
                                  binLocationId: e.value,
                                }));
                              }}
                              options={binLocationField}
                              value={binLocationField?.filter(
                                (option) =>
                                  option.value == warehousedata.binLocationId
                              )}
                              required
                              // styles={warehouserequired ? warestyle : ""}
                              // isDisabled={formType == "edit"}
                            />
                            {/* <AvField
                              name="binLocationId"
                              type="select"
                              onChange={(e) => handle(e)}
                              value={warehousedata.binLocationId}
                              required
                            >
                              <option value="" selected disabled>
                                Select Bin Location
                              </option>
                              {binLocationList.map((bn) => (
                                <option value={bn.id}>{bn.binLocation}</option>
                              ))}
                            </AvField> */}
                          </Col>
                        </Row>
                        <Row></Row>

                        <Row>
                          <Col md={12}>
                            <Row>
                              <Col md={6}>
                                <label>Put Away Quantity *</label>
                                <AvField
                                  name="putawayQuantity"
                                  type="text"
                                  onChange={handle}
                                  value={warehousedata.putawayQuantity}
                                  required
                                ></AvField>
                              </Col>
                              <Col md={6}>
                                <label>Put Away Units *</label>
                                <AvField
                                  name="putawayUnits"
                                  type="text"
                                  onChange={handle}
                                  value={warehousedata.putawayUnits}
                                  required
                                ></AvField>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr />
                      </Col>
                    </Row>

                    {/* <BottomBar /> */}
                    <div className="bottom-bar">
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={4}>
                              <Link to="/put-away">
                                <button
                                  type="button"
                                  className="btn btn-left ml-3"
                                >
                                  Cancel
                                </button>
                              </Link>
                            </Col>
                            {formType == "add" ? (
                              <Col md={4}>
                                <button
                                  type="button"
                                  onClick={clearForm}
                                  className="btn btn-left"
                                >
                                  Clear
                                </button>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Col>
                        {/* <Col md={4} className="printView">
                          <span className="print">Print or Preview</span>
                        </Col> */}
                        <Col md={8}>
                          <button
                            type="submit"
                            className="btn btn-right float-right mr-3"
                            disabled={reload}
                          >
                            {reload ? (
                              <img
                                className="loader"
                                src={loader}
                                alt="loading..."
                              />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { checkOutTableData, checkOutCheckInId, checkOutDetails } =
    state.CommodityTableData;
  return {
    checkOutTableData,
    checkOutCheckInId,
    checkOutDetails,
  };
};
export default connect(mapStatetoProps, {
  requestCheckoutDataByNumber,
})(AddPutAway);
