export const ADD_COMMODITY_TABLE_DATA = "ADD_COMMODITY_TABLE_DATA";
export const SET_COMMODITY_TABLE_DATA = "SET_COMMODITY_TABLE_DATA";
export const UPDATE_COMMODITY_ROW_TOTAL = "UPDATE_COMMODITY_ROW_TOTAL";
export const DELETE_TABLE_ROW = "DELETE_TABLE_ROW";
export const DELETE_OTHER_CHARGE_TABLE_ROW = "DELETE_OTHER_CHARGE_TABLE_ROW";
export const SET_CHECKOUT_TABLE_DATA = "SET_CHECKOUT_TABLE_DATA";
export const UPDATE_CHECKOUT_TABLE_DATA = "UPDATE_CHECKOUT_TABLE_DATA";
export const REQUEST_CHECKOUT_DATA_BY_NUMBER =
  "REQUEST_CHECKOUT_DATA_BY_NUMBER";
export const CLEAR_COMMODITY_DATA = "CLEAR_COMMODITY_DATA";
export const REQUEST_WAREHOUSE_BY_ID = "REQUEST_WAREHOUSE_BY_ID";
export const SET_WAREHOUSE_DATA = "SET_WAREHOUSE_DATA";
export const ADD_COMMODITY_TABLE_OTHER_CHARGES =
  "ADD_COMMODITY_TABLE_OTHER_CHARGES";
export const SET_COMMODITY_TABLE_OTHER_CHARGES =
  "SET_COMMODITY_TABLE_OTHER_CHARGES";
export const UPDATE_COMMODITY_OTHER_CHARGES = "UPDATE_COMMODITY_OTHER_CHARGES";
export const POPULATE_TABLE_ROW_DATA = "POPULATE_TABLE_ROW_DATA";
export const POPULATE_OTHER_CHARGE_TABLE_ROW_DATA =
  "POPULATE_OTHER_CHARGE_TABLE_ROW_DATA";
export const VALIDATE_TABLE_DATA = "VALIDATE_TABLE_DATA";
export const ADD_HANDLING_CHARGE = "ADD_HANDLING_CHARGE";
export const SET_CUSTOMER_CHECKOUT_TABLE_DATA =
  "SET_CUSTOMER_CHECKOUT_TABLE_DATA";
export const SET_INVALID_CHCEKIN_MESSAGE = "SET_INVALID_CHCEKIN_MESSAGE";
export const SET_CHECKOUT_DATA_BY_ID = "SET_CHECKOUT_DATA_BY_ID";
