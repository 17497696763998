import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Col, Modal, Row } from "reactstrap";
import { apiGet, apiPut } from "../../config/apiConfig";
import { BaseUrl } from "../../config/BaseUrl";

function UserPasswordChange(props) {
  const [password, setpassword] = useState("");
  // function getUserDataById() {
  //   const Url = BaseUrl.apiUrl.baseUrl + `api/v1/user_profile/` + props.userId;
  //   let resp = apiGet(Url);
  //   resp.then((resp) => {
  //     var datas = resp.response.data.data;
  //     setValues(datas);
  //   });
  // }
  // function handle(e) {
  //   const newData = { ...values };
  //   newData[e.target.name] = e.target.value;
  //   setValues(newData);
  // }

  // useEffect(() => {
  //   if (props.userId !== "") {
  //     getUserDataById();
  //   }
  // }, []);

  const updateUserPassword = (e) => {
    let orgId = localStorage.getItem("organisationId");
    let id = props.userId;
    const Url = BaseUrl.apiUrl.baseUrl + "api/v1/user_change_password/" + id;
    var body = {
      password: password,
      username: props.values.email,
    };
    let resp = apiPut(Url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success("Edited User Password Successfully");
          props.setpasswordModel(false);
          break;

        default:
          toast.error("Failed to update User Password");
          props.setpasswordModel(false);
      }
    });
  };
  return (
    <div>
      <ToastContainer
        position="bottom-center"
        // autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Modal className="export-modal" isOpen={props.passwordModel}>
        <Row>
          <Col md={12} className="modal-head">
            <div>
              <h5>Edit User Password</h5>
            </div>
            <div onClick={() => props.setpasswordModel(false)}>
              <i class="bx bx-x"></i>
            </div>
          </Col>
        </Row>
        <hr />
        <AvForm className="form" onValidSubmit={(e) => updateUserPassword(e)}>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Email</label>
            </Col>
            <Col md={6}>
              <AvField
                name="email"
                type="email"
                // placeholder="Enter User email"
                // onChange={(e) => handle(e)}
                value={props.values.email}
                disabled
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Enter New Password</label>
            </Col>
            <Col md={6}>
              <AvField
                name="password"
                type="password"
                placeholder="Enter New Password"
                onChange={(e) => setpassword(e.target.value)}
                value={password}
                // disabled
              ></AvField>
            </Col>
            {/* <button onClick={() => props.passwordModel(true)}>
              Change Password
            </button> */}
          </Row>
          <Row>
            <Col md={12}>
              <button type="submit" class="exportuserbtn">
                Update Password
              </button>
            </Col>
          </Row>
        </AvForm>
      </Modal>
    </div>
  );
}

export default UserPasswordChange;
