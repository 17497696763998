import React from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { useCallback } from "react";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";
import { useRef } from "react";
import FileSaver from "file-saver";

function PurchaseOrderHistory() {
  const gridRef = useRef();

  const [vendorList, setVendorList] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [typeRequired, setTypeRequired] = useState(false);
  const current = new Date();
  const date = `${current.getFullYear()}-${
    current.getMonth() < 10
      ? `0${current.getMonth() + 1}`
      : current.getMonth() + 1
  }-${current.getDate() < 10 ? `0${current.getDate()}` : current.getDate()}`;
  const [searchenable, setsearchenable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchdata, setsearchdata] = useState({
    fromDate: "",
    toDate: "",
    vendorId: 0,
  });

  const [vendorId, setVendorId] = useState({
    id: 0,
    name: "",
  });
  const [billingType, setBillingType] = useState({
    id: 0,
    name: "",
  });
  const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);
  const columnDefs = useMemo(
    () => [
      { field: "purchaseOrderDate", headerName: "Date", width: "150" },

      { field: "purchaseOrderNumber", headerName: "Order #", width: "150" },
      { field: "purchaseOrderVendorName", headerName: "Vendor" },
      {
        field: "purchaseOrderPlaceOfSupply",
        headerName: "Place of Supply",
        width: "130",
      },
      {
        field: "purchaseOrderExpectedDeliveryDate",
        headerName: "Expecpted Delivery",
      },
      { field: "purchaseOrderPaymentTerms", headerName: "Payment Terms" },
      { field: "purchaseOrderTotal", headerName: "Order Total", width: "150" },
    ],
    []
  );

  const [rowData, setRowData] = useState([]);
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    []
  );

  //   const onGridReady = useCallback((params) => {
  //     const updateData = (data) => params.api.setRowData(data);
  //     const Url =
  //       BaseUrl.apiUrl.baseUrl + `api/v1/report/checkouts/2022-01-01/${date}/0/0`;
  //     let resp = apiGet(Url);
  //     resp.then((resp) => {
  //       updateData(resp.response.data.data);
  //     });
  //   }, []);

  function handleChange(e) {
    const newData = { ...searchdata };
    newData[e.target.name] = e.target.value;

    setsearchdata(newData);
  }

  const handleGenerateReport = () => {
    setIsLoading(true);
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/purchase_order/${searchdata.fromDate}/${searchdata.toDate}/${vendorId.id}/${billingType.id}/excel`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setIsLoading(false);
      let sliceSize = 1024;
      let byteCharacters = atob(resp.response.data.data);
      let bytesLength = byteCharacters.length;
      let slicesCount = Math.ceil(bytesLength / sliceSize);
      let byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);
        let bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      FileSaver.saveAs(
        new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
        `${Date.now()}.xlsx`
      );
    });
  };
  const handleDownloadPdf = () => {
    setIsLoading(true);
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/purchase_order/${searchdata.fromDate}/${searchdata.toDate}/${vendorId.id}/${billingType.id}/pdf`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setIsLoading(false);
      let sliceSize = 1024;
      let byteCharacters = atob(resp.response.data.data);
      let bytesLength = byteCharacters.length;
      let slicesCount = Math.ceil(bytesLength / sliceSize);
      let byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);
        let bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      FileSaver.saveAs(
        new Blob(byteArrays, { type: "application/pdf" }),
        `${Date.now()}.pdf`
      );
    });
  };
  //   const onFirstDataRendered = useCallback((params) => {
  //     gridRef.current.api.sizeColumnsToFit();
  //   }, []);
  function handleSearch(e) {
    if (
      searchdata.fromDate !== "" ||
      searchdata.toDate !== "" ||
      vendorId.id !== 0
    ) {
      checkInData();
      checkOutData();
    }
  }
  const combinedData = useMemo(() => {
    // Combine data1 and data2 into a single array
    const result = [...data1, ...data2];
    // Sort the combined data by some criteria
    // result.sort((a, b) => a.id - b.id);
    console.log("combined results", result);
    return result;
  }, [data1, data2]);
  function checkInData() {
    setIsLoading(true);
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/purchase_order/${searchdata.fromDate}/${searchdata.toDate}/${vendorId.id}`;
    //   `api/v1/report/checkin_items/2023-03-01/2023-03-15/131`;

    let resp = apiGet(Url);
    resp.then((resp) => {
      console.log("data 1 response ", resp);
      setIsLoading(false);
      setData1(resp.response.data.data);
      //   setRowData(resp.response.data.data);
    });
  }
  function checkOutData() {
    setIsLoading(true);
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/checkout_items/${searchdata.fromDate}/${searchdata.toDate}/${vendorId.id}`;
    //   `api/v1/report/checkout_items/2023-03-01/2023-03-15/131`;

    let resp = apiGet(Url);
    resp.then((resp) => {
      console.log("data 2 response ", resp);
      setIsLoading(false);
      setData2(resp.response.data.data);
      //   setRowData(resp.response.data.data);
    });
  }
  function getVendorData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/vendors/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setVendorList(resp.response.data.data);
    });
  }
  useEffect(() => {
    getVendorData();
  }, []);

  const vendor = vendorList.map((item) => {
    return {
      value: item.vendorId,
      label: item.vendorDisplayName,
    };
  });
  const billingTypeOptions = [
    {
      value: "ALL",
      label: "ALL",
    },
    {
      value: "CBM",
      label: "CBM",
    },
    {
      value: "SQM",
      label: "SQM",
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>PURCHASE OREDR HISTORY</h6>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody className="tabcard">
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12} className="search-sec">
                          <AvForm
                            className="form"
                            onValidSubmit={(e) => handleSearch(e)}
                          >
                            <Row>
                              <Col md={2}>
                                <label>From Date</label>
                                <AvField
                                  name="fromDate"
                                  type="date"
                                  onChange={handleChange}
                                  max={date}
                                  // value={data.custFn}
                                  required
                                ></AvField>
                              </Col>
                              <Col md={2}>
                                <label>To</label>
                                <AvField
                                  name="toDate"
                                  type="date"
                                  onChange={handleChange}
                                  min={searchdata.fromDate}
                                  max={date}
                                  required
                                ></AvField>
                              </Col>

                              <Col md={2}>
                                <label>Vendor</label>
                                <Select
                                  name="checkInVendorId"
                                  onChange={(e) =>
                                    setVendorId({
                                      id: e.value,
                                      name: e.label,
                                    })
                                  }
                                  options={vendor}
                                />
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col>
                                <div className="search-buttons">
                                  <button
                                    className=" btn reportbutton"
                                    type="submit"
                                    // onClick={(e) => handleSearch(e)}
                                    disabled={
                                      isLoading ||
                                      searchdata.fromDate === "" ||
                                      searchdata.toDate === ""
                                    }
                                  >
                                    Get Report
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12}>
                      <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                          ref={gridRef}
                          animateRows="true"
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          //   enableRangeSelection="true"
                          rowData={combinedData}
                          rowSelection="multiple"
                          suppressRowClickSelection="true"
                          //   onGridReady={onGridReady}
                          //   onFirstDataRendered={onFirstDataRendered}
                          pagination={true}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default PurchaseOrderHistory;
