import React, { useCallback, useEffect, useState } from "react";
import stack from "../../assets/icons/stack.png";
import normaltemp from "../../assets/icons/normaltemp.png";
import roomtemp from "../../assets/icons/roomtemp.png";
import coinstack from "../../assets/icons/coinstack.png";
import tempcontrol from "../../assets/icons/tempcontrol.png";

export default function CustomCheckbox(props) {
  const [checkboxdata, setcheckboxdata] = useState([
    {
      name: "Stacking facility",
      checked: false,
      icon: stack,
    },
    {
      name: "Temperature controlled",
      checked: false,
      icon: tempcontrol,
    },
    {
      name: "Room temperature",
      checked: false,
      icon: roomtemp,
    },
    {
      name: "Fork / Scissor Lift",
      checked: false,
      icon: coinstack,
    },
    {
      name: "Normal temperature",
      checked: false,
      icon: normaltemp,
    },
  ]);
  const [facilities, setfacilities] = useState(null);

  function customCheckboxClick(index) {
    var olddata = [...checkboxdata];
    const currentStatus = olddata[index].checked;
    olddata[index].checked = !currentStatus;
    props.setRequireFacility(
      !checkboxdata.some((item) => {
        return item.checked;
      })
    );
    setcheckboxdata(olddata);
  }
  useEffect(() => {
    const fac = checkboxdata.map((items) => {
      return items.checked ? items.name : null;
    });
    setfacilities(fac.join());
  }, [checkboxdata]);

  const checkboxUI = useCallback(() => {
    return checkboxdata.map((items, index) => {
      return (
        <div
          className="custom-checkbox"
          style={{
            background: items.checked
              ? "linear-gradient(to bottom, #f0f1f4, #ebeef6, #e6ebf8, #e1e9f9, #dbe6fb)"
              : "white",
          }}
          onClick={() => customCheckboxClick(index)}
        >
          <div className="checkbox-round">
            {items.checked ? <div className="checkbox-inner-round"></div> : ""}
          </div>
          <img src={items.icon} alt="" style={{ width: "3rem" }} />
          {/* <i
            className={items.icon}
            style={{ color: items.checked ? "rgb(84, 114, 202)" : "" }}
          ></i> */}
          <span className="checkbox-name">{items.name}</span>
        </div>
      );
    });
  }, [checkboxdata]);

  return {
    checkboxdata,
    setcheckboxdata,
    facilities,
    checkbox: <div className="checkbox-div">{checkboxUI()}</div>,
  };
}
