import {
  ADD_TABLE_DATA_OT,
  UPDATE_ROW_TOTAL_OT,
  DELETE_TABLE_ROW_OT,
  CALCULATE_SHIPPING_CHARGE_OT,
  CALCULATE_ADJUSTMENT_CHARGE_OT,
  CALCULATE_TOTALS_OT,
  POPULATE_TABLE_DATA_OT,
  CLEAR_DATA_OT,
  UPDATE_ROW_SELECT,
} from "./actionTypes";

const INIT_STATE = {
  tableRowDataOt: [],
  subTotal: 0,
  shippingCharge: 0,
  adjustment: 0,
  total: 0,
};

const TableDataOthers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_TABLE_DATA_OT:
      var tableData = {
        itemId: "",
        itemName: "",
        itemDescription: "",
        // itemDetails: action.payload.itemName,
        itemQuantity: "",
        //itemUnit: "CBM",
        itemRate: "",
        itemDiscountValue: "",
        itemDiscountType: "",
        // invItemTaxId: 0,
        // invItemTaxPercent: 0,
        itemAmount: 0,
      };

      return {
        ...state,
        tableRowDataOt: [...state.tableRowDataOt, tableData],
        // subTotal: oldSubTotal,
      };

    case UPDATE_ROW_TOTAL_OT:
      var oldData = [...state.tableRowDataOt];
      oldData[action.payload.index][action.payload.value.target.name] =
        action.payload.value.target.value;
      oldData[action.payload.index]["itemAmount"] =
        (oldData[action.payload.index]["itemQuantity"] || 0) *
          (oldData[action.payload.index]["itemRate"] || 0) +
        (oldData[action.payload.index]["itemTaxPercent"] || 0);
      switch (oldData[action.payload.index]["itemDiscountType"]) {
        case "1":
          oldData[action.payload.index]["itemAmount"] =
            (oldData[action.payload.index]["itemAmount"] || 0) -
            ((oldData[action.payload.index]["itemAmount"] || 0) *
              (oldData[action.payload.index]["itemDiscountValue"] || 0)) /
              100;
          break;
        case "2":
          oldData[action.payload.index]["itemAmount"] =
            (oldData[action.payload.index]["itemAmount"] || 0) -
            (oldData[action.payload.index]["itemDiscountValue"] || 0);
          break;
      }
      var newSubTotal = oldData
        .map((item) => parseFloat(item.itemAmount))
        .reduce((prev, curr) => prev + curr, 0);
      var newTotal =
        newSubTotal +
        parseFloat(state.shippingCharge) +
        parseFloat(state.adjustment);
      console.log("data consoled", oldData);
      return {
        ...state,
        tableRowDataOt: oldData,
        subTotal: newSubTotal,
        total: newTotal,
      };
    case UPDATE_ROW_SELECT:
      // console.log("data consoled", action.payload);
      var oldData = [...state.tableRowDataOt];
      oldData[action.payload.index]["itemId"] = action.payload.value;
      return {
        ...state,
        tableRowDataOt: oldData,
      };
    case CALCULATE_SHIPPING_CHARGE_OT:
      var value =
        action.payload.target.value != "" ? action.payload.target.value : 0;
      state.shippingCharge = value;
      state.total =
        parseFloat(state.subTotal) +
        parseFloat(state.shippingCharge) +
        parseFloat(state.adjustment);
      return {
        ...state,
      };
    case CALCULATE_ADJUSTMENT_CHARGE_OT:
      var value =
        action.payload.target.value != "" ? action.payload.target.value : 0;
      state.adjustment = value;
      state.total =
        parseFloat(state.subTotal) +
        parseFloat(state.shippingCharge) +
        parseFloat(state.adjustment);
      return {
        ...state,
      };
    case DELETE_TABLE_ROW_OT:
      var oldData = [...state.tableRowDataOt];
      oldData.splice(action.payload, 1);
      var oldSubTotal = oldData
        .map((item) => parseFloat(item.itemAmount))
        .reduce((prev, curr) => prev + curr, 0);
      state.total =
        oldSubTotal +
        parseFloat(state.shippingCharge) +
        parseFloat(state.adjustment);
      return {
        ...state,
        tableRowDataOt: oldData,
        subTotal: oldSubTotal,
      };
    case CALCULATE_TOTALS_OT:
      var oldData = [...state.tableRowDataOt];
      state.subTotal = oldData
        .map((item) => parseInt(item.itemAmount))
        .reduce((prev, curr) => prev + curr, 0);
      state.total =
        parseFloat(state.subTotal) +
        parseFloat(state.shippingCharge) +
        parseFloat(state.adjustment);
      return {
        ...state,
      };
    case POPULATE_TABLE_DATA_OT:
      // var oldData = [...state.tableRowData];
      state.tableRowDataOt = action.payload.itemList.map((items) => {
        return {
          id: items.id,
          itemId: items.itemId,
          itemName: items.itemName,
          itemDescription: "",
          // itemDetails: action.payload.itemName,
          itemQuantity: items.itemQuantity,
          //itemUnit: items.itemUnit,
          itemRate: items.itemRate,
          itemDiscountValue: items.itemDiscountValue,
          itemDiscountType: 1,
          // invItemTaxId: 0,
          // invItemTaxPercent: 0,
          itemAmount: items.itemAmount,
        };
      });
      state.subTotal = action.payload.subtotal;
      state.shippingCharge = action.payload.shippingcharge;
      state.adjustment = action.payload.adjustment;
      state.total = action.payload.total;
      return {
        ...state,
      };
    case CLEAR_DATA_OT:
      return {
        ...state,
        tableRowDataOt: [],
        subTotal: 0,
        shippingCharge: 0,
        adjustment: 0,
        total: 0,
      };
    default:
      return state;
  }
};
export default TableDataOthers;
