import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  apiGet,
  apiDelete,
  apiPut,
} from "../../../components/config/apiConfig";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../../components/config/BaseUrl";
import FilterComponent from "./FilterComponent";
import DeleteBranch from "./DeleteBranch";
import { ToastContainer, toast } from "react-toastify";
import EditBranch from "./EditBranch";
function BranchList() {
  const [branchList, setBranchList] = useState([]);
  const [editValues, seteditValues] = useState({
    branchName: "",
    branchAddressStreet1: "",
    branchAddressStreet2: "",
    branchAddressCity: "",
    branchAddressState: "",
    branchAddressZipcode: "",
    branchAddressPhone: "",
    branchFax: "",
    branchWebsite: "",
    branchPrimaryContactEmail: "",
    branchGstin: "",
  });
  const [branchId, setBranchId] = useState("");
  const [deleteModel, setdeleteModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const userId = localStorage.getItem("Id");
  const getBranchList = async () => {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/settings/organization_branch/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setBranchList(resp.response.data.data);
      // setTotalRows(resp.response.data.data.totalElements);
    });
  };

  const handleOpenModal = (id) => {
    setBranchId(id);
    setdeleteModel(true);
  };
  const handleEditModal = (id) => {
    setBranchId(id);
    setEditModel(true);
    getBranchById(id);
  };
  const handleChange = (e) => {
    const newData = { ...editValues };
    newData[e.target.name] = e.target.value;
    seteditValues(newData);
  };
  const getBranchById = async (id) => {
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/settings/organization_branch/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      seteditValues(resp.response.data.data);
      // setTotalRows(resp.response.data.data.totalElements);
    });
  };
  useEffect(() => {
    getBranchList();
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (data) => data.branchName,
      sortable: true,
    },
    {
      name: ":Phone",
      selector: (data) => data.branchAddressPhone,
      sortable: true,
    },

    {
      name: "Email",
      selector: (data) => data.branchPrimaryContactEmail,
      sortable: true,
    },
    {
      name: "GSTIN",
      selector: (data) => data.branchGstin,
      sortable: true,
    },
    {
      name: "Action",
      button: true,
      center: true,
      cell: (data) => (
        <>
          <button
            className="btn-round edit"
            onClick={() => handleEditModal(data.branchId)}
          >
            <i class="bx bx-pencil"></i>
          </button>

          <button
            className="btn-round delete"
            onClick={() => handleOpenModal(data.branchId)}
          >
            <i class="bx bx-trash"></i>
          </button>
        </>
      ),
    },
  ];
  const subHeaderComponent = useMemo(() => {
    return (
      <FilterComponent
        getBranchList={getBranchList}
        // onFilter={(e) => setFilterText(e.target.value)}
        // onClear={handleClear}
        // filterText={filterText}
      />
    );
  }, []);
  const handleDelete = (id) => {
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/settings/organization_branch/${id}/${userId}`;
    let resp = apiDelete(Url);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          getBranchList();
          toast.success(resp.response.data.data);
          setdeleteModel(false);
          break;

        default:
          getBranchList();
      }
    });
  };
  const handleEdit = (id) => {
    var body = {
      branchName: editValues.branchName,
      branchAddressStreet1: editValues.branchAddressStreet1,
      branchAddressStreet2: editValues.branchAddressStreet2,

      branchAddressCity: editValues.branchAddressCity,
      branchAddressState: editValues.branchAddressState,
      branchAddressZipcode: editValues.branchAddressZipcode,
      branchAddressPhone: editValues.branchAddressPhone,
      branchFax: editValues.branchFax,
      branchWebsite: editValues.branchWebsite,
      branchPrimaryContactEmail: editValues.branchPrimaryContactEmail,
      branchGstin: editValues.branchGstin,
    };
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/settings/organization_branch/${id}`;
    let resp = apiPut(Url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          getBranchList();
          setEditModel(false);
          break;

        default:
          toast.error(resp.response.data.data);
      }
    });
  };
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        // autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Branch" />
          <Row>
            <Col>
              <div className="card">
                <DataTable
                  columns={columns}
                  data={branchList}
                  pagination
                  paginationServer
                  selectableRows
                  subHeader
                  subHeaderComponent={subHeaderComponent}
                  subHeaderAlign="left"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <EditBranch
        openModal={editModel}
        setOpenModel={setEditModel}
        handle={handleChange}
        editValues={editValues}
        handleEdit={handleEdit}
        id={branchId}
      />
      <DeleteBranch
        openModal={deleteModel}
        setOpenModel={setdeleteModel}
        handleDeleteUom={handleDelete}
        id={branchId}
      />
    </React.Fragment>
  );
}

export default BranchList;
