import {
  SET_DEFAULT_ITEM,
  DELETE_ITEMS,
  UPDATE_ITEMS_TABLE,
} from "./actionTypes";

const INIT_STATE = {
  onlineFormCustomerItems: [],
};

const OnlineFormTable = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DEFAULT_ITEM:
      var tableData = {
        itemCommodityName: "",
        itemInvoiceNumber: "",
        itemReferenceNumber: "",
        itemPreviousCheckInNumber: "",
        itemPreviousCheckInDate: "",
        itemPreviousInvoiceNumber: "",
        itemPreviousInvoiceDate: "",
        itemHsnCode: "",
        itemUom: "",
        itemUnits: 0,
        itemUnitsPerSinglePackage: 0,
        itemTemperature: "",
        itemHandlingInfo: "",
        itemDateOfExpiry: "",
        itemBatch: "",
        itemPallet: "",
        itemTotalWeight: "",
        itemWeightUom: "",
        itemLength: 0,
        itemBreadth: 0,
        itemHeight: 0,
        itemDimensionUnits: "m",
        itemArea: 0,
        itemAreaSqm: 0,
        itemAreaUnits: "cbm",
        itemValueAddReg: "",
        itemProceedFrom: "",
        itemProceedTo: "",
        itemAdditionalInfo: "",
        itemRemarks: "",
        itemCargoCondition: "",
        editByUser: 1,
      };
      return {
        ...state,
        onlineFormCustomerItems: [...state.onlineFormCustomerItems, tableData],
      };
      break;
    case DELETE_ITEMS:
      var array = [...state.onlineFormCustomerItems];
      if (action.payload !== -1) {
        array.splice(action.payload, 1);
      }
      return {
        ...state,
        onlineFormCustomerItems: array,
      };
      break;
    case UPDATE_ITEMS_TABLE:
      var oldData = [...state.onlineFormCustomerItems];
      oldData[action.payload.index][action.payload.value.target.name] =
        action.payload.value.target.value;
      if (
        parseInt(oldData[action.payload.index]["itemLength"]) > 0 &&
        parseInt(oldData[action.payload.index]["itemBreadth"]) > 0 &&
        parseInt(oldData[action.payload.index]["itemHeight"]) > 0
      ) {
        oldData[action.payload.index]["itemArea"] =
          (oldData[action.payload.index]["itemUnits"] *
            oldData[action.payload.index]["itemLength"] *
            oldData[action.payload.index]["itemBreadth"] *
            oldData[action.payload.index]["itemHeight"]) /
          1000000;
        oldData[action.payload.index]["itemArea"] = parseFloat(
          oldData[action.payload.index]["itemArea"].toFixed(3)
        );
      } else {
        oldData[action.payload.index]["itemArea"] = 0;
      }
      if (
        parseInt(oldData[action.payload.index]["itemLength"]) > 0 &&
        parseInt(oldData[action.payload.index]["itemBreadth"]) > 0
      ) {
        oldData[action.payload.index]["itemAreaSqm"] =
          (oldData[action.payload.index]["itemUnits"] *
            oldData[action.payload.index]["itemLength"] *
            oldData[action.payload.index]["itemBreadth"]) /
          10000;
        oldData[action.payload.index]["itemAreaSqm"] = parseFloat(
          oldData[action.payload.index]["itemAreaSqm"].toFixed(3)
        );
      } else {
        oldData[action.payload.index]["itemAreaSqm"] = 0;
      }
      return {
        ...state,
        onlineFormCustomerItems: oldData,
      };
    default:
      return state;
      break;
  }
};

export default OnlineFormTable;
