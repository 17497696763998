import { SAVE_DATA_ON_SUMBIT, UPDATE_RETAINER_LIST } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
};

const retainerInvoice = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DATA_ON_SUMBIT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_RETAINER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default retainerInvoice;
