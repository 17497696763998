import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet, apiPost } from "../../components/config/apiConfig";
import DataTable from "react-data-table-component";
import { useMemo } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  addPalletToClose,
  requestPalletToMerge,
  updatePalletToMergeData,
  clearPalletMergeTableDatas,
} from "../../store/actions";
import { useEffect } from "react";
import * as moment from "moment";
function AddPalletMerge(props) {
  const history = useHistory();
  const [palletToCloseNumber, setPalletToCloseNumber] = useState("");
  const [palletToMergeNumber, setPalletToMergeNumber] = useState("");
  const [reload, setReload] = useState(false);
  const [sameNumber, setSameNumber] = useState(false);
  const [checkClosePalletNumberAvailable, setCheckClosePalletNumberAvailable] =
    useState(true);
  const [
    checkMergePalletNumberAvailable,
    setCheckMergeClosePalletNumberAvailable,
  ] = useState(true);
  function checkPalletAvailable(value, type) {
    if (value !== "") {
      const Url =
        BaseUrl.apiUrl.baseUrl + `api/v1/check_pallet_no_available/${value}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        if (resp.response.data.data === "Pallet Number Exists Already.") {
          if (type === "close") {
            setCheckClosePalletNumberAvailable(true);
            props.addPalletToClose(palletToCloseNumber);
          } else {
            setCheckMergeClosePalletNumberAvailable(true);
            props.requestPalletToMerge(palletToMergeNumber);
          }
        }
        if (resp.response.data.data === "Pallet Available") {
          if (type === "close") {
            setCheckClosePalletNumberAvailable(false);
          } else {
            setCheckMergeClosePalletNumberAvailable(false);
          }
        }
      });
    }
  }
  const checkPalletNumber = (e) => {
    if (palletToCloseNumber == palletToMergeNumber) {
      setSameNumber(true);
      setPalletToMergeNumber("");
    } else if (palletToCloseNumber !== palletToMergeNumber) {
      setSameNumber(false);
    }
  };
  const searchPalletToClose = (type) => {
    checkPalletAvailable(palletToCloseNumber, type);
    // props.addPalletToClose(palletToCloseNumber);
  };
  const searchPalletToMerge = (type) => {
    checkPalletAvailable(palletToMergeNumber, type);
    // props.requestPalletToMerge(palletToMergeNumber);
  };
  const palletCloseColumn = useMemo(() => [
    // {
    //   input: true,
    //   cell: (row, rowIndex) => (
    //     <input
    //       type="checkbox"
    //       name="itemIsSelected"
    //       defaultChecked={row.itemIsSelected}
    //       //   onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
    //     />
    //   ),
    //   width: "30px",
    // },
    {
      name: "Commodity Name",
      selector: (row) => row.itemCommodityName,
      sortable: false,
    },
    {
      name: "HSN Code",
      selector: (row) => row.itemHsnCode,
      sortable: false,
    },

    {
      name: "UOM",
      selector: (row) => row.itemUom,
      sortable: false,
    },
    {
      name: "Stock",
      selector: (row) => row.itemUnitsTotal,
      sortable: false,
    },
    {
      name: "Available Stock",
      selector: (row) => row.itemUnitsAvailable,
      sortable: false,
    },
    {
      name: "Pallet No.",
      selector: (row) => row.itemPallet,
      sortable: false,
    },
    {
      name: "Available CBM",
      selector: (row) => row.itemAreaOccupied,
      sortable: false,
    },
    {
      name: "Available SQM",
      selector: (row) => row.itemSqmOccupied,
      sortable: false,
    },
    {
      name: "L",
      selector: (row) => row.itemLength,
      // cell: (row, rowIndex) => (
      //   <input
      //     type="number"
      //     name="itemLength"
      //     //   onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
      //     disabled={!row.itemIsSelected ? true : false}
      //     style={{
      //       border: "none",
      //       borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
      //       width: "50px",
      //     }}
      //   />
      // ),
      // width: "60px",
    },
    {
      name: "W",
      selector: (row) => row.itemBreadth,
      // cell: (row, rowIndex) => (
      //   <input
      //     type="number"
      //     name="itemBreadth"
      //     //   onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
      //     disabled={!row.itemIsSelected ? true : false}
      //     style={{
      //       border: "none",
      //       borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
      //       width: "50px",
      //     }}
      //   />
      // ),
      // width: "60px",
    },
    {
      name: "H",
      selector: (row) => row.itemHeight,
      // cell: (row, rowIndex) => (
      //   <input
      //     type="number"
      //     name="itemHeight"
      //     //   onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
      //     disabled={!row.itemIsSelected ? true : false}
      //     style={{
      //       border: "none",
      //       borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
      //       width: "50px",
      //     }}
      //   />
      // ),
      // width: "60px",
    },
    {
      name: "CBM",
      selector: (row) => row.itemArea,
      // cell: (row, rowIndex) => (
      //   <input
      //     type="number"
      //     name="itemArea"
      //     value={row.itemArea == 0 ? "" : row.itemArea}
      //     //   onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
      //     disabled={!row.itemIsSelected ? true : false}
      //     style={{
      //       border: "none",
      //       borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
      //       width: "70px",
      //     }}
      //   />
      // ),
      // width: "80px",
      // center: true,
    },
    {
      name: "SQM",
      selector: (row) => row.itemAreaSqm,
      // // selector: (row) => (row.itemArea ? row.itemArea : 0),
      // // sortable: false,
      // cell: (row, rowIndex) => (
      //   <input
      //     type="number"
      //     name="itemAreaSqm"
      //     value={row.itemAreaSqm == 0 ? "" : row.itemAreaSqm}
      //     //   onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
      //     disabled={!row.itemIsSelected ? true : false}
      //     style={{
      //       border: "none",
      //       borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
      //       width: "70px",
      //     }}
      //   />
      // ),
      // width: "80px",
      // center: true,
    },
    {
      name: "Check Out units",
      selector: (row) => row.itemUnitsCheckedOut,
      // cell: (row, rowIndex) => (
      //   <input
      //     type="number"
      //     name="itemUnitsCheckedOut"
      //     value={row.itemUnitsCheckedOut == 0 ? "" : row.itemUnitsCheckedOut}
      //     //   onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
      //     disabled={!row.itemIsSelected ? true : false}
      //     placeholder={row.itemIsSelected ? "Enter checkout units" : ""}
      //     style={{
      //       border: "none",
      //       borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
      //       width: "130px",
      //     }}
      //   />
      // ),
      // width: "140px",
    },
    {
      name: "Remarks",
      selector: (row) => row.itemRemarks,
      // cell: (row, rowIndex) => (
      //   <input
      //     type="text"
      //     name="itemRemarks"
      //     //   onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
      //     disabled={!row.itemIsSelected ? true : false}
      //     placeholder={row.itemIsSelected ? "Remarks" : ""}
      //     style={{
      //       border: "none",
      //       borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
      //       width: "130px",
      //     }}
      //   />
      // ),
      // width: "140px",
    },
    {
      name: "Cargo Condition",
      selector: (row) => row.itemCargoCondition,
      // cell: (row, rowIndex) => (
      //   <input
      //     type="text"
      //     name="itemCargoCondition"
      //     //   onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
      //     disabled={!row.itemIsSelected ? true : false}
      //     placeholder={row.itemIsSelected ? "Conditions" : ""}
      //     style={{
      //       border: "none",
      //       borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
      //       width: "130px",
      //     }}
      //   />
      // ),
      // width: "140px",
    },
  ]);
  const palletMergeColumn = useMemo(() => [
    // {
    //   input: true,
    //   cell: (row, rowIndex) => (
    //     <input
    //       type="checkbox"
    //       name="itemIsSelected"
    //       defaultChecked={row.itemIsSelected}
    //       //   onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
    //     />
    //   ),
    //   width: "30px",
    // },
    {
      name: "Commodity Name",
      selector: (row) => row.itemCommodityName,
      sortable: false,
    },
    {
      name: "HSN Code",
      selector: (row) => row.itemHsnCode,
      sortable: false,
    },

    {
      name: "UOM",
      selector: (row) => row.itemUom,
      sortable: false,
    },
    {
      name: "Stock",
      selector: (row) => row.itemUnits,
      sortable: false,
    },
    {
      name: "Available Stock",
      selector: (row) => row.itemUnitsAvailable,
      sortable: false,
    },
    {
      name: "Pallet No.",
      selector: (row) => row.itemPallet,
      sortable: false,
    },
    {
      name: "Available CBM",
      selector: (row) => row.itemAreaOccupied,
      sortable: false,
    },
    {
      name: "Available SQM",
      selector: (row) => row.itemSqmOccupied,
      sortable: false,
    },
    // {
    //   name: "L",

    //   cell: (row, rowIndex) => (
    //     <input
    //       type="number"
    //       name="itemLength"
    //       onChange={(e) => props.updatePalletToMergeData(e.target, rowIndex)}
    //       style={{
    //         border: "none",
    //         borderBottom: "1px solid #052f85",
    //         width: "50px",
    //       }}
    //     />
    //   ),
    //   width: "60px",
    // },
    // {
    //   name: "W",
    //   cell: (row, rowIndex) => (
    //     <input
    //       type="number"
    //       name="itemBreadth"
    //       onChange={(e) => props.updatePalletToMergeData(e.target, rowIndex)}
    //       style={{
    //         border: "none",
    //         borderBottom: "1px solid #052f85",
    //         width: "50px",
    //       }}
    //     />
    //   ),
    //   width: "60px",
    // },
    // {
    //   name: "H",
    //   cell: (row, rowIndex) => (
    //     <input
    //       type="number"
    //       name="itemHeight"
    //       onChange={(e) => props.updatePalletToMergeData(e.target, rowIndex)}
    //       style={{
    //         border: "none",
    //         borderBottom: "1px solid #052f85",
    //         width: "50px",
    //       }}
    //     />
    //   ),
    //   width: "60px",
    // },
    {
      name: "CBM",
      cell: (row, rowIndex) => (
        <input
          type="number"
          name="itemAreaToMerge"
          value={row.itemAreaToMerge == 0 ? "" : row.itemAreaToMerge}
          onChange={(e) => props.updatePalletToMergeData(e.target, rowIndex)}
          style={{
            border: "none",
            borderBottom: "1px solid #052f85",
            width: "70px",
          }}
          disabled
        />
      ),
      width: "80px",
    },
    {
      name: "SQM",

      // selector: (row) => (row.itemArea ? row.itemArea : 0),
      // sortable: false,
      cell: (row, rowIndex) => (
        <input
          type="number"
          name="itemSqmToMerge"
          value={row.itemSqmToMerge == 0 ? "" : row.itemSqmToMerge}
          onChange={(e) => props.updatePalletToMergeData(e.target, rowIndex)}
          style={{
            border: "none",
            borderBottom: "1px solid #052f85",
            width: "70px",
          }}
          disabled
        />
      ),
      width: "80px",
      // center: true,
    },
    {
      name: "Units to merge",

      cell: (row, rowIndex) => (
        <input
          type="number"
          name="itemUnitsToMerge"
          value={row.itemUnitsToMerge == 0 ? "" : row.itemUnitsToMerge}
          onChange={(e) => props.updatePalletToMergeData(e.target, rowIndex)}
          placeholder={"units to merge"}
          style={{
            border: "none",
            borderBottom: "1px solid #052f85",
            width: "130px",
          }}
          disabled
        />
      ),
      width: "140px",
    },
    {
      name: "Remarks",

      cell: (row, rowIndex) => (
        <input
          type="text"
          name="itemRemarks"
          onChange={(e) => props.updatePalletToMergeData(e.target, rowIndex)}
          placeholder={"Remarks"}
          style={{
            border: "none",
            borderBottom: "1px solid #052f85",
            width: "130px",
          }}
        />
      ),
      width: "140px",
    },
    {
      name: "Cargo Condition",
      selector: (row) => row.itemCargoCondition,
      cell: (row, rowIndex) => (
        <input
          type="text"
          name="itemCargoCondition"
          onChange={(e) => props.updatePalletToMergeData(e.target, rowIndex)}
          placeholder={"Conditions"}
          style={{
            border: "none",
            borderBottom: "1px solid #052f85",
            width: "130px",
          }}
        />
      ),
      width: "140px",
    },
  ]);

  const handleMergeItems = () => {
    setReload(true);
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/merge_request";
    let body = {
      requestDate: moment(new Date()).format("YYYY-MM-DD"),
      requestWarehouseId:
        props.palletRequestCustomerDetails?.checkInWarehouseId,
      requestWarehouseName:
        props.palletRequestCustomerDetails?.checkInWarehouseName,
      requestCustomerId: props.palletRequestCustomerDetails?.checkInCustomerId,
      requestCustomerName:
        props.palletRequestCustomerDetails?.checkInCustomerName,
      requestCustomerEmail:
        props.palletRequestCustomerDetails?.checkInCustomerEmail,
      requestCustomerPhone:
        props.palletRequestCustomerDetails?.checkInCustomerPhone,
      requestCustomerAddress:
        props.palletRequestCustomerDetails?.checkInCustomerAddress,
      requestRatePerCbm: props.palletRequestCustomerDetails?.checkInRatePerCbm,
      requestCbmRatePerDay:
        props.palletRequestCustomerDetails?.checkInCbmRatePerDay,
      requestStatus: 0,
      requestRemarks: props.palletRequestCustomerDetails?.checkInRemarks,
      requestComments: props.palletRequestCustomerDetails?.checkInComments,
      organizationId: orgId,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
      itemsList: [props.palletToCloseRowData[0], props.palletToMergeRowData[0]],
    };

    let resp = apiPost(url, body);
    resp.then((resp) => {
      setReload(false);
      switch (resp.response.data.code) {
        case 200:
          toast.success(
            `${resp.response.data.message} ${resp.response.data.status}`
          );
          setTimeout(() => {
            history.push("/palletmerge");
          }, 1000);
          break;
        default:
          toast.error(resp.response.data.data);
      }
    });
  };
  useEffect(() => {
    return () => {
      props.clearPalletMergeTableDatas();
    };
  }, []);
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>ADD PALLET MERGE</h6>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={12} md={12} sm={12} className="">
                      <AvForm
                        className="form"
                        // onValidSubmit={(e) => handleSubmit(e)}
                        // model={data}
                      >
                        <Row className="mb-4">
                          <Col md={6}>
                            <label>
                              Search pallet to close{" "}
                              {!checkClosePalletNumberAvailable ? (
                                <span
                                  style={{ marginLeft: "1em", color: "red" }}
                                >
                                  Pallet number is invalid
                                </span>
                              ) : props.emptyDataArrayClose ? (
                                <span
                                  style={{ marginLeft: "1em", color: "red" }}
                                >
                                  No datas available
                                </span>
                              ) : (
                                ""
                              )}
                            </label>
                            <div className="pallet-search">
                              <AvField
                                className="pallet-input"
                                name="search"
                                type="text"
                                onChange={(e) =>
                                  setPalletToCloseNumber(e.target.value)
                                }
                              ></AvField>
                              <button
                                class="btn btn-primary pallet-btn"
                                type="button"
                                onClick={() => searchPalletToClose("close")}
                              >
                                Search
                              </button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {props.palletRequestCustomerDetails
                            ?.checkInCustomerName ? (
                            <Col md={12}>
                              <strong>Customer Name : </strong>
                              <strong>
                                {
                                  props.palletRequestCustomerDetails
                                    ?.checkInCustomerName
                                }
                              </strong>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                        <Row>
                          <Col md={12}>
                            <strong>Item details to close</strong>
                          </Col>
                          <Col md={12}>
                            <DataTable
                              columns={palletCloseColumn}
                              data={props.palletToCloseRowData}
                              responsive
                            />
                            {/* <DataTable
                              columns={columns}
                              // data={props.checkOutTableData}
                              defaultSortFieldID={1}
                              pagination
                              // selectableRows
                              // selectableRowsComponent={BootyCheckbox}
                              // onSelectedRowsChange={handleChange}
                              // selectableRowsHighlight
                              responsive
                            /> */}
                          </Col>
                        </Row>
                        <Row className="mb-4 mt-4">
                          <Col md={6}>
                            <label>
                              Search pallet to merge{" "}
                              {!checkMergePalletNumberAvailable ? (
                                <span
                                  style={{ marginLeft: "1em", color: "red" }}
                                >
                                  Pallet number is invalid
                                </span>
                              ) : props.emptyDataArrayMerge ? (
                                <span
                                  style={{ marginLeft: "1em", color: "red" }}
                                >
                                  No datas available
                                </span>
                              ) : (
                                ""
                              )}
                              {/* {sameNumber ? (
                                <span
                                  style={{ marginLeft: "1em", color: "red" }}
                                >
                                  Choose a different Pallet
                                </span>
                              ) : (
                                ""
                              )} */}
                            </label>
                            <div className="pallet-search">
                              <AvField
                                className="pallet-input"
                                name="search"
                                type="text"
                                onBlur={checkPalletNumber}
                                onChange={(e) =>
                                  setPalletToMergeNumber(e.target.value)
                                }
                                disabled={
                                  props.palletToCloseRowData.length === 0
                                }
                              ></AvField>

                              <button
                                class="btn btn-primary pallet-btn"
                                type="button"
                                onClick={() => searchPalletToMerge("merge")}
                                disabled={
                                  props.palletToCloseRowData.length === 0
                                }
                              >
                                Search
                              </button>
                            </div>
                          </Col>
                          {/* <Col md={12}>
                            <button
                              class="btn btn-primary"
                              type="button"
                              onClick={searchPalletToMerge}
                            >
                              Search
                            </button>
                          </Col> */}
                        </Row>

                        <Row>
                          <Col md={12}>
                            <strong>Item details to merge</strong>
                          </Col>
                          <Col md={12}>
                            <DataTable
                              columns={palletMergeColumn}
                              data={props.palletToMergeRowData}
                              responsive
                            />
                            {/* <DataTable
                              columns={columns}
                              // data={props.checkOutTableData}
                              defaultSortFieldID={1}
                              pagination
                              // selectableRows
                              // selectableRowsComponent={BootyCheckbox}
                              // onSelectedRowsChange={handleChange}
                              // selectableRowsHighlight
                              responsive
                            /> */}
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12} className="mt-4">
                            <button
                              class="btn btn-primary"
                              type="button"
                              onClick={handleMergeItems}
                              disabled={
                                props.palletToCloseRowData.length > 0 &&
                                props.palletToMergeRowData.length > 0
                                  ? props.palletToCloseRowData[0]
                                      ?.itemCustomerId !=
                                      props.palletToMergeRowData[0]
                                        ?.itemCustomerId ||
                                    props.palletToCloseRowData[0]
                                      ?.isPalletCloseRequestGiven === 1 ||
                                    props.palletToMergeRowData[0]
                                      ?.isPalletCloseRequestGiven === 1
                                  : false || sameNumber
                              }
                            >
                              Merge Items
                            </button>
                            {props.palletToCloseRowData.length > 0 &&
                            props.palletToMergeRowData.length > 0 &&
                            props.palletToCloseRowData[0]?.itemCustomerId !=
                              props.palletToMergeRowData[0]?.itemCustomerId ? (
                              <span style={{ marginLeft: "1em", color: "red" }}>
                                You can only merge items for same customers
                              </span>
                            ) : (
                              ""
                            )}
                            {sameNumber ? (
                              <span style={{ marginLeft: "1em", color: "red" }}>
                                Choose a different Pallet
                              </span>
                            ) : (
                              ""
                            )}
                            {(props.palletToCloseRowData.length > 0 &&
                              props.palletToMergeRowData.length > 0 &&
                              props.palletToCloseRowData[0]
                                ?.isPalletCloseRequestGiven === 1) ||
                            props.palletToMergeRowData[0]
                              ?.isPalletCloseRequestGiven === 1 ? (
                              <span style={{ marginLeft: "1em", color: "red" }}>
                                Pallet request already send for one of the above
                                items
                              </span>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </AvForm>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
const mapStatetoProps = (state) => {
  const {
    palletToCloseRowData,
    palletToMergeRowData,
    palletRequestCustomerDetails,
    emptyDataArrayClose,
    emptyDataArrayMerge,
  } = state.PalletMergeTable;

  return {
    palletToCloseRowData,
    palletToMergeRowData,
    palletRequestCustomerDetails,
    emptyDataArrayClose,
    emptyDataArrayMerge,
  };
};
export default connect(mapStatetoProps, {
  addPalletToClose,
  requestPalletToMerge,
  updatePalletToMergeData,
  clearPalletMergeTableDatas,
})(AddPalletMerge);
