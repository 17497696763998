import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Input,
  ButtonGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Formik, Field } from "formik";
//i18n
import { withTranslation } from "react-i18next";
const Transactions = (props) => {
  const [transactionData, setTransactionData] = useState([]);
  useEffect(() => {
    setTransactionData(props.recent);
  }, [props]);

  return (
    <div>
      <CardTitle className="mb-4">Invoice</CardTitle>
      {transactionData.length > 0 ? (
        <Row>
          <Col md={12}>
            <table class="table table-condensed table-bordered  fixed_headers">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Warehouse</th>
                  <th>Invoice#</th>
                  <th>Order Number</th>
                  <th>Amount</th>
                  {/* <th>Status</th> */}
                </tr>
              </thead>
              <tbody>
                {transactionData
                  ? transactionData.map((items, index) => {
                      return (
                        <tr key={index}>
                          <td>{items.invoiceDate}</td>

                          <td>{items.invoiceWarehouseName}</td>
                          <td className="text-invoicenumber">
                            {items.invoiceNumber}
                          </td>
                          <td>{items.invoiceOrderNumber}</td>
                          <td>{items.invoiceTotal}</td>
                          {/* <td>{items.invoiceStatus}</td> */}
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={12} className="text-center bold">
            NO DATA FOUND
          </Col>
        </Row>
      )}
    </div>
  );
};
export default Transactions;
