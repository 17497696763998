import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
// import DataTableExtensions from "react-data-table-component-extensions";
// import "react-data-table-component-extensions/dist/index.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
// import Table from "./Table";
import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import FilterComponent from "./FilterComponent";
import { AgGridReact } from "ag-grid-react";

function PaymenytMadeList() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }

  function toPages(pages) {
    const results = [];

    for (let i = 1; i < pages; i++) {
      results.push(i);
    }

    return results;
  }
  // const conditionalRowStyles = [
  //   {
  //     when: (row) => row.toggleSelected,
  //     style: {
  //       // "&:hover": {
  //       //   backgroundColor: "gray",
  //       // },
  //       backgroundColor: "green",
  //       userSelect: "none",
  //     },
  //   },
  // ];
  // const handleRowClicked = (row) => {
  //   const updatedData = data.map((item) => {
  //     if (row.customerId !== item.customerId) {
  //       return item;
  //     }
  //     return {
  //       ...item,
  //     };
  //   });
  //   // setData(updatedData);
  // };
  const columns = [
    {
      name: "Date",
      selector: (data) => data.paymentsMadeDate,
      sortable: true,
    },
    // {
    //   name: "Branch",
    //   selector: (data) => data.customerCompanyName,
    //   sortable: true,
    // },

    {
      name: "Payment #",
      selector: (data) => (
        <div className="text-opinenumber">
          <Link to={`/singleinvoice/${data.paymentMadeId}`}>
            {data.paymentsMadeNumber}
          </Link>
        </div>
      ),
      sortable: true,
      // right: true,
    },
    {
      name: "Reference#",
      selector: (data) => data.paymentsMadeReferenceNumber,
      sortable: true,
      right: true,
    },
    {
      name: "Vendor Name",
      selector: (data) => data.recurringInvCustomerContactNumber,
      sortable: true,
      right: true,
    },
    {
      name: "Bill",
      selector: (data) => data.paymentsMadeVendorBillTotalx,
      sortable: true,
      right: true,
    },
    {
      name: "Mode",
      selector: (data) => data.paymentsMadeMode,
      sortable: true,
      right: true,
    },
  ];
  const getData = async (page) => {
    setLoading(true);
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/payments_made/org?org_id=${organisationId}&page=${page}&size=${perPage}&sort=paymentsMadeId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      console.log("pm data", resp.response.data.data.content);
      setData(resp.response.data.data.content);
      setTotalRows(resp.response.data.data.totalElements);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    getData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/payments_made/org?org_id=${organisationId}&page=${page}&size=${newPerPage}&sort=paymentsMadeId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setPerPage(newPerPage);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData(1);
  }, [filterText]);
  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };
  const subHeaderComponent = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const handleRowClick = (row) => {
    history.push(`/paymentsmadeview/${row.data.paymentsMadeId}`);
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "paymentsMadeDate",
      headerName: "Date",
      headerTooltip: "Date",
    },
    {
      field: "paymentsMadeNumber",
      headerName: "Payment #",
      headerTooltip: "Payment #",
    },
    {
      field: "paymentsMadeReferenceNumber",
      headerName: "Reference#",
      headerTooltip: "Reference#",
    },

    {
      field: "paymentsMadeVendorName",
      headerName: "Vendor Id",
      headerTooltip: "Vendor Id",
    },
    {
      cellRendererFramework: ({ data }) => {
        const paymentsMadeStatus = data.paymentsMadeStatus;
        return (
          <>
            {data.paymentsMadeStatus == 1 ? (
              <div className="text-approved">CREATED</div>
            ) : data.paymentsMadeStatus == 2 ? (
              <div className="text-draft">DRAFT</div>
            ) : data.paymentsMadeStatus == 3 ? (
              <div className="text-overdue">SEND FOR APPROVAL</div>
            ) : data.paymentsMadeStatus == 4 ? (
              <div className="text-approved">APPROVED</div>
            ) : data.paymentsMadeStatus == 5 ? (
              <div className="text-overdue">REJECTED</div>
            ) : data.paymentsMadeStatus == 6 ? (
              <div>FREEZED</div>
            ) : (
              ""
            )}
          </>
        );
      },
      headerName: "Status",
      headerTooltip: "Status",
    },
    // {
    //   field: "billDueDate",
    //   headerName: "Due Date",
    //   headerTooltip: "Due Date",
    // },
    // {
    //   field: "invoiceTotal",
    //   headerName: "Amount",
    //   headerTooltip: "Amount",
    // },
    {
      field: "paymentsMadeMode",
      headerName: "Payment Mode",
      headerTooltip: "Payment Mode",
    },
    {
      field: "paymentsMadeVendorBillTotal",
      headerName: "Total Amount",
      headerTooltip: "Total Amount",
    },
  ]);
  const gridRef = useRef();
  const defaultColDef = useMemo(
    () => ({
      filter: true,
      sortable: true,
      resizable: true,
    }),
    []
  );
  const onGridReady = useCallback(
    (params) => {
      return data;
    },
    [data]
  );
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        minWidth: 225,
        maxWidth: 225,
        width: 225,
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
        minWidth: 180,
        maxWidth: 400,
        width: 250,
      },
    ],
    position: "right",
    defaultToolPanel: "",
  };
  const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);
  return (
    <div className="App">
      <div className="card">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
        {/* {loadingData ? (
          <div className="tableLoading">
            <p>Loading Please wait...</p>
          </div>
        ) : ( */}
        {/* <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows
          subHeader
          subHeaderComponent={subHeaderComponent}
          subHeaderAlign="left"
          onRowClicked={handleRowClick}
          highlightOnHover
          pointerOnHover
        /> */}
        <div className="ag-theme-alpine" style={gridStyle}>
          {" "}
          <AgGridReact
            columnDefs={columnDefs}
            rowData={filteredItems}
            ref={gridRef}
            pagination
            grid
            onRowClicked={handleRowClick}
            sideBar={sideBar}
            // onPaginationChanged={handlePageChange}
            animateRows={true}
            defaultColDef={defaultColDef}
            subHeaderComponent={subHeaderComponent}
            onGridReady={onGridReady}
          />
        </div>
        {/* )} */}
      </div>
    </div>
  );
}
export default PaymenytMadeList;
