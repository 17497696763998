import React from "react";
import { Container, Row, Col, Modal } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
function EditReportingTag(props) {
  return (
    <Modal className="export-modal" isOpen={props.openModal}>
      <Row>
        <Col md={12} className="modal-head">
          <div>
            <h5>Edit Reporting Tag</h5>
          </div>
          <div onClick={() => props.setOpenModel(false)}>
            <i class="bx bx-x"></i>
          </div>
        </Col>
      </Row>
      <hr />
      <AvForm className="form" onValidSubmit={() => props.handleEdit(props.id)}>
        <Row className="d-flex align-items-center mb-3">
          <Col md={6}>
            <label style={{ whiteSpace: "pre" }}>Reporting Tag Name</label>
          </Col>
          <Col md={6}>
            <AvField
              name="tagName"
              type="text"
              placeholder="Enter Reporting Tag Name"
              onChange={(e) => props.handle(e)}
              value={props.editValues.tagName}
              required
            ></AvField>
          </Col>
        </Row>
        <Row className="d-flex align-items-center mb-3">
          <Col md={6}>
            <label style={{ whiteSpace: "pre" }}>Description</label>
          </Col>
          <Col md={6}>
            <AvField
              name="tagDescription"
              placeholder="Enter Description"
              type="text"
              onChange={(e) => props.handle(e)}
              value={props.editValues.tagDescription}
              required
            ></AvField>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <button type="submit" class="exportuserbtn">
              Update Reporting Info
            </button>
          </Col>
        </Row>
      </AvForm>
    </Modal>
  );
}

export default EditReportingTag;
