import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import classnames from "classnames";
import Expense from "./expense";
import BottomBar from "./bottomBar";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

// Pages Component
import Receipt from "./receipt";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiPost } from "../../components/config/apiConfig";
import loader from "../../assets/loader/load.gif";
// import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";

const AddExpenses = (props) => {
  //
  const { expenseRender, data } = Expense();
  //
  const history = useHistory();
  const [modal, setmodal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [reload, setReload] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  function handleSubmit(e) {
    e.preventDefault();
    setReload(true);
    let token = localStorage.getItem("accessToken");
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/expenses";

    let body = {
      expenseDate: data.date,
      expenseAccountGroupId: parseInt(data.expenseAccount),
      expenseType: data.expenseType,
      expenseSac: data.sac,
      expenseHsn: data.hsnCode,
      expenseIsItemized: 1,
      expenseAmount: parseInt(data.expenseAmount),
      expensePaidThroughAccountId: parseFloat(data.paidThrough),
      expenseVendorId: parseInt(0),
      expenseGstTreatmentId: parseInt(data.gstTreatment),
      expenseSourceOfSupplyId: parseInt(data.sos),
      expenseDestinationOfSupplyId: parseInt(data.dos),
      expenseIsRreverseChargeApplicable: parseInt(data.reverseCharge),
      expenseTaxId: parseInt(0),
      expenseTaxPercent: parseInt(data.tax),
      expenseInvoiceNumber: data.invoice,
      expenseNotes: data.notes,
      expenseCustomerId: parseInt(data.customer),
      expenseVendorName: data.expenseVendorId,
      expenseVendorContactNumber: "",
      expenseCustomerName: data.customer,
      expenseCustomerContactNumber: "",
      expenseReportingTag: data.reportingtags,
      organizationId: 1,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
      expenseItemsList: [
        {
          expItemsAccountGroupId: parseInt(0),
          expItemsNotes: "",
          expItemsTaxId: parseInt(0),
          expItemsTaxPercent: parseInt(0),
          expItemsAmount: parseInt(0),
          addedByUser: 1,
          editByUser: 1,
        },
      ],
    };
    let resp = apiPost(url, body);
    resp.then((resp) => {
      console.log("expense add", resp);
      setReload(false);

      switch (resp?.response?.data?.code) {
        case 200:
          toast.success(resp?.response?.data?.data);
          setTimeout(() => {
            // history.push("/customerlist");
            history.push("/expenses");
          }, 1000);
          break;

        default:
          toast.error(resp.response.data.data);
      }
    });
  }
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>Dashboard</h6>
            </Col>
          </Row>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Record Expenses")}
            breadcrumbItem={props.t("Invoice")}
          />

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className="form"
                    onValidSubmit={(e) => handleSubmit(e)}
                    model={data}
                  >
                    <Row>
                      <Col md={8} className="formLeft">
                        {/* <Expense /> */}
                        {expenseRender}
                      </Col>
                      <Col md={4}>
                        {/* <Receipt /> */}
                        <Row>
                          <Col md={12}>
                            <h6>Receipt</h6>
                          </Col>
                          <Col md={12}>
                            <div className="input-file">
                              <label
                                for="file-upload"
                                class="custom-file-upload"
                              >
                                <i class="bx bx-cloud-upload"></i> Upload
                                Receipt
                              </label>
                              <input id="file-upload" type="file" />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {/* <BottomBar /> */}
                    <div className="bottom-bar">
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={4}>
                              <Link to="expenseslist">
                                <button
                                  type="button"
                                  className="btn btn-left ml-3"
                                >
                                  Cancel
                                </button>
                              </Link>
                            </Col>
                            <Col md={4}>
                              <button
                                type="button"
                                // onClick={clearDatas}
                                className="btn btn-left"
                              >
                                Clear
                              </button>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4} className="printView">
                          <span className="print">Print or Preview</span>
                        </Col>
                        <Col md={4}>
                          <button
                            type="submit"
                            className="btn btn-right float-right mr-3"
                          >
                            {reload ? (
                              <img
                                className="loader"
                                src={loader}
                                alt="loading..."
                              />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(AddExpenses);
