import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { BaseUrl } from "../../components/config/BaseUrl";
import { Link, useHistory } from "react-router-dom";
import { apiGet, apiPost, apiPut } from "../../components/config/apiConfig";
import classnames from "classnames";
import BillingAddress from "./billingDetails";
import loader from "../../assets/loader/load.gif";
import AddGoodsTransfer from "./addGoodstransfer";
import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";
import AddTable from "./addTable";
import BottomBar from "./bottomBar";
import { connect } from "react-redux";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

// Pages Component
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
import {
  getCurrentSelectedCustomer,
  getCurrentSelectedItem,
  addTableData,
  updateRowTotal,
  calculateShippingCharge,
  calculateAdjustmentCharge,
  deleteTableRow,
  populateTableDataGt,
  clearCustomerData,
  clearTableData,
  addTableDataGt,
  updateRowTotalGt,
  // getTableData,
} from "../../store/actions";
import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } fro m "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRef } from "react";
import { addDays, addMonths, endOfMonth, format, setDate } from "date-fns";
import { useParams } from "react-router-dom";

const GoodsTransfer = (props) => {
  const form = useRef(null);
  const history = useHistory();
  var formType = props.match.params.type;
  var id = props.match.params.id;
  const [itemData, setItemData] = useState([]);
  const [transferdata, settransferata] = useState({});
  const [rowsData, setRowsData] = useState([
    {
      id: 0,
      itemId: 0,
      itemName: "",
      itemDescription: "",
      sourceStock: "",
      destinationStock: "",
      transferQuantity: "",
    },
  ]);
  const [state, setState] = useState([]);
  const [gstTreatment, setgsttreatment] = useState([]);
  const [paymentterms, setpaymentterms] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [customerList, setCustomerList] = useState([]);
  const [reload, setReload] = useState(false);
  const [billingaddressid, setbillingaddressid] = useState();
  const [shippingaddressid, setshippingaddressid] = useState();
  const [checkInWarehouseId, setcheckInWarehouseId] = useState({
    id: 0,
    name: "",
  });
  var today = new Date();
  var formatedDate =
    today.getMonth() + 1 + "-" + today.getDate() + "-" + today.getFullYear();
  const clearForm = () => {
    form.current.reset(); //this will reset all the inputs in the form
    props.clearCustomerData();
    props.clearTableData();
  };
  const currentDate = format(new Date(), "yyyy-MM-dd");

  const [data, setData] = useState({
    transferDate: "",
    transferTime: "",
    transferReason: "",
    sourceWarehouseId: 0,
    destinationWarehouseId: 0,
  });

  const addTableRows = () => {
    const rowsInput = {
      itemId: 0,
      //itemName: "",
      itemDescription: "",
      sourceStock: "",
      destinationStock: "",
      transferQuantity: "",
    };
    setRowsData([...rowsData, rowsInput]);
    props.addTableDataGt();
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    props.deleteTableRow(index);
  };

  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;

    props.getCurrentSelectedItem(value);

    // props.updateRowTotal(index, evnt);
    setRowsData(rowsInput);
    console.log("table data", rowsData);
    // addTableRows();
  };

  const onHandleBlur = (index, e) => {};
  function handle(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }
  function handleDueDate(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    updateDueDate();
  }
  function handleCustomerChange(e) {
    props.getCurrentSelectedCustomer(e.target.value);
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    updateDueDate();
    // setData({
    //   ...data,
    //   invoicePaymentTermsId: props.selectedCustomer?.customerPaymentTermsId,
    // });
  }
  const handleChangeWarehouse = (e) => {
    setcheckInWarehouseId({
      id: e.value,
      name: e.label,
    });
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  function getState() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/states/1`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setState(resp.response.data.data);
      }
    });
  }
  function getGstTreatment() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/gst_treatments`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setgsttreatment(resp.response.data.data);
      }
    });
  }
  function getPaymentTerms() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/payment_terms`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setpaymentterms(resp.response.data.data);
      }
    });
  }
  function getWarehouseList() {
    let orgId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/warehouse/org/${orgId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setWarehouseList(resp.response.data.data);
      }
    });
  }
  function createGoodsTransfer() {
    setReload(true);
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/goods_transfer";

    let body = {
      transferDate: data.transferDate,
      transferTime: data.transferTime,
      transferReason: data.transferReason,
      sourceWarehouseId: parseInt(data.sourceWarehouseId),
      destinationWarehouseId: parseInt(data.destinationWarehouseId),
      organizationId: orgId,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
      itemsList: props.tableRowData,
    };

    let resp = apiPost(url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            setReload(false);
            history.push("/view-goodstransfer");
          }, 1000);
          break;

        default:
          setReload(false);
          toast.error(resp.response.data.data);
      }
    });
  }
  function updateGoodsTransfer() {
    setReload(true);
    let url = BaseUrl.apiUrl.baseUrl + `api/v1/goods_transfer/${id}`;

    let body = {
      transferDate: data.transferDate,
      transferTime: data.transferTime,
      transferReason: data.transferReason,
      sourceWarehouseId: parseInt(data.sourceWarehouseId),
      destinationWarehouseId: parseInt(data.destinationWarehouseId),
      itemsList: props.tableRowDataGt,
      editByUser: 1,
    };
    let resp = apiPut(url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            setReload(false);
            history.push(`/singlegoodstransfer/${id}`);
          }, 1000);
          break;
        case 500:
          setReload(false);
          toast.error(resp.response.data.status);
          break;
        default:
          setReload(false);
          toast.error(resp.response.data.status);
      }
    });
  }
  const updateDueDate = (e) => {
    if (props.selectedCustomer?.customerPaymentTermsId) {
      let startDate = new Date(data.invoiceDate);
      let date = e?.target?.value.length
        ? e?.target?.value
        : data.invoicePaymentTermsId.length
        ? data.invoicePaymentTermsId
        : props.selectedCustomer?.customerPaymentTermsId
        ? props.selectedCustomer?.customerPaymentTermsId
        : data.invoicePaymentTermsId;
      let term =
        date == "1"
          ? 15
          : date == "2"
          ? 30
          : date == "3"
          ? 45
          : date == "4"
          ? 60
          : "";
      if (date == "8") return;
      let updatedValue = {};
      updatedValue = {
        invoiceDueDate: format(
          date == "5"
            ? endOfMonth(startDate)
            : date == "6"
            ? endOfMonth(addMonths(startDate, 1))
            : addDays(startDate, term),
          "yyyy-MM-dd"
        ),
      };
      setData((prevData) => ({
        ...prevData,
        ...updatedValue,
      }));
    }
  };
  useEffect(() => {
    if (props.selectedCustomer?.customerPaymentTermsId) {
      updateDueDate();
    }
  }, [props.selectedCustomer?.customerPaymentTermsId, data.invoiceDate]);

  function handleSubmit(e) {
    if (formType == "add") {
      createGoodsTransfer();
    } else if (formType == "edit") {
      updateGoodsTransfer();
    }
  }

  function getItemData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setItemData(resp.response.data.data);
    });
  }
  function getData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setCustomerList(resp.response.data.data);
    });
  }
  function getGoodsTransferDataById() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/goods_transfer/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      var datas = resp.response.data.data;
      console.log("trs data", datas.itemsList);
      setData(resp.response.data.data);
      // setData((prevData) =>{

      // })
      settransferata(resp.response.data.data);
      props.populateTableDataGt(
        /*datas.invoiceSubTotal,
        datas.invoiceShippigCharges,
        datas.invoiceAdjustment,
        datas.invoiceTotal,*/
        resp.response.data.data.itemsList
      );
      setRowsData(
        resp.response.data.data.itemsList.map((items) => {
          return {
            id: items.id,
            itemId: items.itemId,
            itemName: items.itemName,
            itemDescription: items.itemDescription,
            sourceStock: items.sourceStock,
            destinationStock: items.destinationStock,
            transferQuantity: items.transferQuantity,
          };
        })
      );
      //props.getCurrentSelectedCustomer(datas.invoiceCustomerId);
    });
  }

  useEffect(() => {
    console.log("data change in gt", data);
  }, [data]);
  const warehouse =
    props.selectedCustomer.warehouseList &&
    props.selectedCustomer.warehouseList.map((item) => {
      return {
        value: item.warehouseId,
        label: item.warehouseName,
      };
    });
  useEffect(() => {
    if (formType === "edit") {
      if (props.selectedCustomer.warehouseList?.length) {
        setcheckInWarehouseId({
          id: props.selectedCustomer.warehouseList[0].warehouseId,
          name: props.selectedCustomer.warehouseList[0].warehouseName,
        });
      }
    }
  }, [props.selectedCustomer]);

  // const handleQuantityZero = (e) => {
  //   if (e.target.value == 0) {
  //     toast.error(" Quantity is Empty !", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: false,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // };
  // const handleRateZero = (e) => {
  //   if (e.target.value == 0) {
  //     toast.error(" Rate is Empty !", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: false,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // };
  useEffect(() => {
    if (formType == "edit") {
      getGoodsTransferDataById();
    }
    getData();
    getState();
    getGstTreatment();
    getItemData();
    getPaymentTerms();
    getWarehouseList();
    return () => {
      form.current.reset(); //this will reset all the inputs in the form
      props.clearCustomerData();
      props.clearTableData();
    };
  }, []);
  useEffect(() => {
    if (formType == "add") {
      props.addTableDataGt();
    }
  }, []);

  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>
                {formType == "add"
                  ? "Add Goods Transfer"
                  : formType == "edit"
                  ? "Edit Goods Transfer"
                  : ""}
              </h6>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className="form"
                    onValidSubmit={(e) => handleSubmit(e)}
                    ref={form}
                  >
                    <Row>
                      <Col md={8} className="formLeft">
                        <Row className="mb-3">
                          <Col md={4}>
                            <label>Transfer Date *</label>
                            <AvField
                              name="transferDate"
                              type="date"
                              placeholder="Select Date"
                              value={data.transferDate}
                              onChange={(e) => handle(e)}
                              required
                            ></AvField>
                          </Col>
                          <Col md={4}>
                            <label>Transfer Time *</label>
                            <AvField
                              name="transferTime"
                              type="time"
                              placeholder="Select Time"
                              value={data.transferTime}
                              onChange={(e) => handle(e)}
                              required
                            ></AvField>
                          </Col>
                        </Row>
                        <hr />
                        <Row className="mb-4">
                          <Col md={4}>
                            <label>Transfer Reason *</label>
                            <AvField
                              name="transferReason"
                              type="text"
                              placeholder="Transfer Reason"
                              value={data.transferReason}
                              onChange={(e) => handle(e)}
                              required
                            ></AvField>
                          </Col>
                          <Col md={4}>
                            <label>Source Warehouse</label>
                            <AvField
                              name="sourceWarehouseId"
                              type="select"
                              placeholder="&#x260E; Select Source Warehouse"
                              value={data.sourceWarehouseId}
                              onChange={(e) => {
                                handle(e);
                              }}
                            >
                              <option value="" selected disabled></option>
                              {warehouseList.map((wh) => (
                                <option value={wh.warehouseId}>
                                  {wh.warehouseName}
                                </option>
                              ))}
                            </AvField>
                          </Col>
                          <Col md={4}>
                            <label>Destination Warehouse</label>
                            <AvField
                              name="destinationWarehouseId"
                              type="select"
                              placeholder="&#x260E; Select Source Warehouse"
                              value={data.destinationWarehouseId}
                              onChange={(e) => {
                                handle(e);
                              }}
                            >
                              <option value="" selected disabled></option>
                              {warehouseList.map((wh) => (
                                <option value={wh.warehouseId}>
                                  {wh.warehouseName}
                                </option>
                              ))}
                            </AvField>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr />
                    <div className="row clearfix">
                      <div className="col-md-12 column">
                        <table
                          className="table table-bordered invoice-table"
                          id="tab_logic"
                        >
                          <thead>
                            <tr>
                              <th className="text-left"> ITEM DETAILS</th>
                              <th className="text-left"> DESCRIPTION</th>
                              <th className="text-right"> SOURCE STOCK</th>
                              <th className="text-left"> DESTINATION STOCK</th>
                              <th className="text-right">
                                {" "}
                                TRANSFER QUANTITY{" "}
                              </th>

                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.tableRowDataGt?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <AvField
                                      type="text"
                                      name="itemName"
                                      value={
                                        props.tableRowDataGt[index]
                                          ? props.tableRowDataGt[index][
                                              "itemName"
                                            ]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        props.updateRowTotalGt(index, e)
                                      }
                                      placeholder="Eg: Item Name"
                                      required
                                    ></AvField>
                                    {/* <AvField
                                      type="select"
                                      required
                                      className="table-select"
                                      name="itemId"
                                      onChange={(evnt) =>
                                        handleTableChange(index, evnt)
                                      }
                                      value={
                                        props.tableRowData[index]
                                          ? props.tableRowData[index]["itemId"]
                                          : ""
                                      }
                                    >
                                      <option value="" selected disabled>
                                        Select Items
                                      </option>
                                      {itemData.map((items) => (
                                        <option value={items.itemId}>
                                          {items.itemName}
                                        </option>
                                      ))}
                                    </AvField> */}
                                  </td>
                                  <td>
                                    <AvField
                                      type="text"
                                      name="itemDescription"
                                      value={
                                        props.tableRowDataGt[index]
                                          ? props.tableRowDataGt[index][
                                              "itemDescription"
                                            ]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        props.updateRowTotalGt(index, e)
                                      }
                                      // onBlur={(e) => handleQuantityZero(e)}
                                      // onClick={(e) => onHandleBlur(index, e)}
                                      className="form-control table-input"
                                      placeholder="Description"
                                      required
                                    ></AvField>
                                  </td>

                                  <td>
                                    <AvField
                                      type="text"
                                      name="sourceStock"
                                      value={
                                        props.tableRowDataGt[index]
                                          ? props.tableRowDataGt[index][
                                              "sourceStock"
                                            ]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        props.updateRowTotalGt(index, e)
                                      }
                                      // onBlur={(e) => handleRateZero(e)}
                                      className="form-control"
                                      placeholder="Source Stock"
                                      required
                                    ></AvField>
                                  </td>
                                  <td>
                                    <AvField
                                      type="text"
                                      name="destinationStock"
                                      value={
                                        props.tableRowDataGt[index]
                                          ? props.tableRowDataGt[index][
                                              "destinationStock"
                                            ]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        props.updateRowTotalGt(index, e)
                                      }
                                      // onBlur={(e) => handleRateZero(e)}
                                      className="form-control"
                                      placeholder="Destination Stock"
                                      required
                                    ></AvField>
                                  </td>
                                  <td>
                                    <AvField
                                      type="text"
                                      name="transferQuantity"
                                      value={
                                        props.tableRowDataGt[index]
                                          ? props.tableRowDataGt[index][
                                              "transferQuantity"
                                            ]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        props.updateRowTotalGt(index, e)
                                      }
                                      // onBlur={(e) => handleRateZero(e)}
                                      className="form-control"
                                      placeholder="Transfer Quantity"
                                      required
                                    ></AvField>
                                  </td>

                                  {/* <td>
                                    <select
                                      className="table-select"
                                      name="invItemTaxPercent"
                                      id="tax"
                                      disabled
                                      value={
                                        props.tableRowData[index]
                                          ? props.tableRowData[index][
                                              "invItemTaxPercent"
                                            ]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        props.updateRowTotal(index, e)
                                      }
                                    >
                                      <option value="%" disabled selected>
                                        Select a tax
                                      </option>
                                    </select>
                                  </td> */}

                                  <td className="text-center" width="10px">
                                    <span
                                      className=" btn-outline-danger"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      onClick={(e) => deleteTableRows(index, e)}
                                    >
                                      <i class="bx bx-trash"></i>
                                    </span>
                                    {/* <span
                                      className="btn btn-outline-danger"
                                      onClick={(e) => deleteTableRows(index, e)}
                                    >
                                      x
                                    </span> */}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <Row>
                          <Col md={6} className="table-left">
                            <div>
                              <button
                                type="button"
                                onClick={addTableRows}
                                className="btn btn-default pull-left add-tablerow"
                              >
                                <i class="bx bxs-plus-circle"></i>
                                <span>Add another line</span>
                              </button>
                              {/* <button
                      onClick={this.handleRemoveRow}
                      className="pull-right btn btn-default table-btn"
                    >
                      Clear all rows
                    </button> */}
                            </div>
                            <div className="customernotes">
                              <label>Customer Notes</label>
                              <AvField
                                name="invoiceCustomerNotes"
                                type="textarea"
                                value={data.invoiceCustomerNotes}
                                onChange={(e) => handle(e)}
                              ></AvField>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="table-right">
                              {/* <Row>
                      <Col md={4}>
                        <div>
                          <span className="detail-head">Tcs</span>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="input-tooltip">
                          <select name="selecttax" placeholder="select a tax">
                            <option value="%">Select a Tax</option>
                            <option value="rs">Rs.</option>
                          </select>
                          <i class="bx bx-help-circle"></i>
                        </div>
                      </Col>
                      <Col md={3} className="text-right">
                        <div>
                          <span>0.00</span>
                        </div>
                      </Col>
                    </Row> */}
                            </div>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          {/*<Col md={6}>
                            <label>Terms & Conditions</label>
                            <AvField
                              name="invoiceTermsAndConditions"
                              type="textarea"
                              value={data.invoiceTermsAndConditions}
                              onChange={(e) => handle(e)}
                              placeholder="Enter your terms and conditons of your business to be displayed in your transactions"
                            ></AvField>
                  </Col>*/}
                          {/* <Col md={2}>
                            <label>Attach file(s) to invoice</label>
                            <AvField
                              name="branch"
                              type="file"
                              value={data.branch}
                              onChange={(e) => handle(e)}
                            ></AvField>
                          </Col> */}
                        </Row>
                      </div>
                    </div>
                    <div className="bottom-bar">
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={4}>
                              <Link
                                to={
                                  formType == "add"
                                    ? "/view-goodstransfer"
                                    : formType == "edit"
                                    ? `/singlegoodstransfer/${id}`
                                    : ""
                                }
                              >
                                <button
                                  type="button"
                                  className="btn btn-left ml-3"
                                >
                                  Cancel
                                </button>
                              </Link>
                            </Col>
                            {formType == "add" ? (
                              <Col md={4}>
                                <button
                                  type="button"
                                  onClick={clearForm}
                                  className="btn btn-left"
                                >
                                  Clear
                                </button>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Col>
                        {/* <Col md={4} className="printView">
                          <span className="print">Print or Preview</span>
                        </Col> */}
                        <Col md={8}>
                          <button
                            type="submit"
                            className="btn btn-right float-right mr-3"
                            disabled={reload}
                          >
                            {reload ? (
                              <img
                                className="loader"
                                src={loader}
                                alt="loading..."
                              />
                            ) : formType == "add" ? (
                              "Save"
                            ) : formType == "edit" ? (
                              "Update"
                            ) : (
                              ""
                            )}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { selectedCustomerId, selectedCustomer } = state.Layout;
  const { selectedItemId, selectedItem } = state.ItemDetails;
  const { tableRowData, subTotal, shippingCharge, adjustment, total } =
    state.TableData;
  const { tableRowDataGt } = state.TableDataGt;
  return {
    selectedCustomerId,
    selectedCustomer,
    selectedItemId,
    selectedItem,
    tableRowData,
    tableRowDataGt,
    subTotal,
    shippingCharge,
    adjustment,
    total,
    // dataByIndex,
  };
};
export default connect(mapStatetoProps, {
  getCurrentSelectedCustomer,
  getCurrentSelectedItem,
  addTableData,
  addTableDataGt,
  updateRowTotal,
  updateRowTotalGt,
  calculateShippingCharge,
  calculateAdjustmentCharge,
  deleteTableRow,
  populateTableDataGt,
  clearCustomerData,
  clearTableData,
  // getTableData,
})(GoodsTransfer);
