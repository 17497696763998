import {
  SET_DEFAULT_ITEM,
  DELETE_ITEMS,
  UPDATE_ITEMS_TABLE,
} from "./actionTypes";

export const setDefaultItems = () => ({
  type: SET_DEFAULT_ITEM,
});

export const deleteItems = (index) => ({
  type: DELETE_ITEMS,
  payload: index,
});
export const updateTableItems = (value, index) => ({
  type: UPDATE_ITEMS_TABLE,
  payload: { value, index },
});
