import React, { useMemo, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
// import DataTableExtensions from "react-data-table-component-extensions";
// import "react-data-table-component-extensions/dist/index.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
// import Table from "./Table";
import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import FilterComponent from "./FilterComponent";

function PaymenytRecievedList() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }

  function toPages(pages) {
    const results = [];

    for (let i = 1; i < pages; i++) {
      results.push(i);
    }

    return results;
  }
  // const conditionalRowStyles = [
  //   {
  //     when: (row) => row.toggleSelected,
  //     style: {
  //       // "&:hover": {
  //       //   backgroundColor: "gray",
  //       // },
  //       backgroundColor: "green",
  //       userSelect: "none",
  //     },
  //   },
  // ];
  // const handleRowClicked = (row) => {
  //   const updatedData = data.map((item) => {
  //     if (row.customerId !== item.customerId) {
  //       return item;
  //     }
  //     return {
  //       ...item,
  //     };
  //   });
  //   // setData(updatedData);
  // };
  const columns = [
    {
      name: "Payments Received Date",
      selector: (data) => data.paymentsRcvDate,
      sortable: true,
    },
    // {
    //   name: "Branch",
    //   selector: (data) => data.customerCompanyName,
    //   sortable: true,
    // },

    {
      name: "Payment #",
      selector: (data) => (
        <div className="text-opinenumber">
          <Link to={`/paymentsrecievedView/${data.paymentsRcvId}`}>
            {data.paymentsRcvNumber}
          </Link>
        </div>
      ),
      sortable: true,
      // right: true,
    },
    {
      name: "Reference #",
      selector: (data) => data.paymentsRcvReferenceNumber,
      sortable: true,
      // right: true,
    },
    {
      name: "Customer Name",
      selector: (data) => data.paymentsRcvCustomerName,
      sortable: true,
      right: true,
    },

    {
      name: "Mode",
      selector: (data) => data.paymentsRcvMode,
      sortable: true,
      right: true,
    },

    // {
    //   name: "Balance Due",
    //   selector: (data) => "₹ 0.0",
    //   sortable: true,
    //   // right: true,
    // },
  ];
  const getData = async (page) => {
    setLoading(true);
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/payments_received/org?org_id=${organisationId}&page=${page}&size=${perPage}&sort=paymentsRcvId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setTotalRows(resp.response.data.data.totalElements);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    getData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/payments_received/org?org_id=${organisationId}&page=${page}&size=${newPerPage}&sort=paymentsRcvId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setPerPage(newPerPage);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData(1);
  }, [filterText]);
  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const handleRowClick = (row) => {
    history.push(`/paymentsrecievedView/${row.paymentsRcvId}`);
  };
  return (
    <div className="App">
      <div className="card">
        {/* {loadingData ? (
          <div className="tableLoading">
            <p>Loading Please wait...</p>
          </div>
        ) : ( */}
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows
          subHeader
          subHeaderComponent={subHeaderComponent}
          subHeaderAlign="left"
          onRowClicked={handleRowClick}
          highlightOnHover
          pointerOnHover
        />
        {/* )} */}
      </div>
    </div>
  );
}
export default PaymenytRecievedList;
