import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
} from "reactstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import classnames from "classnames";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Formik, Field } from "formik";
//i18n
import { withNamespaces } from "react-i18next";
import OverviewChart from "./overviewChart";
import { apiGet } from "../../../components/config/apiConfig";
import { BaseUrl } from "../../../components/config/BaseUrl";
import moment from "moment";
const Overview = (props) => {
  const [customerData, setCustomerData] = useState({});
  const [paymentTerm, setPaymentTerm] = useState({});

  function getPaymentTermsById() {
    var paymentTermsid = customerData.customerPaymentTermsId;
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/master/payment_terms/${paymentTermsid}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setPaymentTerm(resp.response.data.data);
      }
    });
  }
  useEffect(() => {
    if (!customerData.customerPaymentTermsId == "") {
      getPaymentTermsById();
    }
  }, [customerData]);
  useEffect(() => {
    setCustomerData(props.data);
  }, [props]);
  return (
    <React.Fragment>
      <Row>
        <Col md={4} className="formLeft">
          <div className="overviewLeft">
            <div className="custname ">
              <span>{customerData.customerDisplayName}</span>
              <Link to={`/customer/edit/${customerData.customerId}`}>
                Edit Profile
              </Link>
            </div>
            <hr />
            <Row>
              <Col md={3}>
                <div className="letterImage">
                  <span className="text-uppercase">
                    {customerData.customerDisplayName
                      ? customerData.customerDisplayName.charAt(0)
                      : "N"}
                  </span>
                </div>
              </Col>
              <Col md={9}>
                <div className="name">
                  <strong>
                    {" "}
                    {customerData.customerSalutation}{" "}
                    {customerData.customerFirstname}{" "}
                    {customerData.customerLastname}
                  </strong>
                </div>
                <div className="name">{customerData.customerEmail}</div>
                <div className="name">{customerData.customerMobile}</div>
                <div className="name">{customerData.customerWorkPhone}</div>
              </Col>
            </Row>
            <div className="head">
              <span>ADDRESS</span>
              <i class="bx bx-chevron-down "></i>
            </div>
            <hr />
            {customerData?.customerAddresses?.map((address) => {
              return (
                <div className="address">
                  <span className="bold">
                    {address.addressType == "1"
                      ? "Billing Address"
                      : "Shipping Address"}
                  </span>

                  <div className="addaddress">
                    <span>{address.addressAttention}</span>
                    <span>{address.addressLine1}</span>
                    <span>{address.addressLine2}</span>
                    <span>{address.addressPhone}</span>
                    <span>
                      {address.addressCity} {address.addressState}
                    </span>
                    <span> {address.addressCountryName}</span>
                    <span>{address.addressZipCode}</span>

                    <br />
                  </div>
                </div>
              );
            })}
            <Row>
              <Col md={12}>
                <div className="warehouse-sect">
                  <span>WAREHOUSES</span>
                  <i class="bx bx-chevron-down "></i>
                  <hr />
                  <div className="warehouse-names">
                    {customerData.warehouseList?.map((item) => {
                      return (
                        <div className="warehouses">{item.warehouseName}</div>
                      );
                    })}
                  </div>
                </div>
              </Col>
            </Row>
            <div className="head">
              <span>OTHER DETAILS</span>
              <i class="bx bx-chevron-down "></i>
            </div>
            <hr />
            <div className="otherdetails">
              <div className="jsc">
                <span>Customer Number</span>
                <div className="align-center">
                  <span>{customerData.customerNumber}</span>
                </div>
              </div>

              <div className="jsc">
                <span>Currency</span>
                <div className="align-center">
                  <span>{customerData.customerCurrency}</span>
                </div>
              </div>
              <div className="jsc">
                <span>Credit Limit</span>
                <div className="align-center">
                  <span>{customerData.customerCreditLimit}</span>
                </div>
              </div>
              <div className="jsc">
                <span>Rate / CBM</span>
                <div className="align-center">
                  <span>{customerData.customerFixedQuoteRate}</span>
                </div>
              </div>
              <div className="jsc">
                <span>Cost Rate</span>
                <div className="align-center">
                  <span>{customerData.customerCostRate}</span>
                </div>
              </div>
              <div className="jsc">
                <span>Opening Balance</span>
                <div className="align-center">
                  <span>{customerData.customerOpeningBalance}</span>
                </div>
              </div>
              <div className="jsc">
                <span>Handling In</span>
                <div className="align-center">
                  <span>{customerData.customerHandlingInCharge}</span>
                </div>
              </div>
              <div className="jsc">
                <span>Handling Out</span>
                <div className="align-center">
                  <span>{customerData.customerHandlingOutCharge}</span>
                </div>
              </div>
              <div className="jsc">
                <span>Website</span>
                <div className="align-center">
                  <span>{customerData.customerWebsite}</span>
                </div>
              </div>
              <div className="jsc">
                <span>Facebook</span>
                <div className="align-center">
                  <span>{customerData.customerFacebook}</span>
                </div>
              </div>
              <div className="jsc">
                <span>Twitter</span>
                <div className="align-center">
                  <span>{customerData.customerTwitter}</span>
                </div>
              </div>
              <div className="jsc">
                <span>Skype</span>
                <div className="align-center">
                  <span>{customerData.customerSkypeName}</span>
                </div>
              </div>
              <div className="jsc">
                <span>Payment Terms</span>
                <div className="align-center">
                  <span>{paymentTerm.paymentTermName}</span>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={8} className="formRight">
          <Row className="overviewRight">
            <Col md={12}>
              <div className="overviewCompanyDetails">
                <div className="companyName">
                  {customerData.customerCompanyName
                    ? customerData.customerCompanyName
                    : "No Company Found"}
                </div>
                <div className="aboutCompany">
                  <span>Designation</span>
                  <div className="aboutCompanytext">
                    {customerData.customerDesignation}
                  </div>
                </div>
                <div className="aboutCompany">
                  <span>Department</span>
                  <div className="aboutCompanytext">
                    {customerData.customerDepartment}
                  </div>
                </div>
              </div>
            </Col>
            {/* <Col className="align-center">
              <div>
                <h1>{customerData.customerCompanyName}</h1>
              </div>
            </Col>
            <hr />
            <Col className="align-center">
              <div>
                <span>Designation</span>
                <h6>{customerData.customerDesignation}</h6>
              </div>
            </Col>
            <hr />
            <Col className="align-center">
              <div>
                <span>Department</span>
                <h6>{customerData.customerDepartment}</h6>
              </div>
            </Col> */}
          </Row>
          <hr />
          <Row className="ovRightTable">
            <div className="ovRightTablediv">
              <div className="ovTableTopbar">
                <span className="ovTableTopbarSpan"></span>
                <div className="ovTableIncomeRightside">
                  <div className="Dropdown">
                    <span className="dropdownToggle"></span>
                    <div></div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div>
                <OverviewChart chartData={props.chart} />
              </div>
            </div>
          </Row>
          {customerData.responseList?.length > 0 ? (
            <Row className="ovRightTable">
              <Col md={12} className="mt-5 container">
                <CardTitle className="mb-4">Customer Mail History</CardTitle>
                <ul className="verti-timeline list-unstyled">
                  {customerData.responseList?.map((items, index) => {
                    return (
                      <li className="event-list" key={index}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18" />
                        </div>
                        <div className="d-flex">
                          <div className="me-3">
                            <h5 className="font-size-14">
                              {/* {items.mailSentOn} */}
                              {moment(items.mailSentOn).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                              <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                            </h5>
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              {items.mailType} |{" "}
                              <span
                                className={
                                  items.mailStatus === "Success"
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {items.mailResponse}
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default Overview;
