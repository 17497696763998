import React, { useState } from "react";
import { connect } from "react-redux";
import { fetchDashboardData } from "../../store/actions";
function Header(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState("1 Month");

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleSelectRange = (range, payload) => {
    props.fetchDashboardData(payload);
    setSelectedRange(range);
    setIsOpen(false); // Close the dropdown after selecting a range
  };
  return (
    <div className="page-header">
      <span className="title">Dashboard</span>
      <div className="header-btns">
        <div className="date-arrange" onClick={handleToggleDropdown}>
          <span>Date Arrange</span>
          <span>
            <i class="bx bxs-calendar"></i>
            {selectedRange}
            <i class="bx bxs-down-arrow"></i>
          </span>
        </div>
        {isOpen && (
          <div className="dropdown">
            <div onClick={() => handleSelectRange("1 Month", "1m")}>
              1 Month
            </div>
            <div onClick={() => handleSelectRange("6 Months", "6m")}>
              6 Months
            </div>
            <div onClick={() => handleSelectRange("1 Year", "1y")}>1 Year</div>
            {/* <div onClick={() => handleSelectRange("5 Years", "5y")}>
              5 Years
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}
const mapStatetoProps = (state) => {
  return {};
};
export default connect(mapStatetoProps, { fetchDashboardData })(Header);
// export default Header;
