import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import { Formik, Field, validateYupSchema } from "formik";
import { Link } from "react-router-dom";
import VendorCreditTable from "./vendorCreditTable";
import TermsAndConditions from "./termsAndConditions";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet, apiPost } from "../../components/config/apiConfig";
import loader from "../../assets/loader/load.gif";
// import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { IoIosAddCircle } from "react-icons/io";
import Select from "react-select";

//i18n

const AddVendorCreditForm = (props) => {
  const [itemRate, setItemRate] = useState("");
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [reload, setReload] = useState(false);
  const [subTotalAmount, setSubTotalAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [shipChrg, setShipChrg] = useState(0);
  const [adjus, setAdjus] = useState(0);
  const [itemData, setItemData] = useState([]);
  const [tcsList, setTcsData] = useState([]);

  const [tcsRate, setTcsRate] = useState(null);
  const [tcsTax, setTcsTax] = useState(null);
  const [tcsAmount, setTcsAmount] = useState(0);

  const [branchList, setBranchData] = useState([]);

  function changeStatus(e) {
    //setTcs(e.target.value);
    //console.log("tcs:", tcs);
    var index = e.nativeEvent.target.selectedIndex;
    var id = e.nativeEvent.target[index].value;
    console.log("selected text0", e.nativeEvent.target[index].value);
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tcs/${id}`;
    let resp = apiGet(Url);
    var taxrate = 0;
    resp.then((resp) => {
      console.log("tcs data", resp.response.data.data);

      taxrate = resp.response.data.data.taxRate;
      setTcsRate(taxrate);
      setTcsTax(resp.response.data.data.taxName);
      const rowsInput = [...rowsData];

      const sum = rowsInput
        .map((item) => parseInt(item.amount))
        .reduce((prev, curr) => prev + curr, 0);
      setSubTotalAmount(sum);
      const tax = (sum * (taxrate || 0)) / 100;
      setTcsAmount(tax.toFixed(2));
      setTotalAmount(
        parseInt(subTotalAmount ? subTotalAmount : 0) +
          parseInt(shipChrg ? shipChrg : 0) +
          parseInt(adjus ? adjus : 0) +
          parseFloat(tax ? tax.toFixed(2) : 0)
      );
    });
  }

  const handleEdit = () => {
    setEdit(!edit);
  };
  const history = useHistory();

  // to get customer list
  useEffect(() => {
    function getData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl + `api/v1/vendors/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        console.log("vendor table", resp);
        setData(resp.response.data.data);
      });
    }
    getData();
  }, []);
  const custOptions = data.map((cust) => ({
    value: cust.vendorId,
    label: cust.vendorDisplayName,
  }));

  // custom add button in Select
  const CustomButton = ({ onClick }) => (
    <Link to="addvendor">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Vendor
      </button>
    </Link>
  );

  const DropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomButton onClick={console.log("clicked")} />
      </div>
    );
  };

  const customComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: DropdownMenu, // Use your custom dropdown menu
  };

  // const customAddCustomerOption = {
  //   label: (
  //     <Link to="addvendor">
  //       <span style={{ color: "#408dfb" }}>
  //         <span style={{ marginRight: "8px", color: "#408dfb" }}>
  //           <IoIosAddCircle color="#408dfb" />
  //         </span>{" "}
  //         Add Vendor
  //       </span>
  //     </Link>
  //   ),
  //   value: "addvendor",
  //   onClick: () => {
  //     console.log("Add Customer Clicked");
  //     history.push("addvendor");
  //   },
  //   isFixed: true,
  // };

  // const options = [...custOptions, customAddCustomerOption];
  //to get item list
  useEffect(() => {
    function getItemData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        console.log("item list", resp);
        setItemData(resp?.response?.data?.data);
      });
    }
    getItemData();
  }, []);

  useEffect(() => {
    function getTcsData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tcs`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setTcsData(resp.response.data.data);
      });
    }
    getTcsData();
  }, []);

  useEffect(() => {
    function getBranchList() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl +
        `api/v1/settings/organization_branch/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setBranchData(resp.response.data.data);
      });
    }
    getBranchList();
  }, []);

  const addTableRows = () => {
    const rowsInput = {
      pdetails: "",
      account: "",
      quantity: "",
      rate: "",
      tax: "",
      amount: "",
      itemDiscountType: 0,
      itemDiscountValue: 0,
    };
    setRowsData([...rowsData, rowsInput]);
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    const sum = rows
      .map((item) => parseInt(item.estItemAmount))
      .reduce((prev, curr) => prev + curr, 0);
    console.log(sum);
    setSubTotalAmount(sum);
    const tax = (sum * (tcsRate || 0)) / 100;
    setTcsAmount(tax.toFixed(2));
    setTotalAmount(
      parseInt(subTotalAmount) +
        parseInt(shipChrg) +
        parseInt(adjus) +
        parseFloat(tax.toFixed(2))
    );
  };

  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;

    if (rowsInput[index]["itemDiscountType"] == 0) {
      rowsInput[index]["amount"] =
        rowsInput[index]["quantity"] * rowsInput[index]["rate"];

      rowsInput[index]["amount"] =
        rowsInput[index]["amount"] -
        ((rowsInput[index]["amount"] || 0) *
          (rowsInput[index]["itemDiscountValue"] || 0)) /
          100;
    }
    if (rowsInput[index]["itemDiscountType"] == 1) {
      rowsInput[index]["amount"] =
        rowsInput[index]["quantity"] * rowsInput[index]["rate"];

      rowsInput[index]["amount"] =
        rowsInput[index]["amount"] -
        (rowsInput[index]["itemDiscountValue"] || 0);
    }

    rowsInput[index]["amount"] =
      rowsInput[index]["quantity"] * rowsInput[index]["rate"];
    setRowsData(rowsInput);
    console.log(rowsInput);
    const sum = rowsInput
      .map((item) => parseInt(item.amount))
      .reduce((prev, curr) => prev + curr, 0);
    console.log(sum);
    setSubTotalAmount(sum);
    const tax = (sum * (tcsRate || 0)) / 100;
    setTcsAmount(tax.toFixed(2));
    const totAmount =
      parseInt(subTotalAmount) +
      parseInt(shipChrg ? shipChrg : 0) +
      parseInt(adjus ? adjus : 0) +
      parseFloat(tcsAmount ? tcsAmount : 0);
    setTotalAmount(totAmount);
    if (name === "pdetails") {
      const selectedItem = itemData.find((item) => item.itemName === value);
      if (selectedItem) {
        rowsInput[index]["rate"] = selectedItem.itemSalesSellingPrice;
      }
    }
  };
  const onHandleBlur = (index, e) => {
    const rowsInput = [...rowsData];
    rowsInput[index]["amount"] =
      rowsInput[index]["quantity"] * rowsInput[index]["rate"];
    setRowsData(rowsInput);
    setTotalAmount(
      parseInt(subTotalAmount) +
        parseInt(shipChrg) +
        parseInt(adjus) +
        parseFloat(tcsAmount ? tcsAmount : 0)
    );
  };
  // console.log("itemRate value is ", itemRate);

  // item custom button
  const CustomItemButton = ({ onClick }) => (
    <Link to="/additems/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Item
      </button>
    </Link>
  );
  const ItemDropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomItemButton onClick={console.log("clicked")} />
      </div>
    );
  };
  const customItemComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: ItemDropdownMenu, // Use your custom dropdown menu
  };
  const reactSelectOptions = itemData?.map((items) => ({
    value: items.itemId,
    label: items.itemName,
  }));
  const accountGroupOptions = [
    { value: "1", label: "Advance tax" },
    { value: "2", label: "Employee Advance" },
    { value: "3", label: "Tax Credit" },
  ];
  return (
    <React.Fragment>
      <Formik
        initialValues={{
          // deliv: "1",
          addretinvoice: false,
          vendorName: "",
          branch: "",
          creditNoteNumber: "",
          orderNumber: "",
          vendorCreditDate: "",
          reverseCharge: "",
          customernotes: "",
          discount: "",
          adjustment: "",
          termsandcondition: "",
          import: "",
          tcsTaxId: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.vendorName) {
            errors.vendorName = "Required";
          }
          if (!values.creditNoteNumber) {
            errors.creditNoteNumber = "Required";
          }
          if (!values.orderNumber) {
            errors.orderNumber = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          // alert("submitted");
          let orgId = localStorage.getItem("organisationId");
          let url = BaseUrl.apiUrl.baseUrl + "api/v1/vendor_credit";
          let body = {
            vendorCreditVendorId: parseInt(values.vendorName),
            vendorCreditGstTreatmentId: parseInt(""),
            vendorCreditSourceOfSupplyId: parseInt(""),
            vendorCreditDestinationOfSupplyId: parseInt(""),
            vendorCreditBillNumber: "",
            vendorCreditBillType: "",
            vendorCreditNumber: values.creditNoteNumber,
            vendorCreditOrderNumber: values.orderNumber,
            vendorCreditDate: values.vendorCreditDate,
            vendorCreditIsReverseChargeApplicable: parseInt(
              values.reverseCharge
            ),
            vendorCreditSubTotal: parseInt(subTotalAmount),
            vendorCreditDiscountType: parseInt(""),
            vendorCreditDiscountPercentOrAmount: parseInt(""),
            vendorCreditDiscountAmount: parseInt(""),
            vendorCreditAdjustment: values.adjustment,
            vendorCreditRoundOff: parseInt(""),
            vendorCreditTotal: parseInt(totalAmount),
            vendorCreditCustomerNotes: values.customernotes,
            vendorCreditTermsAndConditions: values.termsandcondition,
            vendorCreditAttachments: values.import,
            vendorCreditVendorName: values.vendorName,
            vendorCreditVendorContactNumber: "",
            vendorCreditTcsTaxId: values.tcsTaxId,
            vendorCreditTcsTaxPercent: tcsRate,
            vendorCreditTcsTaxAmount: tcsAmount,
            vendorCreditTcsTax: tcsTax,
            organizationId: 1,
            branchId: parseInt(values.branch),
            addedByUser: 1,
            editByUser: 1,
            vendorCreditItemsList: rowsData,
          };
          let resp = apiPost(url, body);
          resp.then((resp) => {
            console.log("recurring invoice data is", resp.response.data);
            setReload(false);
            switch (resp.response.data.code) {
              case 200:
                toast.success(resp.response.data.data);
                setTimeout(() => {
                  history.push("/vendorcredit");
                  // useHistory().push("/customerlist");
                }, 1000);
                break;
              default:
                toast.error(resp.response.data.data);
            }
          });
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="formik" id="vendorCredit">
            <Row>
              <Col md={2}>
                <label className="formik-label"> Vendor Name</label>
              </Col>
              <Col md={3} className="">
                {/* <Field
                  className="formik-input"
                  as="select"
                  name="vendorName"
                  onChange={handleChange}
                  value={values.vendorName}
                >
                  <option value="" selected disabled>
                    Select Customer
                  </option>
                  {data.map((cust, index) => {
                    return (
                      <option value={index}>{cust.customerDisplayName}</option>
                    );
                  })}
                </Field> */}
                <Select
                  options={custOptions}
                  name="vendorname"
                  components={customComponents}
                  placeholder="Select Vendor"
                  value={
                    custOptions
                      ? custOptions.find(
                          (option) => option.value === data.vendorname
                        )
                      : ""
                  }
                  onChange={(option) => {
                    setFieldValue({ vendorname: option.value });
                    if (option.value == "addvendor") {
                      console.log(true);
                      history.push("/addvendor");
                      // setPushToCustomer(true);
                    }
                  }}
                />
                {/* <div>
                  <i className="bx bx-search"></i>
                </div> */}
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Branch</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="branch"
                  onChange={handleChange}
                  value={values.branch}
                >
                  <option value=""> Select Branch</option>
                  {branchList.map((br) => (
                    <option value={br.branchId}>{br.branchName}</option>
                  ))}
                </Field>
                <div>
                  {/*<span>Source of supply: Arunachal Pradesh</span>*/}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Credit Note#</label>
              </Col>
              <Col md={10} className="formik-input-search">
                <Field
                  className="formik-input"
                  type="text"
                  name="creditNoteNumber"
                  onChange={handleChange}
                  value={values.creditNoteNumber}
                ></Field>
                {/* <div>
                  <i class="bx bx-cog"></i>
                </div> */}
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Order Number#</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="text"
                  name="orderNumber"
                  onChange={handleChange}
                  value={values.orderNumber}
                ></Field>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label">Vendor Credit Date</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="date"
                  name="vendorCreditDate"
                  onChange={handleChange}
                  value={values.vendorCreditDate}
                ></Field>
                <div>
                  <label className="retinvoice mt-2">
                    <Field
                      type="checkbox"
                      name="reverseCharge"
                      // onChange={handleChange}
                      // value={values.reverseCharge}
                    />

                    <span className="ml-2">
                      This transaction is applicable for reverse charge
                    </span>
                  </label>
                </div>
              </Col>
            </Row>

            {/* <VendorCreditTable /> */}
            <div className="row clearfix">
              <div className="col-md-12 column">
                <table
                  className="table table-bordered invoice-table"
                  id="tab_logic"
                >
                  <thead>
                    <tr>
                      <th className="text-center"> # </th>
                      <th className="text-left"> ITEM DETAILS</th>
                      <th className="text-left"> ACCOUNT</th>
                      <th className="text-right"> QUANTITY</th>
                      <th className="text-right"> RATE</th>
                      {/* <th className="text-right"> TAX</th> */}
                      <th className="text-right"> AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rowsData.map((item, index) => (
                      <tr id="addr0" key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {/* <select
                            name="pdetails"
                            value={item.pdetails}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                          >
                            <option selected disabled>
                              Select Item
                            </option>
                            {itemData.map((items, index) => (
                              <option>{items.itemName}</option>
                            ))}
                          </select> */}
                          <Select
                            required
                            name="invItemId"
                            components={customItemComponents}
                            onChange={(selectedOption) => {
                              // Extract the selected item's ID from the selectedOption
                              const selectedItemId = selectedOption.value;
                              const rowsInput = [...rowsData];
                              rowsInput[index]["pdetails"] = selectedItemId;
                              setRowsData(rowsInput);
                            }}
                            value={reactSelectOptions.find(
                              (option) =>
                                option.value ==
                                (rowsData[index]
                                  ? rowsData[index]["pdetails"]
                                  : "")
                            )}
                            options={reactSelectOptions}
                          />
                        </td>
                        <td>
                          {/* <select
                            name="account"
                            id="tax"
                            value={item.account}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                          >
                            <option value="" disabled selected>
                              Select a tax
                            </option>
                            <option>Advance tax</option>
                            <option>Employee Advance</option>
                            <option>Tax Credit</option>
                          </select> */}
                          <Select
                            name="account"
                            // components={customItemComponents}
                            onChange={(selectedOption) => {
                              // Extract the selected item's ID from the selectedOption
                              const selectedValue = selectedOption.value;
                              const rowsInput = [...rowsData];
                              rowsInput[index]["account"] = selectedValue;
                              setRowsData(rowsInput);
                            }}
                            value={accountGroupOptions.find(
                              (option) =>
                                option.value ===
                                (rowsData[index]
                                  ? rowsData[index]["account"]
                                  : "")
                            )}
                            options={accountGroupOptions}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            name="quantity"
                            value={item.quantity}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                            onBlur={(e) => onHandleBlur(index, e)}
                            className="form-control"
                            placeholder="1.00"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            name="rate"
                            value={item.rate}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                            onBlur={(e) => onHandleBlur(index, e)}
                            className="form-control"
                            // disabled
                            placeholder="0.00"
                          />
                        </td>

                        {/* <td width="10%">
                          <div className="table-inputs">
                            <div>
                              {" "}
                              <input
                                type="text"
                                name="itemDiscountValue"
                                value={item.itemDiscountValue}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                className="form-control"
                                placeholder="0"
                              />
                            </div>
                            <div>
                              <select
                                name="itemDiscountType"
                                id="itemDiscountType"
                                value={item.itemDiscountType}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                              >
                                <option value="0">%</option>
                                <option value="1">Rs.</option>
                              </select>
                            </div>
                          </div>
                        </td> */}
                        <td width="15%">
                          <input
                            type="number"
                            name="amount"
                            value={item.amount}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                            className="form-control"
                          />
                        </td>
                        <td className="text-center">
                          <span
                            className="btn btn-outline-danger"
                            onClick={(e) => deleteTableRows(index, e)}
                          >
                            x
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Row>
                  <Col md={6} className="table-left">
                    <div>
                      <span
                        onClick={addTableRows}
                        className="btn btn-default pull-left add-tablerow"
                      >
                        <i class="bx bxs-plus-circle"></i>
                        <span>Add Item</span>
                      </span>
                    </div>
                    <div className="customernotes">
                      <span>Customer Notes</span>
                      <div>
                        <textarea
                          name="customernotes"
                          rows="4"
                          cols="50"
                          placeholder="Looking forward for your business"
                          onChange={handleChange}
                          value={values.customernotes}
                        ></textarea>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="table-right">
                      <Row>
                        <Col className="details">
                          <div>
                            <span className="detail-head">Sub Total</span>
                          </div>
                          <div>
                            <span>{subTotalAmount}</span>
                          </div>
                        </Col>
                      </Row>
                      {/* <Row>
                          <Col md={4}>
                            <div>
                              <span className="detail-head">Discount</span>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="input-tooltip">
                              <input
                                type="text"
                                name="shippingCharge"
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  console.log("cajksdhajksdh");
                                  setShipChrg(values.discount);
                                  let sum =
                                    parseInt(subTotalAmount) -
                                    parseInt(values.shippingCharge) +
                                    parseInt(values.adjustment);
                                  setTotalAmount(sum);
                                }}
                                value={values.discount}
                              />
                              <i class="bx bx-help-circle"></i>
                            </div>
                          </Col>
                          <Col md={3} className="text-right">
                            <div>
                              <span>{values.discount}</span>
                            </div>
                          </Col>
                        </Row> */}
                      <Row>
                        <Col md={4}>
                          <div>
                            <span className="detail-head">Adjustment</span>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="input-tooltip">
                            <input
                              type="number"
                              name="adjustment"
                              onChange={handleChange}
                              value={values.adjustment}
                              onBlur={(e) => {
                                handleBlur(e);
                                setAdjus(values.adjustment);
                                let sum =
                                  parseInt(subTotalAmount) +
                                  parseInt(
                                    values.shippingCharge
                                      ? values.shippingCharge
                                      : 0
                                  ) +
                                  parseInt(
                                    values.adjustment ? values.adjustment : 0
                                  ) +
                                  parseFloat(tcsAmount ? tcsAmount : 0);
                                setTotalAmount(sum);
                              }}
                            />
                            <i class="bx bx-help-circle"></i>
                          </div>
                        </Col>
                        <Col md={3} className="text-right">
                          <div>
                            <span>{values.adjustment}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <span className="detail-head">Tcs</span>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="input-tooltip">
                            <select
                              name="tcsTaxId"
                              placeholder="select a
                              tax"
                              onChange={(e) => {
                                changeStatus(e);
                                handleChange(e);
                              }}
                              value={values.tcsTaxId}
                            >
                              <option value="" selected>
                                Select a Tax
                              </option>
                              {tcsList.map((tcs) => (
                                <option value={tcs.id} key={tcs.id}>
                                  {tcs.taxName} - {tcs.taxRate} %
                                </option>
                              ))}
                            </select>
                            <i class="bx bx-help-circle"></i>
                          </div>
                        </Col>
                        <Col md={3} className="text-right">
                          <div>
                            <span>{tcsAmount}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="details">
                          <div>
                            <span className="total">Total</span>
                          </div>

                          <div>
                            <span className="total">{totalAmount}</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <hr />
            <Row>
              <Col md={12}>
                {/* <TermsAndConditions invoice={values.addretinvoice} /> */}
                <div className="table-bottom">
                  <div className="row clearfix">
                    <div className="col-md-8 termsandcondition">
                      <span>Terms & Conditions</span>
                      <div className="mt-2">
                        <Field
                          className="formik-textarea terms-textarea"
                          as="textarea"
                          name="termsandcondition"
                          onChange={handleChange}
                          value={values.termsandcondition}
                        ></Field>
                      </div>
                    </div>
                    <div className="col-md-4 attachfiles">
                      <span>Attach File(s) to Estimate</span>
                      <div className="attach">
                        <Field
                          name="import"
                          type="file"
                          onChange={handleChange}
                          value={values.import}
                        ></Field>
                      </div>
                      <div>
                        <span style={{ fontSize: "10px" }}>
                          You can upload a maximum of 5 files, 5MB each
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
      <div className="bottom-bar">
        <Row>
          <Col md={4}>
            <Row>
              <Col md={4}>
                <button className="btn btn-left ml-3">Cancel</button>
              </Col>
              <Col md={4}>
                <button type="reset" className="btn btn-left">
                  Clear
                </button>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="printView">
            <span className="print"> Print or Preview</span>
          </Col>
          <Col md={4}>
            <button
              className="btn btn-right float-right mr-3"
              type="submit"
              form="vendorCredit"
            >
              {reload ? (
                <img className="loader" src={loader} alt="loading..." />
              ) : (
                "Save"
              )}
            </button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default AddVendorCreditForm;
