import {
  FETCH_DASHBOARD_DATA,
  FETCH_DASHBOARD_REVENUE_GRAPH,
  FETCH_DASHBOARD_CHECKIN_CLOSE,
  FETCH_DASHBOARD_CHECKOUT_CLOSE,
  FETCH_DASHBOARD_SPACE_GRAPH,
} from "./actionTypes";

export const fetchDashboardData = (type) => {
  return {
    type: FETCH_DASHBOARD_DATA,
    payload: type,
  };
};
export const fetchDashboardRevenueGraph = (type) => {
  return {
    type: FETCH_DASHBOARD_REVENUE_GRAPH,
    payload: type,
  };
};
export const dashboardCheckInClose = (checkInId, setCheckInId) => {
  return {
    type: FETCH_DASHBOARD_CHECKIN_CLOSE,
    payload: { checkInId, setCheckInId },
  };
};
export const dashboardCheckOutClose = (checkOutId, checkOutType) => {
  return {
    type: FETCH_DASHBOARD_CHECKOUT_CLOSE,
    payload: { checkOutId, checkOutType },
  };
};
export const fetchDashboardSpaceGraph = () => {
  return {
    type: FETCH_DASHBOARD_SPACE_GRAPH,
  };
};
