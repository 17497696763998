import React from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { useCallback } from "react";
import * as moment from "moment";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";
import { format } from "date-fns";
import { useRef } from "react";
import { BASE_CODE } from "./const";
import FileSaver from "file-saver";
function BillingReport() {
  const current = format(new Date(), "yyyy-MM-dd");
  const [warehouseList, setwarehouseList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const ref = useRef();
  //   const date = `${current.getFullYear()}-${
  //     current.getMonth() + 1 < 10
  //       ? `0${current.getMonth() + 1}`
  //       : current.getMonth() + 1
  //   }-${current.getDate() < 10 ? `0${current.getDate()}` : current.getDate()}`;

  const [searchenable, setsearchenable] = useState(false);
  const [searchdata, setsearchdata] = useState({
    fromDate: current,
    toDate: current,
    warehouseId: 0,
    customerId: 0,
  });
  const [WarehouseId, setWarehouseId] = useState({
    id: 0,
    name: "",
  });
  const [warehouserequired, setwarehouserequired] = useState(false);
  const [customerrequired, setcustomerrequired] = useState(false);
  const [typeRequired, setTypeRequired] = useState(false);
  const [customerId, setCustomerId] = useState({
    id: 0,
    name: "",
  });
  const [billingType, setBillingType] = useState({
    id: 0,
    name: "",
  });
  const [url, setFileUrl] = useState();
  function handleChange(e) {
    const newData = { ...searchdata };
    newData[e.target.name] = e.target.value;

    setsearchdata(newData);
  }

  const handleSearch = () => {
    if (WarehouseId.id === 0 && customerId.id === 0 && billingType.id === 0) {
      setwarehouserequired(true);
      setcustomerrequired(true);
      setTypeRequired(true);
    } else if (WarehouseId.id === 0) {
      setwarehouserequired(true);
    } else if (customerId.id === 0) {
      setcustomerrequired(true);
    } else if (billingType.id === 0) {
      setTypeRequired(true);
    } else {
      const Url =
        BaseUrl.apiUrl.baseUrl +
        `api/v1/report/stock_billing_report/${searchdata.fromDate}/${searchdata.toDate}/${WarehouseId.id}/${customerId.id}/${billingType.id}/excel`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        let sliceSize = 1024;
        let byteCharacters = atob(resp.response.data.data);
        let bytesLength = byteCharacters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
          let begin = sliceIndex * sliceSize;
          let end = Math.min(begin + sliceSize, bytesLength);
          let bytes = new Array(end - begin);
          for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
          }
          byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        FileSaver.saveAs(
          new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
          `${Date.now()}.xlsx`
        );
      });
    }
  };
  const handleDownloadPDF = () => {
    if (WarehouseId.id === 0 && customerId.id === 0 && billingType.id === 0) {
      setwarehouserequired(true);
      setcustomerrequired(true);
      setTypeRequired(true);
    } else if (WarehouseId.id === 0) {
      setwarehouserequired(true);
    } else if (customerId.id === 0) {
      setcustomerrequired(true);
    } else if (billingType.id === 0) {
      setTypeRequired(true);
    } else {
      const Url =
        BaseUrl.apiUrl.baseUrl +
        `api/v1/report/stock_billing_report/${searchdata.fromDate}/${searchdata.toDate}/${WarehouseId.id}/${customerId.id}/${billingType.id}/pdf`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        let sliceSize = 1024;
        let byteCharacters = atob(resp.response.data.data);
        let bytesLength = byteCharacters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
          let begin = sliceIndex * sliceSize;
          let end = Math.min(begin + sliceSize, bytesLength);
          let bytes = new Array(end - begin);
          for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
          }
          byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        FileSaver.saveAs(
          new Blob(byteArrays, { type: "application/pdf" }),
          `${Date.now()}.pdf`
        );
      });
    }
  };

  function getWarehouseData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/warehouse/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setwarehouseList(resp.response.data.data);
    });
  }
  function getCustomerData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setCustomerList(resp.response.data.data);
    });
  }
  useEffect(() => {
    getWarehouseData();
    getCustomerData();
  }, []);
  const warehouse = warehouseList.map((item) => {
    return {
      value: item.warehouseId,
      label: item.warehouseName,
    };
  });
  const customer = customerList.map((item) => {
    return {
      value: item.customerId,
      label: item.customerDisplayName,
    };
  });
  const billingTypeOptions = [
    {
      value: "ALL",
      label: "ALL",
    },
    {
      value: "CBM",
      label: "CBM",
    },
    {
      value: "SQM",
      label: "SQM",
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content billing-page">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>BILLING REPORT</h6>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody className="tabcard">
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12} className="search-sec">
                          <AvForm className="form" ref={ref}>
                            <Row>
                              <Col md={2}>
                                <label>From Date</label>
                                <AvField
                                  name="fromDate"
                                  type="date"
                                  onChange={handleChange}
                                  max={current}
                                  value={searchdata.fromDate}
                                  required
                                ></AvField>
                              </Col>
                              <Col md={2}>
                                <label>To</label>
                                <AvField
                                  name="toDate"
                                  type="date"
                                  onChange={handleChange}
                                  min={searchdata.fromDate}
                                  max={current}
                                  required
                                  value={searchdata.toDate}
                                ></AvField>
                              </Col>
                              <Col md={2}>
                                <label>Warehouse</label>
                                <Select
                                  name="checkInWarehouseId"
                                  onChange={(e) => {
                                    setwarehouserequired(false);
                                    setWarehouseId({
                                      id: e.value,
                                      name: e.label,
                                    });
                                  }}
                                  options={warehouse}
                                />
                                {warehouserequired ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    This field is required
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                              <Col md={2}>
                                <label>Customer</label>
                                <Select
                                  name="checkInCustomerId"
                                  onChange={(e) => {
                                    setcustomerrequired(false);
                                    setCustomerId({
                                      id: e.value,
                                      name: e.label,
                                    });
                                  }}
                                  options={customer}
                                />
                                {customerrequired ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    This field is required
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                              <Col md={2}>
                                <label>Billing Type</label>
                                <Select
                                  onChange={(e) => {
                                    setTypeRequired(false);
                                    setBillingType({
                                      id: e.value,
                                      name: e.label,
                                    });
                                  }}
                                  options={billingTypeOptions}
                                />
                                {typeRequired ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    This field is required
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col className="d-flex gap-2">
                                <div className="search-buttons">
                                  <button
                                    class=" btn reportbutton"
                                    onClick={handleSearch}
                                  >
                                    Download Excel
                                  </button>
                                </div>
                                <div className="search-buttons">
                                  <button
                                    class=" btn reportbutton"
                                    onClick={handleDownloadPDF}
                                  >
                                    Download PDF
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default BillingReport;
