import React, { useEffect, useState } from "react";

import {
  Switch,
  BrowserRouter as Router,
  Route,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";
import { useHistory } from "react-router-dom";
// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import { logoutUser } from "./store/actions";
const App = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [idle, setIdle] = useState(false);
  useEffect(() => {
    let timeoutId;
    function onMouseMove() {
      setIdle(false);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIdle(true), 30 * 60 * 1000);
    }
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseclick", onMouseMove);
    document.addEventListener("keydown", onMouseMove);
    document.addEventListener("keypress", onMouseMove);
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseclick", onMouseMove);
      document.removeEventListener("keydown", onMouseMove);
      document.removeEventListener("keypress", onMouseMove);
    };
  }, []);
  useEffect(() => {
    if (idle) {
      history.push("/inactivity");
      window.location.reload();
    }
  }, [idle]);

  function getLayout() {
    let layoutCls = VerticalLayout;

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();

  const NonAuthmiddleware = ({ component: Component, layout: Layout }) => (
    <Route
      render={(props) => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <NonAuthmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              exact
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, {
  logoutUser,
})(App);
