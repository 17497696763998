import React, { useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import ItemComponent from "./ItemComponent";
import { useEffect } from "react";
import { setDefaultItems } from "../../store/actions";
import { connect } from "react-redux";
import { BaseUrl } from "../../components/config/BaseUrl";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dyarco from "../../assets/dyarco/dyarco.jpeg";
import { apiPut } from "../../components/config/apiConfig";

function UsersForm(props) {
  const form = useRef(null);
  const history = useHistory();
  const [inputitemDiv, setInputitemDiv] = useState([<ItemComponent />]);

  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    mobile: "",
    organizationId: 1,
  });

  // const onAddBtnClick = (event) => {
  //   setInputitemDiv([...inputitemDiv, <ItemComponent />]);
  // };
  // const removeDiv = (index) => {
  //   console.log("eeeee", index);
  //   var array = [...inputitemDiv];
  //   if (index !== -1) {
  //     array.splice(index, 1);
  //     setInputitemDiv(array);
  //   }
  // };
  // useEffect(() => {
  //   setCustomerId(props.history.location.pathname.split("/")[2]);
  //   setCustomerName(props.history.location.pathname.split("/")[3]);
  //   props.setDefaultItems();
  // }, []);
  const handleSubmit = (e) => {
    e.persist();
    const Url = BaseUrl.apiUrl.baseUrl + "api/v1/add_user";
    var body = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      password: values.password,
      mobile: values.mobile,
      organizationId: 1,
    };
    let resp = apiPut(Url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          // setcheckinnumber(resp.response.data.data);
          // handlePrint();
          toast.success(
            `${resp.response.data.message} ${resp.response.data.status}`
          );
          setTimeout(() => {
            history.push(`/Users`);
          }, 1000);
          break;

        default:
          toast.error(resp.response.data.data);
      }
    });
  };

  function handle(e) {
    const newData = { ...values };
    newData[e.target.name] = e.target.value;
    setValues(newData);
  }
  return (
    <div className="online-from-container">
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Row>
        <Col md={12} className="text-center">
          <img src={dyarco} alt="dyarco" className="slip-image" />
        </Col>
        <Col md={12} className="mb-2 text-center">
          <h4>New User</h4>
        </Col>
      </Row>
      <AvForm
        className="form online-form"
        onValidSubmit={(e) => handleSubmit(e)}
        ref={form}
      >
        {/* <Row className="d-flex align-items-center mb-3">
          <Col md={2}>
            <label>Date & Time</label>
          </Col>
          <Col md={4}>
            <AvField
              name="date"
              type="date"
              placeholder="Enter date"
              onChange={(e) => handle(e)}
              value={date}
              required
            ></AvField>
          </Col>
          <Col md={4}>
            <AvField
              name="time"
              type="time"
              placeholder="Enter time"
              onChange={(e) => handle(e)}
              value={time}
              required
            ></AvField>
          </Col>
        </Row> */}
        <Row className="d-flex align-items-center mb-3">
          <Col md={2}>
            <label>First Name</label>
          </Col>
          <Col md={6}>
            <AvField
              name="firstname"
              type="text"
              placeholder="Enter User Firstname"
              onChange={(e) => handle(e)}
              value={values.firstname}
            ></AvField>
          </Col>
        </Row>
        <Row className="d-flex align-items-center mb-3">
          <Col md={2}>
            <label>Last Name</label>
          </Col>
          <Col md={6}>
            <AvField
              name="lastname"
              placeholder="Enter User Lastname"
              type="text"
              onChange={(e) => handle(e)}
              value={values.lastname}
              required
            ></AvField>
          </Col>
        </Row>
        <Row className="d-flex align-items-center mb-3">
          <Col md={2}>
            <label>Email</label>
          </Col>
          <Col md={6}>
            <AvField
              name="email"
              type="email"
              placeholder="Enter User Email"
              onChange={(e) => handle(e)}
              value={values.email}
            ></AvField>
          </Col>
        </Row>
        <Row className="d-flex align-items-center mb-3">
          <Col md={2}>
            <label>Enter Password</label>
          </Col>
          <Col md={6}>
            <AvField
              name="password"
              type="password"
              placeholder="Enter User Password"
              onChange={(e) => handle(e)}
              value={values.password}
            ></AvField>
          </Col>
        </Row>
        {/* <Row className="d-flex align-items-center mb-3">
          <Col md={2}>
            <label>Confirm Password</label>
          </Col>
          <Col md={6}>
            <AvField
              name="ConfirmPassword"
              type="password"
              placeholder="Confirm User Password"
              onChange={(e) => handle(e)}
              onBlur={(e) => handleConfirm(e)}
              value={values.confirmPassword}
              min={values.password}
            ></AvField>
          </Col>
         </Row> */}
        <Row className="d-flex align-items-center mb-3">
          <Col md={2}>
            <label>Enter Contact Number</label>
          </Col>
          <Col md={6}>
            <AvField
              name="mobile"
              type="number"
              placeholder="Enter User Phone"
              onChange={(e) => handle(e)}
              value={values.mobile}
            ></AvField>
          </Col>
        </Row>
        <Row className="d-flex align-items-center mb-3">
          <Col md={2}>
            <label>Enter Role</label>
          </Col>
          <Col md={6}>
            <AvField
              name="role"
              type="text"
              placeholder="Enter User Role"
              onChange={(e) => handle(e)}
              value={values.role}
            ></AvField>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={12} style={{ justifyContent: "right" }}>
            <button
              type="submit"
              className="user-form-submitBtn"
              // style={{
              //   background: success?.status === "Success" ? "green" : "",
              // }}
              // disabled={!checkbox || success?.status === "Success"}
            >
              {"Submit"}
            </button>
          </Col>
        </Row>
      </AvForm>
    </div>
  );
}
const mapStatetoProps = (state) => {
  const { onlineFormCustomerItems } = state.OnlineFormTable;
  return { onlineFormCustomerItems };
};
export default connect(mapStatetoProps, { setDefaultItems })(UsersForm);
