import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useState } from "react";
const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 40px;
  width: 300px;
  border-radius: 5px;
  // border-top-left-radius: 5px;
  // border-bottom-left-radius: 5px;
  // border-top-right-radius: 0;
  // border-bottom-right-radius: 0;
  border: 1px solid #1c6180;
  padding: 0 32px 0 24px;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: #052f855c;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  return (
    <>
      <div
        className="col-md-12 iitem"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="position-relative">
          <Input
            id="search"
            type="text"
            placeholder="Search invoice"
            value={filterText}
            onChange={onFilter}
          />
          <span
            className="bx bx-search-alt"
            style={{ position: "absolute", top: "33%", left: "8px" }}
          ></span>
        </div>
        <div className="itembtn">
          <Link to="/addretainerinvoice">
            <button className="newbtn">
              {" "}
              <i class="bx bx-plus"></i> New
            </button>
          </Link>
          {/* <button>
            {" "}
            <i class="bx bxs-cog"></i>
          </button> */}
          {/* <Dropdown
            className="d-inline-block"
            isOpen={socialDrp}
            toggle={() => {
              setsocialDrp(!socialDrp);
            }}
          >
            <DropdownToggle
              className="btn header-item waves-effect ddtoggle"
              tag="button"
            >
              <button>
                {" "}
                <i class="bx bx-menu"></i>
              </button>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag="a" href="">
                {" "}
                <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                Import Invoice
              </DropdownItem>
              <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                {" "}
                <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                Export Invoice
              </DropdownItem>
              <hr />
              <DropdownItem
                tag="a"
                onClick={() => window.location.reload(false)}
              >
                {" "}
                <i className="bx bx-refresh font-size-16 align-middle mr-1"></i>
                Refresh List
              </DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
        </div>
      </div>
    </>
  );
};

export default FilterComponent;
