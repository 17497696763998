import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import { Formik, Field } from "formik";
import { Link, useHistory } from "react-router-dom";
import RecurringInvoiceTable from "./recurringInvoiceTable";
import TermsAndConditions from "./termsAndConditions";
import { apiGet, apiPost } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import loader from "../../assets/loader/load.gif";
// import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { IoIosAddCircle } from "react-icons/io";

//i18n

const AddRecurringInvoiceForm = (props) => {
  const [reload, setReload] = useState(false);
  const [itemRate, setItemRate] = useState("");
  const [itemData, setItemData] = useState([]);
  const [salespersonData, setSalespersonData] = useState([]);
  console.log("itemRate value is ", itemRate);
  //  customer state
  const [data, setData] = useState([]);
  // table config
  const [rowsData, setRowsData] = useState([]);
  const [subTotalAmount, setSubTotalAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [shipChrg, setShipChrg] = useState(0);
  const [adjus, setAdjus] = useState(0);
  const [tcsList, setTcsData] = useState([]);
  const [tcsTax, setTcsTax] = useState(null);
  const [tcsRate, setTcsRate] = useState(null);
  const [tcsAmount, setTcsAmount] = useState(0);

  const [branchList, setBranchData] = useState([]);

  const history = useHistory();

  function changeStatus(e) {
    //setTcs(e.target.value);
    //console.log("tcs:", tcs);
    var index = e.nativeEvent.target.selectedIndex;
    var id = e.nativeEvent.target[index].value;
    console.log("selected text0", e.nativeEvent.target[index].value);
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tcs/${id}`;
    let resp = apiGet(Url);
    var taxrate = 0;
    resp.then((resp) => {
      console.log("tcs data", resp.response.data.data);

      taxrate = resp.response.data.data.taxRate;
      setTcsRate(taxrate);
      setTcsTax(resp.response.data.data.taxName);
      const rowsInput = [...rowsData];

      const sum = rowsInput
        .map((item) => parseInt(item.itemAmount))
        .reduce((prev, curr) => prev + curr, 0);
      setSubTotalAmount(sum);
      const tax = (sum * (taxrate || 0)) / 100;
      setTcsAmount(tax.toFixed(2));
      setTotalAmount(
        parseInt(subTotalAmount ? subTotalAmount : 0) +
          parseInt(shipChrg ? shipChrg : 0) +
          parseInt(adjus ? adjus : 0) +
          parseFloat(tax ? tax.toFixed(2) : 0)
      );
    });
  }

  // get customer (dropdown)
  useEffect(() => {
    function getData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        console.log("customer table", resp);
        setData(resp.response.data.data);
      });
    }
    getData();
  }, []);
  // const [PushToCustomer, setPushToCustomer] = useState(false);
  // useEffect(() => {
  //   if (PushToCustomer === true) {
  //     history.push("/customer/add");
  //   }
  // }, []);

  const custOptions = data.map((cust) => ({
    value: cust.customerId,
    label: cust.customerDisplayName,
  }));

  const CustomButton = ({ onClick }) => (
    <Link to="/customer/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Customer
      </button>
    </Link>
  );

  const DropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomButton onClick={console.log("clicked")} />
      </div>
    );
  };

  const customComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: DropdownMenu, // Use your custom dropdown menu
  };
  // const customAddCustomerOption = {
  //   label: (
  //     <Link to="/customer/add">
  //       <span style={{ color: "#408dfb" }}>
  //         <span style={{ marginRight: "8px", color: "#408dfb" }}>
  //           <IoIosAddCircle color="#408dfb" />
  //         </span>{" "}
  //         Add Customer
  //       </span>
  //     </Link>
  //   ),
  //   value: "add-customer",
  //   onselect: () => {
  //     console.log("Add Customer Clicked");
  //     // history.push("/customer/add");
  //     history.push("/customer/add");
  //   },
  //   isFixed: true,
  // };

  // const options = [...custOptions, customAddCustomerOption];

  useEffect(() => {
    function getTcsData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tcs`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setTcsData(resp.response.data.data);
      });
    }
    getTcsData();
  }, []);

  useEffect(() => {
    function getSalesPersonData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/users/role/SALESPERSON`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setSalespersonData(resp.response.data.data);
      });
    }
    getSalesPersonData();
  }, []);

  //get items
  useEffect(() => {
    function getItemData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        console.log("item list", resp);
        setItemData(resp.response.data.data);
      });
    }
    getItemData();
  }, []);

  useEffect(() => {
    function getBranchList() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl +
        `api/v1/settings/organization_branch/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setBranchData(resp.response.data.data);
      });
    }
    getBranchList();
  }, []);

  // table config
  const addTableRows = () => {
    const rowsInput = {
      itemId: 0,
      itemQuantity: "",
      itemRate: "",
      itemDiscountType: "",
      itemDiscountValue: "",
      itemTaxId: 0,
      itemAmount: 0,
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    const sum = rows
      .map((item) => parseInt(item.itemAmount))
      .reduce((prev, curr) => prev + curr, 0);
    console.log(sum);
    setSubTotalAmount(sum);
    const tax = (sum * (tcsRate || 0)) / 100;
    setTcsAmount(tax.toFixed(2));
    setTotalAmount(
      parseInt(subTotalAmount) +
        parseInt(shipChrg ? shipChrg : 0) +
        parseInt(adjus ? adjus : 0) +
        parseFloat(tcsAmount)
    );
  };

  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    if (rowsInput[index]["itemDiscountType"] == 0) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        ((rowsInput[index]["itemAmount"] || 0) *
          (rowsInput[index]["itemDiscountValue"] || 0)) /
          100;
    }
    if (rowsInput[index]["itemDiscountType"] == 1) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        (rowsInput[index]["itemDiscountValue"] || 0);
    }
    setRowsData(rowsInput);
    console.log(rowsInput);
    const sum = rowsInput
      .map((item) => parseInt(item.itemAmount))
      .reduce((prev, curr) => prev + curr, 0);
    console.log(sum);
    setSubTotalAmount(sum);
    const tax = (sum * (tcsRate || 0)) / 100;
    setTcsAmount(tax.toFixed(2));
    setTotalAmount(
      parseInt(subTotalAmount ? subTotalAmount : 0) +
        parseInt(shipChrg ? shipChrg : 0) +
        parseInt(adjus ? adjus : 0) +
        parseFloat(tcsAmount ? tcsAmount : 0)
    );
    if (name === "itemId") {
      const selectedItem = itemData.find(
        (item) => item.itemId === parseInt(value)
      );
      if (selectedItem) {
        rowsInput[index]["itemRate"] = selectedItem.itemSalesSellingPrice;
      }
    }
  };
  const onHandleBlur = (index, e) => {
    const rowsInput = [...rowsData];
    if (rowsInput[index]["itemDiscountType"] == 0) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        ((rowsInput[index]["itemAmount"] || 0) *
          (rowsInput[index]["itemDiscountValue"] || 0)) /
          100;
    }
    if (rowsInput[index]["itemDiscountType"] == 1) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        (rowsInput[index]["itemDiscountValue"] || 0);
    }
    setRowsData(rowsInput);
    setTotalAmount(
      parseInt(subTotalAmount) +
        parseInt(shipChrg ? shipChrg : 0) +
        parseInt(adjus ? adjus : 0) +
        parseFloat(tcsAmount ? tcsAmount : 0)
    );
  };

  // item custom button
  const CustomItemButton = ({ onClick }) => (
    <Link to="/additems/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Item
      </button>
    </Link>
  );
  const ItemDropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomItemButton onClick={console.log("clicked")} />
      </div>
    );
  };
  const customItemComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: ItemDropdownMenu, // Use your custom dropdown menu
  };
  const reactSelectOptions = itemData.map((items) => ({
    value: items.itemId,
    label: items.itemName,
  }));
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Formik
        initialValues={{
          mode: "1",
          addretinvoice: false,
          customername: "",
          branch: "",
          profilename: "",
          ordernumber: "",
          repeat: "",
          startdate: "",
          enddate: "",
          check: "",
          paymentterms: "",
          salesperson: "",
          customernotes: "",
          shippingCharge: "",
          adjustment: "",
          termsandconditions: "",
          tcsTaxId: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.customerId) {
            errors.customername = "Required";
          }
          if (!values.profilename) {
            errors.profilename = "Required";
          }
          if (!values.ordernumber) {
            errors.ordernumber = "Required";
          }

          return errors;
        }}
        // onSubmit={(values, { setSubmitting }) => {
        //   setTimeout(() => {
        //     alert(JSON.stringify(values, null, 2));
        //     setSubmitting(false);
        //   }, 400);
        // }}
        onSubmit={(values, { setSubmitting }) => {
          alert("submitted");
          let orgId = localStorage.getItem("organisationId");
          let url = BaseUrl.apiUrl.baseUrl + "api/v1/recurring_invoice";
          let body = {
            recurringInvCustomerId: values.customerId,
            recurringInvBillingAddressId: Number,
            recurringInvShippingAddressId: Number,
            recurringInvCustomerRemarks: "",
            recurringInvGstTreatmentId: Number,
            recurringInvPlaceOfSupplyId: Number,
            recurringInvEntityType: "",
            recurringInvProfileName: values.profilename,
            recurringInvOrderNumber: values.ordernumber,
            recurringInvRepeatType: values.repeat,
            recurringInvStartDate: values.startdate,
            recurringInvEndDate: values.enddate,
            recurringInvExpiryType: Number,
            recurringInvPaymentTerms: values.paymentterms,
            recurringInvSalesPersonId: values.salesperson,
            recurringInvIsTaxExclusive: Number,
            recurringInvPriceListId: Number,
            recurringInvSubTotal: subTotalAmount,
            recurringInvShippigCharges: values.shippingCharge,
            recurringInvAdjustment: values.adjustment,
            recurringInvRoundOff: Number,
            recurringInvTotal: totalAmount,
            recurringInvCustomerNotes: values.customernotes,
            recurringInvTermsAndConditions: values.termsandconditions,
            recurringInvCustomerName: values.customername,
            recurringInvCustomerShippingAddress: "",
            recurringInvCustomerBillingAddress: "",
            recurringInvCustomerContactNumber: "",

            recurringInvTcsTaxId: values.tcsTaxId,
            recurringInvTcsTaxPercent: tcsRate,
            recurringInvTcsTaxAmount: tcsAmount,
            recurringInvTcsTax: tcsTax,

            organizationId: orgId,
            branchId: values.branch,
            addedByUser: 1,
            editByUser: 1,
            recurringInvoiceItemsList: rowsData,
          };
          let resp = apiPost(url, body);
          resp.then((resp) => {
            console.log("recurring invoice data is", resp.response.data);
            setReload(false);
            switch (resp.response.data.code) {
              case 200:
                toast.success(resp.response.data.data);
                setTimeout(() => {
                  history.push("/recurringinvoice");
                }, 1000);
                break;
              default:
                toast.error(resp.response.data.data);
            }
          });
          // useEffect(() => {
          //   if (values.customername == "add-customer") {
          //     history.push("/customer/add");
          //   }
          // }, [values.customername]);

          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
          // {
          //   recurringInvItemId: Number,
          //   recurringInvItemDescription: rowsData.estItemDescription,
          //   recurringInvItemQuantity: rowsData.estItemQuantity,
          //   recurringInvItemUnit: "",
          //   recurringInvItemRate: rowsData.estItemRate,
          //   recurringInvItemDiscountType: rowsData.estItemDiscountType,
          //   recurringInvItemDiscountValue: rowsData.estItemDiscountValue,
          //   recurringInvItemTaxId: rowsData.estItemTaxId,
          //   recurringInvItemTaxPercent: Number,
          //   recurringInvItemAmount: rowsData.estItemAmount,
          //   addedByUser: 1,
          //   editByUser: 1,
          // }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setSubmitting,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form
            onSubmit={handleSubmit}
            className="formik"
            id="addrecurringinvoice"
          >
            <Row>
              <Col md={2}>
                <label className="formik-label"> Customer Name</label>
              </Col>
              <Col md={3} className="">
                {/* <Field
                  className="formik-input"
                  as="select"
                  name="customerId"
                  onChange={handleChange}
                  value={values.customerId}
                >
                  <option value="" selected disabled>
                    Select a Customer
                  </option>
                  {data.map((cust, index) => {
                    return (
                      <option value={cust.customerId}>
                        {cust.customerDisplayName}
                      </option>
                    );
                  })}
                </Field> */}
                <Select
                  options={custOptions}
                  components={customComponents}
                  name="customername"
                  value={
                    custOptions
                      ? custOptions.find(
                          (option) => option.value === values.customername
                        )
                      : ""
                  }
                  onChange={(option) => {
                    setFieldValue("customername", option.value);
                    if (option.value == "add-customer") {
                      console.log(true);
                      history.push("/customer/add");
                      // setPushToCustomer(true);
                    }
                  }}
                />
                {/*<div>
                  <i className="bx bx-search"></i>
                </div>*/}
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Branch</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="branch"
                  onChange={handleChange}
                  value={values.branch}
                >
                  <option value=""> Select Branch</option>
                  {branchList.map((br) => (
                    <option value={br.branchId}>{br.branchName}</option>
                  ))}
                </Field>
                <div>
                  {/*<span>Source of supply: Arunachal Pradesh</span>*/}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Profile Name</label>
              </Col>
              <Col md={10} className="formik-input-search">
                <Field
                  className="formik-input"
                  type="text"
                  name="profilename"
                  onChange={handleChange}
                  value={values.profilename}
                ></Field>
                {/*<div>
                  <i class="bx bx-cog"></i>
              </div>*/}
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Order Number</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="number"
                  name="ordernumber"
                  onChange={handleChange}
                  value={values.ordernumber}
                ></Field>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Repeat Every</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="repeat"
                  onChange={handleChange}
                  value={values.repeat}
                >
                  <option value="" selected disabled>
                    select a period
                  </option>
                  <option value="week"> week</option>
                  <option value="2week">2 weeks </option>
                  <option value="month">1 month </option>
                  <option value="2month">2 months </option>
                  <option value="4month">4 months </option>
                  <option value="6month">6 months </option>
                  <option value="year">1 year </option>
                  <option value="2year">2 years</option>
                  <option value="custom">custom</option>
                </Field>
                <div>
                  {/*<span>Source of supply: Arunachal Pradesh</span>*/}
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label className="formik-label"> Start On</label>
                  </Col>
                  <Col md={8}>
                    <Field
                      className="formik-input formik-inputrow"
                      type="date"
                      name="startdate"
                      onChange={handleChange}
                      value={values.startdate}
                    ></Field>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label className="formik-label"> Ends On</label>
                  </Col>
                  <Col md={8}>
                    <div>
                      <Field
                        className="formik-input formik-inputrow"
                        type="date"
                        name="enddate"
                        onChange={handleChange}
                        value={values.enddate}
                        min={values.startdate}
                      ></Field>
                      <label className="retinvoice mt-2">
                        <Field
                          type="checkbox"
                          name="check"
                          onChange={handleChange}
                          value={values.check}
                        />
                        <span className="ml-2">Never Expires</span>
                      </label>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Payment Terms</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="paymentterms"
                  onChange={handleChange}
                  value={values.paymentterms}
                >
                  <option value="" selected disabled>
                    select Payment Terms
                  </option>
                  <option value="Net15">Net 15</option>
                  <option value="Net30">Net 30</option>
                  <option value="Net45">Net 45</option>
                  <option value="Net60">Net 60</option>
                  <option value="Dueendofmonth ">Due end of the month</option>
                  <option value="Dueendnextmonth ">
                    Due end of next month
                  </option>
                  <option value="Dueonreciept ">Due on Reciept</option>
                </Field>
                <div>
                  {/* <span>Source of supply: Arunachal Pradesh</span>*/}
                </div>
              </Col>
            </Row>

            <hr />
            <Row>
              <Col md={2}>
                <label className="formik-label"> Salesperson</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="salesperson"
                  onChange={handleChange}
                  value={values.salesperson}
                >
                  <option value="" disabled selected>
                    Select a Sales person
                  </option>
                  {salespersonData.map((sp) => (
                    <option value={sp.id}>
                      {sp.firstname + " " + sp.lastname}
                    </option>
                  ))}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label">
                  {" "}
                  Associate Project(s) Hours
                </label>
              </Col>
              <Col md={10}>
                <span>There are no active projects for this customer</span>
              </Col>
            </Row>
            <hr />
            {/* <RecurringInvoiceTable /> */}
            <div className="row clearfix">
              <div className="col-md-12 column">
                <table
                  className="table table-bordered invoice-table"
                  id="tab_logic"
                >
                  <thead>
                    <tr>
                      <th className="text-center"> # </th>
                      <th className="text-left"> ITEM</th>
                      <th className="text-right"> QUANTITY</th>
                      <th className="text-right"> RATE</th>
                      <th className="text-right"> DISCOUNT </th>
                      {/* <th className="text-right"> TAX</th> */}
                      <th className="text-right"> AMOUNT</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {rowsData.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {/* <select
                              name="itemId"
                              className="form-control"
                              value={itemData.itemId}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            >
                              <option value="" selected disabled>
                                Select Item
                              </option>
                              {itemData.map((items, index) => (
                                <option key={items.itemId} value={items.itemId}>
                                  {items.itemName}
                                </option>
                              ))}
                            </select> */}
                            <Select
                              required
                              name="itemId"
                              components={customItemComponents}
                              onChange={(selectedOption) => {
                                // Extract the selected item's ID from the selectedOption
                                const selectedItemId = selectedOption.value;
                                const rowsInput = [...rowsData];
                                rowsInput[index]["itemId"] = selectedItemId;
                                setRowsData(rowsInput);
                              }}
                              value={reactSelectOptions.find(
                                (option) =>
                                  option.value ==
                                  (rowsData[index]
                                    ? rowsData[index]["itemId"]
                                    : "")
                              )}
                              options={reactSelectOptions}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              name="itemQuantity"
                              className="form-control"
                              placeholder="1.00"
                              value={data.itemQuantity}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                              onBlur={(e) => onHandleBlur(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              name="itemRate"
                              className="form-control"
                              placeholder="0.00"
                              value={data.itemRate}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                              onBlur={(e) => onHandleBlur(index, e)}
                            />
                          </td>
                          <td width="10%">
                            <div className="table-inputs">
                              <div>
                                {" "}
                                <input
                                  type="number"
                                  name="itemDiscountValue"
                                  className="form-control"
                                  placeholder="0"
                                  value={data.itemDiscountValue}
                                  onChange={(evnt) =>
                                    handleTableChange(index, evnt)
                                  }
                                  onBlur={(e) => onHandleBlur(index, e)}
                                />
                              </div>
                              <div>
                                <select
                                  name="itemDiscountType"
                                  id="itemDiscountType"
                                  value={data.itemDiscountType}
                                  onChange={(evnt) =>
                                    handleTableChange(index, evnt)
                                  }
                                  onBlur={(e) => onHandleBlur(index, e)}
                                >
                                  <option value="0">%</option>
                                  <option value="1">Rs.</option>
                                </select>
                              </div>
                            </div>
                          </td>
                          {/* <td>
                            <select
                              name="itemTaxId"
                              id="itemTaxId"
                              disabled
                              value={data.itemTaxId}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            >
                              <option value="%" disabled selected>
                                Select a tax
                              </option>
                            </select>
                          </td> */}
                          <td width="15%">
                            <input
                              type="number"
                              name="itemAmount"
                              className="form-control"
                              value={data.itemAmount}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            />
                          </td>
                          <td className="text-center">
                            <span
                              className="btn btn-outline-danger"
                              onClick={(e) => deleteTableRows(index, e)}
                            >
                              x
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Row>
                  <Col md={6} className="table-left">
                    <div>
                      <div className=" col-md-4 btn btn-default pull-left add-tablerow">
                        <i class="bx bxs-plus-circle"></i>
                        <div onClick={addTableRows}>Add Item</div>
                      </div>
                      {/* <button
                      onClick={this.handleRemoveRow}
                      className="pull-right btn btn-default table-btn"
                    >
                      Clear all rows
                    </button> */}
                    </div>
                    <div className="customernotes">
                      <span>Customer Notes</span>
                      <div>
                        <textarea
                          name="customernotes"
                          rows="4"
                          cols="40"
                          placeholder="Looking forward for your business"
                          onChange={handleChange}
                          value={values.customernotes}
                        ></textarea>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="table-right">
                      <Row>
                        <Col className="details">
                          <div>
                            <span className="detail-head">Sub Total</span>
                          </div>
                          <div>
                            <span>{subTotalAmount}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <span className="detail-head">
                              Shipping Charges
                            </span>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="input-tooltip">
                            <input
                              type="text"
                              name="shippingCharge"
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                console.log("cajksdhajksdh");
                                setShipChrg(values.shippingCharge);
                                let sum =
                                  parseInt(subTotalAmount) +
                                  parseInt(
                                    values.shippingCharge
                                      ? values.shippingCharge
                                      : 0
                                  ) +
                                  parseInt(
                                    values.adjustment ? values.adjustment : 0
                                  ) +
                                  parseFloat(tcsAmount ? tcsAmount : 0);
                                setTotalAmount(sum);
                              }}
                              value={values.shippingCharge}
                            />
                            <i class="bx bx-help-circle"></i>
                          </div>
                        </Col>
                        <Col md={3} className="text-right">
                          <div>
                            <span>{values.shippingCharge}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <span className="detail-head">Adjustment</span>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="input-tooltip">
                            <input
                              type="number"
                              name="adjustment"
                              onChange={handleChange}
                              value={values.adjustment}
                              onBlur={(e) => {
                                handleBlur(e);
                                console.log("cajksdhajksdh");
                                setAdjus(values.adjustment);
                                let sum =
                                  parseInt(subTotalAmount) +
                                  parseInt(
                                    values.shippingCharge
                                      ? values.shippingCharge
                                      : 0
                                  ) +
                                  parseInt(
                                    values.adjustment ? values.adjustment : 0
                                  ) +
                                  parseFloat(tcsAmount ? tcsAmount : 0);
                                setTotalAmount(sum);
                              }}
                            />
                            <i class="bx bx-help-circle"></i>
                          </div>
                        </Col>
                        <Col md={3} className="text-right">
                          <div>
                            <span>{values.adjustment}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <span className="detail-head">Tcs</span>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="input-tooltip">
                            <select
                              name="tcsTaxId"
                              placeholder="select a
                              tax"
                              onChange={(e) => {
                                changeStatus(e);
                                handleChange(e);
                              }}
                              value={values.tcsTaxId}
                            >
                              <option value="" selected>
                                Select a Tax
                              </option>
                              {tcsList.map((tcs) => (
                                <option value={tcs.id} key={tcs.id}>
                                  {tcs.taxName} - {tcs.taxRate} %
                                </option>
                              ))}
                            </select>
                            <i class="bx bx-help-circle"></i>
                          </div>
                        </Col>
                        <Col md={3} className="text-right">
                          <div>
                            <span>{tcsAmount}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="details">
                          <div>
                            <span className="total">Total</span>
                          </div>

                          <div>
                            <span className="total">{totalAmount}</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <hr />
            <Row>
              <Col md={12}>
                {/* <TermsAndConditions invoice={values.addretinvoice} /> */}
                <div className="container table-bottom">
                  <div className="row clearfix">
                    <div className="col-md-8 termsandcondition">
                      <span>Terms & Conditions</span>
                      <div className="mt-2">
                        <Field
                          className="formik-textarea terms-textarea"
                          as="textarea"
                          name="termsandconditions"
                          onChange={handleChange}
                          value={values.termsandconditions}
                        ></Field>
                      </div>
                    </div>
                  </div>
                  {/*<div className="mt-3">
                    <span>Want to get paid faster?</span>
                    <div className="mt-2">
                      Configure payment gateways and receive payments online.{" "}
                      Set up Payment Gateway
                    </div>
                  </div>
                  <hr />
                  <div className="mt-4 align-center">
                    <span>Preference :</span>
                    <span className="ml-3">Create Invoices as Drafts</span>
                    <i className="bx bx-cog ml-2"></i>
                  </div>*/}
                </div>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
      <div className="bottom-bar">
        <Row>
          <Col md={4}>
            <Row>
              <Col md={4}>
                <button className="btn btn-left ml-3">Cancel</button>
              </Col>
              <Col md={4}>
                <button type="reset" className="btn btn-left">
                  Clear
                </button>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="printView">
            <span className="print">Make Recurring | Print or Preview</span>
          </Col>
          <Col md={4}>
            <button
              className="btn btn-right float-right mr-3"
              type="submit"
              form="addrecurringinvoice"
            >
              {reload ? (
                <img className="loader" src={loader} alt="loading..." />
              ) : (
                " Save"
              )}
            </button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default AddRecurringInvoiceForm;
