import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          {localStorage.getItem("userRole") === "ADMIN" ||
          localStorage.getItem("userRole") === "USER" ? (
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="/dashboard" className="waves-effect">
                  {/* <i className="bx bx-home-circle"></i> */}
                  <i class="bx bxs-dashboard"></i>

                  <span>{this.props.t("My Dashboard")}</span>
                </Link>
              </li>
              {/* ------------------------------ FOR WAREHOUSE ---------------------------------- */}
              {/* <li>
                <Link to="/customerlist" className="waves-effect">
                  <i class="bx bxs-user"></i>

                  <span>{this.props.t("Customers")}</span>
                </Link>
              </li> */}
              <li>
                <Link to="/customerlist" className="waves-effect">
                  <i class="bx bxs-user"></i>

                  <span>{this.props.t("Customers")}</span>
                </Link>
              </li>

              <li>
                <Link to="/subscription" className="waves-effect">
                  <i class="fas fa-bell"></i>

                  <span>{this.props.t("Subscription")}</span>
                </Link>
              </li>

              <li>
                <Link to="/view-invoice">
                  <i class="bx bxs-spreadsheet"></i>

                  <span>{this.props.t("Invoice")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i class="bx bxs-basket"></i>
                  <span>{this.props.t("Warehouse")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/warehouse-list">
                      {this.props.t("Warehouse")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/cargocheckin-list">
                      {this.props.t("Cargo Check-In")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/cargocheckout-list">
                      {this.props.t("Cargo Check-Out")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/palletmerge">
                      {this.props.t("Pallet Merge")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/online-form-list">
                      {this.props.t("Order Form")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/Users">
                  <i class="fas fa-users"></i>
                  <span> {this.props.t("Users")}</span>
                </Link>
              </li>
              {/* <li>
                <Link to="/Calender">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                  {this.props.t("Calender")}
                </Link>
              </li> */}

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i class="bx bxs-report"></i>
                  <span>{this.props.t("Reports")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/checkinreport">
                      {this.props.t("Check-In Report")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/checkoutreport">
                      {this.props.t("Check-Out Report")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/stockreport">
                      {this.props.t("Stock Report")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/combinedreport">
                      {this.props.t("Combined Report")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/billingreport">
                      {this.props.t("Billing Report")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/costsheetreport">
                      {this.props.t("Cost Sheet Report")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/profitreport">
                      {this.props.t("Profit Report")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/rentlist">
                      {this.props.t("Customer Rent List")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/warehouse-usage-report">
                      {this.props.t("Warehouse Usage Report")}
                    </Link>
                  </li>
                </ul>
              </li>

              {/* SETTINGS */}
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i class="bx bxs-cog"></i>
                  <span>{this.props.t("Settings")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/unit-of-measurement">{this.props.t("UOM")}</Link>
                  </li>
                  <li>
                    <Link to="/handlinginfo">
                      {this.props.t("Handling Info")}
                    </Link>
                  </li>

                  <li>
                    <Link to="/location-type">
                      {this.props.t("Location Type")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/aisle">{this.props.t("Aisle")}</Link>
                  </li>
                  <li>
                    <Link to="/zone">{this.props.t("Zone")}</Link>
                  </li>
                  <li>
                    <Link to="/rack">{this.props.t("Rack")}</Link>
                  </li>
                  <li>
                    <Link to="/row">{this.props.t("Row")}</Link>
                  </li>
                  <li>
                    <Link to="/bin">{this.props.t("Bin")}</Link>
                  </li>

                  <li>
                    <Link to="/reporting-tag">
                      {this.props.t("Reporting Tag")}
                    </Link>
                  </li>
                  {/* {localStorage.getItem("userName") ===
                  "opine_ac_admin@gmail.com" ? (
                    <>
                      <li>
                        <Link to="/branch">{this.props.t("Branch")}</Link>
                      </li>
                      <li>
                        <Link to="/challan-type">
                          {this.props.t("Challan Type")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/project">{this.props.t("Project")}</Link>
                      </li>
                      <li>
                        <Link to="/delivery-method">
                          {this.props.t("Delivery Method")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/tax">{this.props.t("Tax")}</Link>
                      </li>
                      <li>
                        <Link to="/tax-section">
                          {this.props.t("Tax Section")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/tcs">{this.props.t("Tcs")}</Link>
                      </li>
                      <li>
                        <Link to="/tds">{this.props.t("Tds")}</Link>
                      </li>
                    </>
                  ) : (
                    ""
                  )} */}
                </ul>
              </li>

              <li>
                <Link to="/bin-location" className="waves-effect">
                  <i class="mdi mdi-map-marker"></i>

                  <span>{this.props.t("Bin Location")}</span>
                </Link>
              </li>
              <li>
                <Link to="/put-away" className="waves-effect">
                  <i class="bx bxs-archive"></i>

                  <span>{this.props.t("Put Away")}</span>
                </Link>
              </li>
              <li>
                <Link to="/pick-up" className="waves-effect">
                  <i class="mdi mdi-truck-check"></i>

                  <span>{this.props.t("Pick Up")}</span>
                </Link>
              </li>
              <li>
                <Link to="/view-goodstransfer" className="waves-effect">
                  <i class="mdi mdi-transfer"></i>

                  <span>{this.props.t("Goods Transfer")}</span>
                </Link>
              </li>
              <li>
                <Link to="/estimates">
                  <i class="bx bxs-calculator"></i>
                  <span>{this.props.t("Estimates")}</span>
                </Link>
              </li>
              {/* ------------------------------ END FOR WAREHOUSE ----------------------------------  */}
              {/* {localStorage.getItem("userName") ===
              "opine_ac_admin@gmail.com" ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i class="bx bxs-calculator"></i>
                    <span>{this.props.t("Sales")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/estimates">{this.props.t("Estimates")}</Link>
                    </li>
                    <li>
                      <Link to="/retainerinvoice">
                        {this.props.t("Retainer Invoice")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/salesorder">
                        {this.props.t("Sales Order")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/sales-order-history">
                        {this.props.t("Sales Order History")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/deliverychallan">
                        {this.props.t("Delivery Challan")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/paymentsrecieved">
                        {this.props.t("Payments Recieved")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/recurringinvoice">
                        {this.props.t("Recurring Invoice")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/creditnotes">
                        {this.props.t("Credit Notes")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : (
                ""
              )} */}
              {/* {localStorage.getItem("userName") ===
              "opine_ac_admin@gmail.com" ? (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i class="bx bxs-shopping-bag"></i>
                    <span>{this.props.t("Purchases")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/vendors">{this.props.t("Vendors")}</Link>
                    </li>
                    <li>
                      <Link to="/expenses">{this.props.t("Expenses")}</Link>
                    </li>
                    <li>
                      <Link to="/purchaseorders">
                        {this.props.t("Purchase Orders")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/purchase-order-history">
                        {this.props.t("Purchase Order History")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/bills">{this.props.t("Bills")}</Link>
                    </li>
                    <li>
                      <Link to="/paymentsmade">
                        {this.props.t("Payments Made")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/vendorcredit">
                        {this.props.t("Vendor Credit")}
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : (
                ""
              )} */}
              {/* <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i class="bx bxs-package"></i>
                  <span>{this.props.t("Items")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/viewitems">{this.props.t("Items")}</Link>
                  </li>
                  {localStorage.getItem("userName") ===
                  "opine_wh_admin@gmail.com" ? (
                    <></>
                  ) : (
                    ""
                  )}
                </ul>
              </li> */}
              {/*<li>
                    <Link to="/#" className="waves-effect">
                      <i class="bx bxs-file-doc"></i>

                      <span>{this.props.t("Documents and Payslips")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className="waves-effect">
                      <i class="bx bxs-user-circle"></i>

                      <span>{this.props.t("Statements and Clients")}</span>
                    </Link>
                  </li>
                  <hr />
                  <li>
                    <Link to="/#" className="waves-effect">
                      <i class="bx bxs-file-export"></i>
                      <span>{this.props.t("Export Data")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className="waves-effect">
                      <i class="bx bxs-report"></i>

                      <span>{this.props.t("Reports")}</span>
                    </Link>
                  </li>*/}
              <hr />
              {/* <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i class="bx bxs-basket"></i>
                      <span>{this.props.t("Warehouse")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/warehouse-list">
                          {this.props.t("Warehouse")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/cargocheckin-list">
                          {this.props.t("Cargo Check-In")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/cargocheckout-list">
                          {this.props.t("Cargo Check-Out")}
                        </Link>
                      </li>
                    </ul>
                  </li> */}
            </ul>
          ) : localStorage.getItem("userRole") === "CUSTOMER" ? (
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="/dashboard" className="waves-effect">
                  {/* <i className="bx bx-home-circle"></i> */}
                  <i class="bx bxs-dashboard"></i>

                  <span>{this.props.t("My Dashboard")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i class="bx bxs-basket"></i>
                  <span>{this.props.t("Warehouse")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/customer-checkin-list">
                      {this.props.t("Cargo Check-In")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/customer-checkout-list">
                      {this.props.t("Cargo Check-Out")}
                    </Link>
                  </li>

                  <li>
                    <Link to="/customer-order-list">
                      {this.props.t("Order Form")}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(SidebarContent));
