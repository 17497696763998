import React, { useState, useRef } from "react";
import { Row, Col } from "reactstrap";

import { useEffect } from "react";

import { BaseUrl } from "../../components/config/BaseUrl";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dyarco from "../../assets/dyarco/awc.png";
function OnlineFormCustomerSingleView(props) {
  const form = useRef(null);
  const history = useHistory();
  const [success, setSuccess] = useState();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isValid, setIsValid] = useState("");
  const [showDiv, setShowDiv] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [token, setToken] = useState("");

  const [orderData, setOrderData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  function checkFormValid(token) {
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/public/v1/validate_order_token/${token}`;
    fetch(Url)
      .then((response) => response.json())
      .then((data) => {
        setIsPageLoading(false);
        setIsValid(data.data);
      });
  }
  function getOrderDetails(orderId) {
    const Url = BaseUrl.apiUrl.baseUrl + `api/public/v1/order_info/${orderId}`;
    fetch(Url)
      .then((response) => response.json())
      .then((data) => {
        setOrderData(data.data);
      });
  }
  useEffect(() => {
    setOrderId(history.location.pathname.split("/")[2]);
    setCustomerId(history.location.pathname.split("/")[3]);
    setToken(history.location.pathname.split("/")[4]);
    checkFormValid(history.location.pathname.split("/")[4]);
    getOrderDetails(history.location.pathname.split("/")[2]);
  }, []);
  useEffect(() => {
    if (isValid === "valid") {
      setTimeout(() => {
        setIsValid("");
        setShowDiv(true);
      }, 3000);
    }
  }, [isValid]);
  const handleAccept = (orderId) => {
    setIsLoading(true);
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/public/v1/online_order/${orderId}/set_status`;
    var body = {
      orderCustomerStatus: 1,
    };
    fetch(Url, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        switch (data.code) {
          case 200:
            setSuccess(data);
            toast.success(`${data.message} `);
            getOrderDetails(orderId);
            // form.current.reset();
            // setTimeout(() => {
            //   history.push("/login");
            // }, 1000);
            break;

          default:
            toast.error(data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };
  const handleReject = (orderId) => {
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/public/v1/online_order/${orderId}/set_status`;
    var body = {
      orderCustomerStatus: 2,
    };
    fetch(Url, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        switch (data.code) {
          case 200:
            setSuccess(data);
            toast.success(`${data.message} `);
            getOrderDetails(orderId);
            // form.current.reset();
            // setTimeout(() => {
            //   history.push("/login");
            // }, 1000);
            break;

          default:
            toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      {isPageLoading ? (
        <div className="page-load-center">
          <div className="loading-content">
            <div
              className="spinner-grow text-success"
              style={{ width: "6rem", height: "6rem" }}
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <div style={{ fontSize: "18px" }}>Checking the link is valid</div>
          </div>
        </div>
      ) : isValid === "valid" ? (
        <div className="page-load-center">
          <div className="loading-content">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6em"
              height="6em"
              fill="#81c345b3"
              class="bi bi-check-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
            <div style={{ fontSize: "18px" }}>
              Link is valid. Redirecting to form please wait....
            </div>
          </div>
        </div>
      ) : isValid === "invalid token" ? (
        <div className="page-load-center">
          <div className="loading-content">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6em"
              height="6em"
              fill="#e98383"
              class="bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
            <div style={{ fontSize: "18px" }}>
              Link Expired. Please contact with your warehouse assistants.
            </div>
          </div>
        </div>
      ) : showDiv ? (
        <div className="online-from-container">
          <ToastContainer
            position="bottom-center"
            // autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          />
          <Row>
            <Col md={12} className="text-center">
              <img src={dyarco} alt="dyarco" className="slip-image" />
            </Col>
            <Col md={12} className="mb-2 text-center">
              <h4>Booking Order Confirmation</h4>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-4 orderDetailsHead">
              <span className="confirmation-head">Order Details</span>
              <span
                className={
                  orderData.orderCustomerStatus === 3
                    ? "status yellow"
                    : orderData.orderCustomerStatus === 1
                    ? "status green"
                    : orderData.orderCustomerStatus === 2
                    ? "status red"
                    : ""
                }
              >
                {orderData.orderCustomerStatus === 3
                  ? "New Confirmation Request"
                  : orderData.orderCustomerStatus === 1
                  ? "Order Confirmed"
                  : orderData.orderCustomerStatus === 2
                  ? "Order Rejected"
                  : ""}
              </span>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={12}>
              <div>
                <Row className="confirmation-container">
                  <Col md={3}>
                    <label>Date</label>
                    <div className="confirmation-contents">
                      {orderData.orderDate}
                    </div>
                  </Col>
                  <Col md={9}>
                    <label>Customer Name</label>
                    <div className="confirmation-contents">
                      {orderData.orderCustomerName}
                    </div>
                  </Col>
                </Row>
                <Row className="confirmation-container">
                  <Col md={4}>
                    <label>Warehouse</label>
                    <div className="confirmation-contents">
                      {orderData.orderWarehouseName}
                    </div>
                  </Col>
                  <Col md={4}>
                    <label>Rate /CBM</label>
                    <div className="confirmation-contents">
                      {orderData.checkInRatePerCbm}
                    </div>
                  </Col>
                  <Col md={4}>
                    <label>Order Type</label>
                    <div className="confirmation-contents">
                      {orderData.orderType === 1 ? "Check In" : "Check Out"}
                    </div>
                  </Col>
                </Row>
                <Row className="confirmation-container">
                  <Col md={4}>
                    <label>Vehicle Number</label>
                    <div className="confirmation-contents">
                      {orderData.vehicleNo}
                    </div>
                  </Col>
                  <Col md={4}>
                    <label>Loading From</label>
                    <div className="confirmation-contents">
                      {orderData.loadingFrom}
                    </div>
                  </Col>
                  <Col md={4}>
                    <label>Delivery To</label>
                    <div className="confirmation-contents">
                      {orderData.deliveryTo}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-4">
                    <span className="confirmation-head">Item Details</span>
                  </Col>
                </Row>
                {orderData.itemsList?.map((items, index) => {
                  return (
                    <div key={index}>
                      <Row className="mb-3">
                        <Col md={12}>
                          <div className="conf-items-length">
                            <span>{index + 1}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row className="confirmation-container">
                        <Col md={6}>
                          <label>Item Name</label>
                          <div className="confirmation-contents">
                            {items.itemCommodityName}
                          </div>
                        </Col>
                        <Col md={3}>
                          <label>Invoice</label>
                          <div className="confirmation-contents">
                            {items.itemInvoiceNumber}
                          </div>
                        </Col>
                        <Col md={3}>
                          <label>HSN Code</label>
                          <div className="confirmation-contents">
                            {items.itemHsnCode}
                          </div>
                        </Col>
                      </Row>
                      <Row className="confirmation-container">
                        <Col md={3}>
                          <label>CTNS</label>
                          <div className="confirmation-contents">
                            {items.itemUom}
                          </div>
                        </Col>
                        <Col md={3}>
                          <label>QTY</label>
                          <div className="confirmation-contents">
                            {items.itemUnits}
                          </div>
                        </Col>
                        <Col md={3}>
                          <label>PCS</label>
                          <div className="confirmation-contents">
                            {items.itemUnitsPerSinglePackage}
                          </div>
                        </Col>
                        <Col md={3}>
                          <label>Weight</label>
                          <div className="confirmation-contents">
                            {items.itemTotalWeight}
                          </div>
                        </Col>
                      </Row>
                      <Row className="confirmation-container">
                        <Col md={4}>
                          <label>Facility</label>
                          <div className="confirmation-contents">
                            {items.itemTemperature}
                          </div>
                        </Col>
                        <Col md={4}>
                          <label>Handling Info</label>
                          <div className="confirmation-contents">
                            {items.itemHandlingInfo}
                          </div>
                        </Col>
                        <Col md={2}>
                          <label>Batch</label>
                          <div className="confirmation-contents">
                            {items.itemBatch}
                          </div>
                        </Col>
                        <Col md={2}>
                          <label>Pallet</label>
                          <div className="confirmation-contents">
                            {items.itemPallet}
                          </div>
                        </Col>
                      </Row>
                      <Row className="confirmation-container">
                        <Col md={2}>
                          <label>Length</label>
                          <div className="confirmation-contents">
                            {items.itemLength}
                          </div>
                        </Col>
                        <Col md={2}>
                          <label>Breadth</label>
                          <div className="confirmation-contents">
                            {items.itemBreadth}
                          </div>
                        </Col>
                        <Col md={2}>
                          <label>Height</label>
                          <div className="confirmation-contents">
                            {items.itemHeight}
                          </div>
                        </Col>
                        <Col md={3}>
                          <label>CBM</label>
                          <div className="confirmation-contents">
                            {items.itemArea}
                          </div>
                        </Col>
                        <Col md={3}>
                          <label>SQM</label>
                          <div className="confirmation-contents">
                            {items.itemAreaSqm}
                          </div>
                        </Col>
                      </Row>
                      <Row className="confirmation-container">
                        <Col md={6}>
                          <label>Additional Info</label>
                          <div className="confirmation-contents">
                            {items.itemRemarks}
                          </div>
                        </Col>
                        <Col md={6}>
                          <label>Cargo Condition</label>
                          <div className="confirmation-contents">
                            {items.itemCargoCondition}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
                {orderData.chargeList?.length > 0 ? (
                  <Row>
                    <Col md={12} className="mb-4">
                      <span className="confirmation-head">
                        Handling Charge Details
                      </span>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {orderData.chargeList?.map((items, index) => {
                  return (
                    <div key={index}>
                      <Row className="confirmation-container">
                        <Col md={6}>
                          <div className="other-charge-left">
                            <div className="conf-other-item-length">
                              <span>{index + 1}</span>
                            </div>
                            <div className="other-charge-right">
                              <label>Charge Name</label>
                              <div className="confirmation-contents">
                                {items.chargeName}
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <label>Charge Amount</label>
                          <div className="confirmation-contents">
                            {items.chargeAmount}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          {orderData.orderCustomerStatus === 3 ? (
            <Row>
              <Col md={12} className="conf-btns">
                <button
                  className="online-buttons reject"
                  onClick={() => handleReject(orderId)}
                >
                  Reject
                </button>
                <button
                  className="online-buttons accept"
                  onClick={() => handleAccept(orderId)}
                  disabled={isLoading}
                >
                  {isLoading ? "Please Wait..." : "Accept Confirmation"}
                </button>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default OnlineFormCustomerSingleView;
