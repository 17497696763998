import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
//i18n
import { withTranslation } from "react-i18next";
// Pages Components
import BillingAddress from "./billingAddress";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";

function ShippingAddress(
  country,
  formType,
  customerData,
  data,
  customerWorkPhone,
  customerMobile
) {
  const [activeTab, setActiveTab] = useState("1");
  const [checkbox, setCheckbox] = useState(false);
  const [billingstate, setbillingState] = useState([]);
  const [shippingstate, setshippingState] = useState([]);
  const [defaultStateValue, setDefaultStateValue] = useState(null);
  const [selectedbillingstate, setselectedbillingstate] = useState({
    id: 0,
    name: "",
  });
  const [selectedshippingstate, setselectedshippingstate] = useState({
    id: 0,
    name: "",
  });
  const [addressData, setAddressData] = useState({
    addressId: "",
    addressAttention: "",
    addressCountry: "",
    addressLine1: "",
    addressLine2: "",
    addressCity: "",
    addressStateId: "",
    addressZipCode: "",
    addressPhone: "",
    addressFax: "",
  });

  const [billingData, setBillingData] = useState({
    addressId: "",
    addressAttention: "",
    addressCountry: "",
    addressLine1: "",
    addressLine2: "",
    addressCity: "",
    addressStateId: "",
    addressState: "",
    addressZipCode: "",
    addressPhone: "",
    addressFax: "",
  });
  const [contactData, setContactData] = useState({
    contactId: "",
    contactSalutation: "",
    contactFirstname: "",
    contactLastname: "",
    contactEmail: "",
    contactMobile: "",
  });

  const handleCheckBox = () => {
    setCheckbox((value) => !value);
    // if (checkbox) {
    // }
  };
  function getbillingState(id) {
    if (id !== "") {
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/states/${id}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        if (resp.response.data.code == 200) {
          setbillingState(resp.response.data.data);
        }
      });
    }
  }
  function getshippingState(id) {
    if (id !== "") {
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/states/${id}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        if (resp.response.data.code == 200) {
          setshippingState(resp.response.data.data);
        }
      });
    }
  }
  function handle(e) {
    const newData = { ...addressData };
    newData[e.target.name] = e.target.value;
    setAddressData(newData);
  }
  function billingDataHandle(e) {
    const newBillingData = { ...billingData };
    newBillingData[e.target.name] = e.target.value;
    setBillingData(newBillingData);
  }
  function contactDataHandle(e) {
    const newContactData = { ...contactData };
    newContactData[e.target.name] = e.target.value;
    setContactData(newContactData);
  }
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  function populateBillingAddressData() {
    customerData.customerAddresses &&
      customerData.customerAddresses.map((address) => {
        if (address.addressType == 1) {
          getbillingState(address?.addressCountry);
          setBillingData(address);
          setselectedbillingstate({
            id: address?.addressStateId,
            name: address?.addressState,
          });
        }
      });
  }
  function populateShippingAddressData() {
    customerData.customerAddresses &&
      customerData.customerAddresses.map((address) => {
        if (address.addressType == 2) {
          getshippingState(address.addressCountry);
          setAddressData(address);
          setselectedshippingstate({
            id: address.addressStateId,
            name: address.addressState,
          });
          setDefaultStateValue({
            value: address?.addressStateId,
            label: address?.addressState,
          });
        }
      });
  }
  function populateContactPersonData() {
    customerData.contactPersons &&
      setContactData(customerData.contactPersons[0]);
  }
  useEffect(() => {
    console.log("checkbox", checkbox);
    if (formType == "edit") {
      populateBillingAddressData();
      populateShippingAddressData();
      populateContactPersonData();
    }
  }, [customerData]);
  useEffect(() => {
    const contact = data.contactPersons?.find((cntct) => {
      return cntct;
    });

    setContactData({
      contactId: contact ? contact.contactId : "",
      contactSalutation:
        data.customerSalutation != null ? data.customerSalutation : "",
      contactFirstname:
        data.customerFirstname != null ? data.customerFirstname : "",
      contactLastname:
        data.customerLastname != null ? data.customerLastname : "",
      contactEmail: data.customerEmail != null ? data.customerEmail : "",
      contactMobile: customerMobile != null ? customerMobile : "",
    });
  }, [customerMobile, customerWorkPhone]);
  useEffect(() => {
    if (checkbox) {
      const aId = data.customerAddresses?.find((address) => {
        return address.addressType == 2;
      });
      setAddressData({
        addressId: formType == "edit" ? aId.addressId : "",
        addressAttention:
          billingData.addressAttention != null
            ? billingData.addressAttention
            : "",
        addressCountry:
          billingData.addressCountry != null ? billingData.addressCountry : "",
        addressLine1:
          billingData.addressLine1 != null ? billingData.addressLine1 : "",
        addressLine2:
          billingData.addressLine2 != null ? billingData.addressLine2 : "",
        addressCity:
          billingData.addressCity != null ? billingData.addressCity : "",
        addressStateId:
          billingData.addressStateId != null ? billingData.addressStateId : "",
        addressZipCode:
          billingData.addressZipCode != null ? billingData.addressZipCode : "",
        addressPhone:
          billingData.addressPhone != null ? billingData.addressPhone : "",
        addressFax:
          billingData.addressFax != null ? billingData.addressFax : "",
      });
      // const oldAddress = { ...billingData };
      // setAddressData(oldAddress);
      setselectedshippingstate({
        id: billingData.addressStateId,
        name: billingData.addressState,
      });
      setDefaultStateValue({
        value: billingData?.addressStateId,
        label: billingData?.addressState,
      });
      console.log("aId", aId);
      // getshippingState(parseInt(oldAddress.addressCountry));
    } else {
      setAddressData({
        addressId: "",
        addressAttention: "",
        addressCountry: "",
        addressLine1: "",
        addressLine2: "",
        addressCity: "",
        addressStateId: "",
        addressZipCode: "",
        addressPhone: "",
        addressFax: "",
      });
    }
  }, [checkbox]);

  const billingoptions = billingstate?.map((item) => {
    return {
      value: item.stateId,
      label: item.stateName,
    };
  });
  const shippingoptions = shippingstate?.map((item) => {
    return {
      value: item.stateId,
      label: item.stateName,
    };
  });
  const shippingStateInput = useMemo(() => {
    return (
      <Col md={12}>
        <label>State</label>
        <Select
          name="addressStateId"
          options={shippingoptions}
          defaultValue={defaultStateValue}
          onChange={(e) =>
            setselectedshippingstate({
              id: e.value,
              name: e.label,
            })
          }
        />
      </Col>
    );
  }, [
    shippingstate,
    shippingoptions,
    selectedshippingstate,
    defaultStateValue,
  ]);
  const handleStateChange = (e) => {
    console.log("E", e);
    setBillingData({ ...billingData, addressStateId: e.value });
    setselectedbillingstate({
      id: e.value,
      name: e.label,
    });
  };
  useEffect(() => {
    getshippingState(addressData.addressCountry);
    console.log("selectedshippingstate", addressData);
  }, [addressData.addressCountry]);
  useEffect(() => {
    if (checkbox) {
      getbillingState(country.id);
    }
  }, [country]);

  // only numbners in name
  const handleKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const isNumber = keyCode >= 48 && keyCode <= 57;

    if (isNumber) {
      event.preventDefault();
    }
  };
  return {
    addressData,
    selectedbillingstate,
    selectedshippingstate,
    setselectedshippingstate,
    setselectedbillingstate,
    billingData,
    contactData,
    setContactData,
    setBillingData,
    setAddressData,
    addressDataRender: (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggle("1");
              }}
            >
              Billing Address
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                toggle("2");
              }}
            >
              Shipping address
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "3",
              })}
              onClick={() => {
                toggle("3");
              }}
            >
              Contact Person
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={6}>
                    <label>Name *</label>
                    <AvField
                      name="addressAttention"
                      type="input"
                      onKeyPress={handleKeyPress}
                      onChange={(e) => billingDataHandle(e)}
                      value={billingData.addressAttention}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Enter the data",
                        },
                      }}
                    ></AvField>
                  </Col>
                  <Col md={6}>
                    <label
                    // className={
                    //   billingData.addressCountry == ""
                    //     ? "mandatory-fields"
                    //     : ""
                    // }
                    >
                      Country / Region *
                    </label>
                    <AvField
                      name="addressCountry"
                      type="select"
                      onChange={(e) => {
                        billingDataHandle(e);
                        getbillingState(e.target.value);
                      }}
                      value={billingData.addressCountry}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Enter the data",
                        },
                      }}
                    >
                      <option value="0" selected>
                        Select Country
                      </option>
                      {country.map((coun) => (
                        <option value={coun.countryId}>
                          {coun.countryName}
                        </option>
                      ))}
                    </AvField>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <label
                    // className={
                    //   billingData.addressLine1 == "" ? "mandatory-fields" : ""
                    // }
                    >
                      Address Line *
                    </label>
                    <AvField
                      name="addressLine1"
                      type="input"
                      placeholder="Enter address"
                      onChange={(e) => billingDataHandle(e)}
                      value={billingData.addressLine1}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Enter the data",
                        },
                      }}
                    ></AvField>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <label>Address Line 1</label>
                    <AvField
                      name="addressLine2"
                      type="textarea"
                      onChange={(e) => billingDataHandle(e)}
                      value={billingData.addressLine2}
                    ></AvField>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <label
                    // className={
                    //   billingData.addressCity == "" ? "mandatory-fields" : ""
                    // }
                    >
                      City *
                    </label>
                    <AvField
                      name="addressCity"
                      type="input"
                      onChange={(e) => billingDataHandle(e)}
                      value={billingData.addressCity}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Enter the data",
                        },
                      }}
                    ></AvField>
                  </Col>
                  <Col md={12}>
                    <label
                    // className={
                    //   selectedbillingstate.id == 0 ? "mandatory-fields" : ""
                    // }
                    >
                      State *
                    </label>
                    {!checkbox ? (
                      <Select
                        name="addressStateId"
                        options={billingoptions}
                        value={billingoptions?.filter(
                          (option) => option.value === selectedbillingstate.id
                        )}
                        onChange={(e) => handleStateChange(e)}
                      />
                    ) : (
                      <input
                        value={billingoptions?.filter(
                          (option) => option.value === selectedbillingstate.id
                        )}
                      />
                    )}
                  </Col>
                  <Col md={12}>
                    <label
                    // className={
                    //   billingData.addressZipCode == ""
                    //     ? "mandatory-fields"
                    //     : ""
                    // }
                    >
                      Zip code *
                    </label>
                    <AvField
                      name="addressZipCode"
                      type="number"
                      onChange={(e) => billingDataHandle(e)}
                      value={billingData.addressZipCode}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Enter the data",
                        },
                      }}
                    ></AvField>
                  </Col>
                  <Col md={6}>
                    <label>Phone</label>
                    <AvField
                      name="addressPhone"
                      type="number"
                      onChange={(e) => billingDataHandle(e)}
                      value={billingData.addressPhone}
                    ></AvField>
                  </Col>
                  <Col md={6}>
                    <label>Fax</label>
                    <AvField
                      name="addressFax"
                      type="input"
                      onChange={(e) => billingDataHandle(e)}
                      value={billingData.addressFax}
                    ></AvField>
                  </Col>
                </Row>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col md={12} className="mb-2">
                <AvField
                  type="checkbox"
                  name="avFieldCheckbox"
                  label="Same as billing address"
                  value={checkbox}
                  onChange={handleCheckBox}
                />
                <p>{checkbox}</p>
              </Col>
              {/* <span onClick={handleCheckBox}>Copy Billing Address</span> */}
            </Row>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={6}>
                    <label>Name</label>
                    <AvField
                      name="addressAttention"
                      type="text"
                      onKeyPress={handleKeyPress}
                      onChange={(e) => handle(e)}
                      value={addressData.addressAttention}
                    ></AvField>
                  </Col>
                  <Col md={6}>
                    <label>Country / Region</label>
                    <AvField
                      name="addressCountry"
                      type="select"
                      onChange={(e) => {
                        // getshippingState(e.target.value);
                        handle(e);
                      }}
                      value={addressData.addressCountry}
                    >
                      <option value="0" selected>
                        Select Country
                      </option>
                      {country.map((coun) => (
                        <option value={coun.countryId}>
                          {coun.countryName}
                        </option>
                      ))}
                    </AvField>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <label>Address Line</label>
                    <AvField
                      name="addressLine1"
                      type="text"
                      placeholder="Enter address"
                      onChange={(e) => handle(e)}
                      value={addressData.addressLine1}
                    ></AvField>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <label>Address Line 1</label>
                    <AvField
                      name="addressLine2"
                      type="textarea"
                      onChange={(e) => handle(e)}
                      value={addressData.addressLine2}
                    ></AvField>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <label>City</label>
                    <AvField
                      name="addressCity"
                      type="text"
                      onChange={(e) => handle(e)}
                      value={addressData.addressCity}
                    ></AvField>
                  </Col>
                  {shippingStateInput}
                  {/* <Col md={12}>
                    <label>State</label>
                    <Select
                      name="addressStateId"
                      options={shippingoptions}
                      defaultValue={
                        shippingoptions &&
                        shippingoptions.filter(
                          (option) => option.value === selectedshippingstate.id
                        )
                      }
                      onChange={(e) =>
                        setselectedshippingstate({
                          id: e.value,
                          name: e.label,
                        })
                      }
                    />
                  </Col> */}
                  <Col md={12}>
                    <label>Zip code</label>
                    <AvField
                      name="addressZipCode"
                      type="number"
                      onChange={(e) => handle(e)}
                      value={addressData.addressZipCode}
                    ></AvField>
                  </Col>
                  <Col md={6}>
                    <label>Phone</label>
                    <AvField
                      name="addressPhone"
                      type="number"
                      onChange={(e) => handle(e)}
                      value={addressData.addressPhone}
                    ></AvField>
                  </Col>
                  <Col md={6}>
                    <label>Fax</label>
                    <AvField
                      name="addressFax"
                      type="text"
                      onChange={(e) => handle(e)}
                      value={addressData.addressFax}
                    ></AvField>
                  </Col>
                </Row>
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="3">
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={4}>
                    <label>Salutation</label>
                    <AvField
                      name="contactSalutation"
                      type="select"
                      onChange={(e) => contactDataHandle(e)}
                      value={contactData.contactSalutation}
                    >
                      <option value="" selected disabled>
                        Select salutation
                      </option>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                      <option value="Dr">Dr</option>
                    </AvField>
                  </Col>

                  <Col md={8}>
                    <label>First Name</label>
                    <AvField
                      name="contactFirstname"
                      type="input"
                      onChange={(e) => contactDataHandle(e)}
                      value={contactData.contactFirstname}
                    ></AvField>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <label>Last Name</label>
                    <AvField
                      name="contactLastname"
                      type="input"
                      onChange={(e) => contactDataHandle(e)}
                      value={contactData.contactLastname}
                    ></AvField>
                  </Col>
                  <Col md={12}>
                    <label>Email</label>
                    <AvField
                      name="contactEmail"
                      type="email"
                      onChange={(e) => contactDataHandle(e)}
                      value={contactData.contactEmail}
                    ></AvField>
                  </Col>
                  <Col md={6}>
                    <label>Mobile</label>
                    <AvField
                      name="contactMobile"
                      type="number"
                      onChange={(e) => contactDataHandle(e)}
                      value={contactData.contactMobile}
                    ></AvField>
                  </Col>
                </Row>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </React.Fragment>
    ),
  };
}
export default ShippingAddress;
