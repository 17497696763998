// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { DATA_INITIALISE } from "./actionType";
import { fetchCustomerRequest } from "../customerDetails/actions";
import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";

/**
 * Show the rightsidebar
 */

function* getAllInitialData() {

  //   yield call(fetchCustomerRequest());
  yield call(fetchCustomerRequest);
}

/**
 * Watchers
 */

export function* watchgetAllInitialData() {
  yield takeEvery(DATA_INITIALISE, getAllInitialData);
}
function* InitialiseDataSaga() {
  yield all([fork(watchgetAllInitialData)]);
}

export default InitialiseDataSaga;
