import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import dyarco from "../../assets/dyarco/dyarco.jpeg";
import loader from "../../assets/loader/load.gif";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet, apiPost, apiPut } from "../../components/config/apiConfig";

import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomCheckbox from "../../components/Features/customcheckbox";
import { useSelector } from "react-redux";
import {
  addCommodityTableData,
  deleteTableRow,
  clearCommodityData,
  updateCommodityRowTotal,
  requestWarehouseData,
  getCurrentSelectedCustomer,
  addCommodityTableOtherCharges,
  updateCommodityOtherCharges,
  deleteOtherChargeTableRow,
  clearCustomerData,
  populateTableRowData,
  populateOtherChargeTableRowData,
  validateTableData,
} from "../../store/actions";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

const AddSubscription = (props) => {
  var formType = props.match.params.type;
  var id = props.match.params.id;
  const scrollRef = useRef(null);
  const selectRef = useRef(null);
  var current = new Date();
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1 < 10
      ? `0${current.getMonth() + 1}`
      : current.getMonth() + 1
  }-${current.getDate() < 10 ? `0${current.getDate()}` : current.getDate()}`;

  const history = useHistory();
  const form = useRef(null);
  const componentRef = useRef();
  const [reload, setReload] = useState(false);
  const [uom, setuom] = useState([]);
  const [handlinginfo, sethandlinginfo] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [warehouseList, setwarehouseList] = useState([]);
  const [checkinnumber, setcheckinnumber] = useState("");
  const [customerrequired, setcustomerrequired] = useState(false);
  const [warehouserequired, setwarehouserequired] = useState(false);
  const [state, setstate] = useState({});
  const [otherChargeName, setotherChargeName] = useState("");
  const [warehouseFacility, setWareHouseFacility] = useState([]);
  const [validateTable, setValidateTable] = useState(false);
  // function getWarehouseData() {
  //   const organisationId = localStorage.getItem("organisationId");
  //   const Url =
  //     BaseUrl.apiUrl.baseUrl + `api/v1/warehouse/org/${organisationId}`;
  //   let resp = apiGet(Url);
  //   resp.then((resp) => {
  //     console.log("warehouse list", resp);
  //     setwarehouseList(resp.response.data.data);
  //   });
  // }

  function getData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setCustomerList(resp.response.data.data);
    });
  }

  function getUOM() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/uom`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setuom(resp.response.data.data);
      }
    });
  }
  function getSubscriptionEligibility() {
    let warehouseId = subscriptiondata.warehouseId;
    let customerId = subscriptiondata.customerId;
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/check_wh_subscription/${warehouseId}/${customerId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        sethandlinginfo(resp.response.data.data);
      }
    });
  }

  const [subscriptiondata, setsubscriptiondata] = useState({
    warehouseId: "",
    warehouseName: "",
    warehouseNumber: "",
    customerId: "",
    customerName: "",
    customerNumber: "",
    areaCbm: "",
    areaSqm: "",
    rentAmount: "",
    rentAdditionalSpace: "",
    handlingCharge: "",
    totalMonths: "",
    startDate: "",
  });
  const [checkInWarehouseId, setcheckInWarehouseId] = useState({
    id: 0,
    name: "",
  });
  const [checkInWarehouseCustomer, setcheckInWarehouseCustomer] = useState({
    checkInCustomerId: 0,
    checkInCustomerName: "",
  });
  const [subscriptionType, setsubscriptionType] = useState({
    typeId: 0,
    typeName: "",
  });
  const [spaceType, setspaceType] = useState({
    typeId: 0,
    typeName: "",
  });
  const handle = (e) => {
    setsubscriptiondata((oldState) => {
      return {
        ...oldState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const addCommodity = (e, index) => {
    var tableValue = selectRef.current.value;
    props.addCommodityTableData(e);
    if (props.tableRowData.length > 0) {
      props.validateTableData();
    }
    // var data = e.target.defaultValue;
    // console.log("ref calue", data);
    // if (data) {
    //   props.addCommodityTableData(data);
    // }
  };
  // const handleCustomerChange = (e) => {
  //   setcargocheckindata({
  //     ...cargocheckindata,
  //     [cargocheckindata.checkInCustomerName]: e.value,
  //   });
  //   console.log("data", cargocheckindata);
  // };
  function createCargoCheckIn() {
    setReload(true);
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/wh_subscribe";
    let body = {
      warehouseId: parseInt(subscriptiondata.warehouseId),
      warehouseName: subscriptiondata.warehouseName,
      warehouseNumber: parseInt(subscriptiondata.warehouseNumber),
      customerId: parseInt(subscriptiondata.customerId),
      customerName: subscriptiondata.customerName,
      customerNumber: parseInt(subscriptiondata.customerNumber),
      type: subscriptionType.typeName,
      areaCbm: parseInt(subscriptiondata.areaCbm),
      areaSqm: parseInt(subscriptiondata.areaSqm),
      minimumSpaceType: spaceType.typeId,
      rentAmount: subscriptiondata.rentAmount,
      rentAdditionalSpace:
        subscriptiondata.rentAdditionalSpace.length > 0
          ? parseInt(subscriptiondata.rentAdditionalSpace)
          : props.selectedCustomer.customerFixedQuoteRate,
      handlingCharge: parseInt(subscriptiondata.handlingCharge),
      totalMonths: parseInt(subscriptiondata.totalMonths),
      startDate: subscriptiondata.startDate,
      organizationId: orgId,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
    };
    let resp = apiPost(url, body);
    resp.then((resp) => {
      setReload(false);
      switch (resp.response.data.code) {
        case 200:
          setcheckinnumber(resp.response.data.data);
          handlePrint();
          toast.success(
            `${resp.response.data.message} ${resp.response.data.status}`
          );
          setTimeout(() => {
            history.push("/subscription");
          }, 1000);
          break;

        default:
          toast.error(resp.response.data.data);
      }
    });
  }
  function updateCargoCheckIn() {
    setReload(true);
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + `api/v1/wh_subscribe/${id}`;
    let body = {
      warehouseId: parseInt(subscriptiondata.warehouseId),
      warehouseName: subscriptiondata.warehouseName,
      warehouseNumber: parseInt(subscriptiondata.warehouseNumber),
      customerId: parseInt(subscriptiondata.customerId),
      customerName: subscriptiondata.customerName,
      customerNumber: parseInt(subscriptiondata.customerNumber),
      type: subscriptionType.typeName,
      areaCbm: parseInt(subscriptiondata.areaCbm),
      areaSqm: parseInt(subscriptiondata.areaSqm),
      minimumSpaceType: spaceType.typeId,
      rentAmount: subscriptiondata.rentAmount,
      rentAdditionalSpace:
        subscriptiondata.rentAdditionalSpace.length > 0
          ? parseInt(subscriptiondata.rentAdditionalSpace)
          : props.selectedCustomer.customerFixedQuoteRate,
      handlingCharge: parseInt(subscriptiondata.handlingCharge),
      totalMonths: parseInt(subscriptiondata.totalMonths),
      startDate: subscriptiondata.startDate,
      organizationId: 1,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
    };
    let resp = apiPut(url, body);
    resp.then((resp) => {
      setReload(false);
      switch (resp.response.data.code) {
        case 200:
          setcheckinnumber(resp.response.data.data);
          // handlePrint();
          toast.success(
            `${resp.response.data.message} ${resp.response.data.status}`
          );
          setTimeout(() => {
            history.push(`/subscription`);
          }, 1000);
          break;

        default:
          toast.error(resp.response.data.data);
      }
    });
  }

  function handleSubmit(e) {
    if (props.validateValue == null) {
      setValidateTable(false);
      if (
        checkInWarehouseId.id != 0 &&
        checkInWarehouseCustomer.checkInCustomerId != 0
      ) {
        if (checkInWarehouseId.id != 0) {
          if (checkInWarehouseCustomer.checkInCustomerId != 0) {
            if (
              props.totalItemArea < props.warehouseData.warehouseAreaAvailable
            ) {
              if (formType == "add") {
                createCargoCheckIn();
              } else {
                updateCargoCheckIn();
              }
            } else {
              toast.info(
                "Exceeded the maximum space allocated for this warehouse"
              );
            }
          } else {
            setcustomerrequired(true);
          }
        } else {
          setwarehouserequired(true);
        }
      } else {
        setcustomerrequired(true);
        setwarehouserequired(true);
      }
    } else {
      executeScroll();
      setValidateTable(true);
    }
  }
  useEffect(() => {
    return () => {
      props.clearCustomerData();
    };
  }, []);

  useEffect(() => {
    if (props.validateValue == null) {
      setValidateTable(false);
    }
  }, [props.validateValue]);

  function getSubscriptionDataById() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/wh_subscribe/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      var datas = resp.response.data.data;
      var spacetype = datas.minimumSpaceType.toString();
      setsubscriptiondata(datas);
      setstate(datas);
      setcheckInWarehouseId({
        id: datas.warehouseId,
        name: datas.warehouseName,
      });
      setcheckInWarehouseCustomer({
        checkInCustomerId: datas.customerId,
        checkInCustomerName: datas.customerName,
      });
      setsubscriptionType({
        typeId: datas.type,
        typeName: datas.type,
      });
      setspaceType({
        typeId: spacetype,
        typeName: spacetype === "1" ? "Fixed" : "Minimum",
      });
      setArea(datas.areaCbm);
      props.getCurrentSelectedCustomer(datas.customerId);
      props.requestWarehouseData(datas.warehouseId);
      // props.populateTableRowData(datas.itemsList);
      // props.populateOtherChargeTableRowData(datas.chargeList);
      // setRowsData(datas.itemsList);
      // setOtherCharge(datas.chargeList);
    });
  }
  useEffect(() => {
    if (formType == "edit") {
      getSubscriptionDataById();
    }
    // getWarehouseData();
    getUOM();
    getData();
    // return () => {
    //   props.clearCommodityData();
    //   props.clearCustomerData();
    // };
  }, []);
  const warehouse =
    props.selectedCustomer.warehouseList &&
    props.selectedCustomer.warehouseList.map((item) => {
      return {
        value: item.warehouseId,
        label: item.warehouseName,
      };
    });
  const options = customerList.map((item) => {
    return {
      value: item.customerId,
      label: item.customerDisplayName,
    };
  });
  const typeOptions = [
    { value: "CBM", label: "CBM" },
    {
      value: "SQM",
      label: "SQM",
    },
  ];
  const spaceTypeOptions = [
    { value: "1", label: "Fixed" },
    {
      value: "2",
      label: "Minimum",
    },
  ];
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "print-btn",
    pageStyle: "margin:10px",
  });
  const clearForm = () => {
    // setsubscriptiondata({});
    form.current.reset();
    warehouse.reset();
    // props.clearCommodityData();
    // props.clearCustomerData();
    // setcommodityName("");
    // setotherChargeName("");
    //this will reset all the inputs in the form
  };
  const [area, setArea] = useState("");
  const handleChangeArea = (e) => {
    setsubscriptiondata((oldState) => {
      return {
        ...oldState,
        areaCbm: e.target.value,
        // areaSqm: e.target.value,
      };
    });
    setArea(e.target.value);
  };
  const handleChangeWarehouse = (e) => {
    setwarehouserequired(false);
    setcheckInWarehouseId({
      id: e.value,
      name: e.label,
    });
    setsubscriptiondata((oldState) => {
      return {
        ...oldState,
        warehouseId: e.value,
        warehouseName: e.label,
      };
    });
    props.requestWarehouseData(e.value);
    if (props.warehouseData.warehouseFacilities != null) {
      const facility =
        props.warehouseData.warehouseFacilities != null
          ? props.warehouseData.warehouseFacilities
          : "";
      const arrayFacility = facility.split(",");
    }
  };
  const handleChangeCustomer = (e) => {
    setcustomerrequired(false);

    setcheckInWarehouseCustomer({
      checkInCustomerId: e.value,
      checkInCustomerName: e.label,
    });
    setsubscriptiondata((oldState) => {
      return {
        ...oldState,
        customerId: e.value,
        customerName: e.label,
      };
    });
    props.getCurrentSelectedCustomer(e.value);
  };
  const warestyle = {
    control: (base) => ({
      ...base,
      borderColor: "#f46a6a",
    }),
  };
  const style = {
    control: (base) => ({
      ...base,
      borderColor: "#f46a6a",
    }),
  };
  const executeScroll = () => scrollRef.current.scrollIntoView();
  // function filteredArray() {
  //   const filterArray = (a, b) => {
  //     return a.filter((e) => {
  //       return e != b;
  //     });
  //   };
  //   let array = props.warehouseData.warehouseFacilities.split(",");
  //   let filter = filterArray(array, "");
  //   return filter;
  // }
  // useEffect(() => {
  //   if (props.warehouseData.warehouseFacilities != null) {
  //     filteredArray();
  //   }
  // }, [props.warehouseData]);
  // console.log("area", area);
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content" ref={scrollRef}>
        {validateTable ? (
          <Row className="m-0 mb-4">
            <Col md={12} className="bg-danger pt-3 white">
              <ul>
                <li>{props.validateValue}</li>
              </ul>
            </Col>
          </Row>
        ) : (
          ""
        )}

        <Container fluid>
          <Row>
            <Col md={12}>
              {formType == "add" ? (
                <h6>SUBSCRIPTION</h6>
              ) : (
                <h6>EDIT SUBSCRIPTION </h6>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className="form"
                    onValidSubmit={(e) => handleSubmit(e)}
                    ref={form}
                    // model={data}
                  >
                    <Row>
                      <Col md={12} className="">
                        <Row>
                          {/* <Col md={2}>
                            <label>CheckIn date</label>
                            <AvField
                              name="checkInDate"
                              type="date"
                              onChange={handle}
                              value={cargocheckindata.checkInDate}
                              required
                            ></AvField>
                          </Col> */}
                          <Col md={4}>
                            <label>Customer Name</label>
                            <Select
                              name="checkInCustomerName"
                              styles={customerrequired ? style : ""}
                              onChange={handleChangeCustomer}
                              value={options.filter(
                                (option) =>
                                  option.label ===
                                  checkInWarehouseCustomer.checkInCustomerName
                              )}
                              options={options}
                              // onBlur={() => {
                              //   setwarehouseList(
                              //     props.selectedCustomer.warehouseList
                              //   );
                              //   console.log("warehopseasdkasd", warehouseList);
                              // }}
                              isDisabled={formType == "edit"}
                              required
                            />
                            {customerrequired ? (
                              <p style={{ fontSize: "80%", color: "#f46a6a" }}>
                                This field is invalid
                              </p>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col md={3}>
                            <label>Warehouse</label>
                            <Select
                              name="checkInWarehouseId"
                              onChange={handleChangeWarehouse}
                              options={warehouse}
                              value={warehouse?.filter(
                                (option) =>
                                  option.label === checkInWarehouseId.name
                              )}
                              required
                              styles={warehouserequired ? warestyle : ""}
                              isDisabled={formType == "edit"}
                            />
                            {subscriptiondata.warehouseId == ""
                              ? ""
                              : getSubscriptionEligibility()}
                            {checkInWarehouseId.id != 0 &&
                            (props.warehouseData.warehouseAreaAvailable ==
                              null ||
                              props.warehouseData.warehouseAreaAvailable ==
                                "0") ? (
                              <span style={{ color: "red" }}>
                                Warehouse have no storage space
                              </span>
                            ) : (
                              ""
                            )}
                            {warehouserequired ? (
                              <p style={{ fontSize: "80%", lor: "#f46a6a" }}>
                                This field is invalid
                              </p>
                            ) : (
                              ""
                            )}
                            {formType == "add" &&
                            handlinginfo == "Already Subscribed" ? (
                              <span style={{ color: "red" }}>
                                Already Subscribed
                              </span>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col md={3}>
                            <label>Type</label>
                            <Select
                              onChange={(e) =>
                                setsubscriptionType({
                                  typeId: e.value,
                                  typeName: e.label,
                                })
                              }
                              options={typeOptions}
                              value={typeOptions?.filter(
                                (option) =>
                                  option.label === subscriptionType.typeName
                              )}
                              required
                              // styles={warehouserequired ? warestyle : ""}
                              // isDisabled={formType == "edit"}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          {subscriptionType.typeName === "CBM" ||
                          subscriptionType.typeName === "" ? (
                            <Col md={3}>
                              <label>Area CBM</label>
                              <AvField
                                name="areaCbm"
                                onChange={handle}
                                required
                                type="text"
                                value={subscriptiondata.areaCbm}
                              />
                              {parseInt(subscriptiondata.areaCbm) >
                              parseInt(
                                props.warehouseData.warehouseAreaAvailable
                              ) ? (
                                <span style={{ color: "red" }}>
                                  Warehouse have no storage space
                                </span>
                              ) : (
                                ""
                              )}
                            </Col>
                          ) : (
                            <Col md={3}>
                              <label>Area SQM</label>
                              <AvField
                                name="areaSqm"
                                onChange={handle}
                                required
                                type="text"
                                value={subscriptiondata.areaSqm}
                              />
                              {/* {parseInt(subscriptiondata.areaSqm) >
                              parseInt(
                                props.warehouseData.warehouseAreaAvailable
                              ) ? (
                                <span style={{ color: "red" }}>
                                  Warehouse have no storage space
                                </span>
                              ) : (
                                ""
                              )} */}
                            </Col>
                          )}
                          <Col md={3}>
                            <label>Space Type</label>
                            <Select
                              onChange={(e) =>
                                setspaceType({
                                  typeId: e.value,
                                  typeName: e.label,
                                })
                              }
                              options={spaceTypeOptions}
                              value={spaceTypeOptions?.filter(
                                (option) => option.label === spaceType.typeName
                              )}
                              required
                              // styles={warehouserequired ? warestyle : ""}
                              // isDisabled={formType == "edit"}
                            />
                          </Col>
                          <Col md={3}>
                            <label>
                              {spaceType.typeName === "Fixed" ||
                              spaceType.typeName === ""
                                ? "Fixed Amount"
                                : "Minimum Amount"}
                            </label>
                            <AvField
                              name="rentAmount"
                              onChange={handle}
                              type="Number"
                              value={subscriptiondata.rentAmount}
                              required
                            />
                          </Col>
                          <Col md={3}>
                            <label>Additional Amount</label>
                            <AvField
                              name="rentAdditionalSpace"
                              onChange={handle}
                              // value={
                              //   subscriptiondata.rentAdditionalSpace ||
                              //   props.selectedCustomer.customerFixedQuoteRate
                              // }value={
                              value={
                                formType == "edit"
                                  ? subscriptiondata.rentAdditionalSpace
                                  : props.selectedCustomer
                                      .customerFixedQuoteRate
                                  ? props.selectedCustomer
                                      .customerFixedQuoteRate
                                  : subscriptiondata.rentAdditionalSpace
                              }
                              required
                              type="number"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <label>Start Date</label>
                            <AvField
                              name="startDate"
                              onChange={handle}
                              type="date"
                              min={formType == "add" ? date : ""}
                              required
                              value={subscriptiondata.startDate}
                              disabled={formType == "edit"}
                            />
                          </Col>
                          <Col md={3}>
                            <label>Number of Months</label>
                            <AvField
                              name="totalMonths"
                              type="number"
                              onChange={handle}
                              required
                              value={subscriptiondata.totalMonths}
                              // min={formType == "edit" ? state.totalMonths : ""}
                            />
                          </Col>
                        </Row>
                        {/* <hr /> */}
                      </Col>
                    </Row>

                    {/* <BottomBar /> */}
                    <div className="bottom-bar">
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={4}>
                              <Link to="/subscription">
                                <button
                                  type="button"
                                  className="btn btn-left ml-3"
                                >
                                  Cancel
                                </button>
                              </Link>
                            </Col>
                            {formType == "add" ? (
                              <Col md={4}>
                                <button
                                  type="button"
                                  onClick={clearForm}
                                  className="btn btn-left"
                                >
                                  Clear
                                </button>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Col>

                        <Col md={8}>
                          <button
                            type="submit"
                            className="btn btn-right float-right mr-3"
                            disabled={
                              formType == "add" &&
                              handlinginfo == "Already Subscribed"
                            }
                          >
                            {reload ? (
                              <img
                                className="loader"
                                src={loader}
                                alt="loading..."
                              />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const {
    tableRowData,
    warehouseData,
    totalItemArea,
    otherChargeTableData,
    validateValue,
  } = state.CommodityTableData;
  const { selectedCustomer } = state.Layout;
  return {
    tableRowData,
    warehouseData,
    totalItemArea,
    selectedCustomer,
    otherChargeTableData,
    validateValue,
  };
};
export default connect(mapStatetoProps, {
  addCommodityTableData,
  deleteTableRow,
  updateCommodityRowTotal,
  clearCommodityData,
  requestWarehouseData,
  getCurrentSelectedCustomer,
  addCommodityTableOtherCharges,
  updateCommodityOtherCharges,
  deleteOtherChargeTableRow,
  clearCustomerData,
  populateTableRowData,
  populateOtherChargeTableRowData,
  validateTableData,
})(AddSubscription);
