import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";

import loader from "../../assets/loader/load.gif";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet, apiPost, apiPut } from "../../components/config/apiConfig";

//import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomCheckbox from "../../components/Features/customcheckbox";
import { useSelector } from "react-redux";
import { useRef } from "react";
const AddBinLocation = (props) => {
  var indexAisle = "",
    labelAisle = "",
    indexBin = "",
    indexRack = "",
    indexRow = "",
    labelBin = "",
    labelRack = "",
    labelRow = "";
  var formType = props.match.params.type;
  var id = props.match.params.id;
  const form = useRef(null);
  const [uncheck, setuncheck] = useState([]);
  const history = useHistory();
  const [isrentable, setisrentable] = useState(false);
  const [requireFacility, setRequireFacility] = useState(false);
  const [aisleList, setAisleList] = useState([]);
  const [rackList, setRackList] = useState([]);
  const [rowList, setRowList] = useState([]);
  const [binList, setBinList] = useState([]);
  const { checkbox, setcheckboxdata, checkboxdata, facilities } =
    CustomCheckbox({ setRequireFacility });
  const [reload, setReload] = useState(false);
  //const inputRef = [useRef(1),useRef(2), useRef(3)];

  const [warehousedata, setwarehousedata] = useState({
    binLocation: "",
    barcode: "",
    aisleId: 0,
    rackId: 0,
    rowId: 0,
    binId: 0,
    usingPurpose: "",
    capacity: "",
    efficiency: "",
  });

  function getAisleList() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/aisle2`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      console.log("aisle list", resp);
      if (resp.response.data.code == 200) {
        setAisleList(resp.response.data.data);
      }
    });
  }
  function getRackList() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/racks2`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setRackList(resp.response.data.data);
      }
    });
  }
  function getRowList() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/rows2`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setRowList(resp.response.data.data);
      }
    });
  }
  function getBinList() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/bin2`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setBinList(resp.response.data.data);
      }
    });
  }

  function isRentable() {
    setisrentable(!isrentable);
  }

  const [binLocationName, setBinLocationName] = useState("");
  /*const [binLocationName, setBinLocationName] = useState({
    aisle: "",
    barcode: "",
    capacity: "",
    efficiency: "",
  });*/

  const handle = (e, index) => {
    /*if (index <= 3) {
      var updatedValues = [...binLocationName];
      var indexAisle = e.nativeEvent.target.selectedIndex;
      updatedValues[index] = e.nativeEvent.target[indexAisle].text;
    }*/
    setwarehousedata({
      ...warehousedata,
      [e.target.name]: e.target.value,
    });

    // setBinLocationName(updatedValues);
    //warehousedata.binLocation = setBinLocationName(updatedValues);
    //let indexAisle = e.nativeEvent.target.selectedIndex;
    //let labelAisle = e.nativeEvent.target[index].text;
    // setBinLocationName(label || []);
  };

  const handleChange = (e, index) => {
    console.log("hnadle change");
    setwarehousedata({
      ...warehousedata,
      [e.target.name]: e.target.value,
    });

    var newValue = [...binLocationName];
    //newValue[index] = value;

    var indexAisle = e.nativeEvent.target.selectedIndex;
    newValue[index] = e.nativeEvent.target[indexAisle].text;

    /*const newElement = e.nativeEvent.target[indexAisle].text;
    const updatedArray = [
      ...newValue.slice(0, index),
      newElement,
      ...newValue.slice(index),
    ];

    console.log(updatedArray);*/

    /*const newArray = [
      ...newValue.slice(0, index),
      e.nativeEvent.target[indexAisle].text,
      ...newValue.slice(index),
    ];*/

    console.log("hnadle change 1", newValue);
    setBinLocationName(newValue);
    warehousedata.binLocation = setBinLocationName(newValue);
  };

  useEffect(() => {
    console.log("data", warehousedata);
  }, [warehousedata]);

  function createBinLocation() {
    setReload(true);
    let orgId = localStorage.getItem("organisationId");
    console.log("orgid", orgId);
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/bin_location";
    console.log("bin lc nam", binLocationName);
    let body = {
      binLocation: `${
        aisleList.find((aisle) => aisle.id == warehousedata.aisleId)?.name
      }-${rackList.find((rack) => rack.id == warehousedata.rackId)?.name}-${
        rowList.find((row) => row.id == warehousedata.rowId)?.name
      }-${binList.find((bin) => bin.id == warehousedata.binId)?.name}`,
      barcode: warehousedata.barcode,
      aisleId: parseInt(warehousedata.aisleId),
      rackId: parseInt(warehousedata.rackId),
      rowId: parseInt(warehousedata.rowId),
      binId: parseInt(warehousedata.binId),
      usingPurpose: warehousedata.usingPurpose,
      capacity: warehousedata.capacity,
      efficiency: warehousedata.efficiency,
      organizationId: orgId,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
    };
    let resp = apiPost(url, body);
    resp.then((resp) => {
      setReload(false);
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            history.push("/bin-location");
            // useHistory().push("/customerlist");
          }, 1000);
          break;

        default:
          toast.error(resp.response.data.data);
      }
    });
  }
  function updateBinLocation() {
    setReload(true);
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + `api/v1/bin_location/${id}`;
    let body = {
      binLocation: `${
        aisleList.find((aisle) => aisle.id == warehousedata.aisleId)?.name
      }-${rackList.find((rack) => rack.id == warehousedata.rackId)?.name}-${
        rowList.find((row) => row.id == warehousedata.rowId)?.name
      }-${binList.find((bin) => bin.id == warehousedata.binId)?.name}`,
      barcode: warehousedata.barcode,
      aisleId: parseInt(warehousedata.aisleId),
      rackId: parseInt(warehousedata.rackId),
      rowId: parseInt(warehousedata.rowId),
      binId: parseInt(warehousedata.binId),
      usingPurpose: warehousedata.usingPurpose,
      capacity: warehousedata.capacity,
      efficiency: warehousedata.efficiency,

      organizationId: orgId,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
    };
    let resp = apiPut(url, body);
    resp.then((resp) => {
      setReload(false);
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            history.push("/bin-location");
            // useHistory().push("/customerlist");
          }, 1000);
          break;

        default:
          toast.error(resp.response.data.data);
      }
    });
  }
  function getBinLocationDataById() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/bin_location/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      var datas = resp.response.data.data;
      setwarehousedata(datas);
      //setBinLocationName(datas.binLocation);
      setcheckboxdata((data) => {
        return data.map((item) => {
          /*if (datas.warehouseFacilities.includes(item.name)) {
            return { ...item, checked: true };
          }*/
          return item;
        });
      });
    });
  }

  function handleSubmit(e) {
    if (formType == "add") {
      createBinLocation();
    } else {
      updateBinLocation();
    }
  }

  const clearForm = () => {
    form.current.reset(); //this will reset all the inputs in the form
    setuncheck(
      checkboxdata.map((items, index) => {
        return (items.checked = false);
      })
    );
  };
  useEffect(() => {
    getAisleList();
    getRackList();
    getRowList();
    getBinList();
  }, [facilities]);

  useEffect(() => {
    if (formType == "edit") {
      getBinLocationDataById();
      getAisleList();
    }
  }, [formType]);
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>
                {formType == "add"
                  ? "ADD NEW BIN LOCATION"
                  : "EDIT BIN LOCATION"}
              </h6>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className="form"
                    onValidSubmit={(e) => handleSubmit(e)}
                    ref={form}
                    // model={data}
                  >
                    <Row>
                      <Col md={12} className="formLeft">
                        <Row></Row>
                        <Row>
                          <Col md={12}>
                            <label>
                              <b>Setup Bin Position</b>
                            </label>
                            <Row>
                              <Col md={6}>
                                <label>Aisle</label>
                                <AvField
                                  name="aisleId"
                                  type="select"
                                  onChange={(e) => handleChange(e, 0)}
                                  value={warehousedata.aisleId}
                                  required
                                >
                                  <option value="" selected disabled>
                                    Select Aisle
                                  </option>
                                  {aisleList.map((ail) => (
                                    <option value={ail.id}>{ail.name}</option>
                                  ))}
                                </AvField>
                              </Col>
                              <Col md={6}>
                                <label>Rack</label>
                                <AvField
                                  name="rackId"
                                  type="select"
                                  onChange={(e) => handleChange(e, 1)}
                                  value={warehousedata.rackId}
                                  required
                                >
                                  <option value="" selected disabled>
                                    Select Rack
                                  </option>
                                  {rackList.map((rk) => (
                                    <option value={rk.id}>{rk.name}</option>
                                  ))}
                                </AvField>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <label>Row</label>
                            <AvField
                              name="rowId"
                              type="select"
                              onChange={(e) => handleChange(e, 2)}
                              value={warehousedata.rowId}
                              required
                            >
                              <option value="" selected disabled>
                                Select Row
                              </option>
                              {rowList.map((rw) => (
                                <option value={rw.id}>{rw.name}</option>
                              ))}
                            </AvField>
                          </Col>
                          <Col md={6}>
                            <label>Bin</label>
                            <AvField
                              name="binId"
                              type="select"
                              onChange={(e) => handleChange(e, 3)}
                              value={warehousedata.binId}
                              required
                            >
                              <option value="" selected disabled>
                                Select Bin
                              </option>
                              {binList.map((bn) => (
                                <option value={bn.id}>{bn.name}</option>
                              ))}
                            </AvField>
                          </Col>
                        </Row>
                        <hr />
                        <Row className="mb-3">
                          <Col md={12}>
                            <strong>Bin Identification</strong>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <label>Bin Location</label>
                            {/* <AvField
                              name="binLocation"
                              type="text"
                              //onChange={(e) => handle(e)}
                              value={binLocationName}
                              readOnly
                            ></AvField> */}
                            <Row
                            //  style={{ alignItems: "center" }}
                            >
                              <Col md={2}>
                                {" "}
                                <AvField
                                  name="aisleId1"
                                  readOnly
                                  style={{ textAlign: "center" }}
                                  // value={warehousedata.aisleId}
                                  value={
                                    aisleList.find(
                                      (aisle) =>
                                        aisle.id == warehousedata.aisleId
                                    )?.name
                                  }
                                />
                              </Col>

                              <Col md={2}>
                                {" "}
                                <AvField
                                  name="rackId1"
                                  readOnly
                                  style={{ textAlign: "center" }}
                                  // value={warehousedata.rackId}
                                  value={
                                    rackList.find(
                                      (rack) => rack.id == warehousedata.rackId
                                    )?.name
                                  }
                                />
                              </Col>

                              <Col md={2}>
                                {" "}
                                <AvField
                                  name="rowId1"
                                  readOnly
                                  style={{ textAlign: "center" }}
                                  // value={warehousedata.rowId}
                                  value={
                                    rowList.find(
                                      (row) => row.id == warehousedata.rowId
                                    )?.name
                                  }
                                />
                              </Col>

                              <Col md={2}>
                                {" "}
                                <AvField
                                  name="binId1"
                                  readOnly
                                  style={{ textAlign: "center" }}
                                  // value={warehousedata.binId}
                                  value={
                                    binList.find(
                                      (bin) => bin.id == warehousedata.binId
                                    )?.name
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md={6}>
                            <label>Barcode</label>

                            <AvField
                              name="barcode"
                              type="text"
                              onChange={(e) => handle(e)}
                              value={warehousedata.barcode}
                              required
                            ></AvField>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={12}>
                            <label>
                              <b>Using Purpose</b>
                            </label>
                            <Row>
                              <Col md={6}>
                                <label>Using Purpose</label>
                                <AvField
                                  name="usingPurpose"
                                  type="select"
                                  onChange={(e) => handle(e)}
                                  value={warehousedata.usingPurpose}
                                  required
                                >
                                  <option value="" selected disabled>
                                    Select Using Purpose
                                  </option>
                                  <option value="Available for Selling">
                                    Available for Selling
                                  </option>
                                  <option value="Available for Replenish">
                                    Available for Replenish
                                  </option>
                                  <option value="Available for Storage">
                                    Available for Storage
                                  </option>
                                </AvField>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={12}>
                            <label>
                              <b>Capacity & Efficiency</b>
                            </label>
                            <Row>
                              <Col md={6}>
                                <label>Efficiency</label>
                                <AvField
                                  name="efficiency"
                                  type="text"
                                  onChange={(e) => handle(e)}
                                  value={warehousedata.efficiency}
                                  required
                                ></AvField>
                              </Col>
                              <Col md={6}>
                                <label>Capacity</label>
                                <AvField
                                  name="capacity"
                                  type="text"
                                  onChange={(e) => handle(e)}
                                  value={warehousedata.capacity}
                                  required
                                ></AvField>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr />
                      </Col>
                    </Row>

                    {/* <BottomBar /> */}
                    <div className="bottom-bar">
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={4}>
                              <Link to="/warehouse-list">
                                <button
                                  type="button"
                                  className="btn btn-left ml-3"
                                >
                                  Cancel
                                </button>
                              </Link>
                            </Col>
                            {formType == "add" ? (
                              <Col md={4}>
                                <button
                                  type="button"
                                  onClick={clearForm}
                                  className="btn btn-left"
                                >
                                  Clear
                                </button>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Col>
                        {/* <Col md={4} className="printView">
                          <span className="print">Print or Preview</span>
                        </Col> */}
                        <Col md={8}>
                          <button
                            type="submit"
                            className="btn btn-right float-right mr-3"
                          >
                            {reload ? (
                              <img
                                className="loader"
                                src={loader}
                                alt="loading..."
                              />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(AddBinLocation);
