import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import { AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import { BaseUrl } from "../../components/config/BaseUrl";
import { connect } from "react-redux";
import { apiGet } from "../../components/config/apiConfig";
//i18n
import {
  getCurrentSelectedCustomer,
  getCurrentSelectedItem,
} from "../../store/actions";

const AddGoodsTransfer = (props) => {
  const [data, setData] = useState({
    transferDate: "",
    transferTime: "",
    transferReason: "",
    sourceWarehouseId: "",
    destinationWarehouseId: "",
  });
  const [customerDetails, setCustomerDetails] = useState({});

  function handle(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }
  function handleCustomerChange(e) {
    props.getCurrentSelectedCustomer(e.target.value);
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }

  return (
    <React.Fragment>
      <Row className="mb-3"></Row>
      <Row className="mb-3">
        <Col md={4}>
          <label>Transfer Date *</label>
          <AvField
            name="transferDate"
            type="date"
            placeholder="Enter Transfer Date"
            value={data.transferDate}
            onChange={(e) => handle(e)}
          ></AvField>
        </Col>
        <Col md={4}>
          <label>Transfer Time *</label>
          <AvField
            name="transferTime"
            type="time"
            placeholder="Select Invoice Time"
            value={data.transferTime}
            onChange={(e) => handle(e)}
          ></AvField>
        </Col>
        <Col md={4}>
          <label>Transfer Reason *</label>
          <AvField
            name="transferReason"
            type="text"
            placeholder="Transfer Reason"
            value={data.transferReason}
            onChange={(e) => handle(e)}
          ></AvField>
        </Col>
      </Row>
      <hr />
      <Row className="mb-4">
        <Col md={4}>
          <label>GST Treatment</label>
          <AvField
            name="gst"
            type="text"
            placeholder="Enter GST"
            value={data.gstNum}
            onChange={(e) => handle(e)}
          ></AvField>
          <Link>
            <i class="bx bx-edit"></i>{" "}
            <span className="addlinks">Edit GST details</span>
          </Link>
        </Col>

        <Col md={4}>
          <label>Terms of Pay</label>
          <AvField
            name="terms"
            type="select"
            placeholder="&#x260E; Select Terms"
            value={data.terms}
            onChange={(e) => handle(e)}
          >
            <option>John Sebastian Doe</option>
            <option>John Sebastian Doe</option>
          </AvField>
        </Col>
        <Col md={4}>
          <label>Salesperson</label>
          <AvField
            name="salesperson"
            type="input"
            placeholder="Enter person name"
            value={data.salesPerson}
            onChange={(e) => handle(e)}
          ></AvField>
          <Link>
            <i class="bx bx-plus"></i>{" "}
            <span className="addlinks">Add a new sales person</span>
          </Link>
        </Col>
      </Row>
      <Row>
        <div class="r-payment">
          <Col>
            <AvField
              type="checkbox"
              name="avFieldCheckbox"
              label="I have received payment"
              // value={checkbox}
              // onChange={handleCheckBox}
            />
          </Col>
        </div>

        <div className="gateway">
          <Link>
            <i class="bx bx-plus"></i>
            <span>Setup payment gateway</span>
          </Link>
        </div>
      </Row>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { selectedCustomerId, selectedCustomer } = state.Layout;
  const { selectedItemId, selectedItem } = state.ItemDetails;
  return {
    selectedCustomerId,
    selectedCustomer,
    selectedItemId,
    selectedItem,
  };
};
export default connect(mapStatetoProps, {
  getCurrentSelectedCustomer,
  getCurrentSelectedItem,
})(AddGoodsTransfer);
