import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import styled from "styled-components";
import SingleCustomerView from "../Customer/viewCustomer/singleCustomerView";
import {
  checkboxSelection,
  headerCheckboxSelection,
  sideBar,
} from "../Aggrid fucntions/aggrid-functions";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";
import { useHistory } from "react-router-dom";
const Customers = () => {
  const history = useHistory();
  const [rowClick, setRowClick] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(100000000);
  const [filterText, setFilterText] = React.useState("");
  const [rowData, setRowData] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "customerDisplayName",
      headerName: "Name",
      headerTooltip: "Name",
      checkboxSelection: checkboxSelection,
      headerCheckboxSelection: headerCheckboxSelection,
    },
    {
      field: "customerCompanyName",
      headerName: "Company Name",
      headerTooltip: "Company Name",
    },
    {
      field: "customerEmail",
      headerName: "Email",
      headerTooltip: "Email",
      width: "max-content",
    },

    {
      field: "customerWorkPhone",
      headerName: "Work Phone",
      headerTooltip: "Work Phone",
    },
    {
      field: "customerNumber",
      headerName: "Customer ID",
      headerTooltip: "Customer ID",
    },
    {
      field: "customerCreditLimit",
      headerName: "Credit Limit",
      headerTooltip: "Credit Limit",
    },
    {
      field: "customerCurrency",
      headerName: "Currency",
      headerTooltip: "Currency",
    },
  ]);

  const [columnDefs1, setColumDefs1] = useState([
    {
      field: "customerDisplayName",
      headerName: "Name",
      headerTooltip: "Name",
      checkboxSelection: checkboxSelection,
      headerCheckboxSelection: headerCheckboxSelection,
    },
  ]);

  const getData = async (page) => {
    setLoading(true);
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/customers/org?org_id=${organisationId}&page=${page}&size=${perPage}&sort=customerId,desc&sort=customerFirstname,asc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      console.log("customer list", resp.response.data.data.totalElements);
      setRowData(resp.response.data.data.content);
      setTotalRows(resp.response.data.data.totalElements);
      setLoading(false);
    });
  };

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
    }),
    []
  );

  const handleRowClick = (row) => {
    setCustomerId(row.data.customerId);
  };
  const resetCustomerId = () => {
    setCustomerId("");
  };
  useEffect(() => {
    getData(1);
  }, [filterText]);
  const cellClicked = (params) => {
    params.node.setSelected(true);
  };
  const handleAdd = () => {
    history.push(`/customer/add`);
  };
  return (
    <React.Fragment>
      <PageContent $singleView={customerId}>
        <ListHead>
          <Title>Customer</Title>
          <ListButtons>
            <Button onClick={handleAdd}>
              <i class="fa fa-plus" aria-hidden="true"></i> New
            </Button>
            <Button $primary>
              <i class="fa fa-cog" aria-hidden="true"></i>
            </Button>
          </ListButtons>
        </ListHead>
        <ListGridTable className="ag-theme-alpine">
          <AgGridReact
            rowData={rowData}
            columnDefs={customerId ? columnDefs1 : columnDefs}
            onRowClicked={handleRowClick}
            animateRows={true}
            pagination={true} // Only show pagination if totalRows > 15
            paginationAutoPageSize={true}
            sideBar={customerId ? false : sideBar}
            defaultColDef={defaultColDef}
            // onCellClicked={cellClicked}
            // headerHeight={rowClick ? 0 : 40}
          ></AgGridReact>
        </ListGridTable>
      </PageContent>
      {customerId ? (
        <SingleCustomerView close={resetCustomerId} custId={customerId} />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

const PageContent = styled.div`
  padding: calc(60px + 24px) 0 60px 0;
  width: ${(props) => (props.$singleView ? "390px" : "100%")};
  overflow: auto;
  border-right: ${(props) =>
    props.$singleView ? "0.5px solid #aeaeae;" : "none"};
  transition: all 0.3s ease-out;
  @media (max-width: 1200px) {
    display: ${(props) => (props.$singleView ? "none" : "block")};
  }
`;
const ListHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #bfbfbf;
  padding: 0 11px 20px 15px;
`;
const Title = styled.h4`
  margin-bottom: 0;
`;
const ListButtons = styled.div`
  display: flex;
  gap: 1.5em;
  align-items: center;

  @media (max-width: 1366px) {
    gap: 1em;
  }
`;
const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${(props) => (props.$primary ? "#ffffff" : "#3cba00")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: ${(props) => (props.$primary ? "0.5px solid #aeaeae;" : "none")};
  padding: 0 1.5em;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  color: ${(props) => (props.$primary ? "black" : "white")};
  height: 40px;
  transition: all 0.3s ease-in-out;
  @media (max-width: 1366px) {
    padding: 0 1em;
  }
`;
const ListGridTable = styled.div`
  width: 100%;
  height: calc(100% - 64px);
`;
export default Customers;
