import { DATA_INITIALISE } from "./actionType";
const INIT_STATE = {
  isLoading: false,
};
const InitialDatas = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DATA_INITIALISE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
export default InitialDatas;
