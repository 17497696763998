import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Input,
  ButtonGroup,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Formik, Field } from "formik";
//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../../components/config/BaseUrl";
import { apiGet } from "../../../components/config/apiConfig";
const VendorStatements = (props) => {
  const [vendorData, setVendorData] = useState({});

  useEffect(() => {
    setVendorData(props.data);
  }, [props]);
  const [dropdownMonth, setMonth] = React.useState(false);
  const toggleMonth = () => setMonth(!dropdownMonth);
  const [dropdownFilter, setFilter] = React.useState(false);
  const toggleFilter = () => setFilter(!dropdownFilter);
  const [dropdownOpen, setOpen] = React.useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  return (
    <React.Fragment className="statement">
      <Row className="headerButtons">
        <Col md={2} className="formLeft ">
          <Col>
            <ButtonDropdown isOpen={dropdownMonth} toggle={toggleMonth}>
              <DropdownToggle caret>System Mails</DropdownToggle>
              <DropdownMenu>
                {/* <DropdownItem header>item 1</DropdownItem> */}
                {/* <DropdownItem disabled>item 2</DropdownItem> */}
                <DropdownItem>Today</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>This Week</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>This Month</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>This Quarter</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>This Year</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Yesterday</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Last Week</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Last Month</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Last Quarter</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Last Year</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Custom</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>System mail history</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Col>
        <Col md={2} className="formLeft ">
          <Col>
            <ButtonDropdown isOpen={dropdownFilter} toggle={toggleFilter}>
              <DropdownToggle caret>Filter by:</DropdownToggle>
              <DropdownMenu>
                {/* <DropdownItem header>item 1</DropdownItem> */}
                {/* <DropdownItem disabled>item 2</DropdownItem> */}
                <DropdownItem>All</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Outstanding</DropdownItem>
                <DropdownItem divider />
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Col>
        <Col className="formLeft ">
          <Col>
            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret>Branch :</DropdownToggle>
              <DropdownMenu>
                {/* <DropdownItem header>item 1</DropdownItem> */}
                {/* <DropdownItem disabled>item 2</DropdownItem> */}
                <DropdownItem>All Branch</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Head Office</DropdownItem>
                <DropdownItem divider />
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Col>
        {/* <div md={4} className="HeaderButtonRight" style={{ float: "right" }}> */}
        <button
          md={4}
          title="print"
          style={{ border: "0px", borderRadius: "3px", padding: "10px" }}
        >
          Print
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          md={4}
          title="pdf"
          style={{ border: "0px", borderRadius: "3px", padding: "10px" }}
        >
          PDF
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          md={4}
          title="xls"
          style={{ border: "0px", borderRadius: "3px", padding: "10px" }}
        >
          XLS
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          md={4}
          title="Sent Mail"
          style={{
            border: "0px",
            borderRadius: "3px",
            padding: "10px",
            bgColor: "blue",
          }}
        >
          Sent Mail
        </button>
        {/* </div> */}
      </Row>
      <Row className="textCenter">
        <Col className="textCenter">
          <h4>vendors Statement for {vendorData.vendorDisplayName}</h4>
          <h5>
            <span>From</span>&nbsp;01/06/2022&nbsp;<span>To</span>
            &nbsp;30/06/2022
          </h5>
        </Col>{" "}
      </Row>
      <Row className="detailContainer">
        <Col className="detailContainer">
          <div md={4} class="details-container clearfix">
            <div class="orgdetails-update-frmtxndetails">
              {/* <button id="ember1289" class="ember-view btn btn-primary ">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                viewBox="0 0 512 512"
                // xml:space="preserve"
                class="icon icon-xs align-text-bottom"
              >
                <path d="M258.3 149.8c-57.3 0-103.9 46.6-103.9 103.9 0 57.3 46.6 103.9 103.9 103.9s104-46.6 104-103.9c0-57.3-46.6-103.9-104-103.9zm0 175.9c-39.7 0-71.9-32.3-71.9-71.9 0-39.7 32.3-71.9 71.9-71.9 39.7 0 71.9 32.3 71.9 71.9.1 39.6-32.2 71.9-71.9 71.9z"></path>
                <path d="M491.4 202.9l-38.7-14c-3-9.5-6.6-18.6-10.7-27.1l18.3-38.3c5.6-11.8 3.2-26-6-35.2l-30.5-30.6c-9.3-9.3-23.4-11.7-35.2-6.1l-38.2 18.1c-8.1-3.8-16.7-7.1-27.1-10.3l-14-38.7C304.9 8.5 293.2.4 280.2.4h-43.6c-12.9 0-24.6 8.2-29.1 20.4l-14.1 38.8c-9.7 3.1-18.8 6.7-27.1 10.7l-38.2-18.4c-11.8-5.6-26-3.2-35.2 6L62.1 88.3c-9.3 9.3-11.7 23.5-6 35.3l18 37.8c-5.2 9.8-9.2 18.7-12.2 27l-40.8 14.2C8.6 207 .2 218.7.2 232v43.3c0 13.2 8.4 25 20.7 29.2l40.9 14.3c3.1 8.8 6.9 17.7 11.7 27.1L55.6 384c-5.6 11.9-3.2 26 6.2 35.2L92.6 450c9.2 9.2 23.4 11.7 35.2 6l38.1-18.1c9.7 5.2 18.6 9.2 27.1 12.2l14.2 40.8c4.3 12.5 16 20.8 29.3 20.8h43.3c13.2 0 25-8.4 29.3-20.8l14.2-40.9c8.9-3.1 18-7 27.1-11.7l38.1 17.9c11.8 5.6 26 3.1 35.3-6.2l30.7-30.9c9.2-9.2 11.6-23.3 6-35.2l-18.2-38.4c3.8-8 7.1-16.6 10.3-27.1l38.6-14c12.3-4.3 20.6-16 20.6-29V232c0-13-8.2-24.7-20.4-29.1zm-11.6 71.8l-38.5 14c-9.2 3.3-16.1 10.6-19 19.9v.1c-2.8 9.3-5.7 16.8-8.9 23.5-4.1 8.5-4.2 18.3-.1 26.7l18.2 38.3-29.7 29.9-38-17.9c-8.9-4.1-18.8-4-27.3.5-8 4.1-16 7.6-23.7 10.3-8.9 2.9-16.1 10.1-19.3 19.1l-14.2 40.7h-41.8L223.2 439c-3.1-8.9-10-15.9-19-19-7.3-2.6-15-6.1-23.7-10.7s-19.1-4.8-27.9-.6l-37.9 18-29.9-29.8 17.9-37.9c4.1-8.9 4-18.9-.5-27.3-4.3-8.3-7.7-16.1-10.3-23.9-3.1-8.9-10.2-16-19.1-19.1l-40.6-14.2v-41.8l40.7-14.2c8.9-3.1 15.9-10 19-18.9 2.5-7 6-14.7 10.7-23.5 4.6-8.6 4.9-19 .6-27.9l-18-37.8L115 80.9l38 18.3c8.5 4.1 18.6 4 27.1-.2 7.1-3.5 15.1-6.6 23.6-9.3 9-2.9 16.3-9.9 19.6-19l14-38.5h42.1l13.9 38.4c3.3 9.2 10.6 16.1 19.9 19h.1c9.2 2.8 16.6 5.6 23.4 8.9 8.6 4.1 18.3 4.1 26.8.1l38-18 29.5 29.6-18.2 38-.1.2c-4 8.7-4 18.5.2 26.9 3.6 7.4 6.7 15.3 9.3 23.5 2.7 9 9.8 16.4 18.9 19.8l38.5 14v42.1z"></path>
              </svg>
              <span class="font-xs">Customize</span>
            </button> */}
            </div>
            <div id="ember1290" class="ember-view">
              <style media="all" type="text/css"></style>

              <div class="pcs-template ">
                <div class="pcs-template-header pcs-header-content" id="header">
                  <div class="pcs-template-fill-emptydiv"></div>
                </div>

                <div class="pcs-template-body">
                  <table
                    style={{
                      lineHeight: "18px;",
                      cellPadding: "0",
                      cellSpacing: "0",
                      border: "0",
                      width: "100%",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td></td>

                        <td width="50%" class="pcs-orgname text-align-right">
                          {/* <b>test</b> */}
                          <br />
                          <span
                            style={{
                              whiteSpace: "pre-wrap;",
                              id: "tmp_org_address",
                            }}
                          >
                            Arunachal Pradesh <br /> India <br /> GSTIN
                            123456789012457
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <table
                            cellpadding="0"
                            cellspacing="0"
                            border="0"
                            class="title-section"
                          >
                            <tbody>
                              <tr>
                                <td
                                  class="pcs-entity-title"
                                  style={{
                                    paddingTop: "6px",
                                    lineHeight: "30px",
                                  }}
                                >
                                  <b>Statement of Accounts</b>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "12px",
                                    borderTop: "1px solid #000",
                                    borderBottom: " 1px solid #000",
                                    height: "24",
                                    class: "text-align-right",
                                  }}
                                >
                                  01/06/2022 To 30/06/2022
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "20px 0px 0px 5px;" }}>
                          <table
                            cellpadding="0"
                            cellspacing="0"
                            border="0"
                            width="70%"
                          >
                            <tbody>
                              <tr>
                                <td class="pcs-label">
                                  <b>To</b>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span
                                    style={{ whiteSpace: "pre-wrap;" }}
                                    id="tmp_billing_address"
                                  >
                                    <strong>
                                      <span
                                        class="pcs-customer-name"
                                        id="zb-pdf-customer-detail"
                                      >
                                        <a href="">
                                          {vendorData.vendorDisplayName}
                                        </a>
                                      </span>
                                    </strong>
                                    Address 1 <br /> test city <br /> 999999
                                    Kerala <br /> India
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td
                          style={{
                            padding: "20px 0px 30px 0px",
                            valign: "bottom",
                          }}
                        >
                          <table
                            cellpadding="5"
                            cellspacing="0"
                            width="79%"
                            border="0"
                            class="summary-section"
                          >
                            <tbody>
                              <tr>
                                <td
                                  class="pcs-label"
                                  style={{
                                    padding: "4px 6px 4px 6px",
                                    borderBottom: "1px solid #dcdcdc",
                                    bgColor: "#e8e8e8",
                                    colSpan: "5",
                                  }}
                                >
                                  <b>Account Summary</b>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="pcs-label"
                                  style={{ paddingTop: "6px", width: "50%" }}
                                >
                                  Opening Balance
                                </td>
                                <td
                                  style={{ padding: "6px 0px 0px 6px;" }}
                                  class="text-align-right"
                                >
                                  ₹ 2,22,222.00
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="pcs-label"
                                  style={{ paddingTop: "4px" }}
                                >
                                  Invoiced Amount
                                </td>
                                <td
                                  style={{ padding: "6px 0px 0px 6px" }}
                                  class="text-align-right"
                                >
                                  ₹ 0.00
                                </td>
                              </tr>
                              <tr>
                                <td class="pcs-label">Amount Received</td>
                                <td
                                  style={{ padding: "4px 0px 2px 6px" }}
                                  class="text-align-right"
                                >
                                  ₹ 0.00
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="pcs-label"
                                  style={{
                                    paddingTop: "6px ",
                                    borderTop: "1px solid #000",
                                  }}
                                >
                                  Balance Due
                                </td>
                                <td
                                  style={{
                                    padding: "6px 0px 0px 6px",
                                    borderTop: "1px solid #000;",
                                  }}
                                  class="text-align-right"
                                >
                                  ₹ 2,22,222.00
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    style={{
                      lineHeight: "18px",
                      marginTop: "10px",
                      cellPadding: "2",
                      cellSpacing: "0",
                      border: "0",
                      width: "100%",
                    }}
                    class="trpadding"
                  >
                    <thead>
                      <tr height="26">
                        <th width="15%" class="pcs-itemtable-header">
                          <b>Date</b>
                        </th>
                        <th width="14%" class="pcs-itemtable-header">
                          <b>Transactions</b>
                        </th>
                        <th width="25%" class="pcs-itemtable-header">
                          <b>Details</b>
                        </th>
                        <th
                          width="13%"
                          class="text-align-right pcs-itemtable-header"
                        >
                          <b>Amount</b>
                        </th>
                        <th
                          width="13%"
                          class="text-align-right pcs-itemtable-header"
                        >
                          <b>Payments</b>
                        </th>
                        <th
                          width="20%"
                          class="text-align-right pcs-itemtable-header"
                        >
                          <b>Balance</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="itemBody">
                      <tr class="trclass_oddrow breakrow-inside breakrow-after">
                        <td class="box-padding">01/06/2022</td>
                        <td class="box-padding">***Opening Balance***</td>
                        <td class="box-padding"></td>
                        <td class="text-align-right box-padding">
                          2,22,222.00
                        </td>
                        <td class="text-align-right box-padding"></td>
                        <td class="text-align-right box-padding">
                          2,22,222.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    width="100%"
                    style={{ borderTop: "1px solid #dcdcdc;" }}
                  >
                    <tbody>
                      <tr>
                        <td></td>
                        <td width="50%">
                          <table width="100%">
                            <tbody>
                              <tr>
                                <td
                                  width="50%"
                                  class="box-padding"
                                  align="right"
                                  valign="middle"
                                >
                                  <b>Balance Due</b>
                                </td>
                                <td
                                  class="box-padding"
                                  align="right"
                                  valign="middle"
                                >
                                  ₹ 2,22,222.00
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="pcs-template-footer">
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default VendorStatements;
