import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Input,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import ExportModal from "./exportModal";
import classnames from "classnames";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Formik, Field } from "formik";
//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet, apiPut } from "../../components/config/apiConfig";
import { ToastContainer, toast } from "react-toastify";
import dyarco from "../../assets/dyarco/awc.png";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

const CheckOutSingleView = (props) => {
  const history = useHistory();

  const componentRef = useRef();
  var id = props.match.params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [checkoutdata, setcheckoutdata] = useState({});
  function getCheckInData() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/cargo_checkout/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setcheckoutdata(resp.response.data.data);
    });
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "print-btn",
    pageStyle: "margin:50px",
  });
  useEffect(() => {
    getCheckInData();
  }, []);
  const handleCloseCheckOut = () => {
    setIsLoading(true);
    let userId = localStorage.getItem("Id");
    let checkOutType = checkoutdata.checkOutType === "Unit-Based" ? 1 : 2;
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/cargo_checkout/${checkoutdata.checkOutId}/${userId}/${checkOutType}/freeze`;
    // var body = {
    //   checkInStatus: 2,
    // };
    let resp = apiPut(Url);
    resp.then((resp) => {
      setIsLoading(false);
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          getCheckInData();
          break;
        default:
          toast.error(resp.response.data.data);
      }
    });
  };
  const [dropdownMonth, setMonth] = React.useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleMonth = () => setMonth(!dropdownMonth);
  const [dropdownFilter, setFilter] = React.useState(false);
  const toggleFilter = () => setFilter(!dropdownFilter);
  const [dropdownOpen, setOpen] = React.useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const handleBack = () => {
    history.goBack();
  };
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid className="singlepage">
          <Row>
            <Col md={12} className="item-head">
              <div className="backbutton" onClick={handleBack}>
                <i class="bx bx-arrow-back"></i>
              </div>
              <div className="bbb">
                {checkoutdata.checkOutStatus === 1 ? (
                  <div className="d-flex">
                    <button
                      className="primary-btn btn btn-secondary"
                      onClick={handleCloseCheckOut}
                      disabled={isLoading}
                    >
                      {isLoading ? "Closing..." : "Close Check-Out"}
                    </button>
                    <Link
                      to={
                        checkoutdata.checkOutType === "Unit-Based"
                          ? `/editcheckout/edit/${id}`
                          : `/editcheckoutbyitem/edit/${id}`
                      }
                    >
                      <button>
                        <i class="bx bx-pencil"></i>
                      </button>
                    </Link>
                  </div>
                ) : checkoutdata.checkOutStatus === 2 ? (
                  <button className="status-btn" disabled>
                    Item Checked out
                  </button>
                ) : (
                  ""
                )}
                {/* <Link to={`/invoice/edit/${checkoutdata.invoiceId}`}>
                <button>
                  <i class="bx bx-pencil"></i>
                </button>
                </Link> */}
                <Dropdown
                  className="d-inline-block"
                  isOpen={socialDrp}
                  toggle={() => {
                    setsocialDrp(!socialDrp);
                  }}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect ddtoggle"
                    tag="button"
                  >
                    <button>
                      {" "}
                      {/* <i class="bx bx-menu"></i> */}
                      More
                    </button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {/* <DropdownItem tag="a" href="">
                      {" "}
                      <i className="bx bx-copy font-size-16 align-middle mr-1"></i>
                      {"Clone Item"}{" "}
                    </DropdownItem> */}
                    {/* <hr /> */}
                    {/* <DropdownItem tag="a" href="#">
                      {" "}
                      <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                      {"Import Item"}{" "}
                    </DropdownItem>
                    <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                      {" "}
                      <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                      {"Export Item"}{" "}
                    </DropdownItem> */}
                    <DropdownItem tag="a" onClick={handlePrint}>
                      {" "}
                      <i className="bx bx-printer font-size-16 align-middle mr-1"></i>
                      {"Print Item"}{" "}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Modal className="export-modal" isOpen={modalIsOpen}>
                  <ExportModal isClose={() => setModalIsOpen(false)} />
                </Modal>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Col md={12} className="checkout-right">
                <div className="checkout-pdf " ref={componentRef}>
                  <Row>
                    <Col>
                      <img src={dyarco} alt="dyarco" className="slip-image" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="text-center">
                      <h4>GDN NOTE</h4>
                    </Col>
                    <Col md={12} className="mt-3">
                      <h6>
                        GDN Number :{" "}
                        <strong>{checkoutdata?.checkOutNumber}</strong>
                      </h6>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12} className="mt-2">
                      <h6>
                        Date : <strong>{checkoutdata?.checkOutDate}</strong>
                      </h6>
                    </Col>
                    <Col md={12} className="mt-2">
                      <h6>
                        Warehouse Name :{" "}
                        <strong> {checkoutdata?.checkOutWarehouseName}</strong>
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mt-2">
                      <h6>
                        Check-Out Customer Name :{" "}
                        <strong>{checkoutdata?.checkOutCustomerName}</strong>
                      </h6>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12}>
                      <strong>Item Details</strong>
                    </Col>
                    <Col md={12} className="mt-3">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col" style={{ textAlign: "center" }}>
                                #
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Name
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                HSN
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                UOM
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Stock
                              </th>
                              {/* <th scope="col" style={{ textAlign: "center" }}>Available Stock</th> */}
                              <th scope="col" style={{ textAlign: "center" }}>
                                Pallet No.
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Check Out units
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Check Out items
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                L
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                W
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                H
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Checked-Out CBM
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Checked-Out SQM
                              </th>
                              {/* <th scope="col" style={{ textAlign: "center" }}>Area</th> */}
                              <th scope="col" style={{ textAlign: "center" }}>
                                Remarks
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Cargo Condition
                              </th>
                            </tr>
                          </thead>
                          {checkoutdata.itemsList &&
                            checkoutdata.itemsList.map((item, index) => (
                              <tbody>
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <th style={{ textAlign: "center" }}>
                                    {item.itemCommodityName}
                                  </th>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemHsnCode}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemUom}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemUnitsTotal}
                                  </td>
                                  {/* style={{textAlign: 'center'}} <td>{item.itemUnitsAvailableCheckOut}</td> */}
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemPallet}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemUnitsCheckedOut}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemPackageWiseCheckedOut}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemLength}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemBreadth}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemHeight}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemArea}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemAreaSqm}
                                  </td>
                                  {/* style={{textAlign: 'center'}} <td>{item.itemAreaUnits}</td> */}
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemRemarks}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemCargoCondition}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                        </table>
                      </div>
                    </Col>
                  </Row>
                  {checkoutdata.chargeList?.length > 0 ? (
                    <Row>
                      <Col md={12}>
                        <strong>Other Charges</strong>
                      </Col>
                      <Col md={6} className="mt-3">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Order charge</th>
                            </tr>
                          </thead>
                          <tbody>
                            {checkoutdata.chargeList?.length > 0
                              ? checkoutdata.chargeList
                                  // .filter((item) => item.chargeAmount > 0)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <th width="10">{index + 1}</th>
                                        <th>{item.chargeName}</th>
                                        <td>{item.chargeAmount}</td>
                                        <td>{item.chargeAmountCostSheet}</td>
                                      </tr>
                                    );
                                  })
                              : ""}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default CheckOutSingleView;
