import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "reactstrap";
import UpdatePriceList from "./updatePriceList";
import { Formik, Field } from "formik";

import { AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import Switch from "react-switch";

// import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BaseUrl } from "../../../components/config/BaseUrl";
import { apiGet, apiPost } from "../../../components/config/apiConfig";

//i18n

const PriceListForm = (props) => {
  // const [pricingScheme, setPricingScheme] = useState("1");
  const [reload, setReload] = useState(false);

  const [checked, setChecked] = useState(false);
  const [item, setItem] = useState(false);
  const [vpData, setVpData] = useState([]);
  const [upData, setUpData] = useState([]);
  const [data, setData] = useState({
    name: "",
    pricetype: "Sales",
    itemrate: "1",
    description: "",
    percentage: "",
    roundOff: "",
    pricingscheme: "",
    currency: "INR - Indian Rupee",
  });

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  function handle(e) {
    e.persist();
    const newData = { ...data };
    newData[e.target.name] = e.target.value;

    setData(newData);
    console.log(data);
  }
  useEffect(() => {
    function getData(e) {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        console.log("items", resp);
        setItem(resp.response.data.data);
      });
    }
    getData();
  }, []);
  useEffect(() => {
    if (item?.length) {
      const row = item.map((data) => {
        return {
          startquantity: "",
          endquantity: "",
          customrate: "",
          discount: "",
        };
      });
      setVpData(row);
    }
  }, [item]);

  // useEffect(() => {
  //   if (item?.length) {
  //     const rows = item.map((data) => {
  //       return {
  //         upcustomerate: "",
  //         updiscount: "",
  //       };
  //     });
  //     setUpData(rows);
  //   }
  // }, [item]);

  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...vpData];
    rowsInput[index][name] = value;
    setVpData(rowsInput);
    console.log("items data", rowsInput);
  };
  console.log("row data", vpData);

  return {
    data,
    upData,
    vpData,
    addPriceListRender: (
      <React.Fragment>
        <Row>
          <Col md={6}>
            <label className="label">Price List Name</label>
            <AvField
              name="name"
              type="input"
              placeholder="Enter name"
              onChange={(e) => handle(e)}
              value={data.name}
              required
            ></AvField>
          </Col>
          <Col md={6}>
            <label className="label">Type</label>
            <AvRadioGroup
              inline
              name="pricetype"
              onChange={(e) => handle(e)}
              value={data.pricetype}
              required
            >
              <AvRadio label="Sales" value="Sales" />
              <AvRadio label="Purchase" value="Purchase" />
            </AvRadioGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <label className="label">Item Rate</label>
            <AvRadioGroup
              inline
              name="itemrate"
              onChange={(e) => handle(e)}
              value={data.itemrate}
              required
            >
              <AvRadio
                label="Markup or Markdown the item rates by a percentage"
                value="1"
              />
              <AvRadio
                label="Enter the rate individually for each item"
                value="2"
              />
            </AvRadioGroup>
          </Col>
          <Col md={6}>
            <label className="label">Description</label>
            <AvField
              name="description"
              type="textarea"
              placeholder="Enter description"
              onChange={(e) => handle(e)}
              value={data.description}
              required
            ></AvField>
          </Col>
        </Row>
        {data.itemrate == "1" ? (
          <Row>
            <Col md={6}>
              <label className="label">Percentage</label>
              <AvField
                name="percentage"
                type="number"
                placeholder="Enter percentage"
                onChange={(e) => handle(e)}
                value={data.percentage}
                required
              ></AvField>
            </Col>
            <Col md={6}>
              <label className="label">Round off to</label>
              <AvField
                name="roundOff"
                type="select"
                onChange={(e) => handle(e)}
                value={data.roundOff}
              >
                <option value="" selected disabled>
                  Select Roundoff
                </option>
                <option value="0.0000000001">Never mind</option>
                <option value="0.00">Nearest whole number</option>
                <option value="0.99">0.99</option>
                <option value="0.50">0.50</option>
                <option value="0.49">0.49</option>
              </AvField>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col md={6}>
                <label className="label">Pricing Scheme</label>
                <AvRadioGroup
                  inline
                  name="pricingscheme"
                  onChange={(e) => handle(e)}
                  value={data.pricingscheme}
                >
                  <AvRadio label="Unit Pricing" value="Unit" selected />
                  <AvRadio label="Volume Pricing" value="Volume" />
                </AvRadioGroup>
              </Col>
              <Col md={6}>
                <label className="label">Currency</label>
                <AvField
                  name="currency"
                  type="select"
                  onChange={(e) => handle(e)}
                  value={data.currency}
                  required
                >
                  <option value="" selected disabled>
                    Select Currency
                  </option>
                  <option>INR - Indian Rupee</option>
                </AvField>
              </Col>
            </Row>
            <hr />
            {/* <UpdatePriceList pricing={pricingScheme} /> */}
            <Row>
              <Col md={6}>
                <h5>Update Custom Rates in Bulk</h5>
                {checked ? (
                  <p>
                    Export your items in an XLS file, add the custom rate for
                    each item and import the same file in CSV or XLS format.
                  </p>
                ) : (
                  <p>
                    Add custom rates for each item to be saved as a price list.
                  </p>
                )}
              </Col>
              <Col md={6} className="switch">
                <span>Import Price List for Items</span>
                <Switch
                  onChange={handleChange}
                  checked={checked}
                  className="react-switch"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={40}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {checked ? (
                  <div className="pricecustomimport">
                    <p>Note :</p>
                    <ul>
                      <li>
                        In the import file, there are two fields:{" "}
                        <span>Item Name</span> and
                        <span> PriceList Rate</span> whose column names should
                        be specifically in English.
                      </li>
                    </ul>
                    <Row>
                      <Col className="pricebtns">
                        <div className="btn-link">
                          <span className="cursor-pointer">
                            <i className="bx bx-export"></i>
                            <span>Export Items</span>
                          </span>
                        </div>
                        <div className="btn-link">
                          <span className="cursor-pointer">
                            <i className="bx bx-import"></i>
                            <span>Import Custom Rates</span>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : data.pricingscheme === "Volume" &&
                  data.pricetype === "Sales" ? (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th style={{ width: "60%" }}>Item Details </th>
                        <th>Sales Rate (INR)</th>
                        <th>Start Quantity</th>
                        <th>End Quantity</th>
                        <th>Custom Rate (INR)</th>
                        <th>Discount (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.map((data, index) => {
                        return (
                          <tr id={index}>
                            <td>{data.index}</td>
                            <td>{data.itemName}</td>
                            <td>{data.itemPurchaseCostPrice}</td>

                            <td>
                              <input
                                type="number"
                                name="startquantity"
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                value={data.startquantity}
                                required
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="endquantity"
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                value={data.endquantity}
                                required
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="customrate"
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                value={data.customrate}
                                required
                              />
                            </td>
                            <td>
                              <input
                                type="percentage"
                                name="discount"
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                value={data.discount}
                                min="0"
                                max="100"
                                step="0.01"
                                required
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : data.pricingscheme === "Volume" &&
                  data.pricetype === "Purchase" ? (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th style={{ width: "60%" }}>Item Details </th>
                        <th>Purchase Rate (INR)</th>
                        <th>Start Quantity</th>
                        <th>End Quantity</th>
                        <th>Custom Rate (INR)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.map((data, index) => {
                        return (
                          <tr id={index}>
                            <td>{data.index}</td>
                            <td>{data.itemName}</td>
                            <td>{data.itemPurchaseCostPrice}</td>

                            <td>
                              <input
                                type="number"
                                name="startquantity"
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                value={data.startquantity}
                                required
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="endquantity"
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                value={data.endquantity}
                                required
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="customrate"
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                value={data.customrate}
                                required
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : data.pricingscheme === "Unit" &&
                  data.pricetype === "Sales" ? (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th style={{ width: "50%" }}>Item Details </th>
                        <th>Sales Rate (INR)</th>
                        <th>Custom Rate (INR)</th>
                        <th>Discount (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.map((data, index) => {
                        return (
                          <tr id={index}>
                            <td>{data.index}</td>
                            <td>{data.itemName}</td>
                            <td>{data.itemPurchaseCostPrice}</td>
                            <td>
                              <input
                                type="number"
                                name="customrate"
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                value={data.customrate}
                                required
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="discount"
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                value={data.discount}
                                required
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th style={{ width: "50%" }}>Item Details </th>
                        <th>Purchase Rate (INR)</th>
                        <th>Custom Rate (INR)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.map((data, index) => {
                        return (
                          <tr id={index}>
                            <td>{data.index}</td>
                            <td>{data.itemName}</td>
                            <td>{data.itemPurchaseCostPrice}</td>
                            <td>
                              <input
                                type="number"
                                name="customrate"
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                value={data.customrate}
                                required
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </Col>
            </Row>
          </>
        )}
      </React.Fragment>
    ),
  };
};
export default PriceListForm;
