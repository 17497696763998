import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import { AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import { IoIosAddCircle } from "react-icons/io";
import Select from "react-select";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
//i18n

const Expense = (props) => {
  const [data, setData] = useState({
    date: "",
    expenseAccount: "",
    expenseType: "",
    sac: "",
    hsnCode: "",
    expenseAmount: "",
    paidThrough: "",
    salesPerson: "",
    gstTreatment: "",
    gstin: "",
    sos: "",
    dos: "",
    reverseCharge: "",
    tax: "",
    invoice: "",
    notes: "",
    customer: "",
    reportingtags: "",
  });
  const [gst, setGst] = useState([]);
  const [state, setState] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [reportingTag, setReportingTag] = useState([]);
  const [coa, setCoA] = useState([]);
  const history = useHistory();
  // to get gst treatment list
  useEffect(() => {
    function getGst() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/gst_treatments`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setGst(resp.response.data.data);
      });
    }
    getGst();
  }, []);
  // to get states
  useEffect(() => {
    function getStates() {
      const organisationId = localStorage.getItem("organisationId");
      // const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/countries`;
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/states/101`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setState(resp.response.data.data);
      });
    }
    getStates();
  }, []);
  // to get customer list
  useEffect(() => {
    function getCustomer() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setCustomer(resp.response.data.data);
      });
    }
    getCustomer();
  }, []);

  useEffect(() => {
    function getVendor() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl + `api/v1/vendors/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        console.log("resp", resp);
        setVendor(resp.response.data.data);
      });
    }
    getVendor();
  }, []);
  const custOptions = vendor.map((cust) => ({
    value: cust.vendorId,
    label: cust.vendorDisplayName,
  }));

  // const customAddCustomerOption = {
  //   label: (
  //     <Link to="addvendor">
  //       <span style={{ color: "#408dfb" }}>
  //         <span style={{ marginRight: "8px", color: "#408dfb" }}>
  //           <IoIosAddCircle color="#408dfb" />
  //         </span>{" "}
  //         Add Vendor
  //       </span>
  //     </Link>
  //   ),
  //   value: "addvendor",
  //   onClick: () => {
  //     console.log("Add Customer Clicked");
  //     history.push("/addvendor");
  //   },
  //   isFixed: true,
  // };

  // const options = [...custOptions, customAddCustomerOption];
  // custom add button in Select
  const CustomButton = ({ onClick }) => (
    <Link to="addvendor">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Vendor
      </button>
    </Link>
  );

  const DropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomButton onClick={console.log("clicked")} />
      </div>
    );
  };

  const customComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: DropdownMenu, // Use your custom dropdown menu
  };

  useEffect(() => {
    function getReportingTag() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/reporting_tags`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setReportingTag(resp.response.data.data);
      });
    }
    getReportingTag();
  }, []);

  useEffect(() => {
    function getCoAData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/accounts/coa`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        console.log("coa list", resp);
        setCoA(resp.response.data.data);
      });
    }
    getCoAData();
  }, []);

  function handle(e) {
    e.persist();
    const newData = { ...data };
    newData[e.target.name] = e.target.value;

    setData(newData);
  }

  return {
    data,
    expenseRender: (
      <React.Fragment>
        <Row className="mb-3">
          <Col md={4}>
            <label>Date</label>
            <AvField
              name="date"
              type="date"
              placeholder="Enter date"
              onChange={(e) => handle(e)}
              value={data.date}
            ></AvField>
          </Col>

          <Col md={4}>
            <label>Expense Account</label>
            <AvField
              name="expenseAccount"
              type="select"
              placeholder="Enter expense account"
              onChange={(e) => handle(e)}
              value={data.expenseAccount}
              required
            >
              <option value="0" selected>
                Select Account
              </option>
              {coa.map((ca, index) => {
                return <option value={ca.headId}>{ca.headName}</option>;
              })}
            </AvField>
            {/* <Link>
              <i class="bx bx-plus"></i>
              <span className="addlinks"> Add a new expense account</span>
            </Link> */}
          </Col>
          <Col md={4} className="text-right">
            <h6>EXPENSE AMOUNT</h6>
            <span className="total-amount">00.00</span>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4}>
            <label>Expense Type</label>
            <AvRadioGroup
              inline
              name="expenseType"
              onChange={(e) => handle(e)}
              value={data.expenseType}
              required
            >
              <AvRadio label="Goods" value="1" inline />
              <AvRadio label="Services" value="2" />
            </AvRadioGroup>
          </Col>
          {data.expenseType === "2" ? (
            <Col md={4}>
              <label>SAC</label>
              <div className="sac">
                <AvField
                  name="sac"
                  type="text"
                  placeholder="xxxxxxxxxxxxxxxx"
                  onChange={(e) => handle(e)}
                  value={data.sac}
                ></AvField>
                {/*<span>
                  <Link>
                    <i class="bx bx-edit"></i>
                    <span className="addlinks"> Itemize</span>
                  </Link>
          </span>*/}
              </div>
            </Col>
          ) : (
            <Col md={4}>
              <label>HSN Code</label>
              <div className="sac">
                <AvField
                  name="hsnCode"
                  type="text"
                  placeholder="xxxxxxxxxxxxxxxx"
                  onChange={(e) => handle(e)}
                  value={data.hsnCode}
                ></AvField>
                {/*<span>
                  <Link>
                    <i class="bx bx-edit"></i>
                    <span className="addlinks"> Itemize</span>
                  </Link>
          </span>*/}
              </div>
            </Col>
          )}
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <label>Expense amount</label>
            <AvField
              name="expenseAmount"
              type="text"
              placeholder="Enter Amount"
              onChange={(e) => handle(e)}
              value={data.expenseAmount}
              required
            ></AvField>
          </Col>

          <Col md={4}>
            <label>Paid through</label>
            <AvField
              name="paidThrough"
              type="select"
              placeholder="&#x260E; Select Terms"
              onChange={(e) => handle(e)}
              required
              value={data.paidThrough}
            >
              <option>John Sebastian Doe</option>
              <option>John Sebastian Doe</option>
            </AvField>
          </Col>
          <Col md={4}>
            <label> Vendor </label>
            {/* <AvField
              name="expenseVendorId"
              type="select"
              onChange={(e) => handle(e)}
              value={data.expenseVendorId}
            >
              <option value="" selected disabled>
                Select Vendor
              </option>
              {vendor.map((data) => {
                return <option>{data.vendorDisplayName}</option>;
              })}
            </AvField> */}
            <Select
              options={custOptions}
              components={customComponents}
              name="expenseVendorId"
              value={
                custOptions
                  ? custOptions.find(
                      (option) => option.value === data.expenseVendorId
                    )
                  : ""
              }
              onChange={(option) => {
                setData({ ...data, expenseVendorId: option.value });
                // if (option.value == "addvendor") {
                //   console.log(true);
                //   history.push("/addvendor");
                //   // setPushToCustomer(true);
                // }
              }}
            />
            {/* <Link to="/addvendor">
              <i class="bx bx-plus"></i> Add a new vendor
            </Link> */}
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <label>GST Treatment</label>
            <AvField
              name="gstTreatment"
              type="select"
              placeholder="GST Treatment"
              onChange={(e) => handle(e)}
              required
              value={data.gstTreatment}
            >
              <option value="" selected disabled>
                Select a GST Treatment
              </option>
              {gst.map((data, index) => {
                return <option value={data.id}>{data.gstTreatmentName}</option>;
              })}
            </AvField>
          </Col>
          {data.gstTreatment !== "3" &&
          data.gstTreatment !== "" &&
          data.gstTreatment !== "4" &&
          data.gstTreatment !== "7" ? (
            <Col md={4}>
              <label>Vendor GSTIN</label>
              <AvField
                name="gstin"
                type="input"
                placeholder="GST IN"
                onChange={(e) => handle(e)}
                value={data.gstin}
              ></AvField>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          <Col md={4}>
            <label>Source of Supply</label>
            <AvField
              name="sos"
              type="select"
              placeholder="Source of Supply"
              onChange={(e) => handle(e)}
              required
              value={data.sos}
            >
              <option value="" selected disabled>
                Select a Source of Supply
              </option>
              {state.map((data) => {
                return <option value={data.stateId}>{data.stateName}</option>;
              })}
            </AvField>
          </Col>
          <Col md={4}>
            <label>Destination of Supply</label>
            <AvField
              name="dos"
              type="select"
              placeholder="Destination of Supply"
              onChange={(e) => handle(e)}
              required
              value={data.dos}
            >
              <option value="" selected disabled>
                Select Destination of Supply
              </option>
              {state.map((data) => {
                return <option value={data.stateId}>{data.stateName}</option>;
              })}
            </AvField>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <AvField
              type="checkbox"
              name="reverseCharge"
              label="This transaction is applicable for reverse charge"
              onChange={(e) => handle(e)}
              value={data.reverseCharge}
            />
          </Col>
        </Row>
        <hr />

        <Row>
          <Col md={4}>
            <label>Tax</label>
            <AvField
              name="tax"
              type="text"
              placeholder="Enter Tax"
              onChange={(e) => handle(e)}
              required
              value={data.tax}
            ></AvField>
          </Col>

          <Col md={4}>
            <label>Invoice#</label>
            <AvField
              name="invoice"
              type="input"
              placeholder="Invoice Number"
              onChange={(e) => handle(e)}
              required
              value={data.invoice}
            ></AvField>
          </Col>
          <Col md={4}>
            <label>Notes</label>
            <AvField
              name="notes"
              type="input"
              placeholder="Notes"
              onChange={(e) => handle(e)}
              value={data.notes}
            ></AvField>
          </Col>
        </Row>
        <hr />

        <Row>
          <Col md={4}>
            <label>Customer</label>
            <AvField
              name="customer"
              type="select"
              onChange={(e) => handle(e)}
              required
              value={data.customer}
            >
              <option value="" selected disabled>
                Select Customer
              </option>
              {customer.map((data) => {
                return <option>{data.customerDisplayName}</option>;
              })}
            </AvField>
          </Col>
          <Col md={4}>
            <label>Reporting Tags</label>

            <AvField
              name="reportingtags"
              type="select"
              onChange={(e) => handle(e)}
              value={data.reportingtags}
            >
              <option value="" selected disabled>
                Select Reporting Tag
              </option>
              {reportingTag.map((data) => {
                return <option>{data.tagName}</option>;
              })}
            </AvField>
          </Col>
        </Row>
      </React.Fragment>
    ),
  };
};
export default Expense;
