import React from "react";
import { Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { BaseUrl } from "../../../components/config/BaseUrl";
import { apiDelete } from "../../../components/config/apiConfig";
import { useHistory } from "react-router-dom";

const ExportModal = (props) => {
  const userId = localStorage.getItem("Id");
  const history = useHistory();
  const handleDeleteItem = (id) => {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/${id}/${userId}`;
    console.log("url delete", Url);
    let resp = apiDelete(Url);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          props.isClose();
          setTimeout(() => {
            history.push("/viewitems");
          }, 1000);
          break;
        default:
      }
    });
  };
  return (
    <>
      <ToastContainer />
      {/* <Row>
        <Col md={12} className="modal-head">
          <div>
            <h5>Export</h5>
          </div>
          <div onClick={props.isClose}>
            <i class="bx bx-x"></i>
          </div>
        </Col>
      </Row>
      <AvForm className="form">
        <Row>
          <Col md={12}>
            <p>you can export your data in CSV or XML format.</p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <button onClick={props.isClose} class="exportbtn">
              {" "}
              Cancel
            </button>
            <button type="submit" class="btn-outline-danger-delete">
              {" "}
              Delete
            </button>
          </Col>
        </Row>
      </AvForm> */}
      {/* <Modal className="export-modal" isOpen={props.openModal}> */}
      <Row>
        <Col md={12} className="modal-head">
          <div>
            <h5>Are you sure you want to delete this Project</h5>
          </div>
          <div onClick={props.isClose}>
            <i class="bx bx-x"></i>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="declineModal">
          <button onClick={() => handleDeleteItem(props.id)}>Yes</button>
          <button onClick={props.isClose}>No</button>
        </Col>
      </Row>
      {/* </Modal>{" "} */}
    </>
  );
};

export default withTranslation()(ExportModal);
