import { SET_TABLE_DATA, UPDATE_TABLE_DATA } from "./actionTypes";

const INIT_STATE = {
  tableRowData: [],
};

const PaymentRecievedTableData = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TABLE_DATA:
      return {
        ...state,
        tableRowData: action.payload,
      };
    case UPDATE_TABLE_DATA:
      const oldtableData = state.tableRowData;
      const value = action.payload.value;
      const index = action.payload.index;
      oldtableData[index][value.name] = value.value;

      return {
        ...state,
        tableRowData: oldtableData,
      };
    default:
      return state;
  }
};

export default PaymentRecievedTableData;
