import React, { useEffect, useState } from "react";
// import { Row, Col } from "reactstrap";

import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Formik, ErrorMessage } from "formik";
import { Link } from "react-router-dom";

import TermsAndConditions from "./termsAndConditions";
import { apiGet, apiPost } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import loader from "../../assets/loader/load.gif";
// import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import classnames from "classnames";
import { IoIosAddCircle } from "react-icons/io";
import Select from "react-select";
import { AvField } from "availity-reactstrap-validation";

//i18n

const AddPaymentMadeForm = (props) => {
  const history = useHistory();
  const [reload, setReload] = useState(false);
  const [itemData, setItemData] = useState([]);
  //  customer state
  const [data, setData] = useState([]);
  // table config
  const [rowsData, setRowsData] = useState([]);
  const [subTotalAmount, setSubTotalAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [shipChrg, setShipChrg] = useState(0);
  const [adjus, setAdjus] = useState(0);
  const [activeTab, setActiveTab] = useState("1");

  const [branchList, setBranchData] = useState([]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //.....................................................................................
  // get customer (dropdown)
  useEffect(() => {
    function getData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl + `api/v1/vendors/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        console.log("customer table", resp);
        setData(resp.response.data.data);
      });
    }
    getData();
  }, []);
  //customer list
  const custOptions = data.map((cust) => ({
    value: cust.vendorId,
    label: cust.vendorDisplayName,
  }));
  // customer add option
  const customAddCustomerOption = {
    label: (
      <Link to="addvendor">
        <span
          style={{
            color: "#408dfb",
            // position: "absolute",
            // top: "0",
            // width: "100%",
            // zIndex: "12",
          }}
        >
          <span style={{ marginRight: "8px", color: "#408dfb" }}>
            <IoIosAddCircle color="#408dfb" />
          </span>{" "}
          Add Vendor
        </span>
      </Link>
    ),
    value: "addvendor",
    onClick: () => {
      console.log("Add Customer Clicked");
      history.push("addvendor");
    },
    isFixed: true,
  };
  // select options
  const options = [...custOptions, customAddCustomerOption];
  const [FilteredData, setFilteredData] = useState([customAddCustomerOption]);

  const filterSelect = (inputValue) => {
    if (inputValue.length >= 2) {
      // var filter = [];
      // filter = custOptions?.filter((option) => ({
      //   label: option.label.toLowerCase().includes(inputValue.toLowerCase()),
      // }));
      // console.log("filter", filter);
      const filter = [
        customAddCustomerOption,
        ...custOptions.filter((option) => ({
          label: option?.label.toLowerCase().includes(inputValue.toLowerCase()),
        })),
      ];
      setFilteredData(...filter);
    } else {
      setFilteredData([customAddCustomerOption]);
    }
    //   if (inputValue.length >= 2) {
    //     return options.filter((option) =>
    //       option.label.toLowerCase().includes(inputValue.toLowerCase())
    //     );
    //   }
    //   return [];
  };
  useEffect(() => {
    console.log("filtered data", FilteredData);
  }, [FilteredData]);

  const CustomButton = ({ onClick }) => (
    <Link to="addvendor">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Vendor
      </button>
    </Link>
  );

  const DropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomButton onClick={console.log("clicked")} />
      </div>
    );
  };

  const customComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: DropdownMenu, // Use your custom dropdown menu
  };
  //.....................................................................................

  //get items
  useEffect(() => {
    function getItemData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        console.log("item list", resp);
        setItemData(resp.response.data.data);
      });
    }
    getItemData();
  }, []);

  useEffect(() => {
    function getBranchList() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl +
        `api/v1/settings/organization_branch/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setBranchData(resp.response.data.data);
      });
    }
    getBranchList();
  }, []);

  // table config
  const addTableRows = () => {
    const rowsInput = {
      itemId: 0,
      itemName: "",
      itemQuantity: "",
      itemRate: "",
      itemDiscountType: 0,
      itemDiscountValue: "",
      itemTaxId: 0,
      itemAmount: 0,
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };
  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    if (rowsInput[index]["itemDiscountType"] == 0) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        ((rowsInput[index]["itemAmount"] || 0) *
          (rowsInput[index]["itemDiscountValue"] || 0)) /
          100;
    }
    if (rowsInput[index]["itemDiscountType"] == 1) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        (rowsInput[index]["itemDiscountValue"] || 0);
    }

    setRowsData(rowsInput);
    const sum = rowsInput
      .map((item) => parseInt(item.itemAmount))
      .reduce((prev, curr) => prev + curr, 0);

    setSubTotalAmount(sum);
    setTotalAmount(
      parseInt(subTotalAmount ? subTotalAmount : 0) +
        parseInt(shipChrg ? shipChrg : 0) +
        parseInt(adjus ? adjus : 0)
    );
    if (name === "itemId") {
      const selectedItem = itemData.find(
        (item) => item.itemId === parseInt(value)
      );
      if (selectedItem) {
        console.log("selecteitem", selectedItem);
        rowsInput[index]["itemRate"] = selectedItem.itemSalesSellingPrice;
      }
    }
  };
  const onHandleBlur = (index, e) => {
    const rowsInput = [...rowsData];
    if (rowsInput[index]["itemDiscountType"] == 0) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        ((rowsInput[index]["itemAmount"] || 0) *
          (rowsInput[index]["itemDiscountValue"] || 0)) /
          100;
    }
    if (rowsInput[index]["itemDiscountType"] == 1) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        (rowsInput[index]["itemDiscountValue"] || 0);
    }
    setRowsData(rowsInput);
    setTotalAmount(
      parseInt(subTotalAmount) +
        parseInt(shipChrg ? shipChrg : 0) +
        parseInt(adjus ? adjus : 0)
    );
  };

  const handleCancel = () => {
    setTimeout(() => {
      history.push("/paymentsmade");
    }, 1000);
  };

  // item custom button
  const CustomItemButton = ({ onClick }) => (
    <Link to="/additems/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Item
      </button>
    </Link>
  );
  const ItemDropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomItemButton onClick={console.log("clicked")} />
      </div>
    );
  };
  const customItemComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: ItemDropdownMenu, // Use your custom dropdown menu
  };
  const reactSelectOptions = itemData.map((items) => ({
    value: items.itemId,
    label: items.itemName,
  }));
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Formik
        initialValues={{
          mode: "1",
          addretinvoice: false,
          vendorname: "",
          branch: "",
          paymentsmade: "",
          paymentdate: "",
          paymentmode: "",
          paidthrough: "",
          reference: "",
          notes: "",
          import: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.vendorname) {
            errors.vendorname = "* Required";
          }
          if (!values.paymentsmade) {
            errors.paymentsmade = "* Required";
          }
          if (!values.paymentmode) {
            errors.paymentmode = "* Required";
          }
          if (!values.paidthrough) {
            errors.paidthrough = "* Required";
          }
          return errors;
        }}
        // onSubmit={(values, { setSubmitting }) => {
        //   setTimeout(() => {
        //     alert(JSON.stringify(values, null, 2));
        //     setSubmitting(false);
        //   }, 400);
        // }}
        onSubmit={(values, { setSubmitting }) => {
          // alert("submitted");
          let orgId = localStorage.getItem("organisationId");
          let url = BaseUrl.apiUrl.baseUrl + "api/v1/payments_made";
          let body = {
            paymentsMadeVendorId: Number,
            paymentsMadeAmount: values.paymentsmade,
            paymentsMadeCurrency: "",
            paymentsMadeDate: values.paymentdate,
            paymentsMadeMode: values.paymentmode,
            paymentsMadeNumber: "",
            paymentsMadeReferenceNumber: values.reference,
            paymentsMadePaidThroughAccountId: values.paidthrough,
            paymentsMadeVendorBillTotal: Number,
            paymentsMadeAmountUsedForPayments: Number,
            paymentsMadeAmountRefunded: Number,
            paymentsMadeAmountInExcess: Number,
            paymentsMadeNotes: values.notes,
            paymentsMadeVendorName: values.vendorname,
            paymentsMadeVendorContactNumber: "",
            organizationId: 1,
            branchId: values.branch,
            addedByUser: 1,
            editByUser: 1,
          };
          let resp = apiPost(url, body);
          resp.then((resp) => {
            console.log("recurring invoice data is", resp.response.data);
            setReload(false);
            switch (resp.response.data.code) {
              case 200:
                toast.success(resp.response.data.data);
                setTimeout(() => {
                  history.push("/paymentsmade");
                }, 1000);
                break;
              default:
                toast.error(resp.response.data.data);
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="formik" id="addpaymentmade">
            {/* <TabContent activeTab={activeTab}> */}
            {/* //
          //OtherDetails
          // */}
            {/* <TabPane tabId="1"> */}
            <Row>
              <Col md={9}>
                <Row>
                  <Col md={4}>
                    <label className="formik-label"> Vendor Name</label>
                    {/* </Col>
                <Col md={3} className=""> */}
                    {/* <AvField
                      className="formik-input"
                      as="select"
                      name="vendorname"
                      onChange={handleChange}
                      value={values.vendorname}
                    >
                      <option value="" selected disabled>
                        Select Vendor
                      </option>
                      {data.map((cust, index) => {
                        return (
                          <option value={cust.customerId}>
                            {cust.customerDisplayName}
                          </option>
                        );
                      })}
                    </AvField> */}
                    <Select
                      {...props}
                      options={custOptions}
                      components={customComponents}
                      name="vendorname"
                      placeholder="Select Vendor"
                      // filterOptions={options}
                      onInputChange={(e) => filterSelect(e)}
                      value={
                        options
                          ? options.find(
                              (option) => option.value === data.vendorname
                            )
                          : ""
                      }
                      // onBlur={() => setFilteredData([customAddCustomerOption])}
                      onChange={(option) => {
                        setFieldValue({ vendorname: option.value });
                        if (option.value == "addvendor") {
                          console.log(true);
                          history.push("/addvendor");
                          // setPushToCustomer(true);
                        }
                      }}
                    />
                    {/* <div>
                      <i className="bx bx-search"></i>
                    </div> */}
                    {/* <ErrorMessage
                      style={{ color: "red" }}
                      component="div"
                      name="vendorname"
                    /> */}
                  </Col>
                  {/* <ErrorMessage name="vendorname" /> */}
                </Row>
                <Row>
                  <Col md={4}>
                    <label className="formik-label"> Branch</label>
                    {/* </Col>
                  <Col md={10}> */}
                    <Select
                      // className="formik-input"
                      as="select"
                      name="branch"
                      onChange={handleChange}
                      value={values.branch}
                    />
                    {/* <option value="" selected disabled>
                        Head Office
                      </option>
                      <option value="1">Branch 1</option> */}
                    {/* </AvField> */}
                  </Col>
                  {/* </Row>
                <Row> */}
                  <Col md={4}>
                    <label className="formik-label"> Payments Made</label>
                    {/* </Col>
                  <Col md={10}> */}
                    <AvField
                      // className="formik-input"
                      type="number"
                      name="paymentsmade"
                      placeholder="₹"
                      onChange={handleChange}
                      value={values.paymentsmade}
                    ></AvField>
                    <ErrorMessage
                      style={{ color: "red" }}
                      component="div"
                      name="paymentsmade"
                    />
                  </Col>
                  {/* </Row>
                <Row> */}
                  <Col md={4}>
                    <label className="formik-label"> Payment Date</label>
                    {/* </Col>
                  <Col md={10}> */}
                    <AvField
                      // className="formik-input"
                      type="date"
                      name="paymentdate"
                      onChange={handleChange}
                      value={values.paymentdate}
                    ></AvField>
                  </Col>
                  {/* </Row>
                <Row> */}
                  <Col md={4}>
                    <label className="formik-label"> Payment Mode</label>
                    {/* </Col>
                  <Col md={10}> */}
                    <AvField
                      // className="formik-input"
                      as="select"
                      name="paymentmode"
                      onChange={handleChange}
                      value={values.paymentmode}
                    >
                      <option value="" selected disabled>
                        select Payment Mode
                      </option>
                      <option>Bank</option>
                      <option>Credit Card</option>
                      <option>Cash</option>
                      {/* <option>Net 30</option>
                      <option>Net 45</option>
                      <option>Net 60</option>
                      <option>Due end of the month</option>
                      <option>Due end of next month</option>
                      <option>Due on Reciept</option> */}
                    </AvField>
                    <ErrorMessage
                      style={{ color: "red" }}
                      component="div"
                      name="paymentmode"
                    />
                  </Col>
                  {/* </Row>
                <Row> */}
                  <Col md={4}>
                    <label className="formik-label"> Paid Through</label>
                    {/* </Col>
                  <Col md={10}> */}
                    <AvField
                      // className="formik-input"
                      as="select"
                      name="paidthrough"
                      onChange={handleChange}
                      value={values.paidthrough}
                    >
                      <option value="0" selected>
                        Petty Cash
                      </option>
                      <option value="1">Undeposited fund</option>
                    </AvField>
                    <ErrorMessage
                      style={{ color: "red" }}
                      component="div"
                      name="paidthrough"
                    />
                  </Col>
                  {/* </Row>
                <Row> */}
                  <Col md={4}>
                    <label className="formik-label"> Reference#</label>
                    {/* </Col>
                  <Col md={10}> */}
                    <div>
                      <AvField
                        // className="formik-input"
                        type="input"
                        name="reference"
                        onChange={handleChange}
                        value={values.reference}
                      ></AvField>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={3}></Col>
            </Row>

            <hr />

            <div className="row clearfix">
              <div className="col-md-12 column">
                <table
                  className="table table-bordered invoice-table"
                  id="tab_logic"
                >
                  <thead>
                    <tr>
                      <th className="text-center"> # </th>
                      <th className="text-left"> ITEM </th>
                      {/*<th className="text-left"> ACCOUNT</th>*/}
                      <th className="text-right"> QUANTITY</th>
                      <th className="text-right"> RATE</th>
                      <th className="text-right"> DISCOUNT </th>
                      <th className="text-right"> TAX</th>
                      <th className="text-right"> AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rowsData.map((item, index) => (
                      <tr id="addr0" key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {/* <select
                            name="itemId"
                            className="form-control"
                            value={itemData.itemId}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                          >
                            <option value="" selected disabled>
                              Select Item
                            </option>
                            {itemData.map((items, index) => (
                              <option key={items.itemId} value={items.itemId}>
                                {items.itemName}
                              </option>
                            ))}
                          </select> */}
                          <Select
                            required
                            name="invItemId"
                            components={customItemComponents}
                            onChange={(selectedOption) => {
                              // Extract the selected item's ID from the selectedOption
                              const selectedItemId = selectedOption.value;
                              const rowsInput = [...rowsData];
                              rowsInput[index]["itemId"] = selectedItemId;
                              setRowsData(rowsInput);
                            }}
                            value={reactSelectOptions.find(
                              (option) =>
                                option.value ==
                                (rowsData[index]
                                  ? rowsData[index]["itemId"]
                                  : "")
                            )}
                            options={reactSelectOptions}
                          />
                        </td>
                        {/*<td>
                            <select
                              name="account"
                              id="tax"
                              value={item.account}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            >
                              <option value="%" disabled selected>
                                Select a tax
                              </option>
                              <option>Advance tax</option>
                              <option>Employee Advance</option>
                              <option>Tax Credit</option>
                            </select>
                            </td>*/}
                        <td>
                          <input
                            type="number"
                            name="itemQuantity"
                            value={item.itemQuantity}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                            className="form-control"
                            placeholder="1.00"
                            onBlur={(e) => onHandleBlur(index, e)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            name="itemRate"
                            value={item.itemRate}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                            className="form-control"
                            placeholder="0.00"
                            onBlur={(e) => onHandleBlur(index, e)}
                          />
                        </td>
                        <td width="10%">
                          <div className="table-inputs">
                            <div>
                              {" "}
                              <input
                                type="text"
                                name="itemDiscountValue"
                                value={item.itemDiscountValue}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                className="form-control"
                                placeholder="0"
                              />
                            </div>
                            <div>
                              <select
                                name="itemDiscountType"
                                id="itemDiscountType"
                                value={item.itemDiscountType}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                              >
                                <option value="0">%</option>
                                <option value="1">Rs.</option>
                              </select>
                            </div>
                          </div>
                        </td>
                        <td>
                          <select
                            name="itemTaxId"
                            id="itemTaxId"
                            disabled
                            value={item.itemTaxId}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                          >
                            <option value="%" disabled selected>
                              Select a tax
                            </option>
                          </select>
                        </td>
                        <td width="15%">
                          <input
                            type="number"
                            name="itemAmount"
                            value={item.itemAmount}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                            className="form-control"
                          />
                        </td>
                        <td className="text-center">
                          <span
                            className="btn btn-outline-danger"
                            onClick={(e) => deleteTableRows(index, e)}
                          >
                            x
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Row>
                  <Col md={6} className="table-left">
                    <div>
                      <span
                        onClick={addTableRows}
                        className="btn btn-default pull-left add-tablerow"
                      >
                        <i class="bx bxs-plus-circle"></i>
                        <span>Add Item</span>
                      </span>
                      {/* <button
                      onClick={this.handleRemoveRow}
                      className="pull-right btn btn-default table-btn"
                    >
                      Clear all rows
                    </button> */}
                    </div>
                    <div className="customernotes">
                      <span>Customer Notes</span>
                      <div>
                        <AvField
                          name="customernotes"
                          type="textarea"
                          placeholder="Looking forward for your business"
                          onChange={handleChange}
                          value={values.creditNoteCustomerNotes}
                        ></AvField>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="table-right">
                      <Row>
                        <Col className="details">
                          <div>
                            <span className="detail-head">Sub Total</span>
                          </div>
                          <div>
                            <span>{subTotalAmount}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <span className="detail-head">
                              Shipping Charges
                            </span>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="input-tooltip">
                            <AvField
                              type="number"
                              name="shippingCharge"
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                setShipChrg(values.shippingCharge);
                                let sum =
                                  parseInt(subTotalAmount) +
                                  parseInt(
                                    values.shippingCharge
                                      ? values.shippingCharge
                                      : 0
                                  ) +
                                  parseInt(
                                    values.adjustment ? values.adjustment : 0
                                  );
                                setTotalAmount(sum);
                              }}
                              value={values.shippingCharge}
                            />
                            <i class="bx bx-help-circle"></i>
                          </div>
                        </Col>
                        <Col md={3} className="text-right">
                          <div>
                            <span>{values.shippingCharge}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <span className="detail-head">Adjustment</span>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="input-tooltip">
                            <AvField
                              type="number"
                              name="adjustment"
                              onChange={handleChange}
                              value={values.adjustment}
                              onBlur={(e) => {
                                handleBlur(e);
                                setAdjus(values.adjustment);
                                let sum =
                                  parseInt(subTotalAmount) +
                                  parseInt(
                                    values.shippingCharge
                                      ? values.shippingCharge
                                      : 0
                                  ) +
                                  parseInt(
                                    values.adjustment ? values.adjustment : 0
                                  );
                                setTotalAmount(sum);
                              }}
                            />
                            <i class="bx bx-help-circle"></i>
                          </div>
                        </Col>
                        <Col md={3} className="text-right">
                          <div>
                            <span>{values.adjustment}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <span className="detail-head">Tcs</span>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="input-tooltip">
                            <AvField
                              name="selecttax"
                              placeholder="select a tax"
                            >
                              <option value="%">Select a Tax</option>
                              <option value="rs">Rs.</option>
                            </AvField>
                            <i class="bx bx-help-circle"></i>
                          </div>
                        </Col>
                        <Col md={3} className="text-right">
                          <div>
                            <span>0.00</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="details">
                          <div>
                            <span className="total">Total</span>
                          </div>

                          <div>
                            <span className="total">{totalAmount}</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <hr />
            <Row>
              <Col md={12}>
                <div className="notes">
                  <label>Notes</label>
                  <div>
                    <AvField
                      className="formik-textarea terms-textarea"
                      type="textarea"
                      name="notes"
                      placeholder="Looking forward for your business"
                      onChange={handleChange}
                      value={values.notes}
                    ></AvField>
                  </div>
                </div>
                <hr />
                <div className=" table-bottom">
                  <div className="row clearfix">
                    <div className="col-md-4 attachfiles">
                      <span>Attach File(s)</span>
                      <div className="attach">
                        <AvField
                          name="import"
                          type="file"
                          onChange={handleChange}
                          value={values.import}
                        ></AvField>
                      </div>
                      <div>
                        <span style={{ fontSize: "10px" }}>
                          You can upload a maximum of 5 files, 5MB each
                        </span>
                      </div>
                    </div>
                    {/* <div className="col-md-8 termsandcondition">
                          <span>Terms & Conditions</span>
                          <div className="mt-2">
                            <AvField
                              className="formik-textarea terms-textarea"
                              as="textarea"
                              name="termsandconditions"
                              onChange={handleChange}
                              value={values.termsandconditions}
                            ></AvField>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <span>Want to get paid faster?</span>
                        <div className="mt-2">
                          Configure payment gateways and receive payments
                          online. <Link>Set up Payment Gateway</Link>
                        </div>
                      </div>
                      <hr />
                      <div className="mt-4 align-center">
                        <span>Preference :</span>
                        <span className="ml-3">Create Invoices as Drafts</span>
                        <i className="bx bx-cog ml-2"></i> */}
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
      <div className="bottom-bar">
        <Row>
          <Col md={4}>
            <Row>
              <Col md={4}>
                <button className="btn btn-left ml-3" onClick={handleCancel}>
                  Cancel
                </button>
              </Col>
              <Col md={4}>
                <button
                  type="reset"
                  className="btn btn-left"
                  form="addpaymentmade"
                >
                  Clear
                </button>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="printView">
            <span className="print"></span>
          </Col>
          <Col md={4}>
            <button
              className="btn btn-right float-right mr-3"
              type="submit"
              form="addpaymentmade"
            >
              {reload ? (
                <img className="loader" src={loader} alt="loading..." />
              ) : (
                " Save"
              )}
            </button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default AddPaymentMadeForm;
