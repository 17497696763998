import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import AddItems from "./addItem";

import { AvForm } from "availity-reactstrap-validation";

// Pages Component
import SalesInfo from "./salesInformation";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../assets/loader/load.gif";
//i18n
import { withTranslation } from "react-i18next";
import { apiGet, apiPost, apiPut } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";

const Items = (props) => {
  var formType = props.match.params.type;
  var id = props.match.params.id;
  const history = useHistory();
  const [vendors, setVendors] = useState([]);
  const [itemdata, setitemdata] = useState({});
  const [modal, setmodal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [reload, setReload] = useState(false);
  const {
    salesData,
    purchaseData,
    checkSelling,
    checkPurchase,
    salesInformation,
    addItem,
    data,
  } = AddItems(vendors, itemdata, formType);

  function print() {
    window.print();
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  function getVendors() {
    let orgId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/vendors/org/${orgId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setVendors(resp.response.data.data);
      }
    });
  }
  function createItem() {
    setReload(true);
    if (checkPurchase === true) {
      if (purchaseData.itemPurchaseCostPrice.length == 0) {
        toast.error("Fill the fields in Purchase Information");
      } else {
        let orgId = localStorage.getItem("organisationId");
        let url = BaseUrl.apiUrl.baseUrl + "api/v1/items";

        let body = {
          itemType: data.itemType,
          itemName: data.itemName,
          itemSKU: "09002",
          itemUnit: data.itemUnit,
          isDgitalService: 1,
          isInventoryEnabled: data.isInventoryEnabled,
          itemHsnCode: data.itemHsnCode,
          itemSacCode: data.itemSacCode,
          itemTaxPreference: parseInt(data.itemTaxPreference),
          itemSalesSellingPrice: parseInt(salesData.itemSalesSellingPrice),
          itemSalesAccount: parseInt(salesData.itemSalesAccount),
          itemsSalesDescription: salesData.itemsSalesDescription,
          itemPurchaseCostPrice: parseInt(purchaseData.itemPurchaseCostPrice),
          itemPurchaseAccount: parseInt(purchaseData.itemPurchaseAccount),
          itemsPurchaseDescription: purchaseData.itemsPurchaseDescription,
          itemInterStateTax:
            data.itemTaxPreference == "0"
              ? parseFloat(data.itemInterStateTax)
              : 0,
          itemIntraStateTax:
            data.itemTaxPreference == "0"
              ? parseFloat(data.itemIntraStateTax)
              : 0,
          itemInventoryAccountId: data.itemInventoryAccountId,
          itemOpeningStock: data.itemOpeningStock,
          itemOpeningStockRatePerUnit: data.itemOpeningStockRatePerUnit,
          itemReorderPoint: data.itemReorderPoint,
          itemPreferredVendorId: purchaseData.itemPreferredVendorId,
          organizationId: orgId,
          branchId: 0,
          addedByUser: 1,
          editByUser: 1,
        };
        console.log("item data", purchaseData);

        let resp = apiPost(url, body);
        resp.then((resp) => {
          switch (resp.response.data.code) {
            case 200:
              toast.success(resp.response.data.data);
              setTimeout(() => {
                history.push("/viewitems");
                setReload(false);
              }, 1000);
              break;

            default:
              toast.error(resp.response.data.data);
          }
        });
      }
    } else {
      setReload(true);
      let orgId = localStorage.getItem("organisationId");
      let url = BaseUrl.apiUrl.baseUrl + "api/v1/items";

      let body = {
        itemType: data.itemType,
        itemName: data.itemName,
        itemSKU: "09002",
        itemUnit: data.itemUnit,
        isDgitalService: 1,
        itemHsnCode: data.itemHsnCode,
        itemSacCode: data.itemSacCode,
        itemTaxPreference: parseInt(data.itemTaxPreference),
        itemSalesSellingPrice: parseInt(salesData.itemSalesSellingPrice),
        itemSalesAccount: parseInt(salesData.itemSalesAccount),
        itemsSalesDescription: salesData.itemsSalesDescription,
        itemPurchaseCostPrice: parseInt(purchaseData.itemPurchaseCostPrice),
        itemPurchaseAccount: purchaseData.itemPurchaseAccount,
        itemsPurchaseDescription: purchaseData.itemsPurchaseDescription,
        itemInterStateTax:
          data.itemTaxPreference == "0"
            ? parseFloat(data.itemInterStateTax)
            : 0,
        itemIntraStateTax:
          data.itemTaxPreference == "0"
            ? parseFloat(data.itemIntraStateTax)
            : 0,
        itemInventoryAccountId: data.itemInventoryAccountId,
        itemOpeningStock: data.itemOpeningStock,
        itemOpeningStockRatePerUnit: data.itemOpeningStockRatePerUnit,
        itemReorderPoint: data.itemReorderPoint,
        itemPreferredVendorId: data.itemPreferredVendorId,
        organizationId: orgId,
        isInventoryEnabled: data.isInventoryEnabled,
        branchId: 0,
        addedByUser: 1,
        editByUser: 1,
      };
      console.log("item data", body);
      let resp = apiPost(url, body);
      resp.then((resp) => {
        switch (resp.response.data.code) {
          case 200:
            toast.success(resp.response.data.data);
            setTimeout(() => {
              history.push("/viewitems");
              setReload(false);
            }, 1000);
            break;

          default:
            toast.error(resp.response.data.data);
        }
      });
    }
  }
  function updateItem() {
    setReload(true);
    if (checkPurchase === true) {
      if (purchaseData.itemPurchaseCostPrice.length == 0) {
        toast.error("Fill the fields in Purchase Information");
      } else {
        let orgId = localStorage.getItem("organisationId");
        let url = BaseUrl.apiUrl.baseUrl + `api/v1/items/${id}`;

        let body = {
          itemType: data.itemType,
          itemName: data.itemName,
          itemSKU: "09002",
          itemUnit: data.itemUnit,
          isDgitalService: 1,
          isInventoryEnabled: data.isInventoryEnabled,
          itemHsnCode: data.itemHsnCode,
          itemSacCode: data.itemSacCode,
          itemTaxPreference: parseInt(data.itemTaxPreference),
          itemSalesSellingPrice: parseInt(salesData.itemSalesSellingPrice),
          itemSalesAccount: parseInt(salesData.itemSalesAccount),
          itemsSalesDescription: salesData.itemsSalesDescription,
          itemPurchaseCostPrice: parseInt(purchaseData.itemPurchaseCostPrice),
          itemPurchaseAccount: parseInt(purchaseData.itemPurchaseAccount),
          itemsPurchaseDescription: purchaseData.itemsPurchaseDescription,
          itemInterStateTax:
            data.itemTaxPreference == "0"
              ? parseFloat(data.itemInterStateTax)
              : 0,
          itemIntraStateTax:
            data.itemTaxPreference == "0"
              ? parseFloat(data.itemIntraStateTax)
              : 0,
          itemInventoryAccountId: data.itemInventoryAccountId,
          itemOpeningStock: data.itemOpeningStock,
          itemOpeningStockRatePerUnit: data.itemOpeningStockRatePerUnit,
          itemReorderPoint: data.itemReorderPoint,
          itemPreferredVendorId: data.itemPreferredVendorId,
          editByUser: 1,
        };

        let resp = apiPut(url, body);
        resp.then((resp) => {
          switch (resp.response.data.code) {
            case 200:
              toast.success(resp.response.data.data);
              setTimeout(() => {
                history.push(`/singleitems/${id}`);
                setReload(false);
              }, 1000);
              break;

            default:
              toast.error(resp.response.data.data);
          }
        });
      }
    } else {
      setReload(true);
      let orgId = localStorage.getItem("organisationId");
      let url = BaseUrl.apiUrl.baseUrl + `api/v1/items/${id}`;

      let body = {
        itemType: data.itemType,
        itemName: data.itemName,
        itemSKU: "09002",
        itemUnit: data.itemUnit,
        isDgitalService: 1,
        isInventoryEnabled: data.isInventoryEnabled,
        itemHsnCode: data.itemHsnCode,
        itemSacCode: data.itemSacCode,
        itemTaxPreference: parseInt(data.itemTaxPreference),
        itemSalesSellingPrice: parseInt(salesData.itemSalesSellingPrice),
        itemSalesAccount: parseInt(salesData.itemSalesAccount),
        itemsSalesDescription: salesData.itemsSalesDescription,
        itemPurchaseCostPrice: parseInt(purchaseData.itemPurchaseCostPrice),
        itemPurchaseAccount: parseInt(purchaseData.itemPurchaseAccount),
        itemsPurchaseDescription: purchaseData.itemsPurchaseDescription,
        itemInterStateTax:
          data.itemTaxPreference == "0"
            ? parseFloat(data.itemInterStateTax)
            : 0,
        itemIntraStateTax:
          data.itemTaxPreference == "0"
            ? parseFloat(data.itemIntraStateTax)
            : 0,
        itemInventoryAccountId: data.itemInventoryAccountId,
        itemOpeningStock: data.itemOpeningStock,
        itemOpeningStockRatePerUnit: data.itemOpeningStockRatePerUnit,
        itemReorderPoint: data.itemReorderPoint,
        itemPreferredVendorId: data.itemPreferredVendorId,
        editByUser: 1,
      };

      let resp = apiPut(url, body);
      resp.then((resp) => {
        switch (resp.response.data.code) {
          case 200:
            toast.success(resp.response.data.data);
            setTimeout(() => {
              history.push(`/singleitems/${id}`);
              setReload(false);
            }, 1000);
            break;

          default:
            toast.error(resp.response.data.data);
        }
      });
    }
  }
  function handleSubmit() {
    if (formType == "add" || formType == "clone") {
      createItem();
    } else if (formType == "edit") {
      updateItem();
    }
  }
  function getItemById() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      var datas = resp.response.data.data;
      setitemdata(datas);
    });
  }
  useEffect(() => {
    console.log("formtype", formType);
    if (formType == "edit" || formType == "clone") {
      getItemById();
    }
    getVendors();
  }, []);
  useEffect(() => {
    console.log("item wwww salesInformation", salesData);
    // console.log("item wwww purchaseData", purchaseData);
    // console.log("item wwww salesData", salesData);
  }, [salesData]);

  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>Item</h6>
            </Col>
          </Row>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={
              formType == "add"
                ? "Add new Item"
                : formType == "edit"
                ? "Edit Item"
                : formType == "clone"
                ? "Add Item"
                : ""
            }
            breadcrumbItem={props.t("Items")}
          />

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className="form"
                    onValidSubmit={(e) => handleSubmit()}
                    model={data}
                  >
                    <Row>
                      {/* classname="formLeft" */}
                      <Col md={12} className="">
                        {addItem}
                        {/* {salesInformation} */}
                      </Col>
                      {/* <Col md={4}></Col> */}
                    </Row>
                    {/* <BottomBar /> */}
                    <div className="bottom-bar">
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={4}>
                              <Link
                                to={
                                  formType == "add"
                                    ? "/viewitems"
                                    : formType == "edit"
                                    ? `/singleitems/${id}`
                                    : formType == "clone"
                                    ? `/viewitems`
                                    : ""
                                }
                              >
                                <button className="btn btn-left ml-3">
                                  Cancel
                                </button>
                              </Link>
                            </Col>
                            {formType == "add" ? (
                              <Col md={4}>
                                <button
                                  type="button"
                                  // onClick={clearDatas}
                                  className="btn btn-left"
                                >
                                  Clear
                                </button>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Col>
                        {/* <Col md={4} className="printView">
                          <span className="print" onClick={() => print()}>
                            Print or Preview
                          </span>
                        </Col> */}
                        <Col md={8} className="float-right">
                          <button
                            type="submit"
                            className="btn btn-right float-right mr-3"
                            disabled={reload}
                          >
                            {reload ? (
                              <img
                                className="loader"
                                src={loader}
                                alt="loading..."
                              />
                            ) : formType == "add" ? (
                              "Save"
                            ) : formType == "edit" ? (
                              "Update"
                            ) : formType == "clone" ? (
                              "Save"
                            ) : (
                              ""
                            )}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Items);
