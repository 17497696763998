import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

function AddReportingTag(props) {
  return (
    <>
      <Modal className="export-modal" isOpen={props.openModal}>
        <Row>
          <Col md={12} className="modal-head">
            <div>
              <h5>Add Reporting Tag</h5>
            </div>
            <div onClick={() => props.setOpenModel(false)}>
              <i class="bx bx-x"></i>
            </div>
          </Col>
        </Row>
        <hr />
        <AvForm className="form" onValidSubmit={(e) => props.handleSubmit(e)}>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Reporting Tag Name</label>
            </Col>
            <Col md={6}>
              <AvField
                name="tagName"
                type="text"
                placeholder="Enter Reporting Tag Name"
                onChange={(e) => props.handle(e)}
                value={props.values.tagName}
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Description</label>
            </Col>
            <Col md={6}>
              <AvField
                name="tagDescription"
                placeholder="Enter Description"
                type="text"
                onChange={(e) => props.handle(e)}
                value={props.values.tagDescription}
                required
              ></AvField>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <button
                type="submit"
                class="exportuserbtn"
                disabled={props.isSaving}
              >
                Add Reporting Tag
              </button>
            </Col>
          </Row>
        </AvForm>
      </Modal>
    </>
  );
}

export default AddReportingTag;
