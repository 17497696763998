import React from "react";
import { Container, Row, Col, Modal } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
function EditDeliveryMethod(props) {
  return (
    <Modal className="export-modal" isOpen={props.openModal}>
      <Row>
        <Col md={12} className="modal-head">
          <div>
            <h5>Add Delivery Method</h5>
          </div>
          <div onClick={() => props.setOpenModel(false)}>
            <i class="bx bx-x"></i>
          </div>
        </Col>
      </Row>
      <hr />
      <AvForm className="form" onValidSubmit={() => props.handleEdit(props.id)}>
        <Row className="d-flex align-items-center mb-3">
          <Col md={6}>
            <label style={{ whiteSpace: "pre" }}>Delivery Method</label>
          </Col>
          <Col md={6}>
            <AvField
              name="deliveryMethod"
              type="text"
              placeholder="Enter Delivery Method"
              onChange={(e) => props.handle(e)}
              value={props.editValues.deliveryMethod}
              required
            ></AvField>
          </Col>
        </Row>
        <Row className="d-flex align-items-center mb-3">
          <Col md={6}>
            <label style={{ whiteSpace: "pre" }}>Description</label>
          </Col>
          <Col md={6}>
            <AvField
              name="description"
              placeholder="Enter Description"
              type="text"
              onChange={(e) => props.handle(e)}
              value={props.editValues.description}
              required
            ></AvField>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <button type="submit" class="exportuserbtn">
              Edit Delivery Method
            </button>
          </Col>
        </Row>
      </AvForm>
    </Modal>
  );
}

export default EditDeliveryMethod;
