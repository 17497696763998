import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
import ExportModal from "./exportModal";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { BaseUrl } from "../../components/config/BaseUrl";
// import { toast, ToastContainer } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import { apiPost, apiPut } from "../../components/config/apiConfig";
const Buttons = (props) => {
  let userRole = localStorage.getItem("userRole");
  let adminId = localStorage.getItem("Id");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const form = useRef(null);
  const history = useHistory();
  // const [inputitemDiv, setInputitemDiv] = useState([<ItemComponent />]);
  const [approveModel, setapproveModel] = useState(false);

  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    mobile: "",
    organizationId: "",
  });

  // const onAddBtnClick = (event) => {
  //   setInputitemDiv([...inputitemDiv, <ItemComponent />]);
  // };
  // const removeDiv = (index) => {
  //   console.log("eeeee", index);
  //   var array = [...inputitemDiv];
  //   if (index !== -1) {
  //     array.splice(index, 1);
  //     setInputitemDiv(array);
  //   }
  // };
  // useEffect(() => {
  //   setCustomerId(props.history.location.pathname.split("/")[2]);
  //   setCustomerName(props.history.location.pathname.split("/")[3]);
  //   props.setDefaultItems();
  // }, []);
  // let refresh = window.location.reload();
  const handleSubmit = (e) => {
    e.persist();
    let orgId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + "api/v1/add_user";
    var body = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      password: values.password,
      mobile: values.mobile,
      organizationId: orgId,
    };
    let resp = apiPost(Url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success("Added User Successfully");
          setapproveModel(false);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
          break;

        default:
          toast.error("Failed to add user");
          setapproveModel(false);
        // setTimeout(() => {
        // }, 2000);
      }
    });
  };

  function handle(e) {
    const newData = { ...values };
    newData[e.target.name] = e.target.value;
    setValues(newData);
  }
  return (
    <Row>
      <ToastContainer
        position="bottom-center"
        // autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Col className="iitem">
        <div>
          <h5></h5>
        </div>
        <div className="itembtn">
          {/* {userRole == "ADMIN" ? (
            <button className="newbtn" onClick={() => setapproveModel(true)}>
              <i class="bx bx-plus"></i> New
            </button>
          ) : (
            ""
          )} */}
          {/* <button>
            {" "}
            <i class="bx bxs-cog"></i>
          </button> */}
          {/* <Dropdown
            className="d-inline-block"
            isOpen={socialDrp}
            toggle={() => {
              setsocialDrp(!socialDrp);
            }}
          >
            <DropdownToggle
              className="btn header-item waves-effect ddtoggle"
              tag="button"
            >
              <button>
                {" "}
                <i class="bx bx-menu"></i>
              </button>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag="a" href="">
                {" "}
                <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                {props.t("Import Online-Form")}{" "}
              </DropdownItem>
              <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                {" "}
                <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                {props.t("Export Online-Form")}{" "}
              </DropdownItem>
              <hr />
              <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                {" "}
                <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                {props.t("Export Current View")}{" "}
              </DropdownItem>
              <hr />
              <DropdownItem
                tag="a"
                onClick={() => window.location.reload(false)}
              >
                {" "}
                <i className="bx bx-refresh font-size-16 align-middle mr-1"></i>
                {props.t("Refresh List")}{" "}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
        </div>
        {/* <Modal className="export-modal" isOpen={modalIsOpen}>
          <ExportModal isClose={() => setModalIsOpen(false)} />
        </Modal> */}
        <Modal className="export-modal" isOpen={approveModel}>
          <Row>
            <Col md={12} className="modal-head">
              <div>
                <h5>Add New User</h5>
              </div>
              <div onClick={() => setapproveModel(false)}>
                <i class="bx bx-x"></i>
              </div>
            </Col>
          </Row>
          <hr />
          <AvForm className="form" onValidSubmit={(e) => handleSubmit(e)}>
            <Row className="d-flex align-items-center mb-3">
              <Col md={6}>
                <label style={{ whiteSpace: "pre" }}>First Name</label>
              </Col>
              <Col md={6}>
                <AvField
                  name="firstname"
                  type="text"
                  placeholder="Enter User Firstname"
                  onChange={(e) => handle(e)}
                  value={values.firstname}
                  required
                ></AvField>
              </Col>
            </Row>
            <Row className="d-flex align-items-center mb-3">
              <Col md={6}>
                <label style={{ whiteSpace: "pre" }}>Last Name</label>
              </Col>
              <Col md={6}>
                <AvField
                  name="lastname"
                  placeholder="Enter User Lastname"
                  type="text"
                  onChange={(e) => handle(e)}
                  value={values.lastname}
                  required
                ></AvField>
              </Col>
            </Row>
            <Row className="d-flex align-items-center mb-3">
              <Col md={6}>
                <label style={{ whiteSpace: "pre" }}>Email</label>
              </Col>
              <Col md={6}>
                <AvField
                  name="email"
                  type="email"
                  placeholder="Enter User Email"
                  onChange={(e) => handle(e)}
                  value={values.email}
                  required
                ></AvField>
              </Col>
            </Row>
            <Row className="d-flex align-items-center mb-3">
              <Col md={6}>
                <label style={{ whiteSpace: "pre" }}>Enter Password</label>
              </Col>
              <Col md={6}>
                <AvField
                  name="password"
                  type="password"
                  placeholder="Enter User Password"
                  onChange={(e) => handle(e)}
                  value={values.password}
                  required
                ></AvField>
              </Col>
            </Row>
            <Row className="d-flex align-items-center mb-3">
              <Col md={6}>
                <label style={{ whiteSpace: "pre" }}>
                  Enter Contact Number
                </label>
              </Col>
              <Col md={6}>
                <AvField
                  name="mobile"
                  type="number"
                  placeholder="Enter User Phone"
                  onChange={(e) => handle(e)}
                  value={values.mobile}
                  required
                ></AvField>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <button type="submit" class="exportuserbtn">
                  Add User
                </button>
              </Col>
            </Row>
          </AvForm>
        </Modal>
      </Col>
    </Row>
  );
};

export default withTranslation()(Buttons);
