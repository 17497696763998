import {
  SAVE_CURRENT_SELECTED_ITEM,
  GET_CURRENT_SELECTED_ITEM,
} from "./actionTypes";

export const getCurrentSelectedItem = (itemId) => ({
  type: GET_CURRENT_SELECTED_ITEM,
  payload: itemId,
});
export const saveCurrentSelectedItem = (data) => ({
  type: SAVE_CURRENT_SELECTED_ITEM,
  payload: { data },
});
