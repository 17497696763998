import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ResetEmailPassword from "../pages/Authentication/ResetPasswordEmail";
import ForgetPassword from "../pages/Authentication/ForgetPassword";
import MailSendSuccess from "../pages/Authentication/MailSendSuccess";
import InActive from "../pages/Inactive/InActive";

// Dashboard
import Customer from "../pages/Customer/index";
import ViewCustomer from "../pages/Customer/viewCustomer/index";
import SingleCustomer from "../pages/Customer/viewCustomer/singleCustomerView";
import ViewInvoice from "../pages/Invoice/viewInvoice";
import Invoice from "../pages/Invoice/index";
import SingleInvoice from "../pages/Invoice/singleinvoiceview";
import Dashboard from "../pages/Dashboard/index";
import Expenses from "../pages/Expenses/index";
import AddExpenses from "../pages/Expenses/addExpense";
import Items from "../pages/Items/index";
import ViewItems from "../pages/Items/ViewItems";
import SingleItem from "../pages/Items/ViewItems/itemViewModal";
import PriceLists from "../pages/Items/PriceLists/index";
import AddPriceList from "../pages/Items/PriceLists/addPriceList";
import InventoryAdj from "../pages/Items/InventoryAdjustments/index";
import AddNewAdjustment from "../pages/Items/InventoryAdjustments/addNewAdjustment";
import SingleInventoryView from "../pages/Items/InventoryAdjustments/singleInventoryView";
import Estimates from "../pages/Estimates/index";
import AddEstimate from "../pages/Estimates/addEstimate";
import SingleEstimateView from "../pages/Estimates/singlePageView";
import SingleBillView from "../pages/Bills/singlePageView";
import SalesOrder from "../pages/SalesOrder/index";
import AddSalesOrder from "../pages/SalesOrder/addSalesOrder";
import SingleSalesOrderView from "../pages/SalesOrder/singlePageView";
import SingleCreditNoteView from "../pages/CreditNotes/singlePageView";

import SinglePaymentsRecievedView from "../pages/PaymentRecieved/singlePageView";

import SingleRecurringInvoiceView from "../pages/RecuringInvoice/singlePageView";
import DeliveryChallan from "../pages/DeliveryChallan/index";
import AddDeliveryChallan from "../pages/DeliveryChallan/addDeliveryChallan";
import SingleDeliverChallanView from "../pages/DeliveryChallan/singlePageView";
import RecurringInvoice from "../pages/RecuringInvoice/index";
import AddRecurringInvoice from "../pages/RecuringInvoice/addRecurringInvoice";
import RetainerInvoice from "../pages/RetainerInvoice/index";
import AddRetainerInvoice from "../pages/RetainerInvoice/addRetainerInvoice";
import SingleRetainerInvoiceView from "../pages/RetainerInvoice/singlePageView";
import CreditNotes from "../pages/CreditNotes/index";
import AddCreditNote from "../pages/CreditNotes/addCreditNote";
import PurchaseOrders from "../pages/PurchaseOrders/index";
import AddPurchaseOrder from "../pages/PurchaseOrders/addPurchaseOrder";
import Bills from "../pages/Bills/index";
import AddBills from "../pages/Bills/addBills";
import VendorCredit from "../pages/VendorCredits/index";
import AddVendorCredit from "../pages/VendorCredits/addVendorCredit";
// PURCHASES
import Vendors from "../pages/Vendors/index";
import AddVendor from "../pages/Vendors/addVendor";
import singleViewVendors from "../pages/Vendors/VIewVendors/singleViewVendors";
import ExpensesList from "../pages/Expenses/expensesList";
import ExpenseSingleView from "../pages/Expenses/ExpenseSingleview";
import PurchaseOrderList from "../pages/PurchaseOrders/purchaseOrderList";
import AddPaymentMadeForm from "../pages/PaymentMade/addPaymentMadeForm";
import paymentMade from "../pages/PaymentMade/addPaymentMade";
import PaymentMade from "../pages/PaymentMade/";
import addPaymentMade from "../pages/PaymentMade/addPaymentMade";
import addPaymentRecieved from "../pages/PaymentRecieved/addPaymentRecieved";
import PaymentRecieved from "../pages/PaymentRecieved";
//WAREHOUSE
import ViewWareHouse from "../warehousepages/Warehouse/index";
import AddWareHouse from "../warehousepages/Warehouse/addWareHouse";
import ViewCargoCheckIn from "../warehousepages/Cargocheckin/index";
import CheckInSingleView from "../warehousepages/Cargocheckin/checkInSingleView";
import AddCargoCheckIn from "../warehousepages/Cargocheckin/addcargocheckin";
import CargoCheckOutList from "../warehousepages/Cargocheckout/index";
import CheckOutItem from "../warehousepages/Cargocheckout/checkoutitem";
import CheckOutByItem from "../warehousepages/Cargocheckout/CheckOutByItem";
import CheckOutSingleView from "../warehousepages/Cargocheckout/checkOutSingleView";
import CheckInreport from "../warehousepages/Reports/checkin-report";
import CheckOutReport from "../warehousepages/Reports/checkout-report";
import StockReport from "../warehousepages/Reports/stock-report";
import CustomerRentList from "../warehousepages/Reports/customer-rentlist";
import Subscription from "../warehousepages/subscription";
import AddSubscription from "../warehousepages/subscription/addSubscription";
import PalletMerge from "../warehousepages/PalletMerge";
import SinglePalletMerge from "../warehousepages/PalletMerge/singlepalletview";
import AddPalletMerge from "../warehousepages/PalletMerge/AddPalletMerge";
import OnlineForm from "../pages/OnlineForm/OnlineFrom";
import OnlineFormIndex from "../pages/OnlineForm/index";
import OnlineFormCustomerSingleView from "../pages/OnlineForm/onlineFormCustomerSingleView";
import OnlineFormEdit from "../pages/OnlineForm/OnlineFormEdit";
import UserIndex from "../warehousepages/Users";
import UsersForm from "../warehousepages/Users/UsersFrom";
import BillingReport from "../warehousepages/Reports/BillingReport";
import PasswordResetSuccess from "../pages/Authentication/PasswordResetSuccess";
import UomList from "../pages/Settings/UOM/uomList";

import HandlingInfoList from "../pages/Settings/HandlingInfo/HandlingInfoList";
import ReportingTagList from "../pages/Settings/ReportingTag/ReportingTagList";
import LocationTypeList from "../pages/Settings/LocationType/LocationTypeList";
import AisleList from "../pages/Settings/Aisle/AisleList";
import ZoneList from "../pages/Settings/Zone/ZoneList";
import RackList from "../pages/Settings/Racks/RackList";
import RowList from "../pages/Settings/Row/RowList";
import BinList from "../pages/Settings/Bin/BinList";

import BinLocationList from "../pages/BinLocation/BinLocationList";
import AddBinLocation from "../pages/BinLocation/AddBinLocation";

import PutAwayList from "../pages/PutAway/PutAwayList";
import AddPutAway from "../pages/PutAway/AddPutAway";

import PickUpList from "../pages/PickUp/PickUpList";
import addPickUp from "../pages/PickUp/AddPickUp";

import ChallanTypeList from "../pages/Settings/ChallanType/challanTypeList";
import addChallanType from "../pages/Settings/ChallanType/AddChallanType";

import DeliveryMethodList from "../pages/Settings/DeliveryMethod/deliveryMethodList";
import addDeliveryMethod from "../pages/Settings/DeliveryMethod/AddDeliveryMethod";

import ProjectList from "../pages/Settings/Project/projectList";
import addProject from "../pages/Settings/Project/AddProject";

import BranchList from "../pages/Settings/Branch/branchList";
import addBranch from "../pages/Settings/Branch/AddBranch";

import TaxList from "../pages/Settings/Tax/taxList";
import addTax from "../pages/Settings/Tax/AddTax";

import TcsList from "../pages/Settings/Tcs/tcsList";
import addTcs from "../pages/Settings/Tcs/AddTcs";

import TdsList from "../pages/Settings/Tds/tdsList";
import addTds from "../pages/Settings/Tds/AddTds";

import TaxSectionList from "../pages/Settings/TaxSection/taxSectionList";
import addTaxSection from "../pages/Settings/TaxSection/AddTaxSection";

import SalesOrderHistory from "../pages/SalesOrder/SalesOrderHistory";
import PurchaseOrderHistory from "../pages/PurchaseOrders/PurchaseOrderHistory";

import StockList from "../pages/Stock/StockList";

import ViewGoodsTransfer from "../pages/GoodsTransferNew/viewGoodsTransfer";
import GoodsTransfer from "../pages/GoodsTransferNew/index";
import SingleGoodsTransfer from "../pages/GoodsTransferNew/singlegoodstransferview";

import ViewCustomerCheckIn from "../customerpages/checkin/index";
import CustomerCheckOut from "../customerpages/checkout/index";
import CustomerCheckInSingleView from "../customerpages/checkin/CustomerCheckInSingleView";
import CustomerItemCheckOut from "../customerpages/checkin/CustomerItemCheckOut";
import CustomerOrder from "../customerpages/orderform/index";
import TermsAndUse from "../pages/TermsAndUse";
import CombinedReport from "../warehousepages/Reports/CombinedReport";
import CostSheetReport from "../warehousepages/Reports/CostSheetReport";
import ProfitReport from "../warehousepages/Reports/ProfitReport";
import WarehouseUsageReport from "../warehousepages/Reports/warehouseUsage";
import SinglePaymentsMadeView from "../pages/PaymentMade/singlePageView";
import Customers from "../pages/custom-page/custom";
import CalendarModule from "../pages/Calendar/Calendar";
//import GoodsTransferList from "../pages/GoodsTransfer/goodsTransferList";
//import GoodsTransfer from "../pages/GoodsTransfer";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/customer/:type", component: Customer },
  { path: "/customer/:type/:id", component: Customer },
  { path: "/customerlist", component: ViewCustomer },
  { path: "/expenseslist", component: ExpensesList },

  { path: "/invoice/:type", component: Invoice },
  { path: "/invoice/:type/:id", component: Invoice },
  { path: "/view-invoice", component: ViewInvoice },
  { path: "/expenses", component: Expenses },
  { path: "/addexpenses", component: AddExpenses },
  { path: "/ExpenseSingleView/:id", component: ExpenseSingleView },
  { path: "/paymentsmade", component: PaymentMade },
  { path: "/paymentsmadeview/:id", component: SinglePaymentsMadeView },
  { path: "/addpaymentsmade", component: addPaymentMade },
  { path: "/paymentsrecieved", component: PaymentRecieved },
  { path: "/addpaymentsrecieved", component: addPaymentRecieved },
  { path: "/addpaymentsrecieved/:type/:id", component: addPaymentRecieved },
  { path: "/additems/:type", component: Items },
  { path: "/additems/:type/:id", component: Items },
  { path: "/viewitems", component: ViewItems },
  { path: "/priceLists", component: PriceLists },
  { path: "/addpricelist", component: AddPriceList },
  { path: "/inventoryadjustment", component: InventoryAdj },
  { path: "/addadjustment", component: AddNewAdjustment },
  { path: "/estimates", component: Estimates },
  { path: "/addestimate", component: AddEstimate },
  { path: "/salesorder", component: SalesOrder },
  { path: "/addsalesorder/:type", component: AddSalesOrder },
  { path: "/addsalesorder/:type/:id", component: AddSalesOrder },
  { path: "/deliverychallan", component: DeliveryChallan },
  { path: "/adddeliverychallan", component: AddDeliveryChallan },

  { path: "/recurringinvoice", component: RecurringInvoice },
  { path: "/addrecurringinvoice", component: AddRecurringInvoice },
  { path: "/retainerinvoice", component: RetainerInvoice },
  { path: "/addretainerinvoice", component: AddRetainerInvoice },
  { path: "/creditnotes", component: CreditNotes },
  { path: "/addcreditnote", component: AddCreditNote },
  { path: "/purchaseorders", component: PurchaseOrders },
  { path: "/addpurchaseorder", component: AddPurchaseOrder },
  { path: "/bills", component: Bills },
  { path: "/addbills", component: AddBills },
  { path: "/vendorcredit", component: VendorCredit },
  { path: "/addvendorcredit", component: AddVendorCredit },

  //SINGLE VIEW
  { path: "/singlecustomer/:id", component: SingleCustomer },
  { path: "/SingleVIewVendors/:id", component: singleViewVendors },
  { path: "/adjustedinventory", component: SingleInventoryView },
  { path: "/deliverchallanview/:id", component: SingleDeliverChallanView },
  { path: "/salesorderview/:id", component: SingleSalesOrderView },
  { path: "/retainerinvoiceview/:id", component: SingleRetainerInvoiceView },
  { path: "/paymentsrecievedview/:id", component: SinglePaymentsRecievedView },

  { path: "/singleitems/:id", component: SingleItem },
  { path: "/singleestimates/:id", component: SingleEstimateView },
  { path: "/singleBill/:id", component: SingleBillView },
  { path: "/singleinvoice/:id", component: SingleInvoice },
  { path: "/viewcheckin/:id", component: CheckInSingleView },
  { path: "/viewcheckout/:id", component: CheckOutSingleView },
  { path: "/viewpalletmerge/:id", component: SinglePalletMerge },
  { path: "/creditnoteview/:id", component: SingleCreditNoteView },
  { path: "/recurringinvoiceview/:id", component: SingleRecurringInvoiceView },

  //PURCHASE
  { path: "/vendors", component: Vendors },
  { path: "/addvendor", component: AddVendor },
  //WAREHOUSE
  { path: "/warehouse-list", component: ViewWareHouse },
  { path: "/addwarehouse/:type", component: AddWareHouse },
  { path: "/editwarehouse/:type/:id", component: AddWareHouse },
  { path: "/cargocheckin-list", component: ViewCargoCheckIn },
  { path: "/addcargocheckin/:type", component: AddCargoCheckIn },
  { path: "/editcargocheckin/:type/:id", component: AddCargoCheckIn },
  { path: "/cargocheckout-list", component: CargoCheckOutList },
  { path: "/addcheckout/:type", component: CheckOutItem },
  { path: "/editcheckout/:type/:id", component: CheckOutItem },
  { path: "/addcheckoutbyitem/:type", component: CheckOutByItem },
  { path: "/editcheckoutbyitem/:type/:id", component: CheckOutByItem },

  { path: "/checkinreport", component: CheckInreport },
  { path: "/checkoutreport", component: CheckOutReport },
  { path: "/stockreport", component: StockReport },
  { path: "/billingreport", component: BillingReport },
  { path: "/combinedreport", component: CombinedReport },
  { path: "/rentlist", component: CustomerRentList },
  { path: "/subscription", component: Subscription },
  { path: "/subscription/:type", component: AddSubscription },
  { path: "/subscription/:type/:id", component: AddSubscription },
  { path: "/palletmerge", component: PalletMerge },
  { path: "/palletmerge/:type", component: AddPalletMerge },
  { path: "/palletmerge/:type/:id", component: PalletMerge },
  { path: "/online-form-list", component: OnlineFormIndex },
  { path: "/profitreport", component: ProfitReport },
  { path: "/warehouse-usage-report", component: WarehouseUsageReport },

  // { path: "/vieworderform/:id", component: OnlineFormSingleView },
  { path: "/vieworderform/:id", component: OnlineFormEdit },
  { path: "/Users", component: UserIndex },
  { path: "/Add-User", component: UsersForm },
  { path: "/costsheetreport", component: CostSheetReport },

  // ------------------------------------SETTINGS-------------------------------------------
  { path: "/unit-of-measurement", component: UomList },
  { path: "/handlinginfo", component: HandlingInfoList },
  { path: "/reporting-tag", component: ReportingTagList },
  { path: "/location-type", component: LocationTypeList },
  { path: "/aisle", component: AisleList },
  { path: "/zone", component: ZoneList },
  { path: "/rack", component: RackList },
  { path: "/row", component: RowList },
  { path: "/bin", component: BinList },
  { path: "/tax", component: TaxList },
  { path: "/tax-section", component: TaxSectionList },
  { path: "/tcs", component: TcsList },
  { path: "/tds", component: TdsList },

  // { path: "/handling-info", component: UsersForm },
  // { path: "/unit-of-measurement", component: UsersForm },

  //CustomerLogin
  { path: "/customer-checkin-list", component: ViewCustomerCheckIn },
  { path: "/customer-checkout-list", component: CustomerCheckOut },
  { path: "/customer-checkin-view/:id", component: CustomerCheckInSingleView },
  { path: "/customer-checkout/:orderId/:id", component: CustomerItemCheckOut },
  { path: "/customer-order-list", component: CustomerOrder },

  { path: "/bin-location", component: BinLocationList },
  { path: "/addBinLocation/:type", component: AddBinLocation },
  { path: "/editBinLocation/:type/:id", component: AddBinLocation },

  { path: "/put-away", component: PutAwayList },
  { path: "/addPutAway/:type", component: AddPutAway },
  { path: "/editPutAway/:type/:id", component: AddPutAway },

  { path: "/pick-up", component: PickUpList },
  { path: "/addPickUp/:type", component: addPickUp },
  { path: "/editPickUp/:type/:id", component: addPickUp },

  { path: "/challan-type", component: ChallanTypeList },
  { path: "/addChallanType/:type", component: addChallanType },
  { path: "/editChallanType/:type/:id", component: addChallanType },

  { path: "/delivery-method", component: DeliveryMethodList },
  { path: "/addDeliveryMethod/:type", component: addDeliveryMethod },
  { path: "/editDeliveryMethod/:type/:id", component: addDeliveryMethod },

  { path: "/project", component: ProjectList },
  { path: "/addProject/:type", component: addProject },
  { path: "/editProject/:type/:id", component: addProject },

  { path: "/branch", component: BranchList },
  { path: "/addBranch/:type", component: addBranch },
  { path: "/editBranch/:type/:id", component: addBranch },

  { path: "/sales-order-history", component: SalesOrderHistory },
  { path: "/purchase-order-history", component: PurchaseOrderHistory },

  { path: "/stock-status", component: StockList },

  //{ path: "/goods-transfer", component: GoodsTransfer },
  //{ path: "/addGoodsTransfer/:type", component: addGoodsTransfer },
  //{ path: "/addGoodsTransfer/:type/:id", component: addGoodsTransfer },
  //{ path: "/goodsTransferView/:id", component: SingleGoodsTransferView },

  { path: "/singlegoodstransfer/:id", component: SingleGoodsTransfer },
  { path: "/goodstransfer/:type", component: GoodsTransfer },
  { path: "/goodstransfer/:type/:id", component: GoodsTransfer },
  { path: "/view-goodstransfer", component: ViewGoodsTransfer },
  { path: "/customer", component: Customers },
  { path: "/Calender", component: CalendarModule },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ResetEmailPassword },
  { path: "/reset-password/:token/:email", component: ForgetPassword },
  { path: "/register", component: Register },
  { path: "/mail-success", component: MailSendSuccess },
  { path: "/reset-success", component: PasswordResetSuccess },
  {
    path: "/online-form/:id/:organisationId/:token",
    component: OnlineForm,
  },
  {
    path: "/confirm-order/:orderid/:customerid/:token",
    component: OnlineFormCustomerSingleView,
  },
  { path: "/inactivity", component: InActive },
  { path: "/terms", component: TermsAndUse },
];

export { userRoutes, authRoutes };
