import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import classnames from "classnames";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

// Pages Component

import PriceListForm from "./priceListForm";
import BottomBar from "./bottomBar";
import UpdatePriceList from "./updatePriceList";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import loader from "../../../assets/loader/load.gif";
// import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//i18n
import { withTranslation } from "react-i18next";

import { BaseUrl } from "../../../components/config/BaseUrl";
import { apiPost } from "../../../components/config/apiConfig";

import { useHistory } from "react-router-dom";

const AddPriceList = (props) => {
  const [modal, setmodal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [reload, setReload] = useState(false);

  const { addPriceListRender, upData, vpData, data } = PriceListForm();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    setReload(true);
    let token = localStorage.getItem("accessToken");
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/price_list";

    let body = {
      priceListName: data.name,
      priceListType: data.pricetype,
      priceListItemRateType: data.itemrate,
      priceListDescription: data.description,
      priceListMarkUpType: data.itemrate,
      priceListPercentage: data.percentage,
      priceListRoundoff: data.roundOff,
      priceListCurrency: data.currency,
      priceListPricingScheme: data.pricingscheme,
      organizationId: 1,
      branchId: Number,
      addedByUser: 1,
      editByUser: 1,
      priceListItemsList: vpData,
    };
    let resp = apiPost(url, body);
    resp.then((resp) => {
      console.log("response data is", resp.response.data);
      setReload(false);
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            history.push("/priceLists");
            // useHistory().push("/customerlist");
          }, 1000);
          break;

        default:
          toast.error(resp.response.data.data);
      }
    });
  }
  const handleCancel = () => {
    setTimeout(() => {
      history.push("/priceLists");
    }, 50);
  };
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Add new price list")}
            breadcrumbItem={props.t("Items")}
          />

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className="form"
                    model={data}
                    onValidSubmit={(e) => handleSubmit(e)}
                  >
                    <Row>
                      <Col md={12} className="formLeft pricelist">
                        {/* <PriceListForm /> */}
                        {addPriceListRender}
                      </Col>
                      {/* <Col md={4}>sa</Col> */}
                    </Row>
                    <div className="bottom-bar">
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={4}>
                              <button
                                className="btn btn-left ml-3"
                                onClick={handleCancel}
                              >
                                Cancel
                              </button>
                            </Col>
                            <Col md={4}>
                              <button type="reset" className="btn btn-left">
                                Clear
                              </button>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4} className="printView">
                          <span className="print">Print or Preview</span>
                        </Col>
                        <Col md={4}>
                          <button
                            className="btn btn-right float-right mr-3"
                            type="submit"
                          >
                            {reload ? (
                              <img
                                className="loader"
                                src={loader}
                                alt="loading..."
                              />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(AddPriceList);
