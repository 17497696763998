import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import ShippingAddress from "./shippingDetails";
import AddCustomer from "./addCustomer";
import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";
import BottomBar from "./bottomBar";
import loader from "../../assets/loader/load.gif";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

// Pages Components
import BillingAddress from "./billingAddress";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet, apiPost, apiPut } from "../../components/config/apiConfig";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Customer(props) {
  var formType = props.match.params.type;
  var id = props.match.params.id;
  const scrollRef = useRef();
  const [countryId, setCountryId] = useState("1");
  const history = useHistory();
  const [customerData, setcustomerData] = useState({});
  const [currency, setCurrency] = useState([]);
  const [state, setState] = useState([]);
  const [country, setCountry] = useState([]);
  const [warehouseList, setwarehouseList] = useState([]);
  const [mandatoryReminder, setMandatoryReminder] = useState(false);
  const {
    email,
    addCustomerRender,
    data,
    customerWorkPhone,
    customerMobile,
    selectedWarehouses,
    mobile,
    workPhone,
    setData,
    setSNames,
    setcustomerMobile,
    setcustomerWorkPhone,
    setMobile,
    setWorkPhone,
    selectRef,
    contactPerson,
  } = AddCustomer(currency, formType, customerData, warehouseList);
  const {
    addressDataRender,
    addressData,
    selectedbillingstate,
    selectedshippingstate,
    setselectedshippingstate,
    setselectedbillingstate,
    billingData,
    contactData,
    setContactData,
    setBillingData,
    setAddressData,
  } = ShippingAddress(
    country,
    formType,
    customerData,
    data,
    customerWorkPhone,
    customerMobile
  );
  const [reload, setReload] = useState(false);

  const [modal, setmodal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const form = useRef();
  const clearForm = () => {
    form.current.reset(); //this will reset all the inputs in the form
    setSNames([]);
    setData({
      customerType: "business",
      customerBillingType: "",
      customerFixedQuoteRate: "",
      customerSalutation: "",
      customerFirstname: "",
      customerLastname: "",
      customerDisplayName: "",
      customerCompanyName: "",
      customerEmail: "",
      customerWorkPhone: "",
      customerMobile: "",
      panNumber: "",
      customerCurrency: "",
      customerOpeningBalance: "",
      customerCreditLimit: "",
      customerHandlingCharges: "",
      customerPaymentTermsId: "",
      customerPaymentTerms: "",
      customerHandlingInCharge: "",
      customerHandlingOutCharge: "",
      customerRemarks: "",
      customerGstTreatmentId: "",
      gstIn: "",
      customerPlaceOfSupplyId: "",
      customerTaxPreference: "0",
      exemptReason: "",
      customerFacebook: "",
      customerTwitter: "",
      customerWebsite: "",
      customerSkypeName: "",
      customerDesignation: "",
      customerDepartment: "",
      reportingTag: "",
    });
    setcustomerMobile("");
    setcustomerWorkPhone("");
    setContactData({
      contactId: "",
      contactSalutation: "",
      contactFirstname: "",
      contactLastname: "",
      contactEmail: "",
      contactMobile: "",
    });
    setBillingData({
      addressId: "",
      addressAttention: "",
      addressCountry: "",
      addressLine1: "",
      addressLine2: "",
      addressCity: "",
      addressStateId: "",
      addressZipCode: "",
      addressPhone: "",
      addressFax: "",
    });
    setAddressData({
      addressId: "",
      addressAttention: "",
      addressCountry: "",
      addressLine1: "",
      addressLine2: "",
      addressCity: "",
      addressStateId: "",
      addressZipCode: "",
      addressPhone: "",
      addressFax: "",
    });
    setselectedbillingstate({
      id: 0,
      name: "",
    });
    setselectedshippingstate({
      id: 0,
      name: "",
    });
  };
  function getWarehouseData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/warehouse/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setwarehouseList(resp.response.data.data);
    });
  }
  function getCurrency() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/currencies`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setCurrency(resp.response.data.data);
      }
    });
  }
  function getCountry() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/countries`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setCountry(resp.response.data.data);
      }
    });
  }

  function createCustomer() {
    setReload(true);
    let token = localStorage.getItem("accessToken");
    let organizationId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/customers";

    let body = {
      customerType: "",
      customerBillingType: data.customerBillingType,
      customerSalutation: data.customerSalutation,
      customerCostType: data.customerCostType,
      customerHandlingType: data.customerHandlingType,
      customerFirstname: data.customerFirstname,
      customerLastname: data.customerLastname,
      customerFixedQuoteRate: parseInt(data.customerFixedQuoteRate),
      customerCompanyName: data.customerCompanyName,
      customerDisplayName: data.customerDisplayName,
      customerEmail: data.customerEmail,
      customerWorkPhone: customerWorkPhone,
      customerMobile: customerMobile,
      customerDesignation: data.customerDesignation,
      customerDepartment: data.customerDepartment,
      customerWebsite: data.customerWebsite,
      customerGstTreatmentId: parseInt(data.customerGstTreatmentId), //int
      customerPlaceOfSupplyId: parseInt(data.customerPlaceOfSupplyId), //int
      customerTaxPreference: parseInt(data.customerTaxPreference), //int
      customerCurrency: data.customerCurrency,
      customerOpeningBalance: data.customerOpeningBalance,
      customerPaymentTermsId: parseInt(data.customerPaymentTermsId), //int
      customerPaymentTerms: parseInt(data.customerPaymentTerms), //int
      customerHandlingOutCharge: parseInt(data.customerHandlingOutCharge), //int
      customerHandlingInCharge: parseInt(data.customerHandlingInCharge), //int
      customerSkypeName: data.customerSkypeName,
      customerFacebook: data.customerFacebook,
      customerTwitter: data.customerTwitter,
      customerRemarks: data.customerRemarks,
      customerCreditLimit: parseInt(data.customerCreditLimit),
      customerReportingTags: data.customerReportingTags,
      customerCostRate: data.customerCostRate,
      organizationId: organizationId,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
      customerAddresses: [
        {
          addressType: 2,
          addressAttention: addressData.addressAttention,
          addressCountry: addressData.addressCountry,
          addressLine1: addressData.addressLine1,
          addressLine2: addressData.addressLine2,
          addressCity: addressData.addressCity,
          addressStateId: selectedshippingstate.id,
          addressZipCode: addressData.addressZipCode,
          addressPhone: addressData.addressPhone,
          addressFax: addressData.addressFax,
          addedByUser: 1,
          editByUser: 1,
        },
        {
          addressType: 1,
          addressAttention: billingData.addressAttention,
          addressCountry: billingData.addressCountry,
          addressLine1: billingData.addressLine1,
          addressLine2: billingData.addressLine2,
          addressCity: billingData.addressCity,
          addressStateId: selectedbillingstate.id,
          addressZipCode: billingData.addressZipCode,
          addressPhone: billingData.addressPhone,
          addressFax: billingData.addressFax,
          addedByUser: 1,
          editByUser: 1,
        },
      ],
      contactPersons: contactPerson,
      warehouseList: selectedWarehouses,
    };

    let resp = apiPost(url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            setReload(false);
            history.push("/customerlist");
            // useHistory().push("/customerlist");
          }, 1000);
          break;

        default:
          toast.error(resp.response.data.data);
          setReload(false);
      }
    });
  }
  function updateCustomer() {
    setReload(true);

    let token = localStorage.getItem("accessToken");
    let organizationId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + `api/v1/customers/${id}`;

    let body = {
      customerType: "",
      customerBillingType: data.customerBillingType,
      customerCostType: data.customerCostType,
      customerHandlingType: data.customerHandlingType,
      customerSalutation: data.customerSalutation,
      customerFirstname: data.customerFirstname,
      customerLastname: data.customerLastname,
      customerFixedQuoteRate: parseInt(data.customerFixedQuoteRate),
      customerCompanyName: data.customerCompanyName,
      customerDisplayName: data.customerDisplayName,
      customerEmail: data.customerEmail,
      customerWorkPhone: customerWorkPhone,
      customerMobile: customerMobile,
      customerDesignation: data.customerDesignation,
      customerDepartment: data.customerDepartment,
      customerWebsite: data.customerWebsite,
      customerGstTreatmentId: parseInt(data.customerGstTreatmentId), //int
      customerPlaceOfSupplyId: parseInt(data.customerPlaceOfSupplyId), //int
      customerTaxPreference: parseInt(data.customerTaxPreference), //int
      customerCurrency: data.customerCurrency,
      customerOpeningBalance: data.customerOpeningBalance,
      customerPaymentTermsId: parseInt(data.customerPaymentTermsId), //int
      customerPaymentTerms: parseInt(data.customerPaymentTerms), //int
      customerHandlingOutCharge: parseInt(data.customerHandlingOutCharge), //int
      customerHandlingInCharge: parseInt(data.customerHandlingInCharge), //int
      customerSkypeName: data.customerSkypeName,
      customerFacebook: data.customerFacebook,
      customerTwitter: data.customerTwitter,
      customerRemarks: data.customerRemarks,
      customerCreditLimit: parseInt(data.customerCreditLimit),
      customerReportingTags: data.customerReportingTags,
      customerCostRate: data.customerCostRate,
      organizationId: organizationId,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
      customerAddresses: [
        {
          addressId: parseInt(addressData.addressId),
          addressType: 2,
          addressAttention: addressData.addressAttention,
          addressCountry: addressData.addressCountry,
          addressLine1: addressData.addressLine1,
          addressLine2: addressData.addressLine2,
          addressCity: addressData.addressCity,
          addressStateId: selectedshippingstate.id,
          addressZipCode: addressData.addressZipCode,
          addressPhone: addressData.addressPhone,
          addressFax: addressData.addressFax,
          addedByUser: 1,
          editByUser: 1,
        },
        {
          addressId: parseInt(billingData.addressId),
          addressType: 1,
          addressAttention: billingData.addressAttention,
          addressCountry: billingData.addressCountry,
          addressLine1: billingData.addressLine1,
          addressLine2: billingData.addressLine2,
          addressCity: billingData.addressCity,
          addressStateId: selectedbillingstate.id,
          addressZipCode: billingData.addressZipCode,
          addressPhone: billingData.addressPhone,
          addressFax: billingData.addressFax,
          addedByUser: 1,
          editByUser: 1,
        },
      ],
      contactPersons: contactPerson,
      warehouseList: selectedWarehouses,
    };
    let resp = apiPut(url, body);

    resp.then((resp) => {
      setReload(false);

      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            history.push(`/singlecustomer/${id}`);
          }, 1000);
          break;

        default:
          toast.error(resp.response.data.data);
      }
    });
  }

  function handleSubmit(e) {
    if (formType == "add") {
      if (
        //selectedWarehouses.length > 0 &&
        email == "Email Available" &&
        customerWorkPhone.length > 8 &&
        billingData.addressAttention !== "" &&
        billingData.addressCountry !== "" &&
        billingData.addressLine1 !== "" &&
        billingData.addressCity !== "" &&
        selectedbillingstate.id !== 0 &&
        billingData.addressZipCode !== ""
      ) {
        setMandatoryReminder(false);
        createCustomer();
      } else {
        executeScroll();
        setMandatoryReminder(true);
        // setTimeout(() => {
        //   setMandatoryReminder(false);
        // }, 2000);
      }
    } else if (formType == "edit") {
      if (
        //selectedWarehouses.length > 0 &&
        customerWorkPhone.length > 8 &&
        billingData.addressAttention !== "" &&
        billingData.addressCountry !== "" &&
        billingData.addressLine1 !== "" &&
        billingData.addressCity !== "" &&
        selectedbillingstate.id !== 0 &&
        billingData.addressZipCode !== ""
      ) {
        updateCustomer();
      } else {
        executeScroll();
        setMandatoryReminder(true);
        // setTimeout(() => {
        //   setMandatoryReminder(false);
        // }, 2000);
      }
    }
  }

  function getCustomerDataById() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/customers/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      var datas = resp.response.data.data;

      setcustomerData(datas);
    });
  }
  useEffect(() => {
    if (formType == "edit") {
      getCustomerDataById();
    }
    getCountry();
    getCurrency();
    getWarehouseData();
  }, []);
  useEffect(() => {
    if (mandatoryReminder) {
      if (
        //selectedWarehouses.length > 0 &&
        email == "Email Available" &&
        customerWorkPhone.length > 8 &&
        billingData.addressAttention !== "" &&
        billingData.addressCountry !== "" &&
        billingData.addressLine1 !== "" &&
        billingData.addressCity !== "" &&
        selectedbillingstate.id !== 0 &&
        billingData.addressZipCode !== ""
      ) {
        setMandatoryReminder(false);
      } else {
        setMandatoryReminder(true);
      }
    }
  }, [
    selectedWarehouses,
    email,
    customerWorkPhone,
    billingData,
    selectedbillingstate,
  ]);
  const executeScroll = () => scrollRef.current.scrollIntoView();
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content" ref={scrollRef}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>{formType == "add" ? "Add Customer" : "Edit Customer"}</h6>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card>
                {mandatoryReminder ? (
                  <Row>
                    <Col md={12}>
                      <div
                        className={
                          mandatoryReminder
                            ? "mandatory-container blinker"
                            : "mandatory-container"
                        }
                      >
                        <span>
                          * symbol indicates mandatory fields. Fill all the
                          mandatory fields
                        </span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <CardBody>
                  <AvForm
                    className="form"
                    onValidSubmit={(e) => handleSubmit(e)}
                    model={data}
                    ref={form}
                  >
                    <Row>
                      <Col md={8} className="formLeft">
                        {addCustomerRender}
                      </Col>
                      <Col md={4}>{addressDataRender}</Col>
                    </Row>
                    <div className="bottom-bar">
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={4}>
                              <Link to="/customerlist">
                                <button
                                  type="button"
                                  className="btn btn-left ml-3"
                                >
                                  Cancel
                                </button>
                              </Link>
                            </Col>
                            {formType == "add" ? (
                              <Col md={4}>
                                <button
                                  type="button"
                                  onClick={clearForm}
                                  className="btn btn-left"
                                >
                                  Clear
                                </button>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Col>

                        <Col md={8}>
                          <button
                            type="submit"
                            className="btn btn-right float-right mr-3"
                            disabled={reload}
                          >
                            {reload ? (
                              <img
                                className="loader"
                                src={loader}
                                alt="loading..."
                              />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(Customer);
