import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import classnames from "classnames";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

// Pages Component

import AddRecurringInvoiceForm from "./addPaymentMadeForm";
import BottomBar from "./bottomBar";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import AddPaymentMadeForm from "./addPaymentMadeForm";

const AddPaymentMade = (props) => {
  const [modal, setmodal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Record New Payment"
            breadcrumbItem={props.t("Accounting")}
          />

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AvForm className="form">
                    <Row>
                      <Col md={12} className=" pricelist">
                        <AddPaymentMadeForm />
                      </Col>
                      {/* <Col md={4}>sa</Col> */}
                    </Row>
                    {/* <BottomBar /> */}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(AddPaymentMade);
