import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { connect } from "react-redux";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ExportModal from "./exportModal";
import { Link } from "react-router-dom";
import { getCurrentSelectedItem } from "../../../store/actions";
import { BaseUrl } from "../../../components/config/BaseUrl";
import { apiPut } from "../../../components/config/apiConfig";
const SingleItem = (props) => {
  console.log("props", props);
  var id = props.match.params.id;
  const [socialDrp, setsocialDrp] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reorderPoint, setReorderPoint] = useState(
    props.selectedItem.itemReorderPoint
  );
  const [reorderpopoverbottom, setreorderpopoverbottom] = useState(false);
  useEffect(() => {
    props.getCurrentSelectedItem(id);
  }, []);
  useEffect(() => {
    console.log("selected item", props.selectedItem);
  }, [props]);
  const handleActive = (e) => {
    e.preventDefault();
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/${id}/active_status`;
    let activeStatus = props.selectedItem.activeStatus === 0 ? 1 : 0;
    let body = {
      activeStatus: activeStatus,
      editByUser: 1,
    };
    let resp = apiPut(Url, body);
    resp.then((resp) => {
      if (resp.response.data.code === 200) {
        console.log("coa list", resp);
        props.getCurrentSelectedItem(id);
      }
      // setCoA(resp.response.data.data);
    });
  };
  const handleUpdateRP = (e) => {
    e.preventDefault();
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/${id}/reorder_point`;
    // let activeStatus = props.selectedItem.activeStatus === 0 ? 1 : 0;
    let body = { itemReorderPoint: reorderPoint, editByUser: 1 };
    let resp = apiPut(Url, body);
    resp.then((resp) => {
      if (resp.response.data.code === 200) {
        setreorderpopoverbottom(false);
        props.getCurrentSelectedItem(id);
      }
      // setCoA(resp.response.data.data);
    });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12} className="item-head">
              <div>
                <h5>{props.selectedItem.itemName} </h5>
              </div>
              <div className="bbb">
                <Link to={`/additems/edit/${id}`}>
                  <button>
                    <i class="bx bx-pencil"></i>
                  </button>
                </Link>
                <Dropdown
                  className="d-inline-block"
                  isOpen={socialDrp}
                  toggle={() => {
                    setsocialDrp(!socialDrp);
                  }}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect ddtoggle"
                    tag="button"
                  >
                    <button>
                      {" "}
                      {/* <i class="bx bx-menu"></i> */}
                      More
                    </button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <Link to={`/additems/clone/${id}`}>
                      <DropdownItem tag="a" href="">
                        {" "}
                        {"Clone Item"}{" "}
                      </DropdownItem>
                    </Link>
                    <hr />
                    <DropdownItem tag="a" href="" onClick={handleActive}>
                      {" "}
                      {props.selectedItem.activeStatus === 0
                        ? "Mark as Inactive"
                        : "Mark as Active"}{" "}
                    </DropdownItem>
                    <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                      {" "}
                      {"Delete"}{" "}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Modal className="export-modal" isOpen={modalIsOpen}>
                  <ExportModal isClose={() => setModalIsOpen(false)} id={id} />
                </Modal>
              </div>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <AvForm className="form itemview">
                <div>
                  <Row>
                    <Col md={4}>
                      <span className="head">Item Type</span>
                    </Col>
                    <Col md={8}>
                      {props.selectedItem.itemType == 1 ? "Service" : "Goods"}
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Created Source</span>
                    </Col>
                    <Col md={8}>User</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Active Status</span>
                    </Col>
                    <Col md={8}>
                      {props.selectedItem.activeStatus === 0
                        ? "Active"
                        : "Inactive"}
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Tax Preference</span>
                    </Col>
                    <Col md={8}>
                      {props.selectedItem.itemTaxPreference == 1
                        ? "Non-Taxable"
                        : "Taxable"}
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Intra State Tax Rate</span>
                    </Col>
                    <Col md={8}>{props.selectedItem.itemIntraStateTax} %</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Inter State Tax Rate</span>
                    </Col>
                    <Col md={8}>{props.selectedItem.itemInterStateTax} %</Col>
                  </Row>
                </div>
                {/* <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Stock in Hand</span>
                    </Col>
                    <Col md={8}>{props.selectedItem.stockInHand} </Col>
                  </Row>
                </div> */}

                <div>
                  <Row>
                    <Col md={12}>
                      <span className="itempur-info">Purchase information</span>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Cost Price</span>
                    </Col>
                    <Col md={8}>
                      ₹{props.selectedItem.itemPurchaseCostPrice}
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Purchase Account</span>
                    </Col>
                    <Col md={8}>
                      {props.selectedItem.itemPurchaseAccountName}
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Purchase Description</span>
                    </Col>
                    <Col md={8}>
                      {props.selectedItem.itemsPurchaseDescription}
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={12}>
                      <span className="itempur-info">Sales Information</span>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Selling Price</span>
                    </Col>
                    <Col md={8}>
                      ₹{props.selectedItem.itemSalesSellingPrice}
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Sales Account</span>
                    </Col>
                    <Col md={8}>{props.selectedItem.itemSalesAccountName}</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Sales Description</span>
                    </Col>
                    <Col md={8}>{props.selectedItem.itemsSalesDescription}</Col>
                  </Row>
                </div>
              </AvForm>
            </CardBody>
          </Card>
          <Card className="extra-item">
            <CardBody>
              <div className="mb-4">
                <h6>Opening Stock</h6>
                <strong>{props.selectedItem.itemOpeningStock}</strong>
              </div>
              <div className="mb-4">
                <h6>Stock on Hand</h6>
                <strong>
                  {props.selectedItem.stockInHand == null
                    ? 0
                    : props.selectedItem.stockInHand}
                </strong>
              </div>
              <div className="mb-4">
                <h6>Committed Stock</h6>
                <strong>{props.selectedItem.stockCommitted}</strong>
              </div>
              <div className="mb-4">
                <h6>Available for Sale</h6>
                <strong>{props.selectedItem.stockAvailableForSale}</strong>
              </div>
              {/* <div className="row">
                <div className="col-md-6">
                  <Card>
                    <CardBody className="item-card">
                      <div className="d-flex">
                        <h4 className="mr-2">
                          {props.selectedItem.quantityToBeInvoiced}
                        </h4>
                        Qty
                      </div>
                      To be Invoiced
                    </CardBody>
                  </Card>
                </div>
                <div className="col-md-6">
                  <Card>
                    <CardBody className="item-card">
                      <div className="d-flex">
                        <h4 className="mr-2">
                          {props.selectedItem.quantityToBeBilled}
                        </h4>
                        Qty
                      </div>
                      To be Billed
                    </CardBody>
                  </Card>
                </div>
              </div> */}
              <hr />
              <div>
                <h6>Reorder Point</h6>
                <div>
                  <strong className="mr-3">
                    {props.selectedItem.itemReorderPoint}
                  </strong>
                  <i
                    class="bx bxs-pencil"
                    id="reorderPopoverbottom"
                    onClick={() => {
                      setreorderpopoverbottom(!reorderpopoverbottom);
                    }}
                  ></i>
                  <Popover
                    placement="bottom"
                    isOpen={reorderpopoverbottom}
                    target="reorderPopoverbottom"
                    toggle={() => {
                      setreorderpopoverbottom(!reorderpopoverbottom);
                    }}
                    className="billing"
                  >
                    <PopoverHeader>
                      Reorder Point
                      <button
                        type="button"
                        class="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setreorderpopoverbottom(false);
                        }}
                      ></button>
                    </PopoverHeader>
                    <PopoverBody className="popover-reorder">
                      <label>Set Reorder point</label>
                      <input
                        type="text"
                        className="mb-4"
                        value={reorderPoint}
                        onChange={(e) => setReorderPoint(e.target.value)}
                      />
                      <button
                        type="button"
                        class="btn btn-primary w-xs"
                        onClick={handleUpdateRP}
                      >
                        Update
                      </button>
                    </PopoverBody>
                  </Popover>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  console.log("this page", state);

  const { selectedItemId, selectedItem } = state.ItemDetails;
  return {
    selectedItemId,
    selectedItem,
  };
};
export default connect(mapStatetoProps, {
  getCurrentSelectedItem,
})(SingleItem);
