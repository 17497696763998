import { Container, Row, Col, Modal } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { BaseUrl } from "../../../components/config/BaseUrl";
import { apiGet, apiPost, apiPut } from "../../../components/config/apiConfig";
import React, { useState, useEffect } from "react";

function EditTds(props) {
  const [taxSectionList, setTaxSectionList] = useState([]);

  const [data, setData] = useState({
    taxName: "",
    taxRate: 0,
    taxSection: "",
    isHigherRateTax: 0,
    reasonForHigherRate: "",
  });

  function getTaxSection() {
    //const organisationId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tax_section`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setTaxSectionList(resp.response.data.data);
    });
  }

  useEffect(() => {
    getTaxSection();
  }, []);

  return (
    <Modal className="export-modal" isOpen={props.openModal}>
      <Row>
        <Col md={12} className="modal-head">
          <div>
            <h5>Add TDS</h5>
          </div>
          <div onClick={() => props.setOpenModel(false)}>
            <i class="bx bx-x"></i>
          </div>
        </Col>
      </Row>
      <hr />
      <AvForm className="form" onValidSubmit={() => props.handleEdit(props.id)}>
        <Row className="d-flex align-items-center mb-3">
          <Col md={6}>
            <label style={{ whiteSpace: "pre" }}>TDS Tax</label>
          </Col>
          <Col md={6}>
            <AvField
              name="taxName"
              type="text"
              placeholder="Enter TDS Tax"
              onChange={(e) => props.handle(e)}
              value={props.editValues.taxName}
              required
            ></AvField>
          </Col>
        </Row>
        <Row className="d-flex align-items-center mb-3">
          <Col md={6}>
            <label style={{ whiteSpace: "pre" }}>Rate (%)</label>
          </Col>
          <Col md={6}>
            <AvField
              name="taxRate"
              placeholder="Enter Rate(%)"
              type="text"
              onChange={(e) => props.handle(e)}
              value={props.editValues.taxRate}
              required
            ></AvField>
          </Col>
        </Row>
        <Row className="d-flex align-items-center mb-3">
          <Col md={6}>
            <label style={{ whiteSpace: "pre" }}>Nature of Collection</label>
          </Col>
          <Col md={6}>
            <AvField
              type="select"
              name="taxSection"
              value={props.editValues.taxSection}
              onChange={(e) => props.handle(e)}
            >
              <option value="" disabled selected>
                Select Tax Type
              </option>
              {taxSectionList.map((tx) => (
                <option value={tx.taxSection}>{tx.taxSection}</option>
              ))}
            </AvField>
          </Col>
        </Row>
        <Row className="d-flex align-items-center mb-3">
          <Col md={6}>
            <label style={{ whiteSpace: "pre" }}>
              This is a higher TDS rate
            </label>
          </Col>
          <Col md={6}>
            <AvField
              type="checkbox"
              name="isHigherRateTax"
              // disabled={props.value.itemType == "1"}
              label="This is a higher TDS rate"
              onChange={(e) => props.handleTrackIsHigherRateTax(e)}
              value={props.trackIsHigherRateTax}
              checked={props.trackIsHigherRateTax}
            />
          </Col>
        </Row>
        {props.trackIsHigherRateTax == true ? (
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>
                Reason for higher rate
              </label>
            </Col>
            <Col md={6}>
              <AvField
                name="reasonForHigherRate"
                placeholder="Enter Reason For Higher Rate"
                type="text"
                onChange={(e) => props.handle(e)}
                value={props.editValues.reasonForHigherRate}
                required
              ></AvField>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row>
          <Col md={12}>
            <button
              type="submit"
              class="exportuserbtn"
              disabled={props.isSaving}
            >
              Edit TDS
            </button>
          </Col>
        </Row>
      </AvForm>
    </Modal>
  );
}

export default EditTds;
