import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { connect } from "react-redux";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ExportModal from "./exportModal";
import { Link } from "react-router-dom";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";

const ExpenseSingleView = (props) => {
  console.log("props", props);
  var id = props.match.params.id;
  const [socialDrp, setsocialDrp] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reorderpopoverbottom, setreorderpopoverbottom] = useState(false);
  const [expdata, setExpdata] = useState({});

  function getExpenseDetails() {
    const Url =
      BaseUrl.apiUrl.baseUrl + "api/v1/expenses/" + props.match.params.id;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setExpdata(resp.response.data.data);
    });
  }

  useEffect(() => {
    getExpenseDetails();
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12} className="item-head">
              <div>
                <h5>
                  {expdata.expenseNumber} / {expdata.expenseDate}
                </h5>
              </div>
              {/*<div className="bbb">
                <Link to={`/addexpenses/edit/${id}`}>
                  <button>
                    <i class="bx bx-pencil"></i>
                  </button>
                </Link>
                <Dropdown
                  className="d-inline-block"
                  isOpen={socialDrp}
                  toggle={() => {
                    setsocialDrp(!socialDrp);
                  }}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect ddtoggle"
                    tag="button"
                  >
                    <button>
                      {" "}
                      {/* <i class="bx bx-menu"></i> */}
              {/*   More
                    </button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem tag="a" href="">
                      {" "}
                      {"Clone Item"}{" "}
                    </DropdownItem>
                    <hr />
                    <DropdownItem tag="a" href="">
                      {" "}
                      {"Mark as Inactive"}{" "}
                    </DropdownItem>
                    <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                      {" "}
                      {"Delete"}{" "}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Modal className="export-modal" isOpen={modalIsOpen}>
                  <ExportModal isClose={() => setModalIsOpen(false)} id={id} />
                </Modal>
              </div>*/}
            </Col>
          </Row>

          <Card>
            <CardBody>
              <AvForm className="form itemview">
                <div>
                  <Row>
                    <Col md={4}>
                      <span className="head">Expense#</span>
                    </Col>
                    <Col md={8}>{expdata.expenseNumber}</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Expense Date</span>
                    </Col>
                    <Col md={8}>{expdata.expenseDate}</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Expense Account</span>
                    </Col>
                    <Col md={8}>{expdata.expenseAccountGroup}</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Expense Type</span>
                    </Col>
                    <Col md={8}>
                      {expdata.expenseType == 1 ? "Goods" : "Service"}
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">HSN Code</span>
                    </Col>
                    <Col md={8}>{expdata.expenseHsn}</Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Sac Code</span>
                    </Col>
                    <Col md={8}>{expdata.expenseSac}</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={12}>
                      <span className="itempur-info"></span>
                    </Col>
                  </Row>
                  <hr />
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Expense Amount</span>
                    </Col>
                    <Col md={8}>{expdata.expenseAmount}</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Paid Through</span>
                    </Col>
                    <Col md={8}>{expdata.expensePaidThroughAccount}</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={12}>
                      <span className="itempur-info"></span>
                    </Col>
                  </Row>
                  <hr />
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Vendor</span>
                    </Col>
                    <Col md={8}>{expdata.expenseVendorName}</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">GST Treatment</span>
                    </Col>
                    <Col md={8}>{expdata.expenseGstTreatment}</Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Source of Supply</span>
                    </Col>
                    <Col md={8}>{expdata.expenseSourceOfSupply}</Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Destination of Supply</span>
                    </Col>
                    <Col md={8}>{expdata.expenseDestinationOfSupply}</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={12}>
                      <span className="itempur-info"></span>
                    </Col>
                  </Row>
                  <hr />
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Tax</span>
                    </Col>
                    <Col md={8}>{expdata.expenseTaxPercent} %</Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Invoice</span>
                    </Col>
                    <Col md={8}>{expdata.expenseInvoiceNumber}</Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Notes</span>
                    </Col>
                    <Col md={8}>{expdata.expenseNotes}</Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Customer</span>
                    </Col>
                    <Col md={8}>{expdata.expenseCustomerName}</Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Reporting Tags</span>
                    </Col>
                    <Col md={8}>{expdata.expenseReportingTag}</Col>
                  </Row>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  console.log("this page", state);

  const { selectedItemId, selectedItem } = state.ItemDetails;
  return {
    selectedItemId,
    selectedItem,
  };
};
export default connect(mapStatetoProps, {
  //getCurrentSelectedItem,
})(ExpenseSingleView);
