// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
  SAVE_CURRENT_SELECTED_ITEM,
  GET_CURRENT_SELECTED_ITEM,
} from "./actionTypes";
import { ADD_TABLE_DATA, CALCULATE_TOTALS } from "../tableData/actionTypes";
import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";

/**
 * Show the rightsidebar
 */

function* getCurrentSelectedItem({ payload: data }) {
  try {
    let savedItem = {};
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/${data}`;
    let resp = apiGet(Url);
    yield resp.then((resp) => {
      savedItem = resp.response.data.data;
    });

    yield put({
      type: "SAVE_CURRENT_SELECTED_ITEM",
      payload: savedItem,
    });
    yield put({
      type: "ADD_TABLE_DATA",
      payload: savedItem,
    });
    yield put({
      type: CALCULATE_TOTALS,
    });
  } catch (error) {}
}

/**
 * Watchers
 */

export function* watchgetCurrentSelectedItem() {
  yield takeEvery(GET_CURRENT_SELECTED_ITEM, getCurrentSelectedItem);
}
function* ItemSaga() {
  yield all([fork(watchgetCurrentSelectedItem)]);
}

export default ItemSaga;
