import React, { useMemo, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";
import Axios from "axios";
// import Table from "./Table";
import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import dyarco from "../../assets/dyarco/dyarco.jpeg";
import FilterComponent from "./FilterComponent";
function CustomerCheckInList() {
  const customerId = localStorage.getItem("customerId");
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [printdata, setprintdata] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }

  function toPages(pages) {
    const results = [];

    for (let i = 1; i < pages; i++) {
      results.push(i);
    }

    return results;
  }
  // const printPDF = (data) => {
  //   const Url = BaseUrl.apiUrl.baseUrl + `api/v1/cargo_checkin/${data}`;
  //   let resp = apiGet(Url);
  //   resp.then((resp) => {
  //     setprintdata(resp.response.data.data);
  //     handlePrint();
  //   });
  // };
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   bodyClass: "print-btn",
  //   pageStyle: "margin:50px",
  // });
  const columns = [
    {
      name: "Check-In Number",
      selector: (data) => data.checkInNumber,
      sortable: true,
    },
    {
      name: "Warehouse Name",
      selector: (data) => data.checkInWarehouseName,
      sortable: true,
    },

    {
      name: "Customer Name",
      selector: (data) => data.checkInCustomerName,
      sortable: true,
      // right: true,
    },
    {
      name: "Quotation Number",
      selector: (data) => data.checkInQuotationNumber,
      sortable: true,
      // right: true,
    },

    {
      name: "Rate / CBM",
      selector: (data) => data.checkInRatePerCbm,
      sortable: true,
      // right: true,
    },
    {
      name: "Estimated Commodity Value",
      selector: (data) => data.checkInEstimatedCommodityValue,
      sortable: true,
      // right: true,
    },

    {
      button: true,
      cell: (data) => (
        <div className="tablebtn">
          <Link to={`/customer-checkin-view/${data.checkInId}`}>
            <button>View</button>
          </Link>
        </div>
      ),
    },
  ];
  const getData = async (page) => {
    setLoading(true);
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/cargo_checkin/org/cus?org_id=${organisationId}&page=${page}&size=${perPage}&sort=checkInId,desc&searchText=${filterText}&customer_id=${customerId}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setTotalRows(resp.response.data.data.totalElements);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    getData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/cargo_checkin/org/cus?org_id=${organisationId}&page=${page}&size=${newPerPage}&sort=checkInId,desc&searchText=${filterText}&customer_id=${customerId}`;

    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setPerPage(newPerPage);
      setLoading(false);
    });
  };
  useEffect(() => {
    getData(1);
  }, [filterText]);
  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  return (
    <div className="App">
      <div className="card">
        {/* {loadingData ? (
          <div className="tableLoading">
            <p>Loading Please wait...</p>
          </div>
        ) : ( */}
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows
          subHeader
          subHeaderComponent={subHeaderComponent}
          subHeaderAlign="left"
        />
        {/* )} */}
      </div>
    </div>
  );
}
export default CustomerCheckInList;
