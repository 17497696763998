import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Formik, Field } from "formik";
//i18n
import { withTranslation } from "react-i18next";
import OverviewChart from "./overviewChart";
import { apiGet } from "../../../components/config/apiConfig";
import { BaseUrl } from "../../../components/config/BaseUrl";
import VendorOverviewChart from "./overviewChart";

const VendorOverview = (props) => {
  const [vendorData, setVendorData] = useState({});
  useEffect(() => {
    setVendorData(props.data);
    console.log("props vendors", props.data);
  }, [props]);
  return (
    <React.Fragment>
      <Row>
        <Col md={4} className="formLeft ">
          <div className="overviewLeft">
            <div className="custname">
              <span>{vendorData.vendorCompanyName}</span>
            </div>
            <hr />
            <Row>
              <Col md={3}>
                <div className="letterImage">
                  <span className="text-uppercase">
                    {vendorData.vendorCompanyName
                      ? vendorData.vendorCompanyName.charAt(0)
                      : "N"}
                  </span>
                </div>
              </Col>
              <Col md={9}>
                <div className="name">{vendorData.vendorDisplayName}</div>
                <div className="name">{vendorData.vendorEmail}</div>
                <div className="name">{vendorData.vendorMobile}</div>
                <div className="name">{vendorData.vendorNumber}</div>
                {/* <div className="profiledit">
                  <small>Edit </small>
                  <small>Delete</small>
                  <small>Disable SMS Notifications</small>
                </div> */}
              </Col>
            </Row>
            <div className="head">
              <span>ADDRESS</span>
              <i class="bx bx-chevron-down "></i>
            </div>
            <hr />
            {vendorData?.vendorAddresses?.map((address) => {
              return (
                <div className="address">
                  <span className="bold">
                    {address.addressType == "1"
                      ? "Billing Address"
                      : "Shipping Address"}
                  </span>

                  <div className="addaddress">
                    <span>{address.addressAttention}</span>
                    <span>{address.addressLine1}</span>
                    <span>{address.addressLine2}</span>
                    <span>{address.addressPhone}</span>
                    <span>
                      {address.addressCity} {address.addressState}
                    </span>
                    <span> {address.addressCountryName}</span>
                    <span>{address.addressZipCode}</span>

                    <br />
                  </div>
                </div>
              );
            })}
            <div className="head">
              <span>OTHER DETAILS</span>
              <i class="bx bx-chevron-down "></i>
            </div>
            <hr />
            <div className="otherdetails">
              <div className="jsc">
                <span>Vendors Number</span>
                <span>{vendorData.vendorNumber}</span>
              </div>
              <div className="jsc">
                <span>Currency</span>
                <div className="align-center">
                  <span>{vendorData.vendorCurrency}</span>
                  <i className="bx bx-pencil ml-2"></i>
                </div>
              </div>
              <div className="jsc">
                <span>Terms</span>
                <span>{vendorData.vendorAdvNotes}</span>
              </div>
              {/* <div className="jsc">
                <span>Portal Status</span>
                <div className="align-center">
                  <span>Disabled</span>
                  <i className="bx bx-pencil ml-2"></i>
                </div>
              </div>
              <div className="jsc">
                <span>Portal Language</span>
                <div className="align-center">
                  <span>English</span>
                  <i className="bx bx-pencil ml-2"></i>
                </div>
              </div> */}
              <div className="jsc">
                <span>GST Treatement </span>
                <div className="align-center">
                  <span>{vendorData.vendorGstTreatment}</span>
                  <i className="bx bx-pencil ml-2"></i>
                </div>
              </div>
              <div className="jsc">
                <span>Place Of Supply</span>
                <div className="align-center">
                  <span>{vendorData.vendorPlaceOfSupply}</span>
                  <i className="bx bx-pencil ml-2"></i>
                </div>
              </div>
              <div className="jsc">
                {/* <span>Tax Preference</span> */}
                <div className="align-center">
                  {/* <span>{vendorData.customerTaxPreference}</span> */}
                  <i className="bx bx-pencil ml-2"></i>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={8} className="formRight">
          <Row>
            <div className="custname">
              <span>
                You can request your contact to directly update the GSTIN by
                sending an email. {/*<a href="#">Send mail</a>*/}
              </span>
              <hr />
              <div>
                <ul className="overviewTop">
                  <li className="overviewTopli">
                    <h6>Outstanding Recievables</h6>
                    <span>₹{vendorData.vendorOpeningBalance}</span>
                  </li>
                  <li className="overviewTopliright">
                    <div className="overviewToplirightdivUC">
                      <span className="overviewToplirightdivspan1">
                        Unused Credit
                      </span>
                      <span className="overviewToplirightdivspan2">$0.00</span>
                    </div>
                    <div className="overviewToplirightdivUC">
                      <span className="overviewToplirightdivspan1">
                        Payment due period
                      </span>
                      <span className="overviewToplirightdivspan2pdp">
                        Due on Receipt
                      </span>
                    </div>
                  </li>
                </ul>
                <div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; view opening balance
                </div>
              </div>
            </div>
          </Row>
          {/* <hr /> */}
          <Row className="ovRightTable">
            <div className="ovRightTablediv">
              <div className="ovTableTopbar">
                <span className="ovTableTopbarSpan">Income</span>
                <div className="ovTableIncomeRightside">
                  <div className="Dropdown">
                    <span className="dropdownToggle">"Accrual"</span>
                    <div></div>
                  </div>
                  <div></div>
                </div>
              </div>
              <hr />
              <div>
                <VendorOverviewChart />
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default VendorOverview;
