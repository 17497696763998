import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Modal, Row } from "reactstrap";
import { apiGet, apiPut } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import UserPasswordChange from "./UserPasswordChange";

function UserEdit(props) {
  const [passwordModel, setpasswordModel] = useState(false);
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    mobile: "",
    organizationId: "",
  });
  const updateUser = (e) => {
    e.persist();
    let orgId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + "api/v1/user_profile/" + props.userId;
    var body = {
      firstname: values.firstname,
      lastname: values.lastname,
      mobile: values.mobile,
    };
    let resp = apiPut(Url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success("Edited User Successfully");
          props.setapproveModel(false);
          props.refreshUserList();
          break;

        default:
          toast.error("Failed to update user");
          props.setapproveModel(false);
      }
    });
  };
  function handle(e) {
    const newData = { ...values };
    newData[e.target.name] = e.target.value;
    setValues(newData);
  }
  function getUserDataById() {
    if (props.userId !== "") {
      const Url =
        BaseUrl.apiUrl.baseUrl + `api/v1/user_profile/` + props.userId;
      let resp = apiGet(Url);
      resp.then((resp) => {
        var datas = resp.response.data.data;
        setValues(datas);
      });
    }
  }
  useEffect(() => {
    getUserDataById();
  }, [props.userId]);
  return (
    <div>
      <Modal className="export-modal" isOpen={props.approveModel}>
        <Row>
          <Col md={12} className="modal-head">
            <div>
              <h5>Edit User</h5>
            </div>
            <div onClick={() => props.setapproveModel(false)}>
              <i class="bx bx-x"></i>
            </div>
          </Col>
        </Row>
        <hr />
        <AvForm className="form" onValidSubmit={(e) => updateUser(e)}>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>First Name</label>
            </Col>
            <Col md={6}>
              <AvField
                name="firstname"
                type="text"
                placeholder="Enter User Firstname"
                onChange={(e) => handle(e)}
                value={values.firstname}
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Last Name</label>
            </Col>
            <Col md={6}>
              <AvField
                name="lastname"
                placeholder="Enter User Lastname"
                type="text"
                onChange={(e) => handle(e)}
                value={values.lastname}
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Edit Contact Number</label>
            </Col>
            <Col md={6}>
              <AvField
                name="mobile"
                type="number"
                placeholder="Enter User Phone"
                onChange={(e) => handle(e)}
                value={values.mobile}
                required
              ></AvField>
              <span
                className="pswchange"
                onClick={() => setpasswordModel(true)}
              >
                Change Password
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <button type="submit" class="exportuserbtn">
                Update User
              </button>
            </Col>
          </Row>
        </AvForm>
      </Modal>
      <UserPasswordChange
        setpasswordModel={setpasswordModel}
        passwordModel={passwordModel}
        userId={props.userId}
        values={values}
      />
    </div>
  );
}

export default UserEdit;
