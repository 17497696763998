import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import { Formik, Field, ErrorMessage } from "formik";
import { Link, useHistory } from "react-router-dom";
import DeliveryChallanTable from "./deliveryChallanTable";
import TermsAndConditions from "./termsAndConditions";
import { BaseUrl } from "../../components/config/BaseUrl";
import loader from "../../assets/loader/load.gif";
import Select from "react-select";

import { apiGet, apiPost } from "../../components/config/apiConfig";
// import {
//   ToastContainer,
//   loader,
//   toast,
// } from "react-toastify";

import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosAddCircle } from "react-icons/io";

//i18n

const AddDeliveryChallanForm = (props) => {
  const [itemRate, setItemRate] = useState("");
  const [subTotalAmount, setSubTotalAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [shipChrg, setShipChrg] = useState(0);
  const [rowsData, setRowsData] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [adjus, setAdjus] = useState(0);
  const [reload, setReload] = useState(false);
  const [challanTypeData, setChallanTypeData] = useState([]);
  const [tcsList, setTcsData] = useState([]);
  const [tcsRate, setTcsRate] = useState(null);
  const [tcsAmount, setTcsAmount] = useState(0);
  const [tcsTax, setTcsTax] = useState(null);

  //to calculate tcs amount
  function changeStatus(e) {
    //setTcs(e.target.value);
    //console.log("tcs:", tcs);
    var index = e.nativeEvent.target.selectedIndex;
    var id = e.nativeEvent.target[index].value;
    console.log("selected text0", e.nativeEvent.target[index].value);
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tcs/${id}`;
    let resp = apiGet(Url);
    var taxrate = 0;
    resp.then((resp) => {
      console.log("tcs data", resp.response.data.data);

      taxrate = resp.response.data.data.taxRate;
      setTcsRate(taxrate);
      setTcsTax(resp.response.data.data.taxName);
      const rowsInput = [...rowsData];

      const sum = rowsInput
        .map((item) => parseInt(item.itemAmount))
        .reduce((prev, curr) => prev + curr, 0);
      setSubTotalAmount(sum);
      const tax = (sum * (taxrate || 0)) / 100;
      setTcsAmount(tax.toFixed(2));
      setTotalAmount(
        parseInt(subTotalAmount ? subTotalAmount : 0) +
          parseInt(shipChrg ? shipChrg : 0) +
          parseInt(adjus ? adjus : 0) +
          parseFloat(tax ? tax.toFixed(2) : 0)
      );
    });
  }

  // to get customer list
  const [data, setData] = useState([]);
  useEffect(() => {
    function getData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setData(resp.response.data.data);
      });
    }
    getData();
  }, []);
  const custOptions = data.map((cust) => ({
    value: cust.customerId,
    label: cust.customerDisplayName,
  }));
  const CustomButton = ({ onClick }) => (
    <Link to="/customer/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Customer
      </button>
    </Link>
  );

  const DropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomButton onClick={console.log("clicked")} />
      </div>
    );
  };

  const customComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: DropdownMenu, // Use your custom dropdown menu
  };
  // const customAddCustomerOption = {
  //   label: (
  //     <Link to="/customer/add">
  //       <span style={{ color: "#408dfb" }}>
  //         <span style={{ marginRight: "8px", color: "#408dfb" }}>
  //           <IoIosAddCircle color="#408dfb" />
  //         </span>{" "}
  //         Add Customer
  //       </span>
  //     </Link>
  //   ),
  //   value: "add-customer",
  //   onClick: () => {
  //     console.log("Add Customer Clicked");
  //     history.push("/customer/add");
  //   },
  //   isFixed: true,
  // };

  // const options = [...custOptions, customAddCustomerOption];
  //to get item list
  useEffect(() => {
    function getItemData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setItemData(resp.response.data.data);
      });
    }
    getItemData();
  }, []);

  useEffect(() => {
    function getChallanTypeData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/challan_type`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setChallanTypeData(resp.response.data.data);
      });
    }
    getChallanTypeData();
  }, []);

  useEffect(() => {
    function getTcsData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tcs`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setTcsData(resp.response.data.data);
      });
    }
    getTcsData();
  }, []);

  const addTableRows = () => {
    const rowsInput = {
      itemId: 0,
      itemQuantity: "",
      itemRate: "",
      itemDiscountType: 0,
      itemDiscountValue: "",
      itemTaxId: 0,
      itemAmount: 0,
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    const sum = rows
      .map((item) => parseInt(item.itemAmount))
      .reduce((prev, curr) => prev + curr, 0);
    setSubTotalAmount(sum);
    const tax = (sum * (tcsRate || 0)) / 100;
    setTcsAmount(tax.toFixed(2));
    setTotalAmount(
      parseInt(subTotalAmount) +
        parseInt(shipChrg) +
        parseInt(adjus) +
        parseFloat(tax.toFixed(2))
    );
  };

  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    if (rowsInput[index]["itemDiscountType"] == 0) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        ((rowsInput[index]["itemAmount"] || 0) *
          (rowsInput[index]["itemDiscountValue"] || 0)) /
          100;
    }
    if (rowsInput[index]["itemDiscountType"] == 1) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        (rowsInput[index]["itemDiscountValue"] || 0);
    }

    setRowsData(rowsInput);
    const sum = rowsInput
      .map((item) => parseInt(item.itemAmount))
      .reduce((prev, curr) => prev + curr, 0);
    setSubTotalAmount(sum);
    const tax = (sum * (tcsRate || 0)) / 100;
    setTcsAmount(tax.toFixed(2));
    setTotalAmount(
      parseInt(subTotalAmount ? subTotalAmount : 0) +
        parseInt(shipChrg ? shipChrg : 0) +
        parseInt(adjus ? adjus : 0) +
        parseFloat(tcsAmount ? tcsAmount : 0)
    );
    if (name === "itemId") {
      const selectedItem = itemData.find(
        (item) => item.itemId === parseInt(value)
      );
      if (selectedItem) {
        rowsInput[index]["itemRate"] = selectedItem.itemSalesSellingPrice;
      }
    }
  };
  const onHandleBlur = (index, e) => {
    const rowsInput = [...rowsData];
    if (rowsInput[index]["itemDiscountType"] == 0) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        ((rowsInput[index]["itemAmount"] || 0) *
          (rowsInput[index]["itemDiscountValue"] || 0)) /
          100;
    }
    if (rowsInput[index]["itemDiscountType"] == 1) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        (rowsInput[index]["itemDiscountValue"] || 0);
    }
    setRowsData(rowsInput);
    setTotalAmount(
      parseInt(subTotalAmount) +
        parseInt(shipChrg) +
        parseInt(adjus) +
        parseFloat(tcsAmount ? tcsAmount : 0)
    );
  };
  const history = useHistory();

  const handleCancel = () => {
    setTimeout(() => {
      history.push("/deliverychallan");
    }, 100);
  };

  // item custom button
  const CustomItemButton = ({ onClick }) => (
    <Link to="/additems/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Item
      </button>
    </Link>
  );
  const ItemDropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomItemButton onClick={console.log("clicked")} />
      </div>
    );
  };
  const customItemComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: ItemDropdownMenu, // Use your custom dropdown menu
  };
  const reactSelectOptions = itemData.map((items) => ({
    value: items.itemId,
    label: items.itemName,
  }));
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Formik
        initialValues={{
          mode: "1",
          customername: "",
          branch: "",
          deliverychallan: "",
          reference: "",
          dcdate: "",
          ctype: "",
          shippingCharge: 0,
          adjustment: 0,
          termsandconditions: "",
          import: "",
          customernotes: "",
          tcsTaxId: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.customerId) {
            errors.customerId = "Required";
          }

          if (!values.dcdate) {
            errors.dcdate = "Required";
          }
          if (!values.ctype) {
            errors.ctype = "Required";
          }
          return errors;
        }}
        onSubmit={(values) => {
          let orgId = localStorage.getItem("organisationId");
          let url = BaseUrl.apiUrl.baseUrl + "api/v1/delivery_challan";
          let body = {
            deliveryChallanCustomerId: values.customerId,
            deliveryChallanShippingAddressId: 200,
            deliveryChallanGstTreatmentId: 300,
            deliveryChallanPlaceOfSupplyId: 0,
            deliveryChallanReferenceNumber: values.reference,
            deliveryChallanDate: values.dcdate,
            deliveryChallanType: values.ctype,
            deliveryChallanPrieListId: 90,
            deliveryChallanSubTotal: subTotalAmount,
            deliveryChallanAdjustment: values.adjustment,
            deliveryChallanRoundOff: 4000,
            deliveryChallanTotal: totalAmount,
            deliveryChallanCustomerNotes: values.customernotes,
            deliveryChallanTermsAndConditions: values.termsandconditions,
            deliveryChallanCustomerName: values.customername,
            deliveryChallanCustomerShippingAddress: values.shippingCharge,
            deliveryChallanCustomerContactNumber: "",

            deliveryChallanTcsTaxId: values.tcsTaxId,
            deliveryChallanTcsTaxPercent: tcsRate,
            deliveryChallanTcsTaxAmount: tcsAmount,
            deliveryChallanTcsTax: tcsTax,

            organizationId: 1,
            branchId: values.branch,
            addedByUser: 1,
            editByUser: 1,
            deliveryChallanItemsList: rowsData,
          };
          let resp = apiPost(url, body);
          resp.then((resp) => {
            setReload(false);
            switch (resp.response.data.code) {
              case 200:
                toast.success(resp.response.data.data);
                setTimeout(() => {
                  history.push("/deliverychallan");
                }, 1000);
                break;
              default:
                toast.error(resp.response.data.data);
            }
          });
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="formik" id="deliverychallan">
            <Row>
              <Col md={2}>
                <label className="formik-label"> Customer Name</label>
              </Col>
              <Col md={3} className="">
                {/* <Field
                  className="formik-input"
                  as="select"
                  name="customerId"
                  onChange={handleChange}
                  value={values.customerId}
                >
                  <option value="" selected disabled>
                    Select Customer
                  </option>
                  {data.map((cust) => {
                    return (
                      <option value={cust.customerId}>
                        {cust.customerDisplayName}
                      </option>
                    );
                  })}

                </Field>
                {/*<div>
                  <i className="bx bx-search"></i>
                </div>*/}
                {/* <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="customerId"
                /> */}
                <Select
                  options={custOptions}
                  name="customerId"
                  components={customComponents}
                  value={
                    custOptions
                      ? custOptions.find(
                          (option) => option.value === values.customerId
                        )
                      : ""
                  }
                  onChange={(option) => {
                    setFieldValue("customerId", option.value);
                    if (option.value == "add-customer") {
                      console.log(true);
                      history.push("/customer/add");
                      // setPushToCustomer(true);
                    }
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Branch</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="branch"
                  onChange={handleChange}
                  value={values.branch}
                >
                  <option value="" selected disabled>
                    Select a Branch
                  </option>
                  <option value="0">Head Office</option>
                </Field>
                {/*<div>
                  <span>Source of supply: Arunachal Pradesh</span>
              </div>*/}
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <label className="formik-label"> Reference#</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="text"
                  onChange={handleChange}
                  name="reference"
                  value={values.reference}
                ></Field>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Delivery Challan Date#</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="date"
                  name="dcdate"
                  onChange={handleChange}
                  value={values.dcdate}
                ></Field>
                <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="dcdate"
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={2}>
                <label className="formik-label"> Challan Type</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="ctype"
                  value={values.ctype}
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Choose a proper challan type
                  </option>
                  {challanTypeData.map((ct) => (
                    <option value={ct.id}>{ct.challanType}</option>
                  ))}
                </Field>
                <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="ctype"
                />
              </Col>
            </Row>
            <hr />
            {/* <DeliveryChallanTable /> */}
            <div className="row clearfix">
              <div className="col-md-12 column">
                <table
                  className="table table-bordered invoice-table"
                  id="tab_logic"
                >
                  <thead>
                    <tr>
                      <th className="text-center"> # </th>
                      <th className="text-left"> ITEM DETAILS</th>
                      <th className="text-right"> QUANTITY</th>
                      <th className="text-right"> RATE</th>
                      <th className="text-right"> DISCOUNT </th>
                      <th className="text-right"> TAX</th>
                      <th className="text-right"> AMOUNT</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {rowsData.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {/* <select
                              name="itemId"
                              value={data.itemId}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            >
                              <option selected disabled>
                                Select Item
                              </option>
                              {itemData.map((items, index) => (
                                <option value={items.itemId}>
                                  {items.itemName}
                                </option>
                              ))}
                            </select> */}
                            <Select
                              required
                              components={customItemComponents}
                              name="invItemId"
                              onChange={(selectedOption) => {
                                // Extract the selected item's ID from the selectedOption
                                const selectedItemId = selectedOption.value;
                                const rowsInput = [...rowsData];
                                rowsInput[index]["itemId"] = selectedItemId;
                                setRowsData(rowsInput);
                              }}
                              value={reactSelectOptions.find(
                                (option) =>
                                  option.value ==
                                  (rowsData[index]
                                    ? rowsData[index]["itemId"]
                                    : "")
                              )}
                              options={reactSelectOptions}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              name="itemQuantity"
                              className="form-control"
                              placeholder="1.00"
                              value={data.itemQuantity}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                              onBlur={(e) => onHandleBlur(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              name="itemRate"
                              className="form-control"
                              placeholder="0.00"
                              value={data.itemRate}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                              onBlur={(e) => onHandleBlur(index, e)}
                            />
                          </td>
                          <td width="10%">
                            <div className="table-inputs">
                              <div>
                                {" "}
                                <input
                                  type="number"
                                  name="itemDiscountValue"
                                  className="form-control"
                                  placeholder="0"
                                  value={data.itemDiscountValue}
                                  onChange={(evnt) =>
                                    handleTableChange(index, evnt)
                                  }
                                />
                              </div>
                              <div>
                                <select
                                  name="itemDiscountType"
                                  id="perc"
                                  value={data.itemDiscountType}
                                  onChange={(evnt) =>
                                    handleTableChange(index, evnt)
                                  }
                                >
                                  <option value="0">%</option>
                                  <option value="1">Rs.</option>
                                </select>
                              </div>
                            </div>
                          </td>
                          <td>
                            <select
                              name="itemTaxId"
                              id="tax"
                              disabled
                              value={data.itemTaxId}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            >
                              <option value="%" disabled selected>
                                Select a tax
                              </option>
                            </select>
                          </td>
                          <td width="15%">
                            <input
                              type="number"
                              name="itemAmount"
                              className="form-control"
                              value={data.itemAmount}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            />
                          </td>
                          <td className="text-center">
                            <span
                              className="btn btn-outline-danger"
                              onClick={(e) => deleteTableRows(index, e)}
                            >
                              x
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Row>
                  <Col md={6} className="table-left">
                    <div>
                      <div
                        onClick={addTableRows}
                        className=" col-md-4 btn btn-default pull-left add-tablerow"
                      >
                        <i class="bx bxs-plus-circle"></i>
                        <span>Add Item</span>
                      </div>
                      {/* <button
                      onClick={this.handleRemoveRow}
                      className="pull-right btn btn-default table-btn"
                    >
                      Clear all rows
                    </button> */}
                    </div>
                    <div className="customernotes">
                      <span>Customer Notes</span>
                      <div>
                        <textarea
                          name="customernotes"
                          rows="4"
                          cols="40"
                          placeholder="Looking forward for your business"
                          onChange={handleChange}
                          value={values.customernotes}
                        ></textarea>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="table-right">
                      <Row>
                        <Col className="details">
                          <div>
                            <span className="detail-head">Sub Total</span>
                          </div>
                          <div>
                            <span>{subTotalAmount}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <span className="detail-head">
                              Shipping Charges
                            </span>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="input-tooltip">
                            <input
                              type="number"
                              name="shippingCharge"
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                setShipChrg(values.shippingCharge);
                                let sum =
                                  parseInt(subTotalAmount) +
                                  parseInt(values.shippingCharge) +
                                  parseInt(values.adjustment) +
                                  parseFloat(tcsAmount ? tcsAmount : 0);
                                setTotalAmount(sum);
                              }}
                              value={values.shippingCharge}
                            />
                            <i class="bx bx-help-circle"></i>
                          </div>
                        </Col>
                        <Col md={3} className="text-right">
                          <div>
                            <span>{values.shippingCharge}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <span className="detail-head">Adjustment</span>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="input-tooltip">
                            <input
                              type="number"
                              name="adjustment"
                              onChange={handleChange}
                              value={values.adjustment}
                              onBlur={(e) => {
                                handleBlur(e);
                                setAdjus(values.adjustment);
                                let sum =
                                  parseInt(subTotalAmount) +
                                  parseInt(values.shippingCharge) +
                                  parseInt(values.adjustment) +
                                  parseFloat(tcsAmount ? tcsAmount : 0);
                                setTotalAmount(sum);
                              }}
                            />
                            <i class="bx bx-help-circle"></i>
                          </div>
                        </Col>
                        <Col md={3} className="text-right">
                          <div>
                            <span>{values.adjustment}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <span className="detail-head">Tcs</span>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="input-tooltip">
                            <select
                              name="tcsTaxId"
                              placeholder="select a
                              tax"
                              onChange={(e) => {
                                changeStatus(e);
                                handleChange(e);
                              }}
                              value={values.tcsTaxId}
                            >
                              <option value="" selected>
                                Select a Tax
                              </option>
                              {tcsList.map((tcs) => (
                                <option value={tcs.id} key={tcs.id}>
                                  {tcs.taxName} - {tcs.taxRate} %
                                </option>
                              ))}
                            </select>
                            <i class="bx bx-help-circle"></i>
                          </div>
                        </Col>
                        <Col md={3} className="text-right">
                          <div>
                            <span>{tcsAmount}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="details">
                          <div>
                            <span className="total">Total</span>
                          </div>

                          <div>
                            <span className="total">{totalAmount}</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <hr />
            <Row>
              <Col md={12}>
                {/* <TermsAndConditions invoice={values.addretinvoice} /> */}
                <div className="container table-bottom">
                  <div className="row clearfix">
                    <div className="col-md-8 termsandcondition">
                      <span>Terms & Conditions</span>
                      <div className="mt-2">
                        <Field
                          className="formik-textarea terms-textarea"
                          as="textarea"
                          name="termsandconditions"
                          onChange={handleChange}
                          value={values.termsandconditions}
                        ></Field>
                      </div>
                    </div>
                    <div className="col-md-4 attachfiles">
                      <span>Attach File(s) </span>
                      <div className="attach">
                        <Field
                          name="import"
                          type="file"
                          value={values.import}
                          onChange={handleChange}
                        ></Field>
                      </div>
                      <div>
                        <span style={{ fontSize: "10px" }}>
                          You can upload a maximum of 5 files, 5MB each
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
      <div className="bottom-bar">
        <Row>
          <Col md={4}>
            <Row>
              <Col md={4}>
                <button className="btn btn-left ml-3" onClick={handleCancel}>
                  Cancel
                </button>
              </Col>
              <Col md={4}>
                <button
                  type="reset"
                  form="deliverychallan"
                  className="btn btn-left"
                >
                  Clear
                </button>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="printView">
            <span className="print">
              {/* Make Recurring | Print or Preview */}
            </span>
          </Col>
          <Col md={4}>
            <button
              className="btn btn-right float-right mr-3"
              type="submit"
              form="deliverychallan"
            >
              {reload ? (
                <img className="loader" src={loader} alt="loading..." />
              ) : (
                " Save"
              )}
            </button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default AddDeliveryChallanForm;
