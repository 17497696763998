import React from "react";
import { render } from "react-dom";
import { Row, Col } from "reactstrap";
import { AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
class ValueTable extends React.Component {
  state = {
    rows: [0],
  };
  handleChange = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    rows[idx] = {
      [name]: value,
    };
    this.setState({
      rows,
    });
  };
  handleAddRow = () => {
    const item = {
      pDetails: "",
      currentval: "",
      changedval: "",
      adjustedval: "",
    };
    this.setState({
      rows: [...this.state.rows, item],
    });
  };
  handleRemoveRow = () => {
    this.setState({
      rows: this.state.rows.slice(0, 0),
    });
  };

  render() {
    return (
      <div>
        <div className="container">
          <div className="row clearfix">
            <div className="col-md-12 column">
              <table
                className="table table-bordered invoice-table"
                id="tab_logic"
              >
                <thead>
                  <tr>
                    <th className="text-center"> # </th>
                    <th className="text-left"> ITEM DETAILS</th>
                    <th className="text-right"> CURRENT VALUE</th>
                    <th className="text-right"> CHANGED VALUE</th>
                    <th className="text-right"> ADJUSTED VALUE </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.rows.map((item, idx) => (
                    <tr id="addr0" key={idx}>
                      <td>{idx}</td>
                      <td>
                        <input
                          type="text"
                          name="pdetails"
                          value={this.state.rows[idx].pDetails}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="currentval"
                          disabled
                          value={this.state.rows[idx].currentval}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="changedval"
                          value={this.state.rows[idx].changedval}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                          placeholder="0.00"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="adjustedval"
                          value={this.state.rows[idx].adjustedval}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                          placeholder="Eg. +10, -10"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Row>
                <Col md={12}>
                  <div>
                    <button
                      onClick={this.handleAddRow}
                      className="btn btn-default pull-left add-tablerow"
                    >
                      <i class="bx bxs-plus-circle"></i>
                      <span>Add another line</span>
                    </button>
                    {/* <button
                      onClick={this.handleRemoveRow}
                      className="pull-right btn btn-default table-btn"
                    >
                      Clear all rows
                    </button> */}
                  </div>
                  {/* <div className="attach">
                    <label>Attach files to invoice</label>
                    <AvField
                      name="email"
                      type="file"
                      placeholder="Enter first name"
                    ></AvField>
                  </div> */}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ValueTable;
