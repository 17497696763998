import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import UpdatePriceList from "./updatePriceList";
import { Formik, Field, ErrorMessage } from "formik";
import QuantityTable from "./quantityTable";
import ValueTable from "./valueTable";
import { apiGet, apiPost } from "../../../components/config/apiConfig";
import { BaseUrl } from "../../../components/config/BaseUrl";
// import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import loader from "../../../assets/loader/load.gif";
import { Link, useHistory } from "react-router-dom";

//i18n

const AddAdjustmentForm = (props) => {
  const history = useHistory();
  const [reload, setReload] = useState(false);
  const [itemData, setItemData] = useState("");
  const [rowsData, setRowsData] = useState([]);

  //to get item list
  useEffect(() => {
    function getItemData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        console.log("items inv", resp.response.data.data);
        setItemData(resp.response.data.data);
      });
    }
    getItemData();
  }, []);

  const addTableRows = () => {
    const rowsInput = {
      pDetails: "",
      quantity: "",
      quantitynew: "",
      quantityadj: "",
      purchaseprice: "",
      costprice: "",
      currentval: "",
      changedval: "",
      adjustedval: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };
  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    console.log("value", value);

    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
    if (name === "pdetails") {
      const selectedItem = itemData.find((item) => item.itemId === value);
      if (selectedItem) {
        console.log("selecteitem", selectedItem);
        rowsInput[index]["currentval"] = selectedItem.itemSalesSellingPrice;
      }
    }
  };
  const handleCancel = () => {
    setTimeout(() => {
      history.push("/addadjustment");
    }, 1000);
  };

  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Formik
        initialValues={{
          mode: "1",
          reference: "",
          date: "",
          account: "",
          reason: "",
          branch: "",
          description: "",
          import: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.reference) {
            errors.reference = "Required";
          }
          if (!values.date) {
            errors.date = "Required";
          }
          if (!values.account) {
            errors.account = "Required";
          }
          if (!values.reason) {
            errors.reason = "Required";
          }
          return errors;
        }}
        // onSubmit={(values, { setSubmitting }) => {
        //   setTimeout(() => {
        //     alert(JSON.stringify(values, null, 2));
        //     setSubmitting(false);
        //   }, 400);
        // }}
        onSubmit={(values) => {
          let orgId = localStorage.getItem("organisationId");
          let url =
            BaseUrl.apiUrl.baseUrl +
            "api/v1/inventory_adjustment                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ";
          let body = {
            inventoryAdjVendorId: Number,
            inventoryAdjmodeOfAdjustment: values.mode,
            inventoryAdjRefernceNo: values.reference,
            inventoryAdjDate: values.date,
            inventoryAdjAccountType: values.account,
            inventoryAdjReason: values.reason,
            inventoryAdjDescription: values.description,
            organizationId: 1,
            branchId: 0,
            addedByUser: 1,
            editByUser: 1,
            inventoryAdjItemList: [
              {
                iaItemId: 1,
                iaItemName: "",
                iaItemQuantityAvailable: Number,
                iaItemNewQuantityOnHand: rowsData.quantitynew,
                iaItemQuantityAdjustType: "",
                iaItemQuantityAdjusted: Number,
                iaItemPurchasePrice: Number,
                iaItemCostPrice: Number,
                addedByUser: 1,
                editByUser: 1,
              },
            ],
          };
          let resp = apiPost(url, body);
          resp.then((resp) => {
            setReload(false);
            switch (resp.response.data.code) {
              case 200:
                toast.success(resp.response.data.data);
                setTimeout(() => {
                  history.push("/addadjustment");
                  // useHistory().push("/customerlist");
                }, 1000);
                break;
              default:
                toast.error(resp.response.data.data);
            }
          });
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="formik" id="addadjustment">
            <Row>
              <Col md={2}>
                <label className="formik-label"> Mode of Adjustment</label>
              </Col>
              <Col md={10}>
                <div className="formik-radiodiv">
                  <Field
                    type="radio"
                    name="mode"
                    value="1"
                    checked={values.mode === "1"}
                    onChange={() => setFieldValue("mode", "1")}
                  />
                  <span>Quantity Adjustment</span>
                </div>
                <div className="formik-radiodiv">
                  <Field
                    type="radio"
                    name="mode"
                    value="2"
                    checked={values.mode === "2"}
                    onChange={() => setFieldValue("mode", "2")}
                  />
                  <span>Value Adjustment</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Reference Number</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="number"
                  name="reference"
                  onChange={handleChange}
                  value={values.reference}
                ></Field>
                <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="reference"
                />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Date</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="date"
                  name="date"
                  onChange={handleChange}
                  value={values.date}
                ></Field>

                <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="date"
                />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Account</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="account"
                  onChange={handleChange}
                  value={values.account}
                >
                  <option value="" defaultValue disabled>
                    Select an Account
                  </option>
                  <option value="1">Account</option>
                  {/* <option value="">Account </option>
                  <option value="">Account</option>
                  <option value="">Account</option>
                  <option value="">Account </option>
                  <option value="">Account</option>
                  <option value="">Account</option>
                  <option value="">Account </option> */}
                </Field>

                <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="account"
                />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Reason</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="reason"
                  onChange={handleChange}
                  value={values.reason}
                >
                  <option value="" defaultValue disabled>
                    Select a Reason
                  </option>
                  <option value="reason">reason</option>
                  {/* <option value="">reason </option>
                  <option value="">reason</option>
                  <option value="">reason</option>
                  <option value="">reason </option>
                  <option value="">reason</option>
                  <option value="">reason</option>
                  <option value="">reason </option> */}
                </Field>

                <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="reason"
                />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Branch</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="branch"
                  onChange={handleChange}
                  value={values.branch}
                >
                  <option value="" defaultValue disabled>
                    Select a Branch
                  </option>
                  <option value="branch">branch</option>
                  {/* <option value="">branch </option>
                  <option value="">branch</option>
                  <option value="">branch</option>
                  <option value="">branch </option>
                  <option value="">branch</option>
                  <option value="">branch</option>
                  <option value="">branch </option> */}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Description</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-textarea"
                  as="textarea"
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                ></Field>
              </Col>
            </Row>
            {values.mode === "1" ? (
              //  <QuantityTable />
              <div className="container">
                <div className="row clearfix">
                  <div className="col-md-12 column">
                    <table
                      className="table table-bordered invoice-table"
                      id="tab_logic"
                    >
                      <thead>
                        <tr>
                          <th className="text-center"> # </th>
                          <th className="text-left"> Item Details</th>
                          <th className="text-right"> Quantity Avaliable</th>
                          <th className="text-right"> New Quantity on Hand</th>
                          <th className="text-right"> Quantity Adjusted </th>
                          <th className="text-right"> Purchase Price</th>
                          <th className="text-right"> Cost Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rowsData.map((item, index) => (
                          <tr id="addr0" key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <select
                                name="pdetails"
                                value={item.pdetails}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                              >
                                <option value="" selected disabled>
                                  Select Item
                                </option>
                                {itemData.map((items, index) => (
                                  <option value={items.itemId}>
                                    {items.itemName}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <input
                                type="number"
                                name="quantity"
                                id="tax"
                                value={item.quantity}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="quantitynew"
                                value={item.quantitynew}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                className="form-control"
                                placeholder="1.00"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="quantityadj"
                                value={item.quantityadj}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                className="form-control"
                                placeholder="0.00"
                              />
                            </td>
                            <td width="10%">
                              <input
                                type="number"
                                name="purchaseprice"
                                value={item.purchaseprice}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                className="form-control"
                                placeholder="0"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="costprice"
                                value={item.costprice}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                              />
                            </td>
                            <td className="text-center">
                              <span
                                className="btn btn-outline-danger"
                                onClick={(e) => deleteTableRows(index, e)}
                              >
                                x
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Row>
                      <Col md={6} className="table-left">
                        <div>
                          <span
                            onClick={addTableRows}
                            className="btn btn-default pull-left add-tablerow"
                          >
                            <i class="bx bxs-plus-circle"></i>
                            <span>Add Item</span>
                          </span>
                          {/* <button
                      onClick={this.handleRemoveRow}
                      className="pull-right btn btn-default table-btn"
                    >
                      Clear all rows
                    </button> */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            ) : (
              // <ValueTable />
              <div className="container">
                <div className="row clearfix">
                  <div className="col-md-12 column">
                    <table
                      className="table table-bordered invoice-table"
                      id="tab_logic"
                    >
                      <thead>
                        <tr>
                          <th className="text-center"> # </th>
                          <th className="text-left"> Item Details</th>
                          <th className="text-right"> Current Value</th>
                          <th className="text-right"> Changed Value</th>
                          <th className="text-right"> Adjusted Value </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rowsData.map((item, index) => (
                          <tr id="addr0" key={index}>
                            <td>{index}</td>
                            <td>
                              <select
                                name="pdetails"
                                value={item.pdetails}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                              >
                                <option selected disabled>
                                  Select Item
                                </option>
                                {itemData.map((items, index) => (
                                  <option value={items.itemId}>
                                    {items.itemName}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <input
                                type="number"
                                name="currentval"
                                id="tax"
                                value={item.currentval}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="changedval"
                                value={item.changedval}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                className="form-control"
                                placeholder="1.00"
                              />
                            </td>

                            <td width="10%">
                              <input
                                type="number"
                                name="adjustedval"
                                value={item.adjustedval}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                className="form-control"
                                placeholder="0"
                              />
                            </td>

                            <td className="text-center">
                              <span
                                className="btn btn-outline-danger"
                                onClick={(e) => deleteTableRows(index, e)}
                              >
                                x
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Row>
                      <Col md={6} className="table-left">
                        <div>
                          <span
                            onClick={addTableRows}
                            className="btn btn-default pull-left add-tablerow"
                          >
                            <i class="bx bxs-plus-circle"></i>
                            <span>Add Item</span>
                          </span>
                          {/* <button
                    onClick={this.handleRemoveRow}
                    className="pull-right btn btn-default table-btn"
                  >
                    Clear all rows
                  </button> */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}
            <hr />
            <Row>
              <Col md={12}>
                <span>Attach File(s) to inventory adjustment</span>
              </Col>
              <Col md={4}>
                <div className="attach">
                  <Field
                    name="import"
                    type="file"
                    onChange={handleChange}
                    value={values.import}
                  ></Field>
                </div>
                <div>
                  <span style={{ fontSize: "10px" }}>
                    You can upload a maximum of 5 files, 5MB each
                  </span>
                </div>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
      <div className="bottom-bar">
        <Row>
          <Col md={4}>
            <Row>
              <Col md={4}>
                <button className="btn btn-left ml-3" onClick={handleCancel}>
                  Cancel
                </button>
              </Col>
              <Col md={4}>
                <button
                  type="reset"
                  className="btn btn-left"
                  form="addadjustment"
                >
                  Clear
                </button>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="printView">
            <span className="print"> Print or Preview</span>
          </Col>
          <Col md={4}>
            <button
              className="btn btn-right float-right mr-3"
              type="submit"
              form="addadjustment"
            >
              {reload ? (
                <img className="loader" src={loader} alt="loading..." />
              ) : (
                " Save"
              )}
            </button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default AddAdjustmentForm;
