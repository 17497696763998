import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";

const ExportModal = (props) => {
  return (
    <>
      <Row>
        <Col md={12} className="modal-head">
          <div>
            <h5>Export</h5>
          </div>
          <div onClick={props.isClose}>
            <i class="bx bx-x"></i>
          </div>
        </Col>
      </Row>
      <AvForm className="form">
        <Row>
          <Col md={12}>
            <p>you can export your data in CSV or XML format.</p>
          </Col>
          <Col md={6}>
            <label>Selected Item</label>
            <AvField name="custname" type="select">
              <option>Item 1</option>
              <option>Item 2</option>
              <option>Item 3</option>
              <option>Item 4</option>
            </AvField>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={6}>
            <label>Export as</label>
            <AvRadioGroup inline name="exportas">
              <AvRadio label="CSV" value="1" />
              <AvRadio label="XML" value="2" />
            </AvRadioGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <button type="submit" class="exportbtn">
              {" "}
              Export
            </button>
          </Col>
        </Row>
      </AvForm>
    </>
  );
};

export default withTranslation()(ExportModal);
