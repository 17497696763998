import {
  FETCH_DASHBOARD_DATA,
  FETCH_DASHBOARD_FAILURE,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_REVENUE_GRAPH,
  FETCH_DASHBOARD_REVENUE_GRAPH_SUCCESS,
  FETCH_DASHBOARD_REVENUE_GRAPH_FAILURE,
  FETCH_DASHBOARD_RECENT_CHECKIN,
  FETCH_DASHBOARD_RECENT_CHECKOUT,
  FETCH_DASHBOARD_SPACE_GRAPH,
  FETCH_DASHBOARD_SPACE_SUCCESS,
  FETCH_DASHBOARD_SPACE_FAILURE,
} from "./actionTypes";

const initialState = {
  loading: false,
  graphLoading: false,
  spaceGraphLoading: false,
  dashboardCards: [],
  dashboardRevenueGraph: {},
  recentCheckIn: [],
  recentCheckOut: [],
  error: "",
  graphError: "",
  spaceGraphError: "",
  dashboardSpaceGraph: {},
};

const DashboardDetails = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_DATA:
      return {
        ...state,
        loading: true,
      };

    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardCards: action.payload,
      };
    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_DASHBOARD_REVENUE_GRAPH:
      return {
        ...state,
        graphLoading: true,
      };
    case FETCH_DASHBOARD_REVENUE_GRAPH_SUCCESS:
      return {
        ...state,
        graphLoading: false,
        dashboardRevenueGraph: action.payload,
      };
    case FETCH_DASHBOARD_REVENUE_GRAPH_FAILURE:
      return {
        ...state,
        graphLoading: false,
        graphError: action.payload,
      };
    case FETCH_DASHBOARD_RECENT_CHECKIN:
      return {
        ...state,
        recentCheckIn: action.payload,
      };
    case FETCH_DASHBOARD_RECENT_CHECKOUT:
      return {
        ...state,
        recentCheckOut: action.payload,
      };
    case FETCH_DASHBOARD_SPACE_GRAPH:
      return {
        ...state,
        spaceGraphLoading: true,
      };
    case FETCH_DASHBOARD_SPACE_SUCCESS:
      return {
        ...state,
        spaceGraphLoading: false,
        dashboardSpaceGraph: action.payload,
      };
    case FETCH_DASHBOARD_SPACE_FAILURE:
      return {
        ...state,
        spaceGraphLoading: false,
        spaceGraphError: action.payload,
      };
    default:
      return state;
  }
};

export default DashboardDetails;
