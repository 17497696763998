import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { useEffect } from "react";
import {
  setDefaultItems,
  deleteItems,
  updateTableItems,
} from "../../store/actions";
import { connect } from "react-redux";
function ItemComponent(props) {
  const handleAddRow = () => {
    props.add();
    props.setDefaultItems();
  };
  const handleDeleteRow = () => {
    props.delete(props.index);
    props.deleteItems(props.index);
  };
  return (
    <div className="itemContainer" key={props.index}>
      {props.index !== 0 ? (
        <div className="close" onClick={handleDeleteRow}>
          <span>
            <i class="bx bx-trash"></i>
          </span>
        </div>
      ) : (
        ""
      )}
      <Row className="d-flex align-items-center mb-3">
        <Col md={2}>
          <label>Item name</label>
        </Col>
        <Col md={6}>
          <AvField
            name="itemCommodityName"
            type="text"
            placeholder="Enter item name"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index][
                    "itemCommodityName"
                  ]
                : ""
            }
            required
          ></AvField>
        </Col>
      </Row>
      {props.formActivity === "2" ? (
        <>
          <Row className="d-flex align-items-center mb-3">
            <Col md={2}>
              <label>Previous Check In No.</label>
            </Col>
            <Col md={6}>
              <AvField
                name="itemPreviousCheckInNumber"
                type="text"
                placeholder="Enter item previous checkin number"
                onChange={(e) => props.updateTableItems(e, props.index)}
                value={
                  props.onlineFormCustomerItems[props.index]
                    ? props.onlineFormCustomerItems[props.index][
                        "itemPreviousCheckInNumber"
                      ]
                    : ""
                }
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={2}>
              <label>Goods Ref No.</label>
            </Col>
            <Col md={6}>
              <AvField
                name="itemPreviousGoodsReferenceNumber"
                type="text"
                placeholder="Enter goods reference number"
                onChange={(e) => props.updateTableItems(e, props.index)}
                value={
                  props.onlineFormCustomerItems[props.index]
                    ? props.onlineFormCustomerItems[props.index][
                        "itemPreviousGoodsReferenceNumber"
                      ]
                    : ""
                }
                required
              ></AvField>
            </Col>
          </Row>
        </>
      ) : (
        ""
      )}
      <Row className="d-flex align-items-center mb-3">
        <Col md={2}>
          <label>Batch # / Inv #</label>
        </Col>
        <Col md={6}>
          <AvField
            name="itemInvoiceNumber"
            type="text"
            placeholder="Enter batch / invoice number"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index][
                    "itemInvoiceNumber"
                  ]
                : ""
            }
            // required
          ></AvField>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <label>Qty</label>
          <AvField
            name="itemUnits"
            type="text"
            placeholder="Qty"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemUnits"]
                : ""
            }
            // required
          ></AvField>
        </Col>
        <Col md={2}>
          <label>CTNS</label>
          <AvField
            name="itemUom"
            type="text"
            placeholder="CTNS"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemUom"]
                : ""
            }
            // required
          ></AvField>
        </Col>
        <Col md={2}>
          <label>PCS</label>
          <AvField
            name="itemUnitsPerSinglePackage"
            type="text"
            placeholder="PCS"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index][
                    "itemUnitsPerSinglePackage"
                  ]
                : ""
            }
            // required
          ></AvField>
        </Col>
        <Col md={4}>
          <label className="form-input-dimension">
            <span>L</span>
            <span>B</span>
            <span>H</span>
          </label>

          <div className="form-input-dimension">
            <AvField
              name="itemLength"
              type="text"
              placeholder="Length"
              onChange={(e) => props.updateTableItems(e, props.index)}
              value={
                props.onlineFormCustomerItems[props.index]
                  ? props.onlineFormCustomerItems[props.index]["itemLength"]
                  : ""
              }
              // required
            ></AvField>
            <AvField
              name="itemBreadth"
              type="text"
              placeholder="Breadth"
              onChange={(e) => props.updateTableItems(e, props.index)}
              value={
                props.onlineFormCustomerItems[props.index]
                  ? props.onlineFormCustomerItems[props.index]["itemBreadth"]
                  : ""
              }
              // required
            ></AvField>
            <AvField
              name="itemHeight"
              type="text"
              placeholder="Height"
              onChange={(e) => props.updateTableItems(e, props.index)}
              value={
                props.onlineFormCustomerItems[props.index]
                  ? props.onlineFormCustomerItems[props.index]["itemHeight"]
                  : ""
              }
              // required
            ></AvField>
          </div>
        </Col>
        <Col md={2}>
          <label>Weight</label>
          <AvField
            name="itemTotalWeight"
            type="text"
            placeholder="weight"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemTotalWeight"]
                : ""
            }
            // required
          ></AvField>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <label>Value Add reg</label>
          <AvField
            name="itemValueAddReg"
            type="text"
            placeholder="Value"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemValueAddReg"]
                : ""
            }
            // required
          ></AvField>
        </Col>
        <Col md={4}>
          <label>Forklift / handling /loader </label>
          <AvField
            name="itemTemperature"
            type="text"
            placeholder="Type"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemTemperature"]
                : ""
            }
            // required
          ></AvField>
        </Col>
        <Col md={4}>
          <label>Weight handling</label>
          <AvField
            name="itemWeightUom"
            type="text"
            placeholder="Weight handling"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemWeightUom"]
                : ""
            }
            // required
          ></AvField>
        </Col>
      </Row>
      <Row className="d-flex align-items-center mb-3 mt-3">
        <Col md={2}>
          <label>Proceed from/To</label>
        </Col>
        <Col md={5}>
          <AvField
            name="itemProceedFrom"
            type="text"
            placeholder="Proceed From"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemProceedFrom"]
                : ""
            }
            // required
          ></AvField>
        </Col>
        <Col md={5}>
          <AvField
            name="itemProceedTo"
            type="text"
            placeholder="Proceed To"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemProceedTo"]
                : ""
            }
            // required
          ></AvField>
        </Col>
      </Row>
      <Row className="d-flex align-items-center mb-3 mt-3">
        <Col md={2}>
          <label>Location from/To</label>
        </Col>
        <Col md={5}>
          <AvField
            name="itemLocationFrom"
            type="text"
            placeholder="Location From"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemLocationFrom"]
                : ""
            }
            // required
          ></AvField>
        </Col>
        <Col md={5}>
          <AvField
            name="itemLocationTo"
            type="text"
            placeholder="Location To"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemLocationTo"]
                : ""
            }
            // required
          ></AvField>
        </Col>
      </Row>
      <Row className="d-flex align-items-center mb-3">
        <Col md={2}>
          <label>Vehicle #</label>
        </Col>
        <Col md={10}>
          <AvField
            name="itemVehicleNo"
            type="text"
            placeholder="Vehicle No"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemVehicleNo"]
                : ""
            }
            // required
          ></AvField>
        </Col>
      </Row>
      <Row className="d-flex align-items-center mb-3">
        <Col md={2}>
          <label>Additional Info</label>
        </Col>
        <Col md={10}>
          <AvField
            name="itemAdditionalInfo"
            type="textarea"
            placeholder="More Information"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index][
                    "itemAdditionalInfo"
                  ]
                : ""
            }
            required
          ></AvField>
        </Col>
      </Row>

      {props.array.length - 1 === props.index ? (
        <Row>
          <Col md={12} className="text-right">
            <button
              className="addDivBtn"
              type="button"
              onClick={() => handleAddRow()}
              disabled={props.formSuccess}
            >
              Add new item
            </button>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <hr className="border-bottom" />
    </div>
  );
}
const mapStatetoProps = (state) => {
  const { onlineFormCustomerItems } = state.OnlineFormTable;
  return { onlineFormCustomerItems };
};
export default connect(mapStatetoProps, {
  setDefaultItems,
  deleteItems,
  updateTableItems,
})(ItemComponent);
