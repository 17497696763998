// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
  FETCH_CUSTOMER_FAILURE,
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
} from "./actionTypes";

import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";

/**
 * Show the rightsidebar
 */

function* getAllCustomers() {
  try {
    let allCustomer = {};
    let orgId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${orgId}`;
    let resp = apiGet(Url);
    yield resp.then((resp) => {
      allCustomer = resp.response.data.data;
    });

    yield put({
      type: "FETCH_CUSTOMER_SUCCESS",
      payload: allCustomer,
    });
  } catch (error) {
    yield put({
      type: "FETCH_CUSTOMER_FAILURE",
      payload: error,
    });
  }
}

/**
 * Watchers
 */

export function* watchgetAllCustomers() {
  yield takeEvery(FETCH_CUSTOMER_REQUEST, getAllCustomers);
}
function* CustomerSaga() {
  yield all([fork(watchgetAllCustomers)]);
}

export default CustomerSaga;
