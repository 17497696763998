import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import retainerInvoice from "./retainerInvoice/reducer";
import customerDetails from "./customerDetails/reducer";
import ItemDetails from "./items/reducer";
import InitialDatas from "./dataInitialise/reducer";
import TableData from "./tableData/reducer";
import CommodityTableData from "./commodityTable/reducer";
import PalletMergeTable from "./palletmerge/reducer";
import OnlineFormTable from "./OnlineOrderForm/reducer";
//import GoodsTransferTable from "../pages/GoodsTransfer/goodsTransferTable";
import TableDataGt from "./goodsTransfer/reducer";
import TableDataOthers from "./tableDataOthers/reducer";
import PaymentRecievedTableData from "./paymentsRecieved/reducer";
import DashboardDetails from "./dashboard/reducer";
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  retainerInvoice,
  customerDetails,
  ItemDetails,
  InitialDatas,
  TableDataGt,
  TableData,
  CommodityTableData,
  PalletMergeTable,
  OnlineFormTable,
  PaymentRecievedTableData,
  // GoodsTransferTable,
  TableDataOthers,
  DashboardDetails,
});

export default rootReducer;
