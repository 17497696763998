import React from "react";
import { Container, Row, Col, Modal } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
function EditLocationType(props) {
  return (
    <Modal className="export-modal" isOpen={props.openModal}>
      <Row>
        <Col md={12} className="modal-head">
          <div>
            <h5>Edit Location Type</h5>
          </div>
          <div onClick={() => props.setOpenModel(false)}>
            <i class="bx bx-x"></i>
          </div>
        </Col>
      </Row>
      <hr />
      <AvForm className="form" onValidSubmit={() => props.handleEdit(props.id)}>
        <Row className="d-flex align-items-center mb-3">
          <Col md={6}>
            <label style={{ whiteSpace: "pre" }}>Location Type</label>
          </Col>
          <Col md={6}>
            <AvField
              name="type"
              type="text"
              placeholder="Enter Location Type"
              onChange={(e) => props.handle(e)}
              value={props.editValues.type}
              required
            ></AvField>
          </Col>
        </Row>
        
        <Row>
          <Col md={12}>
            <button type="submit" class="exportuserbtn">
              Update Location Type
            </button>
          </Col>
        </Row>
      </AvForm>
    </Modal>
  );
}

export default EditLocationType;
