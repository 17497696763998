import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
} from "reactstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
//i18n
import ExportModal from "./exportModal";
import { withTranslation } from "react-i18next";
import Overview from "./overview";
import CustomerStock from "./customerStocks";
import Transactions from "./transactions";
import Mails from "./mails";
import Statements from "./statements";
import { apiGet } from "../../../components/config/apiConfig";
import { BaseUrl } from "../../../components/config/BaseUrl";
// import { ToastContainer, toast } from "react-toastify/dist/react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SingleCustomer = (props) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("1");
  const [socialDrp, setsocialDrp] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [customer, setCustomer] = useState({});
  const [customerStock, setCustomerStock] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [recentTransactions, setRecentTransactions] = useState([]);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  function getCustomer() {
    const Url =
      BaseUrl.apiUrl.baseUrl + "api/v1/customers/" + props.match.params.id;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setCustomer(resp.response.data.data);
    });
  }
  function getIncomeChart() {
    const Url =
      BaseUrl.apiUrl.baseUrl +
      "api/v1/customer_billing/" +
      props.match.params.id;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setRecentTransactions(resp.response.data.data.recentTransactions);
      setChartData(resp.response.data.data.graphData);
    });
  }
  function getCustomerStocks() {
    const Url =
      BaseUrl.apiUrl.baseUrl + "api/v1/customer_stock/" + props.match.params.id;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setCustomerStock(resp.response.data.data);
    });
  }
  const handleSendForm = (e) => {
    e.preventDefault();
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/send_order_link/${customer.customerId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      toast.success(`${resp.response.data.data}`);
    });
  };
  useEffect(() => {
    getCustomer();
    getIncomeChart();
    getCustomerStocks();
  }, []);
  const handleBack = () => {
    history.goBack();
  };
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12} className="item-head">
              <div className="backbutton" onClick={handleBack}>
                <i class="bx bx-arrow-back"></i>
              </div>
              <div className="bbb">
                <Dropdown
                  className="d-inline-block"
                  isOpen={socialDrp}
                  toggle={() => {
                    setsocialDrp(!socialDrp);
                  }}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect ddtoggle"
                    tag="button"
                  >
                    <button> More</button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {localStorage.getItem("userRole") === "ADMIN" ? (
                      <DropdownItem tag="button" onClick={handleSendForm}>
                        <i className="bx bx-envelope font-size-16 align-middle mr-1"></i>
                        {props.t("Send form")}{" "}
                      </DropdownItem>
                    ) : (
                      ""
                    )}
                    {/* <DropdownItem tag="a" href="">
                      {" "}
                      <i className="bx bx-copy font-size-16 align-middle mr-1"></i>
                      {props.t("Clone Customer")}{" "}
                    </DropdownItem>
                    <hr />
                    <DropdownItem tag="a" href="">
                      {" "}
                      <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                      {props.t("Import Customer")}{" "}
                    </DropdownItem>
                    <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                      {" "}
                      <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                      {props.t("Export Customer")}{" "}
                    </DropdownItem> */}
                  </DropdownMenu>
                </Dropdown>
                <Modal className="export-modal" isOpen={modalIsOpen}>
                  <ExportModal isClose={() => setModalIsOpen(false)} />
                </Modal>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Overview
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Transactions
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Stocks
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "4",
                    })}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    Mails
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "5",
                    })}
                    onClick={() => {
                      toggle("5");
                    }}
                  >
                    Statements
                  </NavLink>
                </NavItem> */}
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Overview data={customer} chart={chartData} />
                </TabPane>
                <TabPane tabId="2">
                  <Transactions recent={recentTransactions} />
                </TabPane>
                <TabPane tabId="3">
                  <CustomerStock stocks={customerStock} />
                </TabPane>
                {/*   <TabPane tabId="4">
                  <Mails />
                </TabPane>
                <TabPane tabId="5">
                  <Statements data={customer} />
                </TabPane> */}
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(SingleCustomer);
