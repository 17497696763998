import React from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { useCallback } from "react";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";
import * as moment from "moment";
import { max } from "lodash";
import FileSaver from "file-saver";
function CheckInreport() {
  const current = new Date();
  const [warehouseList, setwarehouseList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [typeRequired, setTypeRequired] = useState(false);
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1 < 10
      ? `0${current.getMonth() + 1}`
      : current.getMonth() + 1
  }-${current.getDate() < 10 ? `0${current.getDate()}` : current.getDate()}`;

  const [searchenable, setsearchenable] = useState(false);
  const [searchdata, setsearchdata] = useState({
    fromDate: date,
    toDate: date,
    warehouseId: 0,
    customerId: 0,
    checkInNumber: 0,
  });
  const [WarehouseId, setWarehouseId] = useState({
    id: 0,
    name: "",
  });
  const [customerId, setCustomerId] = useState({
    id: 0,
    name: "",
  });
  const [billingType, setBillingType] = useState({
    id: 0,
    name: "",
  });
  const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);
  const columnDefs = useMemo(
    () => [
      {
        field: "checkInDate",
        headerName: "Date",
        valueFormatter: function (params) {
          return moment(params.value).format("YYYY-MM-DD");
        },
        width: 110,
      },
      {
        field: "checkInTime",
        headerName: "Time",
        valueFormatter: function (params) {
          return params.value === null
            ? "Invalid time"
            : moment(params.value, "HH:mm").format("hh:mm A");
        },
        width: 110,
      },
      { field: "checkInNumber", headerName: "CheckIn Number" },
      { field: "checkInWarehouseName", headerName: "Warehouse Name" },
      { field: "checkInCustomerName", headerName: "Customer Name" },
      { field: "checkInCustomerEmail", headerName: "Email" },
      { field: "checkInCustomerPhone", headerName: "Phone" },
      { field: "checkInQuotationNumber", headerName: "Quotation No" },
      { field: "checkInVehicleNumber", headerName: "Vehicle No" },
      { field: "checkInDrivingLicense", headerName: "Driving License" },
      { field: "checkInDriverName", headerName: "Driver Name" },
      { field: "checkInCbm", headerName: "CBM" },
      { field: "checkInSqm", headerName: "SQM" },
      { field: "checkInRatePerCbm", headerName: "Rate/CBM" },
      // { field: "checkInCbmRatePerDay", headerName: "CBM Rate/Day" },
      {
        field: "checkInEstimatedCommodityValue",
        headerName: "Commodity Value",
      },
    ],
    []
  );
  const [rowData, setRowData] = useState();
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    []
  );

  const onGridReady = useCallback((params) => {
    const updateData = (data) => params.api.setRowData(data);
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/checkins_1/${searchdata.fromDate}/${searchdata.toDate}/0/0/0`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      updateData(resp.response.data.data);
    });
  }, []);

  function handleChange(e) {
    const newData = { ...searchdata };
    newData[e.target.name] = e.target.value;

    setsearchdata(newData);
  }
  const handleGenerateReport = () => {
    setIsLoading(true);
    let checkInNumb = searchdata.checkInNumber;
    if (checkInNumb === "") {
      checkInNumb = 0;
    }
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/checkins/export/${searchdata.fromDate}/${searchdata.toDate}/${WarehouseId.id}/${customerId.id}/${checkInNumb}/${billingType.id}/excel`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setIsLoading(false);
      let sliceSize = 1024;
      let byteCharacters = atob(resp.response.data.data);
      let bytesLength = byteCharacters.length;
      let slicesCount = Math.ceil(bytesLength / sliceSize);
      let byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);
        let bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      FileSaver.saveAs(
        new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
        `${Date.now()}.xlsx`
      );
    });
  };
  const handleDownloadPdf = () => {
    setIsLoading(true);
    let checkInNumb = searchdata.checkInNumber;
    if (checkInNumb === "") {
      checkInNumb = 0;
    }
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/checkins/export/${searchdata.fromDate}/${searchdata.toDate}/${WarehouseId.id}/${customerId.id}/${checkInNumb}/${billingType.id}/pdf`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setIsLoading(false);
      let sliceSize = 1024;
      let byteCharacters = atob(resp.response.data.data);
      let bytesLength = byteCharacters.length;
      let slicesCount = Math.ceil(bytesLength / sliceSize);
      let byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);
        let bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      FileSaver.saveAs(
        new Blob(byteArrays, { type: "application/pdf" }),
        `${Date.now()}.pdf`
      );
    });
  };
  function handleSearch(e) {
    let checkInNumb = searchdata.checkInNumber;
    if (checkInNumb === "") {
      checkInNumb = 0;
    }
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/checkins_1/${searchdata.fromDate}/${searchdata.toDate}/${WarehouseId.id}/${customerId.id}/${checkInNumb}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setRowData(resp.response.data.data);
    });
  }

  function getWarehouseData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/warehouse/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setwarehouseList(resp.response.data.data);
    });
  }
  function getCustomerData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setCustomerList(resp.response.data.data);
    });
  }
  useEffect(() => {
    getWarehouseData();
    getCustomerData();
  }, []);
  const warehouse = warehouseList.map((item) => {
    return {
      value: item.warehouseId,
      label: item.warehouseName,
    };
  });
  const customer = customerList.map((item) => {
    return {
      value: item.customerId,
      label: item.customerDisplayName,
    };
  });
  const billingTypeOptions = [
    {
      value: "ALL",
      label: "ALL",
    },
    {
      value: "CBM",
      label: "CBM",
    },
    {
      value: "SQM",
      label: "SQM",
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>CARGO CHECK IN REPORT</h6>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody className="tabcard">
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12} className="search-sec">
                          <AvForm className="form">
                            <Row>
                              <Col md={2}>
                                <label>From Date</label>
                                <AvField
                                  name="fromDate"
                                  type="date"
                                  onChange={handleChange}
                                  // max={date}
                                  value={searchdata.fromDate}
                                  required
                                ></AvField>
                              </Col>
                              <Col md={2}>
                                <label>To</label>
                                <AvField
                                  name="toDate"
                                  type="date"
                                  onChange={handleChange}
                                  min={searchdata.fromDate}
                                  value={searchdata.toDate}
                                  // max={date}
                                  required
                                  // value={data.custFn}
                                ></AvField>
                              </Col>
                              <Col md={2}>
                                <label>Warehouse</label>
                                <Select
                                  name="checkInWarehouseId"
                                  onChange={(e) =>
                                    setWarehouseId({
                                      id: e.value,
                                      name: e.label,
                                    })
                                  }
                                  options={warehouse}
                                />
                              </Col>
                              <Col md={2}>
                                <label>Customer</label>
                                <Select
                                  name="checkInCustomerId"
                                  onChange={(e) =>
                                    setCustomerId({
                                      id: e.value,
                                      name: e.label,
                                    })
                                  }
                                  options={customer}
                                />
                              </Col>
                              <Col md={2}>
                                <label>CheckIn Number</label>
                                <AvField
                                  name="checkInNumber"
                                  type="text"
                                  onChange={handleChange}
                                  value={searchdata.checkInNumber}
                                ></AvField>
                              </Col>
                              <Col md={2}>
                                <label>Type</label>
                                <Select
                                  onChange={(e) => {
                                    setTypeRequired(false);
                                    setBillingType({
                                      id: e.value,
                                      name: e.label,
                                    });
                                  }}
                                  options={billingTypeOptions}
                                />
                                {typeRequired ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    This field is required
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                              {/* <Col md={6}>
                                <div className="search-buttons">
                                  <button class=" btn reportbutton">
                                    Get Report
                                  </button>
                                </div>
                              </Col> */}
                            </Row>
                            <Row className="mt-3">
                              <Col>
                                <div className="search-buttons">
                                  <button
                                    class=" btn reportbutton"
                                    type="button"
                                    onClick={(e) => handleSearch(e)}
                                  >
                                    Get Report
                                  </button>
                                  <button
                                    className=" btn reportbutton"
                                    onClick={handleGenerateReport}
                                    disabled={isLoading || billingType.id === 0}
                                  >
                                    Download Excel
                                  </button>
                                  <button
                                    className=" btn reportbutton"
                                    onClick={handleDownloadPdf}
                                    disabled={isLoading || billingType.id === 0}
                                  >
                                    Download PDF
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12}>
                      <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          enableRangeSelection="true"
                          rowData={rowData}
                          rowSelection="multiple"
                          suppressRowClickSelection="true"
                          suppressRowVirtualisation="true"
                          //   paginationPageSize="11"
                          onGridReady={onGridReady}
                          pagination={true}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default CheckInreport;
