import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  apiGet,
  apiDelete,
  apiPut,
} from "../../../components/config/apiConfig";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../../components/config/BaseUrl";
import FilterComponent from "./FilterComponent";
import DeleteTds from "./DeleteTds";
import { ToastContainer, toast } from "react-toastify";
import EditTds from "./EditTds";
function TdsList() {
  const [tdsList, setTdsList] = useState([]);
  const [isSaving, setisSaving] = useState(false);
  const [trackIsHigherRateTax, setTrackIsHigherRateTax] = useState(false);

  const [editValues, seteditValues] = useState({
    taxName: "",
    taxRate: 0,
    taxSection: "",
    isHigherRateTax: 0,
    reasonForHigherRate: "",
  });
  const [tdsId, setTdsId] = useState("");
  const [deleteModel, setdeleteModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const userId = localStorage.getItem("Id");
  const getTdsList = async () => {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tds`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setTdsList(resp.response.data.data);
      // setTotalRows(resp.response.data.data.totalElements);
    });
  };

  const handleOpenModal = (id) => {
    setTdsId(id);
    setdeleteModel(true);
  };
  const handleEditModal = (id) => {
    setTdsId(id);
    setEditModel(true);
    getTdsById(id);
  };
  const handleChange = (e) => {
    const newData = { ...editValues };
    newData[e.target.name] = e.target.value;
    seteditValues(newData);
  };

  const getTdsById = async (id) => {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tds/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      seteditValues(resp.response.data.data);
      if (resp.response.data.data.isHigherRateTax === 1) {
        setTrackIsHigherRateTax(true);
      } else {
        setTrackIsHigherRateTax(false);
      }

      console.log(
        "edit track vaue db:",
        resp.response.data.data.isHigherRateTax
      );

      console.log("edit track vaue :", trackIsHigherRateTax);

      // setTotalRows(resp.response.data.data.totalElements);
    });
  };

  useEffect(() => {
    console.log("track value on load", trackIsHigherRateTax);
  }, [trackIsHigherRateTax]);

  useEffect(() => {
    console.log("item data", editValues);
  }, [editValues]);

  function handleTrackIsHigherRateTax(e) {
    console.log("track e", e);
    setTrackIsHigherRateTax(!trackIsHigherRateTax);
    console.log(
      "afetr setting track TrackIsHigherRateTax ",
      trackIsHigherRateTax
    );
    //  const newData = { ...data };
    //  newData["isInventoryEnabled"] = trackinv ? 1 : 0;
    //  setData(newData);
  }

  useEffect(() => {
    console.log("checkbox selected val:::::", trackIsHigherRateTax);
    //var checkVal = trackIsHigherRateTax;
    //console.log("checkval === ", checkVal);
    if (trackIsHigherRateTax) {
      seteditValues({ ...editValues, isHigherRateTax: 1 });
      console.log("track value:", editValues.isHigherRateTax);
    } else {
      seteditValues({ ...editValues, isHigherRateTax: 0 });
      console.log("track value:", editValues.isHigherRateTax);
    }
    console.log("data", editValues);
  }, [trackIsHigherRateTax]);

  useEffect(() => {
    getTdsList();
  }, []);

  const columns = [
    {
      name: "Tds",
      selector: (data) => data.taxName,
      sortable: true,
    },
    {
      name: "Rate",
      selector: (data) => data.taxRate,
      sortable: true,
    },
    {
      name: "Section",
      selector: (data) => data.taxSection,
      sortable: true,
    },

    {
      name: "Action",
      button: true,
      center: true,
      cell: (data) => (
        <>
          <button
            className="btn-round edit"
            onClick={() => handleEditModal(data.id)}
          >
            <i class="bx bx-pencil"></i>
          </button>

          <button
            className="btn-round delete"
            onClick={() => handleOpenModal(data.id)}
          >
            <i class="bx bx-trash"></i>
          </button>
        </>
      ),
    },
  ];
  const subHeaderComponent = useMemo(() => {
    return (
      <FilterComponent
        getTdsList={getTdsList}
        // onFilter={(e) => setFilterText(e.target.value)}
        // onClear={handleClear}
        // filterText={filterText}
      />
    );
  }, []);
  const handleDelete = (id) => {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tds/${id}/${userId}`;
    let resp = apiDelete(Url);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          getTdsList();
          toast.success(resp.response.data.data);
          setdeleteModel(false);
          break;

        default:
          getTdsList();
      }
    });
  };
  const handleEdit = (id) => {
    setisSaving(true);

    var body = {
      taxName: editValues.taxName,
      taxRate: editValues.taxRate,
      taxSection: editValues.taxSection,
      isHigherRateTax: editValues.isHigherRateTax,
      reasonForHigherRate: editValues.reasonForHigherRate,
    };
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tds/${id}`;
    let resp = apiPut(Url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          getTdsList();
          setEditModel(false);
          setisSaving(false);

          break;

        default:
          toast.error(resp.response.data.data);
      }
    });
  };
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        // autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="TDS" />
          <Row>
            <Col>
              <div className="card">
                <DataTable
                  columns={columns}
                  data={tdsList}
                  pagination
                  paginationServer
                  selectableRows
                  subHeader
                  subHeaderComponent={subHeaderComponent}
                  subHeaderAlign="left"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <EditTds
        openModal={editModel}
        setOpenModel={setEditModel}
        handle={handleChange}
        isSaving={isSaving}
        editValues={editValues}
        handleEdit={handleEdit}
        id={tdsId}
        handleTrackIsHigherRateTax={handleTrackIsHigherRateTax}
        trackIsHigherRateTax={trackIsHigherRateTax}
      />
      <DeleteTds
        openModal={deleteModel}
        setOpenModel={setdeleteModel}
        handleDeleteUom={handleDelete}
        id={tdsId}
      />
    </React.Fragment>
  );
}

export default TdsList;
