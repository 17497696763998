import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Input,
  ButtonGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Formik, Field } from "formik";
//i18n
import { withTranslation } from "react-i18next";
const VendorComments = (props) => {
  return (
    <React.Fragment>
      <Row>
        <Col md={8} className="formLeft ">
          <Col>
            <Input type="textarea" name="text" id="exampleText" />
          </Col>
          <div className="form-right"></div>
        </Col>
      </Row>
      <br />
      <Col md={8} className="formDown">
        <Button className="btn-gray ">Add Comment</Button>
      </Col>
    </React.Fragment>
  );
};
export default VendorComments;
