import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { SAVE_DATA_ON_SUMBIT } from "./actionTypes";
import { saveRetainerInvoiceData } from "./actions";

import { apiGet, apiPost } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";

// Is user register successfull then direct plot user in redux.
function* submitRetainerInvoiceData({ payload: { data } }) {
  try {
    const Url = BaseUrl.apiUrl.baseUrl + "api/v1/retainer_invoice";
    const body = {
      retainerInvoiceCustomerId: 20,
      retainerInvoiceShippingAddressId: 90,
      retainerInvoiceReferenceNumber: 100,
      retainerInvoiceDate: "2021-09-07",
      retainerInvoiceProject: "inv",
      retainerInvoiceTotal: 2300,
      retainerInvoiceCustomerNotes: "notes",
      retainerInvoiceTermsAndConditions: "good",
      retainerinvoiceCustomerName: "",
      retainerinvoiceCustomerShippingAddress: "",
      retainerinvoiceCustomerContactNumber: "",
      organizationId: 1,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
      retainerInvoiceItemsList: [
        {
          retInvItemDescription: "GOOD",
          retInvItemAmount: 5600,
          addedByUser: 1,
          editByUser: 1,
        },
      ],
    };
    yield apiPost(Url, body).then((resp) => {
    });
  } catch (error) {
    // yield put(apiError(error));
    console.log("Error occured");
  }
}

export function* watchUserRetainerInvoice() {
  yield takeEvery(SAVE_DATA_ON_SUMBIT, submitRetainerInvoiceData);
}

function* retainerSaga() {
  yield all([fork(watchUserRetainerInvoice)]);
}

export default retainerSaga;
