import React from "react";
import dyarco from "../assets/dyarco/dyarco.jpeg";
// import footerlogo from "../assets/dyarco/footerlogo.jpg";
function TermsAndUse() {
  return (
    <>
      <div className="terms ">
        <img src={dyarco} alt="dyarco" />
        <div className="container mt-4">
          <div className="heading">GENERAL TERMS AND CONDITIONS</div>
          <div className="contents mb-3">
            <div className="common-head">
              Definitions and Interpretations Definitions
            </div>
            <div>In these General Terms and Conditions:</div>
            <div>
              <strong>"Affiliates"</strong> means in relation to a Party,
              another person who, either directly or indirectly, controls, is
              controlled by or is under common control with such Party. For the
              purposes of this definition "controls" (including the terms
              "controlled by" and "under common control with") shall mean the
              power to direct or cause the direction of the management and
              policies of a company whether by ownership of a majority of the
              equity of such company, by contract or otherwise.
            </div>
            <div className="mt-4">
              <strong>"COMPANY"</strong> or <strong>"DSS"</strong> means Dyarco
              Shipping Services W.L.L, a company registered under the Laws of
              the State of Qatar, having its registered address at P.O Box:
              3841, Doha, State of Qatar.
            </div>
            <div className="mt-4">
              <strong>"CONTRACT"</strong> means the FORM OF AGREEMENT entered
              into between the Parties (if any), these General Terms and
              Conditions and the Quotation (falling within the duration of FORM
              OF AGREEMENT) that has been offered by the COMPANY and accepted by
              the CUSTOMER.
            </div>
            <div className="mt-4">
              <strong> "CUSTOMER"</strong> means the person that accepts the
              Quotation offered by the COMPANY
            </div>
            <div className="mt-4">
              <strong>“Goods”</strong> means the whole or any part of the cargo,
              contents and any packaging accepted from the customer and
              including but not limited to containers, carton and packaging.
            </div>
            <div className="mt-4">
              <strong>"Owner"</strong> has the meaning given in Article 3.
            </div>
            <div className="mt-4">
              <strong>"Parties"</strong> means the COMPANY and the CUSTOMER and
              "Party" means either of them as the context requires.
            </div>
            <div className="mt-4">
              <strong>"Personnel"</strong> means employees, directors, officers
              and agents of a Party.
            </div>
            <div className="mt-4">
              <strong>"Quotation"</strong> means the quotation issued to the
              CUSTOMER at the same time as these General Terms and Conditions,
              setting out the pricing and commercial terms offered by the
              COMPANY for the performance of the SERVICES.
            </div>
            <div className="mt-4">
              <strong>"Service Fee"</strong> has the meaning given in Article 2
            </div>
            <div className="mt-4">
              <strong>"SERVICES"</strong> means the logistics, freight
              forwarding and/or warehousing services (as applicable) described
              in the Quotation.
            </div>
            <div className="mt-4 mb-4">
              <strong>"Taxes"</strong> has the meaning given in Article 2.
            </div>
            <div className="common-head">Interpretation</div>
            <div>
              In these General Terms and Conditions, except where the context
              otherwise requires, the following shall apply:
            </div>
            <div>
              Headings of clauses or group of clauses in these conditions are
              for indicative purpose only and do not affect the interpretation
              of these General Terms and Conditions;
            </div>
            <div className="mb-4">
              <ul>
                <li>
                  References to Articles, clauses or Annexures are to articles,
                  clauses and Annexures of these General Terms and Conditions;
                </li>
                <li>The singular shall include the plural and vice versa;</li>
                <li>
                  References to one gender shall include references to the
                  other;
                </li>
                <li>
                  References to a 'person' include any person, firm, company,
                  corporation, government, state or agency of a state or any
                  association, trust or partnership (whether or not having a
                  separate legal personality) or two or more of the foregoing
                  and shall include successors in title and permitted assigns;
                </li>
                <li>
                  Any reference to any statute or legislation shall be deemed to
                  include any amendments, re-enactments or replacements unless
                  otherwise stated; and
                </li>
                <li>
                  Any references to dates are dates construed by reference to
                  the Gregorian calendar and all times shall be construed as
                  references to the time in Doha, Qatar.
                </li>
              </ul>
            </div>
            <div className="common-head">Article 1. Appointment:</div>
            <div className="mb-4">
              Following written acceptance of the Quotation by the CUSTOMER, the
              COMPANY shall perform the SERVICES in accordance with reasonable
              skill and care. The COMPANY shall be under no obligation to
              perform any SERVICES, and there shall be no CONTRACT in place,
              until such time as the CUSTOMER has accepted the terms of the
              Quotation offered by the COMPANY.
            </div>
            <div className="mb-4">
              All quotations (including the Quotation) and tenders offered,
              Goods accepted and SERVICES performed by the COMPANY are subject
              to these General Terms and Conditions. Save for the other
              documents forming the CONTRACT, no other terms and conditions
              (including any terms of business provided by the CUSTOMER or any
              Owner) shall apply, whether or not the same shall be signed by or
              on behalf of the COMPANY. In case of any conflict or inconsistency
              between the documents forming the CONTRACT, these General Terms
              and Conditions shall prevail.
            </div>
            <div className="common-head">Article 2. Price and Payment:</div>
            <div>
              <strong>Quotation and Price:</strong> The Quotation constitutes an
              offer by the COMPANY to perform the SERVICES subject to these
              General Terms and Conditions.
            </div>
            <div>
              Upon countersignature by the CUSTOMER, the Quotation represents an
              integral part of the CONTRACT between the Parties and the prices
              stated therein shall be used as the basis for calculating the
              amount(s) payable by the CUSTOMER to the COMPANY in consideration
              for the SERVICES (the "Service Fee"). Additional local or
              international services other than those mentioned in the Quotation
              will be quoted on a case by case basis, with the most recent
              quotation superseding all previous quotations. Once the quotation
              is accepted by the CUSTOMER, the relevant services will form part
              of the SERVICES and the agreed amount will form part of the
              Service Fee. Estimates and quotations (including the Quotation)
              are given by the COMPANY on the basis of immediate acceptance and
              are subject to withdrawal or revision if not accepted by the
              CUSTOMER within the validity period specified in the Quotation.
              Unless otherwise agreed in writing, the COMPANY shall be entitled
              to revise the Service Fee with or without notice in the event of
              changes outside the COMPANY’s control occurring in currency
              exchange rates, rates of freight, insurance premiums or any other
              charges applicable to the Goods and/or the SERVICES. All sums due
              to the COMPANY under the CONTRACT are payable on demand by invoice
              or otherwise. Payment shall be made without deduction and shall
              not be withheld or deferred on account of any claim, counterclaim
              or set-off, unless prior written consent of the COMPANY is
              obtained.
            </div>
            <div>
              <strong> Taxes:</strong> All payments to the COMPANY under the
              CONTRACT are exclusive of value added, goods and services, sales,
              withholding, customs, excise and all similar taxes ("Taxes")
              applicable on the Goods and/or the SERVICES. The CUSTOMER shall be
              responsible for payment of all Taxes, if any, levied on or
              deducted from any amounts payable to COMPANY or its Affiliates in
              connection with the CONTRACT. If the COMPANY or its Affiliate has
              made payment to the applicable governmental authority of any such
              TAXES, the CUSTOMER shall reimburse the COMPANY or such Affiliate
              of the same together with the payment of the Service Fee to which
              it relates or as otherwise required by applicable law so that the
              net amount of the Service Fee actually received by COMPANY or such
              Affiliate (after payment of such Taxes) equals the full amount
              stated to be payable in the applicable invoice issued by the
              COMPANY. To the extent applicable law requires any such Taxes to
              be paid by CUSTOMER directly to a governmental authority or to the
              extent a reverse charge mechanism is available and applicable to
              CUSTOMER, the CUSTOMER shall account for and pay such Taxes
              promptly and receipts or other written proof of such payment shall
              be provided to COMPANY or such Affiliate promptly. CUSTOMER
              undertakes to pay invoices without any deduction for Taxes or
              similar imposts or any other deductions or withholding of any
              description prior to completion of the SERVICES unless a credit
              facility has been approved by the COMPANY and the balance
              outstanding at any point in time does not exceed the overall
              sanctioned limits. The credit facility will specify the terms of
              payment for TAXES. A credit facility will only be granted to
              CUSTOMER upon the completion of the appropriate credit application
              form by the Parties and the approval of the COMPANY’s credit
              control department. Until such time, all SERVICES provided will be
              on a prepaid cash basis. The COMPANY’s credit control department
              reserves the right to withdraw credit facilities at any time
              without assigning reasons thereto
            </div>
            <div className="mb-4">
              <strong>Reimbursement of expenses, duties etc:</strong>
              Unless otherwise agreed by the COMPANY, the CUSTOMER shall pay in
              advance any expenses towards legalization, fines, duties,
              demurrages, penalties or any other expenses deemed payable in
              advance of performing the SERVICES by the COMPANY. However, the
              expenses incurred by the COMPANY on behalf of the CUSTOMER towards
              delivery orders or any other expenses will be invoiced at cost
              along with the Service Fee.
            </div>
            <div className="common-head">Article 3. CUSTOMER’S Warranties:</div>
            <div className="mb-4">
              <ul>
                <li>
                  The CUSTOMER warrants that he is either the owner or the
                  authorized agent of the owner of the Goods, and is authorized
                  to accept these General Terms and Conditions not only for
                  himself but also as agent for and on behalf of the owner
                  and/or the consignee of the Goods and all other persons who
                  are or may hereafter become interested in the Goods (any such
                  persons being herein called the "Owner”).
                </li>
                <li>
                  The CUSTOMER warrants that the description and particulars of
                  any Goods and other consignments furnished by, or on behalf
                  of, the CUSTOMER are complete and accurate.
                </li>
                <li>
                  Except where the COMPANY is instructed in writing to pack the
                  Goods, the CUSTOMER warrants that all Goods are properly
                  packed, marked, labelled, stuffed and stowed in a manner
                  appropriate to any operations or transactions affecting the
                  Goods and the characteristics of the Goods.
                </li>
                <li>
                  Whilst agents on site may be instructed by the CUSTOMER to
                  re-pack consignments after an exhibition for return or onward
                  transmission, facilities and expert packers are not normally
                  available. Whilst reasonable care will be exercised, no
                  responsibility can be accepted by the COMPANY or the site
                  agents for claims arising from inadequacies in packing.
                </li>
                <li>
                  The CUSTOMER warrants that where the COMPANY receives the
                  Goods from the CUSTOMER already stowed in or on a container,
                  trailer, tanker, or other device specifically constructed for
                  the carriage of Goods by land, sea or air (each hereinafter
                  individually referred to as “the Transport Unit”), the
                  Transport Unit is in good condition and is suitable for the
                  carriage to the intended destination of the Goods loaded
                  therein or thereon.
                </li>
              </ul>
            </div>
            <div className="common-head">
              Article 4. Indemnification, Liability and Insurance:
            </div>
            <div>
              <strong>Indemnification: </strong>The CUSTOMER shall release,
              defend, indemnify and hold harmless (“Indemnify”) the COMPANY, the
              Company's Affiliates, and their Personnel from and against all
              costs, claims, demands, liabilities, damages, losses, fines and
              expenses (including, without limitation, attorneys’ fees and other
              costs of defense) arising out of or incidental to the CONTRACT for
              any and all:
            </div>
            <div>
              <ul>
                <li>
                  Injuries to or death or illnesses of any person whatsoever;
                </li>
                <li>
                  Damage to or loss of real or personal property of the COMPANY
                  or any third party due to spreading of damp, infestation,
                  leakage or the escape of fumes or substances or otherwise
                  howsoever from the Goods; and
                </li>
                <li>
                  Discrepancies, errors or omissions in the description of the
                  GOODS and/or their value declared by the CUSTOMER or the Owner
                  in connection with any customs clearance activities,
                </li>
              </ul>
            </div>
            <div className="mt-4">
              in each case, except to the extent occasioned by the gross
              negligence or wilful misconduct of the COMPANY. Without prejudice
              to any other conditions herein, advice and information given by
              the COMPANY is provided gratuitously and without liability. Any
              advice and information provided is for the CUSTOMER's convenience
              only and the CUSTOMER shall indemnify and hold harmless the
              COMPANY against any liability, claims, loss, damage, costs or
              expenses arising out of or in connection with any other persons
              relying upon such advice or information.
            </div>
            <div>
              <strong>Liability:</strong> The COMPANY is only responsible for
              any loss of or damage to Goods or for any non-delivery or for any
              non-compliance with the CONTRACT if and to the extent that (i) it
              has been agreed between the Parties or finally determined that the
              same has arisen due to the negligence or wilful misconduct of the
              COMPANY, its Affiliates or their respective Personnel and (ii) the
              loss, damage, non-delivery or non-compliance is not otherwise
              recovered under any insurances in respect of the Goods (or that
              would have been recoverable but for a breach by the CUSTOMER of
              its obligations to effect and maintain insurances under the
              CONTRACT). The COMPANY shall not be liable hereunder for any loss
              or damage to the extent that the same is caused or contributed to
              by any negligence, wilful misconduct or breach of the CONTRACT by
              the CUSTOMER, its Affiliates or their respective Personnel.
            </div>
            <div>
              The COMPANY's liability for lost or damaged Goods caused by its
              (or its Affiliates' or Personnel's) negligence shall in no event
              exceed the lower of:
            </div>
            <div>
              <ul>
                <li>
                  The value of the Goods that have been damaged or destroyed as
                  a result of such negligence;
                </li>
                <li>
                  The reasonable and documented cost of repairing the Goods that
                  have been damaged as a result of such negligence; and
                </li>
                <li>
                  A sum of QR 0.8000 per Tonne of 1000 Kgs on the gross weight
                  of the Goods that have been damaged or destroyed as a result
                  of such negligence.
                </li>
              </ul>
            </div>
            <div className="mt-4">
              <strong>Limitation of Liability: </strong> Notwithstanding any
              other provision of the CONTRACT, the maximum liability of the
              COMPANY, howsoever arising under or in connection with the
              CONTRACT (whether in contract, negligence, indemnity or otherwise
              at law), will not exceed an amount equal to the Service Fee.
            </div>
            <div className="mt-4">
              In no circumstances whatsoever shall the COMPANY be liable to the
              CUSTOMER, any Owner or their respective Affiliates, Personnel for
              any loss of profit or revenue, loss of use of Goods or associated
              equipment, loss of production or down time costs, loss of
              opportunity, loss of contract or loss of goodwill or any other
              indirect or consequential losses, provided always that this shall
              not prevent, limit or exclude the COMPANY'S liability in the case
              of its fraud, gross negligence, or wilful misconduct.
            </div>
            <div className="mt-4">
              “DSS” is not liable for any kind of damage or lost goods resulted
              from handling inside the warehouse if their selling value exceeds
              the “DSS” limited liability which is governed by the insurance in
              place that is linked to the liability. In no event shall either
              party be liable in contract or in tort or otherwise for
              incidental, financial, consequential, special, or punitive
              damages, costs or expenses or a loss of profit, business,
              contract, revenues, or anticipated savings.
            </div>
            <div className="mt-4">
              The foregoing provisions of this Article 4 shall survive the
              expiration or termination of the CONTRACT provided that the
              liability was incurred during the term of the contract.
            </div>
            <div className="mt-4">
              <strong>Care and Custody: </strong>
              Notwithstanding that the Goods may be in the custody of the
              COMPANY during the performance of the SERVICES, risk and
              responsibility in the Goods shall remain with the CUSTOMER at all
              times.
            </div>
            <div className="mt-4">
              <strong> Insurance:</strong> The COMPANY shall not be under any
              obligation to effect insurance on any Goods and the CUSTOMER
              acknowledges that COMPANY will not, except as stated below, insure
              the Goods. The CUSTOMER shall, at its own cost and expense, insure
              (or shall procure that the Owner insures) the Goods with a
              financially sound and reputable insurer against all risks of loss
              or damage on customary terms in an amount no less than the full
              reinstatement value thereof whilst being carried by COMPANY,
              stored at any of COMPANY’s or its Affiliates' or Personnel's
              premises or otherwise under their care and custody. In addition:
            </div>
            <div>
              <ul>
                <li>
                  The CUSTOMER shall, from time to time upon reasonable request,
                  provide to the COMPANY certificates evidencing that the
                  insurance policy or policies required under this Article 4 are
                  in full force and effect. The CUSTOMER shall not do or omit to
                  do anything whereby the policy or policies may be or become
                  void or voidable;
                </li>
                <li>
                  CUSTOMER undertakes that all insurers' rights of subrogation
                  against the COMPANY, its Affiliates and their Personnel for
                  losses or claims arising out of any loss or damage to the
                  Goods shall be waived under such policies and CUSTOMER shall
                  indemnify and hold harmless the COMPANY, its Affiliates and
                  their Personnel in respect of all claims, costs, demands,
                  liabilities, damages, losses, fines and expenses (including,
                  without limitation, attorneys’ fees and other costs of
                  defense) arising from a breach of such undertaking or
                  resulting from a vitiating act by the CUSTOMER, the Owner, its
                  Affiliates or their Personnel in respect of any insurance
                  policy required under the CONTRACT; and
                </li>
                <li>
                  Save in the case of gross negligence or willful misconduct by
                  the COMPANY, the CUSTOMER shall bear responsibility for all
                  deductibles under any insurances policy required hereunder.
                </li>
                <li>
                  Under specific instructions in writing from the CUSTOMER prior
                  to the Goods coming under the custody of the COMPANY, and
                  subject to agreement between the Parties of the commercial
                  terms applicable to the insurance policy, COMPANY may arrange
                  insurance coverage for loss or damage to the Goods whilst in
                  the custody of the COMPANY. The CUSTOMER shall be named as an
                  additional insured and the loss payee under any such policy.
                  The CUSTOMER shall accept an increase in the Service Fee by an
                  amount equal to the premiums and all internal costs incurred
                  by the COMPANY in insuring the Goods. The CUSTOMER will be
                  liable for all deductibles under the policy, and shall be
                  responsible for all loss or damage which falls outside the
                  scope shall be responsible for all loss or damage which falls
                  outside the scope of cover arranged by the COMPANY. COMPANY
                  shall in no circumstances incur liability as insurer, and if
                  for any reason the insurer(s) dispute liability the CUSTOMER
                  shall have recourse against the insurers only.
                </li>
              </ul>
            </div>
            <div className="mt-4">
              <strong> Time limit for notice of claims: </strong>
              Any claim by the CUSTOMER or Owner against the COMPANY shall be
              made in writing and notified to the COMPANY:
            </div>
            <div>
              <ul>
                <li>
                  In the case of lost or damaged Goods, within 7 days of
                  becoming aware of the loss or damage;
                </li>
                <li>
                  In case of delay in delivery or non-delivery of the Goods in
                  accordance with the agreed terms, within 7 days of the date
                  when the Goods should have been delivered; and
                </li>
                <li>
                  In any other case, within 14 days of the event giving rise to
                  the claim.
                </li>
              </ul>
            </div>
            <div className="mt-4">
              Any claim not made and notified as aforesaid shall be deemed to be
              waived and absolutely barred.
            </div>
            <div className="mt-4 mb-4">
              <strong> Non-recourse: </strong>
              The obligations of COMPANY under the CONTRACT are the obligations
              solely of COMPANY without recourse to any other entity or person.
              In no event shall CUSTOMER have or assert any claim against or
              recourse to the owners, Affiliates or Personnel of COMPANY,
              whether by contract, operation of law, or otherwise.
            </div>
            <div className="common-head">
              Article 5. Rights and Duties of Parties:
            </div>
            <div>
              {" "}
              <strong>Dangerous Goods: </strong> Except under special
              arrangements previously made in writing the COMPANY will not
              accept or deal with any noxious, dangerous, hazardous or
              inflammable or explosive goods or any goods likely to cause
              damage. Should the CUSTOMER or any Owner nevertheless deliver any
              such goods to the COMPANY or cause the COMPANY to handle or deal
              with any such goods otherwise than under special arrangements
              previously made in writing, CUSTOMER shall be liable for all loss
              and damage whatsoever caused by or to or in connection with the
              Goods however arising and shall indemnify the COMPANY, its
              Affiliates and their Personnel against all penalties, claims,
              damages, costs and expenses whatsoever arising in connection
              therewith and the Goods may be destroyed or otherwise dealt with
              at the sole discretion of the COMPANY or any other person in whose
              custody they may be at the relevant time. If such goods are
              accepted under arrangements previously made in writing:
            </div>
            <div>
              <ul>
                <li>
                  CUSTOMER shall provide the COMPANY with the "Material Safety
                  Data Sheet" as required under applicable laws, which shall be
                  written in Arabic and English;
                </li>
                <li>
                  And they comprise products or materials which are considered
                  to be dangerous or hazardous according to any applicable laws,
                  the "UN Orange Book" or the "UN Purple Book", the CUSTOMER
                  shall clearly attach safety labels in compliance with such
                  applicable laws, the "UN Orange Book" and the "UN Purple
                  Book"; and
                </li>
                <li>
                  They may nevertheless be so destroyed or otherwise dealt with
                  on account of risk to other goods, property, life or health
                  though the COMPANY will where reasonably practicable contact
                  the CUSTOMER. The expression “goods likely to cause damage”
                  includes goods likely to harbour or encourage vermin or other
                  pests.
                </li>
              </ul>
            </div>
            <div className="mt-4">
              <strong>Bullion, bank notes, precious stones etc: </strong>
              Except under special arrangements previously made in writing the
              COMPANY will not accept or deal with bullion, coins, Bank or
              Treasury Notes, precious stones, jewelry, valuables, antiques,
              pictures, works of art, livestock, plants or human remains. Should
              any CUSTOMER or Owner nevertheless deliver any such goods to the
              COMPANY or cause the COMPANY to handle or deal with any such goods
              otherwise than under special arrangements previously made in
              writing the COMPANY shall be under no liability whatsoever for
              their loss, damage or diminution in value, however caused.
            </div>
            <div className="mt-4">
              <strong> Handling of goods: </strong>
              The COMPANY shall not be obliged to arrange for the Goods to be
              carried, stored or handled separately from the goods of other
              customers.
            </div>
            <div className="mt-4">
              <strong> Delivery of Goods: </strong>
              For all deliveries, it is the responsibility of the CUSTOMER to
              ensure that all roadways are paved and serviceable. The COMPANY
              will not accept any responsibility for deliveries made on unpaved
              roads. If delivery of the Goods or any part thereof is not taken
              by the CUSTOMER, consignee or Owner at the time and place when and
              where the COMPANY is entitled to call upon such person to take
              delivery thereof, the COMPANY shall be entitled to store the Goods
              or any part thereof at the sole risk of the CUSTOMER, whereupon
              the liability of the COMPANY in respect of the Goods or that part
              thereof stored as aforesaid shall wholly cease and the cost of
              such storage shall forthwith upon demand be paid by the CUSTOMER
              to the COMPANY. In any event the COMPANY may at any time serve
              notice on the CUSTOMER and exercise its rights under the below
              Article. The COMPANY does not undertake that the Goods or any
              documents shall depart, arrive or be available on particular dates
              and the CUSTOMER waives any claim against the COMPANY for any
              delay in performing any SERVICES, whether or not any such delay is
              caused by the negligence of the COMPANY.
            </div>
            <div className="mt-4">
              <strong>Disposal of Goods in case of delay: </strong>
              The COMPANY shall be entitled at the expense of the CUSTOMER to
              sell or dispose of
            </div>
            <div>
              <ul>
                <li>
                  On 21 days’ notice in writing to the CUSTOMER or, where
                  despite reasonable efforts the CUSTOMER cannot be traced,
                  after the Goods have been held by the COMPANY for 90 days, all
                  Goods which in the opinion of the COMPANY cannot be delivered
                  to the CUSTOMER or its consignee either because they are
                  insufficiently or incorrectly addressed, because they are not
                  collected or accepted by the CUSTOMER or its consignee or for
                  any other reason, and
                </li>
                <li>
                  Without notice in respect of Goods which have perished,
                  deteriorated, or altered or are in immediate prospect of doing
                  so, in a manner which has caused or may be reasonably expected
                  to cause loss or damage to any person or property or to
                  contravene applicable laws or regulations.
                </li>
              </ul>
            </div>
            <div className="mt-4">
              <strong>Lien: </strong>The COMPANY shall have a general lien on
              all Goods and documents relating to the Goods for all sums due at
              any time from the CUSTOMER or Owner, and shall be entitled to sell
              or dispose of such Goods or documents at the expense of the
              CUSTOMER and apply the proceeds in or towards the payment of such
              sums on 21 days’ notice in writing to the CUSTOMER. When Goods are
              accepted or dealt with upon instructions to collect freight,
              duties, charges or other expenses from the consignee or any other
              person the CUSTOMER shall remain responsible for the same if they
              are not paid by such consignee or other person immediately when
              due.
            </div>
            <div className="mt-4">
              <strong>Force Majeure: </strong>Neither Party shall be liable
              under, or have the right to terminate the CONTRACT for any delay
              or default in performing hereunder if such delay or default is
              caused by conditions beyond its control including, but not limited
              to: Acts of God, Government restrictions (including the denial or
              cancellation of any import/export or other necessary license),
              wars, insurrections, industrial action (strike or partial strike),
              and/or any other cause beyond the reasonable control of the Party
              whose performance is affected.
            </div>
            <div className="mt-4">
              <strong>Confidential Information: </strong>: All information
              disclosed (whether in writing, orally or by another means and
              whether directly or indirectly) by the COMPANY to the CUSTOMER or
              any Owner must be treated as confidential and must not be divulged
              to any persons, media, firms or corporations other than as
              approved by COMPANY'S authorized representative in writing.
            </div>
            <div className="mt-4">
              <strong>Intellectual Property: </strong>: The COMPANY and the
              CUSTOMER acknowledge that each party owns Intellectual Property,
              including their respective business processes, procedures and
              systems, at the date of the CONTRACT which ownership will remain
              and be unaffected by the CONTRACT.
            </div>
            <div className="mt-4">
              <strong> Non-Solicitation: </strong>
              During any period that any individual assigned by a Party to
              perform obligations in connection with the CONTRACT is performing
              such obligations and for a period of twelve (12) months
              thereafter, without the prior written consent of the Party that
              has employed such individual, the other Party shall not, directly
              or indirectly, solicit for employment, or contract for the
              services of, such individual; provided, however, that the
              foregoing will not prohibit a general, non-targeted solicitation
              for employment, such as newspaper advertisements.
            </div>
            <div className="mt-4">
              <strong>No Conflicts of Interest: </strong>The CUSTOMER shall not
              allow any of its personnel or COMPANY’s personnel to acquire any
              personal interest that may conflict with their responsibilities
              under the CONTRACT. Upon discovery, the CUSTOMER will notify
              COMPANY of any incidences of conflict of interest and take the
              necessary steps to cease the participation of the affected
              personnel in any CONTRACT related activity. The COMPANY may, at
              its own discretion, allow participation of affected personnel if
              it determines that the personnel’s involvement in the performance
              of the SERVICES is not detrimental to COMPANY’s interests.
            </div>
            <div className="mt-4">
              <strong>Other Obligations: </strong>
              <div>
                <ul>
                  <li>
                    CUSTOMER is obliged to update DSS with their company’s
                    official documents when they are expired and/or modified.
                    The official documents that need to be keep updated are:
                    Commercial Registration (CR), Computer Card, Tax Card,
                    Municipality License and copy of QID of the authorized
                    signatory.
                  </li>
                  <li>
                    CUSTOMER is obliged to import goods in abidance with the
                    specifications and which are not in contrary to QATAR LAWS
                    and REGULATIONS. ‘DSS’ will not be liable for any breach of
                    laws by the customer.
                  </li>
                  <li>
                    CUSTOMER shall make sure that all the employees working or
                    visiting the warehouse are on good behavior
                  </li>
                </ul>
              </div>
              <p>
                <strong>Suspension of Services: </strong>
                The COMPANY may suspend its performance of the SERVICES or any
                portion thereof, at any time, if an amount exceeding the credit
                limit set by COMPANY’s credit control department remains unpaid
                for a period in excess of 30 days after the due date for
                payment, such suspension to be effective upon CUSTOMER’s receipt
                of written notice of suspension from COMPANY. Such suspension
                shall be without prejudice to CUSTOMER’s liability for, without
                limitation, any costs and other amounts, including interest,
                owed or accrued to COMPANY pursuant to the CONTRACT up to and
                including the date of termination or suspension, and any costs
                reasonably incurred by COMPANY as a result of such termination
                or suspension.
              </p>
              <p>
                <strong>Termination: </strong>Either Party shall be entitled to
                terminate the CONTRACT with immediate effect by written notice
                if the other Party becomes the subject of bankruptcy,
                insolvency, liquidation, administration, administrative or other
                receivership or dissolution (or any equivalent or analogous
                proceedings in the relevant jurisdiction).
              </p>
              <p>
                The COMPANY shall be entitled to terminate the CONTRACT if any
                suspension of the SERVICES pursuant to the clause above
                continues for a period in excess of 30 days as a result of the
                CUSTOMER'S failure to make payment of the outstanding amount due
                to the COMPANY.
              </p>
              <p>
                On termination of the CONTRACT for any reason the CUSTOMER shall
                immediately pay to the COMPANY all of the COMPANY'S outstanding
                unpaid invoices.
              </p>
              <div className="common-head">Article 6. Freight forwarding:</div>
              <p>
                Where the SERVICES comprise freight forwarding or customs
                clearance activities, the COMPANY shall be entitled to enter
                into contracts for the carriage of Goods by any route or by any
                means and for the storage, packing or handling of the Goods by
                any persons at any place or places and for any length of time
                and to do such acts as may be necessary or incidental thereto at
                the reasonable discretion of the COMPANY, and to depart from the
                CUSTOMER’S instructions in any respect if in the opinion of the
                COMPANY it is necessary or desirable to do so in the CUSTOMER'S
                interests.
              </p>
              <div className="common-head">Article 7. Miscellaneous:</div>
              <p>
                Independent Contractor: Save as otherwise stated herein, COMPANY
                is and shall be an independent contractor with respect to the
                CONTRACT. Nothing contained in the CONTRACT shall be construed
                to create between the Parties an association, employment, trust,
                partnership, joint venture or other business entity, or impose
                any trust or partnership or similar duty on any Party.
              </p>
              <p>
                <strong> Third Party Beneficiaries: </strong>
                The CONTRACT is for the benefit of the Parties hereto and their
                respective successors and permitted assigns and shall not
                otherwise be deemed to confer upon or give to any other third
                party any right, claim, cause of action, or other interest
                herein.
              </p>
              <p>
                <strong>Subcontracting: </strong>
                The COMPANY shall be entitled to subcontract the performance of
                any of its obligations without the prior written consent of the
                CUSTOMER.
              </p>
              <p>
                <strong>Declaration: </strong>
                The COMPANY shall not be obliged to make any declaration for the
                purpose of any statute or convention or contract as to the
                nature or value of any Goods or as to any special interest in
                delivery, unless expressly instructed by the CUSTOMER in
                writing. Where there is a choice of rates according to the
                extent or degree of the liability assumed by carriers,
                warehousemen or others, Goods may be forwarded, dealt with etc,
                at CUSTOMER’s risk or other minimum charges and no declaration
                of value (where optional) will be made, unless express
                instructions in writing to the contrary have previously been
                given by the CUSTOMER. Any such declaration shall be made in
                accordance with the CUSTOMER'S instructions only (without the
                need for independent verification by the COMPANY) and the
                COMPANY does not accept any liability in connection with such a
                declaration.
              </p>
              <p>
                <strong> Notices: </strong>
                Any notice required or allowed under the CONTRACT shall be
                deemed to be effective when received at the address set forth in
                the Quotation, or at such replacement addresses of which a Party
                may duly notify the other.
              </p>
              <p>
                <strong>Governing Law and Jurisdiction: </strong>
                The CONTRACT shall be governed by the laws of the State of Qatar
                and any dispute arising out of any such act or CONTRACT shall be
                within the exclusive jurisdiction of the Courts of the State of
                Qatar. Each Party agrees that the Courts of the State of Qatar
                shall have exclusive jurisdiction to hear or otherwise decide
                such dispute and agrees not to argue to the contrary.
              </p>
              <p>
                <strong>Entire Agreement: </strong>
                The CONTRACT constitutes the entire agreement between the
                Parties and supersedes and cancels all previous agreements and
                working arrangements whether oral, express or implied between
                the Parties in respect to or in conjunction with the matters
                herein referred to. The CUSTOMER hereby acknowledges that it has
                not relied on any statement, promise, representation, assurance
                or warranty made or given by or on behalf of the COMPANY which
                is not set out in the CONTRACT.
              </p>
              <p>
                <strong>Amendments: </strong>
                These General Terms and Conditions may be amended, modified or
                varied or supplemented at the sole discretion of the COMPANY
                from time to time and will be published on COMPANY website and
                the Parties agree that such amendments/new versions shall have a
                superseding effect over the existing General Terms and
                Conditions. All agreement and contracts concluded by the COMPANY
                and the CUSTOMER after such publication shall be subject to the
                amended conditions.
              </p>
              <p>
                <strong>Waivers: </strong>
                Any waiver by either Party of any right hereunder or of any
                failure to perform or breach hereof by the other Party shall be
                in writing and signed by the waiving Party; provided, however,
                no such waiver shall be construed as a waiver of any continuing
                or succeeding breach of such provision, a waiver or modification
                of the provision itself, or a waiver or modification of any
                right under the CONTRACT, unless the instrument constituting the
                waiver so states.
              </p>
              <p>
                <strong>Severance: </strong>
                The invalidity or unenforceability of any portion or provision
                of the CONTRACT shall in no way affect the validity or
                enforceability of any other portion or provision hereof. Any
                invalid or unenforceable portion or provision shall be deemed
                severed from the CONTRACT and the balance of the CONTRACT shall
                be construed and enforced as if the CONTRACT did not contain
                such invalid or unenforceable portion or provision.
              </p>
            </div>
          </div>
        </div>
        <div className="term-footer">
          Tel.: (974)40290564,Fax: (974)44988707, P.O.Box :3841, DOHA-QATAR{" "}
          <br />
          C.R.No.: 88249 - Paid up Capital: QR. 1,000,000,
          E-mail:info@dyarcoshipping.com,
          <br />
          Website:www.dyarcoshipping.com
          {/* <img src={footerlogo} alt="license" className="footer-logo" /> */}
        </div>
      </div>
    </>
  );
}

export default TermsAndUse;
