import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useState } from "react";
import AddAisle from "./AddAisle";
import { BaseUrl } from "../../../components/config/BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import { apiPost } from "../../../components/config/apiConfig";
const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 40px;
  width: 300px;
  border-radius: 5px;
  // border-top-left-radius: 5px;
  // border-bottom-left-radius: 5px;
  // border-top-right-radius: 0;
  // border-bottom-right-radius: 0;
  border: 1px solid #1c6180;
  padding: 0 32px 0 24px;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: #052f855c;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear, getData }) => {
  const [approveModel, setapproveModel] = useState(false);
  const [values, setValues] = useState({
    aisle: "",
    
  });
  const handleChange = (e) => {
    const newData = { ...values };
    newData[e.target.name] = e.target.value;
    setValues(newData);
  };
  const handleSubmit = (e) => {
    e.persist();
    const Url = BaseUrl.apiUrl.baseUrl + "api/v1/master/aisle";
    //const Url = "http://localhost:8080/api/v1/master/aisle";
    var body = {
      aisle: values.aisle,
    };
    let resp = apiPost(Url, body);
    resp.then((resp) => {
      console.log(resp);
      switch (resp.response.data.code) {
        case 200:
          clearStateValues();
          getData();
          toast.success(resp.response.data.data);
          setapproveModel(false);
          break;

        default:
          getData();
      }
    });
  };
  const clearStateValues = () => {
    setValues({
      uom: "",
      uomDescription: "",
    });
  };
  return (
    <>
      <ToastContainer
        position="bottom-center"
        // autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div
        className="col-md-12 iitem"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="position-relative">
          {/* <Input
            id="search"
            type="text"
            placeholder="Search handling info"
            value={filterText}
            onChange={onFilter}
          />
          <span
            className="bx bx-search-alt"
            style={{ position: "absolute", top: "33%", left: "8px" }}
          ></span> */}
        </div>
        <div className="itembtn">
          <button className="newbtn" onClick={() => setapproveModel(true)}>
            {" "}
            <i class="bx bx-plus"></i> New
          </button>
        </div>
        <AddAisle
          openModal={approveModel}
          setOpenModel={setapproveModel}
          values={values}
          handle={handleChange}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

export default FilterComponent;
