import React, { useState } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Button } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { loginUser, apiError } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import opineIcon from "../../assets/images/icon-opine.png";
import Google from "../../assets/acc-images/google.svg";
import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import awcLogo from "../../assets/dyarco/awc.png";
import { useEffect } from "react";

const Login = (props) => {
  // handleValidSubmit

  const [login, setLogin] = useState({
    username: "",
    password: "",
    isChecked: false,
  });

  useEffect(() => {
    if (localStorage.checkbox && localStorage.username !== "") {
      setLogin({
        isChecked: localStorage.checkbox,
        username: localStorage.username,
        password: localStorage.password,
      });
    }
  }, []);
  const onChangeValue = (event) => {
    const newData = { ...login };
    newData[event.target.name] = event.target.value;
    setLogin(newData);
  };
  const onChangeCheckbox = (event) => {
    if (event.target.checked === false) {
      localStorage.clear();
    }
    const newData = { ...login };
    newData["isChecked"] = event.target.checked;
    setLogin(newData);
  };

  function handleValidSubmit(event, values) {
    props.loginUser(values, props.history);
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="container-fluid">
        <Row>
          <Col md="3 fp-left">
            <div className="fp-left-container">
              <Row>
                <Col>
                  {/* {" "}
                  <h6 className=""> Opine Accounting</h6> */}
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <h3 className="">Warehouse Management</h3>
                </Col>
              </Row>
              <Row>
                {/* <Col>
                  Lorem ipsum dolor sit amet <br />
                  consectetur adipising elit set duo eius mod tempr
                </Col> */}
              </Row>
            </div>
          </Col>
          <div className="col-md-8 fp-right">
            <div className="formChange">
              {/* Don't have an account?<Link to="/register">Sign up</Link> */}
            </div>
            <Row className="fp-form">
              <Col>
                <div className="text-center">
                  <img
                    src={awcLogo}
                    alt="dyarco"
                    className="slip-image-login"
                  />
                </div>
                <h5>Login</h5>
                <h6>Login to manage your accounting needs efficiently</h6>
                {/* <p className="">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit set duo
                  eius mod tempor. eius mod tempor.{" "}
                </p> */}
                <AvForm onValidSubmit={handleValidSubmit}>
                  <label htmlFor="username" className="">
                    Username
                  </label>
                  <AvField
                    name="username"
                    type="text"
                    value={login.username}
                    onChange={onChangeValue}
                    required
                  ></AvField>
                  <label htmlFor="password" className="">
                    Password
                  </label>
                  <AvField
                    name="password"
                    type="password"
                    value={login.password}
                    onChange={onChangeValue}
                    required
                  ></AvField>

                  <div className="authentication-bottom-links">
                    <div className="d-flex rememberme">
                      <AvField
                        name="lsRememberMe"
                        type="checkbox"
                        checked={login.isChecked}
                        onChange={onChangeCheckbox}
                      ></AvField>
                      <span className=""> Remember me</span>
                    </div>
                    <Link to="/forgot-password"> Forgot your password ?</Link>
                  </div>

                  {/* <a href="ForgetPswrd" className="fp-link">
                    Forgot your password ?
                  </a> */}
                  <Button type="submit" className="btn-full-main">
                    Sign in
                  </Button>
                  <Row>
                    <Col className="mt-4 text-center">
                      <p className="">Powered by Opine</p>
                    </Col>
                  </Row>
                </AvForm>
                {/* <hr className="hr" />
                <Button className="btn-full-gray ">
                  <img src={Google} alt="" className="btn-icon-main" />
                  Continue using Google
                </Button> */}
              </Col>
            </Row>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStatetoProps, { loginUser, apiError })(Login)
);
