import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import ExportModal from "./exportModal";
//i18n
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet, apiPut } from "../../components/config/apiConfig";
import dyarco from "../../assets/dyarco/awc.png";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SinglePalletMerge = (props) => {
  const history = useHistory();

  const componentRef = useRef();
  const [palletmergeData, setpalletmergeData] = useState({});
  const [requestDrp, setrequestDrp] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [comment, setComment] = useState("");
  const [approveModel, setapproveModel] = useState(false);
  const [declineModel, setdeclineModel] = useState(false);
  function getPalletMergeById() {
    const Url =
      BaseUrl.apiUrl.baseUrl + "api/v1/merge_request/" + props.match.params.id;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setpalletmergeData(resp.response.data.data);
    });
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "print-btn",
    pageStyle: "margin:50px",
  });
  function handleAcceptRequest(e) {
    var body = {
      requestStatus: "1",
      // requestRemarksAdmin: remarks,
      requestCommentsAdmin: comment,
    };
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/merge_request/${props.match.params.id}`;
    let resp = apiPut(Url, body);
    resp.then((resp) => {
      if (resp.response.data.status === "Success") {
        toast.success(`${resp.response.data.data} `);
        setapproveModel(false);
        setTimeout(() => {
          getPalletMergeById();
        }, 1000);
      } else {
        setapproveModel(false);
        toast.error(`${resp.response.data.data} `);
      }
    });
  }
  function handleDeclineRequest(e) {
    var body = {
      requestStatus: "2",
      // requestRemarksAdmin: remarks,
      requestCommentsAdmin: comment,
    };
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/merge_request/${props.match.params.id}`;
    let resp = apiPut(Url, body);
    resp.then((resp) => {
      if (resp.response.data.status === "Success") {
        toast.success(`${resp.response.data.data} `);
        setdeclineModel(false);
        setTimeout(() => {
          getPalletMergeById();
        }, 1000);
      } else {
        setdeclineModel(false);
        toast.error(`${resp.response.data.data} `);
      }
    });
  }

  useEffect(() => {
    getPalletMergeById();
  }, []);
  const [dropdownMonth, setMonth] = React.useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleMonth = () => setMonth(!dropdownMonth);
  const [dropdownFilter, setFilter] = React.useState(false);
  const toggleFilter = () => setFilter(!dropdownFilter);
  const [dropdownOpen, setOpen] = React.useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const handleBack = () => {
    history.goBack();
  };
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid className="singlepage">
          <Row>
            <Col md={12} className="item-head">
              <div className="backbutton" onClick={handleBack}>
                <i class="bx bx-arrow-back"></i>
              </div>
              <div className="bbb">
                {localStorage.getItem("userRole") === "ADMIN" &&
                palletmergeData.requestStatus === 0 ? (
                  <Dropdown
                    className="d-inline-block"
                    isOpen={requestDrp}
                    toggle={() => {
                      setrequestDrp(!requestDrp);
                    }}
                  >
                    <DropdownToggle
                      className="btn header-item waves-effect ddtoggle request-approv-btn"
                      tag="button"
                    >
                      <button>Approve Request</button>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        tag="button"
                        onClick={() => setapproveModel(true)}
                      >
                        Accept request
                      </DropdownItem>
                      <DropdownItem
                        tag="button"
                        onClick={() => setdeclineModel(true)}
                      >
                        Decline request
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                ) : palletmergeData.requestStatus == 0 ? (
                  <button className="newBtn">New</button>
                ) : palletmergeData.requestStatus == 1 ? (
                  <button className="approvedBtn">Request Approved</button>
                ) : (
                  <button className="denyBtn">Request Rejected</button>
                )}
                <Dropdown
                  className="d-inline-block"
                  isOpen={socialDrp}
                  toggle={() => {
                    setsocialDrp(!socialDrp);
                  }}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect ddtoggle"
                    tag="button"
                  >
                    <button> More</button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {/* <DropdownItem tag="a" href="">
                      {" "}
                      <i className="bx bx-copy font-size-16 align-middle mr-1"></i>
                      {"Clone Pallet Merge"}{" "}
                    </DropdownItem>
                    <hr />
                    <DropdownItem tag="a" href="#">
                      {" "}
                      <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                      {"Import Pallet Merge"}{" "}
                    </DropdownItem>
                    <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                      {" "}
                      <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                      {"Export Pallet Merge"}{" "}
                    </DropdownItem> */}
                    <DropdownItem tag="a" onClick={handlePrint}>
                      {" "}
                      <i className="bx bx-printer font-size-16 align-middle mr-1"></i>
                      {"Print Pallet Merge"}{" "}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Modal className="export-modal" isOpen={modalIsOpen}>
                  <ExportModal isClose={() => setModalIsOpen(false)} />
                </Modal>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div className="invoice-body" ref={componentRef}>
                <div className="header">
                  <div className="invoice-header">
                    <img src={dyarco} alt="dyarco" />
                    <div className="title">
                      <h4 style={{ fontSize: "1.6rem" }}>PALLET MERGE</h4>
                    </div>
                  </div>
                </div>
                <div className="invoice-main-body">
                  <Row>
                    <Col md={8}>
                      <Row>
                        <Col md={12}>
                          <h4>Acean Warehousing Company</h4>
                        </Col>
                        {/* <Col md={12}>
                          <span>
                            Aamal Tower, 6th Floor Omar Al Mukhthar Street Doha,
                          </span>
                        </Col>
                        <Col md={12}>
                          <span>Qatar, P.O. Box 3841, Doha, Qatar</span>
                        </Col> */}
                      </Row>
                    </Col>
                    <Col md={4} sm={12} className="mt-3  mb-4 ">
                      <Row>
                        <Col md={4} xs={2}>
                          <strong>Pallet Merge #</strong>
                        </Col>
                        <Col
                          className="text-left"
                          // style={{ whiteSpace: "nowrap" }}
                        >
                          <strong> : {palletmergeData.requestNumber}</strong>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={4} xs={2}>
                          <strong>Request Date</strong>
                        </Col>
                        <Col className="text-left">
                          <strong> : {palletmergeData.requestDate}</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} xs={2}>
                          <strong>Request Status</strong>
                        </Col>
                        <Col className="text-left">
                          <strong
                            className={`${
                              palletmergeData.requestStatus == 0
                                ? "orange"
                                : palletmergeData.requestStatus == 1
                                ? "green"
                                : palletmergeData.requestStatus == 2
                                ? "red"
                                : ""
                            }`}
                          >
                            :{" "}
                            {palletmergeData.requestStatus == 0
                              ? "New"
                              : palletmergeData.requestStatus == 1
                              ? "Approved"
                              : palletmergeData.requestStatus == 2
                              ? "Rejected"
                              : ""}
                          </strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} xs={2}>
                          <strong style={{ whiteSpace: "nowrap" }}>
                            Warehouse
                          </strong>
                        </Col>
                        <Col className="text-left">
                          <strong>
                            {" "}
                            : {palletmergeData.requestWarehouseName}
                          </strong>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="text-left mb-3">
                      <strong>Comments</strong>{" "}
                      <span className="ml-3">
                        <strong>
                          {" "}
                          : {palletmergeData.requestCommentsAdmin}
                        </strong>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <h4>PALLET OWNER</h4>
                    </Col>
                    <Col md={12} className="mb-2">
                      <Link
                      // to={`/singlecustomer/${palletmergeData.palletNumber}`}/
                      >
                        <span
                          style={{
                            color: "#495057",
                            fontSize: "1.2rem",
                          }}
                        >
                          {palletmergeData.requestCustomerName}
                        </span>
                      </Link>
                    </Col>
                    <Col md={12}>
                      <span>{palletmergeData.requestCustomerAddress}</span>
                    </Col>
                    <Col md={12}>
                      <span>{palletmergeData.requestCustomerPhone}</span>
                    </Col>
                    <Col md={12}>
                      <span>{palletmergeData.requestCustomerEmail}</span>
                    </Col>
                    {/* <Col md={12}>
                      <span>{palletmergeData.invoiceEmailTo}</span>
                    </Col> */}
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <table class="table table-condensed table-bordered  fixed_headers">
                        <thead>
                          <tr>
                            <th className="text-center">
                              <span class="text-muted ">#</span>
                            </th>
                            <th> Item & Description</th>
                            <th>Item Unit</th>
                            <th>Item Pallet</th>
                            <th className="text-right">
                              Item Units Per Package
                            </th>
                            <th className="text-right">Item Temperature</th>
                            <th className="text-right">Handling Info</th>
                            {/* <th className="text-right">Item Weight</th> */}
                            <th className="text-right">Item Area</th>
                            <th className="text-right">Cargo Condition</th>
                            <th className="text-right">Remarks</th>
                            <th class="change-sort-order text-right">
                              Invoice No.
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {palletmergeData.itemsList
                            ? palletmergeData.itemsList.map((items, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="text-center">
                                      <span class="text-muted ">
                                        {index + 1}
                                      </span>
                                    </td>
                                    <td>{items.itemCommodityName}</td>
                                    <td>{items.itemUnitsAvailable}</td>
                                    <td>{items.itemPallet}</td>
                                    <td className="text-right">
                                      {items.itemUnitsPerSinglePackage}
                                    </td>
                                    <td className="text-right">
                                      {items.itemTemperature}
                                    </td>
                                    <td className="text-right">
                                      {items.itemHandlingInfo}
                                    </td>
                                    {/* <td className="text-right">
                                      {items.itemTotalWeight}
                                    </td> */}
                                    <td className="text-right">
                                      {items.itemArea}
                                    </td>
                                    <td className="text-right">
                                      {items.itemCargoCondition}
                                    </td>
                                    <td className="text-right">
                                      {items.itemRemarks}
                                    </td>
                                    <td className="text-right">
                                      {items.itemInvoiceNumber}
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                          {/* <tr className="border-none">
                            <td colSpan="7" className="border-none text-right">
                              <strong>Subtotal</strong>
                            </td>
                            <td className="border-none text-right">
                              {palletmergeData.invoiceSubTotal}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="7" className="border-none text-right">
                              <strong>Grand Total</strong>
                            </td>
                            <td className="border-none text-right">
                              <h4 className="m-0">
                                {" "}
                                {palletmergeData.invoiceSubTotal}
                              </h4>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col md={12} className="text-right">
                      <div className="signature">Authorized Signature</div>
                      <span style={{ fontSize: "12px" }}>General Manager</span>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md={12}>
                      <h6>
                        <strong>
                          Terms & condition :{" "}
                          {palletmergeData}
                        </strong>
                      </h6>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col md={12}>
                      <h6>
                        <strong
                          style={{ fontStyle: "italic", color: "#1b6182" }}
                        >
                          {" "}
                          {/* {palletmergeData.invoiceCustomerNotes} */}
                        </strong>
                      </h6>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
        <Modal className="export-modal" isOpen={approveModel}>
          <Row>
            <Col md={12} className="modal-head">
              <div>
                <h5>Pallet Merge Approval</h5>
              </div>
              <div onClick={() => setapproveModel(false)}>
                <i class="bx bx-x"></i>
              </div>
            </Col>
          </Row>
          <hr />
          <AvForm
            className="form"
            onValidSubmit={(e) => handleAcceptRequest(e)}
          >
            {/* <Row>
              <Col md={12}>
                <label>Remarks</label>
                <AvField
                  name="remarks"
                  type="text"
                  onChange={(e) => setRemarks(e.target.value)}
                ></AvField>
              </Col>
            </Row> */}
            <Row>
              <Col md={12}>
                <label>Comments</label>
                <AvField
                  name="comment"
                  type="textarea"
                  onChange={(e) => setComment(e.target.value)}
                ></AvField>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <button type="submit" class="exportbtn">
                  Approve request
                </button>
              </Col>
            </Row>
          </AvForm>
        </Modal>
        <Modal className="export-modal" isOpen={declineModel}>
          <Row>
            <Col md={12} className="modal-head">
              <div>
                <h5>Pallet Merge Decline reason</h5>
              </div>
              <div onClick={() => setdeclineModel(false)}>
                <i class="bx bx-x"></i>
              </div>
            </Col>
          </Row>
          <hr />
          <AvForm
            className="form"
            onValidSubmit={(e) => handleDeclineRequest(e)}
          >
            {/* <Row>
              <Col md={12}>
                <label>Remarks</label>
                <AvField
                  name="remarks"
                  type="text"
                  onChange={(e) => setRemarks(e.target.value)}
                ></AvField>
              </Col>
            </Row> */}
            <Row>
              <Col md={12}>
                <label>Comments</label>
                <AvField
                  name="comment"
                  type="textarea"
                  onChange={(e) => setComment(e.target.value)}
                ></AvField>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <button type="submit" class="exportbtn">
                  Decline request
                </button>
              </Col>
            </Row>
          </AvForm>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default SinglePalletMerge;
