import React, { useRef } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Modal,
} from "reactstrap";
import { useState } from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { apiGet, apiPost } from "../../components/config/apiConfig";
import { toast, ToastContainer } from "react-toastify";
import { BaseUrl } from "../../components/config/BaseUrl";
const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 40px;
  width: 300px;
  border-radius: 5px;
  // border-top-left-radius: 5px;
  // border-bottom-left-radius: 5px;
  // border-top-right-radius: 0;
  // border-bottom-right-radius: 0;
  border: 1px solid #1c6180;
  padding: 0 32px 0 24px;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: #052f855c;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear, props }) => {
  let userRole = localStorage.getItem("userRole");
  let adminId = localStorage.getItem("Id");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [email, setEmail] = useState([]);

  const form = useRef(null);
  const history = useHistory();
  // const [inputitemDiv, setInputitemDiv] = useState([<ItemComponent />]);
  const [approveModel, setapproveModel] = useState(false);

  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    mobile: "",
    organizationId: "",
    role: "",
  });

  // const onAddBtnClick = (event) => {
  //   setInputitemDiv([...inputitemDiv, <ItemComponent />]);
  // };
  // const removeDiv = (index) => {
  //   console.log("eeeee", index);
  //   var array = [...inputitemDiv];
  //   if (index !== -1) {
  //     array.splice(index, 1);
  //     setInputitemDiv(array);
  //   }
  // };
  // useEffect(() => {
  //   setCustomerId(props.history.location.pathname.split("/")[2]);
  //   setCustomerName(props.history.location.pathname.split("/")[3]);
  //   props.setDefaultItems();
  // }, []);
  // let refresh = window.location.reload();
  const handleSubmit = (e) => {
    e.persist();
    let orgId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + "api/v1/add_user";
    var body = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      password: values.password,
      mobile: values.mobile,
      organizationId: orgId,
      role: values.role,
    };
    let resp = apiPost(Url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success("Added User Successfully");
          setapproveModel(false);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
          break;

        default:
          toast.error("Failed to add user");
          setapproveModel(false);
        // setTimeout(() => {
        // }, 2000);
      }
    });
  };

  function handle(e) {
    const newData = { ...values };
    newData[e.target.name] = e.target.value;
    setValues(newData);
  }
  const validateEmail = (e) => {
    if (e.target.value !== "") {
      const Url =
        BaseUrl.apiUrl.baseUrl +
        "api/v1/check_username_available/" +
        e.target.value;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setEmail(resp.response.data.data);
      });
    } else {
      setEmail("");
    }
  };

  return (
    <>
      {" "}
      <ToastContainer
        position="bottom-center"
        // autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div
        className="col-md-12 iitem"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="position-relative">
          <Input
            id="search"
            type="text"
            placeholder="Search User"
            value={filterText}
            onChange={onFilter}
          />
          <span
            className="bx bx-search-alt"
            style={{ position: "absolute", top: "33%", left: "8px" }}
          ></span>
        </div>
        <div className="itembtn">
          {/* <Link to={`online-form`}> */}
          {userRole == "ADMIN" ? (
            <button className="newbtn" onClick={() => setapproveModel(true)}>
              <i class="bx bx-plus"></i> New
            </button>
          ) : (
            ""
          )}
          {/* </Link> */}
          <Modal className="export-modal" isOpen={approveModel}>
            <Row>
              <Col md={12} className="modal-head">
                <div>
                  <h5>Add New User</h5>
                </div>
                <div onClick={() => setapproveModel(false)}>
                  <i class="bx bx-x"></i>
                </div>
              </Col>
            </Row>
            <hr />
            <AvForm className="form" onValidSubmit={(e) => handleSubmit(e)}>
              <Row className="d-flex align-items-center mb-3">
                <Col md={6}>
                  <label style={{ whiteSpace: "pre" }}>First Name</label>
                </Col>
                <Col md={6}>
                  <AvField
                    name="firstname"
                    type="text"
                    placeholder="Enter User Firstname"
                    onChange={(e) => handle(e)}
                    value={values.firstname}
                    required
                  ></AvField>
                </Col>
              </Row>
              <Row className="d-flex align-items-center mb-3">
                <Col md={6}>
                  <label style={{ whiteSpace: "pre" }}>Last Name</label>
                </Col>
                <Col md={6}>
                  <AvField
                    name="lastname"
                    placeholder="Enter User Lastname"
                    type="text"
                    onChange={(e) => handle(e)}
                    value={values.lastname}
                    required
                  ></AvField>
                </Col>
              </Row>
              <Row className="d-flex align-items-center mb-3">
                <Col md={6}>
                  <label style={{ whiteSpace: "pre" }}>Email</label>
                </Col>
                <Col md={6}>
                  <AvField
                    name="email"
                    type="email"
                    placeholder="Enter User Email"
                    onChange={(e) => handle(e)}
                    value={values.email}
                    onBlur={(e) => validateEmail(e)}
                    required
                  ></AvField>
                  {email !== "Email Available" ? (
                    <Col style={{ color: "red" }}>{email}</Col>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row className="d-flex align-items-center mb-3">
                <Col md={6}>
                  <label style={{ whiteSpace: "pre" }}>Enter Password</label>
                </Col>
                <Col md={6}>
                  <AvField
                    name="password"
                    type="password"
                    placeholder="Enter User Password"
                    onChange={(e) => handle(e)}
                    value={values.password}
                    required
                  ></AvField>
                </Col>
              </Row>
              <Row className="d-flex align-items-center mb-3">
                <Col md={6}>
                  <label style={{ whiteSpace: "pre" }}>
                    Enter Contact Number
                  </label>
                </Col>
                <Col md={6}>
                  <AvField
                    name="mobile"
                    type="number"
                    placeholder="Enter User Phone"
                    onChange={(e) => handle(e)}
                    value={values.mobile}
                    required
                    maxLength={10}
                    minLength={10}
                  ></AvField>
                </Col>
              </Row>
              <Row className="d-flex align-items-center mb-3">
                <Col md={6}>
                  <label style={{ whiteSpace: "pre" }}>Enter Role</label>
                </Col>
                <Col md={6}>
                  <AvField
                    name="role"
                    type="select"
                    placeholder="Enter Role"
                    onChange={(e) => handle(e)}
                    value={values.role}
                    required
                  >
                    <option value="" selected disabled>
                      Select Role
                    </option>
                    <option value="USER">User</option>
                    <option value="SALESPERSON">Salesperson</option>
                  </AvField>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <button
                    type="submit"
                    class="exportuserbtn"
                    disabled={email !== "Email Available"}
                  >
                    Add User
                  </button>
                </Col>
              </Row>
            </AvForm>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default FilterComponent;
