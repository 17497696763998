import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import { Formik, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import CreditNoteTable from "./creditNoteTable";
import TermsAndConditions from "./termsAndConditions";
// import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiGet, apiPost } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import loader from "../../assets/loader/load.gif";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { IoIosAddCircle } from "react-icons/io";

//i18n

const AddCreditNoteForm = (props) => {
  const [itemRate, setItemRate] = useState("");
  const [rowsData, setRowsData] = useState([]);
  const [reload, setReload] = useState(false);
  const [subTotalAmount, setSubTotalAmount] = useState("");
  const [adjus, setAdjus] = useState(0);
  const [totalAmount, setTotalAmount] = useState("");
  const [shipChrg, setShipChrg] = useState(0);
  // to get customer list
  const [data, setData] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [salespersonData, setSalespersonData] = useState([]);

  const [tcsList, setTcsData] = useState([]);
  const [tcsTax, setTcsTax] = useState(null);
  const [tcsRate, setTcsRate] = useState(null);
  const [tcsAmount, setTcsAmount] = useState(0);

  const [branchList, setBranchData] = useState([]);

  function changeStatus(e) {
    //setTcs(e.target.value);
    //console.log("tcs:", tcs);
    var index = e.nativeEvent.target.selectedIndex;
    var id = e.nativeEvent.target[index].value;
    console.log("selected text0", e.nativeEvent.target[index].value);
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tcs/${id}`;
    let resp = apiGet(Url);
    var taxrate = 0;
    resp.then((resp) => {
      console.log("tcs data", resp.response.data.data);

      taxrate = resp.response.data.data.taxRate;
      setTcsRate(taxrate);
      setTcsTax(resp.response.data.data.taxName);
      const rowsInput = [...rowsData];

      const sum = rowsInput
        .map((item) => parseInt(item.itemAmount))
        .reduce((prev, curr) => prev + curr, 0);
      setSubTotalAmount(sum);
      const tax = (sum * (taxrate || 0)) / 100;
      setTcsAmount(tax.toFixed(2));
      setTotalAmount(
        parseInt(subTotalAmount ? subTotalAmount : 0) +
          parseInt(shipChrg ? shipChrg : 0) +
          parseInt(adjus ? adjus : 0) +
          parseFloat(tax ? tax.toFixed(2) : 0)
      );
    });
  }

  useEffect(() => {
    function getData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setData(resp.response.data.data);
      });
    }
    function getItemData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setItemData(resp.response.data.data);
      });
    }
    function getSalesPersonData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/users/role/SALESPERSON`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setSalespersonData(resp.response.data.data);
      });
    }
    getSalesPersonData();
    getData();
    getItemData();
  }, []);

  const custOptions = data.map((cust) => ({
    value: cust.customerId,
    label: cust.customerDisplayName,
  }));

  const CustomButton = ({ onClick }) => (
    <Link to="/customer/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Customer
      </button>
    </Link>
  );

  const DropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomButton onClick={console.log("clicked")} />
      </div>
    );
  };

  const customComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: DropdownMenu, // Use your custom dropdown menu
  };
  // const customAddCustomerOption = {
  //   label: (
  //     <Link to="/customer/add">
  //       <span style={{ color: "#408dfb" }}>
  //         <span style={{ marginRight: "8px", color: "#408dfb" }}>
  //           <IoIosAddCircle color="#408dfb" />
  //         </span>{" "}
  //         Add Customer
  //       </span>
  //     </Link>
  //   ),
  //   value: "add-customer",
  //   onClick: () => {
  //     console.log("Add Customer Clicked");
  //     history.push("/customer/add");
  //   },
  //   isFixed: true,
  // };

  // const options = [...custOptions, customAddCustomerOption];
  useEffect(() => {
    function getTcsData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tcs`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setTcsData(resp.response.data.data);
      });
    }
    getTcsData();
  }, []);

  useEffect(() => {
    function getBranchList() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl +
        `api/v1/settings/organization_branch/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setBranchData(resp.response.data.data);
      });
    }
    getBranchList();
  }, []);

  const addTableRows = () => {
    const rowsInput = {
      itemId: 0,
      itemQuantity: "",
      itemRate: "",
      itemDiscountType: 0,
      itemDiscountValue: "",
      itemTaxId: 0,
      itemAmount: 0,
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    const sum = rows
      .map((item) => parseInt(item.estItemAmount))
      .reduce((prev, curr) => prev + curr, 0);
    setSubTotalAmount(sum);
    const tax = (sum * (tcsRate || 0)) / 100;
    setTcsAmount(tax);
    setTotalAmount(
      parseInt(subTotalAmount) +
        parseInt(shipChrg) +
        parseInt(adjus) +
        parseFloat(tax.toFixed(2))
    );
  };
  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    if (rowsInput[index]["itemDiscountType"] == 0) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        ((rowsInput[index]["itemAmount"] || 0) *
          (rowsInput[index]["itemDiscountValue"] || 0)) /
          100;
    }
    if (rowsInput[index]["itemDiscountType"] == 1) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        (rowsInput[index]["itemDiscountValue"] || 0);
    }

    setRowsData(rowsInput);
    const sum = rowsInput
      .map((item) => parseInt(item.itemAmount))
      .reduce((prev, curr) => prev + curr, 0);
    setSubTotalAmount(sum);
    const tax = (sum * (tcsRate || 0)) / 100;
    setTcsAmount(tax.toFixed(2));
    setTotalAmount(
      parseInt(subTotalAmount ? subTotalAmount : 0) +
        parseInt(shipChrg ? shipChrg : 0) +
        parseInt(adjus ? adjus : 0) +
        parseFloat(tcsAmount ? tcsAmount : 0)
    );
    if (name === "itemId") {
      const selectedItem = itemData.find(
        (item) => item.itemId === parseInt(value)
      );
      if (selectedItem) {
        rowsInput[index]["itemRate"] = selectedItem.itemSalesSellingPrice;
      }
    }
  };
  const onHandleBlur = (index, e) => {
    const rowsInput = [...rowsData];
    if (rowsInput[index]["itemDiscountType"] == 0) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        ((rowsInput[index]["itemAmount"] || 0) *
          (rowsInput[index]["itemDiscountValue"] || 0)) /
          100;
    }
    if (rowsInput[index]["itemDiscountType"] == 1) {
      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];

      rowsInput[index]["itemAmount"] =
        rowsInput[index]["itemAmount"] -
        (rowsInput[index]["itemDiscountValue"] || 0);
    }
    setRowsData(rowsInput);
    setTotalAmount(
      parseInt(subTotalAmount) +
        parseInt(shipChrg ? shipChrg : 0) +
        parseInt(adjus ? adjus : 0) +
        parseFloat(tcsAmount ? tcsAmount : 0)
    );
  };

  const history = useHistory();

  const handleCancel = () => {
    setTimeout(() => {
      history.push("/creditnotes");
    }, 1000);
  };
  // item custom button
  const CustomItemButton = ({ onClick }) => (
    <Link to="/additems/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Item
      </button>
    </Link>
  );
  const ItemDropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomItemButton onClick={console.log("clicked")} />
      </div>
    );
  };
  const customItemComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: ItemDropdownMenu, // Use your custom dropdown menu
  };
  const reactSelectOptions = itemData.map((items) => ({
    value: items.itemId,
    label: items.itemName,
  }));
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Formik
        initialValues={{
          mode: "1",
          addretinvoice: false,
          customername: "",
          branch: "",
          reason: "",
          creditnotenumber: "",
          referencenumber: "",
          creditnotedate: "",
          salesperson: "",
          subject: "",
          customernotes: "",
          termsandconditions: "",

          tcsTaxId: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.customername) {
            errors.customername = "* Required";
          }

          if (!values.reason) {
            errors.reason = "* Required";
          }
          return errors;
        }}
        onSubmit={(values) => {
          let orgId = localStorage.getItem("organisationId");
          let url = BaseUrl.apiUrl.baseUrl + "api/v1/credit_notes";
          let body = {
            creditNoteCustomerId: values.customerId,
            creditNoteBillingAddressId: Number,
            creditNoteShippingAddressId: Number,
            creditNoteCustomerRemarks: "",
            creditNoteGstTreatmentId: Number,
            creditNotePlaceOfSupplyId: Number,
            creditNoteReferenceNumber: values.referencenumber,
            creditNoteInvoiceNumber: values.creditnotenumber,
            creditNoteReason: values.reason,
            creditNoteDate: values.creditnotedate,
            creditNoteSalesPersonId: values.salesperson,
            creditNotePriceListId: Number,
            creditNoteIsTaxExclusive: Number,
            creditNoteSubTotal: subTotalAmount,
            creditNoteShippigCharges: shipChrg,
            creditNoteAdjustment: values.adjustment,
            creditNoteRoundOff: Number,
            creditNoteTotal: totalAmount,
            creditNoteCustomerNotes: values.customernotes,
            creditNoteTermsAndConditions: values.termsandconditions,
            creditNoteCustomerName: values.customername,
            creditNoteCustomerShippingAddress: "",
            creditNoteCustomerBillingAddress: "",
            creditNoteCustomerContactNumber: "",

            creditNoteTcsTaxId: values.tcsTaxId,
            creditNoteTcsTaxPercent: tcsRate,
            creditNoteTcsTaxAmount: tcsAmount,
            creditNoteTcsTax: tcsTax,

            organizationId: orgId,
            branchId: values.branch,
            addedByUser: Number,
            editByUser: Number,
            creditNoteItemsList: rowsData,
          };
          let resp = apiPost(url, body);
          resp.then((resp) => {
            setReload(false);
            switch (resp.response.data.code) {
              case 200:
                toast.success(resp.response.data.data);
                setTimeout(() => {
                  history.push("/creditnotes");
                }, 1000);
                break;
              default:
                toast.error(resp.response.data.data);
            }
          });
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="formik" id="addcreditnote">
            <Row>
              <Col md={2}>
                <label className="formik-label"> Customer Name</label>
              </Col>
              <Col md={3} className="formik">
                {/* <Field
                  className="formik-input"
                  as="select"
                  name="customername"
                  onChange={handleChange}
                  value={values.customername}
                >
                  <option value="" disabled selected>
                    Select Customer
                  </option>
                  {data.map((cust) => {
                    return (
                      <option value={cust.customerId}>
                        {cust.customerDisplayName}
                      </option>
                    );
                  })}
                </Field> */}
                <Select
                  options={custOptions}
                  name="customername"
                  components={customComponents}
                  value={
                    custOptions
                      ? custOptions.find(
                          (option) => option.value === values.customername
                        )
                      : ""
                  }
                  onChange={(option) => {
                    setFieldValue("customername", option.value);
                    if (option.value == "add-customer") {
                      console.log(true);
                      history.push("/customer/add");
                      // setPushToCustomer(true);
                    }
                  }}
                />
                {/*<div>
                  <i className="bx bx-search"></i>
                </div>*/}
                {/* <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="customername"
                /> */}
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Branch</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="branch"
                  onChange={handleChange}
                  value={values.branch}
                >
                  <option value=""> Select Branch</option>
                  {branchList.map((br) => (
                    <option value={br.branchId}>{br.branchName}</option>
                  ))}
                </Field>
                <div>
                  {/*<span>Source of supply: Arunachal Pradesh</span>*/}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Reason</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="reason"
                  onChange={handleChange}
                  value={values.reason}
                >
                  <option value="" selected>
                    Select reason
                  </option>
                  <option value="salesreturn">Sales Return</option>
                  <option value="postsalesdiscount">Post Sales Discount</option>
                  <option value="deficiencyinservice">
                    Deficiency in service
                  </option>
                  <option value="correctionininvoice">
                    Correction in invoice
                  </option>
                  <option value="changeinPOS">Change in POS</option>
                  <option value="fpa">
                    Finalisaation of provissional Assessments
                  </option>
                  <option value="others">Others</option>
                </Field>
                <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="reason"
                />
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <label className="formik-label"> Reference#</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="number"
                  name="referencenumber"
                  onChange={handleChange}
                  value={values.referencenumber}
                ></Field>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Credit Note Date</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="date"
                  name="creditnotedate"
                  onChange={handleChange}
                  value={values.creditnotedate}
                ></Field>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={2}>
                <label className="formik-label"> Salesperson</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="salesperson"
                  onChange={handleChange}
                  value={values.salesperson}
                >
                  <option value="" disabled selected>
                    Select Salesperson
                  </option>
                  {salespersonData.map((sp) => (
                    <option value={sp.id}>
                      {sp.firstname + " " + sp.lastname}
                    </option>
                  ))}
                </Field>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={2}>
                <label className="formik-label"> Subject</label>
              </Col>
              <Col md={10}>
                <Field
                  as="textarea"
                  name="subject"
                  className="textarea"
                  onChange={handleChange}
                  value={values.subject}
                ></Field>
              </Col>
            </Row>
            <hr />
            {/* <CreditNoteTable /> */}

            <div className="row clearfix">
              <div className="col-md-12 column">
                <table
                  className="table table-bordered invoice-table"
                  id="tab_logic"
                >
                  <thead>
                    <tr>
                      <th className="text-center"> # </th>
                      <th className="text-left"> ITEM </th>
                      {/*<th className="text-left"> ACCOUNT</th>*/}
                      <th className="text-right"> QUANTITY</th>
                      <th className="text-right"> RATE</th>
                      <th className="text-right"> DISCOUNT </th>
                      <th className="text-right"> TAX</th>
                      <th className="text-right"> AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rowsData.map((item, index) => (
                      <tr id="addr0" key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {/* <select
                              name="itemId"
                              id="itemId"
                              value={item.itemId}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            >
                              <option value="%" selected>
                                Select Item
                              </option>
                              {itemData.map((items, index) => (
                                <option value={items.itemId}>
                                  {items.itemName}
                                </option>
                              ))}
                            </select> */}
                          <Select
                            required
                            name="invItemId"
                            components={customItemComponents}
                            onChange={(selectedOption) => {
                              // Extract the selected item's ID from the selectedOption
                              const selectedItemId = selectedOption.value;
                              const rowsInput = [...rowsData];
                              rowsInput[index]["itemId"] = selectedItemId;
                              setRowsData(rowsInput);
                            }}
                            value={reactSelectOptions.find(
                              (option) =>
                                option.value ==
                                (rowsData[index]
                                  ? rowsData[index]["itemId"]
                                  : "")
                            )}
                            options={reactSelectOptions}
                          />
                        </td>
                        {/*<td>
                            <select
                              name="account"
                              id="tax"
                              value={item.account}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            >
                              <option value="%" disabled selected>
                                Select a tax
                              </option>
                              <option>Advance tax</option>
                              <option>Employee Advance</option>
                              <option>Tax Credit</option>
                            </select>
                            </td>*/}
                        <td>
                          <input
                            type="number"
                            name="itemQuantity"
                            value={item.itemQuantity}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                            className="form-control"
                            placeholder="1.00"
                            onBlur={(e) => onHandleBlur(index, e)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            name="itemRate"
                            value={item.itemRate}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                            className="form-control"
                            placeholder="0.00"
                            onBlur={(e) => onHandleBlur(index, e)}
                          />
                        </td>
                        <td width="10%">
                          <div className="table-inputs">
                            <div>
                              {" "}
                              <input
                                type="text"
                                name="itemDiscountValue"
                                value={item.itemDiscountValue}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                                className="form-control"
                                placeholder="0"
                              />
                            </div>
                            <div>
                              <select
                                name="itemDiscountType"
                                id="itemDiscountType"
                                value={item.itemDiscountType}
                                onChange={(evnt) =>
                                  handleTableChange(index, evnt)
                                }
                              >
                                <option value="0">%</option>
                                <option value="1">Rs.</option>
                              </select>
                            </div>
                          </div>
                        </td>
                        <td>
                          <select
                            name="itemTaxId"
                            id="itemTaxId"
                            disabled
                            value={item.itemTaxId}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                          >
                            <option value="%" disabled selected>
                              Select a tax
                            </option>
                          </select>
                        </td>
                        <td width="15%">
                          <input
                            type="number"
                            name="itemAmount"
                            value={item.itemAmount}
                            onChange={(evnt) => handleTableChange(index, evnt)}
                            className="form-control"
                          />
                        </td>
                        <td className="text-center">
                          <span
                            className="btn btn-outline-danger"
                            onClick={(e) => deleteTableRows(index, e)}
                          >
                            x
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Row>
                  <Col md={6} className="table-left">
                    <div>
                      <span
                        onClick={addTableRows}
                        className="btn btn-default pull-left add-tablerow"
                      >
                        <i class="bx bxs-plus-circle"></i>
                        <span>Add Item</span>
                      </span>
                      {/* <button
                      onClick={this.handleRemoveRow}
                      className="pull-right btn btn-default table-btn"
                    >
                      Clear all rows
                    </button> */}
                    </div>
                    <div className="customernotes">
                      <span>Customer Notes</span>
                      <div>
                        <textarea
                          name="customernotes"
                          rows="4"
                          cols="50"
                          placeholder="Looking forward for your business"
                          onChange={handleChange}
                          value={values.creditNoteCustomerNotes}
                        ></textarea>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="table-right">
                      <Row>
                        <Col className="details">
                          <div>
                            <span className="detail-head">Sub Total</span>
                          </div>
                          <div>
                            <span>{subTotalAmount}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <span className="detail-head">
                              Shipping Charges
                            </span>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="input-tooltip">
                            <input
                              type="number"
                              name="shippingCharge"
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                setShipChrg(values.shippingCharge);
                                let sum =
                                  parseInt(subTotalAmount) +
                                  parseInt(
                                    values.shippingCharge
                                      ? values.shippingCharge
                                      : 0
                                  ) +
                                  parseInt(
                                    values.adjustment ? values.adjustment : 0
                                  ) +
                                  parseFloat(tcsAmount ? tcsAmount : 0);
                                setTotalAmount(sum);
                              }}
                              value={values.shippingCharge}
                            />
                            <i class="bx bx-help-circle"></i>
                          </div>
                        </Col>
                        <Col md={3} className="text-right">
                          <div>
                            <span>{values.shippingCharge}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <span className="detail-head">Adjustment</span>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="input-tooltip">
                            <input
                              type="number"
                              name="adjustment"
                              onChange={handleChange}
                              value={values.adjustment}
                              onBlur={(e) => {
                                handleBlur(e);
                                setAdjus(values.adjustment);
                                let sum =
                                  parseInt(subTotalAmount) +
                                  parseInt(
                                    values.shippingCharge
                                      ? values.shippingCharge
                                      : 0
                                  ) +
                                  parseInt(
                                    values.adjustment ? values.adjustment : 0
                                  ) +
                                  parseFloat(tcsAmount ? tcsAmount : 0);
                                setTotalAmount(sum);
                              }}
                            />
                            <i class="bx bx-help-circle"></i>
                          </div>
                        </Col>
                        <Col md={3} className="text-right">
                          <div>
                            <span>{values.adjustment}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <span className="detail-head">Tcs</span>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="input-tooltip">
                            <select
                              name="tcsTaxId"
                              placeholder="select a
                              tax"
                              onChange={(e) => {
                                changeStatus(e);
                                handleChange(e);
                              }}
                              value={values.tcsTaxId}
                            >
                              <option value="" selected>
                                Select a Tax
                              </option>
                              {tcsList.map((tcs) => (
                                <option value={tcs.id} key={tcs.id}>
                                  {tcs.taxName} - {tcs.taxRate} %
                                </option>
                              ))}
                            </select>
                            <i class="bx bx-help-circle"></i>
                          </div>
                        </Col>
                        <Col md={3} className="text-right">
                          <div>
                            <span>{tcsAmount}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="details">
                          <div>
                            <span className="total">Total</span>
                          </div>

                          <div>
                            <span className="total">{totalAmount}</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <hr />
            <Row>
              <Col md={12}>
                {/* <TermsAndConditions invoice={values.addretinvoice} /> */}
                <div className=" table-bottom">
                  <div className="row clearfix">
                    <div className="col-md-8 termsandcondition">
                      <span>Terms & Conditions</span>
                      <div className="mt-2">
                        <Field
                          className="formik-textarea terms-textarea"
                          as="textarea"
                          name="termsandconditions"
                          onChange={handleChange}
                          value={values.creditNoteTermsAndConditions}
                        ></Field>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
      <div className="bottom-bar">
        <Row>
          <Col md={4}>
            <Row>
              <Col md={4}>
                <button className="btn btn-left ml-3" onClick={handleCancel}>
                  Cancel
                </button>
              </Col>
              <Col md={4}>
                <button
                  type="reset"
                  form="addcreditnote"
                  className="btn btn-left"
                >
                  Clear
                </button>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="printView">
            <span className="print">
              {/* Make Recurring | Print or Preview */}
            </span>
          </Col>
          <Col md={4}>
            <button
              className="btn btn-right float-right mr-3"
              type="submit"
              form="addcreditnote"
            >
              {reload ? (
                <img className="loader" src={loader} alt="loading..." />
              ) : (
                " Save"
              )}
            </button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default AddCreditNoteForm;
