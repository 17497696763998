// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { apiGet, apiPut } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import {
  FETCH_DASHBOARD_DATA,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
  FETCH_DASHBOARD_REVENUE_GRAPH,
  FETCH_DASHBOARD_REVENUE_GRAPH_SUCCESS,
  FETCH_DASHBOARD_REVENUE_GRAPH_FAILURE,
  FETCH_DASHBOARD_RECENT_CHECKIN,
  FETCH_DASHBOARD_RECENT_CHECKOUT,
  FETCH_DASHBOARD_CHECKIN_CLOSE,
  FETCH_DASHBOARD_CHECKIN_CLOSE_FAILURE,
  FETCH_DASHBOARD_CHECKIN_CLOSE_SUCCESS,
  FETCH_DASHBOARD_CHECKOUT_CLOSE,
  FETCH_DASHBOARD_CHECKOUT_CLOSE_FAILURE,
  FETCH_DASHBOARD_SPACE_GRAPH,
  FETCH_DASHBOARD_SPACE_SUCCESS,
  FETCH_DASHBOARD_SPACE_FAILURE,
} from "./actionTypes";

/**
 * Show the rightsidebar
 */

function* getDashboardCards(data) {
  try {
    let allDashboardCards = [];
    let orgId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/dashboard/cards/org/${orgId}/${data.payload}`;
    let resp = apiGet(Url);
    console.log("resp dashbarod", resp);
    yield resp.then((resp) => {
      allDashboardCards = resp.response.data.data;
    });
    yield put({
      type: "FETCH_DASHBOARD_SUCCESS",
      payload: allDashboardCards,
    });
  } catch (error) {
    yield put({
      type: "FETCH_DASHBOARD_FAILURE",
      payload: error,
    });
  }
}
function* getDashboardRevenueGraph(data) {
  try {
    let revenueGraphData = {};
    let orgId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/dashboard/revenue_graph/org/${orgId}/${data.payload}`;
    let resp = apiGet(Url);
    yield resp.then((resp) => {
      revenueGraphData = resp.response.data.data;
    });
    yield put({
      type: "FETCH_DASHBOARD_REVENUE_GRAPH_SUCCESS",
      payload: revenueGraphData,
    });
  } catch (error) {
    yield put({
      type: "FETCH_DASHBOARD_REVENUE_GRAPH_FAILURE",
      payload: error,
    });
  }
}

function* getDashboardSpaceGraph() {
  try {
    let warehouseSpaceGraph = {};
    let orgId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/dashboard/warehouse/graph/org/${orgId}`;
    let resp = apiGet(Url);

    yield resp.then((resp) => {
      warehouseSpaceGraph = resp.response?.data?.data;
    });
    yield put({
      type: "FETCH_DASHBOARD_SPACE_SUCCESS",
      payload: warehouseSpaceGraph,
    });
  } catch (error) {
    yield put({
      type: "FETCH_DASHBOARD_SPACE_FAILURE",
      payload: error,
    });
  }
}
function* getRecentCheckIn() {
  try {
    let recentCheckIn = [];
    let orgId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/dashboard/checkins/recent/org/${orgId}`;
    let resp = apiGet(Url);
    yield resp.then((resp) => {
      recentCheckIn = resp.response.data.data;
    });
    yield put({
      type: "FETCH_DASHBOARD_RECENT_CHECKIN",
      payload: recentCheckIn,
    });
  } catch (error) {
    yield put({
      type: "FETCH_DASHBOARD_FAILURE",
      payload: error,
    });
  }
}
function* getRecentCheckOut() {
  try {
    let recentCheckOut = [];
    let orgId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/dashboard/checkouts/recent/org/${orgId}`;
    let resp = apiGet(Url);
    yield resp.then((resp) => {
      recentCheckOut = resp.response.data.data;
    });
    yield put({
      type: "FETCH_DASHBOARD_RECENT_CHECKOUT",
      payload: recentCheckOut,
    });
  } catch (error) {
    yield put({
      type: "FETCH_DASHBOARD_FAILURE",
      payload: error,
    });
  }
}
function* dashboardCheckInClose(data) {
  try {
    let orgId = localStorage.getItem("organisationId");
    let userId = localStorage.getItem("Id");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/cargo_checkin/${data.payload.checkInId}/${userId}/freeze`;
    var body = {
      checkInStatus: 2,
    };
    const resp = yield call(apiPut, Url, body);

    if (resp) {
      yield call(getRecentCheckIn);
    }
  } catch (error) {
    yield put({
      type: "FETCH_DASHBOARD_CHECKIN_CLOSE_FAILURE",
      payload: error,
    });
  }
}

function* dashboardCheckOutClose(data) {
  try {
    let orgId = localStorage.getItem("organisationId");
    let userId = localStorage.getItem("Id");
    let typeCheckOut = data.payload.checkOutType === "Unit-Based" ? 1 : 2;
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/cargo_checkout/${data.payload.checkOutId}/${userId}/${typeCheckOut}/freeze`;
    var body = {
      checkInStatus: 2,
    };
    const resp = yield call(apiPut, Url, body);

    if (resp) {
      yield call(getRecentCheckOut);
    }
  } catch (error) {
    yield put({
      type: "FETCH_DASHBOARD_CHECKOUT_CLOSE_FAILURE",
      payload: error,
    });
  }
}

/**
 * Watchers
 */

export function* watchgetDashboardDetails() {
  yield takeEvery(FETCH_DASHBOARD_DATA, getDashboardCards);
  yield takeEvery(FETCH_DASHBOARD_REVENUE_GRAPH, getDashboardRevenueGraph);
  yield takeEvery(FETCH_DASHBOARD_DATA, getRecentCheckIn);
  yield takeEvery(FETCH_DASHBOARD_DATA, getRecentCheckOut);
  yield takeEvery(FETCH_DASHBOARD_CHECKIN_CLOSE, dashboardCheckInClose);
  yield takeEvery(FETCH_DASHBOARD_CHECKOUT_CLOSE, dashboardCheckOutClose);
  yield takeEvery(FETCH_DASHBOARD_SPACE_GRAPH, getDashboardSpaceGraph);
}
function* DashboardSaga() {
  yield all([fork(watchgetDashboardDetails)]);
}

export default DashboardSaga;
