import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { fetchCustomers } from "../../store/actions";
import { Row, Col } from "reactstrap";
import Select from "react-select";

import { Formik, Field } from "formik";
import { Link, useHistory } from "react-router-dom";

import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet, apiPost } from "../../components/config/apiConfig";
import loader from "../../assets/loader/load.gif";

import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { IoIosAddCircle } from "react-icons/io";

//i18n

function AddRetainerInvoiceForm() {
  const [itemRate, setItemRate] = useState("");
  const [reload, setReload] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [data, setData] = useState([]);
  const [totalAmount, setTotalAmount] = useState("0.00");
  const [projectData, setProjectData] = useState([]);
  const [branchList, setBranchData] = useState([]);

  useEffect(() => {
    function getProjectData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/project`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setProjectData(resp.response.data.data);
      });
    }
    getProjectData();
  }, []);

  useEffect(() => {
    function getData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        console.log("customer table", resp);
        setData(resp.response.data.data);
      });
    }
    getData();
  }, []);

  useEffect(() => {
    function getBranchList() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl +
        `api/v1/settings/organization_branch/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setBranchData(resp.response.data.data);
      });
    }
    getBranchList();
  }, []);

  const custOptions = data.map((cust) => ({
    value: cust.customerId,
    label: cust.customerDisplayName,
  }));

  // custom add button in Select
  const CustomButton = ({ onClick }) => (
    <Link to="/customer/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Customer
      </button>
    </Link>
  );

  const DropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomButton onClick={console.log("clicked")} />
      </div>
    );
  };

  const customComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: DropdownMenu, // Use your custom dropdown menu
  };

  // const customAddCustomerOption = {
  //   label: (
  //     <Link to="/customer/add">
  //       <span style={{ color: "#408dfb" }}>
  //         <span style={{ marginRight: "8px", color: "#408dfb" }}>
  //           <IoIosAddCircle color="#408dfb" />
  //         </span>{" "}
  //         Add Customer
  //       </span>
  //     </Link>
  //   ),
  //   value: "add-customer",
  //   onClick: () => {
  //     console.log("Add Customer Clicked");
  //     history.push("/customer/add");
  //   },
  //   isFixed: true,
  // };

  // const options = [...custOptions, customAddCustomerOption];

  const addTableRows = () => {
    const rowsInput = {
      retInvItemDescription: "",
      retInvItemAmount: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };

  // const deleteTableRows = (index, e) => {
  //   setRowsData(rowsData.filter((v, i) => i !== index));
  //   console.log("deleted rows", rowsData)
  // };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    const sum = rows
      .map((item) => parseInt(item.retInvItemAmount))
      .reduce((prev, curr) => prev + curr, 0);
    console.log(sum);
    setTotalAmount(sum);
  };

  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
    console.log(rowsInput);
    const sum = rowsInput
      .map((item) => parseInt(item.retInvItemAmount))
      .reduce((prev, curr) => prev + curr, 0);
    console.log(sum);
    setTotalAmount(sum);
  };
  const history = useHistory();
  const handleCancel = () => {
    setTimeout(() => {
      history.push("/retainerinvoice");
    }, 1000);
  };
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Formik
        initialValues={{
          mode: "1",
          addretinvoice: false,
          custName: "",
          branch: "",
          retInvoiceNumber: "",
          reference: "",
          date: "",
          terms: "",
          proName: "",
          customernotes: "",
        }}
        // validate={(values) => {
        //   const errors = {};
        //   if (!values.email) {
        //     errors.email = "Required";
        //   } else if (
        //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        //   ) {
        //     errors.email = "Invalid email address";
        //   }
        //   return errors;
        // }}
        onSubmit={(values) => {
          setReload(true);
          let orgId = localStorage.getItem("organisationId");
          let url = BaseUrl.apiUrl.baseUrl + "api/v1/retainer_invoice";
          let body = {
            retainerInvoiceCustomerId: values.custName,
            retainerInvoiceShippingAddressId: 90,
            retainerInvoiceReferenceNumber: values.reference,
            retainerInvoiceDate: values.date,
            retainerInvoiceProject: values.proName,
            retainerInvoiceTotal: totalAmount,
            retainerInvoiceCustomerNotes: values.customernotes,
            retainerInvoiceTermsAndConditions: values.terms,
            retainerinvoiceCustomerName: values.custName,
            retainerinvoiceCustomerShippingAddress: "",
            retainerinvoiceCustomerContactNumber: "",
            organizationId: orgId,
            branchId: values.branch,
            addedByUser: 1,
            editByUser: 1,
            retainerInvoiceItemsList: rowsData,
          };
          let resp = apiPost(url, body);
          resp.then((resp) => {
            console.log("retainer data is", resp.response.data);
            setReload(false);
            switch (resp.response.data.code) {
              case 200:
                toast.success(resp.response.data.data);
                setTimeout(() => {
                  history.push("/retainerinvoice");
                }, 1000);
                break;
              default:
                toast.error(resp.response.data.data);
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="formik" id="form1">
            <Row>
              <Col md={2}>
                <label className="formik-label"> Customer Name</label>
              </Col>
              <Col md={3} className="">
                {/* <Field
                  className="formik-input"
                  as="select"
                  name="custName"
                  onChange={handleChange}
                  value={values.custName}
                >
                  <option value="" selected disabled>
                    Select Customer
                  </option>

                  {data.map((cust, index) => {
                    return (
                      <option value={cust.customerId}>
                        {cust.customerDisplayName}
                      </option>
                    );
                  })}
                </Field> */}
                <Select
                  options={custOptions}
                  name="custName"
                  components={customComponents}
                  value={
                    custOptions
                      ? custOptions.find(
                          (option) => option.value === values.custName
                        )
                      : ""
                  }
                  onChange={(option) => {
                    setFieldValue("custName", option.value);
                    if (option.value == "add-customer") {
                      console.log(true);
                      history.push("/customer/add");
                      // setPushToCustomer(true);
                    }
                  }}
                  // onBlur={field.onBlur}
                />
                {/* <Select
                  options={options}
                  // className="formik-input"
                  isClearable
                  name="custName"
                  placeholder="Select Customer"
                  value={
                    options.find((item) => item.value == values.custName) || ""
                  }
                  onChange={(selectedOption) => {
                    setFieldValue("custName", selectedOption.label);
                  }}
                  // components={customComponents}
                /> */}
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Branch</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="branch"
                  onChange={handleChange}
                  value={values.branch}
                >
                  <option value=""> Select Branch</option>
                  {branchList.map((br) => (
                    <option value={br.branchId}>{br.branchName}</option>
                  ))}
                </Field>
                <div>
                  {/*<span>Source of supply: Arunachal Pradesh</span>*/}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Retainer Invoice Number</label>
              </Col>
              <Col md={10} className="formik-input-search">
                <Field
                  className="formik-input"
                  type="text"
                  name="retInvoiceNumber"
                  onChange={handleChange}
                  value={values.retInvoiceNumber}
                ></Field>
                <div>
                  <i class="bx bx-cog"></i>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Reference#</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="text"
                  name="reference"
                  onChange={handleChange}
                  value={values.reference}
                ></Field>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Retainer Invoice Date</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="date"
                  name="date"
                  onChange={handleChange}
                  value={values.date}
                ></Field>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Project Name</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="proName"
                  onChange={handleChange}
                  value={values.proName}
                >
                  <option disabled={true} value="">
                    Select a project
                  </option>
                  {projectData.map((pr) => (
                    <option value={pr.id}>{pr.project}</option>
                  ))}
                </Field>
                <div>
                  <span>Select a customer to associate a project.</span>
                </div>
              </Col>
            </Row>
            <hr />
            <div className="row clearfix">
              <div className="col-md-12 column">
                <table
                  className="table table-bordered invoice-table"
                  id="tab_logic"
                >
                  <thead>
                    <tr>
                      <th className="text-left"> Description</th>
                      <th className="text-right"> Amount</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {rowsData.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              name="retInvItemDescription"
                              className="form-control"
                              placeholder="Description"
                              value={data.retInvItemDescription}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              name="retInvItemAmount"
                              className="form-control"
                              value={data.retInvItemAmount}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            />
                          </td>
                          <td className="text-center">
                            <span
                              className="btn btn-outline-danger"
                              onClick={(e) => deleteTableRows(index, e)}
                            >
                              x
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Row>
                  <Col md={6} className="table-left">
                    <div>
                      <div
                        onClick={addTableRows}
                        className=" col-md-4 btn btn-default pull-left add-tablerow"
                      >
                        <i class="bx bxs-plus-circle"></i>
                        <span>Add another line</span>
                      </div>
                    </div>
                    <div className="customernotes">
                      <span>Customer Notes</span>
                      <div>
                        <textarea
                          name="customernotes"
                          rows="4"
                          cols="50"
                          placeholder="Looking forward for your business"
                          onChange={handleChange}
                          value={values.customernotes}
                        ></textarea>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="table-right">
                      <Row>
                        <Col className="details">
                          <div>
                            <span className="total">Total</span>
                          </div>

                          <div>
                            <span className="total">{totalAmount}</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <hr />
            <Row>
              <Col md={12}>
                <div className="table-bottom">
                  <div className="row clearfix">
                    <div className="col-md-8 termsandcondition">
                      <span>Terms & Conditions</span>
                      <div className="mt-2">
                        <Field
                          className="formik-textarea terms-textarea"
                          as="textarea"
                          name="terms"
                          onChange={handleChange}
                          value={values.terms}
                        ></Field>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="mt-3">
                    <span>Want to get paid faster?</span>
                    <div className="mt-2">
                      Configure payment gateways and receive payments online.{" "}
                      Set up Payment Gateway
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
      <div className="bottom-bar">
        <Row>
          <Col md={4}>
            <Row>
              <Col md={4}>
                <button className="btn btn-left ml-3" onClick={handleCancel}>
                  Cancel
                </button>
              </Col>
              <Col md={4}>
                <button type="reset" className="btn btn-left" form="form1">
                  Clear
                </button>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="printView">
            <span className="print">Make Recurring | Print or Preview</span>
          </Col>
          <Col md={4}>
            <button form="form1" className="btn btn-right float-right mr-3">
              {reload ? (
                <img className="loader" src={loader} alt="loading..." />
              ) : (
                "Save"
              )}
            </button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default AddRetainerInvoiceForm;
