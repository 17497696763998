import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import Select from "react-select";
import { AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ErrorMessage, FastField } from "formik";
import { set } from "lodash";
import AddContactPerson from "./addContactPerson";
//i18n

function AddCustomer(currency, formType, customerData, warehouseList) {
  const [moreDetails, setMoreDetails] = useState(false);

  const [gst, setGst] = useState([]);
  const [RTlist, setRTList] = useState([]);
  const [paymentTerm, setPaymentTerm] = useState([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [snames, setSNames] = useState([]);
  // const [reprotingTag, setReportingTag] = useState([]);
  const [email, setEmail] = useState([]);
  // const [selectedPOS, setSelectedPOS] = useState({});
  // const [currency, setCurrency] = useState([]);

  const userName = localStorage.getItem("userName");
  const [requiredValue, setRequired] = useState(false);

  const [data, setData] = useState({
    customerType: "business",
    customerBillingType: "",
    customerFixedQuoteRate: "",
    customerCostType: "",
    customerHandlingType: "",
    customerSalutation: "",
    customerFirstname: "",
    customerLastname: "",
    customerDisplayName: "",
    customerCompanyName: "",
    customerEmail: "",
    customerWorkPhone: "",
    customerMobile: "",
    panNumber: "",
    customerCurrency: "",
    customerOpeningBalance: "",
    customerCreditLimit: "",
    customerHandlingCharges: "",
    customerPaymentTermsId: "",
    customerPaymentTerms: "",
    customerHandlingInCharge: "",
    customerHandlingOutCharge: "",
    customerRemarks: "",
    customerGstTreatmentId: "",
    gstIn: "",
    customerPlaceOfSupplyId: "",
    customerTaxPreference: "0",
    exemptReason: "",
    customerFacebook: "",
    customerTwitter: "",
    customerWebsite: "",
    customerSkypeName: "",
    customerDesignation: "",
    customerDepartment: "",
    customerReportingTags: "",
    customerCostRate: "",
  });
  const [customerWorkPhone, setcustomerWorkPhone] = useState("");
  const [customerMobile, setcustomerMobile] = useState("");
  const [workPhone, setWorkPhone] = useState(true);
  const [mobile, setMobile] = useState(true);
  const [state, setState] = useState([]);
  const [tax, setTax] = useState([]);
  const [contactPerson, setContactPerson] = useState([
    {
      contactCustomerId: null,
      contactId: null,
      contactFirstname: "",
      contactEmail: "",
      contactWorkPhone: "",
    },
  ]);
  const warehouse = warehouseList?.map((item) => {
    return {
      value: item.warehouseId,
      label: item.warehouseName,
      warehouseNumber: item.warehouseNumber,
      // warehouseName: item.warehouseName,
      // warehouseId: item.warehouseId,
    };
  });

  const validateWorkPhone = (e) => {
    console.log("mobile", e);
    e.length <= 11 ? setWorkPhone(false) : setWorkPhone(true);
  };
  // const validateMobile = (e) => {
  //   customerMobile.length <= 6 ? setMobile(false) : setMobile(true);
  // };
  function handle(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;

    setData(newData);
  }

  const handleContactPersonChange = (index, e) => {
    const newContactPersons = [...contactPerson];
    newContactPersons[index][e.target.name] = e.target.value;
    setContactPerson(newContactPersons);
  };
  useEffect(() => {
    console.log("contasct person", contactPerson);
  }, [contactPerson]);
  const addContactPerson = () => {
    setContactPerson([
      ...contactPerson,
      { contactFirstname: "", contactEmail: "", contactWorkPhone: "" },
    ]);
  };
  const removeContactPerson = (index) => {
    const newContactPersons = [...contactPerson];
    newContactPersons.splice(index, 1);
    setContactPerson(newContactPersons);
  };
  function getData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + "api/v1/master/gst_treatments";
    let resp = apiGet(Url);
    resp.then((resp) => {
      setGst(resp.response.data.data);
    });
  }

  function getPaymentTerms() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/payment_terms`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setPaymentTerm(resp.response.data.data);
      }
    });
  }
  // reporting tag list
  function getReportingTagData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/reporting_tags`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      console.log("reporting tag list", resp);
      setRTList(resp.response.data.data);
    });
  }
  function getState() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/states/101`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      // console.log("place list", resp);
      if (resp.response.data.code == 200) {
        setState(resp.response.data.data);
      }
    });
  }
  function getTax() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/taxes`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      // console.log("place list", resp);
      if (resp.response.data.code == 200) {
        setTax(resp.response.data.data);
      }
    });
  }

  const options = RTlist.map((item) => {
    return {
      value: item.id,
      label: item.tagName,
    };
  });
  const reporting_tags = data.customerReportingTags;
  const handleReportingTag = (e) => {
    console.log("e", e);
    setData((oldState) => {
      return {
        ...oldState,
        customerReportingTags: e.label,
      };
    });
  };
  useEffect(() => {
    if (formType == "edit") {
      setData(customerData);
      const selected = customerData.warehouseList?.map((item) => {
        return {
          warehouseNumber: item.warehouseNumber,
          warehouseName: item.warehouseName,
          warehouseId: item.warehouseId,
          customerId: item.customerId,
          customerName: item.customerName,
          customerName: item.customerName,
          customerNumber: item.customerNumber,
          id: item.id,
          organizationId: item.organizationId,
        };
      });
      setSelectedWarehouses(selected);
      const newSelected = customerData.warehouseList?.map((item) => {
        return {
          value: item.warehouseId,
          label: item.warehouseName,
          warehouseNumber: item.warehouseNumber,
          customerId: item.customerId,
          customerName: item.customerName,
          customerName: item.customerName,
          customerNumber: item.customerNumber,
          id: item.id,
          organizationId: item.organizationId,
        };
      });
      setSNames(newSelected);
      setContactPerson(
        customerData.contactPersons?.map((item) => {
          return {
            contactCustomerId: item.contactCustomerId,
            contactId: item.contactId,
            contactFirstname: item.contactFirstname,
            contactEmail: item.contactEmail,
            contactWorkPhone: item.contactWorkPhone,
          };
        })
      );
      // const contact = customerData.contactPersons?.map((item)=>{
      //   return{

      //   }
      // })
      // setReportingTag(data.customerReportingTags);
      setcustomerWorkPhone(customerData.customerWorkPhone);
      setcustomerMobile(customerData.customerMobile);
    }

    getPaymentTerms();
    getData();
    getReportingTagData();
    getState();
    getTax();
  }, [customerData]);
  const validateEmail = (e) => {
    if (e.target.value !== "") {
      const Url =
        BaseUrl.apiUrl.baseUrl +
        "api/v1/check_email_available/" +
        e.target.value;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setEmail(resp.response.data.data);
      });
    } else {
      setEmail("");
    }
  };

  const handleChangeWarehouse = (e) => {
    setSNames(e || []);
    const selected = e?.map((item) => {
      return {
        warehouseNumber: item.warehouseNumber,
        warehouseName: item.label,
        warehouseId: item.value,
        customerId: item.customerId,
        customerName: item.customerName,
        customerName: item.customerName,
        customerNumber: item.customerNumber,
        id: item.id,
        organizationId: item.organizationId,
      };
    });
    setSelectedWarehouses(selected || []);
  };
  useEffect(() => {
    console.log("data edit", data);
  }, [data]);

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    if (userName === "opine_wh_admin@gmail.com") {
      setRequired(true);
    }
    console.log("req validation", requiredValue);
  }, []);

  const handleKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const isNumber = keyCode >= 48 && keyCode <= 57;

    if (isNumber) {
      event.preventDefault();
    }
  };
  return {
    email,
    data,
    setSNames,
    setData,
    setcustomerMobile,
    setcustomerWorkPhone,
    setSelectedWarehouses,
    setMobile,
    setWorkPhone,
    customerWorkPhone,
    customerMobile,
    selectedWarehouses,
    mobile,
    workPhone,
    contactPerson,
    addCustomerRender: (
      <React.Fragment>
        <Row>
          <Col md={6}>
            <label
              className={
                userName == "opine_wh_admin@gmail.com" ||
                selectedWarehouses?.length === 0
                  ? "mandatory-fields"
                  : ""
              }
            >
              Warehouse
            </label>
            <Select
              name="selectedWarehouses"
              value={snames?.map((e) => e)}
              isMulti
              onChange={handleChangeWarehouse}
              options={warehouse}
              required
              validate={{
                required: {
                  value: true,
                  errorMessage: "Enter the data",
                },
              }}
            />
          </Col>
          <Col md={4}>
            <label>Rate / CBM *</label>
            <AvField
              name="customerFixedQuoteRate"
              type="number"
              onChange={(e) => handle(e)}
              value={data.customerFixedQuoteRate}
              required
              validate={{
                required: {
                  value: true,
                  errorMessage: "Enter the data",
                },
              }}
            ></AvField>
          </Col>
          <Col md={4}>
            <label>Cost Rate *</label>
            <AvField
              name="customerCostRate"
              type="number"
              onChange={(e) => handle(e)}
              value={data.customerCostRate}
              required
              validate={{
                required: {
                  value: true,
                  errorMessage: "Enter the data",
                },
              }}
            ></AvField>
          </Col>
          <Col md={4}>
            <label
            // className={
            //   userName == "opine_wh_admin@gmail.com" ||
            //   data.customerBillingType == ""
            //     ? "mandatory-fields"
            //     : ""
            // }
            >
              Billing Type *
            </label>
            <AvField
              name="customerBillingType"
              type="select"
              onChange={(e) => handle(e)}
              value={data.customerBillingType}
              required
              validate={{
                required: {
                  value: true,
                  errorMessage: "Enter the data",
                },
              }}
            >
              <option value="" selected disabled>
                Select Billing Type
              </option>
              <option value="CBM">CBM</option>
              <option value="SQM">SQM</option>
            </AvField>
          </Col>
          <Col md={4}>
            <label>Cost Type *</label>
            <AvField
              name="customerCostType"
              type="select"
              onChange={(e) => handle(e)}
              value={data.customerCostType}
              required
              validate={{
                required: {
                  value: true,
                  errorMessage: "Enter the data",
                },
              }}
            >
              <option value="" selected disabled>
                Select Cost Type
              </option>
              <option value="CBM">CBM</option>
              <option value="SQM">SQM</option>
            </AvField>
          </Col>
          <Col md={4}>
            <label>Handling Type *</label>
            <AvField
              name="customerHandlingType"
              type="select"
              onChange={(e) => handle(e)}
              value={data.customerHandlingType}
              required
              validate={{
                required: {
                  value: true,
                  errorMessage: "Enter the data",
                },
              }}
              /*validate={{
                  required: {
                    value: { requiredValue },
                    errorMessage: "Enter the data",
                  },
                }}*/
            >
              <option value="" selected disabled>
                Select Handling Type
              </option>
              <option value="CBM">CBM</option>
              <option value="SQM">SQM</option>
            </AvField>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col md={2}>
            <label
            // className={
            //   data.customerSalutation == "" ? "mandatory-fields" : ""
            // }
            >
              Salutation
            </label>
            <AvField
              name="customerSalutation"
              type="select"
              onChange={(e) => handle(e)}
              value={data.customerSalutation}
              // required
              validate={{
                required: {
                  value: true,
                  errorMessage: "Enter the data",
                },
              }}
            >
              <option value="" selected disabled>
                Select salutation
              </option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Dr">Dr</option>
            </AvField>
          </Col>
          <Col md={4}>
            <label
            // className={data.customerFirstname == "" ? "mandatory-fields" : ""}
            >
              First Name *
            </label>
            <AvField
              name="customerFirstname"
              type="text"
              placeholder="Enter first name"
              onChange={(e) => handle(e)}
              onKeyPress={handleKeyPress}
              value={data.customerFirstname}
              required
              validate={{
                required: {
                  value: true,
                  errorMessage: "Enter the data",
                },
              }}
            ></AvField>
          </Col>
          <Col md={6}>
            <label>Last Name</label>
            <AvField
              name="customerLastname"
              onKeyPress={handleKeyPress}
              type="text"
              placeholder="Enter last name"
              onChange={(e) => handle(e)}
              value={data.customerLastname}
            ></AvField>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <label
            // className={
            //   data.customerDisplayName == "" ? "mandatory-fields" : ""
            // }
            >
              Customer display name *
            </label>
            <AvField
              name="customerDisplayName"
              type="text"
              placeholder="Enter display name"
              onChange={(e) => handle(e)}
              value={data.customerDisplayName}
              required
              validate={{
                required: {
                  value: true,
                  errorMessage: "Enter the data",
                },
              }}
            ></AvField>
          </Col>
          <Col md={4}>
            <label>Company/Organization</label>
            <AvField
              name="customerCompanyName"
              type="input"
              placeholder="Enter company name"
              onChange={(e) => handle(e)}
              value={data.customerCompanyName}
            ></AvField>
          </Col>
          <Col md={4}>
            <label
            // className={data.customerEmail == "" ? "mandatory-fields" : ""}
            >
              Email address
            </label>
            <AvField
              name="customerEmail"
              type="email"
              placeholder="Enter email address"
              onChange={(e) => {
                handle(e);
                validateEmail(e);
              }}
              value={data.customerEmail}
              // onBlur={(e) => validateEmail(e)}
              // required
              validate={{
                required: {
                  value: true,
                  errorMessage: "Enter the data",
                },
              }}
            ></AvField>
            {email !== "Email Available" ? (
              <Col style={{ color: "red" }}>{email}</Col>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label
            // className={
            //   customerWorkPhone == "" || !workPhone ? "mandatory-fields" : ""
            // }
            >
              Work Phone *
            </label>
            <PhoneInput
              country={"in"}
              inputProps={{
                name: "customerWorkPhone",
                required: true,
              }}
              value={customerWorkPhone}
              onChange={(e) => {
                setcustomerWorkPhone(e);
                validateWorkPhone(e);
              }}
              required
              // onBlur={validateWorkPhone}
            />{" "}
            {!workPhone ? (
              <div className="mt-2">
                <span style={{ color: "#f46a6a", fontSize: "80%" }}>
                  This field is invalid
                </span>
              </div>
            ) : (
              ""
            )}
            {/* <AvField
              name="mobNumber"
              type="number"
              placeholder="&#x260E; Enter phone number"
              onChange={(e) => handle(e)}
              value={data.mobNumber}
            ></AvField> */}
          </Col>
          <Col md={6}>
            <label>Mobile</label>
            {/* <AvField
              name="mobNumber1"
              type="number"
              placeholder="&#x260E; Enter phone number"
              onChange={(e) => handle(e)}
              value={data.mobNumber1}
            ></AvField> */}
            <PhoneInput
              country={"in"}
              inputProps={{
                name: "customerMobile",
                required: true,
              }}
              value={customerMobile}
              onChange={(e) => {
                setcustomerMobile(e);
                // validateWorkPhone(e);
              }}
              // onBlur={(e) => validateMobile(e)}
            />
            {/* {mobile == false ? (
              <div className="mt-2">
                <span style={{ color: "#f46a6a", fontSize: "80%" }}>
                  This field is invalid
                </span>
              </div>
            ) : (
              ""
            )} */}
            {!moreDetails ? (
              <div>
                <span
                  class="copy-address"
                  onClick={() => setMoreDetails(!moreDetails)}
                >
                  Add more details
                </span>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
        {moreDetails ? (
          <Row>
            <Col md={4}>
              <label>Skype Name/Number</label>
              <AvField
                name="customerSkypeName"
                type="input"
                placeholder="Enter Skype name / number"
                onChange={(e) => handle(e)}
                value={data.customerSkypeName}
              ></AvField>
            </Col>
            <Col md={4}>
              <label>Designation</label>
              <AvField
                name="customerDesignation"
                type="input"
                placeholder="Enter designation"
                onChange={(e) => handle(e)}
                value={data.customerDesignation}
              ></AvField>
            </Col>
            <Col md={4}>
              <label>Department</label>
              <AvField
                name="customerDepartment"
                type="input"
                placeholder="Enter department"
                onChange={(e) => handle(e)}
                value={data.customerDepartment}
              ></AvField>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row>
          <Col md={6}>
            <label>Website</label>
            <AvField
              name="customerWebsite"
              type="input"
              placeholder="Enter website"
              onChange={(e) => handle(e)}
              value={data.customerWebsite}
            ></AvField>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr />
          </Col>
        </Row>

        <Row>
          {/* <Col md={3}>
            <label>GST Treatment</label>
            <AvField
              name="customerGstTreatmentId"
              type="select"
              onChange={(e) => handle(e)}
              value={data.customerGstTreatmentId}
            >
              <option value="" selected disabled>
                Select GST Treatment
              </option>
              {gst.map((gstTreatment) => {
                return (
                  <option value={gstTreatment.id}>
                    {gstTreatment.gstTreatmentName}
                  </option>
                );
              })}
            </AvField>
          </Col>
          {data.customerGstTreatmentId == "" ||
          data.customerGstTreatmentId == "3" ||
          data.customerGstTreatmentId == "4" ||
          data.customerGstTreatmentId == "5" ? (
            ""
          ) : (
            <Col md={3}>
              <label>GSTIN/UIN</label>
              <AvField
                name="gstIn"
                type="input"
                placeholder="Enter GSTIN/UIN "
                onChange={(e) => handle(e)}
                value={data.gstIn}
                required
              ></AvField>
            </Col>
          )} */}
          {/* <Col md={4}>
            <label>PAN Card</label>
            <AvField
              name="panNumber"
              type="input"
              placeholder="PAN number"
              onChange={(e) => handle(e)}
              value={data.panNumber}
            ></AvField>
          </Col> */}
          <Col md={4}>
            <label>Place of supply</label>
            <AvField
              name="customerPlaceOfSupplyId"
              type="select"
              onChange={(e) => handle(e)}
              value={data.customerPlaceOfSupplyId}
            >
              <option value="" selected disabled>
                Select Place of supply
              </option>
              {state.map((pos) => (
                <option value={pos.stateId}>
                  [{pos.stateCode}] - {pos.stateName}
                </option>
              ))}
            </AvField>
          </Col>
          <Col md={4}>
            <label
            // className={data.customerCurrency == "" ? "mandatory-fields" : ""}
            >
              Currency
            </label>
            <AvField
              name="customerCurrency"
              type="select"
              onChange={(e) => handle(e)}
              value={data.customerCurrency}
              // required
              // validate={{
              //   required: {
              //     value: true,
              //     errorMessage: "Enter the data",
              //   },
              // }}
            >
              <option value="" selected disabled>
                Select currency
              </option>
              {currency.map((cur) => (
                <option>
                  {cur.currencyCode}-{cur.currencyName}
                </option>
              ))}
            </AvField>
          </Col>
          <Col md={4}>
            <label>Tax Preference</label>
            <AvRadioGroup inline name="customerTaxPreference">
              <AvRadio label="Taxable" value="0" onChange={(e) => handle(e)} />
              <AvRadio
                label="Tax Exempt"
                value="1"
                onChange={(e) => handle(e)}
              />
            </AvRadioGroup>
          </Col>
          {data.customerTaxPreference == "1" ? (
            <Col md={3}>
              <label>Excemption Reason</label>
              <AvField
                name="exemptReason"
                type="input"
                placeholder="Exemption reason"
                onChange={(e) => handle(e)}
                value={data.exemptReason}
                // required
              ></AvField>
            </Col>
          ) : (
            ""
          )}

          <Col md={4}>
            <label>Opening Balance</label>
            <AvField
              name="customerOpeningBalance"
              type="number"
              placeholder="Opening balance"
              onChange={(e) => handle(e)}
              value={data.customerOpeningBalance}
            ></AvField>
          </Col>

          <Col md={4}>
            <label>Credit limit</label>
            <AvField
              name="customerCreditLimit"
              type="number"
              placeholder="Set credit limit"
              onChange={(e) => handle(e)}
              value={data.customerCreditLimit}
            ></AvField>
          </Col>
          <Col md={4}>
            <label
            // className={
            //   data.customerPaymentTermsId == "" ? "mandatory-fields" : ""
            // }
            >
              Payment terms
            </label>
            <AvField
              name="customerPaymentTermsId"
              type="select"
              onChange={(e) => handle(e)}
              value={data.customerPaymentTermsId}
              // required
              // validate={{
              //   required: {
              //     value: true,
              //     errorMessage: "Enter the data",
              //   },
              // }}
            >
              <option value="" selected>
                Select Payment terms
              </option>
              {paymentTerm.map((payment) => (
                <option value={payment.paymentTermId}>
                  {payment.paymentTermName}
                </option>
              ))}
            </AvField>
          </Col>

          <Col md={4}>
            <label>Handling In</label>
            <AvField
              name="customerHandlingInCharge"
              type="number"
              placeholder="Handling In"
              onChange={(e) => handle(e)}
              value={data.customerHandlingInCharge}
            ></AvField>
          </Col>

          <Col md={4}>
            <label>Handling Out</label>
            <AvField
              name="customerHandlingOutCharge"
              type="number"
              placeholder="Handling Out"
              onChange={(e) => handle(e)}
              value={data.customerHandlingOutCharge}
            ></AvField>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h4>Contact Person</h4>
          </Col>
          {contactPerson?.map((contact, index) => (
            <AddContactPerson
              key={index}
              contactPerson={contact}
              handleDataChange={(e) => handleContactPersonChange(index, e)}
              addAnotherContact={addContactPerson}
              removeContact={() => removeContactPerson(index)}
              isLastItem={index === contactPerson.length - 1}
            />
          ))}
        </Row>
        <Row>
          <Col md={12}>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <label>Facebook</label>
            <AvField
              name="customerFacebook"
              type="text"
              placeholder="Enter facebook"
              onChange={(e) => handle(e)}
              value={data.customerFacebook}
            ></AvField>
          </Col>
          <Col md={5}>
            <label>Twitter</label>
            <AvField
              name="customerTwitter"
              type="text"
              placeholder="Enter Twitter"
              onChange={(e) => handle(e)}
              value={data.customerTwitter}
            ></AvField>
          </Col>
          <Col md={12}>
            <hr />
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <label>Reporting Tag</label>
            {/* <Select
              options={options}
              name="customerReportingTags"
              onChange={(e) => handleReportingTag(e)}
              defaultValue={options?.filter(
                (option) => option.label == reporting_tags
              )}
            /> */}
            <AvField
              name="customerReportingTags"
              type="select"
              onChange={(e) => handle(e)}
              value={data.customerReportingTags}
            >
              <option value="" selected disabled>
                Select Reporting Tag
              </option>
              {RTlist.map((tag) => (
                <option value={tag.id}>{tag.tagName}</option>
              ))}
            </AvField>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <label>Remarks</label>
            <AvField
              name="customerRemarks"
              type="textarea"
              placeholder="Due on receipt"
              onChange={(e) => handle(e)}
              value={data.customerRemarks}
            ></AvField>
          </Col>
        </Row>
      </React.Fragment>
    ),
  };
}
export default AddCustomer;
