import React, { useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import ItemComponent from "./ItemComponent";
import { useEffect } from "react";
import { setDefaultItems } from "../../store/actions";
import { connect } from "react-redux";
import { BaseUrl } from "../../components/config/BaseUrl";
import { useHistory, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dyarco from "../../assets/dyarco/awc.png";
function OnlineForm(props) {
  const form = useRef(null);
  const history = useHistory();
  const [success, setSuccess] = useState();
  const [inputitemDiv, setInputitemDiv] = useState([<ItemComponent />]);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [organisationID, setOrganisationID] = useState("");
  const [token, setToken] = useState("");
  const [activity, setActivity] = useState("");
  const [deliveryTo, setDeliveryTo] = useState("");
  const [loadingFrom, setLoadingFrom] = useState("");
  const [vehichleNo, setVehichleNo] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isValid, setIsValid] = useState("");
  const [showDiv, setShowDiv] = useState(false);
  const onAddBtnClick = (event) => {
    setInputitemDiv([...inputitemDiv, <ItemComponent />]);
  };
  const removeDiv = (index) => {
    var array = [...inputitemDiv];
    if (index !== -1) {
      array.splice(index, 1);
      setInputitemDiv(array);
    }
  };
  function checkFormValid(token) {
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/public/v1/validate_order_link_token/${token}`;
    fetch(Url)
      .then((response) => response.json())
      .then((data) => {
        setIsPageLoading(false);
        setIsValid(data.data);
      });
  }
  useEffect(() => {
    setCustomerId(props.history.location.pathname.split("/")[2]);
    setOrganisationID(props.history.location.pathname.split("/")[3]);
    setToken(props.history.location.pathname.split("/")[4]);
    checkFormValid(props.history.location.pathname.split("/")[4]);
    props.setDefaultItems();
  }, []);
  useEffect(() => {
    if (isValid === "valid") {
      setTimeout(() => {
        setIsValid("");
        setShowDiv(true);
      }, 2000);
    }
  }, [isValid]);
  const handleSubmit = (e) => {
    e.persist();
    const Url = BaseUrl.apiUrl.baseUrl + "api/public/v1/online_order";
    var body = {
      orderDate: date,
      orderTime: time,
      orderType: parseInt(activity),
      orderCustomerId: customerId,
      orderCustomerEmail: "test",
      orderCustomerNumber: "test",
      orderCustomerPhone: "test",
      orderCustomerName: customerName,
      orderCustomerAddress: "test address",
      isAgreeTermsAndConditions: checkbox ? 1 : 0,
      organizationId: organisationID,
      loadingFrom: loadingFrom,
      deliveryTo: deliveryTo,
      vehicleNo: vehichleNo,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
      itemsList: props.onlineFormCustomerItems,
    };
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        switch (data.code) {
          case 200:
            setSuccess(data);
            toast.success(`${data.message} `);
            // form.current.reset();
            // setTimeout(() => {
            //   history.push("/login");
            // }, 1000);
            break;

          default:
            toast.error(data.message);
        }
      });
  };
  return (
    <>
      {isPageLoading ? (
        <div className="page-load-center">
          <div className="loading-content">
            <div
              className="spinner-grow text-success"
              style={{ width: "6rem", height: "6rem" }}
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <div style={{ fontSize: "18px" }}>Checking the link is valid</div>
          </div>
        </div>
      ) : isValid === "valid" ? (
        <div className="page-load-center">
          <div className="loading-content">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6em"
              height="6em"
              fill="#81c345b3"
              class="bi bi-check-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
            <div style={{ fontSize: "18px" }}>
              Link is valid. Redirecting to form please wait....
            </div>
          </div>
        </div>
      ) : isValid === "expired" ? (
        <div className="page-load-center">
          <div className="loading-content">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6em"
              height="6em"
              fill="#e98383"
              class="bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
            <div style={{ fontSize: "18px" }}>
              Link Expired. Please contact with your warehouse assistants.
            </div>
          </div>
        </div>
      ) : showDiv ? (
        <div className="online-from-container">
          <ToastContainer
            position="bottom-center"
            // autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          />
          <Row>
            <Col md={12} className="text-center">
              <img src={dyarco} alt="dyarco" className="slip-image" />
            </Col>
            <Col md={12} className="mb-2 text-center">
              <h4>Booking Order</h4>
            </Col>
          </Row>
          <AvForm
            className="form online-form"
            onValidSubmit={(e) => handleSubmit(e)}
            ref={form}
          >
            {/* <Row className="d-flex align-items-center mb-3">
          <Col md={2}>
            <label>Date & Time</label>
          </Col>
          <Col md={4}>
            <AvField
              name="date"
              type="date"
              placeholder="Enter date"
              onChange={(e) => setDate(e.target.value)}
              value={date}
              required
            ></AvField>
          </Col>
          <Col md={4}>
            <AvField
              name="time"
              type="time"
              placeholder="Enter time"
              onChange={(e) => setTime(e.target.value)}
              value={time}
              required
            ></AvField>
          </Col>
        </Row> */}
            {/* <Row className="d-flex align-items-center mb-3">
          <Col md={2}>
            <label>Customer Name</label>
          </Col>
          <Col md={10}>
            <AvField
              name="customerName"
              type="text"
              placeholder="Enter customer name"
              onChange={(e) => setCustomerName(e.target.value)}
              value={customerName}
              disabled
            ></AvField>
          </Col>
        </Row> */}
            <Row className="d-flex align-items-center mb-3">
              <Col md={2}>
                <label className="mandatory-fields">Activity*</label>
              </Col>
              <Col md={6}>
                <AvField
                  name="activity"
                  type="select"
                  onChange={(e) => setActivity(e.target.value)}
                  value={activity}
                  required
                >
                  <option value="" selected disabled>
                    Select activity
                  </option>
                  <option value="1">Goods In</option>
                  {/* <option value="2">Goods out</option> */}
                </AvField>
              </Col>
            </Row>
            <Row className="d-flex align-items-center mb-3">
              <Col md={2}>
                <label>Vehicle #</label>
              </Col>
              <Col md={5}>
                <AvField
                  name="itemVehicleNo"
                  type="text"
                  placeholder="Vehicle No"
                  onChange={(e) => setVehichleNo(e.target.value)}
                  value={vehichleNo}
                  // required
                ></AvField>
              </Col>
            </Row>
            <Row className="d-flex align-items-center mb-3 mt-3">
              <Col md={2}>
                <label className="mandatory-fields">Loading From*</label>
              </Col>
              <Col md={5}>
                <AvField
                  name="itemProceedFrom"
                  type="text"
                  placeholder="Loading From"
                  onChange={(e) => setLoadingFrom(e.target.value)}
                  value={loadingFrom}
                  required
                ></AvField>
                .
              </Col>
              {/* <Col md={5}>
          <AvField
            name="itemProceedTo"
            type="text"
            placeholder="Proceed To"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemProceedTo"]
                : ""
            }
            // required
          ></AvField>
        </Col> */}
            </Row>
            <Row className="d-flex align-items-center mb-3 mt-3">
              <Col md={2}>
                <label className="mandatory-fields">Delivery To*</label>
              </Col>
              <Col md={5}>
                <AvField
                  name="DeliveryTo"
                  type="text"
                  placeholder="Delivery To"
                  onChange={(e) => setDeliveryTo(e.target.value)}
                  value={deliveryTo}
                  required
                ></AvField>
              </Col>
              {/* <Col md={5}>
          <AvField
            name="itemLocationTo"
            type="text"
            placeholder="Location To"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemLocationTo"]
                : ""
            }
            // required
          ></AvField>
        </Col> */}
            </Row>
            <hr />
            <Row>
              <Col md={12}>
                <div className="itemSection">
                  {inputitemDiv.map((items, key) => {
                    return (
                      <ItemComponent
                        array={inputitemDiv}
                        key={items.length}
                        delete={removeDiv}
                        index={key}
                        add={onAddBtnClick}
                        formSuccess={success?.status === "Success"}
                        formActivity={activity}
                      />
                    );
                  })}
                </div>
              </Col>
            </Row>
            <Row>
              <span className="online-note">
                *Note : Customer should send the order request 24hr prior, to
                avoid any inconvenience or discrepancy.
              </span>
            </Row>
            <Row>
              <Col md={8}>
                <div className="d-flex">
                  <AvField
                    name="termsCheckBox"
                    type="checkbox"
                    placeholder="Enter customer name"
                    onChange={(e) => setCheckbox(!checkbox)}
                    value={checkbox}
                  ></AvField>
                  <label>
                    I agree to the Standrad{" "}
                    {/* <Link to="/terms" target="__blank"> */}
                    Terms & conditions
                    {/* </Link>{" "} */}
                    as laid down by the AWC Policies
                  </label>
                </div>
              </Col>
              <Col md={4}>
                <button
                  type="submit"
                  className={
                    !checkbox
                      ? "online-form-submitBtn-disabled"
                      : "online-form-submitBtn"
                  }
                  style={{
                    background: success?.status === "Success" ? "green" : "",
                  }}
                  disabled={!checkbox || success?.status === "Success"}
                >
                  {success?.status === "Success" ? "Form Submitted" : "Submit"}
                </button>
              </Col>
            </Row>
          </AvForm>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
const mapStatetoProps = (state) => {
  const { onlineFormCustomerItems } = state.OnlineFormTable;
  return { onlineFormCustomerItems };
};
export default connect(mapStatetoProps, { setDefaultItems })(OnlineForm);
