import {
  ADD_TABLE_DATA,
  UPDATE_ROW_TOTAL,
  DELETE_TABLE_ROW,
  CALCULATE_SHIPPING_CHARGE,
  CALCULATE_ADJUSTMENT_CHARGE,
  CALCULATE_TOTALS,
  POPULATE_TABLE_DATA,
  CLEAR_DATA,
} from "./actionTypes";

const INIT_STATE = {
  tableRowDataGt: [],
  /*subTotal: 0,
  shippingCharge: 0,
  adjustment: 0,
  total: 0,*/
};

const TableDataGt = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_TABLE_DATA:
      var tableData = {
        id: 0,
        itemId: 0,
        itemName: "",
        itemDescription: "",
        sourceStock: "",
        // itemDetails: action.payload.itemName,
        destinationStock: "",
        transferQuantity: "",
      };

      return {
        ...state,
        tableRowDataGt: [...state.tableRowDataGt, tableData],
        // subTotal: oldSubTotal,
      };

    case UPDATE_ROW_TOTAL:
      var oldData = [...state.tableRowDataGt];
      oldData[action.payload.index][action.payload.value.target.name] =
        action.payload.value.target.value;
      oldData[action.payload.index]["invItemAmount"] =
        (oldData[action.payload.index]["invItemQuantity"] || 0) *
          (oldData[action.payload.index]["invItemRate"] || 0) +
        (oldData[action.payload.index]["invItemTaxPercent"] || 0);
      switch (oldData[action.payload.index]["invItemDiscountType"]) {
        case "1":
          oldData[action.payload.index]["invItemAmount"] =
            (oldData[action.payload.index]["invItemAmount"] || 0) -
            ((oldData[action.payload.index]["invItemAmount"] || 0) *
              (oldData[action.payload.index]["invItemDiscountValue"] || 0)) /
              100;
          break;
        case "2":
          oldData[action.payload.index]["invItemAmount"] =
            (oldData[action.payload.index]["invItemAmount"] || 0) -
            (oldData[action.payload.index]["invItemDiscountValue"] || 0);
          break;
      }
      var newSubTotal = oldData
        .map((item) => parseInt(item.invItemAmount))
        .reduce((prev, curr) => prev + curr, 0);
      var newTotal =
        newSubTotal +
        parseInt(state.shippingCharge) +
        parseInt(state.adjustment);
      return {
        ...state,
        tableRowDataGt: oldData,
        subTotal: newSubTotal,
        total: newTotal,
      };
    case CALCULATE_SHIPPING_CHARGE:
      var value =
        action.payload.target.value != "" ? action.payload.target.value : 0;
      state.shippingCharge = value;
      state.total =
        parseInt(state.subTotal) +
        parseInt(state.shippingCharge) +
        parseInt(state.adjustment);
      return {
        ...state,
      };
    case CALCULATE_ADJUSTMENT_CHARGE:
      var value =
        action.payload.target.value != "" ? action.payload.target.value : 0;
      state.adjustment = value;
      state.total =
        parseInt(state.subTotal) +
        parseInt(state.shippingCharge) +
        parseInt(state.adjustment);
      return {
        ...state,
      };
    case DELETE_TABLE_ROW:
      var oldData = [...state.tableRowDataGt];
      oldData.splice(action.payload, 1);
      var oldSubTotal = oldData
        .map((item) => parseInt(item.invItemAmount))
        .reduce((prev, curr) => prev + curr, 0);
      state.total =
        oldSubTotal +
        parseInt(state.shippingCharge) +
        parseInt(state.adjustment);
      return {
        ...state,
        tableRowDataGt: oldData,
        subTotal: oldSubTotal,
      };
    case CALCULATE_TOTALS:
      var oldData = [...state.tableRowDataGt];
      state.subTotal = oldData
        .map((item) => parseInt(item.invItemAmount))
        .reduce((prev, curr) => prev + curr, 0);
      state.total =
        parseInt(state.subTotal) +
        parseInt(state.shippingCharge) +
        parseInt(state.adjustment);
      return {
        ...state,
      };
    case POPULATE_TABLE_DATA:
      // var oldData = [...state.tableRowData];
      state.tableRowDataGt = action.payload.itemList.map((items) => {
        return {
          id: items.id,
          itemId: items.itemId,
          itemName: items.itemName,
          itemDescription: items.itemDescription,
          // itemDetails: action.payload.itemName,
          sourceStock: items.sourceStock,
          destinationStock: items.destinationStock,
          transferQuantity: items.transferQuantity,
        };
      });
      //state.subTotal = action.payload.subtotal;
      ///state.shippingCharge = action.payload.shippingcharge;
      //state.adjustment = action.payload.adjustment;
      //state.total = action.payload.total;
      return {
        ...state,
      };
    case CLEAR_DATA:
      return {
        ...state,
        tableRowDataGt: [],
        subTotal: 0,
        shippingCharge: 0,
        adjustment: 0,
        total: 0,
      };
    default:
      return state;
  }
};
export default TableDataGt;
