import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
import ItemButtons from "./itemButtons";
import ExportModal from "./exportModal";

const SingleInventoryView = (props) => {
  const [socialDrp, setsocialDrp] = useState(false);
  const [expandDiv, setExpandDiv] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleExpandDiv = () => {
    setExpandDiv(!expandDiv);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12} className="item-head">
              <div>
                <h5>Adjustment Details </h5>
              </div>
              <div className="bbb">
                <button>
                  <i class="bx bx-paperclip"></i>
                </button>
                <button>
                  <i class="bx bx-pencil"></i>
                </button>
                <Dropdown
                  className="d-inline-block"
                  isOpen={socialDrp}
                  toggle={() => {
                    setsocialDrp(!socialDrp);
                  }}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect ddtoggle"
                    tag="button"
                  >
                    <button>
                      {" "}
                      {/* <i class="bx bx-menu"></i> */}
                      More
                    </button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem tag="a" href="">
                      {" "}
                      <i className="bx bx-copy font-size-16 align-middle mr-1"></i>
                      {props.t("Clone Item")}{" "}
                    </DropdownItem>
                    <hr />
                    <DropdownItem tag="a" href="">
                      {" "}
                      <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                      {props.t("Import Item")}{" "}
                    </DropdownItem>
                    <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                      {" "}
                      <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                      {props.t("Export Item")}{" "}
                    </DropdownItem>
                    <hr />
                    <DropdownItem tag="a" href="">
                      {" "}
                      <i className="bx bx-trash font-size-16 align-middle mr-1"></i>
                      {props.t("Delete Item")}{" "}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Modal className="export-modal" isOpen={modalIsOpen}>
                  <ExportModal isClose={() => setModalIsOpen(false)} />
                </Modal>
              </div>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <AvForm className="form itemview">
                <div>
                  <Row>
                    <Col md={4}>
                      <span className="head">Date</span>
                    </Col>
                    <Col md={8}>20/11/2021</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Reason</span>
                    </Col>
                    <Col md={8}>Stolen goods</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Status</span>
                    </Col>
                    <Col md={8}>
                      <span className="status">Adjusted</span>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Account</span>
                    </Col>
                    <Col md={8}>Cost of Goods Sold</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Adjustment Type</span>
                    </Col>
                    <Col md={8}>Quantity</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Adjusted By</span>
                    </Col>
                    <Col md={8}>sumi.opine</Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={4}>
                      {" "}
                      <span className="head">Created Time</span>
                    </Col>
                    <Col md={8}>20/11/2021 11:17 AM</Col>
                  </Row>
                </div>
                <hr />
                <div>
                  <Row>
                    <Col md={12}>
                      <span className="itempur-info">Adjusted Items</span>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col md={10}>
                      <table
                        className="table table-bordered invoice-table"
                        id="tab_logic"
                      >
                        <thead>
                          <tr>
                            <th className="text-center"> # </th>
                            <th className="text-left"> ITEM DETAILS</th>
                            <th className="text-right"> QUANTITY ADJUSTED</th>
                            <th className="text-right"> COST PRICE</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr id="addr0">
                            <td>1</td>
                            <td>
                              <input
                                type="text"
                                name="itemdetails"
                                value="Ram"
                                className="form-control"
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="qtyadjusted"
                                value="10"
                                className="form-control"
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="costprice"
                                value="₹500.00"
                                className="form-control"
                                disabled
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <hr />
                  <div className="display-journal" onClick={handleExpandDiv}>
                    <span className="itempur-info">Display Journal</span>
                    {expandDiv ? (
                      <i class="bx bx-chevron-up"></i>
                    ) : (
                      <i class="bx bx-chevron-down "></i>
                    )}
                  </div>
                </div>
                <hr />
                {expandDiv ? (
                  <Row>
                    <Col md={12} className="mb-3">
                      <span>
                        Amount is displayed in your base currency{" "}
                        <span className="inr">INR</span>{" "}
                      </span>
                    </Col>
                    <Col md={12}>
                      <table
                        className="table table-bordered invoice-table"
                        id="tab_logic"
                      >
                        <thead>
                          <tr>
                            <th className="text-left"> ACCOUNT </th>
                            <th className="text-left"> BRANCH</th>
                            <th className="text-right"> DEBIT</th>
                            <th className="text-right"> CREDIT</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Cost of Goods Sold</td>
                            <td>Head Office</td>
                            <td>0.00</td>
                            <td>5,000.00</td>
                          </tr>
                          <tr>
                            <td>Inventory Asset</td>
                            <td>Head Office</td>
                            <td>5,000.00</td>
                            <td>0.00</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td className="bold">5,000.00</td>
                            <td className="bold">5,000.00</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(SingleInventoryView);
