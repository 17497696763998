import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Modal,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import UserPasswordChange from "./UserPasswordChange";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { toast, ToastContainer } from "react-toastify";
import { BaseUrl } from "../../config/BaseUrl";
import { apiGet, apiPut } from "../../config/apiConfig";

const ProfileMenu = (props) => {
  let Id = localStorage.getItem("Id");
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      setusername(localStorage.getItem("userName"));
    }
  }, [props.success]);
  // user edit

  const [passwordModel, setpasswordModel] = useState(false);
  const [approveModel, setapproveModel] = useState(false);
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    mobile: "",
    organizationId: "",
  });
  const updateUser = (e) => {
    e.persist();
    let orgId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + "api/v1/user_profile/" + Id;
    var body = {
      firstname: values.firstname,
      lastname: values.lastname,
      mobile: values.mobile,
    };
    let resp = apiPut(Url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success("Edited User Successfully");
          setapproveModel(false);
          break;

        default:
          toast.error("Failed to update user");
          setapproveModel(false);
      }
    });
  };
  function handle(e) {
    const newData = { ...values };
    newData[e.target.name] = e.target.value;
    setValues(newData);
  }
  function getUserDataById() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/user_profile/` + Id;
    let resp = apiGet(Url);
    resp.then((resp) => {
      var datas = resp.response.data.data;
      setValues(datas);
    });
  }
  // useEffect(() => {
  //   getUserDataById();
  // }, [Id]);

  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        // autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <Row>
            <Col md={9}>
              <div className="user-profile">
                <div>
                  <span className="d-none d-xl-inline-block ml-2 mr-1">
                    {username}
                  </span>
                </div>
                <div>
                  <span className="d-none d-xl-inline-block ml-2 mr-1">
                    Opine infotech Pvt Ltd.
                  </span>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <div className="header-profile-user header-image ">
                  <span className="text-uppercase ">
                    {" "}
                    <strong>O</strong>{" "}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          {/* <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar" />
                        <span className="d-none d-xl-inline-block ml-2 mr-1">{username}</span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i> */}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            className="dropdown-item"
            onClick={() => {
              setapproveModel(true);
              getUserDataById();
            }}
          >
            <i className="bx bx-user font-size-16 align-middle mr-1"></i>
            {props.t("Edit Profile")}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle mr-1"></i>
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge badge-success float-right">11</span>
            <i className="mdi mdi-settings font-size-17 align-middle mr-1"></i>
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider"></div>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <Modal className="export-modal" isOpen={approveModel}>
        <Row>
          <Col md={12} className="modal-head">
            <div>
              <h5>Edit User</h5>
            </div>
            <div onClick={() => setapproveModel(false)}>
              <i class="bx bx-x"></i>
            </div>
          </Col>
        </Row>
        <hr />
        <AvForm className="form" onValidSubmit={(e) => updateUser(e)}>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>First Name</label>
            </Col>
            <Col md={6}>
              <AvField
                name="firstname"
                type="text"
                placeholder="Enter User Firstname"
                onChange={(e) => handle(e)}
                value={values.firstname}
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Last Name</label>
            </Col>
            <Col md={6}>
              <AvField
                name="lastname"
                placeholder="Enter User Lastname"
                type="text"
                onChange={(e) => handle(e)}
                value={values.lastname}
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Contact Number</label>
            </Col>
            <Col md={6}>
              <AvField
                name="mobile"
                type="number"
                placeholder="Enter User Phone"
                onChange={(e) => handle(e)}
                value={values.mobile}
                required
              ></AvField>
              <span
                className="pswchange"
                onClick={() => setpasswordModel(true)}
              >
                Change Password
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <button type="submit" class="exportuserbtn">
                Update User
              </button>
            </Col>
          </Row>
        </AvForm>
      </Modal>
      <UserPasswordChange
        setpasswordModel={setpasswordModel}
        passwordModel={passwordModel}
        userId={Id}
        values={values}
      />
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
