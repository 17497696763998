import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

function AddBranch(props) {
  return (
    <>
      <Modal className="export-modal" isOpen={props.openModal}>
        <Row>
          <Col md={12} className="modal-head">
            <div>
              <h5>Add Branch</h5>
            </div>
            <div onClick={() => props.setOpenModel(false)}>
              <i class="bx bx-x"></i>
            </div>
          </Col>
        </Row>
        <hr />
        <AvForm className="form" onValidSubmit={(e) => props.handleSubmit(e)}>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Branch Name</label>
            </Col>
            <Col md={6}>
              <AvField
                name="branchName"
                type="text"
                placeholder="Enter Branch"
                onChange={(e) => props.handle(e)}
                value={props.values.branchName}
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Address Line 1</label>
            </Col>
            <Col md={6}>
              <AvField
                name="branchAddressStreet1"
                placeholder="Enter Address Line 1"
                type="text"
                onChange={(e) => props.handle(e)}
                value={props.values.branchAddressStreet1}
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Address Line 2</label>
            </Col>
            <Col md={6}>
              <AvField
                name="branchAddressStreet2"
                placeholder="Enter Address Line 2"
                type="text"
                onChange={(e) => props.handle(e)}
                value={props.values.branchAddressStreet2}
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>City</label>
            </Col>
            <Col md={6}>
              <AvField
                name="branchAddressCity"
                placeholder="Enter City"
                type="text"
                onChange={(e) => props.handle(e)}
                value={props.values.branchAddressCity}
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>State</label>
            </Col>
            <Col md={6}>
              <AvField
                name="branchAddressState"
                placeholder="Enter State"
                type="text"
                onChange={(e) => props.handle(e)}
                value={props.values.branchAddressState}
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Zip Code</label>
            </Col>
            <Col md={6}>
              <AvField
                name="branchAddressZipcode"
                placeholder="Enter Zip Code"
                type="text"
                onChange={(e) => props.handle(e)}
                value={props.values.branchAddressZipcode}
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Phone</label>
            </Col>
            <Col md={6}>
              <AvField
                name="branchAddressPhone"
                placeholder="Enter Phone"
                type="text"
                onChange={(e) => props.handle(e)}
                value={props.values.branchAddressPhone}
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Fax</label>
            </Col>
            <Col md={6}>
              <AvField
                name="branchFax"
                placeholder="Enter Fax"
                type="text"
                onChange={(e) => props.handle(e)}
                value={props.values.branchFax}
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Website</label>
            </Col>
            <Col md={6}>
              <AvField
                name="branchWebsite"
                placeholder="Enter Website"
                type="text"
                onChange={(e) => props.handle(e)}
                value={props.values.branchWebsite}
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>Email</label>
            </Col>
            <Col md={6}>
              <AvField
                name="branchPrimaryContactEmail"
                placeholder="Enter Email"
                type="text"
                onChange={(e) => props.handle(e)}
                value={props.values.branchPrimaryContactEmail}
                required
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={6}>
              <label style={{ whiteSpace: "pre" }}>GSTIN</label>
            </Col>
            <Col md={6}>
              <AvField
                name="branchGstin"
                placeholder="Enter GSTIN"
                type="text"
                onChange={(e) => props.handle(e)}
                value={props.values.branchGstin}
                required
              ></AvField>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <button type="submit" class="exportuserbtn">
                Add Branch
              </button>
            </Col>
          </Row>
        </AvForm>
      </Modal>
    </>
  );
}

export default AddBranch;
