import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { useEffect } from "react";
import {
  setDefaultItems,
  deleteItems,
  updateTableItems,
} from "../../store/actions";
import { connect } from "react-redux";
import { BaseUrl } from "../../components/config/BaseUrl";
function ItemComponent(props) {
  const handleAddRow = () => {
    props.add();
    props.setDefaultItems();
  };
  const handleDeleteRow = () => {
    props.delete(props.index);
    props.deleteItems(props.index);
  };
  const [UOM, setUOM] = useState([]);
  function getUOM() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/public/v1/uom`;
    fetch(Url)
      .then((response) => response.json())
      .then((data) => {
        // setIsPageLoading(false);
        setUOM(data.data);
      });
  }
  useEffect(() => {
    getUOM();
  }, []);

  return (
    <div className="itemContainer" key={props.index}>
      {props.index !== 0 ? (
        <div className="close" onClick={handleDeleteRow}>
          <span>
            <i class="bx bx-trash"></i>
          </span>
        </div>
      ) : (
        ""
      )}
      <Row className="d-flex align-items-center mb-3">
        <Col md={2}>
          <label className="mandatory-fields">Item name*</label>
        </Col>
        <Col md={6}>
          <AvField
            name="itemCommodityName"
            type="text"
            placeholder="Enter item name"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index][
                    "itemCommodityName"
                  ]
                : ""
            }
            required
          ></AvField>
        </Col>
      </Row>
      {props.formActivity === "2" ? (
        <>
          <Row className="d-flex align-items-center mb-3">
            <Col md={2}>
              <label>Previous Check In No.*</label>
            </Col>
            <Col md={6}>
              <AvField
                name="itemPreviousCheckInNumber"
                type="text"
                placeholder="Enter item previous checkin number"
                onChange={(e) => props.updateTableItems(e, props.index)}
                value={
                  props.onlineFormCustomerItems[props.index]
                    ? props.onlineFormCustomerItems[props.index][
                        "itemPreviousCheckInNumber"
                      ]
                    : ""
                }
              ></AvField>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mb-3">
            <Col md={2}>
              <label>Goods Ref No.*</label>
            </Col>
            <Col md={6}>
              <AvField
                name="itemReferenceNumber"
                type="text"
                placeholder="Enter goods reference number"
                onChange={(e) => props.updateTableItems(e, props.index)}
                value={
                  props.onlineFormCustomerItems[props.index]
                    ? props.onlineFormCustomerItems[props.index][
                        "itemReferenceNumber"
                      ]
                    : ""
                }
              ></AvField>
            </Col>
          </Row>
        </>
      ) : (
        ""
      )}
      <Row className="d-flex align-items-center mb-3">
        <Col md={2}>
          <label>Batch #</label>
        </Col>
        <Col md={6}>
          <AvField
            name="itemBatch"
            type="text"
            placeholder="Enter batch"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemBatch"]
                : ""
            }
            // required
          ></AvField>
        </Col>
      </Row>
      <Row className="d-flex align-items-center mb-3">
        <Col md={2}>
          <label>Invoice #</label>
        </Col>
        <Col md={6}>
          <AvField
            name="itemInvoiceNumber"
            type="text"
            placeholder="Enter invoice number"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index][
                    "itemInvoiceNumber"
                  ]
                : ""
            }
            // required
          ></AvField>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <label>Units</label>
          <AvField
            name="itemUnits"
            type="number"
            placeholder="Qty"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemUnits"]
                : ""
            }
            // required
          ></AvField>
        </Col>
        <Col md={2}>
          <label>UOM</label>
          <AvField
            name="itemUom"
            type="select"
            placeholder="CTNS"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemUom"]
                : ""
            }
            // required
          >
            <option value="" selected disabled>
              Select UOM
            </option>
            {UOM.map((uom) => {
              return <option>{uom.uom}</option>;
            })}
          </AvField>
        </Col>
        <Col md={2}>
          <label>Total Items</label>
          <AvField
            name="itemUnitsPerSinglePackage"
            type="number"
            placeholder="PCS"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index][
                    "itemUnitsPerSinglePackage"
                  ]
                : ""
            }
            // required
          ></AvField>
        </Col>
        <Col md={4}>
          <label className="form-input-dimension">
            <span>L</span>
            <span>W</span>
            <span>H</span>
          </label>

          <div className="form-input-dimension">
            <AvField
              name="itemLength"
              type="number"
              placeholder="Length"
              onChange={(e) => props.updateTableItems(e, props.index)}
              value={
                props.onlineFormCustomerItems[props.index]
                  ? props.onlineFormCustomerItems[props.index]["itemLength"]
                  : ""
              }
              // required
            ></AvField>
            <AvField
              name="itemBreadth"
              type="number"
              placeholder="Width"
              onChange={(e) => props.updateTableItems(e, props.index)}
              value={
                props.onlineFormCustomerItems[props.index]
                  ? props.onlineFormCustomerItems[props.index]["itemBreadth"]
                  : ""
              }
              // required
            ></AvField>
            <AvField
              name="itemHeight"
              type="number"
              placeholder="Height"
              onChange={(e) => props.updateTableItems(e, props.index)}
              value={
                props.onlineFormCustomerItems[props.index]
                  ? props.onlineFormCustomerItems[props.index]["itemHeight"]
                  : ""
              }
              // required
            ></AvField>
          </div>
        </Col>
        <Col md={2}>
          <label>Weight</label>
          <AvField
            name="itemTotalWeight"
            type="number"
            placeholder="weight"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemTotalWeight"]
                : ""
            }
            // required
          ></AvField>
        </Col>
      </Row>
      <Row className="d-flex align-items-center mb-3">
        <Col md={2}>
          <label>HSN code</label>
        </Col>
        <Col md={4}>
          <AvField
            name="itemHsnCode"
            type="text"
            placeholder="Value"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemHsnCode"]
                : ""
            }
            // required
          ></AvField>
        </Col>
        {/* <Col md={4}>
          <label>Forklift / handling /loader </label>
          <AvField
            name="itemTemperature"
            type="text"
            placeholder="Type"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemTemperature"]
                : ""
            }
            // required
          ></AvField>
        </Col>
        <Col md={4}>
          <label>Weight handling</label>
          <AvField
            name="itemWeightUom"
            type="text"
            placeholder="Weight handling"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemWeightUom"]
                : ""
            }
            // required
          ></AvField>
        </Col> */}
      </Row>

      <Row className="d-flex align-items-center mb-3">
        <Col md={2}>
          <label>Additional Info</label>
        </Col>
        <Col md={10}>
          <AvField
            name="itemRemarks"
            type="textarea"
            placeholder="More Information"
            onChange={(e) => props.updateTableItems(e, props.index)}
            value={
              props.onlineFormCustomerItems[props.index]
                ? props.onlineFormCustomerItems[props.index]["itemRemarks"]
                : ""
            }
            // required
          ></AvField>
        </Col>
      </Row>

      {props.array.length - 1 === props.index ? (
        <Row>
          <Col md={12} className="text-right">
            <button
              className="addDivBtn"
              type="button"
              onClick={() => handleAddRow()}
              disabled={props.formSuccess}
            >
              Add new item
            </button>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <hr className="border-bottom" />
    </div>
  );
}
const mapStatetoProps = (state) => {
  const { onlineFormCustomerItems } = state.OnlineFormTable;
  return { onlineFormCustomerItems };
};
export default connect(mapStatetoProps, {
  setDefaultItems,
  deleteItems,
  updateTableItems,
})(ItemComponent);
