import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
  Card,
  CardBody,
} from "reactstrap";
import { apiGet, apiPost } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import loader from "../../assets/loader/load.gif";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import { connect } from "react-redux";
import { IoIosAddCircle } from "react-icons/io";
//i18n
import { AvField, AvForm } from "availity-reactstrap-validation";
import { fetchTableData } from "../../store/actions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AddPaymentRecieved = (props) => {
  const [reload, setReload] = useState(false);
  const [coa, setCoA] = useState([]);
  //  customer state
  const [data, setData] = useState([]);
  // table config
  const [rowsData, setRowsData] = useState([]);
  const [subTotalAmount, setSubTotalAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [shipChrg, setShipChrg] = useState(0);
  const [adjus, setAdjus] = useState(0);
  const [activeTab, setActiveTab] = useState("1");

  const history = useHistory();
  const [values, setValues] = useState({
    paymentsRcvCustomerId: "",
    paymentsRcvCustomerPan: "",
    paymentsRcvGstTreatmentId: "",
    paymentsRcvAmount: "",
    paymentsRcvCurrency: "",
    paymentsRcvBankCharges: "",
    paymentsRcvAmountUsedForPayments: "",
    paymentsRcvAmountRefunded: "",
    paymentsRcvAmountInExcess: "",
    paymentsRcvIsFullAmountReceived: "",
    paymentsRcvDate: "",
    paymentsRcvMode: "",
    paymentsRcvDepositToAccountId: "",
    paymentsRcvReferenceNumber: "",
    paymentsRcvNotes: "",
    paymentsRcvAttachments: "",
    paymentsRcvEmailThanksNote: "",
    paymentsRcvThanksNoteEmailTo: "",
    paymentsRcvCustomerName: "",
    paymentsRcvCustomerContactNumber: "",
    organizationId: "",
    branchId: "",
    taxdeducted: "No",
    tds: "",
  });

  function handleChange(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setValues(newData);
  }
  function handleCustomer(e) {
    setValues({ ...values, paymentsRcvCustomerId: e.value });
    console.log("customer e", e.value);
    if (e.value == "add-customer") {
      console.log(true);
      history.push("/customer/add");
    }
    props.fetchTableData(e.value);
  }

  const handleSubmit = () => {
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/payments_received";
    let body = {
      paymentsRcvCustomerId: values.paymentsRcvCustomerId,
      paymentsRcvCustomerPan: values.paymentsRcvCustomerPan,
      paymentsRcvGstTreatmentId: values.paymentsRcvGstTreatmentId,
      paymentsRcvAmoun: values.paymentsRcvAmoun,
      paymentsRcvCurrency: values.paymentsRcvCurrency,
      paymentsRcvBankCharges: values.paymentsRcvBankCharges,
      paymentsRcvAmountUsedForPayments: values.paymentsRcvAmountUsedForPayments,
      paymentsRcvAmountRefunded: values.paymentsRcvAmountRefunded,
      paymentsRcvAmountInExcess: values.paymentsRcvAmountInExcess,
      paymentsRcvIsFullAmountReceived: values.paymentsRcvIsFullAmountReceived,
      paymentsRcvDate: values.paymentsRcvDate,
      paymentsRcvMode: values.paymentsRcvMode,
      paymentsRcvDepositToAccountId: values.paymentsRcvDepositToAccountId,
      paymentsRcvReferenceNumber: values.paymentsRcvReferenceNumber,
      paymentsRcvNotes: values.paymentsRcvNotes,
      paymentsRcvAttachments: values.paymentsRcvAttachments,
      paymentsRcvEmailThanksNote: values.paymentsRcvEmailThanksNote,
      paymentsRcvThanksNoteEmailTo: values.paymentsRcvThanksNoteEmailTo,
      paymentsRcvCustomerName: values.paymentsRcvCustomerName,
      paymentsRcvCustomerContactNumber: values.paymentsRcvCustomerContactNumber,
      organizationId: 1,
      branchId: values.branchId,
      addedByUser: 1,
      editByUser: 1,
    };
    let resp = apiPost(url, body);
    resp.then((resp) => {
      console.log("recurring invoice data is", resp.response.data);
      setReload(false);
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            history.push("/paymentsrecieved");
          }, 1000);
          break;
        default:
          toast.error(resp.response.data.data);
      }
    });
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  // get customer (dropdown)
  function getCustomerListData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      console.log("customer table", resp);
      setData(resp.response.data.data);
    });
  }
  const custOptions = data.map((cust) => ({
    value: cust.customerId,
    label: cust.customerDisplayName,
  }));
  const CustomButton = ({ onClick }) => (
    <Link to="/customer/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Customer
      </button>
    </Link>
  );

  const DropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomButton onClick={console.log("clicked")} />
      </div>
    );
  };

  const customComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: DropdownMenu, // Use your custom dropdown menu
  };
  // const customAddCustomerOption = {
  //   label: (
  //     <Link to="/customer/add">
  //       <span style={{ color: "#408dfb" }}>
  //         <span style={{ marginRight: "8px", color: "#408dfb" }}>
  //           <IoIosAddCircle color="#408dfb" />
  //         </span>{" "}
  //         Add Customer
  //       </span>
  //     </Link>
  //   ),
  //   value: "add-customer",
  //   onClick: () => {
  //     console.log("Add Customer Clicked");
  //     history.push("/customer/add");
  //   },
  //   isFixed: true,
  // };

  // const options = [...custOptions, customAddCustomerOption];

  function getCoAData() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/accounts/coa`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      console.log("coa list", resp);
      setCoA(resp.response.data.data);
    });
  }
  useEffect(() => {
    getCoAData();
    getCustomerListData();
  }, []);
  // table config
  const addTableRows = () => {
    const rowsInput = {
      estItemDescription: "",
      estItemQuantity: 0,
      estItemRate: 0,
      estItemDiscountType: 0,
      estItemDiscountValue: 0,
      estItemTaxId: 0,
      estItemAmount: 0,
    };
    setRowsData([...rowsData, rowsInput]);
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    const sum = rows
      .map((item) => parseInt(item.estItemAmount))
      .reduce((prev, curr) => prev + curr, 0);
    console.log(sum);
    setSubTotalAmount(sum);
    setTotalAmount(
      parseInt(subTotalAmount) + parseInt(shipChrg) + parseInt(adjus)
    );
  };
  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    rowsInput[index]["estItemAmount"] =
      rowsInput[index]["estItemQuantity"] * rowsInput[index]["estItemRate"];
    setRowsData(rowsInput);
    console.log(rowsInput);
    const sum = rowsInput
      .map((item) => parseInt(item.estItemAmount))
      .reduce((prev, curr) => prev + curr, 0);
    console.log(sum);
    setSubTotalAmount(sum);
    setTotalAmount(
      parseInt(subTotalAmount) + parseInt(shipChrg) + parseInt(adjus)
    );
  };
  const onHandleBlur = (index, e) => {
    const rowsInput = [...rowsData];
    rowsInput[index]["estItemAmount"] =
      rowsInput[index]["estItemQuantity"] * rowsInput[index]["estItemRate"];
    setRowsData(rowsInput);
    setTotalAmount(
      parseInt(subTotalAmount) + parseInt(shipChrg) + parseInt(adjus)
    );
  };

  // cancel function

  const handleCancel = () => {
    setTimeout(() => {
      history.push("/paymentsrecieved");
    }, 1000);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Add New Payment" breadcrumbItem={"Accounting"} />

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AvForm className="form">
                    <Row>
                      <Col md={12} className=" pricelist">
                        <AvForm
                          onValidSubmit={handleSubmit}
                          className="formik"
                          id="addpaymentrecieved"
                        >
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "1",
                                })}
                                onClick={() => {
                                  toggle("1");
                                }}
                              >
                                Invoice Payment
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                              <Row>
                                <Col md={2}>
                                  <label className="formik-label">
                                    {" "}
                                    Customer Name
                                  </label>
                                </Col>
                                <Col
                                  md={3}
                                  // className="formik-input-search"
                                >
                                  {/* <select
                                    style={{ borderRadius: "5px" }}
                                    className="formik-input"
                                    as="select"
                                    name="paymentsRcvCustomerId"
                                    onChange={(e) => handleCustomer(e)}
                                    value={values.paymentsRcvCustomerId}
                                    required
                                  >
                                    <option value="" selected disabled>
                                      Select Customer
                                    </option>
                                    {data.map((cust, index) => {
                                      return (
                                        <option value={cust.customerId}>
                                          {cust.customerDisplayName}
                                        </option>
                                      );
                                    })}
                                  </select> */}

                                  <Select
                                    options={custOptions}
                                    components={customComponents}
                                    // className="formik-input"
                                    isClearable
                                    name="paymentsRcvCustomerId"
                                    placeholder="Select Customer"
                                    // defaultValue={customAddCustomerOption}
                                    // value={values.paymentsRcvCustomerId}
                                    value={
                                      custOptions.filter(
                                        (item) =>
                                          item.value ==
                                          values.paymentsRcvCustomerId
                                      ) || ""
                                    }
                                    onChange={handleCustomer}
                                    isFixed="add-customer"
                                    // components={customComponents}
                                  />
                                  {/* <ErrorMessage
                                    style={{ color: "red" }}
                                    component="div"
                                    name="customername"
                                  /> */}
                                </Col>
                              </Row>
                              <Row>
                                <Col md={2}>
                                  <label className="formik-label">
                                    {" "}
                                    Amount Recieved
                                  </label>
                                  <div></div>
                                </Col>
                                <Col md={10}>
                                  <AvField
                                    type="number"
                                    name="paymentsRcvAmount"
                                    placeholder="₹"
                                    className="formik-input"
                                    onChange={(e) => handleChange(e)}
                                    value={values.paymentsRcvAmount}
                                  ></AvField>
                                  {/* <ErrorMessage
                                    style={{ color: "red" }}
                                    component="div"
                                    name="amountrecieved"
                                  /> */}
                                </Col>
                              </Row>
                              <Row>
                                <Col md={2}>
                                  <label className="formik-label">
                                    {" "}
                                    Bank Charges (if any)
                                  </label>
                                  <div></div>
                                </Col>
                                <Col md={10}>
                                  <AvField
                                    type="number"
                                    name="paymentsRcvBankCharges"
                                    placeholder="₹"
                                    className="formik-input"
                                    onChange={(e) => handleChange(e)}
                                    value={values.paymentsRcvBankCharges}
                                  ></AvField>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={2}>
                                  <label className="formik-label">
                                    {" "}
                                    Payment Date
                                  </label>
                                </Col>
                                <Col md={10}>
                                  <AvField
                                    className="formik-input"
                                    type="date"
                                    name="paymentsRcvDate"
                                    onChange={(e) => handleChange(e)}
                                    value={values.paymentsRcvDate}
                                  ></AvField>
                                  {/* <ErrorMessage
                                    style={{ color: "red" }}
                                    component="div"
                                    name="paymentdate"
                                  /> */}
                                </Col>
                              </Row>
                              <Row>
                                <Col md={2}>
                                  <label className="formik-label">
                                    {" "}
                                    Payments#
                                  </label>
                                </Col>
                                <Col md={10}>
                                  <AvField
                                    className="formik-input"
                                    type="text"
                                    name="paymentsRcvReferenceNumber"
                                    placeholder="₹"
                                    onChange={(e) => handleChange(e)}
                                    value={values.paymentsRcvReferenceNumber}
                                  ></AvField>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={2}>
                                  <label className="formik-label">
                                    {" "}
                                    Payment Mode
                                  </label>
                                </Col>
                                <Col md={10}>
                                  <AvField
                                    className="formik-input"
                                    as="select"
                                    name="paymentsRcvMode"
                                    onChange={(e) => handleChange(e)}
                                    value={values.paymentsRcvMode}
                                  >
                                    <option value="" selected disabled>
                                      select Payment Mode
                                    </option>
                                    <option>Bank</option>
                                    <option>Credit Card</option>
                                    <option>Cash</option>
                                  </AvField>
                                  {/* <ErrorMessage
                                    style={{ color: "red" }}
                                    component="div"
                                    name="paymentmode"
                                  /> */}
                                </Col>
                              </Row>
                              <Row>
                                <Col md={2}>
                                  <label className="formik-label">
                                    {" "}
                                    Deposit To
                                  </label>
                                </Col>
                                <Col md={10}>
                                  <select
                                    className="formik-input"
                                    as="select"
                                    name="paymentsRcvDepositToAccountId"
                                    onChange={(e) => handleChange(e)}
                                    value={values.paymentsRcvDepositToAccountId}
                                  >
                                    <option value="0" selected>
                                      Select Account
                                    </option>
                                    {coa.map((ca, index) => {
                                      return (
                                        <option value={ca.headId}>
                                          {ca.headName}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={2}>
                                  <label className="formik-label">
                                    {" "}
                                    Reference#
                                  </label>
                                </Col>
                                <Col md={10}>
                                  <div>
                                    <AvField
                                      className="formik-input"
                                      type="input"
                                      name="paymentsRcvEmailThanksNote"
                                      onChange={(e) => handleChange(e)}
                                      value={values.paymentsRcvEmailThanksNote}
                                    ></AvField>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={2}>
                                  <label className="formik-label">
                                    {" "}
                                    Tax Deducted ?
                                  </label>
                                </Col>
                                <Col md={10}>
                                  <div className="formik-radiodiv">
                                    <label htmlFor="noTax">
                                      <AvField
                                        type="radio"
                                        id="noTax"
                                        name="taxdeducted"
                                        value="No"
                                        checked={values.taxdeducted === "No"}
                                        onChange={(e) => handleChange(e)}
                                      />
                                      No Tax Deducted
                                    </label>
                                  </div>
                                  <div className="formik-radiodiv">
                                    <label htmlFor="yesTDS">
                                      <AvField
                                        type="radio"
                                        id="yesTDS"
                                        name="taxdeducted"
                                        value="Yes"
                                        checked={values.taxdeducted === "Yes"}
                                        onChange={(e) => handleChange(e)}
                                      />
                                      Yes, TDS (Income Tax)
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                              {values.taxdeducted === "Yes" ? (
                                <Row>
                                  <Col md={2}>
                                    <label className="formik-label">
                                      {" "}
                                      TDS Tax Account
                                    </label>
                                  </Col>
                                  <Col md={10}>
                                    <div>
                                      <AvField
                                        className="formik-input"
                                        type="input"
                                        name="tds"
                                        onChange={(e) => handleChange(e)}
                                        value={values.tds}
                                      ></AvField>
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}
                              <hr />
                              {/* <RecurringInvoiceTable /> */}
                              <div className="row clearfix">
                                <div className="col-md-12 column">
                                  <table
                                    className="table table-bordered invoice-table"
                                    id="tab_logic"
                                  >
                                    <thead>
                                      <tr>
                                        <th className="text-center"> # </th>
                                        <th className="text-left"> DATE</th>
                                        <th className="text-right">
                                          {" "}
                                          INVOICE NUMBER
                                        </th>
                                        <th className="text-right">
                                          INVOICE AMOUNT{" "}
                                        </th>
                                        <th className="text-right">
                                          AMOUNT DUE
                                        </th>
                                        <th className="text-right"> PAYMENT</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {props.tableRowData?.unpaidInvoiceItems?.map(
                                        (data, index) => {
                                          return (
                                            <tr key={index}>
                                              <td>{index}</td>
                                              <td>
                                                <input
                                                  type="date"
                                                  name="invoiceDate"
                                                  className="form-control"
                                                  value={data.invoiceDate}
                                                  // onChange={(evnt) =>
                                                  //   handleTableChange(
                                                  //     index,
                                                  //     evnt
                                                  //   )
                                                  // }
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  name="invoiceNumber"
                                                  className="form-control"
                                                  placeholder="1.00"
                                                  value={data.invoiceNumber}
                                                  // onChange={(evnt) =>
                                                  //   handleTableChange(
                                                  //     index,
                                                  //     evnt
                                                  //   )
                                                  // }
                                                  onBlur={(e) =>
                                                    onHandleBlur(index, e)
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="number"
                                                  name="invoiceTotal"
                                                  className="form-control"
                                                  placeholder="0.00"
                                                  value={data.invoiceTotal}
                                                  // onChange={(evnt) =>
                                                  //   handleTableChange(
                                                  //     index,
                                                  //     evnt
                                                  //   )
                                                  // }
                                                  onBlur={(e) =>
                                                    onHandleBlur(index, e)
                                                  }
                                                />
                                              </td>

                                              <td>
                                                <input
                                                  name="estItemTaxId"
                                                  id="tax"
                                                  disabled
                                                  value={data.estItemTaxId}
                                                  // onChange={(evnt) =>
                                                  //   handleTableChange(
                                                  //     index,
                                                  //     evnt
                                                  //   )
                                                  // }
                                                />
                                              </td>
                                              <td width="15%">
                                                <input
                                                  type="number"
                                                  name="estItemAmount"
                                                  className="form-control"
                                                  // value={data.estItemAmount}
                                                  onChange={(evnt) =>
                                                    handleTableChange(
                                                      index,
                                                      evnt
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td className="text-center">
                                                <span
                                                  className="btn btn-outline-danger"
                                                  onClick={(e) =>
                                                    deleteTableRows(index, e)
                                                  }
                                                >
                                                  x
                                                </span>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                  <Row>
                                    <Col md={6} className="table-left">
                                      <div>
                                        <button
                                          type="button"
                                          onClick={addTableRows}
                                          className="btn btn-default pull-left add-tablerow"
                                        >
                                          <i class="bx bxs-plus-circle"></i>
                                          <span>Add another line</span>
                                        </button>
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="table-right">
                                        <Row>
                                          <Col className="details">
                                            <div>
                                              <span className="detail-head">
                                                Amount Paid
                                              </span>
                                            </div>
                                            <div>
                                              <span>0.00</span>
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md={4}>
                                            <div>
                                              <span className="detail-head">
                                                Amount used for Payment
                                              </span>
                                            </div>
                                          </Col>
                                          <Col md={5}></Col>
                                          <Col md={3} className="text-right">
                                            <div>
                                              <span>0.00</span>
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md={4}>
                                            <div>
                                              <span className="detail-head">
                                                Amount Refunded
                                              </span>
                                            </div>
                                          </Col>
                                          <Col md={5}></Col>
                                          <Col md={3} className="text-right">
                                            <div>
                                              <span>0.00</span>
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md={4}>
                                            <div>
                                              <span className="detail-head">
                                                Amount in Excess
                                              </span>
                                            </div>
                                          </Col>
                                          <Col md={5}></Col>
                                          <Col md={3} className="text-right">
                                            <div>
                                              <span>0.00</span>
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col className="details">
                                            <div>
                                              <span className="total">
                                                Total
                                              </span>
                                            </div>

                                            <div>
                                              <span className="total">
                                                {totalAmount}
                                              </span>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                              <hr />
                              <Row>
                                <Col md={12}>
                                  <div className="notes">
                                    <span>Notes</span>
                                    <div>
                                      <AvField
                                        className="formik-textarea terms-textarea"
                                        name="paymentsRcvNotes"
                                        rows="4"
                                        cols="40"
                                        placeholder="Looking forward for your business"
                                        onChange={(e) => handleChange(e)}
                                        value={values.paymentsRcvNotes}
                                      />
                                    </div>
                                  </div>
                                  {/* <hr /> */}
                                  <div className=" table-bottom">
                                    <div className="row clearfix">
                                      <div className="col-md-4 attachfiles">
                                        <span>Attach File(s)</span>
                                        <div className="attach">
                                          <AvField
                                            name="paymentsRcvAttachments"
                                            type="file"
                                            onChange={(e) => handleChange(e)}
                                            value={
                                              values.paymentsRcvAttachments
                                            }
                                          ></AvField>
                                        </div>
                                        <div>
                                          <span style={{ fontSize: "10px" }}>
                                            You can upload a maximum of 5 files,
                                            5MB each
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                          </TabContent>
                        </AvForm>
                      </Col>
                    </Row>
                    <div className="bottom-bar">
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={4}>
                              <button
                                className="btn btn-left ml-3"
                                onClick={handleCancel}
                              >
                                Cancel
                              </button>
                            </Col>
                            <Col md={4}>
                              <button
                                type="reset"
                                className="btn btn-left"
                                form="addpaymentrecieved"
                              >
                                Clear
                              </button>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4} className="printView">
                          <span className="print"></span>
                        </Col>
                        <Col md={4}>
                          <button
                            className="btn btn-right float-right mr-3"
                            type="submit"
                            form="addpaymentrecieved"
                          >
                            {reload ? (
                              <img
                                className="loader"
                                src={loader}
                                alt="loading..."
                              />
                            ) : (
                              " Save"
                            )}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  // console.log("state", state);
  const { selectedCustomerId, selectedCustomer } = state.Layout;
  const { tableRowData } = state.PaymentRecievedTableData;

  return {
    selectedCustomerId,
    selectedCustomer,
    tableRowData,
  };
};
export default connect(mapStatetoProps, {
  fetchTableData,
})(AddPaymentRecieved);
