import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import ExportModal from "./exportModal";

import { BaseUrl } from "../../components/config/BaseUrl";

import { apiGet, apiPut } from "../../components/config/apiConfig";
import { ToastContainer, toast } from "react-toastify";
import dyarco from "../../assets/dyarco/awc.png";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
const CheckInSingleView = (props) => {
  const history = useHistory();

  const componentRef = useRef();
  const itemRef = useRef();
  var id = props.match.params.id;
  var current = new Date();
  const [createdDate, setCreatedDate] = useState("");
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1 < 10
      ? `0${current.getMonth() + 1}`
      : current.getMonth() + 1
  }-${current.getDate() < 10 ? `0${current.getDate()}` : current.getDate()}`;
  const [checkindata, setcheckindata] = useState({});
  const [printData, setPrintData] = useState(null);
  const [displayPrint, setDisplayPrint] = useState("none");
  function getCheckInData() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/cargo_checkin/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setcheckindata(resp.response.data.data);

      var index = resp.response.data.data.dateCreated.indexOf("T");
      var text = resp.response.data.data.dateCreated.substring(0, index);
      setCreatedDate(text);
    });
  }
  console.log("checkindata", checkindata);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "print-btn",
    pageStyle: "margin:50px",
  });
  const handlePrintItem = useReactToPrint({
    content: () => itemRef.current,
    bodyClass: "print-btn",
    pageStyle: "margin:50px",
    // onBeforePrint: () => setDisplayPrint("block"),
    // onClick: () => setDisplayPrint("block"),
    // onBeforeGetContent: () => setDisplayPrint("block"),
    onAfterPrint: () => setPrintData(null),
  });
  useEffect(() => {
    getCheckInData();
  }, []);
  const handleCloseCheckIn = () => {
    let userId = localStorage.getItem("Id");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/cargo_checkin/${checkindata.checkInId}/${userId}/freeze`;
    var body = {
      checkInStatus: 2,
    };
    let resp = apiPut(Url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          getCheckInData();
          break;
        default:
          toast.error(resp.response.data.data);
      }
    });
  };
  const handlePrintClick = (rowData) => {
    setPrintData(rowData);

    // handlePrintItem();
  };
  useEffect(() => {
    if (printData) {
      // Code to print the div with printData values
      handlePrintItem();
    }
  }, [printData]);
  const [dropdownMonth, setMonth] = React.useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleMonth = () => setMonth(!dropdownMonth);
  const [dropdownFilter, setFilter] = React.useState(false);
  const toggleFilter = () => setFilter(!dropdownFilter);
  const [dropdownOpen, setOpen] = React.useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const handleBack = () => {
    history.goBack();
  };
  const PrintDiv = styled.div`
    width:100%
    color: blue;
    font-size: 18px;
    // display: ${(props) => props.printDisplay};
    // @media print {
    //   display: block;
    // }
  `;
  const PrintLogo = styled.div`
    text-align: center;
    img {
      width: 32em;
    }
    margin-bottom: 2em;
  `;
  const PrintHead = styled.h2`
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2em;
  `;
  const PalletSection = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
  `;
  const PalletHead = styled.div`
    width: 30%;
    padding: 12px;
    color: black;
    font-size: 3rem;
    font-weight: 500;
    text-transform: uppercase;
  `;
  const PalletNumber = styled.div`
    padding: 12px;
    font-size: 7rem;
    font-weight: 600;
    color: black;
    text-transform: uppercase;
  `;

  const TableWrapper = styled.table`
    border-collapse: collapse;
    width: 100%;
    border: 2px solid #000;
  `;

  const TableRow = styled.tr`
    border: 1px solid #000;
  `;

  const TableHeader = styled.th`
    width: 40%;
    background-color: #f2f2f2;
    padding: 12px;
    text-align: left;
    color: black;
    font-size: ${(props) => props.fontSize};
    font-weight: 500;
    text-transform: uppercase;
  `;

  const TableCell = styled.td`
    border: 1px solid #000;
    padding: 12px;
    text-align: left;
    color: black;
    font-size: ${(props) => props.fontSize};
    font-weight: ${(props) => props.fontWeight};
  `;
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid className="singlepage">
          <Row>
            <Col md={12} className="item-head">
              <div className="backbutton" onClick={handleBack}>
                <i class="bx bx-arrow-back"></i>
              </div>
              <div className="bbb">
                {checkindata.checkInStatus === 1 ? (
                  <div className="d-flex">
                    <button
                      className="primary-btn btn btn-secondary"
                      onClick={handleCloseCheckIn}
                    >
                      Close Checkin
                    </button>
                    <Link to={`/editcargocheckin/edit/${id}`}>
                      <button>
                        <i class="bx bx-pencil"></i>
                      </button>
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                <Dropdown
                  className="d-inline-block"
                  isOpen={socialDrp}
                  toggle={() => {
                    setsocialDrp(!socialDrp);
                  }}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect ddtoggle"
                    tag="button"
                  >
                    <button>
                      {" "}
                      {/* <i class="bx bx-menu"></i> */}
                      More
                    </button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {/* <DropdownItem tag="a" href="">
                      {" "}
                      <i className="bx bx-copy font-size-16 align-middle mr-1"></i>
                      {"Clone Item"}{" "}
                    </DropdownItem> */}
                    {/* <hr /> */}
                    {/* <DropdownItem tag="a" href="#">
                      {" "}
                      <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                      {"Import Item"}{" "}
                    </DropdownItem>
                    <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                      {" "}
                      <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                      {"Export Item"}{" "}
                    </DropdownItem> */}
                    <DropdownItem tag="a" onClick={handlePrint}>
                      {" "}
                      <i className="bx bx-printer font-size-16 align-middle mr-1"></i>
                      {"Print Item"}{" "}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Modal className="export-modal" isOpen={modalIsOpen}>
                  <ExportModal isClose={() => setModalIsOpen(false)} />
                </Modal>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Col md={12} className="checkout-right">
                <div className="checkout-pdf" ref={componentRef}>
                  <Row>
                    <Col>
                      <img src={dyarco} alt="dyarco" className="slip-image" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="text-center">
                      <h4>GRN NOTE</h4>
                    </Col>
                    <Col md={12} className="mt-3 d-flex">
                      <h6 style={{ width: "70%" }}>
                        GRN NUMBER :{" "}
                        <strong>{checkindata?.checkInNumber}</strong>
                      </h6>
                      <h6>
                        QUOTATION NO. :{" "}
                        <strong>{checkindata?.checkInQuotationNumber}</strong>
                      </h6>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12} className="mt-3 d-flex">
                      <div style={{ width: "70%" }}>
                        <h6>
                          WAREHOUSE :{" "}
                          <strong>{checkindata?.checkInWarehouseName}</strong>
                        </h6>
                        <h6>
                          Total CBM : <strong>{checkindata?.checkInCbm}</strong>
                        </h6>
                        <h6>
                          Total SQM : <strong>{checkindata?.checkInSqm}</strong>
                        </h6>
                      </div>
                      <h6>
                        RATE/CBM :{" "}
                        <strong>{checkindata?.checkInRatePerCbm}</strong>
                      </h6>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mt-3">
                    <Col md={12} className="mt-3 d-flex">
                      <div style={{ width: "70%" }}>
                        <h6>CUSTOMER</h6>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span>
                            <strong>{checkindata?.checkInCustomerName}</strong>
                          </span>
                          <span>
                            <strong>
                              {checkindata?.checkInCustomerAddress}
                            </strong>
                          </span>{" "}
                          <span>
                            <strong>{checkindata?.checkInCustomerEmail}</strong>
                          </span>
                          <span>
                            <strong>{checkindata?.checkInCustomerPhone}</strong>
                          </span>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <h6 className="mt-1">
                            DATE: <strong>{checkindata.checkInDate}</strong>
                          </h6>
                          <h6 className="mt-1">
                            DRIVER NAME :{" "}
                            <strong>{checkindata?.checkInDriverName}</strong>
                          </h6>

                          <h6 className="mt-1">
                            DRIVER LICENSE NO :{" "}
                            <strong>
                              {checkindata?.checkInDrivingLicense}
                            </strong>
                          </h6>
                          <h6 className="mt-1">
                            VEHICLE NUMBER :{" "}
                            <strong>{checkindata?.checkInVehicleNumber}</strong>
                          </h6>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12} className="mt-3 d-flex">
                      <h6>
                        ESTIMATED COMMODITY VALUE :{" "}
                        <strong>
                          {checkindata?.checkInEstimatedCommodityValue}
                        </strong>
                      </h6>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12}>
                      <strong>Cargo Details</strong>
                    </Col>
                    <Col md={12} className="mt-3">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col" style={{ textAlign: "center" }}>
                                #
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Goods Reference No.
                              </th>
                              <th scope="col" width="10">
                                Commodity
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Invoice No.
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                HSN
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                UOM
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Units
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Total Items
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Facility
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Handling Info
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Date of expiry
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Batch
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Pallet No.
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Weight
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Dimension
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                CBM
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                SQM
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Remarks
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Cargo Condition
                              </th>
                            </tr>
                          </thead>
                          {checkindata.itemsList &&
                            checkindata.itemsList.map((item, index) => (
                              <tbody>
                                <tr>
                                  <th
                                    style={{ textAlign: "center" }}
                                    scope="row"
                                  >
                                    {index + 1}
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    {item.itemReferenceNumber}
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    {item.itemCommodityName}
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    {item.itemInvoiceNumber}
                                  </th>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemHsnCode}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemUom}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemUnits}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemUnitsPerSinglePackage}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemTemperature}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemHandlingInfo}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemDateOfExpiry}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemBatch}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemPallet}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemTotalWeight}
                                  </td>
                                  <tr>
                                    <td>
                                      <strong>L</strong>
                                      <br /> {item.itemLength}
                                    </td>
                                    <td>
                                      {" "}
                                      <strong>W</strong>
                                      <br />
                                      {item.itemBreadth}
                                    </td>
                                    <td>
                                      {" "}
                                      <strong>H</strong>
                                      <br />
                                      {item.itemHeight}
                                    </td>
                                  </tr>
                                  <td>{item.itemArea}</td>
                                  <td>{item.itemAreaSqm}</td>
                                  <td>{item.itemRemarks}</td>
                                  <td>{item.itemCargoCondition}</td>
                                  <td>
                                    {/* <ReactToPrint
                                      trigger={() => <button>Print</button>}
                                      content={() => itemRef.current}
                                    /> */}
                                    <button
                                      className="printBtn"
                                      onClick={() => handlePrintClick(item)}
                                    >
                                      <i className="bx bx-printer font-size-16 align-middle"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                        </table>
                        <div style={{ display: "none" }}>
                          <PrintDiv ref={itemRef}>
                            <PrintLogo>
                              <img src={dyarco} alt="dyarco" />
                            </PrintLogo>
                            {/* <PrintHead>Pallet Details</PrintHead> */}
                            <PalletSection>
                              <PalletHead>Pallet No.</PalletHead>
                              <PalletNumber>
                                {printData?.itemPallet}
                              </PalletNumber>
                            </PalletSection>
                            <TableWrapper>
                              <tbody>
                                <TableRow>
                                  <TableHeader fontSize="28px">
                                    Customer Name
                                  </TableHeader>
                                  <TableCell fontSize="32px" fontWeight="600">
                                    {checkindata?.checkInCustomerName}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableHeader fontSize="28px">
                                    Received Date
                                  </TableHeader>
                                  <TableCell fontSize="32px" fontWeight="600">
                                    {checkindata?.checkInDate}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableHeader fontSize="28px">
                                    DSS WH pallet number (Location)
                                  </TableHeader>
                                  <TableCell fontSize="32px" fontWeight="600">
                                    {printData?.itemPallet}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableHeader fontSize="28px">
                                    Total no of pcs
                                  </TableHeader>
                                  <TableCell fontSize="32px" fontWeight="600">
                                    {printData?.itemTotalInAllPackageAvailable}{" "}
                                    / {printData?.itemTotalInAllPackage}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableHeader fontSize="28px">
                                    Dimension
                                  </TableHeader>
                                  <TableCell fontSize="32px" fontWeight="500">
                                    {printData?.itemLength} x{" "}
                                    {printData?.itemBreadth} x{" "}
                                    {printData?.itemHeight}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableHeader fontSize="28px">
                                    cmb/sqm
                                  </TableHeader>
                                  <TableCell fontSize="32px" fontWeight="500">
                                    {printData?.itemArea} CBM /{" "}
                                    {printData?.itemAreaSqm} SQM
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableHeader fontSize="28px">
                                    Type of package
                                  </TableHeader>
                                  <TableCell fontSize="30px" fontWeight="600">
                                    {printData?.itemUom}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableHeader fontSize="28px">
                                    Description / awb # / (customer ref)
                                  </TableHeader>
                                  <TableCell fontSize="30px" fontWeight="600">
                                    {printData?.itemCommodityName} (
                                    {printData?.itemReferenceNumber})
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableHeader fontSize="28px">
                                    Remarks
                                  </TableHeader>
                                  <TableCell fontSize="30px" fontWeight="400">
                                    {printData?.itemRemarks}
                                  </TableCell>
                                </TableRow>
                              </tbody>
                            </TableWrapper>
                          </PrintDiv>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <strong>Other Charges</strong>
                    </Col>
                    <Col md={6} className="mt-3">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Order charges</th>
                          </tr>
                        </thead>
                        <tbody>
                          {checkindata.chargeList &&
                            checkindata.chargeList
                              // .filter((item) => item.chargeAmount > 0)
                              .map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <th width="10">{index + 1}</th>
                                    <th>{item.chargeName}</th>
                                    <td>{item.chargeAmount}</td>
                                    <td>{item.chargeAmountCostSheet}</td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </div>
              </Col>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default CheckInSingleView;
