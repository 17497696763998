import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import dyarco from "../../assets/dyarco/awc.png";
import loader from "../../assets/loader/load.gif";
import moment from "moment";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet, apiPost, apiPut } from "../../components/config/apiConfig";

import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomCheckbox from "../../components/Features/customcheckbox";
import { useSelector } from "react-redux";
import {
  addCommodityTableData,
  setCommodityTableData,
  setCommodityTableOtherCharges,
  deleteTableRow,
  clearCommodityData,
  updateCommodityRowTotal,
  requestWarehouseData,
  getCurrentSelectedCustomer,
  addCommodityTableOtherCharges,
  updateCommodityOtherCharges,
  deleteOtherChargeTableRow,
  clearCustomerData,
  populateTableRowData,
  populateOtherChargeTableRowData,
  validateTableData,
  addHandlingCharge,
} from "../../store/actions";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { set } from "lodash";

const OnlineFormEdit = (props) => {
  var formType = props.match.params.type;
  var id = props.match.params.id;
  var current = new Date();
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1 < 10
      ? `0${current.getMonth() + 1}`
      : current.getMonth() + 1
  }-${current.getDate() < 10 ? `0${current.getDate()}` : current.getDate()}`;
  const [isConvertLoading, setIsConvertLoading] = useState(false);
  const [requestDrp, setrequestDrp] = useState(false);
  const [approveModel, setapproveModel] = useState(false);
  const [declineModal, setdeclineModal] = useState(false);
  const [closeModal, setcloseModal] = useState(false);
  const scrollRef = useRef(null);
  const selectRef = useRef(null);
  const history = useHistory();
  const form = useRef(null);
  const componentRef = useRef();
  const [reload, setReload] = useState(false);
  const [uom, setuom] = useState([]);
  const [checkPalletNumberAvailable, setCheckPalletNumberAvailable] =
    useState(true);
  const [handlinginfo, sethandlinginfo] = useState([]);
  const [validateTable, setValidateTable] = useState(false);
  const [otherChargeName, setotherChargeName] = useState("");
  const [ChargeList, setChargeList] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [declineReason, setDeclineReason] = useState("");
  const [closeReason, setcloseReason] = useState("");
  const [correctionData, setCorrectionData] = useState("");
  const [warehouserequired, setwarehouserequired] = useState(false);
  const [WarehouseId, setWarehouseId] = useState({
    id: 0,
    name: "",
  });
  const [orderData, setOrderData] = useState({});
  const [values, setValues] = useState({
    orderDate: "",
    orderTime: "",
    orderType: 1,
    orderCustomerEmail: "",
    orderCustomerNumber: "",
    orderCustomerPhone: "",
    orderCustomerName: "",
    orderCustomerAddress: "",
    organizationId: "",
    branchId: 0,
    addedByUser: 1,
    editByUser: 1,
    loadingFrom: "",
    deliveryTo: "",
    vehicleNo: "",
    checkInRatePerCbm: "",
    previousCheckingNo: "",
  });
  const warestyle = {
    control: (base) => ({
      ...base,
      borderColor: "#f46a6a",
    }),
  };
  function getUOM() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/uom`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setuom(resp.response.data.data);
      }
    });
  }
  function getHandlingInfo() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/handling_info`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        sethandlinginfo(resp.response.data.data);
      }
    });
  }
  // item pallet/ref no
  function getItemByPallet(e, index) {
    let number = e.target.value;
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/checkin_items/${number}/pallet_ref`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setItemData(resp.response.data.data);
      }
    });
  }
  // item details by prev checkin no
  function getItemsByCheckin(e) {
    let number = e.target.value;
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/checkin_items/${number}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        props.populateTableRowData(resp.response.data.data);
      }
    });
  }
  const addTableRows = () => {
    props.setCommodityTableData();
  };
  const [otherCharge, setOtherCharge] = useState([
    {
      chargeName: "",
      chargeAmount: 0,
    },
  ]);
  const deleteTableRow = (index) => {
    props.deleteTableRow(index);
  };
  const deleteOtherChargeTableRow = (index) => {
    props.deleteOtherChargeTableRow(index);
    const rows = [...otherCharge];
    rows.splice(index, 1);
    setOtherCharge(rows);
  };
  const addOtherChargeTableRows = () => {
    const rowsInput = {
      chargeName: "",
      chargeAmount: 0,
    };
    setOtherCharge([...otherCharge, rowsInput]);
    setotherChargeName("");
    props.setCommodityTableOtherCharges();
  };
  function getOrderDataById() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/online_order/` + id;
    let resp = apiGet(Url);
    resp.then((resp) => {
      var datas = resp.response.data.data;
      props.getCurrentSelectedCustomer(datas.orderCustomerId);
      setWarehouseId({
        id: datas.orderWarehouseId,
        name: datas.orderWarehouseName,
      });
      props.requestWarehouseData(datas.orderWarehouseId);
      setValues(datas);
      setOrderData(datas);
      if (datas.chargeList?.length > 0) {
        setOtherCharge(datas.chargeList);
        props.populateOtherChargeTableRowData(datas.chargeList);
        setChargeList(resp.response.data.data.chargeList);
      }
      props.populateTableRowData(datas.itemsList);
    });
  }
  useEffect(() => {
    setValues({
      ...values,
      orderCustomerName: props.selectedCustomer?.customerDisplayName,
      checkInRatePerCbm: props.selectedCustomer?.customerFixedQuoteRate,
    });
  }, [props.selectedCustomer]);
  useEffect(() => {
    if (orderData.orderStatus === 0) {
      if (values.chargeList?.length === 0) {
        if (values.orderType === 1 || values.orderType === "1") {
          props.addHandlingCharge(
            props.selectedCustomer?.customerHandlingInCharge
          );
        }
        if (values.orderType === 2 || values.orderType === "2") {
          props.addHandlingCharge(
            props.selectedCustomer?.customerHandlingOutCharge
          );
        }
      }
    }
  }, [
    orderData.orderStatus,
    values.orderType,
    props.selectedCustomer?.customerHandlingInCharge,
    props.selectedCustomer?.customerHandlingOutCharge,
  ]);
  function handle(e) {
    const newData = { ...values };
    newData[e.target.name] = e.target.value;
    setValues(newData);
  }
  const updateOnlineForm = () => {
    const Url = BaseUrl.apiUrl.baseUrl + "api/v1/online_order/" + id;
    var body = {
      orderDate: values.orderDate,
      orderTime: values.orderTime,
      orderType: parseInt(values.orderType),
      orderCustomerEmail: props.selectedCustomer.customerEmail,
      orderCustomerNumber: props.selectedCustomer.customerWorkPhone,
      orderCustomerName: values.orderCustomerName,
      orderCustomerPhone: props.selectedCustomer.customerWorkPhone,
      orderCustomerAddress: values.orderCustomerAddress,
      checkInRatePerCbm: values.checkInRatePerCbm,
      loadingFrom: values.loadingFrom,
      deliveryTo: values.deliveryTo,
      vehicleNo: values.vehicleNo,
      additionalInfo: "",
      orderAuthorizedById: parseInt(values.orderAuthorizedById),
      orderAuthorizedByName: values.orderAuthorizedByName,
      orderWarehouseId: parseInt(WarehouseId.id),
      orderWarehouseName: WarehouseId.name,
      editByUser: 1,
      itemsList: props.tableRowData,
      chargeList: props.otherChargeTableData,
    };

    let resp = apiPut(Url, body);
    resp.then((resp) => {
      setReload(false);
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            history.push(`/online-form-list`);
          }, 1000);
          break;
        case 500:
          toast.error(resp.response.data.status);
          break;
        default:
          toast.error(resp.response.data.status);
      }
    });
  };
  const handleSubmit = (e) => {
    const filteredArray = props.tableRowData?.map(
      (item) =>
        item.itemHandlingInfo === "Fragile" &&
        (item.itemDateOfExpiry === "" || item.itemDateOfExpiry === null)
    );
    const tableIssueIndex = filteredArray.filter((item) => item === true);
    if (WarehouseId.id !== 0) {
      if (props.tableRowData.length > 0) {
        if (tableIssueIndex.length === 0) {
          if (props.validateValue == null) {
            setValidateTable(false);
            updateOnlineForm();
          } else {
            executeScroll();
            setValidateTable(true);
          }
        }
      } else {
        toast.info("Enter atleast one cargo details");
      }
    } else {
      setwarehouserequired(true);
    }
  };
  const handleChangeCustomer = (e) => {
    if (values.orderCustomerName !== "") {
      props.getCurrentSelectedCustomer(values.orderCustomerName);
    }
  };
  const executeScroll = () => scrollRef.current.scrollIntoView();
  const handleChangeWarehouse = (e) => {
    setwarehouserequired(false);
    setWarehouseId({
      id: e.value,
      name: e.label,
    });
    props.requestWarehouseData(e.value);
  };

  useEffect(() => {
    getOrderDataById();
    getUOM();
    getHandlingInfo();
    // props.setCommodityTableOtherCharges();
  }, [id]);
  const approveRequest = (e) => {
    var body = {
      orderStatus: 1,
      editByUser: 1,
    };
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/online_order/${id}/send_mail_status`;

    let resp = apiPut(Url, body);
    resp.then((resp) => {
      if (resp.response.data.status === "Success") {
        toast.success(`${resp.response.data.data} `);
        getOrderDataById();
        setapproveModel(false);
      }
    });
  };
  const declineRequest = (e) => {
    var body = {
      orderDeclineIssues: declineReason,
      orderCorrectionData: correctionData,
      orderStatus: 2,
      editByUser: 1,
    };
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/online_order/${id}/send_mail_status`;

    let resp = apiPut(Url, body);
    resp.then((resp) => {
      if (resp.response.data.status === "Success") {
        toast.success(`${resp.response.data.data} `);
        getOrderDataById();
        setdeclineModal(false);
      }
    });
  };
  const closeRequest = (e) => {
    var body = {
      orderComments: closeReason,
      editByUser: 1,
    };
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/online_order/${id}/close`;

    let resp = apiPut(Url, body);
    resp.then((resp) => {
      if (resp.response.data.status === "Success") {
        toast.success(`${resp.response.data.data} `);
        getOrderDataById();
        setcloseModal(false);
      }
    });
  };
  const warehouse =
    props.selectedCustomer.warehouseList &&
    props.selectedCustomer.warehouseList?.map((item) => {
      return {
        value: item.warehouseId,
        label: item.warehouseName,
      };
    });
  useEffect(() => {
    return () => {
      props.clearCommodityData();
      props.clearCustomerData();
    };
  }, []);
  const handleConvertOrder = () => {
    setIsConvertLoading(true);
    var body = {
      editByUser: 1,
    };
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/online_order/${id}/convert`;
    let resp = apiPut(Url, body);
    resp.then((resp) => {
      setIsConvertLoading(false);
      if (resp.response.data.status === "Success") {
        toast.success(`${resp.response.data.data} `);
        getOrderDataById();
      }
    });
  };
  function checkPalletAvailable(e, index) {
    if (e.target.value !== "") {
      const Url =
        BaseUrl.apiUrl.baseUrl +
        `api/v1/check_pallet_no_available/${e.target.value}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        if (resp.response.data.status === "Success") {
          setCheckPalletNumberAvailable(true);
        }
        if (resp.response.data.status === "Failed") {
          setCheckPalletNumberAvailable(false);
        }
      });
    } else {
      setCheckPalletNumberAvailable(true);
    }
  }
  useEffect(() => {
    if (values.orderType === 2 || values.orderType === "2") {
      setCheckPalletNumberAvailable(true);
    }
  }, [values.orderType]);
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content" ref={scrollRef}>
        <Container fluid>
          <Row>
            <Col md={12} className="d-center">
              <h6>ORDER FORM</h6>
              {localStorage.getItem("userRole") === "ADMIN" &&
              values.orderStatus === 0 &&
              orderData.orderCustomerStatus === 0 ? (
                <Dropdown
                  className="d-inline-block"
                  isOpen={requestDrp}
                  toggle={() => {
                    setrequestDrp(!requestDrp);
                  }}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect ddtoggle request-approv-btn"
                    tag="button"
                  >
                    <button
                      className="primary-btn btn btn-secondary"
                      disabled={orderData?.orderWarehouseId === 0}
                    >
                      Confirm Request
                    </button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      tag="button"
                      onClick={() => setapproveModel(true)}
                    >
                      {"Accept request"}{" "}
                    </DropdownItem>
                    <DropdownItem
                      tag="button"
                      onClick={() => setdeclineModal(true)}
                    >
                      {"Decline request"}{" "}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              ) : orderData.orderStatus === 1 &&
                orderData.orderCustomerStatus === 0 ? (
                <button className="approvedBtn">Request Approved</button>
              ) : orderData.orderStatus === 2 &&
                orderData.orderCustomerStatus === 0 ? (
                <button className="denyBtn">Request Denied</button>
              ) : orderData.orderStatus !== 4 &&
                orderData.orderCustomerStatus === 1 ? (
                <button
                  className="primary-btn btn btn-secondary mb-2"
                  onClick={handleConvertOrder}
                  disabled={isConvertLoading}
                >
                  {isConvertLoading ? "Please wait..." : "Convert Order Now"}
                </button>
              ) : orderData.orderStatus === 4 ? (
                <span className="text-convert">Order Converted</span>
              ) : orderData.orderCustomerStatus === 2 ? (
                <button
                  className="primary-btn btn btn-secondary mb-2"
                  onClick={() => setcloseModal(true)}
                >
                  Close order
                </button>
              ) : orderData.orderStatus === 5 ||
                orderData.orderCustomerStatus === 5 ? (
                <button className="approvedBtn mb-3">Order Closed</button>
              ) : orderData.orderCustomerStatus === 3 ? (
                <button className="waitingBtn mb-3">
                  Waiting for customer approval
                </button>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                {validateTable ? (
                  <Row>
                    <Col md={12}>
                      <div className="mandatory-container">
                        <span>{props.validateValue}</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <CardBody>
                  <AvForm
                    className="form"
                    onValidSubmit={(e) => handleSubmit(e)}
                    ref={form}
                    // model={data}
                  >
                    <Row>
                      <Col md={12} className="">
                        <Row>
                          <Col md={2}>
                            <label
                            //  className="mandatory-fields"
                            >
                              Date
                            </label>
                            <AvField
                              name="orderDate"
                              type="Date"
                              // placeholder="Loading From"
                              onChange={(e) => handle(e)}
                              value={values.orderDate}
                              disabled={orderData.orderStatus !== 0}
                              max={moment().format("YYYY-MM-DD")}
                              required
                            ></AvField>
                          </Col>
                          <Col md={4}>
                            <label>Customer Name</label>
                            <AvField
                              name="orderCustomerName"
                              type="text"
                              placeholder="Enter customer name"
                              onChange={(e) => handle(e)}
                              value={values.orderCustomerName}
                              disabled={orderData.orderStatus !== 0}
                              required
                            ></AvField>
                          </Col>
                          <Col>
                            <label>Rate/CBM</label>
                            <AvField
                              name="checkInRatePerCbm"
                              type="number"
                              onChange={(e) => handle(e)}
                              value={
                                values.checkInRatePerCbm === 0
                                  ? ""
                                  : values.checkInRatePerCbm
                              }
                              disabled={orderData.orderStatus !== 0}
                              required
                            ></AvField>
                          </Col>

                          <Col md={4}>
                            <label
                            // className="mandatory-fields"
                            >
                              Warehouse
                            </label>
                            <Select
                              value={warehouse?.filter(
                                (option) => option.value === WarehouseId.id
                              )}
                              options={warehouse}
                              name="deliveryTo"
                              placeholder="Select Warehouse"
                              isDisabled={orderData.orderStatus !== 0}
                              onChange={(e) => handleChangeWarehouse(e)}
                              styles={warehouserequired ? warestyle : ""}
                              // required
                            />
                            {warehouserequired ? (
                              <p style={{ fontSize: "80%", color: "#f46a6a" }}>
                                This field is invalid
                              </p>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <label
                            // className="mandatory-fields"
                            >
                              Activity
                            </label>
                            <AvField
                              name="orderType"
                              type="select"
                              onChange={(e) => handle(e)}
                              value={values.orderType}
                              disabled={orderData.orderStatus !== 0}
                              required
                            >
                              <option value="" selected disabled>
                                Select activity
                              </option>
                              <option value="1">Goods In</option>
                              <option value="2">Goods out</option>
                            </AvField>
                          </Col>
                          <Col md={4}>
                            <label>Vehicle #</label>
                            <AvField
                              name="vehicleNo"
                              type="text"
                              placeholder="Vehicle No"
                              onChange={(e) => handle(e)}
                              value={values.vehicleNo}
                              disabled={orderData.orderStatus !== 0}
                              required
                            ></AvField>
                          </Col>
                          <Col md={4}>
                            <label
                            //  className="mandatory-fields"
                            >
                              Loading From
                            </label>
                            <AvField
                              name="loadingFrom"
                              type="text"
                              placeholder="Loading From"
                              onChange={(e) => handle(e)}
                              disabled={orderData.orderStatus !== 0}
                              value={values.loadingFrom}
                              required
                            ></AvField>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <label
                            // className="mandatory-fields"
                            >
                              Delivery To
                            </label>
                            <AvField
                              name="deliveryTo"
                              type="text"
                              placeholder="Delivery To"
                              onChange={(e) => handle(e)}
                              value={values.deliveryTo}
                              disabled={orderData.orderStatus !== 0}
                              required
                            ></AvField>
                          </Col>
                          {/* {values.orderType == "2" ? (
                            <Col md={4}>
                              <label>Previous Checkin No.</label>
                              <AvField
                                name="previousCheckinNo"
                                type="text"
                                placeholder="Previous Checkin Number"
                                onChange={(e) => {
                                  handle(e);
                                  getItemsByCheckin(e);
                                }}
                                value={values.previousCheckingNo}
                                disabled={orderData.orderStatus !== 0}
                                required
                              ></AvField>
                            </Col>
                          ) : (
                            ""
                          )} */}
                        </Row>
                        <hr />
                      </Col>

                      <Col md={12}>
                        <Row className="mb-3">
                          <Col md={12}>
                            <strong>Cargo Details</strong>
                            {!checkPalletNumberAvailable ? (
                              <span style={{ marginLeft: "1em", color: "red" }}>
                                Pallet number already exists
                              </span>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <table
                              className="table table-bordered invoice-table table-responsive"
                              id="tab_logic"
                            >
                              <thead>
                                <tr>
                                  <th className="text-left"> ITEM NAME</th>
                                  {values.orderType == "1" ? (
                                    ""
                                  ) : (
                                    <th className="text-left">GOODS REF NO.</th>
                                  )}
                                  <th className="text-left"> INVOICE NO.</th>
                                  <th className="text-left"> HSN CODE</th>
                                  <th className="text-left"> UOM</th>
                                  <th className="text-left"> UNITS</th>
                                  <th className="text-left"> Total items</th>
                                  <th className="text-left"> FACILITY</th>
                                  <th className="text-left"> HANDLING INFO</th>
                                  <th className="text-left"> DOE</th>
                                  <th className="text-left"> BATCH</th>
                                  <th className="text-left"> PALLET NO.</th>
                                  <th className="text-left"> WEIGHT</th>
                                  <th className="text-left"> DIMENSIONS</th>
                                  <th className="text-left"> CBM</th>
                                  <th className="text-left"> SQM</th>
                                  <th className="text-left">
                                    {" "}
                                    ADDITIONAL INFO
                                  </th>
                                  <th className="text-left">CARGO CONDITION</th>
                                </tr>
                              </thead>
                              <tbody>
                                {props.tableRowData?.map((data, index) => {
                                  props.validateTableData(index);
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <AvField
                                          ref={selectRef}
                                          type="text"
                                          name="itemCommodityName"
                                          // onBlur={(e, index) =>
                                          //   addCommodity(e, index)
                                          // }
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemCommodityName"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                          className="form-control table-input"
                                        ></AvField>
                                      </td>
                                      {values.orderType == "2" ? (
                                        <td>
                                          <AvField
                                            type="text"
                                            name="itemPreviousGoodsReferenceNumber"
                                            onChange={(e) => {
                                              props.updateCommodityRowTotal(
                                                index,
                                                e
                                              );
                                            }}
                                            value={
                                              props.tableRowData[index]
                                                ? props.tableRowData[index][
                                                    "itemPreviousGoodsReferenceNumber"
                                                  ]
                                                : ""
                                            }
                                            disabled={
                                              (props.tableRowData[index] &&
                                                props.tableRowData[index][
                                                  "isAllItemCheckedOut"
                                                ] === 1) ||
                                              orderData.orderStatus !== 0
                                            }
                                            className="form-control table-input"
                                          ></AvField>
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                      <td>
                                        <AvField
                                          type="text"
                                          name="itemInvoiceNumber"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemInvoiceNumber"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                          className="form-control table-input"
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="text"
                                          name="itemHsnCode"
                                          // onBlur={(e) => onHandleBlur(index, e)}
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemHsnCode"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                          className="form-control table-input"
                                        ></AvField>
                                      </td>
                                      <td width={"10%"}>
                                        <AvField
                                          type="select"
                                          className="table-select"
                                          name="itemUom"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemUom"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                        >
                                          <option value="" disabled selected>
                                            Select UOM
                                          </option>
                                          {uom.map((items) => (
                                            <option
                                              selected={items.uom === "Cartons"}
                                            >
                                              {items.uom}
                                            </option>
                                          ))}
                                        </AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="number"
                                          name="itemUnits"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemUnits"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="number"
                                          name="itemUnitsPerSinglePackage"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemUnitsPerSinglePackage"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="select"
                                          className="table-select"
                                          name="itemTemperature"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemTemperature"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                        >
                                          <option value="" disabled selected>
                                            Select Facility
                                          </option>
                                          {props.warehouseData.warehouseFacilities
                                            ?.split(",")
                                            .map((items) => (
                                              <option>{items}</option>
                                            ))}
                                        </AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="select"
                                          className="table-select"
                                          name="itemHandlingInfo"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemHandlingInfo"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                        >
                                          <option value="" disabled selected>
                                            Select
                                          </option>
                                          {handlinginfo.map((items) => (
                                            <option>
                                              {items.handlingInfo}
                                            </option>
                                          ))}
                                        </AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="date"
                                          name="itemDateOfExpiry"
                                          className="form-control"
                                          min={date}
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemDateOfExpiry"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                        ></AvField>
                                        {props.tableRowData[index]?.[
                                          "itemHandlingInfo"
                                        ] === "Fragile" &&
                                        (props.tableRowData[index]?.[
                                          "itemDateOfExpiry"
                                        ] === "" ||
                                          props.tableRowData[index]?.[
                                            "itemDateOfExpiry"
                                          ] === null) ? (
                                          <div
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            This field is required
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      <td>
                                        <AvField
                                          type="text"
                                          name="itemBatch"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemBatch"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="text"
                                          name="itemPallet"
                                          className="form-control"
                                          onChange={(e) => {
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            );
                                            if (
                                              values.orderType === 1 ||
                                              values.orderType === "1"
                                            ) {
                                              checkPalletAvailable(e, index);
                                            }
                                          }}
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemPallet"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="number"
                                          name="itemTotalWeight"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemTotalWeight"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                        ></AvField>
                                      </td>
                                      <td>
                                        <div className="row">
                                          <div className="col-md-4">
                                            <label
                                              style={{ marginBottom: "0px" }}
                                            >
                                              L
                                            </label>
                                            <AvField
                                              type="number"
                                              name="itemLength"
                                              className="form-control"
                                              style={{
                                                borderBottom: "1px solid black",
                                                borderRadius: "0",
                                              }}
                                              onChange={(e) =>
                                                props.updateCommodityRowTotal(
                                                  index,
                                                  e
                                                )
                                              }
                                              value={
                                                props.tableRowData[index]
                                                  ? props.tableRowData[index][
                                                      "itemLength"
                                                    ]
                                                  : ""
                                              }
                                              disabled={
                                                (props.tableRowData[index] &&
                                                  props.tableRowData[index][
                                                    "isAllItemCheckedOut"
                                                  ] === 1) ||
                                                orderData.orderStatus !== 0
                                              }
                                            ></AvField>
                                          </div>
                                          <div className="col-md-4">
                                            <label
                                              style={{ marginBottom: "0px" }}
                                            >
                                              W
                                            </label>
                                            <AvField
                                              type="number"
                                              name="itemBreadth"
                                              className="form-control"
                                              style={{
                                                borderBottom: "1px solid black",
                                                borderRadius: "0",
                                              }}
                                              onChange={(e) =>
                                                props.updateCommodityRowTotal(
                                                  index,
                                                  e
                                                )
                                              }
                                              value={
                                                props.tableRowData[index]
                                                  ? props.tableRowData[index][
                                                      "itemBreadth"
                                                    ]
                                                  : ""
                                              }
                                              disabled={
                                                (props.tableRowData[index] &&
                                                  props.tableRowData[index][
                                                    "isAllItemCheckedOut"
                                                  ] === 1) ||
                                                orderData.orderStatus !== 0
                                              }
                                            ></AvField>
                                          </div>
                                          <div className="col-md-4">
                                            <label
                                              style={{ marginBottom: "0px" }}
                                            >
                                              H
                                            </label>
                                            <AvField
                                              type="number"
                                              name="itemHeight"
                                              className="form-control"
                                              style={{
                                                borderBottom: "1px solid black",
                                                borderRadius: "0",
                                              }}
                                              onChange={(e) =>
                                                props.updateCommodityRowTotal(
                                                  index,
                                                  e
                                                )
                                              }
                                              value={
                                                props.tableRowData[index]
                                                  ? props.tableRowData[index][
                                                      "itemHeight"
                                                    ]
                                                  : ""
                                              }
                                              disabled={
                                                (props.tableRowData[index] &&
                                                  props.tableRowData[index][
                                                    "isAllItemCheckedOut"
                                                  ] === 1) ||
                                                orderData.orderStatus !== 0
                                              }
                                            ></AvField>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <AvField
                                          type="number"
                                          name="itemArea"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          // disabled={
                                          //   props.tableRowData[index]?.[
                                          //     "itemLength"
                                          //   ]
                                          // }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemArea"
                                                ]
                                              : 0
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="number"
                                          name="itemAreaSqm"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          // disabled={
                                          //   props.tableRowData[index]?.[
                                          //     "itemLength"
                                          //   ]
                                          // }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemAreaSqm"
                                                ]
                                              : 0
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="text"
                                          name="itemRemarks"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemRemarks"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="text"
                                          name="itemCargoCondition"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemCargoCondition"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            (props.tableRowData[index] &&
                                              props.tableRowData[index][
                                                "isAllItemCheckedOut"
                                              ] === 1) ||
                                            orderData.orderStatus !== 0
                                          }
                                        ></AvField>
                                      </td>
                                      {orderData.orderStatus === 0 ? (
                                        <td className="text-center">
                                          <span
                                            className=" btn-outline-danger"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                            onClick={(e) =>
                                              deleteTableRow(index, e)
                                            }
                                          >
                                            <i class="bx bx-trash"></i>
                                          </span>
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                        {orderData.orderStatus === 0 ? (
                          <Row>
                            <Col md={8} className="table-left">
                              <div>
                                <button
                                  type="button"
                                  onClick={addTableRows}
                                  className="btn btn-default pull-left add-tablerow"
                                >
                                  <i class="bx bxs-plus-circle"></i>
                                  <span>Add another line</span>
                                </button>
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        <Row>
                          <Col md={12}>
                            <Row>
                              <Col md={6}>
                                <h6>Other Charges (QAR)</h6>
                              </Col>
                              {orderData.orderStatus === 0 ? (
                                <Col md={6} className="text-right">
                                  <button
                                    type="button"
                                    onClick={addOtherChargeTableRows}
                                    className="btn btn-default add-tablerow float-right"
                                  >
                                    <i
                                      class="bx bxs-plus-circle"
                                      // style={{ color: "#052f85" }}
                                    ></i>
                                    Add New
                                  </button>
                                </Col>
                              ) : (
                                ""
                              )}
                            </Row>
                          </Col>

                          {props.otherChargeTableData?.length > 0 ? (
                            <Col md={12}>
                              <table className="table table-bordered invoice-table ">
                                <thead>
                                  <tr>
                                    <th className="text-left"> Charge Name</th>
                                    <th className="text-left">Charge Amount</th>
                                  </tr>
                                </thead>
                                {orderData.orderStatus !== 0 ? (
                                  <tbody>
                                    {ChargeList.map((charges) => {
                                      return (
                                        <tr>
                                          <td className="text-left">
                                            {charges.chargeName}
                                          </td>
                                          <td className="text-left">
                                            {charges.chargeAmount}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                ) : (
                                  <tbody>
                                    {props.otherChargeTableData?.map(
                                      (data, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <input
                                                type="text"
                                                name="chargeName"
                                                className="form-control"
                                                placeholder="Name"
                                                // onBlur={(e) =>
                                                //   props.addCommodityTableOtherCharges(
                                                //     e
                                                //   )
                                                // }
                                                onChange={(e) => {
                                                  props.updateCommodityOtherCharges(
                                                    index,
                                                    e
                                                  );
                                                  setotherChargeName(
                                                    e.target.value
                                                  );
                                                }}
                                                value={
                                                  props.otherChargeTableData[
                                                    index
                                                  ]
                                                    ? props
                                                        .otherChargeTableData[
                                                        index
                                                      ]["chargeName"]
                                                    : otherChargeName
                                                }
                                                disabled={
                                                  orderData.orderStatus !== 0
                                                }
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number"
                                                name="chargeAmount"
                                                className="form-control"
                                                placeholder="Amount"
                                                onChange={(e) =>
                                                  props.updateCommodityOtherCharges(
                                                    index,
                                                    e
                                                  )
                                                }
                                                value={
                                                  props.otherChargeTableData[
                                                    index
                                                  ]
                                                    ? props
                                                        .otherChargeTableData[
                                                        index
                                                      ]["chargeAmount"]
                                                    : ""
                                                }
                                                disabled={
                                                  orderData.orderStatus !== 0
                                                }
                                              />
                                            </td>
                                            {orderData.orderStatus === 0 ? (
                                              <td
                                                className="text-center"
                                                width="10px"
                                              >
                                                <span
                                                  className=" btn-outline-danger"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                  onClick={(e) =>
                                                    deleteOtherChargeTableRow(
                                                      index,
                                                      e
                                                    )
                                                  }
                                                >
                                                  <i class="bx bx-trash"></i>
                                                </span>
                                              </td>
                                            ) : (
                                              ""
                                            )}
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                )}
                              </table>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </Col>
                    </Row>

                    {/* <BottomBar /> */}
                    {orderData.orderStatus === 0 ? (
                      <div className="bottom-bar">
                        <Row>
                          <Col md={4}>
                            <Row>
                              <Col md={4}>
                                <Link to="/online-form-list">
                                  <button
                                    type="button"
                                    className="btn btn-left float-right ml-3"
                                  >
                                    Cancel
                                  </button>
                                </Link>
                              </Col>
                              {formType == "add" ? (
                                <Col md={4}>
                                  <button
                                    type="button"
                                    // onClick={clearForm}
                                    className="btn btn-left"
                                  >
                                    {/* Clear */}
                                  </button>
                                </Col>
                              ) : (
                                ""
                              )}
                            </Row>
                          </Col>

                          <Col md={8}>
                            <button
                              type="submit"
                              className="btn btn-right float-right mr-3"
                              disabled={!checkPalletNumberAvailable}
                            >
                              {reload ? (
                                <img
                                  className="loader"
                                  src={loader}
                                  alt="loading..."
                                />
                              ) : (
                                "Save"
                              )}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      ""
                    )}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal className="export-modal" isOpen={approveModel}>
            <Row>
              <Col md={12} className="modal-head">
                <div>
                  <h5>Are you sure you want to approve this request</h5>
                </div>
                <div onClick={() => setapproveModel(false)}>
                  <i class="bx bx-x"></i>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="declineModal">
                <button onClick={approveRequest}>Yes</button>
                <button onClick={() => setapproveModel(false)}>No</button>
              </Col>
            </Row>
          </Modal>
          <Modal className="export-modal" isOpen={declineModal}>
            <Row>
              <Col md={12} className="modal-head">
                <div>
                  <h5>Decline this request?</h5>
                </div>
                <div onClick={() => setdeclineModal(false)}>
                  <i class="bx bx-x"></i>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <label>Reason</label>
                <input
                  type="text"
                  className="form-control"
                  name="declinereason"
                  onChange={(e) => setDeclineReason(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={12}>
                <label>Correction Data</label>
                <input
                  type="text"
                  className="form-control"
                  name="correctiondata"
                  onChange={(e) => setCorrectionData(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="declineModal">
                <button onClick={declineRequest}>Submit</button>
                <button onClick={() => setdeclineModal(false)}>Cancel</button>
              </Col>
            </Row>
          </Modal>
          <Modal className="export-modal" isOpen={closeModal}>
            <Row>
              <Col md={12} className="modal-head">
                <div>
                  <h5>Close Order</h5>
                </div>
                <div onClick={() => setcloseModal(false)}>
                  <i class="bx bx-x"></i>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <label>Close Reason</label>
                <input
                  type="text"
                  className="form-control"
                  name="closereason"
                  onChange={(e) => setcloseReason(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="declineModal">
                <button onClick={closeRequest}>Submit</button>
                <button onClick={() => setcloseModal(false)}>Cancel</button>
              </Col>
            </Row>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const {
    tableRowData,
    warehouseData,
    totalItemArea,
    otherChargeTableData,
    validateValue,
    isDuplicate,
  } = state.CommodityTableData;
  const { selectedCustomer } = state.Layout;

  return {
    tableRowData,
    warehouseData,
    totalItemArea,
    selectedCustomer,
    otherChargeTableData,
    validateValue,
    isDuplicate,
  };
};
export default connect(mapStatetoProps, {
  addCommodityTableData,
  setCommodityTableData,
  deleteTableRow,
  updateCommodityRowTotal,
  clearCommodityData,
  setCommodityTableOtherCharges,
  requestWarehouseData,
  getCurrentSelectedCustomer,
  addCommodityTableOtherCharges,
  updateCommodityOtherCharges,
  deleteOtherChargeTableRow,
  clearCustomerData,
  populateTableRowData,
  populateOtherChargeTableRowData,
  validateTableData,
  addHandlingCharge,
})(OnlineFormEdit);
