import {
  ADD_TABLE_DATA,
  UPDATE_ROW_TOTAL,
  DELETE_TABLE_ROW,
  CALCULATE_SHIPPING_CHARGE,
  CALCULATE_ADJUSTMENT_CHARGE,
  CALCULATE_TOTALS,
  POPULATE_TABLE_DATA,
  CLEAR_DATA,
} from "./actionTypes";

export const addTableDataGt = (itemData) => ({
  type: ADD_TABLE_DATA,
  payload: itemData,
});

export const updateRowTotalGt = (index, value) => ({
  type: UPDATE_ROW_TOTAL,
  payload: { index, value },
});
export const calculateShippingChargeGt = (value) => ({
  type: CALCULATE_SHIPPING_CHARGE,
  payload: value,
});
export const calculateAdjustmentChargeGt = (value) => ({
  type: CALCULATE_ADJUSTMENT_CHARGE,
  payload: value,
});
//export const deleteTableRow = (index) => ({
//type: DELETE_TABLE_ROW,
//payload: index,
//});
export const calculateTotalsGt = () => ({
  type: CALCULATE_TOTALS,
});
export const populateTableDataGt = (
  //subtotal,
  //shippingcharge,
  //adjustment,
  //total,
  itemList
) => ({
  type: POPULATE_TABLE_DATA,
  //payload: { subtotal, shippingcharge, adjustment, total, itemList },
  payload: { itemList },
});
export const clearTableDataGt = () => ({
  type: CLEAR_DATA,
});
