import React, { useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.min.css";
import "./calendar.css";
import EventModal from "./EventModal";

const locales = { "en-US": require("date-fns/locale/en-US") };

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

// const events = [
//   {
//     id: "1",
//     title: "Parents Meetting",
//     allDay: true,
//     start: new Date(2023, 9, 1),
//     end: new Date(2023, 9, 1),
//   },
//   {
//     id: "2",
//     title: "Study Leave",
//     start: new Date(2023, 9, 7),
//     end: new Date(2023, 9, 10),
//   },
//   {
//     id: "3",
//     title: "PTA Meeting",
//     start: new Date(2023, 9, 20),
//     end: new Date(2023, 9, 23),
//   },
// ];
const CalendarModule = () => {
  const [newEvent, setNewEvent] = useState({
    id: "",
    title: "",
    start: "",
    end: "",
    startTime: "",
    endTime: "",
  });
  const [allEvents, setAllEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [slotInfo, setSlotInfo] = useState({});
  //event selection
  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    console.log(event);
    setModalOpen(true);
    setNewEvent({
      id: event.id,
      title: event.title,
      start: event.start,
      end: event.end,
    });
  };
  //slot selection
  const handleSelectSlot = (slotInfo) => {
    if (slotInfo.start === slotInfo.end) {
      return;
    }
    setSlotInfo(slotInfo);
    setNewEvent({
      ...newEvent,
      id: "",
      title: "",
      start: slotInfo.start,
      end: slotInfo.end,
    });
    setModalOpen(true);
  };
  //modal Open
  const handleOpenModal = () => {
    setSelectedEvent({});
    setModalOpen(!modalOpen);
  };

  return (
    <div className={modalOpen ? "disable-blur" : ""}>
      <div>
        <h1 className="component-header">Calendar</h1>
      </div>
      <div className="button-div">
        <button onClick={handleOpenModal} className="add-event-button">
          Add Event{" "}
        </button>
      </div>
      <div className="calendar">
        <Calendar
          localizer={localizer}
          events={allEvents}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={(slotInfo) => {
            handleSelectSlot(slotInfo);
          }}
          selectable
          startAccessor="start"
          endAccessor="end"
          style={{ height: 600, margin: "50px" }}
        />
        <EventModal
          event={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setAllEvents={setAllEvents}
          allEvents={allEvents}
          setNewEvent={setNewEvent}
          newEvent={newEvent}
          slotInfo={slotInfo}
        />
      </div>
    </div>
  );
};
export default CalendarModule;
