import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import {
  FETCH_CUSTOMER_FAILURE,
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
} from "./actionTypes";

export const fetchCustomerRequest = () => {
  return {
    type: FETCH_CUSTOMER_REQUEST,
  };
};

const fetchCustomerSuccess = (customers) => {
  return {
    type: FETCH_CUSTOMER_SUCCESS,
    payload: customers,
  };
};

const fetchCustomerFailure = (error) => {
  return {
    type: FETCH_CUSTOMER_FAILURE,
    payload: error,
  };
};
