import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
// import DataTableExtensions from "react-data-table-component-extensions";
// import "react-data-table-component-extensions/dist/index.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
// import Table from "./Table";
import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import FilterComponent from "./FilterComponent";
import { AgGridReact } from "ag-grid-react";
import { ToastContainer } from "react-toastify";

function VendorsList() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }

  function toPages(pages) {
    const results = [];

    for (let i = 1; i < pages; i++) {
      results.push(i);
    }

    return results;
  }

  const columns = [
    {
      name: "Name",
      selector: (data) => data.vendorDisplayName,
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (data) => data.vendorCompanyName,
      sortable: true,
    },
    {
      name: "Work Phone",
      selector: (data) => data.vendorWorkPhone,
      sortable: true,
      right: true,
    },
    {
      name: "Source of Supply",
      selector: (data) => data.vendorPlaceOfSupply,
      sortable: true,
      right: true,
    },
    {
      name: "Payable",
      selector: (data) => data.vendorCurrency,
      sortable: true,
      right: true,
    },
    {
      name: "Unused Credit",
      selector: (data) => data.vendorOpeningBalance,
      sortable: true,
      right: true,
    },

    // {
    //   name: "Balance Due",
    //   selector: (data) => "₹ 0.0",
    //   sortable: true,
    //   // right: true,
    // },
  ];

  //aggrid components

  const gridRef = useRef();
  const defaultColDef = useMemo(
    () => ({
      filter: true,
      sortable: true,
      resizable: true,
    }),
    []
  );
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "vendorDisplayName",
      headerName: "Name",
      headerTooltip: "Name",
    },
    {
      field: "vendorCompanyName",
      headerName: "Company Name",
      headerTooltip: "Company Name",
    },
    {
      field: "vendorEmail",
      headerName: "Email",
      headerTooltip: "Email",
      width: "max-content",
    },

    {
      field: "vendorWorkPhone",
      headerName: "Work Phone",
      headerTooltip: "Work Phone",
    },
    {
      field: "vendorNumber",
      headerName: "Vendor ID",
      headerTooltip: "Vendor ID",
    },
    {
      field: "vendorPlaceOfSupply",
      headerName: "Source of Supply",
      headerTooltip: "Source of Supply",
    },
    {
      field: "vendorCurrency",
      headerName: "Currency",
      headerTooltip: "Currency",
    },
  ]);
  const onGridReady = useCallback(
    (params) => {
      return data;
    },
    [data]
  );
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        minWidth: 225,
        maxWidth: 225,
        width: 225,
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
        minWidth: 180,
        maxWidth: 400,
        width: 250,
      },
    ],
    position: "right",
    defaultToolPanel: "",
  };
  const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);

  const getData = async (page) => {
    setLoading(true);
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/vendors/org?org_id=${organisationId}&page=${page}&size=${perPage}&sort=vendorId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      console.log("vendors list", resp);
      setData(resp.response.data.data.content);
      setTotalRows(resp.response.data.data.totalElements);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    getData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/vendors/org?org_id=${organisationId}&page=${page}&size=${newPerPage}&sort=vendorId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setPerPage(newPerPage);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData(1);
  }, [filterText]);
  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };
  const subHeaderComponent = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const handleRowClick = (row) => {
    history.push(`/SingleVIewVendors/${row.data.vendorId}`);
  };
  return (
    <div className="App">
      <ToastContainer
        position="bottom-center"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="card">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
        {/* {loadingData ? (
          <div className="tableLoading">
            <p>Loading Please wait...</p>
          </div>
        ) : ( */}
        {/* <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows
          subHeader
          subHeaderComponent={subHeaderComponent}
          subHeaderAlign="left"
          onRowClicked={handleRowClick}
          highlightOnHover
          pointerOnHover
        /> */}
        {/* )} */}

        <div className="ag-theme-alpine" style={gridStyle}>
          {" "}
          <AgGridReact
            columnDefs={columnDefs}
            rowData={filteredItems}
            ref={gridRef}
            pagination
            grid
            onChangeRowsPerPage={handlePerRowsChange}
            onRowClicked={handleRowClick}
            sideBar={sideBar}
            animateRows={true}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </div>
  );
}
export default VendorsList;
