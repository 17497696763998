import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardTitle,
} from "reactstrap";
import { connect } from "react-redux";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
// import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchDashboardData,
  fetchDashboardRevenueGraph,
  dashboardCheckInClose,
  dashboardCheckOutClose,
  fetchDashboardSpaceGraph,
} from "../../store/actions";

//i18n
import { withTranslation } from "react-i18next";
import CardWarehouse from "./card-warehouse";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";
import Header from "./Header";
import ReactApexChart from "react-apexcharts";
import chartIcon from "../../assets/images/chart.png";
import chartRedIcon from "../../assets/images/chart-red.png";
import Loader from "../PageLoader/loader";
import styled from "styled-components";
const Dashboard = (props) => {
  const [checkInId, setCheckInId] = useState(null);
  const [checkOutId, setCheckOutId] = useState(null);
  const [graphFilterIndex, setGraphFilterIndex] = useState(1);

  const [graphData, setGraphData] = useState([]);
  const [graphCategory, setGraphCategory] = useState([]);
  const [warehouseSpace, setWarehouseSpace] = useState([]);
  const [warehouseSpaceLabel, setWarehouseSpaceLabel] = useState([]);
  const handleGraphFilter = (index, graphType) => {
    setGraphFilterIndex(index);
    props.fetchDashboardRevenueGraph(graphType);
  };
  useEffect(() => {
    props.fetchDashboardData("1m");
    props.fetchDashboardSpaceGraph();
    props.fetchDashboardRevenueGraph("1m");
  }, []);

  useEffect(() => {
    setGraphData(props.dashboardRevenueGraph?.data);
    setGraphCategory(props.dashboardRevenueGraph?.categories);
  }, [props.dashboardRevenueGraph]);

  useEffect(() => {
    setWarehouseSpace(props.dashboardSpaceGraph?.data);
    setWarehouseSpaceLabel(props.dashboardSpaceGraph?.label);
  }, [props.dashboardSpaceGraph?.data, props.dashboardSpaceGraph?.label]);
  // const options = {
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     curve: "smooth",
  //     width: 3,
  //   },

  //   colors: ["#34c38f", "#132E80"],
  //   labels: [
  //     "01 Jan 2001",
  //     "02 Jan 2001",
  //     "03 Jan 2001",
  //     "04 Jan 2001",
  //     "05 Jan 2001",
  //     "06 Jan 2001",
  //     "07 Jan 2001",
  //     "08 Jan 2001",
  //     "09 Jan 2001",
  //     "10 Jan 2001",
  //     "11 Jan 2001",
  //     "12 Jan 2001",
  //   ],
  //   xaxis: {
  //     type: "datetime",
  //   },
  //   grid: {
  //     borderColor: "#f1f1f1",
  //   },
  //   tooltip: {
  //     x: {
  //       format: "dd/MM/yy HH:mm",
  //     },
  //   },
  //   legend: {
  //     show: true,
  //     position: "top",
  //     horizontalAlign: "left",
  //   },
  //   chart: {
  //     height: 350,
  //     type: "line",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  // };

  const series = [
    {
      name: "series1",
      data: graphData,
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 3,
    },

    colors: ["#34c38f"],
    xaxis: {
      type: "category",
      categories: graphCategory,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };
  // const radialseries = [30, 50];
  // const radialoptions = {
  //   plotOptions: {
  //     radialBar: {
  //       dataLabels: {
  //         name: {
  //           fontSize: "22px",
  //         },
  //         value: {
  //           fontSize: "16px",
  //         },
  //         total: {
  //           show: false,
  //         },
  //       },
  //     },
  //   },
  //   legend: {
  //     show: true,
  //     position: "bottom",
  //   },
  //   labels: ["warehouseSpaceLabel", "Label"],
  //   colors: ["#34C759", "#FF9500"],
  // };
  const radialseries = warehouseSpace;
  const radialoptions = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: false,
          },
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
    },
    labels: ["Remaining", "Used"],
    colors: ["#34C759", "#FF9500"],
  };
  const handleCloseCheckIn = (data) => {
    setCheckInId(data);
    props.dashboardCheckInClose(data, setCheckInId);
  };

  const handleCloseCheckOut = (checkoutId, checkOutType) => {
    setCheckOutId(checkoutId);
    props.dashboardCheckOutClose(checkoutId, checkOutType);
  };
  return (
    <React.Fragment>
      <Header />
      <div className="dashboard_container">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            {props.dashboardCards?.map((card, index) => {
              return (
                <Col md={3} key={index}>
                  <div className="account-cards dash-cards">
                    <div className="account-card-top">
                      <span className="title">{card?.name}</span>
                      <div className="graph-detail">
                        <span
                          className={`count ${
                            card.percentageType == "+"
                              ? "green"
                              : card.percentageType == "-"
                              ? "red"
                              : "green"
                          }`}
                        >
                          {card.percentageType == "-"
                            ? ""
                            : card.percentageType == "+"
                            ? "+"
                            : "+"}
                          {card.percentage ? card.percentage : "0.0"}
                        </span>
                      </div>
                    </div>
                    <div className="account-card-bottom">
                      <span className="amount">
                        {card.value ? card.value : "0"}
                      </span>
                      <div className="account-card-chart">
                        <img
                          src={
                            card.percentageType == "+"
                              ? chartIcon
                              : card.percentageType == "-"
                              ? chartRedIcon
                              : chartIcon
                          }
                          alt={card.name}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row className="mt-5">
            <Col md={8}>
              <div className="cash-flow dash-cards">
                <div className="head">
                  <span className="title">Total Revenue</span>
                  <div className="graph-filter">
                    <span
                      className={`${graphFilterIndex === 1 ? "active" : ""}`}
                      onClick={() => handleGraphFilter(1, "1m")}
                    >
                      1M
                    </span>
                    <span
                      className={`${graphFilterIndex === 2 ? "active" : ""}`}
                      onClick={() => handleGraphFilter(2, "6m")}
                    >
                      6M
                    </span>
                    <span
                      className={`${graphFilterIndex === 3 ? "active" : ""}`}
                      onClick={() => handleGraphFilter(3, "1y")}
                    >
                      1Y
                    </span>
                    <span
                      className={`${graphFilterIndex === 4 ? "active" : ""}`}
                      onClick={() => handleGraphFilter(4, "5y")}
                    >
                      5Y
                    </span>
                  </div>
                </div>
                <div className="mt-4 row">
                  <div className="w-100 col-md-12">
                    {props.graphLoading ? (
                      <div className="d-flex justify-content-center">
                        <Loader />
                      </div>
                    ) : (
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="area"
                        height="350"
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="invoice dash-cards">
                <span className="title">Space</span>
                <div className="mt-4">
                  {props.spaceGraphLoading ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    <ReactApexChart
                      options={radialoptions}
                      series={radialseries}
                      type="radialBar"
                      height="380"
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-5 mb-5">
            <Col md={6}>
              <div className="dash-cards">
                <span className="title">Recent Check-In</span>
                <div className="table-responsive mt-4">
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>CheckIn Number</th>
                        <th>Customer Name</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.recentCheckIn?.map((checkin, index) => {
                        return (
                          <tr key={checkin.checkInId}>
                            <td>{checkin.checkInDate}</td>
                            <td>{checkin.checkInNumber}</td>
                            <td>{checkin.checkInCustomerName}</td>
                            <td>
                              {checkin.checkInStatus == 1 ? (
                                <ButtonComponent
                                  onClick={() =>
                                    handleCloseCheckIn(checkin.checkInId)
                                  }
                                >
                                  Close Check-In
                                </ButtonComponent>
                              ) : checkin.checkInStatus == 2 ? (
                                <div className="text-draft">Closed/Freezed</div>
                              ) : checkin.checkInStatus == 3 ? (
                                <div className="text-overdue">Completed</div>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="dash-cards">
                <span className="title">Recent Check-Out</span>
                <div className="table-responsive mt-4">
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>CheckIn Number</th>
                        <th>Customer Name</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.recentCheckOut?.map((checkout, index) => {
                        return (
                          <tr key={checkout.checkOutId}>
                            <td>{checkout.checkOutDate}</td>
                            <td>{checkout.checkOutNumber}</td>
                            <td>{checkout.checkOutCustomerName}</td>
                            <td>
                              {checkout.checkOutStatus == 1 ? (
                                <ButtonComponent
                                  onClick={() =>
                                    handleCloseCheckOut(
                                      checkout.checkOutId,
                                      checkout.checkOutType
                                    )
                                  }
                                >
                                  Close Check-Out
                                </ButtonComponent>
                              ) : (
                                <div className="text-draft">Closed</div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const ButtonComponent = styled.button`
  border: none;
  padding: 0.5em 1.5em;
  border-radius: 20px;
  background: #2650f6;
  color: white;
`;
const mapStatetoProps = (state) => {
  const {
    isLoading,
    graphLoading,
    dashboardCards,
    dashboardRevenueGraph,
    recentCheckIn,
    recentCheckOut,
    dashboardSpaceGraph,
  } = state.DashboardDetails;
  return {
    isLoading,
    graphLoading,
    dashboardCards,
    dashboardRevenueGraph,
    recentCheckIn,
    recentCheckOut,
    dashboardSpaceGraph,
  };
};
export default connect(mapStatetoProps, {
  fetchDashboardData,
  fetchDashboardRevenueGraph,
  dashboardCheckInClose,
  dashboardCheckOutClose,
  fetchDashboardSpaceGraph,
})(Dashboard);
