import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Input,
  ButtonGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Formik, Field } from "formik";
//i18n
import { withTranslation } from "react-i18next";
const CustomerStocks = (props) => {
  return (
    <React.Fragment>
      <h4>Avaialble Stocks</h4>
      {props.stocks?.length > 0 ? (
        <Row>
          <Col md={12} className="mt-3">
            <table class="table table-condensed table-bordered  fixed_headers">
              <thead>
                <tr>
                  <th>Checkin#</th>
                  <th>Item Name</th>
                  <th>Warehouse</th>
                  <th>Invoice#</th>
                  <th>DOE</th>
                  <th>Pallet</th>
                  <th>Batch</th>
                  <th>Available Units</th>
                  <th>CheckedOut Units</th>

                  <th>Total Units</th>
                </tr>
              </thead>
              <tbody>
                {props.stocks?.map((items, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-opinenumber">
                        {items.itemCargoCheckInNumber}
                      </td>
                      <td>{items.itemCommodityName}</td>
                      <td>{items.itemWarehouseName}</td>
                      <td className="text-invoicenumber">
                        {items.itemInvoiceNumber}
                      </td>

                      <td>{items.itemDateOfExpiry}</td>
                      <td>{items.itemPallet}</td>
                      <td>{items.itemBatch}</td>
                      <td>{items.itemUnitsAvailable}</td>
                      <td>{items.itemUnitsCheckedOut}</td>

                      <td>{items.itemUnits}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col className="mt-5 text-center bold">NO RESULTS FOUND</Col>
        </Row>
      )}
    </React.Fragment>
  );
};
export default CustomerStocks;
