import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Input,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import ExportModal from "./exportModal";
import classnames from "classnames";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Formik, Field } from "formik";
//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";
import dyarco from "../../assets/dyarco/awc.png";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
const SingleGoodsTransfer = (props) => {
  const history = useHistory();
  const componentRef = useRef();
  const [transferdata, settransferdata] = useState({});
  const [paymentTerm, setPaymentTerm] = useState({});
  function getGoodsTransfer() {
    const Url =
      BaseUrl.apiUrl.baseUrl + "api/v1/goods_transfer/" + props.match.params.id;
    let resp = apiGet(Url);
    resp.then((resp) => {
      settransferdata(resp.response.data.data);
    });
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "print-btn",
    pageStyle: "margin:50px",
  });
  useEffect(() => {
    getGoodsTransfer();
  }, []);
  // function getPaymentTermsById() {
  //   var paymentTermsid = invoicedata.invoicePaymentTermsId;
  //   const Url =
  //     BaseUrl.apiUrl.baseUrl + `api/v1/master/payment_terms/${paymentTermsid}`;
  //   let resp = apiGet(Url);
  //   resp.then((resp) => {
  //     if (resp?.response.data.code == 200) {
  //       setPaymentTerm(resp.response.data.data);
  //     }
  //   });
  // }
  // useEffect(() => {
  //   if (invoicedata?.invoicePaymentTerms !== "") {
  //     getPaymentTermsById();
  //   }
  // }, [invoicedata]);
  const [dropdownMonth, setMonth] = React.useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleMonth = () => setMonth(!dropdownMonth);
  const [dropdownFilter, setFilter] = React.useState(false);
  const toggleFilter = () => setFilter(!dropdownFilter);
  const [dropdownOpen, setOpen] = React.useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const handleBack = () => {
    history.goBack();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="singlepage">
          <Row>
            <Col md={12} className="item-head">
              <div className="backbutton" onClick={handleBack}>
                <i class="bx bx-arrow-back"></i>
              </div>
              <div className="bbb">
                <Link to={`/goodstransfer/edit/${transferdata.id}`}>
                  <button>
                    <i class="bx bx-pencil"></i>
                  </button>
                </Link>
                <Dropdown
                  className="d-inline-block"
                  isOpen={socialDrp}
                  toggle={() => {
                    setsocialDrp(!socialDrp);
                  }}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect ddtoggle"
                    tag="button"
                  >
                    <button>
                      {" "}
                      {/* <i class="bx bx-menu"></i> */}
                      More
                    </button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {/* <DropdownItem tag="a" href="">
                      {" "}
                      <i className="bx bx-copy font-size-16 align-middle mr-1"></i>
                      {"Clone Item"}{" "}
                    </DropdownItem>
                    <hr />
                    <DropdownItem tag="a" href="#">
                      {" "}
                      <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                      {"Import Item"}{" "}
                    </DropdownItem>
                    <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                      {" "}
                      <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                      {"Export Item"}{" "}
                    </DropdownItem> */}
                    <DropdownItem tag="a" onClick={handlePrint}>
                      {" "}
                      <i className="bx bx-printer font-size-16 align-middle mr-1"></i>
                      {"Print Goods Transfer"}{" "}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Modal className="export-modal" isOpen={modalIsOpen}>
                  <ExportModal isClose={() => setModalIsOpen(false)} />
                </Modal>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div className="invoice-body" ref={componentRef}>
                <div className="header">
                  <div className="invoice-header">
                    <img src={dyarco} alt="dyarco" />
                    <div className="title">
                      <h4>GOODS TRANSFER</h4>
                    </div>
                  </div>
                </div>
                <div className="invoice-main-body">
                  <Row className="contents">
                    <Col md={8} sm={12}>
                      <Row>
                        <Col md={12}>
                          <h4>Acean Warehousing Company</h4>
                        </Col>
                        {/* <Col md={12}>
                          <span>
                            Aamal Tower, 6th Floor Omar Al Mukhthar Street Doha,
                          </span>
                        </Col>
                        <Col md={12}>
                          <span>Qatar, P.O. Box 3841, Doha, Qatar</span>
                        </Col> */}
                      </Row>
                    </Col>
                    <Col md={4} sm={12} className=" mt-3 mb-4 content-right">
                      <Row>
                        <Col md={4} xs={2}>
                          <strong>Tranfer #</strong>
                        </Col>
                        <Col className="text-left">
                          <strong> : {transferdata.transferNumber}</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} xs={2}>
                          <strong>Transfer Date</strong>
                        </Col>
                        <Col className="text-left">
                          <strong>: {transferdata.transferDate}</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} xs={2}>
                          <strong>Transfer Time</strong>
                        </Col>
                        <Col className="text-left">
                          <strong> : {transferdata.transferTime}</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} xs={2}>
                          <strong>Due Date</strong>
                        </Col>
                        <Col className="text-left">
                          <strong> : {transferdata.transferReason}</strong>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <h4 style={{ color: "#495057" }}>WAREHOUSE DETAILS</h4>
                    </Col>

                    <Col md={12}>
                      <span>
                        <strong>Source Warehouse: </strong>
                        {transferdata.sourceWarehouse},
                      </span>
                    </Col>
                    <Col md={12}>
                      <span>{transferdata.sourceWarehouseAddress}</span>
                    </Col>
                    <Col md={12}>
                      <span>
                        <strong>Destination Warehouse: </strong>
                        {transferdata.destinationWarehouse},
                      </span>
                    </Col>
                    <Col md={12}>
                      <span>{transferdata.destinationWarehouseAddress}</span>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <table class="table table-condensed table-bordered  fixed_headers">
                        <thead>
                          <tr>
                            <th className="text-center">
                              <span class="text-muted ">#</span>
                            </th>
                            <th> Item</th>
                            <th> Description</th>
                            <th>Source Stock</th>
                            <th>Destination Stock</th>
                            <th>Transfer Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transferdata.itemsList
                            ? transferdata.itemsList
                                // .filter((item) => item.invItemName !== "")
                                .map((items, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="text-center">
                                        <span class="text-muted ">
                                          {index + 1}
                                        </span>
                                      </td>
                                      <td>{items.itemName}</td>
                                      <td>{items.itemDescription}</td>
                                      <td>{items.sourceStock}</td>
                                      <td>{items.destinationStock}</td>
                                      <td>{items.transferQuantity}</td>
                                    </tr>
                                  );
                                })
                            : ""}
                        </tbody>
                      </table>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col md={12} className="text-right">
                      <div className="signature">Authorized Signature</div>
                      <span style={{ fontSize: "12px" }}>General Manager</span>
                    </Col>
                  </Row>
                  <Row>
                    {/*<Col md={12}>
                      <h6>
                        <strong>
                          Terms & condition :{" "}
                          {invoicedata.invoiceTermsAndConditions}
                        </strong>
                      </h6>
                              </Col>*/}
                  </Row>
                  <Row>
                    <Col md={12}>
                      <h6>
                        {/*<strong
                          style={{ fontStyle: "italic", color: "#1b6182" }}
                        >
                          {" "}
                          {invoicedata.invoiceCustomerNotes}
                            </strong>*/}
                      </h6>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default SingleGoodsTransfer;
