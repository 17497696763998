import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Input,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import ExportModal from "./exportModal";
import classnames from "classnames";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Formik, Field } from "formik";
//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";
import dyarco from "../../assets/dyarco/dyarco1.png";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
const SingleRetainerInvoiceView = (props) => {
  const history = useHistory();
  const componentRef = useRef();
  const [rtdata, setrtdata] = useState({});
  const [paymentTerm, setPaymentTerm] = useState({});
  function getRetainerInvoice() {
    const Url =
      BaseUrl.apiUrl.baseUrl +
      "api/v1/retainer_invoice/" +
      props.match.params.id;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setrtdata(resp.response.data.data);
    });
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "print-btn",
    pageStyle: "margin:50px",
  });
  useEffect(() => {
    getRetainerInvoice();
  }, []);
  // function getPaymentTermsById() {
  //   var paymentTermsid = invoicedata.invoicePaymentTermsId;
  //   const Url =
  //     BaseUrl.apiUrl.baseUrl + `api/v1/master/payment_terms/${paymentTermsid}`;
  //   let resp = apiGet(Url);
  //   resp.then((resp) => {
  //     if (resp?.response.data.code == 200) {
  //       setPaymentTerm(resp.response.data.data);
  //     }
  //   });
  // }
  // useEffect(() => {
  //   if (invoicedata?.invoicePaymentTerms !== "") {
  //     getPaymentTermsById();
  //   }
  // }, [invoicedata]);
  const [dropdownMonth, setMonth] = React.useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleMonth = () => setMonth(!dropdownMonth);
  const [dropdownFilter, setFilter] = React.useState(false);
  const toggleFilter = () => setFilter(!dropdownFilter);
  const [dropdownOpen, setOpen] = React.useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const handleBack = () => {
    history.goBack();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="singlepage">
          <Row>
            <Col md={12} className="item-head">
              <div className="backbutton" onClick={handleBack}>
                <i class="bx bx-arrow-back"></i>
              </div>
              <div className="bbb">
                <Link
                  to={`/addretainerinvoice/edit/${rtdata.retainerInvoiceId}`}
                >
                  <button>
                    <i class="bx bx-pencil"></i>
                  </button>
                </Link>
                <Dropdown
                  className="d-inline-block"
                  isOpen={socialDrp}
                  toggle={() => {
                    setsocialDrp(!socialDrp);
                  }}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect ddtoggle"
                    tag="button"
                  >
                    <button>
                      {" "}
                      {/* <i class="bx bx-menu"></i> */}
                      More
                    </button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {/* <DropdownItem tag="a" href="">
                      {" "}
                      <i className="bx bx-copy font-size-16 align-middle mr-1"></i>
                      {"Clone Item"}{" "}
                    </DropdownItem>
                    <hr />
                    <DropdownItem tag="a" href="#">
                      {" "}
                      <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                      {"Import Item"}{" "}
                    </DropdownItem>
                    <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                      {" "}
                      <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                      {"Export Item"}{" "}
                    </DropdownItem> */}
                    <DropdownItem tag="a" onClick={handlePrint}>
                      {" "}
                      <i className="bx bx-printer font-size-16 align-middle mr-1"></i>
                      {"Print Retainer Invoice"}{" "}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Modal className="export-modal" isOpen={modalIsOpen}>
                  <ExportModal isClose={() => setModalIsOpen(false)} />
                </Modal>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div className="invoice-body" ref={componentRef}>
                <div className="header">
                  <div className="invoice-header">
                    {/*<img src={dyarco} alt="dyarco" />*/}
                    <div className="title" style={{ height: 6 + "rem" }}>
                      <h4>RETAINER INVOICE</h4>
                    </div>
                  </div>
                </div>
                <div className="invoice-main-body">
                  <Row className="contents">
                    <Col md={8} sm={12}>
                      <Row>
                        <Col md={12}>
                          <h4>Opine Infotech Pvt. Ltd.</h4>
                        </Col>
                        <Col md={12}>
                          <span>Kochi,</span>
                        </Col>
                        <Col md={12}>
                          <span>Kerala</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4} sm={12} className=" mt-3 mb-4 content-right">
                      <Row>
                        <Col md={4} xs={2}>
                          <strong>Retainer Invoice #</strong>
                        </Col>
                        <Col className="text-left">
                          <strong> : {rtdata.retainerInvoiceNumber}</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} xs={2}>
                          <strong>Retainer Invocie Date</strong>
                        </Col>
                        <Col className="text-left">
                          <strong>: {rtdata.retainerInvoiceDate}</strong>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <h4 style={{ color: "#495057" }}>BILLING TO</h4>
                    </Col>
                    <Col md={12}>
                      <Link
                        to={`/singlecustomer/${rtdata.retainerInvoiceCustomerId}`}
                      >
                        <span
                          style={{
                            color: "#495057",
                            fontSize: "1.2rem",
                            fontWeight: "500",
                          }}
                        >
                          {rtdata.retainerinvoiceCustomerName}
                        </span>
                      </Link>
                    </Col>
                    <Col md={12}>
                      <span>
                        {rtdata.retainerinvoiceCustomerShippingAddress}
                      </span>
                    </Col>
                    <Col md={12}>
                      <span>{rtdata.retainerinvoiceCustomerContactNumber}</span>
                    </Col>
                    {/*<Col md={12}>
                      <span>{rtdata.invoiceEmailTo}</span>
                    </Col>*/}
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <table class="table table-condensed table-bordered  fixed_headers">
                        <thead>
                          <tr>
                            <th className="text-center">
                              <span class="text-muted ">#</span>
                            </th>
                            <th> Description</th>

                            <th class="change-sort-order text-right">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rtdata.retainerInvoiceItemsList
                            ? rtdata.retainerInvoiceItemsList
                                // .filter((item) => item.invItemName !== "")
                                .map((items, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="text-center">
                                        <span class="text-muted ">
                                          {index + 1}
                                        </span>
                                      </td>
                                      <td>{items.retInvItemDescription}</td>
                                      <td className="text-right">
                                        {items.retInvItemAmount}
                                      </td>
                                    </tr>
                                  );
                                })
                            : ""}
                          <tr className="border-none">
                            <td colSpan="2" className="border-none text-right">
                              <strong>Subtotal</strong>
                            </td>
                            <td className="border-none text-right">
                              {rtdata.retainerInvoiceTotal}
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="2" className="border-none text-right">
                              <strong>Grand Total</strong>
                            </td>
                            <td className="border-none text-right">
                              <h4 className="m-0">
                                {" "}
                                {rtdata.retainerInvoiceTotal}
                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col md={12} className="text-right">
                      <div className="signature">Authorized Signature</div>
                      <span style={{ fontSize: "12px" }}>General Manager</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <h6>
                        <strong>
                          Terms & condition :{" "}
                          {rtdata.retainerInvoiceTermsAndConditions}
                        </strong>
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <h6>
                        <strong
                          style={{ fontStyle: "italic", color: "#1b6182" }}
                        >
                          {" "}
                          {rtdata.retainerInvoiceCustomerNotes}
                        </strong>
                      </h6>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default SingleRetainerInvoiceView;
