import { useEffect, useState } from "react";
import { Modal, Form, Button, Row } from "react-bootstrap";
import "./calendar.css";
import ReactDatePicker from "react-datepicker";
import { Col } from "reactstrap";

const EventModal = ({
  event,
  modalOpen,
  setModalOpen,
  setAllEvents,
  allEvents,
  setNewEvent,
  newEvent,
  setSelectedEvent,
  slotInfo,
}) => {
  const [id, setId] = useState(event.id || "");
  const [title, setTitle] = useState(event.title || "");
  const [start, setStart] = useState(event.start || "");
  const [end, setEnd] = useState(event.end || "");

  useEffect(() => {
    if (event) {
      setId(event.id);
      setTitle(event.title);
      setStart(event.start);
      setEnd(event.end);
    }
    return () => {
      setId("");
      setTitle("");
      setStart("");
      setEnd("");
    };
  }, [newEvent, event]);

  // on submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (event.id) {
      // update event
      const updatedEvents = allEvents.map((evt) => {
        if (evt.id === event.id) {
          return { id, title, start, end };
        }
        return evt;
      });
      setAllEvents(updatedEvents);
    } else {
      setAllEvents([
        ...allEvents,
        {
          id: id,
          title: title,
          start: slotInfo.start || start,
          end: slotInfo.end || end,
        },
      ]);
    }
    setModalOpen(false);
    setId("");
    setTitle("");
    setStart("");
    setEnd("");
    setSelectedEvent({});
  };
  // on Delete
  const handleDelete = () => {
    setAllEvents(allEvents.filter((e) => e.id !== event.id));
    setId("");
    setTitle("");
    setStart("");
    setEnd("");
    setModalOpen(false);
    setSelectedEvent({});
  };
  //on Cancel
  const handleCancel = () => {
    setId("");
    setTitle("");
    setStart("");
    setEnd("");
    setModalOpen(false);
    setSelectedEvent({});
  };
  return (
    // <Modal
    //   show={modalOpen}
    //   size="lg"
    //   onHide={() => setModalOpen(false)}
    //   className={modalOpen ? "event-modal" : "show-modal"}
    // >
    //   <Modal.Header className="event-modal-header">
    //     <Modal.Title className="event-modal-title">
    //       {event.id ? "Edit Event" : "Add Event"}
    //     </Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
    //     <Form onSubmit={handleSubmit} className="event-modal-form">
    //       <Row>
    //         <Form.Group>
    //           {/* <Form.Label>Event Title</Form.Label> */}
    //           <Form.Control
    //             type="number"
    //             placeholder="Enter event Id"
    //             value={event.id || id}
    //             onChange={(e) => setId(e.target.value)}
    //             required
    //           />
    //         </Form.Group>
    //         <Form.Group>
    //           <Form.Control
    //             type="text"
    //             placeholder="Enter event title"
    //             value={event.title || title}
    //             onChange={(e) => setTitle(e.target.value)}
    //             required
    //           />
    //         </Form.Group>
    //         <Form.Group>
    //           <ReactDatePicker
    //             selected={start || newEvent.start}
    //             placeholderText="Start Date and Time"
    //             onChange={(date) => setStart(date)}
    //             minDate={new Date()}
    //             showTimeSelect
    //             timeFormat="HH:mm"
    //             timeIntervals={15}
    //             dateFormat="MMMM d, yyyy h:mm aa"
    //             timeCaption="time"
    //           />
    //         </Form.Group>
    //         <Form.Group>
    //           <ReactDatePicker
    //             selected={end || newEvent.end}
    //             placeholderText="End Date and Time"
    //             onChange={(date) => setEnd(date)}
    //             minDate={start}
    //             showTimeSelect
    //             timeFormat="HH:mm"
    //             timeIntervals={15}
    //             dateFormat="MMMM d, yyyy h:mm aa"
    //             timeCaption="time"
    //           />
    //         </Form.Group>
    //       </Row>
    //       <Row>
    //         <div className="button-container">
    //           <Button variant="primary" type="submit">
    //             Save
    //           </Button>
    //           {event.id ? (
    //             <Button variant="danger" onClick={handleDelete}>
    //               Delete
    //             </Button>
    //           ) : (
    //             ""
    //           )}
    //           <Button variant="secondary" onClick={handleCancel}>
    //             Cancel
    //           </Button>
    //         </div>
    //       </Row>
    //     </Form>
    //   </Modal.Body>
    // </Modal>
    <Modal
      className="export-modal"
      show={modalOpen}
      size="md"
      onHide={() => setModalOpen(false)}
      // className={modalOpen ? "event-modal" : "show-modal"}
    >
      <Row>
        {/* <div>
            <h5>Add New Event</h5>
          </div>
          <div onClick={() => setModalOpen(false)}>
            <i class="bx bx-x"></i>
          </div> */}
        <Col md={12} className="modal-head">
          <Modal.Header className="event-modal-header">
            <Modal.Title className="event-modal-title">
              {event.id ? "Edit Event" : "Add Event"}
            </Modal.Title>
          </Modal.Header>
        </Col>
      </Row>
      {/* <hr /> */}
      <Form onSubmit={handleSubmit} className="event-modal-form">
        <Row>
          <Col md={12}>
            <Form.Group className="row align-items-center">
              <Col md={12}>
                <Form.Label>Event ID</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  placeholder="Enter event Id"
                  value={event.id || id}
                  onChange={(e) => setId(e.target.value)}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group className="row align-items-center">
              <Col md={12}>
                <Form.Label>Event Title</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter event title"
                  value={event.title || title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group className="row align-items-center">
              <Col md={12}>
                <Form.Label>Event Start Date</Form.Label>
              </Col>
              <Col>
                <ReactDatePicker
                  className="form-control form-control-solid w-100 p-3"
                  selected={start || newEvent.start}
                  wrapperClassName="date_picker full-width"
                  placeholderText="Start Date and Time"
                  onChange={(date) => setStart(date)}
                  minDate={new Date()}
                  showTimeSelect
                  focusSelectedMonth
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="time"
                />
              </Col>
            </Form.Group>
            <Form.Group className="row align-items-center d-flex">
              <Col md={12}>
                <Form.Label>
                  {/* <strong> */}
                  Event End Date
                  {/* </strong> */}
                </Form.Label>
              </Col>
              <Col>
                <ReactDatePicker
                  className="form-control form-control-solid w-100 p-3"
                  selected={end || newEvent.end}
                  placeholderText="End Date and Time"
                  onChange={(date) => setEnd(date)}
                  minDate={start}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  timeCaption="time"
                />
              </Col>
            </Form.Group>
          </Col>{" "}
        </Row>
        <Row>
          <div className="button-container">
            {!event.id ? (
              <Button
                variant="primary"
                type="submit"
                style={{ marginRight: "1em" }}
              >
                Save
              </Button>
            ) : (
              ""
            )}
            {event.id ? (
              <Button
                variant="danger"
                onClick={handleDelete}
                style={{ marginRight: "1em" }}
              >
                Delete
              </Button>
            ) : (
              ""
            )}
            <Button
              variant="secondary"
              onClick={handleCancel}
              style={{ marginRight: "1em" }}
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </Modal>
  );
};
export default EventModal;
