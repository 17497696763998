import React, { useEffect } from "react";
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import axios from "axios";
import { BaseUrl } from "../../components/config/BaseUrl";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ForgetPasswordPage = (props) => {
  const [validationToken, setValidationToken] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const history = useHistory();

  function validateToken(data) {
    const Url = BaseUrl.apiUrl.baseUrl + `api/public/v1/validate_token/${data}`;
    fetch(Url)
      .then((response) => response.json())
      .then((data) => {
        // switch (data.status) {
        // case "Sucess":
        //   toast.success(data.data);
        // break;
        // case "Failed":
        //   toast.error(data.data);
        //   break;
        // default:
        //   toast.info(data.data);
        // }
      });
  }
  const handleValidSubmit = (event, values) => {
    var body = {
      email: values.email,
      newPassword: values.newPassword,
      token: validationToken,
    };
    const Url = BaseUrl.apiUrl.baseUrl + `api/public/v1/reset_password`;
    fetch(Url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        switch (data.status) {
          case "Success":
            setTimeout(() => {
              history.push("/reset-success");
            }, 1000);
            // toast.success(data.data);
            break;
          case "Failed":
            toast.error(data.data);
            break;
          default:
            toast.info(data.data);
        }
      });
  };
  useEffect(() => {
    setValidationToken(props.history.location.pathname.split("/")[2]);
    setUserEmail(props.history.location.pathname.split("/")[3]);
    validateToken(props.history.location.pathname.split("/")[2]);
  }, []);
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Change your password !</h5>
                        <p>Enter the details below.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter your email"
                          type="text"
                          value={userEmail}
                          disabled
                          required
                        />
                      </div>
                      <div className="form-group">
                        <AvField
                          name="newPassword"
                          label="New Password"
                          className="form-control"
                          placeholder="Enter new password"
                          type="password"
                          required
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-right">
                          {/* <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              
                            >
                              Reset
                              </button> */}
                          <button
                            type="submit"
                            className="btn btn-primary w-md waves-effect waves-light"
                          >
                            Submit
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                {/* <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Themesbrand
                </p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgetPasswordPage;
