import React from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { useCallback } from "react";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";
import { useRef } from "react";

function CustomerRentList() {
  const gridRef = useRef();
  const [customerlist, setcustomerlist] = useState([]);
  const current = new Date();
  const date = `${current.getFullYear()}-${
    current.getMonth() < 10
      ? `0${current.getMonth() + 1}`
      : current.getMonth() + 1
  }-${current.getDate() < 10 ? `0${current.getDate()}` : current.getDate()}`;
  const [searchenable, setsearchenable] = useState(false);
  const [customerId, setCustomerId] = useState({
    id: 0,
    name: "",
  });
  const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);
  const columnDefs = useMemo(
    () => [
      { field: "rentCargoCheckInNumber", headerName: "Check In Number" },
      { field: "rentWarehouseName", headerName: "Warehouse Name" },
      { field: "rentCbmUsedTotal", headerName: "CBM Used" },
      { field: "rentRatePerDay", headerName: "Rent Per Day" },
      { field: "rentAmountTotal", headerName: "Total Rent" },
    ],
    []
  );
  const [rowData, setRowData] = useState();
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    []
  );

  const onGridReady = useCallback((params) => {
    const updateData = (data) => params.api.setRowData(data);
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/rent_list/customer/${customerId.id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      updateData(resp.response.data.data);
    });
  }, []);

  function handleSearch(e) {
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/rent_list/customer/${customerId.id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setRowData(resp.response.data.data);
    });
  }
  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  function getCustomerData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setcustomerlist(resp.response.data.data);
    });
  }
  useEffect(() => {
    getCustomerData();
  }, []);
  const customer = customerlist.map((item) => {
    return {
      value: item.customerId,
      label: item.customerDisplayName,
    };
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>CUSTOMER RENT LIST</h6>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody className="tabcard">
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12} className="search-sec">
                          <AvForm
                            className="form"
                            onValidSubmit={(e) => handleSearch(e)}
                          >
                            <Row>
                              <Col md={2}>
                                <label>Select Customer</label>
                                <Select
                                  name="checkInWarehouseId"
                                  onChange={(e) =>
                                    setCustomerId({
                                      id: e.value,
                                      name: e.label,
                                    })
                                  }
                                  options={customer}
                                />
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col>
                                <div className="search-buttons">
                                  <button class=" btn reportbutton">
                                    Get Rent List
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12}>
                      <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                          ref={gridRef}
                          animateRows="true"
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          //   enableRangeSelection="true"
                          rowData={rowData}
                          rowSelection="multiple"
                          suppressRowClickSelection="true"
                          onGridReady={onGridReady}
                          onFirstDataRendered={onFirstDataRendered}
                          pagination={true}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default CustomerRentList;
