import React, { useMemo, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import { Row, Col } from "reactstrap";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";
import Axios from "axios";
// import Table from "./Table";
import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import dyarco from "../../assets/dyarco/dyarco.jpeg";
import FilterComponent from "./FilterComponent";
function CustomerCheckoutList() {
  const customerId = localStorage.getItem("customerId");
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }

  function toPages(pages) {
    const results = [];

    for (let i = 1; i < pages; i++) {
      results.push(i);
    }

    return results;
  }

  // const printPDF = (data) => {
  //   const Url = BaseUrl.apiUrl.baseUrl + `api/v1/cargo_checkout/${data}`;
  //   let resp = apiGet(Url);
  //   resp.then((resp) => {
  //     setprintdata(resp.response.data.data);
  //     handlePrint();
  //   });
  // };

  const columns = [
    {
      name: "Check Out Number",
      selector: (data) => data.checkOutNumber,
      sortable: true,
    },
    {
      name: "Date",
      selector: (data) => data.checkOutDate,
      sortable: true,
    },

    {
      name: "Warehouse Name",
      selector: (data) => data.checkOutWarehouseName,
      sortable: true,
      // right: true,
    },
    {
      name: "Customer Name",
      selector: (data) => data.checkOutCustomerName,
      sortable: true,
      // right: true,
    },

    {
      button: true,
      cell: (data) => (
        <div className="tablebtn">
          <Link to={`/viewcheckout/${data.checkOutId}`}>
            <button>View</button>
          </Link>
        </div>
      ),
    },
  ];
  const getData = async (page) => {
    setLoading(true);
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      // `api/v1/cargo_checkout/org?org_id=${organisationId}&page=${page}&size=${perPage}&sort=checkOutId,desc&searchText=${filterText}`;
      `api/v1/cargo_checkout/org/cus?org_id=${organisationId}&page=${page}&size=${perPage}&sort=checkOutId,desc&searchText=${filterText}&customer_id=${customerId}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setTotalRows(resp.response.data.data.totalElements);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    getData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/cargo_checkout/org/cus?org_id=${organisationId}&page=${page}&size=${newPerPage}&sort=checkOutId,desc&searchText=${filterText}&customer_id=${customerId}`;
    // `api/v1/cargo_checkout/org?org_id=${organisationId}&page=${page}&size=${newPerPage}&sort=checkOutId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setPerPage(newPerPage);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData(1);
  }, [filterText]);
  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  return (
    <div className="App">
      <div className="card">
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows
          subHeader
          subHeaderComponent={subHeaderComponent}
          subHeaderAlign="left"
        />
      </div>
    </div>
  );
}
export default CustomerCheckoutList;
