import React, { useMemo, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import movies from "./itemData";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
// import Table from "./Table";
import { apiGet } from "../../../components/config/apiConfig";
import { BaseUrl } from "../../../components/config/BaseUrl";
import FilterComponent from "./FilterComponent";
// import { ToastContainer, toast } from "react-toastify/dist/react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { customRowStyles } from "../../CommonComponents/datatable_styles";
function CustomerList() {
  const history = useHistory();
  const [formsendstatus, setformsendstatus] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [formSendId, setFormSendId] = useState("");
  // function getNumberOfPages(rowCount, rowsPerPage) {
  //   return Math.ceil(rowCount / rowsPerPage);
  // }

  // function toPages(pages) {
  //   const results = [];

  //   for (let i = 1; i < pages; i++) {
  //     results.push(i);
  //   }

  //   return results;
  // }
  const columns = [
    {
      name: "Name",
      selector: (data) => data.customerDisplayName,
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (data) => data.customerCompanyName,
      sortable: true,
    },

    {
      name: "Email",
      selector: (data) => data.customerEmail,
      sortable: true,
      // right: true,
    },
    {
      name: "Work Phone",
      selector: (data) => data.customerWorkPhone,
      sortable: true,
      // right: true,
    },
    {
      button: true,
      width: "150px",
      cell: (data) => {
        return (
          <div className="tablebtn">
            <button onClick={() => handleForm(data)} disabled={formsendstatus}>
              {formsendstatus && formSendId === data.customerId
                ? "Sending..."
                : "Send Form"}
            </button>
          </div>
        );
      },
    },
  ];
  // Calculate the maximum width for each column
  // const getColumnWidth = (rows, columnName) => {
  //   const maxWidth = 600; // Set a maximum width to avoid overly wide columns
  //   const headerWidth = columnName?.length * 15; // Estimate the header width
  //   const columnWidth = Math.max(
  //     ...rows.map((row) => String(row[columnName])?.length * 15),
  //     headerWidth
  //   );
  //   return Math.min(columnWidth, maxWidth);
  // };

  // // Generate the column configuration with adjusted widths
  // const adjustedColumns = columns?.map((column) => ({
  //   ...column,
  //   width: getColumnWidth(data, column.selector),
  // }));
  const handleForm = (data) => {
    setFormSendId(data.customerId);
    handleSendForm(data.customerId);
  };
  const handleSendForm = (data) => {
    setformsendstatus(true);
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/send_order_link/${data}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.status === "Sucess") {
        setformsendstatus(false);
        toast.success(`${resp.response.data.data}`);
      } else {
        setformsendstatus(false);
        toast.error(`${resp.response.data.data}`);
      }
    });
  };
  const getData = async (page) => {
    setLoading(true);
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/customers/org?org_id=${organisationId}&page=${page}&size=${perPage}&sort=customerId,desc&sort=customerFirstname,asc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setTotalRows(resp.response.data.data.totalElements);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    getData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/customers/org?org_id=${organisationId}&page=${page}&size=${newPerPage}&sort=customerId,desc&sort=customerFirstname,asc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setPerPage(newPerPage);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData(1);
  }, [filterText]);
  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const handleRowClick = (row) => {
    history.push(`/singlecustomer/${row.customerId}`);
  };
  return (
    <div className="App">
      <ToastContainer
        position="bottom-center"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="card">
        {/* {loading ? (
          <div className="tableLoading">
            <p>Loading Please wait...</p>
          </div>
        ) : ( */}
        <DataTable
          columns={columns}
          data={filteredItems}
          // progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows
          subHeader
          subHeaderComponent={subHeaderComponent}
          subHeaderAlign="left"
          onRowClicked={handleRowClick}
          highlightOnHover
          pointerOnHover
        />
        {/* )} */}
      </div>
    </div>
  );
}
export default CustomerList;
