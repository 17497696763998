import {
  FETCH_TABLE_DATA,
  SET_TABLE_DATA,
  UPDATE_TABLE_DATA,
} from "./actionTypes";

export const fetchTableData = (customerId) => ({
  type: FETCH_TABLE_DATA,
  payload: customerId,
});

export const setTableData = (data) => ({
  type: SET_TABLE_DATA,
  payload: data,
});
export const updateTableData = (value, index) => ({
  type: UPDATE_TABLE_DATA,
  payload: { value, index },
});
