import React from "react";
import { Col } from "reactstrap";
import { AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
const AddContactPerson = (props) => {
  const {
    contactPerson,
    handleDataChange,
    addAnotherContact,
    removeContact,
    isLastItem,
  } = props;
  //   const isLastItem = props.isLastItem || false;
  return (
    <>
      <Col md={4}>
        <label>Contact Name</label>
        <AvField
          name="contactFirstname"
          type="text"
          placeholder="Name"
          onChange={(e) => handleDataChange(e)}
          value={contactPerson.contactFirstname}
        ></AvField>
      </Col>
      <Col md={4}>
        <label>Contact Email</label>
        <AvField
          name="contactEmail"
          type="text"
          placeholder="Email"
          onChange={(e) => handleDataChange(e)}
          value={contactPerson.contactEmail}
        ></AvField>
      </Col>
      <Col md={4}>
        <label>Contact Phone</label>
        <div className="contact-last">
          <AvField
            name="contactWorkPhone"
            type="text"
            placeholder="Mobile"
            onChange={(e) => handleDataChange(e)}
            value={contactPerson.contactWorkPhone}
          ></AvField>
          {isLastItem ? (
            <button
              type="button"
              className="contact-btn-a"
              onClick={addAnotherContact}
            >
              <i class="fa fa-plus-circle" aria-hidden="true"></i>
            </button>
          ) : (
            <button
              type="button"
              className="contact-btn-d"
              onClick={removeContact}
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          )}
        </div>
      </Col>
    </>
  );
};

export default AddContactPerson;
