import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Input,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { Tooltip as ReactTooltip } from "react-tooltip";
//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";
import dyarco from "../../assets/dyarco/dyarco.jpeg";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

const CustomerCheckInSingleView = (props) => {
  const componentRef = useRef();
  var id = props.match.params.id;
  var customerId = localStorage.getItem("customerId");
  var current = new Date();
  const [pendingOrder, setPendingOrder] = useState(false);
  const [pendingOrdermsg, setPendingOrderMsg] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1 < 10
      ? `0${current.getMonth() + 1}`
      : current.getMonth() + 1
  }-${current.getDate() < 10 ? `0${current.getDate()}` : current.getDate()}`;
  const [checkindata, setcheckindata] = useState({});
  function getCheckInData() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/cargo_checkin/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setcheckindata(resp.response.data.data);

      var index = resp.response.data.data.dateCreated.indexOf("T");
      var text = resp.response.data.data.dateCreated.substring(0, index);
      setCreatedDate(text);
    });
  }
  function checkHasPendingOrder() {
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/public/v1/check_pending_orders/${customerId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.status === "Failed") {
        setPendingOrder(true);
        setPendingOrderMsg(resp.response.data.data);
      } else {
        setPendingOrder(false);
        setPendingOrderMsg("");
      }
      // setcheckindata(resp.response.data.data);
    });
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "print-btn",
    pageStyle: "margin:50px",
  });
  useEffect(() => {
    getCheckInData();
    checkHasPendingOrder();
  }, [id]);
  const [dropdownMonth, setMonth] = React.useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleMonth = () => setMonth(!dropdownMonth);
  const [dropdownFilter, setFilter] = React.useState(false);
  const toggleFilter = () => setFilter(!dropdownFilter);
  const [dropdownOpen, setOpen] = React.useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="singlepage">
          <Row>
            <Col md={12} className="item-head">
              <div>{/* <h5>Fyroz M Haneefa </h5> */}</div>
              <div className="bbb">
                {/* {createdDate == date ? (
                  <Link to={`/editcargocheckin/edit/${id}`}>
                    <button>
                      <i class="bx bx-pencil"></i>
                    </button>
                  </Link>
                ) : (
                  ""
                )} */}

                {checkindata?.checkInStatus !== 1 && !pendingOrder ? (
                  <Link
                    to={`/customer-checkout/${checkindata?.checkInNumber}/${id}`}
                  >
                    <button
                      style={{
                        border: "none",
                        background: "#2650f6",
                        color: "white",
                      }}
                    >
                      Checkout Items
                    </button>
                  </Link>
                ) : (
                  <>
                    <div className="hover-text">
                      Checkout not available{" "}
                      <i class="bx bx-help-circle" id="app-title"></i>
                    </div>
                    <ReactTooltip
                      anchorId="app-title"
                      place="bottom"
                      content={
                        pendingOrder
                          ? pendingOrdermsg
                          : "Your checkin has not been verified. Please contact your warehouse assistant"
                      }
                      style={{ zIndex: "1" }}
                      // className="tooltip"
                    />
                  </>
                )}

                <Dropdown
                  className="d-inline-block"
                  isOpen={socialDrp}
                  toggle={() => {
                    setsocialDrp(!socialDrp);
                  }}
                >
                  <DropdownToggle
                    className="btn header-item waves-effect ddtoggle"
                    tag="button"
                  >
                    <button>
                      {" "}
                      {/* <i class="bx bx-menu"></i> */}
                      More
                    </button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {/* <DropdownItem tag="a" href="">
                      {" "}
                      <i className="bx bx-copy font-size-16 align-middle mr-1"></i>
                      {"Clone Item"}{" "}
                    </DropdownItem> */}
                    {/* <hr /> */}
                    {/* <DropdownItem tag="a" href="#">
                      {" "}
                      <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                      {"Import Item"}{" "}
                    </DropdownItem>
                    <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                      {" "}
                      <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                      {"Export Item"}{" "}
                    </DropdownItem> */}
                    <DropdownItem tag="a" onClick={handlePrint}>
                      {" "}
                      <i className="bx bx-printer font-size-16 align-middle mr-1"></i>
                      {"Print Item"}{" "}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Col md={12} className="checkout-right">
                <div className="checkout-pdf" ref={componentRef}>
                  <Row>
                    <Col>
                      <img src={dyarco} alt="dyarco" className="slip-image" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="text-center">
                      <h4>GRN NOTE</h4>
                    </Col>
                    <Col md={12} className="mt-3 d-flex">
                      <h6 style={{ width: "70%" }}>
                        GRN NUMBER :{" "}
                        <strong>{checkindata?.checkInNumber}</strong>
                      </h6>
                      <h6>
                        QUOTATION NO. :{" "}
                        <strong>{checkindata?.checkInQuotationNumber}</strong>
                      </h6>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12} className="mt-3 d-flex">
                      <h6 style={{ width: "70%" }}>
                        WAREHOUSE :{" "}
                        <strong>{checkindata?.checkInWarehouseName}</strong>
                      </h6>
                      <h6>
                        RATE/CBM :{" "}
                        <strong>{checkindata?.checkInRatePerCbm}</strong>
                      </h6>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mt-3">
                    <Col md={12} className="mt-3 d-flex">
                      <div style={{ width: "70%" }}>
                        <h6>CUSTOMER</h6>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span>
                            <strong>{checkindata?.checkInCustomerName}</strong>
                          </span>
                          <span>
                            <strong>
                              {checkindata?.checkInCustomerAddress}
                            </strong>
                          </span>{" "}
                          <span>
                            <strong>{checkindata?.checkInCustomerEmail}</strong>
                          </span>
                          <span>
                            <strong>{checkindata?.checkInCustomerPhone}</strong>
                          </span>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <h6>
                            DRIVER NAME :{" "}
                            <strong>{checkindata?.checkInDriverName}</strong>
                          </h6>

                          <h6 className="mt-3">
                            DRIVER LICENSE NO :{" "}
                            <strong>
                              {checkindata?.checkInDrivingLicense}
                            </strong>
                          </h6>
                          <h6 className="mt-2">
                            VEHICLE NUMBER :{" "}
                            <strong>{checkindata?.checkInVehicleNumber}</strong>
                          </h6>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12} className="mt-3 d-flex">
                      <h6>
                        ESTIMATED COMMODITY VALUE :{" "}
                        <strong>
                          {checkindata?.checkInEstimatedCommodityValue}
                        </strong>
                      </h6>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12}>
                      <strong>Cargo Details</strong>
                    </Col>
                    <Col md={12} className="mt-3">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col" style={{ textAlign: "center" }}>
                                #
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Goods Reference No.
                              </th>
                              <th scope="col" width="10">
                                Commodity
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Invoice No.
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                HSN
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                UOM
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Units
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Total Items
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Temperature
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Handling Info
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Date of expiry
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Batch
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Pallet No.
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Weight
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Dimension
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                CBM
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                SQM
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Remarks
                              </th>
                              <th scope="col" style={{ textAlign: "center" }}>
                                Cargo Condition
                              </th>
                            </tr>
                          </thead>
                          {checkindata.itemsList &&
                            checkindata.itemsList.map((item, index) => (
                              <tbody>
                                <tr>
                                  <th
                                    style={{ textAlign: "center" }}
                                    scope="row"
                                  >
                                    {index + 1}
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    {item.itemReferenceNumber}
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    {item.itemCommodityName}
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    {item.itemInvoiceNumber}
                                  </th>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemHsnCode}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemUom}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemUnits}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemUnitsPerSinglePackage}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemTemperature}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemHandlingInfo}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemDateOfExpiry}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemBatch}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemPallet}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {item.itemTotalWeight}
                                  </td>
                                  <tr>
                                    <td>
                                      <strong>L</strong>
                                      <br /> {item.itemLength}
                                    </td>
                                    <td>
                                      {" "}
                                      <strong>W</strong>
                                      <br />
                                      {item.itemBreadth}
                                    </td>
                                    <td>
                                      {" "}
                                      <strong>H</strong>
                                      <br />
                                      {item.itemHeight}
                                    </td>
                                  </tr>
                                  <td>{item.itemArea}</td>
                                  <td>{item.itemAreaSqm}</td>
                                  <td>{item.itemRemarks}</td>
                                  <td>{item.itemCargoCondition}</td>
                                </tr>
                              </tbody>
                            ))}
                        </table>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <strong>Other Charges</strong>
                    </Col>
                    <Col md={6} className="mt-3">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {checkindata.chargeList &&
                            checkindata.chargeList.map((item, index) => {
                              return (
                                <tr>
                                  <th width="10">{index + 2}</th>
                                  <th>{item.chargeName}</th>
                                  <td>{item.chargeAmount}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </div>
              </Col>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default CustomerCheckInSingleView;
