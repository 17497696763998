import {
  ADD_PALLET_TO_CLOSE,
  SET_PALLET_TO_CLOSE,
  REQUEST_PALLET_TO_MERGE,
  SET_PALLET_TO_MERGE,
  UPDATE_PALLET_TO_MERGE_DATA,
  CLEAR_PALLET_MERGE_TABLE_DATAS,
} from "./actionTypes";

export const addPalletToClose = (itemData) => ({
  type: ADD_PALLET_TO_CLOSE,
  payload: itemData,
});

const setPalletToClose = (item) => ({
  type: SET_PALLET_TO_CLOSE,
  payload: { item },
});
export const requestPalletToMerge = (itemData) => ({
  type: REQUEST_PALLET_TO_MERGE,
  payload: itemData,
});
const setPalletToMerge = (item) => ({
  type: SET_PALLET_TO_MERGE,
  payload: { item },
});
export const updatePalletToMergeData = (value, index) => ({
  type: UPDATE_PALLET_TO_MERGE_DATA,
  payload: { value, index },
});
export const clearPalletMergeTableDatas = () => ({
  type: CLEAR_PALLET_MERGE_TABLE_DATAS,
});
