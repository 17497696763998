import React, { useState, useEffect } from "react";

import { Link, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";

import { Formik, Field, ErrorMessage } from "formik";

import TermsAndConditions from "./termsAndConditions";
import MoreInfoTabs from "./moreInfoTabs";
import ContactPersons from "./contactPersons";
import ShippingAddress from "./shippingAddress";
import BillingAddress from "./billingAddress";
import ReportingTags from "./reportingTags";
import Remarks from "./remarks";
import { apiGet, apiPost } from "../../components/config/apiConfig";
import loader from "../../assets/loader/load.gif";
import { BaseUrl } from "../../components/config/BaseUrl";
// import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//i18n

const AddVendorForm = (props) => {
  const [currency, setCurrency] = useState([]);
  const [state, setState] = useState([]);
  const [country, setCountry] = useState([]);
  const [tax, setTax] = useState([]);
  const [paymentTermsList, setPaymentTerms] = useState([]);
  const [gstTreatment, setGstTreatment] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [copyAddress, setCopyAddress] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [reload, setReload] = useState(false);

  const [display, setDisplay] = useState(false);
  const history = useHistory();
  // const history = useHistory();
  const showInput = () => {
    setDisplay(!display);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const addTableRows = () => {
    const rowsInput = {
      contactSalutation: "",
      contactFirstname: "",
      contactLastname: "",
      contactEmail: "",
      contactWorkPhone: "",
      contactMobile: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };
  function getCurrency() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/currencies`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      console.log("currency list", resp);
      if (resp.response.data.code == 200) {
        setCurrency(resp.response.data.data);
      }
    });
  }
  function getCountry() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/countries`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      // console.log("country list", resp);
      if (resp.response.data.code == 200) {
        setCountry(resp.response.data.data);
      }
    });
  }
  function getState() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/states/101`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      // console.log("place list", resp);
      if (resp.response.data.code == 200) {
        setState(resp.response.data.data);
      }
    });
  }
  function getTax() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/taxes`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      // console.log("place list", resp);
      if (resp.response.data.code == 200) {
        setTax(resp.response.data.data);
      }
    });
  }
  function getPaymentTerms() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/payment_terms`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      // console.log("place list", resp);
      if (resp.response.data.code == 200) {
        setPaymentTerms(resp.response.data.data);
      }
    });
  }
  function getGstTreatment() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/gst_treatments`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      // console.log("place list", resp);
      if (resp.response.data.code == 200) {
        setGstTreatment(resp.response.data.data);
      }
    });
  }
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };
  const handleCancel = () => {
    setTimeout(() => {
      history.push("/vendors");
    }, 1000);
  };
  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
    // console.log("table data", rowsInput);
  };

  useEffect(() => {
    getCurrency();
    getCountry();
    getState();
    getTax();
    getGstTreatment();
    getPaymentTerms();
  }, []);

  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Formik
        initialValues={{
          mode: "1",
          addretinvoice: false,
          salutation: "",
          fname: "",
          lname: "",
          companyName: "",
          vendorDisplayName: "",
          vendorEmail: "",
          vendorPhone: "",
          skype: "",
          designation: "",
          department: "",
          vendorMobile: "",
          website: "",
          gst: "",
          gstIN: "",
          sourceOfSupply: "",
          currency: "",
          openingBalance: "",
          paymentTerms: "",
          tds: "",
          attention: "",
          country: "",
          street1: "",
          street2: "",
          city: "",
          state: "",
          zip: "",
          Branch: "",
          phone: "",
          fax: "",
          sattention: "",
          scountry: "",
          sstreet1: "",
          sstreet2: "",
          scity: "",
          sstate: "",
          szip: "",
          sphone: "",
          sfax: "",
          reportingTab: "",
          remarks: "",
          pan: "",
          facebook: "",
          twitter: "",
        }}
        // validate={(values) => {
        //   const errors = {};
        //   if (!values.fname) {
        //     errors.fname = "Required";
        //   } else {
        //     errors.email = "Invalid email address";
        //   }
        //   return errors;
        // }}
        onSubmit={(values, { setSubmitting }) => {
          console.log("estimate datas", rowsData);
          setReload(true);
          let orgId = localStorage.getItem("organisationId");
          let url = BaseUrl.apiUrl.baseUrl + "api/v1/vendors";
          let body = {
            vendorSalutation: values.salutation,
            vendorFirstname: values.fname,
            vendorLastname: values.lname,
            vendorCompanyName: values.companyName,
            vendorDisplayName: values.vendorDisplayName,
            vendorEmail: values.vendorEmail,
            vendorWorkPhone: values.vendorPhone,
            vendorMobile: values.vendorMobile,
            vendorDesignation: values.designation,
            vendorDepartment: values.department,
            vendorWebsite: values.website,
            vendorGstTreatmentId: parseInt(values.gst),
            vendorPlaceOfSupplyId: parseInt(values.sourceOfSupply),
            vendorTaxPreference: 0,
            vendorCurrency: values.currency,
            vendorOpeningBalance: values.openingBalance,
            vendorPaymentTerms: values.paymentTerms,
            vendorTds: values.tds,
            vendorPriceList: "",
            vendorSkypeName: values.skype,
            vendorFacebook: values.facebook,
            vendorTwitter: values.twitter,
            vendorRemarks: values.remarks,
            organizationId: orgId,
            branchId: 1,
            addedByUser: 1,
            editByUser: 1,
            vendorAddresses: [
              {
                addressType: 1,
                addressAttention: values.attention,
                addressCountry: values.country,
                addressLine1: values.street1,
                addressLine2: values.street2,
                addressCity: values.city,
                addressStateId: values.state,
                addressZipCode: values.zip,
                addressPhone: values.phone,
                addressFax: values.fax,
                addedByUser: 1,
                editByUser: 1,
              },
              {
                addressType: 2,
                addressAttention: copyAddress
                  ? values.attention
                  : values.sattention,
                addressCountry: copyAddress ? values.country : values.scountry,
                addressLine1: copyAddress ? values.street1 : values.sstreet1,
                addressLine2: copyAddress ? values.street2 : values.sstreet2,
                addressCity: copyAddress ? values.city : values.scity,
                addressStateId: copyAddress ? values.state : values.sstate,
                addressZipCode: copyAddress ? values.zip : values.szip,
                addressPhone: copyAddress ? values.phone : values.sphone,
                addressFax: copyAddress ? values.fax : values.sfax,
                addedByUser: 1,
                editByUser: 1,
              },
            ],
            contactPersons: rowsData,
          };
          let resp = apiPost(url, body);
          resp.then((resp) => {
            console.log("estimate data is", resp.response.data);
            setReload(false);
            switch (resp.response.data.code) {
              case 200:
                toast.success(resp.response.data.data);
                setTimeout(() => {
                  history.push("/vendors");
                  // useHistory().push("/customerlist");
                }, 1000);
                break;
              default:
                toast.error(resp.response.data.data);
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="formik" id="addVendor">
            <Row>
              <Col md={2}>
                <label className="formik-label"> Primary Contact</label>
              </Col>
              <Col md={10} className="formik-rowName">
                <Field
                  as="select"
                  name="salutation"
                  className="inputs"
                  onChange={handleChange}
                  value={values.salutation}
                >
                  <option value="" disabled selected>
                    Salutation
                  </option>
                  <option value="Mr">Mr.</option>
                  <option value="Mrs">Mrs.</option>
                  <option value="Ms">Ms.</option>
                  <option value="Miss">Miss.</option>
                  <option value="Cr">Dr.</option>
                </Field>
                <Field
                  type="text"
                  name="fname"
                  placeholder="First Name"
                  className="inputs"
                  onChange={handleChange}
                  value={values.fname}
                ></Field>
                <div>{/* <ErrorMessage name="fname" /> */}</div>
                <Field
                  type="text"
                  name="lname"
                  placeholder="Last Name"
                  className="inputs"
                  onChange={handleChange}
                  value={values.lname}
                ></Field>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Company Name</label>
              </Col>
              <Col md={10} className="formik-input-search">
                <Field
                  type="text"
                  name="companyName"
                  placeholder="Company name"
                  className="formik-input"
                  onChange={handleChange}
                  value={values.companyName}
                ></Field>
                <ErrorMessage name="fname" />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Vendor Display Name</label>
              </Col>
              <Col md={10}>
                <Field
                  type="text"
                  name="vendorDisplayName"
                  placeholder="Vendor Display Name"
                  className="formik-input"
                  onChange={handleChange}
                  value={values.vendorDisplayName}
                  required
                ></Field>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Vendor Email</label>
              </Col>
              <Col md={10}>
                <Field
                  type="email"
                  name="vendorEmail"
                  placeholder="Vendor email"
                  className="formik-input"
                  onChange={handleChange}
                  value={values.vendorEmail}
                ></Field>
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <label className="formik-label"> Vendor Phone</label>
                <div>
                  <span className="copy-address" onClick={showInput}>
                    Add more details
                  </span>
                </div>
              </Col>
              <Col md={10} className="formik-rowName">
                <Field
                  type="number"
                  name="vendorPhone"
                  placeholder="Work phone"
                  className="inputs"
                  onChange={handleChange}
                  value={values.vendorPhone}
                ></Field>
                <Field
                  type="number"
                  name="vendorMobile"
                  placeholder="Mobile"
                  className="inputs"
                  onChange={handleChange}
                  value={values.vendorMobile}
                ></Field>
              </Col>
            </Row>
            {display ? (
              <div>
                <Row>
                  <Col md={2}>
                    <label className="formik-label">Skype Name/Number</label>
                  </Col>
                  <Col md={10}>
                    <Field
                      type="text"
                      name="skype"
                      placeholder="Skype Name/Number"
                      className="formik-input"
                      onChange={handleChange}
                      value={values.skype}
                    ></Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label className="formik-label"> Designation</label>
                  </Col>
                  <Col md={10}>
                    <Field
                      type="text"
                      name="designation"
                      placeholder="Designation"
                      className="formik-input"
                      onChange={handleChange}
                      value={values.designation}
                    ></Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label className="formik-label">Department</label>
                  </Col>
                  <Col md={10}>
                    <Field
                      type="text"
                      name="department"
                      placeholder="Department "
                      className="formik-input"
                      onChange={handleChange}
                      value={values.department}
                    ></Field>
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}

            <Row>
              <Col md={2}>
                <label className="formik-label"> Website</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="text"
                  name="website"
                  onChange={handleChange}
                  value={values.website}
                ></Field>
              </Col>
            </Row>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Other Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Address
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  Contact Persons
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "4",
                  })}
                  onClick={() => {
                    toggle("4");
                  }}
                >
                  Custom Fields
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "5",
                  })}
                  onClick={() => {
                    toggle("5");
                  }}
                >
                  Reporting Tags
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "6",
                  })}
                  onClick={() => {
                    toggle("6");
                  }}
                >
                  Remarks
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              {/* //
              //OtherDetails
              // */}
              <TabPane tabId="1">
                <Row>
                  <Col md={2}>
                    <label className="formik-label"> GST Treatment</label>
                  </Col>
                  <Col md={10}>
                    <Field
                      className="formik-input"
                      as="select"
                      name="gst"
                      onChange={handleChange}
                      value={values.gst}
                      required
                    >
                      <option value="" selected disabled>
                        Select a GST Treatment
                      </option>
                      {gstTreatment.map((gt) => (
                        <option value={gt.id}>{gt.gstTreatmentName}</option>
                      ))}
                    </Field>
                  </Col>
                </Row>
                {values.gst !== "3" && values.gst !== "" ? (
                  <Row>
                    <Col md={2}>
                      <label className="formik-label"> GSTIN / UIN</label>
                    </Col>
                    <Col md={10}>
                      <Field
                        type="text"
                        name="gstIN"
                        placeholder="Enter GSTIN"
                        className="formik-input"
                        onChange={handleChange}
                        value={values.gstIN}
                        required
                      ></Field>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Row>
                  <Col md={2}>
                    <label className="formik-label"> PAN</label>
                  </Col>
                  <Col md={10}>
                    <Field
                      type="text"
                      name="pan"
                      placeholder="Enter PAN"
                      className="formik-input"
                      onChange={handleChange}
                      value={values.pan}
                    ></Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label className="formik-label"> Source of Supply </label>
                  </Col>
                  <Col md={10}>
                    <Field
                      className="formik-input"
                      as="select"
                      name="sourceOfSupply"
                      onChange={handleChange}
                      value={values.sourceOfSupply}
                      required
                    >
                      <option value="" selected disabled>
                        Select source of supply
                      </option>
                      {state.map((states) => (
                        <option value={states.stateId}>
                          {[states.stateCode]}-{states.stateName}
                        </option>
                      ))}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label className="formik-label"> Currency</label>
                  </Col>
                  <Col md={10}>
                    <Field
                      className="formik-input"
                      as="select"
                      name="currency"
                      onChange={handleChange}
                      value={values.currency}
                      required
                    >
                      <option value="" selected disabled>
                        Select currency
                      </option>
                      {currency.map((curr) => (
                        <option value={curr.currencyCode}>
                          {[curr.currencyCode]}-{curr.currencyName}
                        </option>
                      ))}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label className="formik-label"> Opening Balance</label>
                  </Col>
                  <Col md={10} className="formik-rowName">
                    <Field
                      className="inputs"
                      as="select"
                      name="Branch"
                      placeholder="Branch"
                      onChange={handleChange}
                      value={values.Branch}
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      <option>Head Office</option>
                    </Field>
                    <Field
                      type="text"
                      name="openingBalance"
                      placeholder="$"
                      className="inputs"
                    ></Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label className="formik-label">Payment Terms</label>
                  </Col>
                  <Col md={10}>
                    <Field
                      className="formik-input"
                      as="select"
                      name="paymentTerms"
                      onChange={handleChange}
                      value={values.paymentTerms}
                      required
                    >
                      <option value="" selected disabled>
                        Select Payment Terms
                      </option>
                      {paymentTermsList.map((payment) => (
                        <option value={payment.paymentTermId}>
                          {payment.paymentTermName}
                        </option>
                      ))}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label className="formik-label">TDS</label>
                  </Col>
                  <Col md={10}>
                    <Field
                      className="formik-input"
                      as="select"
                      name="tds"
                      onChange={handleChange}
                      value={values.tds}
                    >
                      <option>Select a Tax</option>
                      {tax.map((tx) => (
                        <option value={tx.taxName}>{[tx.taxName]}</option>
                      ))}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label className="formik-label"> Facebook</label>
                  </Col>
                  <Col md={10}>
                    <Field
                      type="text"
                      name="facebook"
                      placeholder="Enter Facebook"
                      className="formik-input"
                      onChange={handleChange}
                      value={values.facebook}
                      // required
                    ></Field>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label className="formik-label"> Twitter</label>
                  </Col>
                  <Col md={10}>
                    <Field
                      type="text"
                      name="twitter"
                      placeholder="Enter Twitter"
                      className="formik-input"
                      onChange={handleChange}
                      value={values.twitter}
                      // required
                    ></Field>
                  </Col>
                </Row>
              </TabPane>
              {/* //
              // address //
              // */}
              <TabPane tabId="2">
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col md={12}>
                        <h5>Billing Address</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label"> Attention</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabinput"
                          type="text"
                          name="attention"
                          onChange={handleChange}
                          value={values.attention}
                        ></Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label">
                          {" "}
                          Country / Region
                        </label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabinput"
                          as="select"
                          name="country"
                          onChange={handleChange}
                          value={values.country}
                        >
                          <option value="" selected disabled>
                            Select
                          </option>
                          {country.map((countries) => (
                            <option value={countries.countryCode}>
                              {countries.countryName}
                            </option>
                          ))}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label"> Address</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabtextarea"
                          as="textarea"
                          name="street1"
                          placeholder="Street 1"
                          onChange={handleChange}
                          value={values.street1}
                        ></Field>
                        <Field
                          className="formik-tabtextarea"
                          as="textarea"
                          name="street2"
                          placeholder="Street 2"
                          onChange={handleChange}
                          value={values.street2}
                        ></Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label"> City</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabinput"
                          type="text"
                          name="city"
                          onChange={handleChange}
                          value={values.city}
                        ></Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label"> State</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabinput"
                          as="select"
                          name="state"
                          onChange={handleChange}
                          value={values.state}
                        >
                          <option value="" selected disabled>
                            Select a state
                          </option>
                          {state.map((states) => (
                            <option value={states.stateId}>
                              {[states.stateCode]}-{states.stateName}
                            </option>
                          ))}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label"> Zip Code</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabinput"
                          type="text"
                          name="zip"
                          onChange={handleChange}
                          value={values.zip}
                        ></Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label"> Phone</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabinput"
                          type="number"
                          name="phone"
                          onChange={handleChange}
                          value={values.phone}
                        ></Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label"> Fax</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabinput"
                          type="text"
                          name="fax"
                          onChange={handleChange}
                          value={values.fax}
                        ></Field>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={12}>
                        <div className="jsc">
                          <h5>Shipping Address</h5>
                          <div
                            className="align-center copy-address"
                            onClick={() => setCopyAddress(!copyAddress)}
                          >
                            <i className=" mr-1 bx bxs-down-arrow-alt"></i>
                            <span>Copy billing address</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label"> Attention</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabinput"
                          type="text"
                          name="sattention"
                          onChange={handleChange}
                          value={
                            copyAddress === true
                              ? values.attention
                              : values.sattention
                          }
                        ></Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label">
                          {" "}
                          Country / Region
                        </label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabinput"
                          as="select"
                          name="scountry"
                          onChange={handleChange}
                          value={
                            copyAddress === true
                              ? values.country
                              : values.scountry
                          }
                        >
                          <option value="" selected disabled>
                            Select
                          </option>
                          {country.map((countries) => (
                            <option value={countries.countryCode}>
                              {countries.countryName}
                            </option>
                          ))}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label"> Address</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabtextarea"
                          as="textarea"
                          name="sstreet1"
                          placeholder="Street 1"
                          onChange={handleChange}
                          value={
                            copyAddress === true
                              ? values.street1
                              : values.sstreet1
                          }
                        ></Field>
                        <Field
                          className="formik-tabtextarea"
                          as="textarea"
                          name="sstreet2"
                          placeholder="Street 2"
                          onChange={handleChange}
                          value={
                            copyAddress === true
                              ? values.street2
                              : values.sstreet2
                          }
                        ></Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label"> City</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabinput"
                          type="text"
                          name="scity"
                          onChange={handleChange}
                          value={
                            copyAddress === true ? values.city : values.scity
                          }
                        ></Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label"> State</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabinput"
                          as="select"
                          name="sstate"
                          onChange={handleChange}
                          value={
                            copyAddress === true ? values.state : values.sstate
                          }
                        >
                          <option value="" selected disabled>
                            Select a state
                          </option>
                          {state.map((states) => (
                            <option value={states.stateId}>
                              {[states.stateCode]}-{states.stateName}
                            </option>
                          ))}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label"> Zip Code</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabinput"
                          type="text"
                          name="szip"
                          value={
                            copyAddress === true ? values.zip : values.szip
                          }
                        ></Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label"> Phone</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabinput"
                          type="number"
                          name="sphone"
                          onChange={handleChange}
                          value={
                            copyAddress === true ? values.phone : values.sphone
                          }
                        ></Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="formik-label"> Fax</label>
                      </Col>
                      <Col md={8}>
                        <Field
                          className="formik-tabinput"
                          type="text"
                          name="sfax"
                          onChange={handleChange}
                          value={
                            copyAddress === true ? values.fax : values.sfax
                          }
                        ></Field>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="dflex ">
                      <div className="yellow-bar mr-3"></div>
                      <div>
                        <span>Note:</span>
                        <ul className="mt-2">
                          <li>
                            You can add and manage additional addresses from
                            contact details section.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              {/* //
              // Contact Persons
              // */}
              <TabPane tabId="3">
                <div className="row clearfix">
                  <div className="col-md-12 column">
                    <table
                      className="table table-bordered invoice-table"
                      id="tab_logic"
                    >
                      <thead>
                        <tr>
                          <th className="text-left"> Salutation </th>
                          <th className="text-left"> First Name</th>
                          <th className="text-left"> Last Name</th>
                          <th className="text-right"> Email Address</th>
                          <th className="text-right"> Work Phone</th>
                          <th className="text-right"> Mobile </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {rowsData.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <select
                                  name="contactSalutation"
                                  onChange={(evnt) =>
                                    handleTableChange(index, evnt)
                                  }
                                >
                                  <option value="" selected>
                                    Select
                                  </option>
                                  <option>Mr.</option>
                                  <option>Mrs.</option>
                                  <option>Ms.</option>
                                  <option>Miss.</option>
                                  <option>Dr.</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="contactFirstname"
                                  className="form-control"
                                  value={data.contactFirstname}
                                  onChange={(evnt) =>
                                    handleTableChange(index, evnt)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="contactLastname"
                                  className="form-control"
                                  value={data.contactLastname}
                                  onChange={(evnt) =>
                                    handleTableChange(index, evnt)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="contactEmail"
                                  className="form-control"
                                  value={data.contactEmail}
                                  onChange={(evnt) =>
                                    handleTableChange(index, evnt)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="contactWorkPhone"
                                  className="form-control"
                                  value={data.contactWorkPhone}
                                  onChange={(evnt) =>
                                    handleTableChange(index, evnt)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="contactMobile"
                                  className="form-control"
                                  value={data.contactMobile}
                                  onChange={(evnt) =>
                                    handleTableChange(index, evnt)
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <span
                                  className="btn btn-outline-danger"
                                  onClick={(e) => deleteTableRows(index, e)}
                                >
                                  x
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <Row>
                      <Col md={6} className="table-left">
                        <div>
                          <span
                            className="btn btn-default pull-left add-tablerow"
                            onClick={addTableRows}
                          >
                            <i class="bx bxs-plus-circle"></i>
                            <span>Add Contact Person</span>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="4">
                <div>
                  Start adding custom fields for your contacts by going to
                  Settings - Preferences - Customers and Vendors. You can also
                  refine the address format of your customers from there.
                </div>
              </TabPane>
              <TabPane tabId="5">
                <Row>
                  <Col md={2}>
                    <label className="formik-label"> Location</label>
                  </Col>
                  <Col md={10}>
                    <Field
                      className="formik-input"
                      as="select"
                      name="reportingTab"
                    >
                      <option value="red" selected>
                        Select a Location
                      </option>
                      {state.map((states) => (
                        <option value={states.stateId}>
                          {[states.stateCode]}-{states.stateName}
                        </option>
                      ))}
                    </Field>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="6">
                <Row>
                  <Col md={3}>
                    <label className="formik-label">
                      {" "}
                      Remarks (For Internal Use)
                    </label>
                  </Col>
                  <Col md={9}>
                    <Field
                      className="formik-tabtextarea"
                      as="textarea"
                      name="remarks"
                    ></Field>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </form>
        )}
      </Formik>
      {/* //
      //
      // Buttons
      //
      // */}
      <div className="bottom-bar">
        <Row>
          <Col md={4}>
            <Row>
              <Col md={4}>
                <button className="btn btn-left ml-3" onClick={handleCancel}>
                  Cancel
                </button>
              </Col>
              <Col md={4}>
                <button type="reset" className="btn btn-left" form="addVendor">
                  Clear
                </button>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="printView">
            <span className="print">Print or Preview</span>
          </Col>
          <Col md={4}>
            <button
              type="submit"
              form="addVendor"
              className="btn btn-right float-right mr-3"
            >
              {reload ? (
                <img className="loader" src={loader} alt="loading..." />
              ) : (
                " Save"
              )}
            </button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default AddVendorForm;
