import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
// import DataTableExtensions from "react-data-table-component-extensions";
// import "react-data-table-component-extensions/dist/index.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
// import Table from "./Table";
import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import FilterComponent from "./FilterComponent";
import { AgGridReact } from "ag-grid-react";

function ExpensesList() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }

  function toPages(pages) {
    const results = [];

    for (let i = 1; i < pages; i++) {
      results.push(i);
    }

    return results;
  }
  // const conditionalRowStyles = [
  //   {
  //     when: (row) => row.toggleSelected,
  //     style: {
  //       // "&:hover": {
  //       //   backgroundColor: "gray",
  //       // },
  //       backgroundColor: "green",
  //       userSelect: "none",
  //     },
  //   },
  // ];
  // const handleRowClicked = (row) => {
  //   const updatedData = data.map((item) => {
  //     if (row.customerId !== item.customerId) {
  //       return item;
  //     }
  //     return {
  //       ...item,
  //     };
  //   });
  //   // setData(updatedData);
  // };

  //aggrid components

  const gridRef = useRef();
  const defaultColDef = useMemo(
    () => ({
      filter: true,
      sortable: true,
      resizable: true,
    }),
    []
  );
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "expenseDate",
      headerName: "Date",
      headerTooltip: "Date",
    },
    {
      field: "expenseNumber",
      headerName: "Expense#",
      headerTooltip: "Expense#",
    },
    {
      field: "expenseAccountGroup",
      headerName: "Expense Account",
      headerTooltip: "Expense Account",
    },

    {
      field: "expenseInvoiceNumber",
      headerName: "Invoice Number",
      headerTooltip: "Invoice Number",
    },
    {
      cellRendererFramework: ({ data }) => {
        const expenseType = data.expenseType;
        return expenseType === "1"
          ? "Goods"
          : expenseType === "2"
          ? "Service"
          : "";
      },
      headerName: "Expense Type",
      headerTooltip: "Expense Type",
    },
    {
      field: "expenseTaxId",
      headerName: "Tax ID",
      headerTooltip: "Tax ID",
    },
    {
      field: "expenseCustomerName",
      headerName: "Customer Name",
      headerTooltip: "Customer Name",
    },
    {
      field: "expenseAmount",
      headerName: "Amount",
      headerTooltip: "Amount",
    },
  ]);
  const onGridReady = useCallback(
    (params) => {
      return data;
    },
    [data]
  );
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        minWidth: 225,
        maxWidth: 225,
        width: 225,
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
        minWidth: 180,
        maxWidth: 400,
        width: 250,
      },
    ],
    position: "right",
    defaultToolPanel: "",
  };
  const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);

  const columns = [
    {
      name: "Date",
      selector: (data) => data.expenseDate,
      sortable: true,
    },

    {
      name: "Expense#",
      selector: (data) => (
        <div className="text-opinenumber">
          <Link to={`/ExpenseSingleView/${data.expenseId}`}>
            {data.expenseNumber}
          </Link>
        </div>
      ),
      sortable: true,
      // right: true,
    },

    {
      name: "Expense Account",
      selector: (data) => data.expensePaidThroughAccountId,
      sortable: true,
      right: true,
    },
    {
      name: "Invoice Number",
      selector: (data) => data.expenseInvoiceNumber,
      sortable: true,
      right: true,
    },
    {
      name: "Vendor Name",
      selector: (data) => data.expenseVendorName,
      sortable: true,
      right: true,
    },
    {
      name: "Paid Through",
      selector: (data) => data.expenseType,
      sortable: true,
      right: true,
    },
    {
      name: "Customer Name",
      selector: (data) => data.expenseCustomerName,
      sortable: true,
      right: true,
    },
    {
      name: "Customer Number",
      selector: (data) => data.expenseCustomerContactNumber,
      sortable: true,
      right: true,
    },
    {
      name: "Amount",
      selector: (data) => data.expenseAmount,
      sortable: true,
      right: true,
    },
  ];

  const getData = async (page) => {
    setLoading(true);
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/expenses/org?org_id=${organisationId}&page=${page}&size=${perPage}&sort=expenseId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      console.log("expence data", resp);
      setData(resp.response.data?.data.content);
      setTotalRows(resp.response.data?.data.totalElements);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    console.log("page", page);

    getData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/expenses/org?org_id=${organisationId}&page=${page}&size=${newPerPage}&sort=expenseId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setPerPage(newPerPage);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData(1);
    console.log("expense data", filteredItems);
  }, [filterText]);
  const filteredItems = data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };
  const subHeaderComponent = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const handleRowClick = (row) => {
    history.push(`/ExpenseSingleView/${row.data.expenseId}`);
  };

  return (
    <div className="App">
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
      <div className="card">
        {/* {loadingData ? (
          <div className="tableLoading">
            <p>Loading Please wait...</p>
          </div>
        ) : ( */}
        {/* <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows
          subHeader
          subHeaderComponent={subHeaderComponent}
          subHeaderAlign="left"
          onRowClicked={handleRowClick}
          highlightOnHover
          pointerOnHover
        /> */}
        <div className="ag-theme-alpine" style={gridStyle}>
          {" "}
          <AgGridReact
            columnDefs={columnDefs}
            rowData={filteredItems}
            ref={gridRef}
            pagination
            grid
            onRowClicked={handleRowClick}
            sideBar={sideBar}
            // onPaginationChanged={handlePageChange}
            // animateRows={true}
            defaultColDef={defaultColDef}
            subHeaderComponent={subHeaderComponent}
            onGridReady={onGridReady}
          />
        </div>
        {/* )} */}
      </div>
    </div>
  );
}
export default ExpensesList;
