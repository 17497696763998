import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "reactstrap";

import { AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
//i18n
import Select from "react-select";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";
import SalesInfo from "./salesInformation";

function AddItems(vendors, itemdata, formType) {
  const [taxRate, setTaxRate] = useState(false);
  const [trackinv, setTrackInv] = useState(false);
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [tags, setTags] = useState(false);
  const [uom, setuom] = useState([]);
  const {
    salesInformation,
    salesData,
    purchaseData,
    checkPurchase,
    checkSelling,
  } = SalesInfo(itemdata, formType, vendors);
  const [data, setData] = useState({
    itemType: "0",
    itemName: "",
    itemUnit: "",
    itemHsnCode: "",
    itemSacCode: "",
    itemTaxPreference: "0",
    // trackinv: true,
    itemInterStateTax: 12,
    itemIntraStateTax: 12,
    exemptReason: "",
    itemInventoryAccountId: 0,
    itemOpeningStock: 0,
    itemOpeningStockRatePerUnit: 0,
    itemReorderPoint: 0,
    itemPreferredVendorId: 0,
    location: "",
    isInventoryEnabled: 0,
  });
  function handleTrackInv(e) {
    console.log("track inv", e);
    setTrackInv(!trackinv);
    //  const newData = { ...data };
    //  newData["isInventoryEnabled"] = trackinv ? 1 : 0;
    //  setData(newData);
  }
  // useEffect(() => {
  //   console.log("item wwww data", itemdata);
  // }, [itemdata]);

  useEffect(() => {
    if (trackinv) {
      setData({ ...data, isInventoryEnabled: 1 });
    } else if (!trackinv) {
      setData({ ...data, isInventoryEnabled: 0 });
    }
    console.log("data", data);
  }, [trackinv]);

  function handle(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function getUOM() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/uom`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setuom(resp.response.data.data);
      }
    });
  }
  // useEffect(() => {
  //   // console.log("item wwww purchaseData", purchaseData);
  //   console.log("item wwww salesData", salesData);
  // }, [salesData]);
  useEffect(() => {
    getUOM();
    if (formType == "edit" || formType == "clone") {
      setData(itemdata);
    }
  }, [
    formType == "edit"
      ? itemdata
      : formType == "add"
      ? data
      : formType == "clone"
      ? itemdata
      : "",
  ]);
  const uomOptions = uom.map((items) => ({
    value: items.uom,
    label: items.uom,
  }));
  const inventoryOption = [
    // { value: "", label: "Select account", isDisabled: true },
    { value: "0", label: "Inventory Asset" },
  ];
  return {
    data,
    salesData,
    purchaseData,
    checkPurchase,
    salesInformation,
    checkSelling,
    addItem: (
      <React.Fragment>
        <Row>
          <Col md={6}>
            <label>Item Type</label>
            <AvRadioGroup inline name="itemType">
              <AvRadio label="Goods" value="0" onChange={(e) => handle(e)} />
              <AvRadio label="Service" value="1" onChange={(e) => handle(e)} />
            </AvRadioGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Row>
              <Col md={4}>
                <label>Name</label>
              </Col>
              <Col md={6}>
                <AvField
                  name="itemName"
                  type="text"
                  placeholder="Enter item name"
                  onChange={(e) => handle(e)}
                  value={data.itemName}
                  required
                ></AvField>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={4}>
                <label>
                  Unit <i class="bx bx-help-circle"></i>
                </label>
              </Col>
              <Col md={6}>
                <Select
                  name="itemUnit"
                  // value={uomOptions.find(uom === data.itemUnit)}
                  value={
                    uomOptions
                      ? uomOptions.find(
                          (option) => option.value === data.itemUnit
                        )
                      : ""
                  }
                  onChange={(selectedOption) =>
                    setData({ ...data, itemUnit: selectedOption.value })
                  }
                  options={uomOptions}
                  placeholder="Select UOM"
                />
                {/* <AvField type="select" name="itemUnit" value={data.itemUnit}>
                  <option value="" disabled selected>
                    Select UOM
                  </option>
                  {uom.map((items) => (
                    <option>{items.uom}</option>
                  ))}
                </AvField> */}
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={6}>
            {data.itemType == "0" ? (
              <Row>
                <Col md={4}>
                  <label>HSN Code</label>
                </Col>
                <Col md={6}>
                  <div className="align-center">
                    <AvField
                      name="itemHsnCode"
                      type="input"
                      placeholder="Enter HSN"
                      onChange={(e) => handle(e)}
                      value={data.itemHsnCode}
                      required={data.itemType == "0"}
                    ></AvField>
                    <i
                      className="bx bx-search"
                      onClick={() => {
                        tog_backdrop();
                      }}
                    ></i>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md={4}>
                  <label>SAC Code</label>
                </Col>
                <Col md={8}>
                  <div className="align-center">
                    <AvField
                      name="itemSacCode"
                      type="input"
                      placeholder="Enter SAC"
                      onChange={(e) => handle(e)}
                      value={data.itemSacCode}
                      required={data.itemType == "1"}
                    ></AvField>
                    <i
                      className="bx bx-search"
                      onClick={() => {
                        tog_backdrop();
                      }}
                    ></i>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          <Col md={6}>
            <Row className="d-flex">
              <Col md={4}>
                <label>Tax preference</label>
              </Col>
              <Col md={6}>
                <AvRadioGroup
                  inline
                  name="itemTaxPreference"
                  value={data.itemTaxPreference}
                >
                  <AvRadio
                    label="Taxable"
                    value="0"
                    onChange={(e) => handle(e)}
                  />
                  <AvRadio
                    label="Non-Taxable"
                    value="1"
                    onChange={(e) => handle(e)}
                  />
                </AvRadioGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        {data.itemTaxPreference == "1" ? (
          <Row>
            <Col md={6}>
              <Row>
                <Col md={4}>
                  <label>Exemption Reason</label>
                </Col>
                <Col md={6}>
                  <AvField
                    name="exemptReason"
                    type="input"
                    placeholder="Exemption reason"
                    onChange={(e) => handle(e)}
                    value={data.exemptReason}
                    required
                  ></AvField>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <hr />
        {salesInformation}
        {data.itemTaxPreference == "0" ? (
          <Row>
            <Col md={12}>
              <div className="tax">
                <h5>Default Tax Rates</h5>
                {!taxRate ? (
                  <i onClick={() => setTaxRate(true)} class="bx bx-pencil"></i>
                ) : (
                  ""
                )}
              </div>
            </Col>
            {!taxRate ? (
              <Col md={6}>
                <div className="taxrates">
                  <Row>
                    <Col md={6}>Intra State Tax Rate</Col>
                    <Col md={6}>GST12 (12%)</Col>
                  </Row>
                </div>
                <div className="taxrates">
                  <Row>
                    <Col md={6}>Inter State Tax Rate</Col>
                    <Col md={6}>IGST12 (12%)</Col>
                  </Row>
                </div>
              </Col>
            ) : (
              <Col md={6}>
                <div className="taxrates">
                  <Row>
                    <Col md={6}>Intra State Tax Rate</Col>
                    <Col md={6}>
                      <AvField
                        name="itemIntraStateTax"
                        type="select"
                        onChange={(e) => handle(e)}
                        value={data.itemIntraStateTax}
                      >
                        <option value="0">GST0 [0%]</option>
                        <option value="5">GST5 [5%]</option>
                        <option value="12">GST12 [12%]</option>
                        <option value="18">GST18 [18%]</option>
                        <option value="28">GST28 [28%]</option>
                        <option value="11.5">TEST NS [11.5%]</option>
                      </AvField>
                    </Col>
                  </Row>
                </div>
                <div className="taxrates">
                  <Row>
                    <Col md={6}>Inter State Tax Rate</Col>
                    <Col md={6}>
                      <AvField
                        name="itemInterStateTax"
                        type="select"
                        onChange={(e) => handle(e)}
                        value={data.itemInterStateTax}
                      >
                        <option value="0">IGST0 [0%]</option>
                        <option value="5">IGST5 [5%]</option>
                        <option value="12">IGST12 [12%]</option>
                        <option value="18">IGST18 [18%]</option>
                        <option value="28">IGST28 [28%]</option>
                      </AvField>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
            <Col md={12}>
              <hr />
            </Col>
          </Row>
        ) : (
          ""
        )}

        {localStorage.getItem("userName") === "opine_ac_admin@gmail.com" ? (
          <>
            <Row>
              <Col md={12}>
                <AvField
                  type="checkbox"
                  name="trackinv"
                  disabled={data.itemType == "1"}
                  label="Track inventory for this item"
                  onChange={(e) => handleTrackInv(e)}
                  value={trackinv}
                />
                <label className="trackinv">
                  You cannot enable or disable inventory tracking once you've
                  created transactions for this item
                </label>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
        {trackinv == true && data.itemType == "0" ? (
          <Row>
            <Col md={6}>
              <Row>
                <Col md={4}>
                  <label>Inventory Account</label>
                </Col>
                <Col md={6} style={{ marginBottom: "0.5rem" }}>
                  <Select
                    name="itemInventoryAccountId"
                    // value={data.itemInventoryAccountId}
                    value={
                      inventoryOption
                        ? inventoryOption.find(
                            (option) =>
                              option.value === data.itemInventoryAccountId
                          )
                        : ""
                    }
                    placeholder="Select account"
                    onChange={(selectedOption) =>
                      setData({
                        ...data,
                        itemInventoryAccountId: selectedOption.value,
                      })
                    }
                    options={inventoryOption}
                    required
                  />
                  {/* <AvField
                    name="itemInventoryAccountId"
                    type="select"
                    onChange={(e) => handle(e)}
                    value={data.itemInventoryAccountId}
                    required
                  >
                    <option value="" selected disabled>
                      Select account
                    </option>
                    <option value={0}>Inventory Asset</option>
                  </AvField> */}
                </Col>
                <Col md={4}>
                  <label>Opening Stock</label>
                </Col>
                <Col md={6}>
                  <AvField
                    name="itemOpeningStock"
                    type="number"
                    onChange={(e) => handle(e)}
                    value={data.itemOpeningStock}
                  ></AvField>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={4}>
                  <label>Opening Stock Rate per Unit</label>{" "}
                </Col>
                <Col md={6}>
                  <AvField
                    name="itemOpeningStockRatePerUnit"
                    type="number"
                    onChange={(e) => handle(e)}
                    value={data.itemOpeningStockRatePerUnit}
                  ></AvField>
                </Col>
                <Col md={4}>
                  <label>Reorder Point</label>{" "}
                </Col>
                <Col md={6}>
                  <AvField
                    name="itemReorderPoint"
                    type="number"
                    onChange={(e) => handle(e)}
                    value={data.itemReorderPoint}
                  ></AvField>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {/* <hr />
        <Row>
          {!tags ? (
            <Col md={12}>
              <div
                class="align-center copy-address"
                onClick={() => setTags(!tags)}
              >
                <span>Associate Tags</span>
                <i class=" mr-1 bx bxs-down-arrow-alt"></i>
              </div>
            </Col>
          ) : (
            <Col md={4}>
              <label>Location</label>
              <AvField
                name="location"
                type="select"
                onChange={(e) => handle(e)}
                value={data.location}
              >
                <option value="" selected disabled>
                  Select a location
                </option>
                <option>Location 1</option>
                <option>Location 2</option>
              </AvField>
            </Col>
          )}
        </Row> */}
        <Modal
          isOpen={modal_backdrop}
          toggle={() => {
            tog_backdrop();
          }}
          backdrop={"static"}
          id="staticBackdrop"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Find {data.itemType == "0" ? "HSN" : "SAC"} Code
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setmodal_backdrop(false);
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body hsnsac">
            <p>
              Search {data.itemType == "0" ? "HSN" : "SAC"} code for your item
            </p>
            <input type="text" className="hsnsacinput" />
            <p className="text">
              Could not find the {data.itemType == "0" ? "HSN" : "SAC"} for the
              service. Please provide proper description. or,{" "}
              <span className="copy-address">Contact Support</span>
            </p>
          </div>
        </Modal>
      </React.Fragment>
    ),
  };
}
export default AddItems;
