import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";

import loader from "../../assets/loader/load.gif";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet, apiPost, apiPut } from "../../components/config/apiConfig";

//import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomCheckbox from "../../components/Features/customcheckbox";
import { useSelector } from "react-redux";
import { useRef } from "react";
const AddWareHouse = (props) => {
  var formType = props.match.params.type;
  var id = props.match.params.id;
  const form = useRef(null);
  const [uncheck, setuncheck] = useState([]);
  const history = useHistory();
  const [isrentable, setisrentable] = useState(false);
  const [requireFacility, setRequireFacility] = useState(false);
  const { checkbox, setcheckboxdata, checkboxdata, facilities } =
    CustomCheckbox({ setRequireFacility });
  const [reload, setReload] = useState(false);
  const [warehousedata, setwarehousedata] = useState({
    warehouseName: "",
    warehouseArea: 0,
    warehouseAreaUnit: "",
    warehouseAddress: "",
    warehouseContactName: "",
    warehouseContactEmail: "",
    warehousePhoneNumber: "",
    warehouseStartTime: "",
    warehouseEndTime: "",
    warehouseRentalCheck: "",
    warehouseRentPerAnnum: "",
    warehouseFacilities: facilities,
    warehouseComments: "",
    warehouseTimingto: "",
    warehouseSalary: 0,
    warehouseOtherCharges: 0,
  });
  function isRentable() {
    setisrentable(!isrentable);
  }

  const handle = (e) => {
    setwarehousedata({
      ...warehousedata,
      [e.target.name]: e.target.value,
    });
  };

  function createWarehouse() {
    setReload(true);
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/warehouse";
    let body = {
      warehouseName: warehousedata.warehouseName,
      warehouseArea: parseInt(warehousedata.warehouseArea),
      warehouseAreaUnit: warehousedata.warehouseAreaUnit,
      warehouseAddress: warehousedata.warehouseAddress,
      warehouseContactName: warehousedata.warehouseContactName,
      warehouseContactEmail: warehousedata.warehouseContactEmail,
      warehousePhoneNumber: warehousedata.warehousePhoneNumber,
      warehouseStartTime: warehousedata.warehouseStartTime,
      warehouseEndTime: warehousedata.warehouseEndTime,
      warehouseRentPerAnnum: parseInt(warehousedata.warehouseRentPerAnnum),
      warehouseFacilities: facilities,
      warehouseComments: warehousedata.warehouseComments,
      warehouseSalary: parseInt(warehousedata.warehouseSalary),
      warehouseOtherCharges: parseInt(warehousedata.warehouseOtherCharges),
      organizationId: orgId,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
    };
    let resp = apiPost(url, body);
    resp.then((resp) => {
      setReload(false);
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            history.push("/warehouse-list");
            // useHistory().push("/customerlist");
          }, 1000);
          break;

        default:
          toast.error(resp.response.data.data);
      }
    });
  }
  function updateWarehouse() {
    setReload(true);
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + `api/v1/warehouse/${id}`;
    let body = {
      warehouseName: warehousedata.warehouseName,
      warehouseArea: parseInt(warehousedata.warehouseArea),
      warehouseAreaUnit: warehousedata.warehouseAreaUnit,
      warehouseAddress: warehousedata.warehouseAddress,
      warehouseContactName: warehousedata.warehouseContactName,
      warehouseContactEmail: warehousedata.warehouseContactEmail,
      warehousePhoneNumber: warehousedata.warehousePhoneNumber,
      warehouseStartTime: warehousedata.warehouseStartTime,
      warehouseEndTime: warehousedata.warehouseEndTime,
      warehouseRentPerAnnum: parseInt(warehousedata.warehouseRentPerAnnum),
      warehouseFacilities: facilities,
      warehouseComments: warehousedata.warehouseComments,
      warehouseSalary: parseInt(warehousedata.warehouseSalary),
      warehouseOtherCharges: parseInt(warehousedata.warehouseOtherCharges),
      organizationId: orgId,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
    };
    let resp = apiPut(url, body);
    resp.then((resp) => {
      setReload(false);
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            history.push("/warehouse-list");
            // useHistory().push("/customerlist");
          }, 1000);
          break;

        default:
          toast.error(resp.response.data.data);
      }
    });
  }
  function getWarehouseDataById() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/warehouse/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      var datas = resp.response.data.data;
      setwarehousedata(datas);
      setcheckboxdata((data) => {
        return data.map((item) => {
          if (datas.warehouseFacilities.includes(item.name)) {
            return { ...item, checked: true };
          }
          return item;
        });
      });
    });
  }

  function handleSubmit(e) {
    if (formType == "add") {
      if (facilities === null || facilities === ",,,,") {
        setRequireFacility(true);
      } else {
        setRequireFacility(false);
        createWarehouse();
      }
    } else {
      if (facilities === null || facilities === ",,,,") {
        setRequireFacility(true);
      } else {
        updateWarehouse();
      }
    }
  }

  const clearForm = () => {
    form.current.reset(); //this will reset all the inputs in the form
    setuncheck(
      checkboxdata.map((items, index) => {
        return (items.checked = false);
      })
    );
  };
  useEffect(() => {}, [facilities]);

  useEffect(() => {
    if (formType == "edit") {
      getWarehouseDataById();
    }
  }, [formType]);
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>ADD NEW WAREHOUSE</h6>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className="form"
                    onValidSubmit={(e) => handleSubmit(e)}
                    ref={form}
                    // model={data}
                  >
                    <Row>
                      <Col md={4} className="formLeft">
                        <Row className="mb-3">
                          <Col md={12}>
                            <strong>Warehouse information</strong>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <label>Warehouse Name</label>
                            <AvField
                              name="warehouseName"
                              type="text"
                              onChange={handle}
                              value={warehousedata.warehouseName}
                              required
                            ></AvField>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <label>Total Area</label>
                            <Row>
                              <Col md={8}>
                                <AvField
                                  name="warehouseArea"
                                  type="number"
                                  onChange={handle}
                                  value={warehousedata.warehouseArea}
                                  required
                                ></AvField>
                              </Col>
                              <Col md={4}>
                                <AvField
                                  name="warehouseAreaUnit"
                                  type="select"
                                  onChange={handle}
                                  value={warehousedata.warehouseAreaUnit}
                                  required
                                >
                                  <option value="" selected disabled>
                                    Select unit
                                  </option>
                                  <option value="cbm">CBM</option>
                                  {/* <option value="cft">CFT</option> */}
                                </AvField>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <label>Address</label>
                            <AvField
                              name="warehouseAddress"
                              type="textarea"
                              onChange={handle}
                              value={warehousedata.warehouseAddress}
                              required
                            ></AvField>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <label>Primary Contact Name</label>
                            <AvField
                              name="warehouseContactName"
                              type="text"
                              onChange={handle}
                              value={warehousedata.warehouseContactName}
                              required
                            ></AvField>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <label>Primary Contact Email</label>
                            <AvField
                              name="warehouseContactEmail"
                              type="email"
                              onChange={handle}
                              value={warehousedata.warehouseContactEmail}
                              required
                            ></AvField>
                          </Col>
                          <Col md={6}>
                            <label>Work Phone no</label>
                            <AvField
                              name="warehousePhoneNumber"
                              type="number"
                              onChange={handle}
                              value={warehousedata.warehousePhoneNumber}
                              required
                            ></AvField>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={12}>
                            <label>Timing</label>
                            <Row>
                              <Col md={6}>
                                <label>From</label>
                                <AvField
                                  name="warehouseStartTime"
                                  type="time"
                                  onChange={handle}
                                  value={warehousedata.warehouseStartTime}
                                  required
                                ></AvField>
                              </Col>
                              <Col md={6}>
                                <label>To</label>
                                <AvField
                                  name="warehouseEndTime"
                                  type="time"
                                  onChange={handle}
                                  value={warehousedata.warehouseEndTime}
                                  required
                                ></AvField>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={12}>
                            <label>Expenses (QAR)</label>
                            <Row>
                              <Col md={6}>
                                <label>Salary</label>
                                <AvField
                                  name="warehouseSalary"
                                  type="number"
                                  onChange={handle}
                                  value={warehousedata.warehouseSalary}
                                  required
                                ></AvField>
                              </Col>
                              <Col md={6}>
                                <label>Other expenses</label>
                                <AvField
                                  name="warehouseOtherCharges"
                                  type="number"
                                  onChange={handle}
                                  value={warehousedata.warehouseOtherCharges}
                                  required
                                ></AvField>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={6}>
                            <AvField
                              type="checkbox"
                              name="warehouseRentalCheck"
                              // disabled={data.itemType == "1"}
                              label="Rental Property"
                              // onChange={handle}
                              onClick={() => isRentable()}
                              // onChange={(e) => handleTrackInv(e)}
                              // value={trackinv}
                            />
                          </Col>
                        </Row>
                        {isrentable == true ? (
                          <div>
                            <Row className="mt-2">
                              <Col md={6}>
                                <label>Rent per annum</label>
                                <AvField
                                  name="warehouseRentPerAnnum"
                                  type="number"
                                  onChange={handle}
                                  value={warehousedata.warehouseRentPerAnnum}
                                  required
                                ></AvField>
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col md={8}>
                        <Row className="mb-3">
                          <Col md={12}>
                            <strong>Facilities</strong>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            {/* <CustomCheckbox /> */}
                            {checkbox}

                            {requireFacility ? (
                              <div className="mt-2">
                                <span
                                  style={{ color: "#f46a6a", fontSize: "80%" }}
                                >
                                  Please select a facility
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={12}>
                            <label>Comments</label>
                            <AvField
                              name="warehouseComments"
                              type="textarea"
                              onChange={handle}
                              value={warehousedata.warehouseComments}
                              rows="5"
                            ></AvField>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {/* <BottomBar /> */}
                    <div className="bottom-bar">
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={4}>
                              <Link to="/warehouse-list">
                                <button
                                  type="button"
                                  className="btn btn-left ml-3"
                                >
                                  Cancel
                                </button>
                              </Link>
                            </Col>
                            {formType == "add" ? (
                              <Col md={4}>
                                <button
                                  type="button"
                                  onClick={clearForm}
                                  className="btn btn-left"
                                >
                                  Clear
                                </button>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Col>
                        {/* <Col md={4} className="printView">
                          <span className="print">Print or Preview</span>
                        </Col> */}
                        <Col md={8}>
                          <button
                            type="submit"
                            className="btn btn-right float-right mr-3"
                          >
                            {reload ? (
                              <img
                                className="loader"
                                src={loader}
                                alt="loading..."
                              />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(AddWareHouse);
