import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import dyarco from "../../assets/dyarco/dyarco.jpeg";
import loader from "../../assets/loader/load.gif";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet, apiPost, apiPut } from "../../components/config/apiConfig";

import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomCheckbox from "../../components/Features/customcheckbox";
import { useSelector } from "react-redux";
import {
  addCommodityTableData,
  setCommodityTableData,
  setCommodityTableOtherCharges,
  deleteTableRow,
  clearCommodityData,
  updateCommodityRowTotal,
  requestWarehouseData,
  getCurrentSelectedCustomer,
  addCommodityTableOtherCharges,
  updateCommodityOtherCharges,
  deleteOtherChargeTableRow,
  clearCustomerData,
  populateTableRowData,
  populateOtherChargeTableRowData,
  validateTableData,
  addHandlingCharge,
} from "../../store/actions";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
const AddCargoCheckIn = (props) => {
  var formType = props.match.params.type;
  var id = props.match.params.id;
  const scrollRef = useRef(null);
  const selectRef = useRef(null);
  var current = new Date();
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1 < 10
      ? `0${current.getMonth() + 1}`
      : current.getMonth() + 1
  }-${current.getDate() < 10 ? `0${current.getDate()}` : current.getDate()}`;

  const history = useHistory();
  const form = useRef(null);
  const componentRef = useRef();
  const [reload, setReload] = useState(false);
  const [uom, setuom] = useState([]);
  const [checkPalletNumberAvailable, setCheckPalletNumberAvailable] =
    useState(true);
  const [handlinginfo, sethandlinginfo] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [warehouseList, setwarehouseList] = useState([]);
  const [checkinnumber, setcheckinnumber] = useState("");
  const [customerrequired, setcustomerrequired] = useState(false);
  const [warehouserequired, setwarehouserequired] = useState(false);
  const [commodityName, setcommodityName] = useState("");
  const [otherChargeName, setotherChargeName] = useState("");
  const [warehouseFacility, setWareHouseFacility] = useState([]);
  const [validateTable, setValidateTable] = useState(false);
  const [requiredRowIndex, setRequiredRowIndex] = useState({});
  const [itemData, setItemData] = useState([]);

  function getData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setCustomerList(resp.response.data.data);
    });
  }

  function getUOM() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/uom`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setuom(resp.response.data.data);
      }
    });
  }
  function getHandlingInfo() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/handling_info`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        sethandlinginfo(resp.response.data.data);
      }
    });
  }

  function getItemData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setItemData(resp.response.data.data);
    });
  }

  const [cargocheckindata, setcargocheckindata] = useState({
    checkInDate: date,
    checkInTime: new Date().toLocaleTimeString(),
    checkInCustomerEmail: "",
    checkInCustomerPhone: "",
    checkInCustomerAddress: "",
    checkInQuotationNumber: "",
    checkInInvoiceNumber: "",
    checkInVehicleNumber: "",
    checkInDrivingLicense: "",
    checkInDriverName: "",
    checkInRatePerCbm: "",
    checkInEstimatedCommodityValue: "",
    checkInHandlingInCharge: "",
    branchId: 0,
  });
  const [checkInWarehouseId, setcheckInWarehouseId] = useState({
    id: 0,
    name: "",
  });
  const [checkInWarehouseCustomer, setcheckInWarehouseCustomer] = useState({
    checkInCustomerId: 0,
    checkInCustomerName: "",
  });
  const [rowsData, setRowsData] = useState([
    {
      // itemCommodityId: 0,
      itemCommodityName: "",
      itemHsnCode: "",
      itemUom: "",
      itemUnits: "",
      itemUnitsPerSinglePackage: "",
      itemTemperature: "",
      itemHandlingInfo: "",
      itemDateOfExpiry: "",
      itemBatch: "",
      itemPallet: 0,
      itemLength: 0,
      itemBreadth: 0,
      itemHeight: 0,
      itemArea: "",
      itemSQM: "",
      itemTotalWeight: 0,
      itemRemarks: "",
      itemCargoCondition: "",
    },
  ]);
  const addTableRows = () => {
    const rowsInput = {
      itemCommodityName: "test1",
      itemHsnCode: "8765",
      itemUom: "Cartons",
      itemUnits: "4",
      itemTemperature: "45",
      itemHandlingInfo: "h1",
      itemDateOfExpiry: "2023-12-31",
      itemBatch: "GJKE5645",
      itemPallet: 0,
      itemLength: 17,
      itemBreadth: 17,
      itemHeight: 17,
      itemArea: "cbm",
      itemTotalWeight: 0,
      itemRemarks: "",
      itemCargoCondition: "",
    };
    setRowsData([...rowsData, rowsInput]);
    setcommodityName("");
    props.setCommodityTableData();
  };
  const [otherCharge, setOtherCharge] = useState([
    {
      chargeName: "",
      chargeAmount: 0,
    },
  ]);
  const addOtherChargeTableRows = () => {
    const rowsInput = {
      chargeName: "",
      chargeAmount: 0,
    };
    setOtherCharge([...otherCharge, rowsInput]);
    setotherChargeName("");
    props.setCommodityTableOtherCharges();
  };
  const deleteTableRow = (index) => {
    props.deleteTableRow(index);
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };
  const deleteOtherChargeTableRow = (index) => {
    props.deleteOtherChargeTableRow(index);
    const rows = [...otherCharge];
    rows.splice(index, 1);
    setOtherCharge(rows);
  };

  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;

    setRowsData(rowsInput);
    addTableRows();
  };
  const handle = (e) => {
    setcargocheckindata({
      ...cargocheckindata,
      [e.target.name]: e.target.value,
    });
  };
  const addCommodity = (e, index) => {
    var tableValue = selectRef.current.value;
    props.addCommodityTableData(e);
    if (props.tableRowData.length > 0) {
      props.validateTableData();
    }
  };

  function createCargoCheckIn() {
    setReload(true);
    var checkInChargeList = props.otherChargeTableData.filter(
      (charge) => charge.chargeAmount !== 0
    );
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/cargo_checkin";
    let body = {
      checkInDate: cargocheckindata.checkInDate,
      checkInTime: cargocheckindata.checkInTime,
      checkInWarehouseId: parseInt(checkInWarehouseId.id),
      checkInCustomerId: parseInt(checkInWarehouseCustomer.checkInCustomerId),
      checkInCustomerName: checkInWarehouseCustomer.checkInCustomerName,
      checkInCustomerEmail: props.selectedCustomer.customerEmail
        ? props.selectedCustomer.customerEmail
        : cargocheckindata.checkInCustomerEmail,
      checkInCustomerPhone: props.selectedCustomer.customerWorkPhone
        ? props.selectedCustomer.customerWorkPhone
        : cargocheckindata.checkInCustomerPhone,
      checkInCustomerAddress: cargocheckindata.checkInCustomerAddress,
      checkInQuotationNumber: cargocheckindata.checkInQuotationNumber,
      checkInVehicleNumber: cargocheckindata.checkInVehicleNumber,
      checkInDrivingLicense: cargocheckindata.checkInDrivingLicense,
      checkInDriverName: cargocheckindata.checkInDriverName,
      checkInRatePerCbm:
        cargocheckindata.checkInRatePerCbm.length > 0
          ? parseInt(cargocheckindata.checkInRatePerCbm)
          : props.selectedCustomer.customerFixedQuoteRate,
      checkInEstimatedCommodityValue: parseInt(
        cargocheckindata.checkInEstimatedCommodityValue
      ),
      checkInHandlingInCharge:
        cargocheckindata.checkInHandlingInCharge > 0
          ? parseInt(cargocheckindata.checkInHandlingInCharge)
          : props.selectedCustomer.customerHandlingInCharge,
      organizationId: orgId,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
      itemsList: props.tableRowData,
      chargeList: checkInChargeList,
    };
    console.log("body", body);
    let resp = apiPost(url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          setcheckinnumber(resp.response.data.data);
          // handlePrint();
          toast.success(
            `${resp.response.data.message} ${resp.response.data.status}`
          );
          setTimeout(() => {
            setReload(false);
            history.push("/cargocheckin-list");
          }, 1000);
          break;

        default:
          setReload(false);
          toast.error(resp.response.data.data);
      }
    });
  }
  function updateCargoCheckIn() {
    setReload(true);
    var checkInChargeList = props.otherChargeTableData.filter(
      (charge) => charge.chargeAmount !== 0
    );
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + `api/v1/cargo_checkin/${id}`;
    let body = {
      checkInDate: cargocheckindata.checkInDate,
      checkInTime: cargocheckindata.checkInTime,
      checkInWarehouseId: parseInt(checkInWarehouseId.id),
      checkInCustomerId: parseInt(checkInWarehouseCustomer.checkInCustomerId),
      checkInCustomerName: checkInWarehouseCustomer.checkInCustomerName,
      checkInCustomerEmail: props.selectedCustomer.customerEmail
        ? props.selectedCustomer.customerEmail
        : cargocheckindata.checkInCustomerEmail,
      checkInCustomerPhone: props.selectedCustomer.customerWorkPhone
        ? props.selectedCustomer.customerWorkPhone
        : cargocheckindata.checkInCustomerPhone,
      checkInCustomerAddress: cargocheckindata.checkInCustomerAddress,
      checkInQuotationNumber: cargocheckindata.checkInQuotationNumber,
      checkInVehicleNumber: cargocheckindata.checkInVehicleNumber,
      checkInDrivingLicense: cargocheckindata.checkInDrivingLicense,
      checkInDriverName: cargocheckindata.checkInDriverName,
      checkInRatePerCbm: parseInt(cargocheckindata.checkInRatePerCbm),
      checkInEstimatedCommodityValue: parseInt(
        cargocheckindata.checkInEstimatedCommodityValue
      ),
      checkInHandlingInCharge:
        cargocheckindata.checkInHandlingInCharge > 0
          ? parseInt(cargocheckindata.checkInHandlingInCharge)
          : props.selectedCustomer.customerHandlingInCharge,
      organizationId: orgId,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
      itemsList: props.tableRowData,
      chargeList: checkInChargeList,
    };
    let resp = apiPut(url, body);
    resp.then((resp) => {
      setReload(false);
      switch (resp.response.data.code) {
        case 200:
          setcheckinnumber(resp.response.data.data);
          // handlePrint();
          toast.success(
            `${resp.response.data.message} ${resp.response.data.status}`
          );
          setTimeout(() => {
            history.push(`/viewcheckin/${id}`);
          }, 1000);
          break;

        default:
          toast.error(resp.response.data.data);
      }
    });
  }

  function handleSubmit(e) {
    const filteredArray = props.tableRowData?.map(
      (item) =>
        item.itemHandlingInfo === "Fragile" && item.itemDateOfExpiry === ""
    );
    const tableIssueIndex = filteredArray.filter((item) => item === true);
    if (
      checkInWarehouseId.id != 0 &&
      checkInWarehouseCustomer.checkInCustomerId != 0
    ) {
      if (checkInWarehouseId.id != 0) {
        if (checkInWarehouseCustomer.checkInCustomerId != 0) {
          if (props.tableRowData.length > 0) {
            if (tableIssueIndex.length === 0) {
              if (
                props.totalItemArea < props.warehouseData.warehouseAreaAvailable
              ) {
                if (props.validateValue == null) {
                  setValidateTable(false);
                  if (formType == "add") {
                    createCargoCheckIn();
                  } else {
                    updateCargoCheckIn();
                  }
                } else {
                  executeScroll();
                  setValidateTable(true);
                }
              } else {
                toast.info(
                  "Exceeded the maximum space allocated for this warehouse"
                );
              }
            }
          } else {
            toast.info("Enter atleast one cargo details");
          }
        } else {
          setcustomerrequired(true);
        }
      } else {
        setwarehouserequired(true);
      }
    } else {
      setcustomerrequired(true);
      setwarehouserequired(true);
    }
  }
  useEffect(() => {
    if (props.validateValue == null) {
      setValidateTable(false);
    }
  }, [props.validateValue]);
  function getCheckInDataById() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/cargo_checkin/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      var datas = resp.response.data.data;
      setcargocheckindata(datas);
      setcheckInWarehouseId({
        id: datas.checkInWarehouseId,
        name: datas.checkInWarehouseName,
      });
      setcheckInWarehouseCustomer({
        checkInCustomerId: datas.checkInCustomerId,
        checkInCustomerName: datas.checkInCustomerName,
      });
      props.getCurrentSelectedCustomer(datas.checkInCustomerId);
      props.requestWarehouseData(datas.checkInWarehouseId);
      props.populateTableRowData(datas.itemsList);
      props.populateOtherChargeTableRowData(datas.chargeList);
      setRowsData(datas.itemsList);
      setOtherCharge(datas.chargeList);
    });
  }
  useEffect(() => {
    if (formType == "edit") {
      getCheckInDataById();
    }
    // getWarehouseData();
    getUOM();
    getData();
    getHandlingInfo();
    getItemData();
    return () => {
      props.clearCommodityData();
      props.clearCustomerData();
    };
  }, []);

  const warehouse =
    props.selectedCustomer.warehouseList &&
    props.selectedCustomer.warehouseList.map((item) => {
      return {
        value: item.warehouseId,
        label: item.warehouseName,
      };
    });
  const options = customerList.map((item) => {
    return {
      value: item.customerId,
      label: item.customerDisplayName,
    };
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "print-btn",
    pageStyle: "margin:10px",
  });
  const clearForm = () => {
    form.current.reset();
    props.clearCommodityData();
    props.clearCustomerData();
    setcommodityName("");
    setotherChargeName("");
    //this will reset all the inputs in the form
  };
  const handleChangeWarehouse = (e) => {
    setwarehouserequired(false);
    setcheckInWarehouseId({
      id: e.value,
      name: e.label,
    });
    props.requestWarehouseData(e.value);
    if (props.warehouseData.warehouseFacilities != null) {
      const facility =
        props.warehouseData.warehouseFacilities != null
          ? props.warehouseData.warehouseFacilities
          : "";
      const arrayFacility = facility.split(",");
    }
  };
  const handleChangeCustomer = (e) => {
    setcustomerrequired(false);
    setcheckInWarehouseCustomer({
      checkInCustomerId: e.value,
      checkInCustomerName: e.label,
    });
    props.getCurrentSelectedCustomer(e.value);
    // props.clearCommodityData();
    // console.log("props handling", customerList);

    props.addHandlingCharge(
      customerList.find((item) => {
        return item.customerId === e.value;
      })?.customerHandlingInCharge,
      "checkin"
    );
    // setTimeout(() => {
    //   // props.addHandlingCharge();
    //   console.log(
    //     "cuseotm handling in charge for other charges",
    //     props.selectedCustomer
    //   );
    // }, 5000);
  };
  // useEffect(() => {
  //   cargocheckindata.checkInCustomerPhone =
  //     props.selectedCustomer.customerWorkPhone;
  // }, [props.selectedCustomer.customerWorkPhone]);
  const warestyle = {
    control: (base) => ({
      ...base,
      borderColor: "#f46a6a",
    }),
  };
  const style = {
    control: (base) => ({
      ...base,
      borderColor: "#f46a6a",
    }),
  };
  const executeScroll = () => scrollRef.current.scrollIntoView();
  // handling charge

  function checkPalletAvailable(e, index) {
    if (e.target.value !== "") {
      const Url =
        BaseUrl.apiUrl.baseUrl +
        `api/v1/check_pallet_no_available/${e.target.value}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        if (resp.response.data.status === "Success") {
          setCheckPalletNumberAvailable(true);
        }
        if (resp.response.data.status === "Failed") {
          setCheckPalletNumberAvailable(false);
        }
      });
    } else {
      setCheckPalletNumberAvailable(true);
    }
  }
  useEffect(() => {
    if (formType == "add") {
      props.setCommodityTableData();
      // props.setCommodityTableOtherCharges();
    }
  }, []);
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content" ref={scrollRef}>
        {/* {validateTable ? (
          <Row className="m-0 mb-4">
            <Col md={12} className="bg-danger pt-3 white">
              <ul>
                <li>{props.validateValue}</li>
              </ul>
            </Col>
          </Row>
        ) : (
          ""
        )} */}

        <Container fluid>
          <Row>
            <Col md={12}>
              {formType == "add" ? (
                <h6>ADD NEW CARGO CHECK-IN</h6>
              ) : (
                <h6>EDIT CARGO CHECK-IN</h6>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                {validateTable ? (
                  <Row>
                    <Col md={12}>
                      <div className="mandatory-container">
                        <span>{props.validateValue}</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <CardBody>
                  <AvForm
                    className="form"
                    onValidSubmit={(e) => handleSubmit(e)}
                    ref={form}
                    // model={data}
                  >
                    <Row>
                      <Col md={12} className="">
                        <Row>
                          <Col md={2}>
                            <label>CheckIn date</label>
                            <AvField
                              name="checkInDate"
                              type="date"
                              onChange={handle}
                              value={cargocheckindata.checkInDate}
                              max={date}
                              required
                            ></AvField>
                          </Col>
                          <Col md={2}>
                            <label>CheckIn time</label>
                            <AvField
                              name="checkInTime"
                              type="time"
                              onChange={handle}
                              value={cargocheckindata.checkInTime}
                              required
                            ></AvField>
                          </Col>
                          <Col md={3}>
                            <label>Customer Name *</label>
                            <Select
                              name="checkInCustomerName"
                              styles={customerrequired ? style : ""}
                              onChange={handleChangeCustomer}
                              // onBlur={(e) => {
                              //   console.log("prop handling", e.value);
                              //   props.addHandlingCharge(
                              //     props.selectedCustomer
                              //       ?.customerHandlingInCharge
                              //   );
                              // }}
                              value={options.filter(
                                (option) =>
                                  option.label ===
                                  checkInWarehouseCustomer.checkInCustomerName
                              )}
                              options={options}
                              required
                              isDisabled={formType == "edit"}
                            />
                            {customerrequired ? (
                              <p style={{ fontSize: "80%", color: "#f46a6a" }}>
                                This field is invalid
                              </p>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col md={3}>
                            <label>Warehouse</label>
                            <Select
                              name="checkInWarehouseId"
                              onChange={handleChangeWarehouse}
                              options={warehouse}
                              value={warehouse?.filter(
                                (option) =>
                                  option.label === checkInWarehouseId.name
                              )}
                              required
                              styles={warehouserequired ? warestyle : ""}
                            />
                            {checkInWarehouseId.id != 0 &&
                            (props.warehouseData?.warehouseAreaAvailable ==
                              null ||
                              props.warehouseData?.warehouseAreaAvailable ==
                                "0") ? (
                              <span style={{ color: "red" }}>
                                Warehouse have no storage space
                              </span>
                            ) : (
                              ""
                            )}
                            {warehouserequired ? (
                              <p style={{ fontSize: "80%", color: "#f46a6a" }}>
                                This field is invalid
                              </p>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col md={3}>
                            <label>Contact Number</label>
                            <AvField
                              name="checkInCustomerPhone"
                              type="number"
                              onChange={handle}
                              value={
                                props.selectedCustomer.customerWorkPhone
                                  ? props.selectedCustomer.customerWorkPhone
                                  : cargocheckindata.checkInCustomerPhone
                              }
                              required
                            ></AvField>
                          </Col>
                          <Col md={3}>
                            <label>Contact Email</label>
                            <AvField
                              name="checkInCustomerEmail"
                              type="email"
                              onChange={handle}
                              value={
                                props.selectedCustomer.customerEmail
                                  ? props.selectedCustomer.customerEmail
                                  : cargocheckindata.checkInCustomerEmail
                              }
                              required
                              disabled
                            ></AvField>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <label>Customer Address</label>
                            <AvField
                              name="checkInCustomerAddress"
                              type="textarea"
                              onChange={handle}
                              value={
                                props.selectedCustomer.customerAddresses
                                  ? props.selectedCustomer.customerAddresses[1][
                                      "addressLine1"
                                    ] +
                                    " " +
                                    props.selectedCustomer.customerAddresses[1][
                                      "addressCity"
                                    ] +
                                    " " +
                                    props.selectedCustomer.customerAddresses[1][
                                      "addressState"
                                    ] +
                                    " " +
                                    props.selectedCustomer.customerAddresses[1][
                                      "addressCountryName"
                                    ]
                                  : cargocheckindata.checkInCustomerAddress
                              }
                              required
                            ></AvField>
                          </Col>

                          <Col md={4}>
                            <label>Quotation Number</label>
                            <AvField
                              name="checkInQuotationNumber"
                              type="text"
                              onChange={handle}
                              value={cargocheckindata.checkInQuotationNumber}
                              required
                            ></AvField>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={4}>
                            <label>Driver Name</label>
                            <AvField
                              name="checkInDriverName"
                              type="text"
                              onChange={handle}
                              value={cargocheckindata.checkInDriverName}
                            ></AvField>
                          </Col>

                          <Col md={4}>
                            <label>Vehicle Number</label>
                            <AvField
                              name="checkInVehicleNumber"
                              type="text"
                              onChange={handle}
                              value={cargocheckindata.checkInVehicleNumber}
                            ></AvField>
                          </Col>
                          <Col md={4}>
                            <label>Driving License</label>
                            <AvField
                              name="checkInDrivingLicense"
                              type="text"
                              onChange={handle}
                              value={cargocheckindata.checkInDrivingLicense}
                            ></AvField>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={6}>
                            <label>Rate / CBM (QAR)</label>
                            <AvField
                              name="checkInRatePerCbm"
                              type="number"
                              onChange={handle}
                              value={
                                formType == "edit"
                                  ? cargocheckindata.checkInRatePerCbm
                                  : props.selectedCustomer
                                      .customerFixedQuoteRate
                                  ? props.selectedCustomer
                                      .customerFixedQuoteRate
                                  : cargocheckindata.checkInRatePerCbm
                              }
                              required
                            ></AvField>
                          </Col>

                          <Col md={6}>
                            <label>Estimated Commodity Value (QAR)</label>
                            <AvField
                              name="checkInEstimatedCommodityValue"
                              type="number"
                              onChange={handle}
                              value={
                                cargocheckindata.checkInEstimatedCommodityValue
                              }
                              required
                            ></AvField>
                          </Col>
                        </Row>
                        <hr />
                      </Col>

                      <Col md={12}>
                        <Row className="mb-3">
                          <Col md={6}>
                            <strong>Cargo Details</strong>
                            {!checkPalletNumberAvailable ? (
                              <span style={{ marginLeft: "1em", color: "red" }}>
                                Pallet number already exists
                              </span>
                            ) : (
                              ""
                            )}
                            {props.isDuplicatePallet ? (
                              <span style={{ marginLeft: "1em", color: "red" }}>
                                &#9888; Duplicate pallet number found
                              </span>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col md={6} className="text-right">
                            <strong>
                              Warehouse Available Area :{" "}
                              {props.warehouseData.warehouseAreaAvailable
                                ? props.warehouseData.warehouseAreaAvailable
                                : "0"}{" "}
                              {props.warehouseData.warehouseAreaUnit
                                ? props.warehouseData.warehouseAreaUnit
                                : "cbm"}
                            </strong>
                            <div
                              style={{
                                color:
                                  props.totalItemArea >
                                  props.warehouseData.warehouseAreaAvailable
                                    ? "red"
                                    : "",
                              }}
                            >
                              <strong>
                                Total Item Area :{" "}
                                {props.totalItemArea
                                  ? props.totalItemArea.toFixed(3)
                                  : "0"}{" "}
                                cbm
                              </strong>
                            </div>
                            <div>
                              <strong>
                                {props.totalItemAreaSqm
                                  ? props.totalItemAreaSqm.toFixed(3)
                                  : "0"}{" "}
                                sqm
                              </strong>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <table
                              className="table table-bordered invoice-table table-responsive"
                              id="tab_logic"
                            >
                              <thead>
                                <tr>
                                  <th className="text-left"> COMMODITY</th>
                                  <th className="text-left"> INVOICE NO.</th>
                                  <th className="text-left"> HSN CODE</th>
                                  <th className="text-left">
                                    {" "}
                                    Unit Of Measure
                                  </th>
                                  <th className="text-left"> UNITS </th>
                                  <th className="text-left"> TOTAL ITEMS </th>
                                  <th className="text-left"> FACILITY </th>
                                  <th className="text-left"> HANDLING INFO</th>
                                  <th className="text-left"> DOE</th>
                                  <th className="text-left"> BATCH</th>
                                  <th className="text-left"> PALLET NO.</th>
                                  <th className="text-left"> WEIGHT</th>
                                  <th className="text-left">
                                    {" "}
                                    DIMENSIONS (centimeter)
                                  </th>
                                  <th className="text-left"> CBM</th>
                                  <th className="text-left"> SQM</th>
                                  <th className="text-left"> REMARKS</th>
                                  <th className="text-left">CARGO CONDITION</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {rowsData?.map((data, index) => {
                                  props.validateTableData(index);
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <AvField
                                          ref={selectRef}
                                          type="text"
                                          name="itemCommodityName"
                                          // onBlur={(e, index) =>
                                          //   addCommodity(e, index)
                                          // }
                                          onChange={(e) => {
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            );
                                            setcommodityName(e.target.value);
                                          }}
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemCommodityName"
                                                ]
                                              : commodityName
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                          className="form-control table-input"
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="text"
                                          name="itemInvoiceNumber"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemInvoiceNumber"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                          className="form-control table-input"
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="text"
                                          name="itemHsnCode"
                                          // onBlur={(e) => onHandleBlur(index, e)}
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemHsnCode"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                          className="form-control table-input"
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="select"
                                          className="table-select"
                                          name="itemUom"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemUom"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                        >
                                          <option value="" disabled selected>
                                            Select UOM
                                          </option>
                                          {uom.map((items) => (
                                            <option>{items.uom}</option>
                                          ))}
                                        </AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="number"
                                          name="itemUnits"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemUnits"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="number"
                                          name="itemUnitsPerSinglePackage"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemUnitsPerSinglePackage"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="select"
                                          className="table-select"
                                          name="itemTemperature"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemTemperature"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                        >
                                          <option value="" disabled selected>
                                            Select Facility
                                          </option>
                                          {props.warehouseData.warehouseFacilities
                                            ?.split(",")
                                            .map((items) => (
                                              <option>{items}</option>
                                            ))}
                                        </AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="select"
                                          className="table-select"
                                          name="itemHandlingInfo"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemHandlingInfo"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                        >
                                          <option value="" disabled selected>
                                            Select
                                          </option>
                                          {handlinginfo.map((items) => (
                                            <option>
                                              {items.handlingInfo}
                                            </option>
                                          ))}
                                        </AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="date"
                                          name="itemDateOfExpiry"
                                          className="form-control"
                                          min={date}
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemDateOfExpiry"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                        ></AvField>
                                        {props.tableRowData[index]?.[
                                          "itemHandlingInfo"
                                        ] === "Fragile" &&
                                        props.tableRowData[index]?.[
                                          "itemDateOfExpiry"
                                        ] === "" ? (
                                          <div
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            This field is required
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      <td>
                                        <AvField
                                          type="text"
                                          name="itemBatch"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemBatch"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                        ></AvField>
                                      </td>
                                      <td width="150px">
                                        <AvField
                                          type="text"
                                          name="itemPallet"
                                          className="form-control"
                                          // onChange={(e) =>
                                          //   props.updateCommodityRowTotal(
                                          //     index,
                                          //     e
                                          //   )
                                          // }
                                          onChange={(e) => {
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            );
                                            checkPalletAvailable(e, index);
                                          }}
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemPallet"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="number"
                                          name="itemTotalWeight"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemTotalWeight"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                        ></AvField>
                                      </td>
                                      <td>
                                        <div className="row">
                                          <div className="col-md-4">
                                            <label
                                              style={{ marginBottom: "0px" }}
                                            >
                                              L
                                            </label>
                                            <AvField
                                              type="number"
                                              name="itemLength"
                                              className="form-control"
                                              style={{
                                                borderBottom: "1px solid black",
                                                borderRadius: "0",
                                              }}
                                              onChange={(e) =>
                                                props.updateCommodityRowTotal(
                                                  index,
                                                  e
                                                )
                                              }
                                              value={
                                                props.tableRowData[index]
                                                  ? props.tableRowData[index][
                                                      "itemLength"
                                                    ]
                                                  : ""
                                              }
                                              disabled={
                                                props.tableRowData[index] &&
                                                props.tableRowData[index][
                                                  "isAllItemCheckedOut"
                                                ] === 1
                                              }
                                            ></AvField>
                                          </div>
                                          <div className="col-md-4">
                                            <label
                                              style={{ marginBottom: "0px" }}
                                            >
                                              W
                                            </label>
                                            <AvField
                                              type="number"
                                              name="itemBreadth"
                                              className="form-control"
                                              style={{
                                                borderBottom: "1px solid black",
                                                borderRadius: "0",
                                              }}
                                              onChange={(e) =>
                                                props.updateCommodityRowTotal(
                                                  index,
                                                  e
                                                )
                                              }
                                              value={
                                                props.tableRowData[index]
                                                  ? props.tableRowData[index][
                                                      "itemBreadth"
                                                    ]
                                                  : ""
                                              }
                                              disabled={
                                                props.tableRowData[index] &&
                                                props.tableRowData[index][
                                                  "isAllItemCheckedOut"
                                                ] === 1
                                              }
                                            ></AvField>
                                          </div>
                                          <div className="col-md-4">
                                            <label
                                              style={{ marginBottom: "0px" }}
                                            >
                                              H
                                            </label>
                                            <AvField
                                              type="number"
                                              name="itemHeight"
                                              className="form-control"
                                              style={{
                                                borderBottom: "1px solid black",
                                                borderRadius: "0",
                                              }}
                                              onChange={(e) =>
                                                props.updateCommodityRowTotal(
                                                  index,
                                                  e
                                                )
                                              }
                                              value={
                                                props.tableRowData[index]
                                                  ? props.tableRowData[index][
                                                      "itemHeight"
                                                    ]
                                                  : ""
                                              }
                                              disabled={
                                                props.tableRowData[index] &&
                                                props.tableRowData[index][
                                                  "isAllItemCheckedOut"
                                                ] === 1
                                              }
                                            ></AvField>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <AvField
                                          type="number"
                                          name="itemArea"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          // disabled={
                                          //   props.tableRowData[index]?.[
                                          //     "itemLength"
                                          //   ]
                                          // }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemArea"
                                                ]
                                              : 0
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="number"
                                          name="itemAreaSqm"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          // disabled={
                                          //   props.tableRowData[index]?.[
                                          //     "itemLength"
                                          //   ]
                                          // }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemAreaSqm"
                                                ]
                                              : 0
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="text"
                                          name="itemRemarks"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemRemarks"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                        ></AvField>
                                      </td>
                                      <td>
                                        <AvField
                                          type="text"
                                          name="itemCargoCondition"
                                          className="form-control"
                                          onChange={(e) =>
                                            props.updateCommodityRowTotal(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "itemCargoCondition"
                                                ]
                                              : ""
                                          }
                                          disabled={
                                            props.tableRowData[index] &&
                                            props.tableRowData[index][
                                              "isAllItemCheckedOut"
                                            ] === 1
                                          }
                                        ></AvField>
                                      </td>
                                      <td className="text-center">
                                        <span
                                          className=" btn-outline-danger"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          onClick={(e) =>
                                            deleteTableRow(index, e)
                                          }
                                        >
                                          <i class="bx bx-trash"></i>
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={8} className="table-left">
                            <div>
                              <button
                                type="button"
                                onClick={addTableRows}
                                className="btn btn-default pull-left add-tablerow"
                              >
                                <i class="bx bxs-plus-circle"></i>
                                <span>Add another line</span>
                              </button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Row>
                              <Col md={6}>
                                <h6>Other Charges (QAR)</h6>
                              </Col>
                              <Col md={6} className="text-right">
                                <button
                                  type="button"
                                  onClick={addOtherChargeTableRows}
                                  className="btn btn-default"
                                >
                                  <i
                                    class="bx bxs-plus-circle"
                                    style={{ color: "#052f85" }}
                                  ></i>
                                </button>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={12}>
                            <table className="table table-bordered invoice-table ">
                              <thead>
                                <tr>
                                  <th className="text-left"> Charge Name</th>
                                  <th className="text-left">Charge Amount</th>
                                  <th className="text-left">Order Charge</th>
                                </tr>
                              </thead>
                              <tbody>
                                {otherCharge.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <input
                                          type="text"
                                          name="chargeName"
                                          className="form-control"
                                          placeholder="Name"
                                          // onBlur={(e) =>
                                          //   props.addCommodityTableOtherCharges(
                                          //     e
                                          //   )
                                          // }
                                          onChange={(e) => {
                                            props.updateCommodityOtherCharges(
                                              index,
                                              e
                                            );
                                            setotherChargeName(e.target.value);
                                          }}
                                          value={
                                            props.otherChargeTableData[index]
                                              ? props.otherChargeTableData[
                                                  index
                                                ]["chargeName"]
                                              : otherChargeName
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          name="chargeAmount"
                                          className="form-control"
                                          placeholder="Amount"
                                          onChange={(e) =>
                                            props.updateCommodityOtherCharges(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.otherChargeTableData[index]
                                              ? props.otherChargeTableData[
                                                  index
                                                ]["chargeAmount"]
                                              : ""
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          name="chargeAmountCostSheet"
                                          className="form-control"
                                          placeholder="Order charge"
                                          onChange={(e) =>
                                            props.updateCommodityOtherCharges(
                                              index,
                                              e
                                            )
                                          }
                                          value={
                                            props.otherChargeTableData[index]
                                              ? props.otherChargeTableData[
                                                  index
                                                ]["chargeAmountCostSheet"]
                                              : ""
                                          }
                                        />
                                      </td>
                                      <td className="text-center" width="10px">
                                        <span
                                          className=" btn-outline-danger"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          onClick={(e) =>
                                            deleteOtherChargeTableRow(index, e)
                                          }
                                        >
                                          <i class="bx bx-trash"></i>
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {/* <BottomBar /> */}
                    <div className="bottom-bar">
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={4}>
                              <Link to="/cargocheckin-list">
                                <button
                                  type="button"
                                  className="btn btn-left ml-3"
                                >
                                  Cancel
                                </button>
                              </Link>
                            </Col>
                            {formType == "add" ? (
                              <Col md={4}>
                                <button
                                  type="button"
                                  onClick={clearForm}
                                  className="btn btn-left"
                                >
                                  Clear
                                </button>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Col>

                        <Col md={8}>
                          <button
                            type="submit"
                            className="btn btn-right float-right mr-3"
                            disabled={
                              !checkPalletNumberAvailable ||
                              props.isDuplicatePallet ||
                              reload
                            }
                          >
                            {reload ? (
                              <img
                                className="loader"
                                src={loader}
                                alt="loading..."
                              />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const {
    tableRowData,
    warehouseData,
    totalItemArea,
    totalItemAreaSqm,
    otherChargeTableData,
    validateValue,
    handlingCharge,
    isDuplicatePallet,
  } = state.CommodityTableData;
  const { selectedCustomer } = state.Layout;

  return {
    tableRowData,
    warehouseData,
    totalItemArea,
    totalItemAreaSqm,
    selectedCustomer,
    otherChargeTableData,
    validateValue,
    handlingCharge,
    isDuplicatePallet,
  };
};
export default connect(mapStatetoProps, {
  addCommodityTableData,
  setCommodityTableData,
  setCommodityTableOtherCharges,
  deleteTableRow,
  updateCommodityRowTotal,
  clearCommodityData,
  requestWarehouseData,
  getCurrentSelectedCustomer,
  addCommodityTableOtherCharges,
  updateCommodityOtherCharges,
  deleteOtherChargeTableRow,
  clearCustomerData,
  populateTableRowData,
  populateOtherChargeTableRowData,
  validateTableData,
  addHandlingCharge,
})(AddCargoCheckIn);
