import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
  changeTopbarTheme,
  showRightSidebarAction,
} from "../../store/actions";

//SimpleBar
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

import "./rightbar.scss";
//Import images
import layout1 from "../../assets/images/layouts/layout-1.jpg";
import layout2 from "../../assets/images/layouts/layout-2.jpg";
import layout3 from "../../assets/images/layouts/layout-3.jpg";
import classnames from "classnames";
const RightSidebar = (props) => {
  const [hideDetails, setHideDetails] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const statuses = [
    {
      id: 1,
      stausTitle: "Ordered",

      description: "New common language will be more simple than existing.",
    },
    {
      id: 2,
      stausTitle: "Packed",

      description:
        "To an English person, it will seem like simplified English existence.",
    },
    {
      id: 3,
      stausTitle: "Shipped",

      description:
        "It will be as simple as occidental in fact it will be Cambridge",
    },
  ];

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div className="right-bar">
        <SimpleBar style={{ height: "100vh" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  props.showRightSidebarAction(false);
                }}
                className="right-bar-toggle float-right"
              >
                <i className="mdi mdi-close noti-icon"></i>
              </Link>
              <h5 className="m-0">Customer Details</h5>
            </div>
            <hr className="my-0" />
            <div className="p-4 sidebar_head">
              <div className="profile_pic">
                <img src="https://t3.ftcdn.net/jpg/04/34/72/82/360_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg" />
              </div>
              <div className="profile">
                <span className="name">
                  {props.selectedCustomer.customerFirstname}
                </span>
              </div>
            </div>
            <div className="p-4 sidebar_body">
              <div className="body">
                <div>
                  <span>Outstanding Receivables</span>
                  <h6>₹1,120.00</h6>
                </div>
                <div className="text-right">
                  <span>Unused Credits</span>
                  <h6>₹0.00</h6>
                </div>
              </div>
              <div
                className="other"
                onClick={() => setHideDetails(!hideDetails)}
              >
                <span>Other Details</span>
                {hideDetails === false ? (
                  <i className="bx  bxs-right-arrow "></i>
                ) : (
                  <i className="bx bxs-down-arrow"></i>
                )}
              </div>
              {hideDetails === true ? (
                <div className="other_details">
                  <Row>
                    <Col md={5}>
                      <span>Customer Type</span>
                    </Col>
                    <Col md={7}>{props.selectedCustomer.customerType}</Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <span>Currency Code</span>
                    </Col>
                    <Col md={7}>{props.selectedCustomer.customerCurrency}</Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <span>Credit Limit</span>
                    </Col>
                    <Col md={7}></Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <span>Payment Terms</span>
                    </Col>
                    <Col md={7}>
                      {props.selectedCustomer.customerPaymentTerms}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <span>Portal Status</span>
                    </Col>
                    <Col md={7}>Disabled</Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <span>Portal Language</span>
                    </Col>
                    <Col md={7}></Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <span>GST Treatment</span>
                    </Col>
                    <Col md={7}>
                      {props.selectedCustomer.customerGstTreatment}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <span>Place of Supply</span>
                    </Col>
                    <Col md={7}>
                      {props.selectedCustomer.customerPlaceOfSupply}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <span>Tax Preference</span>
                    </Col>
                    <Col md={7}>
                      {props.selectedCustomer.customerTaxPreference}
                    </Col>
                  </Row>
                </div>
              ) : (
                ""
              )}
            </div>
            <hr className="my-0" />
            <div className="sidebar-tabs">
              <Nav tabs className="nav-tabs-custom nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block tab-fs">Address</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block tab-fs">
                      Contact persons
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleCustom("3");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-envelope"></i>
                    </span>
                    <span className="d-none d-sm-block tab-fs">Activity</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent
                activeTab={customActiveTab}
                className="p-4 text-muted"
              >
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <div className="rightbar_address">
                        <div className="bhead">
                          <i className="bx bx-notepad "></i>
                          <h6>Billing Address</h6>
                        </div>
                        {props.selectedCustomer.customerAddresses.map(
                          (address) =>
                            address.addressType === 1 ? (
                              <div className="bbody">
                                <span>{address.addressLine1}</span>
                                <span>{address.addressLine2}</span>
                                <span>{address.addressCity}</span>
                                <span>
                                  {address.addressStateId == 0
                                    ? ""
                                    : address.addressStateId}{" "}
                                  {address.addressZipCode}
                                </span>
                                <span>{address.addressCountry}</span>
                                {address.addressPhone == "" ? (
                                  ""
                                ) : (
                                  <span>Phone: {address.addressPhone}</span>
                                )}
                              </div>
                            ) : (
                              ""
                            )
                        )}

                        <div className="bhead mt-4">
                          <i className="bx bxs-truck"></i>
                          <h6>Shipping Address</h6>
                        </div>
                        {props.selectedCustomer.customerAddresses.map(
                          (address) =>
                            address.addressType === 2 ? (
                              <div className="bbody mb-5">
                                <span>{address.addressLine1}</span>
                                <span>{address.addressLine2}</span>
                                <span>{address.addressCity}</span>
                                <span>
                                  {address.addressStateId == 0
                                    ? ""
                                    : address.addressStateId}{" "}
                                  {address.addressZipCode}
                                </span>
                                <span>{address.addressCountry}</span>
                                {address.addressPhone == "" ? (
                                  ""
                                ) : (
                                  <span>Phone: {address.addressPhone}</span>
                                )}
                              </div>
                            ) : (
                              ""
                            )
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  {props.selectedCustomer.contactPersons == null ? (
                    "No contact persons available"
                  ) : (
                    <Row>
                      {props.selectedCustomer.contactPersons.map((contacts) => (
                        <Col sm="12">
                          <div className="rightbar_contact">
                            <div className="contact_pic">
                              <img src="https://upload.wikimedia.org/wikipedia/commons/a/aa/Sin_cara.png" />
                            </div>

                            <div className="contact_address">
                              <h6>
                                {contacts.contactSalutation}{" "}
                                {contacts.contactFirstname}{" "}
                                {contacts.contactLastname}{" "}
                                <i class="bx bxs-star"></i>
                              </h6>
                              <span>{contacts.contactEmail}</span>
                              <span>
                                {" "}
                                <i class="bx bxs-phone"></i>
                                {contacts.contactMobile}
                              </span>
                              <span>
                                {" "}
                                <i class="bx bxs-mobile"></i>
                                {contacts.contactWorkPhone}
                              </span>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <div className="">
                        <ul className="verti-timeline list-unstyled">
                          {/* Render Horizontal Timeline Events */}
                          {statuses.map((status, key) => (
                            <li key={key} className="event-list">
                              <div className="event-timeline-dot">
                                <i className={"bx bx-right-arrow-circle"} />
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <div className="timeline-time">
                                      <h5>{status.stausTitle}</h5>
                                      <span>09/06/2022 11:02 AM</span>
                                    </div>
                                    <p className="text-muted">
                                      {status.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay"></div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  // setCustomer(state.Layout.)
  return { ...state.Layout };
};

export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  changePreloader,
  showRightSidebarAction,
})(RightSidebar);
