import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { BaseUrl } from "../../components/config/BaseUrl";
import { Link, useHistory } from "react-router-dom";
import { apiGet, apiPost, apiPut } from "../../components/config/apiConfig";
import classnames from "classnames";
//import BillingAddress from "./billingDetails";
import loader from "../../assets/loader/load.gif";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";
//import AddTable from "./addTable";
import BottomBar from "./bottomBar";
import { connect } from "react-redux";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

// Pages Component
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
import {
  getCurrentSelectedCustomer,
  getCurrentSelectedItem,
  addTableDataOt,
  updateRowTotalOt,
  calculateShippingChargeOt,
  calculateAdjustmentChargeOt,
  deleteTableRowOt,
  populateTableDataOt,
  clearCustomerData,
  clearTableDataOt,
  updateRowSelectOt,
  // getTableData,
} from "../../store/actions";
import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } fro m "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRef } from "react";
import { addDays, addMonths, endOfMonth, format, setDate } from "date-fns";
import { useParams } from "react-router-dom";
import { tr } from "date-fns/locale";
import { IoIosAddCircle } from "react-icons/io";

const AddSalesOrder = (props) => {
  const form = useRef(null);
  const history = useHistory();
  // const { formType, id } = useParams();
  var formType = props.match.params.type;
  var id = props.match.params.id;
  const [itemData, setItemData] = useState([]);
  const [salespersonData, setSalespersonData] = useState([]);
  const [deliveryMethodData, setDeliveryMethodData] = useState([]);
  const [soData, setsalesorderdata] = useState({});
  const [rowsData, setRowsData] = useState([
    {
      itemId: 0,
      itemName: "",
      itemDescription: "",
      // itemDetails: action.payload.itemName,
      itemQuantity: "",
      //itemUnit: "CBM",
      itemRate: "",
      itemDiscountValue: "",
      itemDiscountType: "",
      // invItemTaxId: 0,
      // invItemTaxPercent: 0,
      itemAmount: 0,
    },
  ]);
  const [state, setState] = useState([]);
  const [gstTreatment, setgsttreatment] = useState([]);
  const [paymentterms, setpaymentterms] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [customerList, setCustomerList] = useState([]);
  const [reload, setReload] = useState(false);
  const [billingaddressid, setbillingaddressid] = useState();
  const [shippingaddressid, setshippingaddressid] = useState();
  const [checkInWarehouseId, setcheckInWarehouseId] = useState({
    id: 0,
    name: "",
  });
  var today = new Date();
  var formatedDate =
    today.getMonth() + 1 + "-" + today.getDate() + "-" + today.getFullYear();
  const clearForm = () => {
    form.current.reset(); //this will reset all the inputs in the form
    props.clearCustomerData();
    props.clearTableDataOt();
  };
  const currentDate = format(new Date(), "yyyy-MM-dd");

  const [data, setData] = useState({
    salesOrderCustomerId: 0,
    salesOrderBillingAddressId: 0,
    salesOrderShippingAddressId: 0,
    salesOrderCustomerRemarks: "",
    salesOrderReferenceNumber: "",
    salesOrderDate: currentDate,
    salesOrderShipmentDate: "",
    salesOrderPaymentTerms: "",
    salesOrderDeliveryMethod: "",
    salesOrderSalesPersonId: 0,
    salesOrderPriceListId: 0,
    salesOrderCustomerNotes: "",
    salesOrderTermsAndConditions: "",

    salesOrderCustomerName: "",
    salesOrderCustomerShippingAddress: "",
    salesOrderCustomerBillingAddress: "",
    salesOrderCustomerContactNumber: "",
  });

  const addTableRows = () => {
    const rowsInput = {
      itemId: 0,
      itemName: "",
      itemDescription: "",
      // itemDetails: action.payload.itemName,
      itemQuantity: "",
      //itemUnit: "CBM",
      itemRate: "",
      itemDiscountValue: "",
      itemDiscountType: "",
      // invItemTaxId: 0,
      // invItemTaxPercent: 0,
      itemAmount: 0,
    };
    setRowsData([...rowsData, rowsInput]);
    props.addTableDataOt();
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    props.deleteTableRowOt(index);
  };

  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;

    props.getCurrentSelectedItem(value);

    // props.updateRowTotal(index, evnt);
    setRowsData(rowsInput);
    // addTableRows();
  };

  const onHandleBlur = (index, e) => {};
  function handle(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }
  function handleDueDate(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    updateDueDate();
  }
  function handleCustomerChange(e) {
    props.getCurrentSelectedCustomer(e.target.value);
    updateDueDate();
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    // setData({
    //   ...data,
    //   invoicePaymentTermsId: props.selectedCustomer?.customerPaymentTermsId,
    // });
  }
  const handleChangeWarehouse = (e) => {
    setcheckInWarehouseId({
      id: e.value,
      name: e.label,
    });
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  function getState() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/states/1`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setState(resp.response.data.data);
      }
    });
  }
  function getGstTreatment() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/gst_treatments`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setgsttreatment(resp.response.data.data);
      }
    });
  }
  function getPaymentTerms() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/payment_terms`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setpaymentterms(resp.response.data.data);
      }
    });
  }
  function createSalesOrder() {
    setReload(true);
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/sales_order";

    let body = {
      salesOrderCustomerId: props.selectedCustomer.customerId,

      salesOrderBillingAddressId: billingaddressid,
      salesOrderShippingAddressId: shippingaddressid,
      salesOrderCustomerRemarks: " ",
      salesOrderReferenceNumber: data.salesOrderReferenceNumber,
      salesOrderDate: data.salesOrderDate,
      salesOrderPaymentTerms: data.salesOrderPaymentTerms,

      salesOrderShipmentDate: data.salesOrderShipmentDate,
      salesOrderSalesPersonId: parseInt(data.salesOrderSalesPersonId),
      salesOrderDeliveryMethod: data.salesOrderDeliveryMethod,
      salesOrderSubTotal: props.subTotal ? props.subTotal : 0,
      salesOrderShippigCharges: props.shippingCharge
        ? parseInt(props.shippingCharge)
        : 0,
      salesOrderAdjustment: props.adjustment ? parseInt(props.adjustment) : 0,
      salesOrderRoundOff: 0,
      salesOrderTotal: props.total ? props.total : 0,
      salesOrderCustomerNotes: data.salesOrderCustomerNotes,
      salesOrderTermsAndConditions: data.salesOrderTermsAndConditions,
      salesOrderCustomerName: props.selectedCustomer.customerDisplayName,
      salesOrderCustomerBillingAddress: "",
      salesOrderCustomerShippingAddress: "",
      salesOrderCustomerContactNumber: props.selectedCustomer.customerWorkPhone,
      organizationId: orgId,
      //invoiceWarehouseId: parseInt(checkInWarehouseId.id),
      //invoiceWarehouseName: checkInWarehouseId.name,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
      salesOrderItemsList: props.tableRowDataOt,
    };

    let resp = apiPost(url, body);
    resp.then((resp) => {
      setReload(false);

      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            history.push("/salesorder");
          }, 1000);
          break;

        default:
          toast.error(resp.response.data.data);
      }
    });
  }
  function updateSalesOrdeer() {
    setReload(true);
    let url = BaseUrl.apiUrl.baseUrl + `api/v1/sales_order/${id}`;

    let body = {
      salesOrderCustomerId: soData.salesOrderCustomerId,
      salesOrderBillingAddressId: soData.salesOrderBillingAddressId,
      salesOrderShippingAddressId: soData.salesOrderShippingAddressId,
      salesOrderCustomerRemarks: data.salesOrderCustomerRemarks,
      salesOrderReferenceNumber: data.salesOrderReferenceNumber,
      salesOrderDate: data.salesOrderDate,
      salesOrderPaymentTerms: data.salesOrderPaymentTerms,
      salesOrderShipmentDate: data.salesOrderShipmentDate,
      salesOrderDeliveryMethod: data.salesOrderDeliveryMethod,
      salesOrderSalesPersonId: parseInt(data.salesOrderSalesPersonId),
      salesOrderSubTotal: props.subTotal ? props.subTotal : 0,
      salesOrderShippigCharges: props.shippingCharge
        ? parseInt(props.shippingCharge)
        : 0,
      salesOrderAdjustment: props.adjustment ? parseInt(props.adjustment) : 0,
      salesOrderRoundOff: 0,
      salesOrderTotal: props.total ? props.total : 0,
      salesOrderCustomerNotes: data.salesOrderCustomerNotes,
      salesOrderTermsAndConditions: data.salesOrderTermsAndConditions,
      salesOrderCustomerName: props.selectedCustomer.customerDisplayName,
      salesOrderCustomerBillingAddress: "",
      salesOrderCustomerShippingAddress: "",
      salesOrderCustomerContactNumber: soData.salesOrderCustomerContactNumber,
      editByUser: 1,
      salesOrderItemsList: props.tableRowDataOt,
    };
    let resp = apiPut(url, body);
    resp.then((resp) => {
      setReload(false);

      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            history.push(`/salesorderview/${id}`);
          }, 1000);
          break;
        case 500:
          toast.error(resp.response.data.status);
          break;
        default:
          toast.error(resp.response.data.status);
      }
    });
  }
  const updateDueDate = (e) => {
    if (props.selectedCustomer?.customerPaymentTermsId) {
      let startDate = new Date(data.invoiceDate);
      let date = e?.target?.value.length
        ? e?.target?.value
        : data.invoicePaymentTermsId.length
        ? data.invoicePaymentTermsId
        : props.selectedCustomer?.customerPaymentTermsId
        ? props.selectedCustomer?.customerPaymentTermsId
        : data.invoicePaymentTermsId;
      let term =
        date == "1"
          ? 15
          : date == "2"
          ? 30
          : date == "3"
          ? 45
          : date == "4"
          ? 60
          : "";
      if (date == "8") return;
      let updatedValue = {};
      updatedValue = {
        invoiceDueDate: format(
          date == "5"
            ? endOfMonth(startDate)
            : date == "6"
            ? endOfMonth(addMonths(startDate, 1))
            : addDays(startDate, term),
          "yyyy-MM-dd"
        ),
      };
      setData((prevData) => ({
        ...prevData,
        ...updatedValue,
      }));
    }
  };
  useEffect(() => {
    //if (props.selectedCustomer?.customerPaymentTermsId) {
    // updateDueDate();
    // }
  }, [props.selectedCustomer?.customerPaymentTermsId, data.invoiceDate]);

  function handleSubmit(e) {
    if (formType == "add") {
      createSalesOrder();
    } else if (formType == "edit") {
      updateSalesOrdeer();
    }
  }

  function getItemData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setItemData(resp.response.data.data);
    });
  }
  function getSalesPersonData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/users/role/SALESPERSON`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setSalespersonData(resp.response.data.data);
    });
  }
  function getDeliveryMethodData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/delivery_method`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setDeliveryMethodData(resp.response.data.data);
    });
  }
  function getData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setCustomerList(resp.response.data.data);
    });
  }
  const custOptions = customerList.map((cust) => ({
    value: cust.customerId,
    label: cust.customerDisplayName,
  }));
  // customer add button on Select
  const CustomButton = ({ onClick }) => (
    <Link to="/customer/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Customer
      </button>
    </Link>
  );

  const DropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomButton onClick={console.log("clicked")} />
      </div>
    );
  };

  const customComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: DropdownMenu, // Use your custom dropdown menu
  };
  // const customAddCustomerOption = {
  //   label: (
  //     <Link to="/customer/add">
  //       <span style={{ color: "#408dfb" }}>
  //         <span style={{ marginRight: "8px", color: "#408dfb" }}>
  //           <IoIosAddCircle color="#408dfb" />
  //         </span>{" "}
  //         Add Customer
  //       </span>
  //     </Link>
  //   ),
  //   value: "add-customer",
  //   onClick: () => {
  //     console.log("Add Customer Clicked");
  //     history.push("/customer/add");
  //   },
  //   isFixed: true,
  // };

  // const options = [...custOptions, customAddCustomerOption];

  function getSalesOrderDataById() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/sales_order/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      var datas = resp.response.data.data;
      console.log("so items", datas.salesorderItemsList);
      setData(datas);
      setsalesorderdata(datas);
      props.populateTableDataOt(
        datas.salesOrderSubTotal,
        datas.salesOrderShippigCharges,
        datas.salesOrderAdjustment,
        datas.salesOrderTotal,
        datas.salesOrderItemsList
      );
      setRowsData(
        datas.salesOrderItemsList.map((items) => {
          return {
            itemId: items.itemId,
            itemName: items.itemName,
            itemQuantity: items.itemQuantity,
            itemRate: items.itemRate,
            itemDiscountValue: items.itemDiscountValue,
            itemDiscountType: items.itemDiscountType,
            itemTaxPercent: items.invItemTaxPercent,
            itemAmount: items.invItemAmount,
          };
        })
      );
      props.getCurrentSelectedCustomer(datas.salesOrderCustomerId);
    });
  }
  const warehouse =
    props.selectedCustomer.warehouseList &&
    props.selectedCustomer.warehouseList.map((item) => {
      return {
        value: item.warehouseId,
        label: item.warehouseName,
      };
    });
  useEffect(() => {
    if (formType === "edit") {
      if (props.selectedCustomer.warehouseList?.length) {
        setcheckInWarehouseId({
          id: props.selectedCustomer.warehouseList[0].warehouseId,
          name: props.selectedCustomer.warehouseList[0].warehouseName,
        });
      }
    }
  }, [props.selectedCustomer]);

  // const handleQuantityZero = (e) => {
  //   if (e.target.value == 0) {
  //     toast.error(" Quantity is Empty !", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: false,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // };
  // const handleRateZero = (e) => {
  //   if (e.target.value == 0) {
  //     toast.error(" Rate is Empty !", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: false,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // };
  useEffect(() => {
    if (formType == "edit") {
      getSalesOrderDataById();
    }
    getData();
    getState();
    getGstTreatment();
    getItemData();
    getPaymentTerms();
    getSalesPersonData();
    getDeliveryMethodData();
    return () => {
      form.current.reset(); //this will reset all the inputs in the form
      props.clearCustomerData();
      props.clearTableDataOt();
    };
  }, []);
  useEffect(() => {
    console.log("in use effect");
    if (formType == "add") {
      console.log("in use effect - if");
      props.addTableDataOt();
    }
  }, []);

  // item custom button
  const CustomItemButton = ({ onClick }) => (
    <Link to="/additems/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Item
      </button>
    </Link>
  );
  const ItemDropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomItemButton />
      </div>
    );
  };
  const customItemComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: ItemDropdownMenu, // Use your custom dropdown menu
  };

  const reactSelectOptions = itemData.map((items) => ({
    value: items.itemId,
    label: items.itemName,
  }));
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>
                {formType == "add"
                  ? "Add New Sales Order"
                  : formType == "edit"
                  ? "Edit Sales Order"
                  : ""}
              </h6>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className="form"
                    onValidSubmit={(e) => handleSubmit(e)}
                    ref={form}
                  >
                    <Row>
                      <Col md={8} className="formLeft">
                        <Row className="mb-3">
                          <Col md={2}>
                            <label>Customer name</label>{" "}
                          </Col>
                          <Col md={4}>
                            {/* <AvField
                              name="salesOrderCustomerId"
                              type="select"
                              value={data.salesOrderCustomerId}
                              onChange={(e) => handleCustomerChange(e)}
                              required
                            >
                              <option selected disabled value="">
                                Select a customer
                              </option>
                              {customerList.map((customer) => (
                                <option value={customer.customerId}>
                                  {customer.customerDisplayName}
                                </option>
                              ))}
                            </AvField> */}
                            <Select
                              options={custOptions}
                              // className="formik-input"
                              components={customComponents}
                              isClearable
                              name="salesOrderCustomerId"
                              placeholder="Select Customer"
                              // defaultValue={customAddCustomerOption}
                              // value={values.salesOrderCustomerId}
                              value={
                                custOptions?.filter(
                                  (item) =>
                                    item.value == data.salesOrderCustomerId
                                ) || ""
                              }
                              onChange={(option) => {
                                setData({
                                  ...data,
                                  salesOrderCustomerId: option.value,
                                });
                                if (option.value == "add-customer") {
                                  console.log(true);
                                  history.push("/customer/add");
                                  // setPushToCustomer(true);
                                }
                                props.getCurrentSelectedCustomer(option.value);
                                updateDueDate();
                              }}
                              isFixed="add-customer"
                              // components={customComponents}
                            />
                            {/* <Link to="/customer/add">
                              <i class="bx bx-plus"></i>
                              {""}
                              <span className="addlinks">
                                Add a new customer
                              </span>
                            </Link> */}
                          </Col>
                          {/*<Col md={4}>
                            <label>Warehouse</label>
                            <Select
                              name="checkInWarehouseId"
                              onChange={handleChangeWarehouse}
                              options={warehouse}
                              value={warehouse?.filter(
                                (option) =>
                                  option.label === checkInWarehouseId.name
                              )}
                              defaultValue={"Select"}
                              required
                            />
                              </Col>*/}
                        </Row>
                        <Row className="mb-3">
                          <Col md={2}>
                            <label>Branch</label>
                          </Col>
                          <Col md={10}>
                            <AvField
                              name="branch"
                              type="select"
                              value={data.branch}
                              onChange={(e) => handle(e)}
                            >
                              <option value="0" selected disabled>
                                {" "}
                                Opine
                              </option>
                            </AvField>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={2}>
                            <label>Reference Number</label>
                          </Col>
                          <Col md={10}>
                            <AvField
                              name="salesOrderReferenceNumber"
                              type="text"
                              placeholder="Enter reference number"
                              value={data.salesOrderReferenceNumber}
                              onChange={(e) => handle(e)}
                            ></AvField>
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Col md={2}>
                            <label>Terms of Pay</label>
                          </Col>
                          <Col md={10}>
                            <AvField
                              name="salesOrderPaymentTerms"
                              type="select"
                              placeholder="&#x260E; Select Terms"
                              value={
                                data.salesOrderPaymentTerms ||
                                props.selectedCustomer?.customerPaymentTermsId
                              }
                              onChange={(e) => {
                                handle(e);
                                //updateDueDate(e);
                              }}
                            >
                              <option value="0" selected>
                                Select payment terms
                              </option>
                              {paymentterms.map((payment) => (
                                <option value={payment.paymentTermName}>
                                  {payment.paymentTermName}
                                </option>
                              ))}
                            </AvField>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md={2}>
                            <label>Sales Order date</label>
                          </Col>
                          <Col md={4}>
                            <AvField
                              name="salesOrderDate"
                              type="date"
                              placeholder="Select sales order date"
                              value={data.salesOrderDate}
                              onChange={(e) => handle(e)}
                              required
                            ></AvField>
                          </Col>
                          <Col md={2}>
                            <label>Expected Shipment date</label>
                          </Col>
                          <Col md={4}>
                            <AvField
                              name="salesOrderShipmentDate"
                              type="date"
                              placeholder="Select shipment date"
                              value={data.salesOrderShipmentDate}
                              onChange={(e) => {
                                handle(e);
                                // handleDueDate(e);
                              }}
                              min={formType == "add" ? currentDate : ""}
                            ></AvField>
                          </Col>
                        </Row>

                        <hr />
                        <Row className="mb-4">
                          <Col md={2}>
                            <label className="formik-label">
                              {" "}
                              Delivery Method
                            </label>
                          </Col>
                          <Col md={10}>
                            <AvField
                              name="salesOrderDeliveryMethod"
                              type="select"
                              placeholder="&#x260E; Select Delivery Method"
                              value={data.salesOrderDeliveryMethod}
                              onChange={(e) => {
                                handle(e);
                                //updateDueDate(e);
                              }}
                            >
                              <option value="" selected>
                                Select delivery Method
                              </option>
                              {deliveryMethodData.map((dm) => (
                                <option value={dm.deliveryMethod}>
                                  {dm.deliveryMethod}
                                </option>
                              ))}
                            </AvField>
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Col md={2}>
                            <label className="formik-label"> Salesperson</label>
                          </Col>
                          <Col md={10}>
                            <AvField
                              name="salesOrderSalesPersonId"
                              type="select"
                              placeholder="&#x260E; Select sales person"
                              value={data.salesOrderSalesPersonId}
                              onChange={(e) => {
                                handle(e);
                                //updateDueDate(e);
                              }}
                            >
                              <option value="" selected>
                                Select sales person
                              </option>
                              {salespersonData.map((sp) => (
                                <option value={sp.id}>
                                  {sp.firstname + " " + sp.lastname}
                                </option>
                              ))}
                            </AvField>
                            <div>
                              <span>
                                Select a customer to associate a project.
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={4}>
                        <Row>
                          <Col md={12}>
                            <h6>Billing Address</h6>
                          </Col>
                          {/* <Col md={12}>
                            <Link>
                              <i class="bx bx-plus"></i> Add a new address
                            </Link>
                          </Col> */}
                          <Col md={12}>
                            {props.selectedCustomerId == "" ? (
                              <div className="selected-address">
                                Select a customer
                              </div>
                            ) : (
                              props.selectedCustomer?.customerAddresses?.map(
                                (address) =>
                                  address.addressType == 1 ? (
                                    <div className="selected-address">
                                      <p>{address.addressAttention}</p>
                                      <div className="sel-address">
                                        <p>
                                          {address.addressLine1}
                                          <br></br> {address.addressLine2}
                                          <br></br>
                                          {address.addressCity +
                                            ", " +
                                            address.addressState}
                                          <br></br> {address.addressCountryName}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                              )
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr />
                    <div className="row clearfix">
                      <div className="col-md-12 column">
                        <table
                          className="table table-bordered invoice-table"
                          id="tab_logic"
                        >
                          <thead>
                            <tr>
                              <th className="text-left"> ITEM DETAILS</th>
                              <th className="text-left"> QUANTITY</th>

                              <th className="text-left"> RATE</th>
                              <th className="text-right"> DISCOUNT </th>
                              <th className="text-right"> AMOUNT</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.tableRowDataOt?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {/* <AvField
                                      type="select"
                                      name="itemId"
                                      value={
                                        props.tableRowDataOt[index]
                                          ? props.tableRowDataOt[index][
                                              "itemId"
                                            ]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        props.updateRowTotalOt(index, e)
                                      }
                                      className="form-control"
                                      placeholder="Item"
                                      required
                                    >
                                      <option selected value={0}>
                                        Select Item
                                      </option>
                                      {itemData.map((items, index) => (
                                        <option value={items.itemId}>
                                          {items.itemName}
                                        </option>
                                      ))}
                                    </AvField> */}
                                    <Select
                                      required
                                      // className="form-control"
                                      components={customItemComponents}
                                      name="invItemId"
                                      onChange={(e) => {
                                        // const selectedValue = selectedOption
                                        //   ? selectedOption.value
                                        //   : "";
                                        // handleTableSelectChange(
                                        //   index,
                                        //   selectedValue
                                        // );
                                        // console.log("select change", e);
                                        // props.getCurrentSelectedItem(e.value);
                                        // var itemData = {
                                        //   invItemId: e.value,
                                        //   invItemRate:
                                        //     props?.selectedItem
                                        //       ?.itemSalesSellingPrice,
                                        // };
                                        props.updateRowSelectOt(index, e.value);
                                      }}
                                      value={reactSelectOptions.find(
                                        (option) =>
                                          option.value ==
                                          (props.tableRowDataOt[index]
                                            ? props.tableRowDataOt[index][
                                                "invItemId"
                                              ]
                                            : "")
                                      )}
                                      options={reactSelectOptions}
                                    />
                                  </td>
                                  <td>
                                    <AvField
                                      type="text"
                                      name="itemQuantity"
                                      value={
                                        props.tableRowDataOt[index]
                                          ? props.tableRowDataOt[index]?.[
                                              "itemQuantity"
                                            ]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        props.updateRowTotalOt(index, e)
                                      }
                                      className="form-control table-input"
                                      placeholder="Eg: 100"
                                      required
                                    ></AvField>
                                  </td>

                                  <td>
                                    <AvField
                                      type="text"
                                      name="itemRate"
                                      value={
                                        props.tableRowDataOt[index]
                                          ? props.tableRowDataOt[index]?.[
                                              "itemRate"
                                            ]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        props.updateRowTotalOt(index, e)
                                      }
                                      className="form-control"
                                      placeholder="Eg: 0.00"
                                      required
                                    ></AvField>
                                  </td>
                                  <td width="18%">
                                    <div className="table-inputs d-flex align-items-center">
                                      <div>
                                        {" "}
                                        <AvField
                                          type="number"
                                          name="itemDiscountValue"
                                          value={
                                            props.tableRowDataOt[index]
                                              ? props.tableRowDataOt[index]?.[
                                                  "itemDiscountValue"
                                                ]
                                              : ""
                                          }
                                          onChange={(e) =>
                                            props.updateRowTotalOt(index, e)
                                          }
                                          className="form-control"
                                          placeholder="Eg: 0"
                                        ></AvField>
                                      </div>
                                      <div>
                                        <AvField
                                          type="select"
                                          name="itemDiscountType"
                                          value={
                                            props.tableRowDataOt[index]
                                              ? props.tableRowDataOt[index]?.[
                                                  "itemDiscountType"
                                                ]
                                              : "1"
                                          }
                                          onChange={(e) =>
                                            props.updateRowTotalOt(index, e)
                                          }
                                          className="form-control"
                                          placeholder="Discount Type"
                                        >
                                          <option value="1">%</option>
                                          <option value="2">Rs.</option>
                                        </AvField>
                                      </div>
                                    </div>
                                  </td>

                                  <td width="15%">
                                    <AvField
                                      disabled
                                      type="text"
                                      name="itemAmount"
                                      value={
                                        props.tableRowDataOt[index]
                                          ? props.tableRowDataOt[index]?.[
                                              "itemAmount"
                                            ]
                                          : 0
                                      }
                                      className="form-control text-right"
                                    ></AvField>
                                  </td>

                                  <td className="text-center" width="10px">
                                    <span
                                      className=" btn-outline-danger"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      onClick={(e) => deleteTableRows(index, e)}
                                    >
                                      <i class="bx bx-trash"></i>
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <Row>
                          <Col md={6} className="table-left">
                            <div>
                              <button
                                type="button"
                                onClick={addTableRows}
                                className="btn btn-default pull-left add-tablerow"
                              >
                                <i class="bx bxs-plus-circle"></i>
                                <span>Add another line</span>
                              </button>
                              {/* <button
                      onClick={this.handleRemoveRow}
                      className="pull-right btn btn-default table-btn"
                    >
                      Clear all rows
                    </button> */}
                            </div>
                            <div className="customernotes">
                              <label>Customer Notes</label>
                              <AvField
                                name="salesOrderCustomerNotes"
                                type="textarea"
                                value={data.salesOrderCustomerNotes}
                                onChange={(e) => handle(e)}
                              ></AvField>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="table-right">
                              <Row>
                                <Col className="details">
                                  <div>
                                    <span className="detail-head">
                                      Sub Total
                                    </span>
                                  </div>
                                  <div>
                                    <span>{"₹" + props.subTotal}</span>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="d-flex align-items-center">
                                <Col md={4}>
                                  <div>
                                    <span className="detail-head">
                                      Shipping Charges
                                    </span>
                                  </div>
                                </Col>
                                <Col md={5}>
                                  <div className="input-tooltip">
                                    <AvField
                                      type="number"
                                      name="shippingCharge"
                                      onChange={(e) =>
                                        props.calculateShippingChargeOt(e)
                                      }
                                    ></AvField>
                                  </div>
                                </Col>
                                <Col md={3} className="text-right">
                                  <div>
                                    <span>
                                      {props.shippingCharge
                                        ? props.shippingCharge
                                        : 0}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={4}>
                                  <div>
                                    <span className="detail-head">
                                      Adjustment
                                    </span>
                                  </div>
                                </Col>
                                <Col md={5}>
                                  <div className="input-tooltip">
                                    <AvField
                                      type="number"
                                      name="adjustment"
                                      onChange={(e) =>
                                        props.calculateAdjustmentChargeOt(e)
                                      }
                                    ></AvField>
                                  </div>
                                </Col>
                                <Col md={3} className="text-right">
                                  <div>
                                    <span>
                                      {" "}
                                      {props.adjustment ? props.adjustment : 0}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              {/* <Row>
                      <Col md={4}>
                        <div>
                          <span className="detail-head">Tcs</span>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="input-tooltip">
                          <select name="selecttax" placeholder="select a tax">
                            <option value="%">Select a Tax</option>
                            <option value="rs">Rs.</option>
                          </select>
                          <i class="bx bx-help-circle"></i>
                        </div>
                      </Col>
                      <Col md={3} className="text-right">
                        <div>
                          <span>0.00</span>
                        </div>
                      </Col>
                    </Row> */}
                              <Row>
                                <Col className="details">
                                  <div>
                                    <span className="total">Total</span>
                                  </div>

                                  <div>
                                    <span className="total">
                                      {"₹" + props.total}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={6}>
                            <label>Terms & Conditions</label>
                            <AvField
                              name="salesOrderTermsAndConditions"
                              type="textarea"
                              value={data.salesOrderTermsAndConditions}
                              onChange={(e) => handle(e)}
                              placeholder="Enter your terms and conditons of your business to be displayed in your transactions"
                            ></AvField>
                          </Col>
                          {/* <Col md={2}>
                            <label>Attach file(s) to invoice</label>
                            <AvField
                              name="branch"
                              type="file"
                              value={data.branch}
                              onChange={(e) => handle(e)}
                            ></AvField>
                          </Col> */}
                        </Row>
                      </div>
                    </div>
                    <div className="bottom-bar">
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={4}>
                              <Link
                                to={
                                  formType == "add"
                                    ? "/salesorder"
                                    : formType == "edit"
                                    ? `/salesorderview/${id}`
                                    : ""
                                }
                              >
                                <button
                                  type="button"
                                  className="btn btn-left ml-3"
                                >
                                  Cancel
                                </button>
                              </Link>
                            </Col>
                            {formType == "add" ? (
                              <Col md={4}>
                                <button
                                  type="button"
                                  onClick={clearForm}
                                  className="btn btn-left"
                                >
                                  Clear
                                </button>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Col>
                        {/* <Col md={4} className="printView">
                          <span className="print">Print or Preview</span>
                        </Col> */}
                        <Col md={8}>
                          <button
                            type="submit"
                            className="btn btn-right float-right mr-3"
                          >
                            {reload ? (
                              <img
                                className="loader"
                                src={loader}
                                alt="loading..."
                              />
                            ) : formType == "add" ? (
                              "Save"
                            ) : formType == "edit" ? (
                              "Update"
                            ) : (
                              ""
                            )}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { selectedCustomerId, selectedCustomer } = state.Layout;
  const { selectedItemId, selectedItem } = state.ItemDetails;
  const { tableRowDataOt, subTotal, shippingCharge, adjustment, total } =
    state.TableDataOthers;

  return {
    selectedCustomerId,
    selectedCustomer,
    selectedItemId,
    selectedItem,
    tableRowDataOt,
    subTotal,
    shippingCharge,
    adjustment,
    total,
    // dataByIndex,
  };
};
export default connect(mapStatetoProps, {
  getCurrentSelectedCustomer,
  getCurrentSelectedItem,
  addTableDataOt,
  updateRowTotalOt,
  calculateShippingChargeOt,
  calculateAdjustmentChargeOt,
  deleteTableRowOt,
  populateTableDataOt,
  clearCustomerData,
  clearTableDataOt,
  updateRowSelectOt,
  // getTableData,
})(AddSalesOrder);
