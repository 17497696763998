import {
  ADD_COMMODITY_TABLE_DATA,
  UPDATE_COMMODITY_ROW_TOTAL,
  DELETE_TABLE_ROW,
  SET_CHECKOUT_TABLE_DATA,
  UPDATE_CHECKOUT_TABLE_DATA,
  CLEAR_COMMODITY_DATA,
  SET_WAREHOUSE_DATA,
  ADD_COMMODITY_TABLE_OTHER_CHARGES,
  UPDATE_COMMODITY_OTHER_CHARGES,
  DELETE_OTHER_CHARGE_TABLE_ROW,
  SET_COMMODITY_TABLE_DATA,
  SET_COMMODITY_TABLE_OTHER_CHARGES,
  POPULATE_TABLE_ROW_DATA,
  POPULATE_OTHER_CHARGE_TABLE_ROW_DATA,
  VALIDATE_TABLE_DATA,
  ADD_HANDLING_CHARGE,
  SET_CUSTOMER_CHECKOUT_TABLE_DATA,
  SET_INVALID_CHCEKIN_MESSAGE,
  SET_CHECKOUT_DATA_BY_ID,
} from "./actionTypes";

const INIT_STATE = {
  tableRowData: [],
  checkOutTableData: [],
  checkOutCheckInId: 0,
  checkOutDetails: {},
  warehouseData: {},
  totalItemArea: 0,
  totalItemAreaSqm: 0,
  otherChargeTableData: [],
  handlingCharge: {},
  validateValue: null,
  itemCheckoutCheck: false,
  isDuplicatePallet: false,
  isInvalidCheckInNumber: "",
};

const CommodityTableData = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_COMMODITY_TABLE_DATA:
      var tableData = {
        // itemCommodityId: 0,
        itemCommodityName: "",
        itemInvoiceNumber: "",
        itemHsnCode: "",
        itemUom: "",
        itemUnits: "",
        itemUnitsPerSinglePackage: "",
        itemPreviousGoodsReferenceNumber: "",
        itemTemperature: "",
        itemHandlingInfo: "",
        itemDateOfExpiry: "",
        itemBatch: "",
        itemLength: "",
        itemBreadth: "",
        itemHeight: "",
        itemDimensionUnits: "m",
        itemArea: 0,
        itemAreaSqm: 0,
        itemAreaUnits: "cbm",
        itemTotalWeight: "",
        itemWeightUom: "Tons",
        itemRemarks: "",
        itemCargoCondition: "",
        itemPallet: "",
      };

      return {
        ...state,
        tableRowData: [...state.tableRowData, tableData],
      };
      break;
    case UPDATE_COMMODITY_ROW_TOTAL:
      var oldData = [...state.tableRowData];

      oldData[action.payload.index][action.payload.value.target.name] =
        action.payload.value.target.value;

      var valueArr = oldData.map(function (item) {
        return item.itemPallet;
      });

      var isDuplicate = valueArr.some(function (item, idx) {
        if (item !== "") {
          return valueArr.indexOf(item) != idx;
        }
      });
      if (isDuplicate) {
        state.isDuplicatePallet = true;
      } else {
        state.isDuplicatePallet = false;
      }

      if (
        parseInt(oldData[action.payload.index]["itemLength"]) > 0 &&
        parseInt(oldData[action.payload.index]["itemBreadth"]) > 0 &&
        parseInt(oldData[action.payload.index]["itemHeight"]) > 0
      ) {
        oldData[action.payload.index]["itemArea"] =
          (oldData[action.payload.index]["itemUnits"] *
            oldData[action.payload.index]["itemLength"] *
            oldData[action.payload.index]["itemBreadth"] *
            oldData[action.payload.index]["itemHeight"]) /
          1000000;
        oldData[action.payload.index]["itemArea"] =
          oldData[action.payload.index]["itemArea"].toFixed(3);
      }
      if (
        parseInt(oldData[action.payload.index]["itemLength"]) > 0 &&
        parseInt(oldData[action.payload.index]["itemBreadth"]) > 0
      ) {
        oldData[action.payload.index]["itemAreaSqm"] =
          (oldData[action.payload.index]["itemUnits"] *
            oldData[action.payload.index]["itemLength"] *
            oldData[action.payload.index]["itemBreadth"]) /
          10000;
        oldData[action.payload.index]["itemAreaSqm"] =
          oldData[action.payload.index]["itemAreaSqm"].toFixed(3);
      }

      // if (oldData[action.payload.index]["itemHeight"]) {
      //   oldData[action.payload.index]["itemArea"] = parseInt(
      //     oldData[action.payload.index]["itemArea"]
      //   ).toFixed(3);
      // }
      // if (
      //   oldData[action.payload.index]["itemLength"] &&
      //   oldData[action.payload.index]["itemBreadth"]
      // ) {
      //   oldData[action.payload.index]["itemAreaSqm"] = parseInt(
      //     oldData[action.payload.index]["itemAreaSqm"]
      //   ).toFixed(3);
      // }

      state.totalItemArea = oldData
        .map((item) => parseFloat(item.itemArea))
        .reduce((prev, curr) => prev + curr, 0);
      state.totalItemAreaSqm = oldData
        .map((item) => parseFloat(item.itemAreaSqm))
        .reduce((prev, curr) => prev + curr, 0);
      return {
        ...state,
        tableRowData: oldData,
      };
      break;
    case DELETE_TABLE_ROW:
      var oldData = [...state.tableRowData];
      oldData.splice(action.payload, 1);

      return {
        ...state,
        tableRowData: oldData,
      };
      break;
    case DELETE_OTHER_CHARGE_TABLE_ROW:
      var oldData = [...state.otherChargeTableData];
      oldData.splice(action.payload, 1);

      return {
        ...state,
        otherChargeTableData: oldData,
      };
      break;
    case SET_CHECKOUT_TABLE_DATA:
      if (action.payload.checkInStatus !== 1) {
        var oldData = action.payload.itemsList.map((item, index) => {
          // console.log("checkout table data", item);

          var table;
          return (table = {
            itemCargoCheckInItemId: parseInt(item.itemId),
            // itemCommodityId: parseInt(item.itemCommodityId),
            itemCommodityName: item.itemCommodityName,
            itemHsnCode: item.itemHsnCode,
            itemUom: item.itemUom,
            itemUnitsTotal: parseInt(item.itemUnits),
            itemUnitsAvailable: item.itemUnitsAvailable,
            itemUnitsCheckedOut: 0,
            itemBatch: item.itemBatch,
            itemLength: 0,
            itemBreadth: 0,
            itemHeight: 0,
            itemLengthAvailable: item.itemLength,
            itemBreadthAvailable: item.itemBreadth,
            itemHeightAvailable: item.itemHeight,
            itemDimensionUnits: item.itemDimensionUnits
              ? item.itemDimensionUnits
              : "",
            itemTotalWeight: item.itemTotalWeight,
            itemWeightUom: item.itemWeightUom ? item.itemWeightUom : "",
            itemIsSelected: false,
            itemArea: 0,
            itemAreaSqm: 0,
            itemAreaOccupied: item.itemAreaOccupied,
            itemSqmOccupied: item.itemSqmOccupied,
            itemRemarks: "",
            itemCargoCondition: "",
            itemPallet: item.itemPallet,
            itemPreviousGoodsReferenceNumber:
              item.itemPreviousGoodsReferenceNumber,
            itemUnitsPerSinglePackage: item.itemUnitsPerSinglePackage,
            itemTotalInAllPackageAvailable: item.itemTotalInAllPackageAvailable,
            itemPackageWiseCheckedOut: 0,
          });
        });
      } else {
        oldData = [];
      }

      return {
        ...state,
        checkOutTableData: oldData,
        checkOutCheckInId: action.payload.checkInId,
        checkOutDetails: action.payload,
      };
      break;
    case SET_CUSTOMER_CHECKOUT_TABLE_DATA:
      var oldData = action.payload.item?.map((item, index) => {
        var table;
        return (table = {
          itemCargoCheckInItemId: parseInt(item.itemId),
          // itemCommodityId: parseInt(item.itemCommodityId),
          itemCommodityName: item.itemCommodityName,
          itemHsnCode: item.itemHsnCode,
          itemUom: item.itemUom,
          itemUnitsTotal: parseInt(item.itemUnits),
          itemUnitsAvailable: item.itemUnitsAvailable,
          itemUnitsCheckedOut: 0,
          itemUnits: 0,
          itemBatch: item.itemBatch,
          itemLength: 0,
          itemBreadth: 0,
          itemHeight: 0,
          itemDimensionUnits: item.itemDimensionUnits
            ? item.itemDimensionUnits
            : "",
          itemTotalWeight: item.itemTotalWeight,
          itemWeightUom: item.itemWeightUom ? item.itemWeightUom : "",
          itemIsSelected: false,
          itemArea: 0,
          itemAreaSqm: 0,
          itemAreaOccupied: item.itemAreaOccupied,
          itemSqmOccupied: item.itemSqmOccupied,
          itemRemarks: "",
          itemCargoCondition: "",
          itemPallet: item.itemPallet,
          itemPreviousGoodsReferenceNumber: item.itemReferenceNumber,
          itemPreviousCargoCheckInItemId: item.itemId,
          itemPreviousInvoiceNumber: item.itemInvoiceNumber,
        });
      });
      return {
        ...state,
        checkOutTableData: oldData,
        checkOutCheckInId: action.payload.checkInId,
        checkOutDetails: action.payload,
      };
      break;
    case UPDATE_CHECKOUT_TABLE_DATA:
      var oldData = [...state.checkOutTableData];
      if (action.payload.value.name == "itemIsSelected") {
        oldData[action.payload.index]["itemIsSelected"] =
          !oldData[action.payload.index]["itemIsSelected"];
      } else {
        oldData[action.payload.index][action.payload.value.name] =
          action.payload.value.value;
        // oldData[action.payload.index]["itemArea"] =
        //   oldData[action.payload.index]["itemLength"] *
        //   oldData[action.payload.index]["itemBreadth"] *
        //   oldData[action.payload.index]["itemHeight"];
        if (
          oldData[action.payload.index]["itemPackageWiseCheckedOut"] >
          oldData[action.payload.index]["itemTotalInAllPackageAvailable"]
        ) {
          oldData[action.payload.index]["itemPackageWiseCheckedOut"] =
            oldData[action.payload.index]["itemTotalInAllPackageAvailable"];
        }
        if (
          oldData[action.payload.index]["itemLength"] >
          oldData[action.payload.index]["itemLengthAvailable"]
        ) {
          oldData[action.payload.index]["itemLength"] =
            oldData[action.payload.index]["itemLengthAvailable"];
        }
        if (
          oldData[action.payload.index]["itemBreadth"] >
          oldData[action.payload.index]["itemBreadthAvailable"]
        ) {
          oldData[action.payload.index]["itemBreadth"] =
            oldData[action.payload.index]["itemBreadthAvailable"];
        }
        if (
          oldData[action.payload.index]["itemHeight"] >
          oldData[action.payload.index]["itemHeightAvailable"]
        ) {
          oldData[action.payload.index]["itemHeight"] =
            oldData[action.payload.index]["itemHeightAvailable"];
        }
        if (
          oldData[action.payload.index]["itemUnits"] >
          oldData[action.payload.index]["itemUnitsAvailable"]
        ) {
          oldData[action.payload.index]["itemUnits"] =
            oldData[action.payload.index]["itemUnitsAvailable"];
        }
        if (
          oldData[action.payload.index]["itemUnitsCheckedOut"] >
          oldData[action.payload.index]["itemUnitsAvailable"]
        ) {
          oldData[action.payload.index]["itemUnitsCheckedOut"] =
            oldData[action.payload.index]["itemUnitsAvailable"];
        }
        if (
          oldData[action.payload.index]["itemArea"] >
          oldData[action.payload.index]["itemAreaOccupied"]
        ) {
          oldData[action.payload.index]["itemArea"] =
            oldData[action.payload.index]["itemAreaOccupied"];
        }
        if (
          oldData[action.payload.index]["itemAreaSqm"] >
          oldData[action.payload.index]["itemSqmOccupied"]
        ) {
          oldData[action.payload.index]["itemAreaSqm"] =
            oldData[action.payload.index]["itemSqmOccupied"];
        }
        if (
          parseInt(oldData[action.payload.index]["itemLength"]) > 0 &&
          parseInt(oldData[action.payload.index]["itemBreadth"]) > 0 &&
          parseInt(oldData[action.payload.index]["itemHeight"]) > 0
        ) {
          oldData[action.payload.index]["itemArea"] =
            (oldData[action.payload.index]["itemUnitsCheckedOut"] *
              oldData[action.payload.index]["itemLength"] *
              oldData[action.payload.index]["itemBreadth"] *
              oldData[action.payload.index]["itemHeight"]) /
            1000000;
          oldData[action.payload.index]["itemArea"] =
            oldData[action.payload.index]["itemArea"].toFixed(3);
        }
        if (
          parseInt(oldData[action.payload.index]["itemLength"]) > 0 &&
          parseInt(oldData[action.payload.index]["itemBreadth"]) > 0
        ) {
          oldData[action.payload.index]["itemAreaSqm"] =
            (oldData[action.payload.index]["itemUnitsCheckedOut"] *
              oldData[action.payload.index]["itemLength"] *
              oldData[action.payload.index]["itemBreadth"]) /
            10000;
          oldData[action.payload.index]["itemAreaSqm"] =
            oldData[action.payload.index]["itemAreaSqm"].toFixed(3);
        }
      }

      return {
        ...state,
        checkOutTableData: oldData,
      };
      break;
    case CLEAR_COMMODITY_DATA:
      return {
        ...state,
        tableRowData: [],
        checkOutTableData: [],
        checkOutCheckInId: 0,
        checkOutDetails: {},
        warehouseData: {},
        totalItemArea: 0,
        totalItemAreaSqm: 0,
        otherChargeTableData: [],
        validateValue: null,
      };
      break;
    case SET_WAREHOUSE_DATA:
      return {
        ...state,
        warehouseData: action.payload,
      };
      break;
    case ADD_HANDLING_CHARGE:
      var oldData = [...state.otherChargeTableData];
      if (action.payload.type === "checkin") {
        var objIndex = oldData.findIndex(
          (obj) => obj.chargeName == "Handling In"
        );
        var chargeData = {
          chargeName: "Handling In",
          chargeAmount: action.payload.handlingData,
          chargeAmountCostSheet: "0",
        };
        if (objIndex == -1) {
          state.otherChargeTableData = [
            ...state.otherChargeTableData,
            chargeData,
          ];
        } else {
          state.otherChargeTableData[objIndex].chargeAmount =
            action.payload.handlingData;
        }
      }
      if (action.payload.type === "checkout") {
        var objIndex = oldData.findIndex(
          (obj) => obj.chargeName == "Handling Out"
        );
        var chargeData = {
          chargeName: "Handling Out",
          chargeAmount: action.payload.handlingData,
          chargeAmountCostSheet: 0,
        };
        if (objIndex == -1) {
          state.otherChargeTableData = [
            ...state.otherChargeTableData,
            chargeData,
          ];
        } else {
          state.otherChargeTableData[objIndex].chargeAmount =
            action.payload.handlingData;
        }
      }
      return {
        ...state,
      };
      break;
    case SET_COMMODITY_TABLE_OTHER_CHARGES:
      var tableData = {
        chargeName: "",
        chargeAmount: "",
        chargeAmountCostSheet: "",
      };
      return {
        ...state,
        otherChargeTableData: [...state.otherChargeTableData, tableData],
      };
      break;
    case UPDATE_COMMODITY_OTHER_CHARGES:
      var oldData = [...state.otherChargeTableData];
      oldData[action.payload.index][action.payload.value.target.name] =
        action.payload.value.target.value;

      // state.totalItemArea = oldData
      //   .map((item) => parseInt(item.itemArea))
      //   .reduce((prev, curr) => prev + curr, 0);
      return {
        ...state,
        otherChargeTableData: oldData,
      };
      break;
    case POPULATE_TABLE_ROW_DATA:
      state.totalItemArea = action.payload
        .map((item) =>
          item.isAllItemCheckedOut === 0 ? parseFloat(item.itemArea) : 0
        )
        .reduce((prev, curr) => prev + curr, 0);
      state.totalItemAreaSqm = action.payload
        .map((item) =>
          item.isAllItemCheckedOut === 0 ? parseFloat(item.itemAreaSqm) : 0
        )
        .reduce((prev, curr) => prev + curr, 0);
      return {
        ...state,
        tableRowData: action.payload,
      };
      break;
    case POPULATE_OTHER_CHARGE_TABLE_ROW_DATA:
      // state.totalItemArea = action.payload
      //   .map((item) => parseInt(item.itemArea))
      //   .reduce((prev, curr) => prev + curr, 0);
      return {
        ...state,
        otherChargeTableData: action.payload,
      };
      break;
    case VALIDATE_TABLE_DATA:
      var oldData = [...state.tableRowData];
      var value;
      if (oldData.length > 0) {
        oldData.map((item) => {
          if (item["itemAreaSqm"] == 0) {
            value = "Enter SQM";
          }
          if (item["itemArea"] == 0) {
            value = "Enter CBM";
          }
          if (item["itemPallet"] == "") {
            value = "Enter pallet number";
          }
          if (item["itemBatch"] == "") {
            value = "Enter item batch";
          }
          if (item["itemHandlingInfo"] == "") {
            value = "Enter item handling info";
          }
          if (item["itemTemperature"] == "") {
            value = "Enter warehouse facility type";
          }
          if (item["itemUnits"] == "") {
            value = "Enter item units";
          }
          if (item["itemUom"] == "") {
            value = "Enter UOM";
          }
          if (item["itemInvoiceNumber"] == "") {
            value = "Enter Invoice Number";
          }
          if (item["itemCommodityName"] == "") {
            value = "Enter Commodity Name";
          }
        });
      }
      return {
        ...state,
        validateValue: value,
      };
      break;
    case SET_INVALID_CHCEKIN_MESSAGE:
      return {
        ...state,
        isInvalidCheckInNumber: action.payload,
      };
    case SET_CHECKOUT_DATA_BY_ID:
      return {
        ...state,
        checkOutDetails: action.payload,
        checkOutTableData: action.payload?.itemsList,
        otherChargeTableData: action.payload?.chargeList,
      };
    default:
      return state;
      break;
  }
};
export default CommodityTableData;
