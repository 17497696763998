import {
  ADD_TABLE_DATA,
  UPDATE_ROW_TOTAL,
  DELETE_TABLE_ROW,
  CALCULATE_SHIPPING_CHARGE,
  CALCULATE_ADJUSTMENT_CHARGE,
  CALCULATE_TOTALS,
  POPULATE_TABLE_DATA,
  CLEAR_DATA,
  UPDATE_ITEM_SELECT,
} from "./actionTypes";

export const addTableData = (itemData) => ({
  type: ADD_TABLE_DATA,
  payload: itemData,
});

export const updateRowTotal = (index, value) => ({
  type: UPDATE_ROW_TOTAL,
  payload: { index, value },
});
export const updateItemSelect = (index, value) => ({
  type: UPDATE_ITEM_SELECT,
  payload: { index, value },
});

export const calculateShippingCharge = (value) => ({
  type: CALCULATE_SHIPPING_CHARGE,
  payload: value,
});
export const calculateAdjustmentCharge = (value) => ({
  type: CALCULATE_ADJUSTMENT_CHARGE,
  payload: value,
});
//export const deleteTableRow = (index) => ({
//type: DELETE_TABLE_ROW,
//payload: index,
//});
export const calculateTotals = () => ({
  type: CALCULATE_TOTALS,
});
export const populateTableData = (
  subtotal,
  shippingcharge,
  adjustment,
  total,
  itemList
) => ({
  type: POPULATE_TABLE_DATA,
  payload: { subtotal, shippingcharge, adjustment, total, itemList },
});
export const clearTableData = () => ({
  type: CLEAR_DATA,
});
