import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";
import { connect } from "react-redux";
import { useMemo } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import { apiPost } from "../../components/config/apiConfig";
import {
  updateCheckOutData,
  clearCommodityData,
  setCustomerCheckOutTableData,
} from "../../store/actions";
import moment from "moment";
import { useHistory } from "react-router-dom";
function CustomerItemCheckOut(props) {
  const history = useHistory();
  var id = props.match.params.id;
  const [reload, setReload] = useState(false);
  const [checkindata, setcheckindata] = useState({});
  const [checkOutMandatory, setcheckOutMandatory] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  function getCheckInData() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/cargo_checkin/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setcheckindata(resp.response.data.data);
      props.setCustomerCheckOutTableData(resp.response.data.data.itemsList);
    });
  }
  useEffect(() => {
    getCheckInData();
  }, [id]);

  const columns = useMemo(() => [
    {
      input: true,
      cell: (row, rowIndex) => (
        <input
          type="checkbox"
          name="itemIsSelected"
          defaultChecked={row.itemIsSelected}
          onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
        />
      ),
      width: "30px",
    },
    {
      name: "Commodity Name",
      selector: (row) => row.itemCommodityName,
      sortable: false,
    },
    {
      name: "HSN Code",
      selector: (row) => row.itemHsnCode,
      sortable: false,
    },

    {
      name: "UOM",
      selector: (row) => row.itemUom,
      sortable: false,
    },
    {
      name: "Stock",
      selector: (row) => row.itemUnitsTotal,
      sortable: false,
    },
    {
      name: "Available Stock",
      selector: (row) => row.itemUnitsAvailable,
      sortable: false,
    },
    {
      name: "Pallet No",
      selector: (row) => row.itemPallet,
      sortable: false,
    },
    {
      name: "Available CBM",
      selector: (row) => row.itemAreaOccupied,
      sortable: false,
    },
    {
      name: "Available SQM",
      selector: (row) => row.itemSqmOccupied,
      sortable: false,
    },
    {
      name: "Check Out units",
      cell: (row, rowIndex) => (
        <input
          type="number"
          name="itemUnits"
          value={row.itemUnits == 0 ? "" : row.itemUnits}
          onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
          disabled={!row.itemIsSelected ? true : false}
          placeholder={row.itemIsSelected ? "Enter checkout units" : ""}
          className={
            row.itemIsSelected && !checkOutMandatory
              ? "checkout-input"
              : row.itemIsSelected && checkOutMandatory
              ? "required"
              : "noborder"
          }
          style={{
            // border: "none",
            // borderBottom: row.itemIsSelected
            //   ? "1px solid #052f85"
            //   : row.itemIsSelected && checkOutMandatory
            //   ? "1px solid red !important"
            //   : "none",
            width: "130px",
          }}
        />
      ),
      width: "140px",
    },
    {
      name: "L",
      cell: (row, rowIndex) => (
        <input
          type="number"
          name="itemLength"
          onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
          disabled={!row.itemIsSelected ? true : false}
          style={{
            border: "none",
            borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
            width: "50px",
          }}
        />
      ),
      width: "60px",
    },
    {
      name: "W",
      cell: (row, rowIndex) => (
        <input
          type="number"
          name="itemBreadth"
          onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
          disabled={!row.itemIsSelected ? true : false}
          style={{
            border: "none",
            borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
            width: "50px",
          }}
        />
      ),
      width: "60px",
    },
    {
      name: "H",
      cell: (row, rowIndex) => (
        <input
          type="number"
          name="itemHeight"
          onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
          disabled={!row.itemIsSelected ? true : false}
          style={{
            border: "none",
            borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
            width: "50px",
          }}
        />
      ),
      width: "60px",
    },
    {
      name: "CBM",
      // selector: (row) => (row.itemArea ? row.itemArea : 0),
      // sortable: false,
      cell: (row, rowIndex) => (
        <input
          type="number"
          name="itemArea"
          value={row.itemArea == 0 ? "" : row.itemArea}
          onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
          disabled={!row.itemIsSelected ? true : false}
          placeholder={row.itemIsSelected ? "Enter CBM" : ""}
          style={{
            // border: "none",
            // borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
            width: "70px",
          }}
          className={
            row.itemIsSelected && !checkOutMandatory
              ? "checkout-input"
              : row.itemIsSelected && checkOutMandatory
              ? "required"
              : "noborder"
          }
        />
      ),
      width: "80px",
      // center: true,
    },
    {
      name: "SQM",
      // selector: (row) => (row.itemArea ? row.itemArea : 0),
      // sortable: false,
      cell: (row, rowIndex) => (
        <input
          type="number"
          name="itemAreaSqm"
          value={row.itemAreaSqm == 0 ? "" : row.itemAreaSqm}
          onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
          disabled={!row.itemIsSelected ? true : false}
          placeholder={row.itemIsSelected ? "Enter SQM" : ""}
          style={{
            // border: "none",
            // borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
            width: "70px",
          }}
          className={
            row.itemIsSelected && !checkOutMandatory
              ? "checkout-input"
              : row.itemIsSelected && checkOutMandatory
              ? "required"
              : "noborder"
          }
        />
      ),
      width: "80px",
      // center: true,
    },

    {
      name: "Remarks",
      cell: (row, rowIndex) => (
        <input
          type="text"
          name="itemRemarks"
          onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
          disabled={!row.itemIsSelected ? true : false}
          placeholder={row.itemIsSelected ? "Remarks" : ""}
          style={{
            border: "none",
            borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
            width: "130px",
          }}
        />
      ),
      width: "140px",
    },
    {
      name: "Cargo Condition",
      cell: (row, rowIndex) => (
        <input
          type="text"
          name="itemCargoCondition"
          onChange={(e) => props.updateCheckOutData(e.target, rowIndex)}
          disabled={!row.itemIsSelected ? true : false}
          placeholder={row.itemIsSelected ? "Conditions" : ""}
          style={{
            border: "none",
            borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
            width: "130px",
          }}
        />
      ),
      width: "140px",
    },
  ]);
  const handleCheckOut = (e) => {
    var flag = true;
    props.checkOutTableData.map((items) => {
      if (
        items["itemIsSelected"] &&
        (items["itemUnits"] === 0 ||
          items["itemUnits"] === "" ||
          items["itemAreaSqm"] === 0 ||
          items["itemAreaSqm"] === "" ||
          items["itemArea"] === 0 ||
          items["itemArea"] === "")
      ) {
        setcheckOutMandatory(true);
        flag = false;
      }
    });

    if (flag) {
      if (props.checkOutTableData.some((items) => items["itemIsSelected"])) {
        setcheckOutMandatory(false);
        itemCheckOut();
      }
    }

    if (props.checkOutTableData.some((items) => items["itemIsSelected"])) {
      setCheckbox(false);
    } else {
      setCheckbox(true);
      setcheckOutMandatory(false);
    }
  };
  function itemCheckOut() {
    setReload(true);
    let orgId = localStorage.getItem("organisationId");
    const url = BaseUrl.apiUrl.baseUrl + "api/v1/online_order";
    var body = {
      orderDate: date,
      orderTime: "",
      orderType: 2,
      orderCustomerId: checkindata.checkInCustomerId,
      orderCustomerEmail: checkindata.checkInCustomerEmail,
      orderCustomerNumber: "",
      orderCustomerPhone: checkindata.checkInCustomerPhone,
      orderCustomerName: checkindata.checkInCustomerName,
      orderCustomerAddress: checkindata.checkInCustomerAddress,
      isAgreeTermsAndConditions: 1,
      organizationId: orgId,
      loadingFrom: "",
      deliveryTo: "",
      vehicleNo: "",
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
      itemsList: props.checkOutTableData,
      orderPreviousCheckInId: checkindata.checkInId,
      orderPreviousCheckInNumber: checkindata.checkInNumber,
    };
    let resp = apiPost(url, body);
    resp.then((resp) => {
      setReload(false);
      switch (resp.response.data.code) {
        case 200:
          // handlePrint();
          toast.success(
            `${resp.response.data.message} ${resp.response.data.status}`
          );
          setTimeout(() => {
            history.push("/customer-checkin-list");
          }, 1000);
          break;
        default:
          toast.error(resp.response.data.data);
      }
    });
  }
  useEffect(() => {
    if (checkOutMandatory) {
      props.checkOutTableData.map((items) => {
        if (
          items["itemIsSelected"] &&
          items["itemUnits"] !== 0 &&
          items["itemAreaSqm"] !== 0 &&
          items["itemArea"] !== 0
        ) {
          setcheckOutMandatory(false);
        } else {
          setcheckOutMandatory(true);
        }
      });
    }
  }, [props.checkOutTableData]);
  useEffect(() => {
    return () => {
      props.clearCommodityData();
    };
  }, []);
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid className="singlepage">
          <Row>
            <Col md={12} className="checkout-head">
              Cargo Details - GRN NUMBER {checkindata?.checkInNumber}
              {checkbox ? (
                <span style={{ marginLeft: "1em", color: "red" }}>
                  Select an item for checkout
                </span>
              ) : (
                ""
              )}
              {checkOutMandatory ? (
                <span style={{ marginLeft: "1em", color: "red" }}>
                  Please fill all the required details of selected item
                </span>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={4} className="mb-3">
                      <label style={{ color: "black" }}>Date</label>
                      <input
                        type="date"
                        placeholder="Select date"
                        className="form-control"
                        max={moment().format("YYYY-MM-DD")}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <DataTable
                        columns={columns}
                        data={props.checkOutTableData}
                        defaultSortFieldID={1}
                        pagination
                        // selectableRows
                        // selectableRowsComponent={BootyCheckbox}
                        // onSelectedRowsChange={handleChange}
                        // selectableRowsHighlight
                        responsive
                      />
                    </Col>
                    <Col md={12}>
                      <button
                        class="btn btn-primary"
                        type="button"
                        onClick={handleCheckOut}
                        disabled={reload ? true : false}
                        // disabled={props.itemCheckoutCheck}
                      >
                        Confirm Checkout
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  const {
    checkOutTableData,
    checkOutCheckInId,
    checkOutDetails,
    otherChargeTableData,
    itemCheckoutCheck,
  } = state.CommodityTableData;
  return {
    checkOutTableData,
    checkOutCheckInId,
    checkOutDetails,
    otherChargeTableData,
    itemCheckoutCheck,
  };
};
export default connect(mapStatetoProps, {
  updateCheckOutData,
  clearCommodityData,
  setCustomerCheckOutTableData,
})(CustomerItemCheckOut);
