import React, { Component } from "react";
import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginSuccess, logoutUserSuccess, apiError } from "./actions";
import { getInitialData } from "../../dataInitialise/actions";

import { BaseUrl } from "../../../components/config/BaseUrl";
import { apiGet, apiPost } from "../../../components/config/apiConfig";
import Axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* loginUser({ payload: { user, history } }) {
  try {
    const Url = BaseUrl.apiUrl.baseUrl + "api/public/v1/signin";
    const data = {
      username: user.username,
      password: user.password,
    };
    yield fetch(Url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code == 401) {
          throw new Error("Bad creds");
        }
        if (data.status == "Success" && data.code == 200) {
          // if (user.lsRememberMe) {
          //   localStorage.setItem("username", user.username);
          //   localStorage.setItem("password", user.password);
          //   localStorage.setItem("checkbox", user.lsRememberMe);
          // }
          if (user.lsRememberMe === true && user.username !== "") {
            localStorage.username = user.username;
            localStorage.password = user.password;
            localStorage.checkbox = user.lsRememberMe;
          }
          localStorage.setItem("authUser", JSON.stringify(data));
          localStorage.setItem("accessToken", data.data.accessToken);
          localStorage.setItem("refreshToken", data.data.refreshToken);

          var t = new Date();
          t.setSeconds(t.getSeconds() + data.data.expiresIn);
          localStorage.setItem("expireTime", t);

          let token = localStorage.getItem("accessToken");
          const Url1 = BaseUrl.apiUrl.baseUrl + "api/v1/user_info";
          const userResp = Axios.get(Url1, {
            headers: {
              authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });

          userResp.then((response1) => {
            if (
              response1.data.code == 200 &&
              response1.data.status == "Success"
            ) {
              localStorage.setItem(
                "warehouseId",
                response1.data.data.warehouseId
              );
              localStorage.setItem(
                "organisationId",
                response1.data.data.organizationId
              );
              localStorage.setItem("Id", response1.data.data.id);
              localStorage.setItem(
                "customerId",
                response1.data.data.customerId
              );
              localStorage.setItem("userName", response1.data.data.username);
              localStorage.setItem("userRole", response1.data.data.role);
            }
            history.push("/dashboard");
          });
        } else {
          toast.info("Incorrect Username or Password");
        }
      });
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    // localStorage.clear();
    localStorage.removeItem("authUser");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expireTime");
    localStorage.removeItem("organisationId");
    localStorage.removeItem("Id");
    localStorage.removeItem("userName");
    localStorage.removeItem("userRole");
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
