import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import classnames from "classnames";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

// Pages Component

import AddAdjustmentForm from "./addAdjustmentForm";
import BottomBar from "./bottomBar";
import UpdatePriceList from "./updatePriceList";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

const AddNewAdjustment = (props) => {
  const [modal, setmodal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Add New Adjustment")}
            breadcrumbItem={props.t("Items")}
          />

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AvForm className="form">
                    <Row>
                      <Col md={12} className=" pricelist">
                        <AddAdjustmentForm />
                      </Col>
                      {/* <Col md={4}>sa</Col> */}
                    </Row>
                    {/* <BottomBar /> */}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(AddNewAdjustment);
