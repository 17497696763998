import React from "react";
import { Container, Row, Col, Modal } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
function EditZone(props) {
  return (
    <Modal className="export-modal" isOpen={props.openModal}>
      <Row>
        <Col md={12} className="modal-head">
          <div>
            <h5>Edit Zone</h5>
          </div>
          <div onClick={() => props.setOpenModel(false)}>
            <i class="bx bx-x"></i>
          </div>
        </Col>
      </Row>
      <hr />
      <AvForm className="form" onValidSubmit={() => props.handleEdit(props.id)}>
        <Row className="d-flex align-items-center mb-3">
          <Col md={6}>
            <label style={{ whiteSpace: "pre" }}>Zone</label>
          </Col>
          <Col md={6}>
            <AvField
              name="zone"
              type="text"
              placeholder="Enter Zone"
              onChange={(e) => props.handle(e)}
              value={props.editValues.zone}
              required
            ></AvField>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <button
              type="submit"
              class="exportuserbtn"
              disabled={props.isSaving}
            >
              Update Zone
            </button>
          </Col>
        </Row>
      </AvForm>
    </Modal>
  );
}

export default EditZone;
