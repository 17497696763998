import React, { useState } from "react";

import { connect } from "react-redux";
import { Row, Col, Button } from "reactstrap";

import { Link } from "react-router-dom";

// Reactstrap
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import megamenuImg from "../../assets/images/megamenu-img.png";
import logo from "../../assets/images/logo.svg";
import logoLightPng from "../../assets/images/logo-light.png";
import opineIcon from "../../assets/images/icon-opine.png";
import awcLogo from "../../assets/images/awclogo4.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";
import dyarco2 from "../../assets/dyarco/dyarco2.png";
//i18n
import { withTranslation } from "react-i18next";
import { apiGet } from "../config/apiConfig";
import { BaseUrl } from "../config/BaseUrl";
import { useHistory } from "react-router-dom";
// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

const Header = (props) => {
  const history = useHistory();
  const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  }
  function handleSearch(e) {
    if (e.target.value !== "") {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl +
        `api/v1/customers/org?org_id=${organisationId}&page=1&size=10&sort=customerId,desc&sort=customerFirstname,asc&searchText=${e.target.value}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setSearchData(resp.response.data.data.content);
      });
    } else {
      setSearchData([]);
    }
  }
  const handleCustomerSearch = (customer) => {
    history.push(`/singlecustomer/${customer.customerId}`);
    setSearchData([]);
  };
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={awcLogo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={awcLogo} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={awcLogo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={awcLogo} alt="" height="30" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              class="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
              onClick={() => tToggle()}
            >
              <i class="fa fa-fw fa-bars"></i>
            </button>
            {localStorage.getItem("userRole") !== "CUSTOMER" ? (
              <form className="app-search d-none d-lg-block">
                <div className="position-relative searchContainer">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={props.t("Search") + "..."}
                    onChange={(e) => handleSearch(e)}
                  />
                  <span className="bx bx-search-alt"></span>
                  {searchData.length > 0 ? (
                    <div className="autocomplete-component">
                      {searchData.map((items) => {
                        return (
                          <div
                            className="autocomplete-items"
                            onClick={() => handleCustomerSearch(items)}
                          >
                            <span className="autocomplete-items-text">
                              {items.customerDisplayName}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ml-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify"></i>
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {localStorage.getItem("userRole") !== "CUSTOMER" ? (
              <Dropdown
                className="d-inline-block"
                isOpen={socialDrp}
                toggle={() => {
                  setsocialDrp(!socialDrp);
                }}
              >
                <DropdownToggle
                  className="btn header-item waves-effect"
                  tag="button"
                >
                  <Button className="primary-btn">
                    <i class="bx bx-plus"></i> Create new
                  </Button>
                </DropdownToggle>
                <DropdownMenu right>
                  {/* <DropdownItem tag="a" href="#">
                  {" "}
                  <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                  {props.t("New User")}{" "}
                </DropdownItem> */}

                  <DropdownItem>
                    <Link to="/customer/add" className="text-reset">
                      {" "}
                      <i className="bx bx-group font-size-16 align-middle mr-1"></i>
                      {props.t("New Customer")}{" "}
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/invoice/add" className="text-reset">
                      <i class="bx bxs-spreadsheet font-size-16 align-middle mr-1"></i>
                      {props.t("New Invoice")}{" "}
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/addcargocheckin/add" className="text-reset">
                      {" "}
                      <i className="bx bx-receipt font-size-16 align-middle mr-1"></i>
                      {props.t("New Check-In")}{" "}
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/addcheckout/add" className="text-reset">
                      {" "}
                      <i className="bx bx-receipt font-size-16 align-middle mr-1"></i>
                      {props.t("New Check-Out")}{" "}
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/addestimate" className="text-reset">
                      {" "}
                      <i className="bx bx-receipt font-size-16 align-middle mr-1"></i>
                      {props.t("New Estimate")}{" "}
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : (
              ""
            )}
            {/* <div
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i className="bx bx-cog "></i>
              </button>
            </div> */}

            {localStorage.getItem("userRole") !== "CUSTOMER" ? (
              <NotificationDropdown />
            ) : (
              ""
            )}
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
