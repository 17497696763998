import React from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { useCallback } from "react";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";
import { useRef } from "react";
import FileSaver from "file-saver";
import * as moment from "moment";
function CheckOutReport() {
  const gridRef = useRef();
  const [warehouseList, setwarehouseList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [typeRequired, setTypeRequired] = useState(false);
  const current = new Date();
  const date = `${current.getFullYear()}-${
    current.getMonth() < 10
      ? `0${current.getMonth() + 1}`
      : current.getMonth() + 1
  }-${current.getDate() < 10 ? `0${current.getDate()}` : current.getDate()}`;
  const [searchenable, setsearchenable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchdata, setsearchdata] = useState({
    fromDate: date,
    toDate: date,
    warehouseId: 0,
    customerId: 0,
    checkOutNumber: 0,
  });
  const [WarehouseId, setWarehouseId] = useState({
    id: 0,
    name: "",
  });
  const [customerId, setCustomerId] = useState({
    id: 0,
    name: "",
  });
  const [billingType, setBillingType] = useState({
    id: 0,
    name: "",
  });
  const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);
  const columnDefs = useMemo(
    () => [
      {
        field: "checkOutDate",
        headerName: "Date",
        width: 150,
      },
      {
        field: "checkOutTime",
        headerName: "Time",
        valueFormatter: function (params) {
          return params.value === null
            ? "Invalid time"
            : moment(params.value, "HH:mm").format("hh:mm A");
        },
        width: 130,
      },
      { field: "checkOutNumber", headerName: "Checkout Number" },
      { field: "checkOutType", headerName: "Checkout Type" },
      { field: "checkOutWarehouseName", headerName: "Warehouse Name" },
      { field: "checkOutCustomerName", headerName: "Customer Name" },
      { field: "checkOutCheckInNumber", headerName: "Check In No." },
      { field: "checkOutCbm", headerName: "CBM" },
      { field: "checkOutSqm", headerName: "SQM" },
    ],
    []
  );
  const [rowData, setRowData] = useState();
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    []
  );

  const onGridReady = useCallback((params) => {
    const updateData = (data) => params.api.setRowData(data);
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/checkouts_1/${searchdata.fromDate}/${searchdata.toDate}/0/0/0`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      updateData(resp.response.data.data);
    });
  }, []);

  function handleChange(e) {
    const newData = { ...searchdata };
    newData[e.target.name] = e.target.value;

    setsearchdata(newData);
  }
  function handleSearch(e) {
    setIsLoading(true);
    let checkOutNumb = searchdata.checkOutNumber;
    if (checkOutNumb === "") {
      checkOutNumb = 0;
    }
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/checkouts_1/${searchdata.fromDate}/${searchdata.toDate}/${WarehouseId.id}/${customerId.id}/${checkOutNumb}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setIsLoading(false);
      setRowData(resp.response.data.data);
    });
  }
  const handleGenerateReport = () => {
    setIsLoading(true);
    let checkOutNumb = searchdata.checkOutNumber;
    if (checkOutNumb === "") {
      checkOutNumb = 0;
    }
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/checkouts/export/${searchdata.fromDate}/${searchdata.toDate}/${WarehouseId.id}/${customerId.id}/${checkOutNumb}/${billingType.id}/excel`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setIsLoading(false);
      let sliceSize = 1024;
      let byteCharacters = atob(resp.response.data.data);
      let bytesLength = byteCharacters.length;
      let slicesCount = Math.ceil(bytesLength / sliceSize);
      let byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);
        let bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      FileSaver.saveAs(
        new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
        `${Date.now()}.xlsx`
      );
    });
  };
  const handleDownloadPdf = () => {
    setIsLoading(true);
    let checkOutNumb = searchdata.checkOutNumber;
    if (checkOutNumb === "") {
      checkOutNumb = 0;
    }
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/checkouts/export/${searchdata.fromDate}/${searchdata.toDate}/${WarehouseId.id}/${customerId.id}/${checkOutNumb}/${billingType.id}/pdf`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setIsLoading(false);
      let sliceSize = 1024;
      let byteCharacters = atob(resp.response.data.data);
      let bytesLength = byteCharacters.length;
      let slicesCount = Math.ceil(bytesLength / sliceSize);
      let byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);
        let bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      FileSaver.saveAs(
        new Blob(byteArrays, { type: "application/pdf" }),
        `${Date.now()}.pdf`
      );
    });
  };
  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  function getWarehouseData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/warehouse/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setwarehouseList(resp.response.data.data);
    });
  }
  function getCustomerData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setCustomerList(resp.response.data.data);
    });
  }
  useEffect(() => {
    getWarehouseData();
    getCustomerData();
  }, []);
  const warehouse = warehouseList.map((item) => {
    return {
      value: item.warehouseId,
      label: item.warehouseName,
    };
  });
  const customer = customerList.map((item) => {
    return {
      value: item.customerId,
      label: item.customerDisplayName,
    };
  });
  const billingTypeOptions = [
    {
      value: "ALL",
      label: "ALL",
    },
    {
      value: "CBM",
      label: "CBM",
    },
    {
      value: "SQM",
      label: "SQM",
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>CARGO CHECK OUT REPORT</h6>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody className="tabcard">
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12} className="search-sec">
                          <AvForm
                            className="form"
                            onValidSubmit={(e) => handleSearch(e)}
                          >
                            <Row>
                              <Col md={2}>
                                <label>From Date</label>
                                <AvField
                                  name="fromDate"
                                  type="date"
                                  onChange={handleChange}
                                  max={date}
                                  value={searchdata.fromDate}
                                  required
                                ></AvField>
                              </Col>
                              <Col md={2}>
                                <label>To</label>
                                <AvField
                                  name="toDate"
                                  type="date"
                                  onChange={handleChange}
                                  value={searchdata.toDate}
                                  min={searchdata.fromDate}
                                  max={date}
                                  required
                                  // value={data.custFn}
                                ></AvField>
                              </Col>
                              <Col md={2}>
                                <label>Warehouse</label>
                                <Select
                                  name="checkInWarehouseId"
                                  onChange={(e) =>
                                    setWarehouseId({
                                      id: e.value,
                                      name: e.label,
                                    })
                                  }
                                  options={warehouse}
                                />
                              </Col>
                              <Col md={2}>
                                <label>Customer</label>
                                <Select
                                  name="checkInCustomerId"
                                  onChange={(e) =>
                                    setCustomerId({
                                      id: e.value,
                                      name: e.label,
                                    })
                                  }
                                  options={customer}
                                />
                              </Col>
                              <Col md={2}>
                                <label>CheckOut Number</label>
                                <AvField
                                  name="checkOutNumber"
                                  type="text"
                                  onChange={handleChange}
                                  value={searchdata.checkOutNumber}
                                ></AvField>
                              </Col>
                              <Col md={2}>
                                <label>Type</label>
                                <Select
                                  onChange={(e) => {
                                    setTypeRequired(false);
                                    setBillingType({
                                      id: e.value,
                                      name: e.label,
                                    });
                                  }}
                                  options={billingTypeOptions}
                                />
                                {typeRequired ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    This field is required
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col>
                                <div className="search-buttons">
                                  <button
                                    className=" btn reportbutton"
                                    type="button"
                                    onClick={(e) => handleSearch(e)}
                                    disabled={isLoading}
                                  >
                                    Get Report
                                  </button>
                                  <button
                                    className=" btn reportbutton"
                                    onClick={handleGenerateReport}
                                    disabled={isLoading || billingType.id === 0}
                                  >
                                    Download Excel
                                  </button>
                                  <button
                                    className=" btn reportbutton"
                                    onClick={handleDownloadPdf}
                                    disabled={isLoading || billingType.id === 0}
                                  >
                                    Download PDF
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12}>
                      <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                          ref={gridRef}
                          animateRows="true"
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          //   enableRangeSelection="true"
                          rowData={rowData}
                          rowSelection="multiple"
                          suppressRowClickSelection="true"
                          onGridReady={onGridReady}
                          onFirstDataRendered={onFirstDataRendered}
                          pagination={true}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default CheckOutReport;
