import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

function DeleteProject(props) {
  return (
    // <Modal isOpen={props.openModal}>
    //   <Modal.Header>
    //     <Modal.Title>Delete Confirmation</Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
    //     <div className="alert alert-danger">
    //       Are you sure you want to delete the data
    //     </div>
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button variant="default" onClick={props.setOpenModel(false)}>
    //       Cancel
    //     </Button>
    //     <Button variant="danger">Delete</Button>
    //   </Modal.Footer>
    // </Modal>
    <Modal className="export-modal" isOpen={props.openModal}>
      <Row>
        <Col md={12} className="modal-head">
          <div>
            <h5>Are you sure you want to delete this Project</h5>
          </div>
          <div onClick={() => props.setOpenModel(false)}>
            <i class="bx bx-x"></i>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="declineModal">
          <button onClick={() => props.handleDeleteUom(props.id)}>Yes</button>
          <button onClick={() => props.setOpenModel(false)}>No</button>
        </Col>
      </Row>
    </Modal>
  );
}

export default DeleteProject;
