import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../config/BaseUrl";
import { apiGet, apiPost, apiPut } from "../../config/apiConfig";
import { format } from "date-fns";
import moment from "moment";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [notification, setNotification] = useState([]);
  const history = useHistory();
  function getData() {
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/master/all_notifications_admin`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      // console.log("notification", resp);
      if (resp.response.data.code == 200) {
        setNotification(resp.response.data.data);
      }
    });
  }
  const handleView = () => {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/set_read_notifications`;
    let resp = apiPut(Url);
    resp.then((resp) => {
      // console.log("notification read response", resp);
      if (resp.response.data.data === "Success") {
        getData();
      }
    });
  };
  useEffect(() => {
    if (
      localStorage.getItem("userRole") === "ADMIN" ||
      localStorage.getItem("userRole") === "USER"
    ) {
      getData();
    }
  }, []);

  const handleNotification = (url) => {
    console.log("url", url);
    history.push(url);
  };
  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <button
            type="button"
            class="btn header-item noti-icon waves-effect show"
            id="page-header-notifications-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={handleView}
          >
            <i class="bx bx-bell bx-tada"></i>
            <span class="badge bg-danger rounded-pill">
              {notification?.filter((items) => items.isViewed === 0).length}
            </span>
          </button>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                {/* <a href="#!" className="small">
                  {" "}
                  View All
                </a> */}
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {/* <div className="media emptynoti">
              <h6>No Notifications</h6>
            </div> */}
            {/* <div className="media emptynoti">
              {notification.map((notifications) => (
                <div>
                  <h1>{notifications.title}</h1>
                </div>
              ))}
            </div> */}

            {notification?.map((notifications, index) => {
              var localdate = new Date(notifications.dateCreated);
              return (
                <>
                  {/* <Link
                    key={index}
                    to={notification?.url}
                    className="text-reset notification-item"
                  > */}

                  <div
                    className="media text-reset notification-item"
                    onClick={() => handleNotification(notifications?.url)}
                    key={index}
                  >
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <span className="text-uppercase">
                          {notifications.title
                            ? notifications.title.charAt(4)
                            : "N"}
                        </span>
                      </span>
                    </div>
                    <div className="media-body">
                      <h6 className="mt-0 mb-1">{notifications.title}</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">{notifications.message}</p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline"></i>{" "}
                          {moment(localdate).format("hh:mm a")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </>
              );
            })}

            {/* <Link to="" className="text-reset notification-item">
              <div className="media">
                <img
                  src={avatar3}
                  className="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="media-body">
                  <h6 className="mt-0 mb-1">James Lemire</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("It will seem like simplified English") + "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline"></i>
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item">
              <div className="media">
                <div className="avatar-xs mr-3">
                  <span className="avatar-title bg-success rounded-circle font-size-16">
                    <i className="bx bx-badge-check"></i>
                  </span>
                </div>
                <div className="media-body">
                  <h6 className="mt-0 mb-1">
                    {props.t("Your item is shipped")}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("If several languages coalesce the grammar")}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline"></i>{" "}
                      {props.t("3 min ago")}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="" className="text-reset notification-item">
              <div className="media">
                <img
                  src={avatar4}
                  className="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="media-body">
                  <h6 className="mt-0 mb-1">Salena Layfield</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t(
                        "As a skeptical Cambridge friend of mine occidental"
                      ) + "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline"></i>
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link> */}
          </SimpleBar>
          {/* <div className="p-2 border-top">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
            >
              {" "}
              {props.t("View all")}{" "}
            </Link>
          </div> */}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(NotificationDropdown);
