import React, { useEffect } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../store/actions";
import { useHistory } from "react-router-dom";
function InActive(props) {
  const history = useHistory();
  const handleLogOut = () => {
    props.logoutUser(history);
  };
  return (
    <>
      <div className="inactivity-container">
        <div className="inactivity">
          <div className="inactivity-text">
            Your session has been automatically logged out,
          </div>
          <div className="inactivity-text1">
            because you have been inactive for more than 30 minutes.
          </div>
          <div>
            <button className="inactivity-btn" onClick={handleLogOut}>
              {" "}
              Go back to login
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {
  logoutUser,
})(InActive);
