import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import { Formik, Field } from "formik";
// import { Link } from "react-router-dom";
import PurchaseOrderTable from "./purchaseOrderTable";
import TermsAndConditions from "./termsAndConditions";
import { apiGet, apiPost } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import loader from "../../assets/loader/load.gif";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";

// import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosAddCircle } from "react-icons/io";

//i18n

const AddPurchaseOrderForm = (props) => {
  const [rowsData, setRowsData] = useState([
    {
      pdetails: "",
      account: "",
      quantity: "",
      rate: "",
      tax: "",
      amount: "",
    },
  ]);
  const [reload, setReload] = useState(false);
  const [subTotalAmount, setSubTotalAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [shipChrg, setShipChrg] = useState(0);
  const [adjus, setAdjus] = useState(0);
  const [itemData, setItemData] = useState([]);
  const [data, setData] = useState([]);
  const [IsSaving, setIsSaving] = useState(false);
  const [selectedVendorDetails, setSelectedVendorDetails] = useState({});
  const [edit, setEdit] = useState(false);
  const [branchList, setBranchData] = useState([]);
  const [values, setValues] = useState({
    deliv: "1",
    addretinvoice: false,
    vendorId: "",
    branch: "",
    // deliv: "",
    destinationOrganisation: "",
    destinationCustomer: "",
    purchaseOrderNumber: "",
    referenceNumber: "",
    date: "",
    expectedDeliveryDate: "",
    paymentTerms: "",
    shipmentPreference: "",
    reverseCharge: "",
    customernotes: "",
    shippingCharge: "",
    adjustment: "",
    termsandcondition: "",
    import: "",
  });

  const handleEdit = () => {
    setEdit(!edit);
  };
  const history = useHistory();

  // to get customer list
  function getData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/vendors/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      console.log("vendor table", resp);
      setData(resp.response.data.data);
    });
  }
  const custOptions = data.map((cust) => ({
    value: cust.vendorId,
    label: cust.vendorDisplayName,
  }));

  // const customAddCustomerOption = {
  //   label: (
  //     <Link to="addvendor">
  //       <span style={{ color: "#408dfb" }}>
  //         <span style={{ marginRight: "8px", color: "#408dfb" }}>
  //           <IoIosAddCircle color="#408dfb" />
  //         </span>{" "}
  //         Add Vendor
  //       </span>
  //     </Link>
  //   ),
  //   value: "addvendor",
  //   onClick: () => {
  //     console.log("Add Customer Clicked");
  //     history.push("/addvendor");
  //   },
  //   isFixed: true,
  // };

  // const options = [...custOptions, customAddCustomerOption];
  //to get item list
  function getItemData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      console.log("item list", resp);
      setItemData(resp.response.data.data);
    });
  }

  useEffect(() => {
    function getBranchList() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl +
        `api/v1/settings/organization_branch/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setBranchData(resp.response.data.data);
      });
    }
    getBranchList();
  }, []);

  useEffect(() => {
    getData();
    getItemData();
  }, []);
  const addTableRows = () => {
    const rowsInput = {
      pdetails: "",
      account: "",
      quantity: "",
      rate: "",
      tax: "",
      amount: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    const sum = rows
      .map((item) => parseInt(item.estItemAmount))
      .reduce((prev, curr) => prev + curr, 0);
    console.log(sum);
    setSubTotalAmount(sum);
    setTotalAmount(
      parseInt(subTotalAmount) + parseInt(shipChrg) + parseInt(adjus)
    );
  };

  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    if (name === "pdetails") {
      const selectedItem = itemData.find((item) => item.itemName === value);
      if (selectedItem) {
        rowsInput[index]["rate"] = selectedItem.itemSalesSellingPrice;
      }
    }
    rowsInput[index]["amount"] =
      (rowsInput[index]["quantity"] ? rowsInput[index]["quantity"] : 0) *
      rowsInput[index]["rate"];
    setRowsData(rowsInput);
    console.log(rowsInput);
    const sum = rowsInput
      .map((item) => parseInt(item.amount))
      .reduce((prev, curr) => prev + curr, 0);
    console.log(sum);
    setSubTotalAmount(sum);
    const totAmount =
      parseInt(subTotalAmount ? subTotalAmount : 0) +
      parseInt(shipChrg ? shipChrg : 0) +
      parseInt(adjus ? adjus : 0);
    setTotalAmount(totAmount);
  };
  const onHandleBlur = (index, e) => {
    const rowsInput = [...rowsData];
    rowsInput[index]["amount"] =
      rowsInput[index]["quantity"] * rowsInput[index]["rate"];
    setRowsData(rowsInput);
    const totAmount =
      parseInt(subTotalAmount ? subTotalAmount : 0) +
      parseInt(shipChrg ? shipChrg : 0) +
      parseInt(adjus ? adjus : 0);
    setTotalAmount(totAmount);
  };
  // const onHandleBlur = (index, e) => {
  //   const rowsInput = [...rowsData];
  //   rowsInput[index]["amount"] =
  //     rowsInput[index]["quantity"] * rowsInput[index]["rate"];
  //   setRowsData(rowsInput);
  //   const totAmount =
  //     parseInt(subTotalAmount) +
  //     parseInt(shipChrg ? shipChrg : 0) +
  //     parseInt(adjus ? adjus : 0);
  //   setTotalAmount(totAmount);
  // };
  const onCancel = () => {
    setTimeout(() => {
      history.push("/purchaseorders");
    }, 1000);
  };
  useEffect(() => {
    console.log("rowdatas", rowsData);
  }, [rowsData]);

  // custom add button in Select
  const CustomButton = ({ onClick }) => (
    <Link to="addvendor">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Vendor
      </button>
    </Link>
  );

  const DropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomButton onClick={console.log("clicked")} />
      </div>
    );
  };

  const customComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: DropdownMenu, // Use your custom dropdown menu
  };

  // on submit
  const handleSubmit = () => {
    console.log("values", values);
    setIsSaving(true);
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/purchase_order";
    let body = {
      //purchaseOrderVendorId: Number,
      purchaseOrderBillingAddressId: Number,
      purchaseOrderShippingAddressId: Number,
      purchaseOrderVendorRemarks: "",
      purchaseOrderGstTreatmentId: Number,
      purchaseOrderSourceOfSupplyId: Number,
      purchaseOrderDestinationOfSupplyId: Number,
      purchaseOrderDeliverToType: values.deliv,
      purchaseOrderDeliverToEmail: values.destinationCustomer,
      purchaseOrderDeliverToAddress: values.destinationOrganisation,
      purchaseOrderNumber: values.purchaseOrderNumber,
      purchaseOrderReferenceNumber: values.referenceNumber,
      purchaseOrderDate: values.date,
      purchaseOrderExpectedDeliveryDate: values.expectedDeliveryDate,
      purchaseOrderPaymentTerms: values.paymentTerms,
      purchaseOrderShipmentReference: values.shipmentPreference,
      purchaseOrderDiscountLevelType: "",
      purchaseOrderSubTotal: subTotalAmount,
      purchaseOrderDiscountType: Number,
      purchaseOrderDiscountPercentOrAmount: Number,
      purchaseOrderDiscountAmount: Number,
      purchaseOrderShippigCharges: values.shippingCharge,
      purchaseOrderAdjustment: values.adjustment,
      purchaseOrderTcsTaxId: Number,
      purchaseOrderTcsTaxPercent: Number,
      purchaseOrderRoundOff: Number,
      purchaseOrderTotal: totalAmount,
      purchaseOrderCustomerNotes: values.customernotes,
      purchaseOrderTermsAndConditions: values.termsandcondition,
      purchaseOrderAttachments: values.import,
      purchaseOrderIsSendEmail: Number,
      purchaseOrderSendEmailTo: "",
      purchaseOrderVendorId: values.vendorId,
      purchaseOrderVendorBillingAddress: "",
      purchaseOrderVendorShippingAddress: "",
      purchaseOrderVendorContactNumber: "",
      organizationId: 1,
      branchId: values.branch,
      addedByUser: 1,
      editByUser: 1,
      purchaseOrderItemsList: rowsData,
    };
    let resp = apiPost(url, body);
    resp.then((resp) => {
      console.log("purchase order data is", resp.response.data);
      setReload(false);
      switch (resp.response.data.code) {
        case 200:
          setIsSaving(false);
          toast.success(resp.response.data.data);
          setTimeout(() => {
            history.push("/purchaseorders");
          }, 1000);

          break;
        default:
          setIsSaving(false);
          toast.error(resp.response.data.data);
      }
    });
    // setTimeout(() => {
    //   alert(JSON.stringify(values, null, 2));
    //   setSubmitting(false);
    // }, 400);
  };
  // item custom button
  const CustomItemButton = ({ onClick }) => (
    <Link to="/additems/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Item
      </button>
    </Link>
  );
  const ItemDropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomItemButton onClick={console.log("clicked")} />
      </div>
    );
  };
  const customItemComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: ItemDropdownMenu, // Use your custom dropdown menu
  };
  const reactSelectOptions = itemData.map((items) => ({
    value: items.itemId,
    label: items.itemName,
  }));
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Formik
        initialValues={{
          deliv: "1",
          addretinvoice: false,
          vendorId: "",
          branch: "",
          // deliv: "",
          destinationOrganisation: "",
          destinationCustomer: "",
          purchaseOrderNumber: "",
          referenceNumber: "",
          date: "",
          expectedDeliveryDate: "",
          paymentTerms: "",
          shipmentPreference: "",
          reverseCharge: "",
          customernotes: "",
          shippingCharge: "",
          adjustment: "",
          termsandcondition: "",
          import: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.vendorId) {
            errors.vendorId = "Required";
          }

          if (!values.referenceNumber) {
            errors.referenceNumber = "Required";
          }
          return errors;
        }}
        onSubmit={(values) => {
          setIsSaving(true);
          let orgId = localStorage.getItem("organisationId");
          let url = BaseUrl.apiUrl.baseUrl + "api/v1/purchase_order";
          let body = {
            //purchaseOrderVendorId: Number,
            purchaseOrderBillingAddressId: Number,
            purchaseOrderShippingAddressId: Number,
            purchaseOrderVendorRemarks: "",
            purchaseOrderGstTreatmentId: Number,
            purchaseOrderSourceOfSupplyId: Number,
            purchaseOrderDestinationOfSupplyId: Number,
            purchaseOrderDeliverToType: values.deliv,
            purchaseOrderDeliverToEmail: values.destinationCustomer,
            purchaseOrderDeliverToAddress: values.destinationOrganisation,
            purchaseOrderNumber: values.purchaseOrderNumber,
            purchaseOrderReferenceNumber: values.referenceNumber,
            purchaseOrderDate: values.date,
            purchaseOrderExpectedDeliveryDate: values.expectedDeliveryDate,
            purchaseOrderPaymentTerms: values.paymentTerms,
            purchaseOrderShipmentReference: values.shipmentPreference,
            purchaseOrderDiscountLevelType: "",
            purchaseOrderSubTotal: subTotalAmount,
            purchaseOrderDiscountType: Number,
            purchaseOrderDiscountPercentOrAmount: Number,
            purchaseOrderDiscountAmount: Number,
            purchaseOrderShippigCharges: values.shippingCharge,
            purchaseOrderAdjustment: values.adjustment,
            purchaseOrderTcsTaxId: Number,
            purchaseOrderTcsTaxPercent: Number,
            purchaseOrderRoundOff: Number,
            purchaseOrderTotal: totalAmount,
            purchaseOrderCustomerNotes: values.customernotes,
            purchaseOrderTermsAndConditions: values.termsandcondition,
            purchaseOrderAttachments: values.import,
            purchaseOrderIsSendEmail: Number,
            purchaseOrderSendEmailTo: "",
            purchaseOrderVendorId: values.vendorId,
            purchaseOrderVendorBillingAddress: "",
            purchaseOrderVendorShippingAddress: "",
            purchaseOrderVendorContactNumber: "",
            organizationId: 1,
            branchId: values.branch,
            addedByUser: 1,
            editByUser: 1,
            purchaseOrderItemsList: rowsData,
          };
          console.log("purchase order data is", body);
          let resp = apiPost(url, body);
          resp.then((resp) => {
            console.log("purchase order data is", resp.response.data);
            setReload(false);
            switch (resp.response.data.code) {
              case 200:
                setIsSaving(false);
                toast.success(resp.response.data.data);
                setTimeout(() => {
                  history.push("/purchaseorders");
                }, 1000);

                break;
              default:
                setIsSaving(false);
                toast.error(resp.response.data.data);
            }
          });
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
        }}
      >
        {(
          {
            // values,
            // errors,
            // touched,
            // handleChange,
            // handleBlur,
            // handleSubmit,
            // isSubmitting,
            // setFieldValue,
            /* and other goodies */
          }
        ) => (
          <form
            // onSubmit={handleSubmit()}
            className="formik"
            id="addPurchaseOrder"
          >
            <Row>
              <Col md={2}>
                <label className="formik-label"> Vendor Name</label>
              </Col>
              <Col md={3} className="">
                {/* <Field
                  className="formik-input"
                  as="select"
                  name="vendorId"
                  onChange={(event) => {
                    handleChange(event);
                    const selectedIndex = event.target.value;
                    setSelectedVendorDetails(data[selectedIndex]);
                  }}
                  value={values.vendorId}
                >
                  <option value="" selected disabled>
                    Select Vendor
                  </option>
                  {data.map((vnd, index) => {
                    return (
                      <option value={vnd.vendorId}>
                        {vnd.vendorDisplayName}
                      </option>
                    );
                  })}
                </Field> */}
                <Select
                  components={customComponents}
                  options={custOptions}
                  name="vendorId"
                  value={custOptions.find(
                    (option) => custOptions.value === values.vendorId
                  )}
                  // onChange={(option) => {
                  //   setFieldValue({ vendorId: option.value });
                  // }}
                  onChange={(option) => {
                    setValues({ ...values, vendorId: option.value });
                  }}
                />
                {/* <div>
                  <i className="bx bx-search"></i>
                </div> */}
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Branch</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="branch"
                  onChange={(e) =>
                    setValues({ ...values, branch: e.target.value })
                  }
                  value={values.branch}
                >
                  <option value=""> Select Branch</option>
                  {branchList.map((br) => (
                    <option value={br.branchId}>{br.branchName}</option>
                  ))}
                </Field>
                <div>
                  {/*<span>Source of supply: Arunachal Pradesh</span>*/}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Deliver To</label>
              </Col>
              <Col md={10}>
                <div className="formik-radiodiv">
                  <Field
                    type="radio"
                    name="deliv"
                    value="1"
                    checked={values.deliv === "1"}
                    // onChange={() => setFieldValue("deliv", "1")}
                    onChange={(e) => setValues({ ...values, deliv: "1" })}
                  />
                  <span>Organization</span>
                </div>
                <div className="formik-radiodiv">
                  <Field
                    type="radio"
                    name="deliv"
                    value="2"
                    checked={values.deliv === "2"}
                    onChange={(e) => setValues({ ...values, deliv: "2" })}
                  />
                  <span>Customer</span>
                </div>

                {values.deliv === "1" ? (
                  <div>
                    <div className="dflex mb-3">
                      {/* {edit ? (
                        <>
                          <input
                            type="text"
                            onChange={handleChange}
                            value={values.destinationOrganisation}
                          />{" "}
                          <i
                            class="bx bx-check b ml-1"
                            onClick={handleEdit}
                          ></i>
                        </>
                      ) : (
                        <>
                          <span>Sumi.Opine</span>
                          <i
                            class="bx bx-pencil b ml-1"
                            onClick={handleEdit}
                          ></i>
                        </>
                      )} */}
                    </div>
                    <>
                      {values.vendorName ? (
                        <div>
                          <p>
                            {selectedVendorDetails.vendorCompanyName}
                            {selectedVendorDetails.vendorFirstname} <br />
                            {selectedVendorDetails.vendorPlaceOfSupply}
                            <br />
                            {selectedVendorDetails.vendorMobile}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <div className="copy-address">
                        Change destination to delivery
                      </div> */}
                    </>
                  </div>
                ) : (
                  <div>
                    <Field
                      className="formik-input"
                      as="select"
                      name="destinationCustomer"
                      // onChange={handleChange}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          destinationCustomer: e.target.value,
                        })
                      }
                      value={values.destinationCustomer}
                    >
                      <option value="" selected disabled>
                        Select Customer
                      </option>
                      {data?.map((cust, index) => {
                        return (
                          <option value={index}>{cust.vendorFirstname}</option>
                        );
                      })}
                    </Field>
                    <Col md={4} className="mt-4">
                      <p>
                        {" "}
                        <p>
                          Stock on Hand will not be affected only in case of
                          dropshipments. Selecting the Customer option in the
                          Deliver To field of a normal purchase order will have
                          an effect on your stock level
                        </p>
                      </p>
                    </Col>
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <label className="formik-label"> Reference#</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="text"
                  name="referenceNumber"
                  // onChange={handleChange}
                  onChange={(e) =>
                    setValues({ ...values, referenceNumber: e.target.value })
                  }
                  value={values.referenceNumber}
                ></Field>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Date</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="date"
                  name="date"
                  // onChange={handleChange}
                  onChange={(e) =>
                    setValues({ ...values, date: e.target.value })
                  }
                  value={values.date}
                  min={new Date().toISOString().split("T")[0]}
                ></Field>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label className="formik-label">
                      {" "}
                      Expected Delivery Date
                    </label>
                  </Col>
                  <Col md={8}>
                    <Field
                      className="formik-input formik-inputrow"
                      type="date"
                      name="expectedDeliveryDate"
                      // onChange={handleChange}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          expectedDeliveryDate: e.target.value,
                        })
                      }
                      value={values.expectedDeliveryDate}
                      min={values.date}
                    ></Field>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label className="formik-label"> Payment Terms</label>
                  </Col>
                  <Col md={8}>
                    <Field
                      className="formik-input formik-inputrow"
                      as="select"
                      name="paymentTerms"
                      // onChange={handleChange}
                      onChange={(e) =>
                        setValues({ ...values, paymentTerms: e.target.value })
                      }
                      value={values.paymentTerms}
                    >
                      <option value="" selected disabled>
                        Select Payment
                      </option>
                      <option value="Net15">Net 15</option>
                      <option value="Net30">Net 30</option>
                      <option value="Net45">Net 45</option>
                      <option value="Net60">Net 60</option>
                      <option value="Dueendofmonth ">
                        Due end of the month
                      </option>
                      <option value="Dueendnextmonth ">
                        Due end of next month
                      </option>
                      <option value="Dueonreciept ">Due on Reciept</option>
                    </Field>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Shipment Preference</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="input"
                  name="shipmentPreference"
                  // onChange={handleChange}
                  onChange={(e) =>
                    setValues({ ...values, shipmentPreference: e.target.value })
                  }
                  value={values.shipmentPreference}
                ></Field>
                <div>
                  <label className="retinvoice mt-2">
                    <Field
                      type="checkbox"
                      name="reverseCharge"
                      // onChange={handleChange}
                      onChange={(e) =>
                        setValues({ ...values, reverseCharge: e.target.value })
                      }
                      value={values.reverseCharge}
                    />

                    <span className="ml-2">
                      This transaction is applicable for reverse charge
                    </span>
                  </label>
                </div>
              </Col>
            </Row>

            <hr />

            {/* <PurchaseOrderTable /> */}

            <div className="">
              <div className="row clearfix">
                <div className="col-md-12 column">
                  <table
                    className="table table-bordered invoice-table"
                    id="tab_logic"
                  >
                    <thead>
                      <tr>
                        <th className="text-center"> # </th>
                        <th className="text-left"> ITEM NAME</th>
                        {/*<th className="text-left"> ACCOUNT</th>*/}
                        <th className="text-right"> QUANTITY</th>
                        <th className="text-right"> RATE</th>
                        {/* <th className="text-right"> TAX</th> */}
                        <th className="text-right"> AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rowsData.map((item, index) => (
                        <tr id="addr0" key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {/* <select
                              name="pdetails"
                              value={item.pdetails}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            >
                              <option value="" selected disabled>
                                Select Item
                              </option>
                              {itemData?.map((items, index) => (
                                <option>{items.itemName}</option>
                              ))}
                            </select> */}
                            <Select
                              required
                              name="invItemId"
                              components={customItemComponents}
                              onChange={(selectedOption) => {
                                // Extract the selected item's ID from the selectedOption
                                const selectedItemId = selectedOption.value;
                                const rowsInput = [...rowsData];
                                rowsInput[index]["itemId"] = selectedItemId;
                                setRowsData(rowsInput);
                              }}
                              value={reactSelectOptions.find(
                                (option) =>
                                  option.value ==
                                  (rowsData[index]
                                    ? rowsData[index]["itemId"]
                                    : "")
                              )}
                              options={reactSelectOptions}
                            />
                          </td>
                          {/* <td>
                            <select
                              name="account"
                              id="tax"
                              value={item.account}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            >
                              <option value="" disabled selected>
                                Select an Accounnt
                              </option>
                              <option>Advance tax</option>
                              <option>Employee Advance</option>
                              <option>Tax Credit</option>
                            </select>
                            </td>*/}
                          <td>
                            <input
                              type="number"
                              name="quantity"
                              value={item.quantity}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                              onBlur={(e) => onHandleBlur(index, e)}
                              className="form-control"
                              placeholder="1.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              name="rate"
                              value={item.rate}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                              onBlur={(e) => onHandleBlur(index, e)}
                              className="form-control"
                              placeholder="0.00"
                            />
                          </td>

                          {/* <td>
                            <select
                              name="tax"
                              id="tax"
                              disabled
                              value={item.tax}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            >
                              <option value="%" disabled selected>
                                Select a tax
                              </option>
                            </select>
                          </td> */}
                          {/* <td width="15%">
                            <div className="table-inputs">
                              <div>
                                <select
                                  name="customerselect"
                                  id="perc"
                                  value={item.customerselect}
                                  onChange={(evnt) =>
                                    handleTableChange(index, evnt)
                                  }
                                >
                                  <option value="" selected disabled>
                                    Select Customer
                                  </option>
                                  {data.map((datas, index) => {
                                    return (
                                      <option value={index}>
                                        {datas.customerDisplayName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </td> */}
                          <td width="15%">
                            <input
                              type="number"
                              name="amount"
                              value={item.amount}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                              disabled
                              className="form-control"
                            />
                          </td>
                          <td className="text-center">
                            <span
                              className="btn btn-outline-danger"
                              onClick={(e) => deleteTableRows(index, e)}
                            >
                              x
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Row>
                    <Col md={6} className="table-left">
                      <div>
                        <span
                          onClick={addTableRows}
                          className="btn btn-default pull-left add-tablerow"
                        >
                          <i class="bx bxs-plus-circle"></i>
                          <span>Add Item</span>
                        </span>
                      </div>
                      <div className="customernotes">
                        <span>Customer Notes</span>
                        <div>
                          <textarea
                            name="customernotes"
                            rows="4"
                            cols="50"
                            placeholder="Looking forward for your business"
                            // onChange={handleChange}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                customernotes: e.target.value,
                              })
                            }
                            value={values.customernotes}
                          ></textarea>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="table-right">
                        <Row>
                          <Col className="details">
                            <div>
                              <span className="detail-head">Sub Total</span>
                            </div>
                            <div>
                              <span>{subTotalAmount}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <div>
                              <span className="detail-head">
                                Shipping Charges
                              </span>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="input-tooltip">
                              <input
                                type="text"
                                name="shippingCharge"
                                // onChange={handleChange}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    shippingCharge: e.target.value,
                                  })
                                }
                                onBlur={(e) => {
                                  // handleBlur(e);
                                  console.log("cajksdhajksdh");
                                  setShipChrg(values.shippingCharge);
                                  let sum =
                                    parseInt(subTotalAmount) +
                                    parseInt(
                                      values.shippingCharge
                                        ? values.shippingCharge
                                        : 0
                                    ) +
                                    parseInt(
                                      values.adjustment ? values.adjustment : 0
                                    );
                                  setTotalAmount(sum);
                                }}
                                value={values.shippingCharge}
                              />
                              <i class="bx bx-help-circle"></i>
                            </div>
                          </Col>
                          <Col md={3} className="text-right">
                            <div>
                              <span>{values.shippingCharge}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <div>
                              <span className="detail-head">Adjustment</span>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="input-tooltip">
                              <input
                                type="text"
                                name="adjustment"
                                // onChange={handleChange}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    adjustment: e.target.value,
                                  })
                                }
                                value={values.adjustment}
                                onBlur={(e) => {
                                  // handleBlur(e);
                                  console.log("cajksdhajksdh");
                                  setAdjus(values.adjustment);
                                  let sum =
                                    parseInt(subTotalAmount) +
                                    parseInt(
                                      values.shippingCharge
                                        ? values.shippingCharge
                                        : 0
                                    ) +
                                    parseInt(
                                      values.adjustment ? values.adjustment : 0
                                    );
                                  setTotalAmount(sum);
                                }}
                              />
                              <i class="bx bx-help-circle"></i>
                            </div>
                          </Col>
                          <Col md={3} className="text-right">
                            <div>
                              <span>{values.adjustment}</span>
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col md={4}>
                            <div>
                              <span className="detail-head">Tcs</span>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="input-tooltip">
                              <select
                                name="selecttax"
                                placeholder="select a tax"
                              >
                                <option value="%">Select a Tax</option>
                                <option value="rs">Rs.</option>
                              </select>
                              <i class="bx bx-help-circle"></i>
                            </div>
                          </Col>
                          <Col md={3} className="text-right">
                            <div>
                              <span>0.00</span>
                            </div>
                          </Col>
                        </Row> */}
                        <Row>
                          <Col className="details">
                            <div>
                              <span className="total">Total</span>
                            </div>

                            <div>
                              <span className="total">{totalAmount}</span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <hr />
            <Row>
              <Col md={12}>
                {/* <TermsAndConditions invoice={values.addretinvoice} /> */}
                <div className="table-bottom">
                  <div className="row clearfix">
                    <div className="col-md-8 termsandcondition">
                      <span>Terms & Conditions</span>
                      <div className="mt-2">
                        <Field
                          className="formik-textarea terms-textarea"
                          as="textarea"
                          name="termsandcondition"
                          // onChange={handleChange}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              termsandcondition: e.target.value,
                            })
                          }
                          value={values.termsandcondition}
                        ></Field>
                      </div>
                    </div>
                    <div className="col-md-4 attachfiles">
                      <span>Attach File(s) to Estimate</span>
                      <div className="attach">
                        <Field
                          name="import"
                          type="file"
                          // onChange={handleChange}
                          onChange={(e) =>
                            setValues({ ...values, import: e.target.value })
                          }
                          value={values.import}
                        ></Field>
                      </div>
                      <div>
                        <span style={{ fontSize: "10px" }}>
                          You can upload a maximum of 5 files, 5MB each
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
      <div className="bottom-bar">
        <Row>
          <Col md={4}>
            <Row>
              <Col md={4}>
                <button className="btn btn-left ml-3" onClick={onCancel}>
                  Cancel
                </button>
              </Col>
              <Col md={4}>
                <button
                  type="reset"
                  className="btn btn-left"
                  form="addPurchaseOrder"
                >
                  Clear
                </button>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="printView">
            <span className="print">Print or Preview</span>
          </Col>
          <Col md={4}>
            <button
              className="btn btn-right float-right mr-3"
              // type="submit"
              type="button"
              form="addPurchaseOrder"
              onClick={handleSubmit}
              // disabled={IsSaving}
            >
              {IsSaving ? (
                <img className="loader" src={loader} alt="loading..." />
              ) : (
                " Save"
              )}
            </button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default AddPurchaseOrderForm;
