import React, { useMemo, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";

import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";
import Axios from "axios";
// import Table from "./Table";
import { apiGet, apiDelete, apiPut } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import { toast, ToastContainer } from "react-toastify";
import FilterComponent from "./FilterComponent";
import { Col, Modal, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import UserEdit from "./UserEdit";
function UsersList() {
  let userRole = localStorage.getItem("userRole");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = React.useState("");
  const [declineModal, setdeclineModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [approveModel, setapproveModel] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [loadingData, setLoadingData] = useState(true);
  function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }
  let adminId = localStorage.getItem("Id");
  const handleDelete = () => {
    let Id = userId;
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/user_delete/${Id}/${adminId}`;
    let resp = apiDelete(Url);
    setdeclineModal(false);
    toast.success("User Deleted Successfully");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  const openModal = (userid) => {
    setdeclineModal(true);
    setUserId(userid);
  };

  // user edit handles below
  const openEditModel = (userid) => {
    setUserId(userid);
    setapproveModel(true);
  };
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    mobile: "",
    organizationId: "",
  });
  function handle(e) {
    const newData = { ...values };
    newData[e.target.name] = e.target.value;
    setValues(newData);
  }
  const updateUser = (e) => {
    e.persist();
    let orgId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + "api/v1/user_profile/" + userId;
    var body = {
      firstname: values.firstname,
      lastname: values.lastname,
      mobile: values.mobile,
    };
    let resp = apiPut(Url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success("Edited User Successfully");
          setapproveModel(false);
          setUserId("");
          setTimeout(() => {
            window.location.reload();
          }, 5000);
          break;

        default:
          toast.error("Failed to update user");
          setapproveModel(false);
          setUserId("");
        // setTimeout(() => {
        // }, 2000);
      }
    });
  };
  function getUserDataById(id) {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/user_profile/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      var datas = resp.response.data.data;
      setValues(datas);
    });
  }

  // user edit handles ends here

  function toPages(pages) {
    const results = [];

    for (let i = 1; i < pages; i++) {
      results.push(i);
    }

    return results;
  }

  const columns = [
    {
      name: "User Name",
      selector: (data) => data.firstname,
      sortable: true,
    },
    {
      name: "Type",
      selector: (data) => data.role,
      sortable: true,
    },

    {
      name: "Email",
      selector: (data) => data.email,
      sortable: true,
      // right: true,
    },
    {
      name: "User-Name",
      selector: (data) => data.username,
      sortable: true,
      // right: true,
    },
    {
      name: "Phone Number",
      selector: (data) => data.mobile,
      sortable: true,
      // right: true,
    },
    // {
    //   name: "Customer Email",
    //   selector: (data) => data.orderCustomerEmail,
    //   sortable: true,
    //   // right: true,
    // },
    {
      name: "Active Status",
      selector: (data) => (
        <div
          className={`${
            data.deleteStatus == 0
              ? "green"
              : data.deleteStatus == 1
              ? "red"
              : ""
          }`}

          // disabled={!data.startDate == new Date()}
        >
          {data.deleteStatus == 0
            ? "Active User"
            : data.deleteStatus == 1
            ? "Deleted User"
            : ""}
        </div>
      ),
      sortable: true,
      // right: true,
    },
    // {
    //   button: true,
    //   cell: (data) => (
    //     <div
    //       className="tablebtn"
    //       // disabled={!data.startDate == new Date()}
    //     >
    //       {userRole == "ADMIN" ? (
    //         data.deleteStatus == 0 ? (
    //           <button onClick={() => openEditModel(data.id)}>Edit</button>
    //         ) : (
    //           ""
    //         )
    //       ) : (
    //         ""
    //       )}
    //     </div>
    //   ),
    // },
    {
      button: true,
      cell: (data) => (
        <div className="tabledltbtn">
          {userRole == "ADMIN" ? (
            data.deleteStatus == 0 ? (
              <button onClick={() => openModal(data.id)}>
                <span className=" btn-outline-danger-delete">
                  <i class="bx bx-trash"></i>
                </span>
              </button>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];
  // function tConv24(time24) {
  //   var ts = time24;
  //   var H = +ts.substr(0, 2);
  //   var h = H % 12 || 12;
  //   h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
  //   var ampm = H < 12 ? " AM" : " PM";
  //   ts = h + ts.substr(2, 3) + ampm;
  //   return ts;
  // }

  const getData = async (page) => {
    setLoading(true);
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/users/org?org_id=${organisationId}&page=${page}&size=${perPage}&sort=id,desc&searchText=${filterText}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setTotalRows(resp.response.data.data.totalElements);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    getData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    alert(newPerPage, page);
    setLoading(true);

    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/users/org?org_id=${organisationId}&page=${page}&size=${newPerPage}&sort=id,desc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setPerPage(newPerPage);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData(1);
  }, [filterText]);
  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  // const getData = async () => {
  //   setLoading(true);
  //   const organisationId = localStorage.getItem("organisationId");
  //   const Url = BaseUrl.apiUrl.baseUrl + `api/v1/users/org/${organisationId}`;
  //   let resp = apiGet(Url);
  //   resp.then((resp) => {
  //     console.log("users table", resp);
  //     setLoading(false);
  //     setData(resp.response.data.data);
  //     // setTotalRows(resp.response.data.data.totalElements);
  //   });
  // };
  // useEffect(() => {
  //   getData();
  // }, []);

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        refreshUserList={() => getData(1)}
      />
    );
  }, [filterText, resetPaginationToggle]);
  return (
    <div className="App">
      <div className="card">
        <DataTable
          columns={columns}
          // data={data}
          data={filteredItems}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows
          subHeader
          subHeaderComponent={subHeaderComponent}
          subHeaderAlign="left"
        />
      </div>
      <Modal className="export-modal" isOpen={declineModal}>
        <Row>
          <Col md={12} className="modal-head">
            <div>
              <h5>Are you sure you want to delete this User</h5>
            </div>
            <div onClick={() => setdeclineModal(false)}>
              <i class="bx bx-x"></i>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="declineModal">
            <button onClick={() => handleDelete()}>Yes</button>
            <button onClick={() => setdeclineModal(false)}>No</button>
          </Col>
        </Row>
      </Modal>
      <UserEdit
        setapproveModel={setapproveModel}
        approveModel={approveModel}
        userId={userId}
        refreshUserList={() => getData(1)}
      />
    </div>
  );
}
export default UsersList;
