import React from "react";
import { render } from "react-dom";
import { Row, Col } from "reactstrap";
import { AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
class RecurringInvoiceTable extends React.Component {
  state = {
    rows: [0],
  };
  handleChange = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    rows[idx] = {
      [name]: value,
    };
    this.setState({
      rows,
    });
  };
  handleAddRow = () => {
    const item = {
      pDetails: "",
      quantity: "",
      rate: "",
      discountinput: "",
      discountselect: "",
      tax: "",
      amount: "",
    };
    this.setState({
      rows: [...this.state.rows, item],
    });
  };
  handleRemoveRow = () => {
    this.setState({
      rows: this.state.rows.slice(0, 0),
    });
  };

  render() {
    return (
      <div>
        <div className="container">
          <div className="row clearfix">
            <div className="col-md-12 column">
              <table
                className="table table-bordered invoice-table"
                id="tab_logic"
              >
                <thead>
                  <tr>
                    <th className="text-center"> # </th>
                    <th className="text-left"> ITEM NAME</th>
                    <th className="text-right"> QUANTITY</th>
                    <th className="text-right"> RATE</th>
                    <th className="text-right"> DISCOUNT </th>
                    <th className="text-right"> TAX</th>
                    <th className="text-right"> AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.rows.map((item, idx) => (
                    <tr id="addr0" key={idx}>
                      <td>{idx}</td>
                      <td>
                        <input
                          type="text"
                          name="pdetails"
                          value={this.state.rows[idx].pDetails}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="quantity"
                          value={this.state.rows[idx].quantity}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                          placeholder="1.00"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="rate"
                          value={this.state.rows[idx].rate}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                          placeholder="0.00"
                        />
                      </td>
                      <td width="10%">
                        <div className="table-inputs">
                          <div>
                            {" "}
                            <input
                              type="text"
                              name="discountinput"
                              value={this.state.rows[idx].discountinput}
                              onChange={this.handleChange(idx)}
                              className="form-control"
                              placeholder="0"
                            />
                          </div>
                          <div>
                            <select
                              name="discountselect"
                              id="perc"
                              value={this.state.rows[idx].discountselect}
                              onChange={this.handleChange(idx)}
                            >
                              <option value="%">%</option>
                              <option value="rs">Rs.</option>
                            </select>
                          </div>
                        </div>
                      </td>
                      <td>
                        <select
                          name="tax"
                          id="tax"
                          disabled
                          value={this.state.rows[idx].tax}
                          onChange={this.handleChange(idx)}
                        >
                          <option value="%" disabled selected>
                            Select a tax
                          </option>
                        </select>
                      </td>
                      <td width="15%">
                        <input
                          type="text"
                          name="amount"
                          value={this.state.rows[idx].amount}
                          onChange={this.handleChange(idx)}
                          className="form-control"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Row>
                <Col md={6} className="table-left">
                  <div>
                    <button
                      onClick={this.handleAddRow}
                      className="btn btn-default pull-left add-tablerow"
                    >
                      <i class="bx bxs-plus-circle"></i>
                      <span>Add another line</span>
                    </button>
                    {/* <button
                      onClick={this.handleRemoveRow}
                      className="pull-right btn btn-default table-btn"
                    >
                      Clear all rows
                    </button> */}
                  </div>
                  <div className="customernotes">
                    <span>Customer Notes</span>
                    <div>
                      <textarea
                        name="customernotes"
                        rows="4"
                        cols="50"
                        placeholder="Looking forward for your business"
                      ></textarea>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="table-right">
                    <Row>
                      <Col className="details">
                        <div>
                          <span className="detail-head">Sub Total</span>
                        </div>
                        <div>
                          <span>0.00</span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <div>
                          <span className="detail-head">Shipping Charges</span>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="input-tooltip">
                          <input type="text" />
                          <i class="bx bx-help-circle"></i>
                        </div>
                      </Col>
                      <Col md={3} className="text-right">
                        <div>
                          <span>0.00</span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <div>
                          <span className="detail-head">Adjustment</span>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="input-tooltip">
                          <input type="text" />
                          <i class="bx bx-help-circle"></i>
                        </div>
                      </Col>
                      <Col md={3} className="text-right">
                        <div>
                          <span>0.00</span>
                        </div>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col md={4}>
                        <div>
                          <span className="detail-head">Tcs</span>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="input-tooltip">
                          <select name="selecttax" placeholder="select a tax">
                            <option value="%">Select a Tax</option>
                            <option value="rs">Rs.</option>
                          </select>
                          <i class="bx bx-help-circle"></i>
                        </div>
                      </Col>
                      <Col md={3} className="text-right">
                        <div>
                          <span>0.00</span>
                        </div>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col className="details">
                        <div>
                          <span className="total">Total</span>
                        </div>

                        <div>
                          <span className="total">0.00</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RecurringInvoiceTable;
