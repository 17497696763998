import {
  SAVE_CURRENT_SELECTED_ITEM,
  GET_CURRENT_SELECTED_ITEM,
} from "./actionTypes";

const INIT_STATE = {
  selectedItemId: "",
  selectedItem: {},
};

const ItemDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CURRENT_SELECTED_ITEM:
      return {
        ...state,
        selectedItemId: action.payload,
      };
    case SAVE_CURRENT_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };
    default:
      return state;
  }
};
export default ItemDetails;
