import {
  ADD_PALLET_TO_CLOSE,
  SET_PALLET_TO_CLOSE,
  SET_PALLET_TO_MERGE,
  UPDATE_PALLET_TO_MERGE_DATA,
  CLEAR_PALLET_MERGE_TABLE_DATAS,
} from "./actionTypes";
const INIT_STATE = {
  palletRequestCustomerDetails: {},
  palletToCloseRowData: [],
  palletToMergeRowData: [],
  emptyDataArrayClose: false,
  emptyDataArrayMerge: false,
};

const PalletMergeTable = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PALLET_TO_CLOSE:
      state.emptyDataArrayClose = action.payload === null ? true : false;
      var oldData = state.emptyDataArrayClose
        ? []
        : action.payload?.itemsList?.map((item, index) => {
            var table;
            return (table = {
              itemMergeType: 1,
              isPalletCloseRequestGiven: item.isPalletCloseRequestGiven,
              itemCargoCheckInItemId: parseInt(item.itemId),
              itemCommodityName: item.itemCommodityName,
              itemHsnCode: item.itemHsnCode,
              itemUom: item.itemUom,
              itemUnitsTotal: parseInt(item.itemUnits),
              itemUnitsAvailable: item.itemUnitsAvailable,
              itemUnitsCheckedOut: item.itemUnitsCheckedOut,
              itemBatch: item.itemBatch,
              itemPallet: item.itemPallet,
              itemUnitsPerSinglePackage: item.itemUnitsPerSinglePackage,
              itemPackingType: item.itemPackingType,
              itemTemperature: item.itemTemperature,
              itemHandlingInfo: item.itemHandlingInfo,
              itemDateOfExpiry: item.itemDateOfExpiry,
              itemTotalWeight: item.itemTotalWeight,
              itemWeightUom: item.itemWeightUom ? item.itemWeightUom : "",
              itemLength: item.itemLength,
              itemBreadth: item.itemBreadth,
              itemHeight: item.itemHeight,
              itemDimensionUnits: item.itemDimensionUnits
                ? item.itemDimensionUnits
                : "",
              itemArea: item.itemArea,
              itemAreaSqm: item.itemAreaSqm,
              itemAreaUnits: item.itemAreaUnits,
              itemUnitsToMerge: 0,
              itemAreaToMerge: 0,
              itemSqmToMerge: 0,
              itemWarehouseId: item.itemWarehouseId,
              itemCustomerId: item.itemCustomerId,
              itemInvoiceNumber: item.itemInvoiceNumber,
              itemRemarks: item.itemRemarks,
              itemCargoCondition: item.itemCargoCondition,
              itemIsSelected: false,
              itemAreaOccupied: item.itemAreaOccupied,
              itemSqmOccupied: item.itemSqmOccupied,
            });
          });
      return {
        ...state,
        palletToCloseRowData: oldData,
        palletRequestCustomerDetails: action.payload,
      };
      break;
    case SET_PALLET_TO_MERGE:
      state.emptyDataArrayMerge = action.payload === null ? true : false;
      var oldData = state.emptyDataArrayMerge
        ? []
        : action.payload?.itemsList.map((item, index) => {
            var table;
            return (table = {
              itemMergeType: 2,
              isPalletCloseRequestGiven: item.isPalletCloseRequestGiven,
              itemCargoCheckInItemId: parseInt(item.itemId),
              itemCommodityName: item.itemCommodityName,
              itemHsnCode: item.itemHsnCode,
              itemUom: item.itemUom,
              itemUnits: parseInt(item.itemUnits),
              itemUnitsAvailable: item.itemUnitsAvailable,
              itemUnitsCheckedOut: item.itemUnitsCheckedOut,
              itemBatch: item.itemBatch,
              itemPallet: item.itemPallet,
              itemUnitsPerSinglePackage: item.itemUnitsPerSinglePackage,
              itemPackingType: item.itemPackingType,
              itemTemperature: item.itemTemperature,
              itemHandlingInfo: item.itemHandlingInfo,
              itemDateOfExpiry: item.itemDateOfExpiry,
              itemTotalWeight: item.itemTotalWeight,
              itemWeightUom: item.itemWeightUom ? item.itemWeightUom : "",
              itemLength: item.itemLength,
              itemBreadth: item.itemBreadth,
              itemHeight: item.itemHeight,
              itemDimensionUnits: item.itemDimensionUnits
                ? item.itemDimensionUnits
                : "",
              itemArea: item.itemArea,
              itemAreaSqm: item.itemAreaSqm,
              itemAreaUnits: item.itemAreaUnits,
              itemUnitsToMerge:
                state.palletToCloseRowData[0]?.itemUnitsAvailable,
              itemAreaToMerge: state.palletToCloseRowData[0]?.itemAreaOccupied,
              itemSqmToMerge: state.palletToCloseRowData[0]?.itemSqmOccupied,
              itemWarehouseId: item.itemWarehouseId,
              itemCustomerId: item.itemCustomerId,
              itemInvoiceNumber: item.itemInvoiceNumber,
              itemRemarks: item.itemRemarks,
              itemCargoCondition: item.itemCargoCondition,
              itemIsSelected: false,
              itemAreaOccupied: item.itemAreaOccupied,
              itemSqmOccupied: item.itemSqmOccupied,
            });
          });
      return {
        ...state,
        palletToMergeRowData: oldData,
      };
      break;
    case UPDATE_PALLET_TO_MERGE_DATA:
      var oldData = [...state.palletToMergeRowData];

      oldData[action.payload.index][action.payload.value.name] =
        action.payload.value.value;

      return {
        ...state,
        palletToMergeRowData: oldData,
      };
      break;
    case CLEAR_PALLET_MERGE_TABLE_DATAS:
      return {
        ...state,
        palletRequestCustomerDetails: {},
        palletToCloseRowData: [],
        palletToMergeRowData: [],
        emptyDataArrayClose: false,
        emptyDataArrayMerge: false,
      };
      break;
    default:
      return state;
      break;
  }
};
export default PalletMergeTable;
