import React, { useMemo, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import { Row, Col, Modal } from "reactstrap";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
// import Table from "./Table";
import { apiGet, apiPut } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import dyarco from "../../assets/dyarco/dyarco.jpeg";
import FilterComponent from "./FilterComponent";
import { ToastContainer, toast } from "react-toastify";

function CargoCheckOutList() {
  const history = useHistory();
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = React.useState("");
  const [declineModal, setdeclineModal] = useState(false);
  const [checkOutId, setCheckOutId] = useState("");
  const [checkOutType, setCheckOutType] = useState("");

  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }

  function toPages(pages) {
    const results = [];

    for (let i = 1; i < pages; i++) {
      results.push(i);
    }

    return results;
  }

  // const printPDF = (data) => {
  //   const Url = BaseUrl.apiUrl.baseUrl + `api/v1/cargo_checkout/${data}`;
  //   let resp = apiGet(Url);
  //   resp.then((resp) => {
  //     setprintdata(resp.response.data.data);
  //     handlePrint();
  //   });
  // };

  const columns = [
    {
      name: "Check Out Number",
      selector: (data) => data.checkOutNumber,
      sortable: true,
    },
    {
      name: "Date",
      selector: (data) => data.checkOutDate,
      sortable: true,
    },

    {
      name: "Warehouse Name",
      selector: (data) => data.checkOutWarehouseName,
      sortable: true,
      // right: true,
    },
    {
      name: "Customer Name",
      selector: (data) => data.checkOutCustomerName,
      sortable: true,
      // right: true,
    },

    {
      button: true,
      cell: (data) => (
        <>
          <div className="table-actionBtn">
            {data.checkOutStatus === 1 ? (
              <div className="tablebtn">
                <button onClick={() => openModal(data)}>Close CheckOut</button>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ),
      width: "230px",
    },
  ];
  const getData = async (page) => {
    setLoading(true);
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/cargo_checkout/org?org_id=${organisationId}&page=${page}&size=${perPage}&sort=checkOutId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setTotalRows(resp.response.data.data.totalElements);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    getData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/cargo_checkout/org?org_id=${organisationId}&page=${page}&size=${newPerPage}&sort=checkOutId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setPerPage(newPerPage);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData(1);
  }, [filterText]);
  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const openModal = (datas) => {
    setdeclineModal(true);
    setCheckOutId(datas.checkOutId);
    setCheckOutType(datas.checkOutType);
  };
  const handleClose = () => {
    setLoading(true);
    let userId = localStorage.getItem("Id");
    let typeCheckOut = checkOutType === "Unit-Based" ? 1 : 2;
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/cargo_checkout/${checkOutId}/${userId}/${typeCheckOut}/freeze`;
    // var body = {
    //   checkInStatus: 2,
    // };
    let resp = apiPut(Url);
    resp.then((resp) => {
      setLoading(false);
      switch (resp.response.data.code) {
        case 200:
          setdeclineModal(false);
          toast.success(resp.response.data.data);
          getData(1);
          break;
        default:
          setdeclineModal(false);
          toast.error(resp.response.data.data);
      }
    });
  };
  const handleRowClick = (row) => {
    history.push(`/viewcheckout/${row.checkOutId}`);
  };
  return (
    <div className="App">
      <ToastContainer
        position="bottom-center"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        // pauseOnFocusLoss
        // pauseOnHover
      />
      <div className="card">
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows
          subHeader
          subHeaderComponent={subHeaderComponent}
          subHeaderAlign="left"
          onRowClicked={handleRowClick}
          highlightOnHover
          pointerOnHover
        />
        <Modal className="export-modal" isOpen={declineModal}>
          <Row>
            <Col md={12} className="modal-head">
              <div>
                <h5>Are you sure you want to close this Check-Out</h5>
              </div>
              <div onClick={() => setdeclineModal(false)}>
                <i class="bx bx-x"></i>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="declineModal">
              <button onClick={handleClose} disabled={loading}>
                Yes
              </button>
              <button onClick={() => setdeclineModal(false)}>No</button>
            </Col>
          </Row>
        </Modal>
      </div>
    </div>
  );
}
export default CargoCheckOutList;
