import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import { Formik, Field, ErrorMessage } from "formik";
import { Link, useHistory } from "react-router-dom";
import BillTable from "./billTable";
import TermsAndConditions from "./termsAndConditions";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet, apiPost } from "../../components/config/apiConfig";
import loader from "../../assets/loader/load.gif";
// import { ToastContainer, toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

//i18n

import {
  getCurrentSelectedVendor,
  // getTableData,
} from "../../store/actions";
import { IoIosAddCircle } from "react-icons/io";

const AddBillsForm = (props) => {
  const history = useHistory();

  // const [itemRate, setItemRate] = useState("");
  // const [edit, setEdit] = useState(false);
  // const handleEdit = () => {
  //   setEdit(!edit);
  // };
  const [rowsData, setRowsData] = useState([]);
  const [reload, setReload] = useState(false);
  const [subTotalAmount, setSubTotalAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [shipChrg, setShipChrg] = useState(0);
  const [adjus, setAdjus] = useState(0);
  // to get customer list
  const [data, setData] = useState([]);
  const [cusData, setCusData] = useState([]);
  const [IsSaving, setIsSaving] = useState(false);

  const [tcsList, setTcsData] = useState([]);

  const [tcsRate, setTcsRate] = useState(null);
  const [tcsTax, setTcsTax] = useState(null);
  const [tcsAmount, setTcsAmount] = useState(0);

  function changeStatus(e) {
    //setTcs(e.target.value);
    //console.log("tcs:", tcs);
    var index = e.nativeEvent.target.selectedIndex;
    var id = e.nativeEvent.target[index].value;
    console.log("selected text0", e.nativeEvent.target[index].value);
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tcs/${id}`;
    let resp = apiGet(Url);
    var taxrate = 0;
    resp.then((resp) => {
      console.log("tcs data", resp.response.data.data);

      taxrate = resp.response.data.data.taxRate;
      setTcsRate(taxrate);
      setTcsTax(resp.response.data.data.taxName);
      const rowsInput = [...rowsData];

      const sum = rowsInput
        .map((item) => parseInt(item.itemAmount))
        .reduce((prev, curr) => prev + curr, 0);
      setSubTotalAmount(sum);
      const tax = (sum * (taxrate || 0)) / 100;
      setTcsAmount(tax.toFixed(2));
      setTotalAmount(
        parseInt(subTotalAmount ? subTotalAmount : 0) +
          parseInt(shipChrg ? shipChrg : 0) +
          parseInt(adjus ? adjus : 0) +
          parseFloat(tax ? tax.toFixed(2) : 0)
      );
    });
  }

  useEffect(() => {
    function getData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl + `api/v1/vendors/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setData(resp.response.data.data);
      });
    }
    function getCustomerData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url =
        BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setCusData(resp.response.data.data);
      });
    }
    getData();
    getCustomerData();
  }, []);
  const custOptions = data.map((cust) => ({
    value: cust.vendorId,
    label: cust.vendorDisplayName,
  }));

  // const customAddCustomerOption = {
  //   label: (
  //     <Link to="addvendor">
  //       <span style={{ color: "#408dfb" }}>
  //         <span style={{ marginRight: "8px", color: "#408dfb" }}>
  //           <IoIosAddCircle color="#408dfb" />
  //         </span>{" "}
  //         Add Vendor
  //       </span>
  //     </Link>
  //   ),
  //   value: "addvendor",
  //   onClick: () => {
  //     console.log("Add Customer Clicked");
  //     history.push("addvendor");
  //   },
  //   isFixed: true,
  // };

  // const options = [...custOptions, customAddCustomerOption];

  // custom add button in Select
  const CustomButton = ({ onClick }) => (
    <Link to="addvendor">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Vendor
      </button>
    </Link>
  );

  const DropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomButton onClick={console.log("clicked")} />
      </div>
    );
  };

  const customComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: DropdownMenu, // Use your custom dropdown menu
  };

  const [tax, setTax] = useState([]);
  useEffect(() => {
    function getTax() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/taxes`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setTax(resp.response.data.data);
      });
    }
    getTax();
  }, []);

  useEffect(() => {
    function getTcsData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/settings/tcs`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setTcsData(resp.response.data.data);
      });
    }
    getTcsData();
  }, []);

  const [itemdata, setItemData] = useState([]);
  useEffect(() => {
    function getItemData() {
      const organisationId = localStorage.getItem("organisationId");
      const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/org/${organisationId}`;
      let resp = apiGet(Url);
      resp.then((resp) => {
        console.log("item Data", resp.response.data.data);
        setItemData(resp.response.data.data);
      });
    }
    getItemData();
  }, []);

  const addTableRows = () => {
    const rowsInput = {
      itemId: 0,
      itemName: "",
      itemDescription: "",
      itemAccountGroupId: 0,
      itemQuantity: "",
      itemUnit: "",
      itemRate: 0,
      itemDiscountType: 1, // 1=percent, 2= amt
      itemDiscountValue: 0,
      itemTaxId: 0,
      itemTaxPercent: 0,
      itemCustomerId: 0,
      itemIsBillable: 0,
      itemAmount: 0,
    };
    setRowsData([...rowsData, rowsInput]);
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    const sum = rows
      .map((item) => parseInt(item.amount))
      .reduce((prev, curr) => prev + curr, 0);
    setSubTotalAmount(sum);
    const tax = (sum * (tcsRate || 0)) / 100;
    setTcsAmount(tax.toFixed(2));
    setTotalAmount(
      parseInt(subTotalAmount) +
        parseInt(shipChrg) +
        parseInt(adjus) +
        parseFloat(tax.toFixed(2))
    );
  };

  // const handleTableChange = (index, evnt) => {
  //   console.log("in handle change", index, evnt);
  //   const { name, value } = evnt.target;
  //   const rowsInput = [...rowsData];
  //   rowsInput[index][name] = value;
  //   rowsInput[index]["itemAmount"] =
  //     rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];
  //   setRowsData(rowsInput);
  //   const sum = rowsInput
  //     .map((item) => parseInt(item.itemAmount))
  //     .reduce((prev, curr) => prev + curr, 0);
  //   setSubTotalAmount(sum);
  //   const totAmount =
  //     parseInt(subTotalAmount) +
  //     parseInt(shipChrg ? shipChrg : 0) +
  //     parseInt(adjus ? adjus : 0);
  //   setTotalAmount(totAmount);
  //   const selectedItem = itemdata.find(
  //     (item) => item.itemId === parseInt(value)
  //   );
  //   if (selectedItem) {
  //     rowsInput[index]["itemRate"] = selectedItem.itemSalesSellingPrice;
  //   }
  // };

  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    rowsInput[index]["itemAmount"] =
      rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];
    setRowsData(rowsInput);
    console.log("row input", itemdata);
    const sum = rowsInput
      .map((item) => parseInt(item.itemAmount))
      .reduce((prev, curr) => prev + curr, 0);
    console.log(sum);
    setSubTotalAmount(sum);
    const tax = (sum * (tcsRate || 0)) / 100;
    setTcsAmount(tax.toFixed(2));
    const totAmount =
      parseInt(subTotalAmount ? subTotalAmount : 0) +
      parseInt(shipChrg ? shipChrg : 0) +
      parseInt(adjus ? adjus : 0) +
      parseFloat(tcsAmount ? tcsAmount : 0);
    setTotalAmount(totAmount);
    if (name === "itemId") {
      const selectedItem = itemdata.find(
        (item) => item.itemId === parseInt(value)
      );
      if (selectedItem) {
        rowsInput[index]["itemRate"] = selectedItem.itemSalesSellingPrice;
      }
    }
  };

  const onHandleBlur = (index, e) => {
    const rowsInput = [...rowsData];
    rowsInput[index]["itemAmount"] =
      rowsInput[index]["itemQuantity"] * rowsInput[index]["itemRate"];
    setRowsData(rowsInput);
    const totAmount =
      parseInt(subTotalAmount) +
      parseInt(shipChrg ? shipChrg : 0) +
      parseInt(adjus ? adjus : 0) +
      parseFloat(tcsAmount ? tcsAmount : 0);
    setTotalAmount(totAmount);
  };
  const handleCancel = () => {
    setTimeout(() => {
      history.push("/bills");
    }, 1000);
  };

  // item custom button
  const CustomItemButton = ({ onClick }) => (
    <Link to="/additems/add">
      <button style={{ color: "#408dfb" }} className="custom-button">
        <span style={{ marginRight: "8px", color: "#408dfb" }}>
          <IoIosAddCircle color="#408dfb" />
        </span>{" "}
        Add Item
      </button>
    </Link>
  );
  const ItemDropdownMenu = (props) => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="react-dropdown">
        {children}
        <CustomItemButton onClick={console.log("clicked")} />
      </div>
    );
  };
  const customItemComponents = {
    // DropdownIndicator: null, // Hide the default dropdown indicator
    Menu: ItemDropdownMenu, // Use your custom dropdown menu
  };
  const reactSelectOptions = itemdata.map((items) => ({
    value: items.itemId,
    label: items.itemName,
  }));
  const accountGroupOptions = [
    { value: "1", label: "Advance tax" },
    { value: "2", label: "Employee Advance" },
    { value: "3", label: "Tax Credit" },
  ];
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Formik
        initialValues={{
          deliv: "1",
          addretinvoice: false,
          vendorname: "",
          branch: "",
          billnumber: "",
          ordernumber: "",
          billdate: "",
          duedate: "",
          paymentterms: "",
          shippingCharge: "",
          adjustment: "",
          termsandcondition: "",
          import: "",
          tcsTaxId: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.vendorname) {
            errors.vendorname = "* Required";
          }

          if (!values.ordernumber) {
            errors.ordernumber = "* Required";
          }
          if (!values.billdate) {
            errors.billdate = "* Required";
          }
          if (!values.duedate) {
            errors.duedate = "* Required";
          }
          if (!values.paymentterms) {
            errors.paymentterms = "* Required";
          }
          return errors;
        }}
        onSubmit={(values) => {
          setIsSaving(true);
          let orgId = localStorage.getItem("organisationId");
          let url = BaseUrl.apiUrl.baseUrl + "api/v1/bills";
          let body = {
            billVendorId: values.vendorname,
            billGstTreatmentId: 0,
            billSourceOfSupplyId: 0,
            billDestinationOfSupplyId: 0,
            billOrderNumber: values.ordernumber,
            billDate: values.billdate,
            billDueDate: values.duedate,
            billPaymentTerms: values.paymentterms,
            billIsReverseChargeApplicable: Number,
            billDiscountLevelType: "",
            billSubTotal: subTotalAmount,
            billDiscountType: 0,
            billDiscountPercentOrAmount: 0,
            billDiscountAmount: 0,
            billAdjustment: values.adjustment,
            billTaxAtSourceType: "",
            billTaxAtSourceId: Number,
            billTaxAtSourcePercent: Number,
            billRoundOff: Number,
            billTotal: totalAmount,
            billCustomerNotes: "",
            billTermsAndConditions: values.termsandcondition,
            //billVendorName: values.vendorname,
            billVendorContactNumber: "",
            billTcsTaxId: values.tcsTaxId,
            billTcsTaxPercent: tcsRate,
            billTcsTaxAmount: tcsAmount,
            billTcsTax: tcsTax,
            organizationId: orgId,
            branchId: 0,
            addedByUser: 1,
            editByUser: 1,
            billItemsList: rowsData,
          };
          let resp = apiPost(url, body);
          resp.then((resp) => {
            setReload(false);
            switch (resp.response.data.code) {
              case 200:
                setIsSaving(false);
                toast.success(resp.response.data.data);
                setTimeout(() => {
                  history.push("/bills");
                  // useHistory().push("/customerlist");
                }, 1000);

                break;
              default:
                setIsSaving(false);
                toast.error(resp.response.data.data);
            }
          });
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="formik" id="addbills">
            <Row>
              <Col md={2}>
                <label className="formik-label"> Vendor Name</label>
              </Col>
              <Col md={3} className="">
                {/* <Field
                  className="formik-input"
                  as="select"
                  name="vendorname"
                  onChange={handleChange}
                  value={values.vendorname}
                >
                  <option value="" defaultValue disabled>
                    Select Vendor
                  </option>
                  {data.map((datas, index) => {
                    return (
                      <option value={datas.vendorId}>
                        {datas.vendorDisplayName}
                      </option>
                    );
                  })}
                </Field> */}
                <Select
                  options={custOptions}
                  components={customComponents}
                  name="vendorname"
                  placeholder="Select Vendor"
                  value={
                    custOptions
                      ? custOptions.find(
                          (option) => option.value === data.vendorname
                        )
                      : ""
                  }
                  onChange={(option) => {
                    setFieldValue({ vendorname: option.value });
                    if (option.value == "addvendor") {
                      console.log(true);
                      history.push("/addvendor");
                      // setPushToCustomer(true);
                    }
                  }}
                />
                {/*<div>
                  <i className="bx bx-search"></i>
                </div>*/}
                {/* <ErrorMessage
                  style={{ color: "red" }}
                   component="div"
                  name="vendorname"
                /> */}
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label"> Branch</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  as="select"
                  name="branch"
                  onChange={handleChange}
                  value={values.branch}
                >
                  <option value="" defaultValue disabled>
                    select a Branch
                  </option>
                  <option value="ho">Head Office</option>
                </Field>
              </Col>
            </Row>
            {/*<Row>
              <Col md={2}>
                <label className="formik-label"> Bill Number</label>
              </Col>
              <Col md={10} className="formik-input-search">
                <Field
                  className="formik-input"
                  type="text"
                  name="billnumber"
                  onChange={handleChange}
                  value={values.billnumber}
                ></Field>
                {/* <div>
                  <i class="bx bx-cog"></i>
                </div> */}
            {/* <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="billnumber"
                />
              </Col>
            </Row>*/}
            <Row>
              <Col md={2}>
                <label className="formik-label"> Order Number</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="text"
                  name="ordernumber"
                  onChange={handleChange}
                  value={values.ordernumber}
                ></Field>
                <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="ordernumber"
                />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label className="formik-label">Bill Date</label>
              </Col>
              <Col md={10}>
                <Field
                  className="formik-input"
                  type="date"
                  name="billdate"
                  onChange={handleChange}
                  value={values.billdate}
                  min={new Date().toISOString().split("T")[0]}
                ></Field>
                <ErrorMessage
                  style={{ color: "red" }}
                  component="div"
                  name="billdate"
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label className="formik-label"> Due Date</label>
                  </Col>
                  <Col md={8}>
                    <Field
                      className="formik-input formik-inputrow"
                      type="date"
                      name="duedate"
                      onChange={handleChange}
                      value={values.duedate}
                      min={values?.billdate}
                    ></Field>
                    <ErrorMessage
                      style={{ color: "red" }}
                      component="div"
                      name="duedate"
                    />
                    <div>
                      <label className="retinvoice mt-2">
                        <Field
                          type="checkbox"
                          name="addretinvoice"
                          onChange={handleChange}
                        />

                        <span className="ml-2">
                          This transaction is applicable for reverse charge
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label className="formik-label"> Payment Terms</label>
                  </Col>
                  <Col md={8}>
                    <Field
                      className="formik-input formik-inputrow"
                      as="select"
                      name="paymentterms"
                      onChange={handleChange}
                      value={values.paymentterms}
                    >
                      <option value="" selected disabled>
                        select a payment
                      </option>
                      <option value="Net15">Net 15</option>
                      <option value="Net30">Net 30</option>
                      <option value="Net45">Net 45</option>
                      <option value="Net60">Net 60</option>
                      <option value="Dueendofmonth ">
                        Due end of the month
                      </option>
                      <option value="Dueendnextmonth ">
                        Due end of next month
                      </option>
                      <option value="Dueonreciept ">Due on Reciept</option>
                    </Field>
                    <ErrorMessage
                      style={{ color: "red" }}
                      component="div"
                      name="paymentterms"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <hr />

            {/* <BillTable /> */}

            <div className="">
              <div className="row clearfix">
                <div className="col-md-12 column">
                  <table
                    className="table table-bordered invoice-table"
                    id="tab_logic"
                  >
                    <thead>
                      <tr>
                        <th className="text-center"> # </th>
                        <th className="text-left"> ITEM NAME</th>
                        <th className="text-left"> ACCOUNT</th>
                        <th className="text-right"> QUANTITY</th>
                        <th className="text-right"> RATE</th>
                        <th className="text-right"> TAX</th>
                        <th className="text-right"> CUSTOMER DETAILS </th>
                        <th className="text-right"> AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rowsData.map((item, index) => (
                        <tr id="addr0" key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {/* <select
                              name="itemId"
                              value={item.itemId}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            >
                              <option value="" selected disabled>
                                Select Item
                              </option>
                              {itemdata.map((items, index) => (
                                <option key={items.itemId} value={items.itemId}>
                                  {items.itemName}
                                </option>
                              ))}
                            </select> */}
                            <Select
                              required
                              name="invItemId"
                              components={customItemComponents}
                              onChange={(selectedOption) => {
                                // Extract the selected item's ID from the selectedOption
                                const selectedItemId = selectedOption.value;
                                const rowsInput = [...rowsData];
                                rowsInput[index]["itemId"] = selectedItemId;
                                setRowsData(rowsInput);
                              }}
                              value={reactSelectOptions.find(
                                (option) =>
                                  option.value ==
                                  (rowsData[index]
                                    ? rowsData[index]["itemId"]
                                    : "")
                              )}
                              options={reactSelectOptions}
                            />
                          </td>
                          <td>
                            {/* <select
                              name="itemAccountGroupId"
                              id="tax"
                              value={item.itemAccountGroupId}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            >
                              <option value="" disabled selected>
                                Select a tax
                              </option>
                              <option value="1">Advance tax</option>
                              <option value="2">Employee Advance</option>
                              <option value="3">Tax Credit</option>
                            </select> */}
                            <Select
                              name="itemAccountGroupId"
                              // components={customItemComponents}
                              onChange={(selectedOption) => {
                                // Extract the selected item's ID from the selectedOption
                                const selectedValue = selectedOption.value;
                                const rowsInput = [...rowsData];
                                rowsInput[index]["itemAccountGroupId"] =
                                  selectedValue;
                                setRowsData(rowsInput);
                              }}
                              value={accountGroupOptions.find(
                                (option) =>
                                  option.value ===
                                  (rowsData[index]
                                    ? rowsData[index]["itemAccountGroupId"]
                                    : "")
                              )}
                              options={accountGroupOptions}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              name="itemQuantity"
                              value={item.itemQuantity}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                              onBlur={(e) => onHandleBlur(index, e)}
                              className="form-control"
                              placeholder="1.00"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              name="itemRate"
                              value={item.itemRate}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                              onBlur={(e) => onHandleBlur(index, e)}
                              className="form-control"
                              placeholder="0.00"
                            />
                          </td>

                          <td>
                            <select
                              name="itemTaxId"
                              id="tax"
                              value={item.itemTaxId}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                            >
                              <option value="0" defaultValue disabled>
                                Select Tax
                              </option>
                              {tax.map((items, index) => (
                                <option value={items.id}>
                                  {items.taxName} - {items.taxRate} %
                                </option>
                              ))}
                            </select>
                          </td>
                          <td width="15%">
                            <div className="table-inputs">
                              <div>
                                <select
                                  name="itemCustomerId"
                                  id="perc"
                                  value={item.itemCustomerId}
                                  onChange={(evnt) =>
                                    handleTableChange(index, evnt)
                                  }
                                >
                                  <option value="" selected disabled>
                                    Select Customer
                                  </option>
                                  {cusData.map((cu, index) => {
                                    return (
                                      <option value={cu.customerId}>
                                        {cu.customerDisplayName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </td>
                          <td width="15%">
                            <input
                              type="number"
                              name="itemAmount"
                              value={item.itemAmount}
                              onChange={(evnt) =>
                                handleTableChange(index, evnt)
                              }
                              className="form-control"
                              disabled
                            />
                          </td>
                          <td className="text-center">
                            <span
                              className="btn btn-outline-danger"
                              onClick={(e) => deleteTableRows(index, e)}
                            >
                              x
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Row>
                    <Col md={6} className="table-left">
                      <div>
                        <span
                          onClick={addTableRows}
                          className="btn btn-default pull-left add-tablerow"
                        >
                          <i class="bx bxs-plus-circle"></i>
                          <span>Add Item</span>
                        </span>
                      </div>
                      <div className="customernotes">
                        <span>Customer Notes</span>
                        <div>
                          <textarea
                            name="customernotes"
                            rows="4"
                            cols="50"
                            placeholder="Looking forward for your business"
                            onChange={handleChange}
                            value={values.customernotes}
                          ></textarea>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="table-right">
                        <Row>
                          <Col className="details">
                            <div>
                              <span className="detail-head">Sub Total</span>
                            </div>
                            <div>
                              <span>{subTotalAmount}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <div>
                              <span className="detail-head">
                                Shipping Charges
                              </span>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="input-tooltip">
                              <input
                                type="text"
                                name="shippingCharge"
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setShipChrg(values.shippingCharge);
                                  let sum =
                                    parseInt(subTotalAmount) +
                                    parseInt(
                                      values.shippingCharge
                                        ? values.shippingCharge
                                        : 0
                                    ) +
                                    parseInt(
                                      values.adjustment
                                        ? values.shippingCharge
                                        : 0
                                    ) +
                                    parseFloat(tcsAmount ? tcsAmount : 0);
                                  setTotalAmount(sum);
                                }}
                                value={values.shippingCharge}
                              />
                              <i class="bx bx-help-circle"></i>
                            </div>
                          </Col>
                          <Col md={3} className="text-right">
                            <div>
                              <span>{values.shippingCharge}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <div>
                              <span className="detail-head">Adjustment</span>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="input-tooltip">
                              <input
                                type="text"
                                name="adjustment"
                                onChange={handleChange}
                                value={values.adjustment}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setAdjus(values.adjustment);
                                  let sum =
                                    parseInt(subTotalAmount) +
                                    parseInt(
                                      values.shippingCharge
                                        ? values.shippingCharge
                                        : 0
                                    ) +
                                    parseInt(
                                      values.adjustment ? values.adjustment : 0
                                    ) +
                                    parseFloat(tcsAmount ? tcsAmount : 0);
                                  setTotalAmount(sum);
                                }}
                              />
                              <i class="bx bx-help-circle"></i>
                            </div>
                          </Col>
                          <Col md={3} className="text-right">
                            <div>
                              <span>{values.adjustment}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <div>
                              <span className="detail-head">Tcs</span>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="input-tooltip">
                              <select
                                name="tcsTaxId"
                                placeholder="select a
                              tax"
                                onChange={(e) => {
                                  changeStatus(e);
                                  handleChange(e);
                                }}
                                value={values.tcsTaxId}
                              >
                                <option value="" selected>
                                  Select a Tax
                                </option>
                                {tcsList.map((tcs) => (
                                  <option value={tcs.id} key={tcs.id}>
                                    {tcs.taxName} - {tcs.taxRate} %
                                  </option>
                                ))}
                              </select>
                              <i class="bx bx-help-circle"></i>
                            </div>
                          </Col>
                          <Col md={3} className="text-right">
                            <div>
                              <span>{tcsAmount}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="details">
                            <div>
                              <span className="total">Total</span>
                            </div>

                            <div>
                              <span className="total">{totalAmount}</span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <hr />
            <Row>
              <Col md={12}>
                {/* <TermsAndConditions invoice={values.addretinvoice} /> */}
                <div className=" table-bottom">
                  <div className="row clearfix">
                    <div className="col-md-8 termsandcondition">
                      <span>Terms & Conditions</span>
                      <div className="mt-2">
                        <Field
                          className="formik-textarea terms-textarea"
                          as="textarea"
                          name="termsandcondition"
                          onChange={handleChange}
                          value={values.termsandcondition}
                        ></Field>
                      </div>
                    </div>
                    <div className="col-md-4 attachfiles">
                      <span>Attach File(s) to Estimate</span>
                      <div className="attach">
                        <Field
                          name="import"
                          type="file"
                          onChange={handleChange}
                          value={values.import}
                        ></Field>
                      </div>
                      <div>
                        <span style={{ fontSize: "10px" }}>
                          You can upload a maximum of 5 files, 5MB each
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
      <div className="bottom-bar">
        <Row>
          <Col md={4}>
            <Row>
              <Col md={4}>
                <button className="btn btn-left ml-3" onClick={handleCancel}>
                  Cancel
                </button>
              </Col>
              <Col md={4}>
                <button type="reset" className="btn btn-left" form="addbills">
                  Clear
                </button>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="printView">
            <span className="print">{/* Print or Preview */}</span>
          </Col>
          <Col md={4}>
            <button
              className="btn btn-right float-right mr-3"
              type="submit"
              form="addbills"
              disabled={IsSaving}
            >
              {reload ? (
                <img className="loader" src={loader} alt="loading..." />
              ) : (
                " Save"
              )}
            </button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default AddBillsForm;
