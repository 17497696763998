import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { BaseUrl } from "../../components/config/BaseUrl";
import { Link, useHistory } from "react-router-dom";
import { apiGet, apiPost, apiPut } from "../../components/config/apiConfig";
import classnames from "classnames";
import BillingAddress from "./billingDetails";
import loader from "../../assets/loader/load.gif";
import AddInvoice from "./addInvoice";
import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";
import AddTable from "./addTable";
import BottomBar from "./bottomBar";
import { connect } from "react-redux";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

// Pages Component
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withNamespaces } from "react-i18next";
import {
  getCurrentSelectedCustomer,
  getCurrentSelectedItem,
  addTableData,
  updateRowTotal,
  calculateShippingCharge,
  calculateAdjustmentCharge,
  deleteTableRow,
  populateTableData,
  clearCustomerData,
  clearTableData,
  // getTableData,
} from "../../store/actions";
// import { ToastContainer, toast } from "react-toastify/dist/react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRef } from "react";
import { addDays, addMonths, endOfMonth, format, setDate } from "date-fns";

const Invoice = (props) => {
  const form = useRef(null);
  const history = useHistory();
  var formType = props.match.params.type;
  var id = props.match.params.id;
  const [itemData, setItemData] = useState([]);
  const [invoicedata, setinvoicedata] = useState({});
  const [rowsData, setRowsData] = useState([
    {
      invItemId: "",
      invItemQuantity: 1,
      invItemRate: "",
      invItemUnit: "",
      invItemDiscountValue: 0,
      invItemDiscountType: "",
      invItemTaxPercent: "",
      invItemAmount: "",
    },
  ]);
  const [state, setState] = useState([]);
  const [gstTreatment, setgsttreatment] = useState([]);
  const [paymentterms, setpaymentterms] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [customerList, setCustomerList] = useState([]);
  const [reload, setReload] = useState(false);
  const [billingaddressid, setbillingaddressid] = useState();
  const [shippingaddressid, setshippingaddressid] = useState();
  const [checkInWarehouseId, setcheckInWarehouseId] = useState({
    id: 0,
    name: "",
  });
  var today = new Date();
  var formatedDate =
    today.getMonth() + 1 + "-" + today.getDate() + "-" + today.getFullYear();
  const clearForm = () => {
    form.current.reset(); //this will reset all the inputs in the form
    props.clearCustomerData();
    props.clearTableData();
  };
  const currentDate = format(new Date(), "yyyy-MM-dd");

  const [data, setData] = useState({
    invoiceCustomerId: "",
    invoiceCustomerName: "",
    invoiceEmailTo: "",
    branch: 0,
    invoiceNumber: "",
    invoiceDate: currentDate,
    invoiceOrderNumber: "",
    invoiceSalesPersonId: 0,
    invoicePaymentTerms: "",
    invoicePaymentTermsId: "",
    gstNum: "",
    invoiceDueDate: "",
    invoicePlaceOfSupplyId: "",
    invoiceGstTreatmentId: "",
    subject: "",
    invoiceCustomerNotes: "",
    invoiceTermsAndConditions: "",

    // mobNumber1: "",
    // sal: "",
    // custFn: "",
    // custLn: "",
    // company: "",
    // mobNumber: "",
    // remarks: "",
  });

  const addTableRows = () => {
    const rowsInput = {
      invItemName: "",
      invItemQuantity: 0,
      invItemRate: "",
      invItemDiscountValue: 0,
      invItemDiscountType: "",
      invItemTaxPercent: "",
      invItemAmount: "",
    };
    setRowsData([...rowsData, rowsInput]);
    props.addTableData();
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    props.deleteTableRow(index);
  };

  const handleTableChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;

    props.getCurrentSelectedItem(value);

    // props.updateRowTotal(index, evnt);
    setRowsData(rowsInput);
    // addTableRows();
  };

  const onHandleBlur = (index, e) => {};
  function handle(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }
  function handleDueDate(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    updateDueDate();
  }
  function handleCustomerChange(e) {
    props.getCurrentSelectedCustomer(e.target.value);
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
    updateDueDate();
    // setData({
    //   ...data,
    //   invoicePaymentTermsId: props.selectedCustomer?.customerPaymentTermsId,
    // });
  }
  const handleChangeWarehouse = (e) => {
    setcheckInWarehouseId({
      id: e.value,
      name: e.label,
    });
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  function getState() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/states/1`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setState(resp.response.data.data);
      }
    });
  }
  function getGstTreatment() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/gst_treatments`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setgsttreatment(resp.response.data.data);
      }
    });
  }
  function getPaymentTerms() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/payment_terms`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      if (resp.response.data.code == 200) {
        setpaymentterms(resp.response.data.data);
      }
    });
  }
  function createInvoice() {
    setReload(true);
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/invoice";

    let body = {
      invoiceCustomerId: props.selectedCustomer.customerId,
      invoiceEmailTo:
        props.selectedCustomer.customerEmail != null
          ? props.selectedCustomer.customerEmail
          : data.invoiceEmailTo,
      invoiceEmailCc: "SERVER",
      invoiceBillingAddressId: billingaddressid,
      invoiceShippingAddressId: shippingaddressid,
      invoiceCustomerRemarks: "NO",
      invoiceGstTreatmentId: parseInt(data.invoiceGstTreatmentId),
      invoicePlaceOfSupplyId: parseInt(data.invoicePlaceOfSupplyId),
      invoiceOrderNumber: parseInt(data.invoiceOrderNumber),
      invoiceDate: data.invoiceDate,
      invoicePaymentTerms: data.invoicePaymentTerms,
      invoicePaymentTermsId: data.invoicePaymentTermsId.length
        ? data.invoicePaymentTermsId
        : props.selectedCustomer?.customerPaymentTermsId,

      invoiceDueDate: data.invoiceDueDate,
      invoiceSalesPersonId: parseInt(data.invoiceSalesPersonId),
      invoiceConfirmPaymentReceived: 88,
      invoicePaymentMode: "CASH",
      invoiceDepositTo: "KK",
      invoiceSubTotal: props.subTotal ? props.subTotal : 0,
      invoiceShippigCharges: props.shippingCharge
        ? parseInt(props.shippingCharge)
        : 0,
      invoiceAdjustment: props.adjustment ? parseInt(props.adjustment) : 0,
      invoiceRoundOff: 77,
      invoiceTotal: props.total ? props.total : 0,
      invoiceCustomerNotes: data.invoiceCustomerNotes,
      invoiceTermsAndConditions: data.invoiceTermsAndConditions,
      invoiceCustomerName: props.selectedCustomer.customerDisplayName,
      invoiceCustomerShippingAddress: "",
      invoiceCustomerBillingAddress: "",
      invoiceCustomerContactNumber: props.selectedCustomer.customerWorkPhone,
      organizationId: orgId,
      invoiceWarehouseId: parseInt(checkInWarehouseId.id),
      invoiceWarehouseName: checkInWarehouseId.name,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
      invoiceItemsList: props.tableRowData,
    };

    let resp = apiPost(url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            setReload(false);
            history.push("/view-invoice");
          }, 1000);
          break;

        default:
          setReload(false);
          toast.error(resp.response.data.data);
      }
    });
  }
  function updateInvoice() {
    setReload(true);
    let url = BaseUrl.apiUrl.baseUrl + `api/v1/invoice/${id}`;

    let body = {
      invoiceCustomerId: invoicedata.invoiceCustomerId,
      invoiceEmailTo: data.invoiceEmailTo,
      invoiceEmailCc: "SERVER",
      invoiceBillingAddressId: invoicedata.invoiceBillingAddressId,
      invoiceShippingAddressId: invoicedata.invoiceShippingAddressId
        ? invoicedata.invoiceShippingAddressId
        : 0,
      invoiceCustomerRemarks: "NO",
      invoiceGstTreatmentId: parseInt(data.invoiceGstTreatmentId),
      invoicePlaceOfSupplyId: parseInt(data.invoicePlaceOfSupplyId),
      invoiceOrderNumber: parseInt(data.invoiceOrderNumber),
      invoiceDate: data.invoiceDate,
      invoicePaymentTerms: data.invoicePaymentTerms,
      invoicePaymentTermsId: data.invoicePaymentTermsId.length
        ? data.invoicePaymentTermsId
        : props.selectedCustomer?.customerPaymentTermsId,
      invoiceDueDate: data.invoiceDueDate,
      invoiceSalesPersonId: parseInt(data.invoiceSalesPersonId),
      invoiceConfirmPaymentReceived: 88,
      invoicePaymentMode: "CASH",
      invoiceDepositTo: "",
      invoiceSubTotal: props.subTotal ? props.subTotal : 0,
      invoiceShippigCharges: props.shippingCharge
        ? parseInt(props.shippingCharge)
        : 0,
      invoiceAdjustment: props.adjustment ? parseInt(props.adjustment) : 0,
      invoiceRoundOff: 0,
      invoiceTotal: props.total ? props.total : 0,
      invoiceCustomerNotes: data.invoiceCustomerNotes,
      invoiceTermsAndConditions: data.invoiceTermsAndConditions,
      invoiceCustomerName: props.selectedCustomer.customerDisplayName,
      invoiceCustomerShippingAddress: "",
      invoiceCustomerBillingAddress: "",
      invoiceCustomerContactNumber: invoicedata.invoiceCustomerContactNumber,
      invoiceWarehouseId: parseInt(checkInWarehouseId.id),
      invoiceWarehouseName: checkInWarehouseId.name,
      editByUser: 1,
      invoiceItemsList: props.tableRowData,
    };
    let resp = apiPut(url, body);
    resp.then((resp) => {
      setReload(false);

      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          setTimeout(() => {
            history.push(`/singleinvoice/${id}`);
          }, 1000);
          break;
        case 500:
          toast.error(resp.response.data.status);
          break;
        default:
          toast.error(resp.response.data.status);
      }
    });
  }
  const updateDueDate = (e) => {
    if (props.selectedCustomer?.customerPaymentTermsId) {
      let startDate = new Date(data.invoiceDate);
      let date = e?.target?.value.length
        ? e?.target?.value
        : data.invoicePaymentTermsId.length
        ? data.invoicePaymentTermsId
        : props.selectedCustomer?.customerPaymentTermsId
        ? props.selectedCustomer?.customerPaymentTermsId
        : data.invoicePaymentTermsId;
      let term =
        date == "1"
          ? 15
          : date == "2"
          ? 30
          : date == "3"
          ? 45
          : date == "4"
          ? 60
          : "";
      if (date == "8") return;
      let updatedValue = {};
      updatedValue = {
        invoiceDueDate: format(
          date == "5"
            ? endOfMonth(startDate)
            : date == "6"
            ? endOfMonth(addMonths(startDate, 1))
            : addDays(startDate, term),
          "yyyy-MM-dd"
        ),
      };
      setData((prevData) => ({
        ...prevData,
        ...updatedValue,
      }));
    }
  };
  useEffect(() => {
    if (props.selectedCustomer?.customerPaymentTermsId) {
      updateDueDate();
    }
  }, [props.selectedCustomer?.customerPaymentTermsId, data.invoiceDate]);

  function handleSubmit(e) {
    if (formType == "add") {
      createInvoice();
    } else if (formType == "edit") {
      updateInvoice();
    }
  }

  function getItemData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/items/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setItemData(resp.response.data.data);
    });
  }
  function getData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setCustomerList(resp.response.data.data);
    });
  }
  function getInvoiceDataById() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/invoice/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      var datas = resp.response.data.data;

      setData(datas);
      setinvoicedata(datas);
      props.populateTableData(
        datas.invoiceSubTotal,
        datas.invoiceShippigCharges,
        datas.invoiceAdjustment,
        datas.invoiceTotal,
        datas.invoiceItemsList
      );
      setRowsData(
        datas.invoiceItemsList.map((items) => {
          return {
            invItemId: items.invInvoiceId,
            invItemName: items.invItemName,
            invItemQuantity: items.invItemQuantity,
            invItemRate: items.invItemRate,
            invItemDiscountValue: items.invItemDiscountValue,
            invItemDiscountType: items.invItemDiscountType,
            invItemTaxPercent: items.invItemTaxPercent,
            invItemAmount: items.invItemAmount,
          };
        })
      );
      props.getCurrentSelectedCustomer(datas.invoiceCustomerId);
    });
  }
  const warehouse =
    props.selectedCustomer.warehouseList &&
    props.selectedCustomer.warehouseList.map((item) => {
      return {
        value: item.warehouseId,
        label: item.warehouseName,
      };
    });
  useEffect(() => {
    if (formType === "edit") {
      if (props.selectedCustomer.warehouseList?.length) {
        setcheckInWarehouseId({
          id: props.selectedCustomer.warehouseList[0].warehouseId,
          name: props.selectedCustomer.warehouseList[0].warehouseName,
        });
      }
    }
  }, [props.selectedCustomer]);

  // const handleQuantityZero = (e) => {
  //   if (e.target.value == 0) {
  //     toast.error(" Quantity is Empty !", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: false,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // };
  // const handleRateZero = (e) => {
  //   if (e.target.value == 0) {
  //     toast.error(" Rate is Empty !", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: false,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // };
  useEffect(() => {
    if (formType == "edit") {
      getInvoiceDataById();
    }
    getData();
    getState();
    getGstTreatment();
    getItemData();
    getPaymentTerms();
    return () => {
      form.current.reset(); //this will reset all the inputs in the form
      props.clearCustomerData();
      props.clearTableData();
    };
  }, []);
  useEffect(() => {
    if (formType == "add") {
      props.addTableData();
    }
  }, []);

  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>
                {formType == "add"
                  ? "Add New Invoice"
                  : formType == "edit"
                  ? "Edit Invoice"
                  : ""}
              </h6>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className="form"
                    onValidSubmit={(e) => handleSubmit(e)}
                    ref={form}
                  >
                    <Row>
                      <Col md={8} className="formLeft">
                        <Row className="mb-3">
                          <Col md={4}>
                            <label>Customer Name *</label>
                            <AvField
                              name="invoiceCustomerId"
                              type="select"
                              value={data.invoiceCustomerId}
                              onChange={(e) => handleCustomerChange(e)}
                              required
                            >
                              <option selected disabled value="">
                                Select a customer
                              </option>
                              {customerList.map((customer) => (
                                <option value={customer.customerId}>
                                  {customer.customerDisplayName}
                                </option>
                              ))}
                            </AvField>

                            <Link to="/customer/add">
                              <i class="bx bx-plus"></i>
                              {""}
                              <span className="addlinks">
                                Add a new customer
                              </span>
                            </Link>
                          </Col>
                          <Col md={4}>
                            <label>Warehouse *</label>
                            <Select
                              name="checkInWarehouseId"
                              onChange={handleChangeWarehouse}
                              options={warehouse}
                              value={warehouse?.filter(
                                (option) =>
                                  option.label === checkInWarehouseId.name
                              )}
                              defaultValue={"Select"}
                              required
                            />
                          </Col>
                          <Col md={4} className="text-right">
                            <h6>DUE AMOUNT TOTAL</h6>
                            <span className="total-amount">
                              {"₹" + props.total}
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={4}>
                            <label>Email to</label>
                            <AvField
                              name="invoiceEmailTo"
                              type="input"
                              placeholder="Enter email"
                              value={
                                props.selectedCustomer.customerEmail != null
                                  ? props.selectedCustomer.customerEmail
                                  : data.invoiceEmailTo
                              }
                              onChange={(e) => handle(e)}
                            ></AvField>
                          </Col>
                          <Col md={4}>
                            <label>Branch</label>
                            <AvField
                              name="branch"
                              type="select"
                              value={data.branch}
                              onChange={(e) => handle(e)}
                            >
                              <option value="0" selected disabled>
                                {" "}
                                Opine
                              </option>
                            </AvField>
                          </Col>

                          <Col md={4}>
                            <label>Invoice date</label>
                            <AvField
                              name="invoiceDate"
                              type="date"
                              placeholder="Select invoice date"
                              value={data.invoiceDate}
                              onChange={(e) => handle(e)}
                              required
                            ></AvField>
                          </Col>
                        </Row>
                        <hr />
                        <Row className="mb-4">
                          <Col md={4}>
                            <label>Terms of Pay</label>
                            <AvField
                              name="invoicePaymentTermsId"
                              type="select"
                              placeholder="&#x260E; Select Terms"
                              value={
                                data.invoicePaymentTermsId ||
                                props.selectedCustomer?.customerPaymentTermsId
                              }
                              onChange={(e) => {
                                handle(e);
                                updateDueDate(e);
                              }}
                              disabled={data.invoiceDate === ""}
                            >
                              <option value="" selected disabled>
                                {data.invoiceDate === ""
                                  ? "Select invoice date to enable"
                                  : " Select payment terms"}
                              </option>
                              {paymentterms.map((payment) => (
                                <option value={payment.paymentTermId}>
                                  {payment.paymentTermName}
                                </option>
                              ))}
                            </AvField>
                          </Col>
                          <Col md={4}>
                            <label>Due date</label>
                            <AvField
                              name="invoiceDueDate"
                              type="date"
                              placeholder="Select due date"
                              value={data.invoiceDueDate}
                              onChange={(e) => {
                                handleDueDate(e);
                              }}
                              disabled={data.invoicePaymentTermsId != "8"}
                              min={formType == "add" ? currentDate : ""}
                            ></AvField>
                          </Col>

                          <Col md={4}>
                            <label>Order Number</label>
                            <AvField
                              name="invoiceOrderNumber"
                              type="number"
                              placeholder="Enter order number"
                              value={data.invoiceOrderNumber}
                              onChange={(e) => handle(e)}
                            ></AvField>
                          </Col>
                        </Row>
                        {/* <Col md={6}>
                          <label>Rate / CBM</label>
                          <AvField
                            name="customerFixedQuoteRate"
                            type="number"
                            onChange={(e) => handle(e)}
                            value={data.customerFixedQuoteRate}
                            required
                          ></AvField>
                        </Col> */}
                        {/* <Row>
                          <Col md={4}>
                            <label>Subject</label>
                            <AvField
                              name="subject"
                              type="textarea"
                              value={data.subject}
                              onChange={(e) => handle(e)}
                            ></AvField>
                          </Col>
                        </Row> */}
                      </Col>
                      <Col md={4}>
                        <Row>
                          <Col md={12}>
                            <h6>Billing Address</h6>
                          </Col>
                          {/* <Col md={12}>
                            <Link>
                              <i class="bx bx-plus"></i> Add a new address
                            </Link>
                          </Col> */}
                          <Col md={12}>
                            {props.selectedCustomerId == "" ? (
                              <div className="selected-address">
                                Select a customer
                              </div>
                            ) : (
                              props.selectedCustomer?.customerAddresses?.map(
                                (address) =>
                                  address.addressType == 1 ? (
                                    <div className="selected-address">
                                      <p>{address.addressAttention}</p>
                                      <div className="sel-address">
                                        <p>
                                          {address.addressLine1}
                                          <br></br> {address.addressLine2}
                                          <br></br>
                                          {address.addressCity +
                                            ", " +
                                            address.addressState}
                                          <br></br> {address.addressCountryName}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                              )
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr />
                    <div className="row clearfix">
                      <div className="col-md-12 column">
                        <table
                          className="table table-bordered invoice-table"
                          id="tab_logic"
                        >
                          <thead>
                            <tr>
                              <th className="text-left"> ITEM DETAILS</th>
                              <th className="text-left"> QUANTITY</th>
                              <th className="text-right"> ITEM UNIT</th>
                              <th className="text-left"> RATE</th>
                              <th className="text-right"> DISCOUNT </th>
                              <th className="text-right"> AMOUNT</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.tableRowData?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <AvField
                                      type="text"
                                      name="invItemName"
                                      value={
                                        props.tableRowData[index]
                                          ? props.tableRowData[index][
                                              "invItemName"
                                            ]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        props.updateRowTotal(index, e)
                                      }
                                      placeholder="Eg: Item Name"
                                      required
                                    ></AvField>
                                    {/* <AvField
                                      type="select"
                                      required
                                      className="table-select"
                                      name="invItemName"
                                      onChange={(evnt) =>
                                        handleTableChange(index, evnt)
                                      }
                                      value={
                                        props.tableRowData[index]
                                          ? props.tableRowData[index][
                                              "invItemName"
                                            ]
                                          : ""
                                      }
                                     
                                    >
                                     
                                      <option value="" selected disabled>
                                        Select Items
                                      </option>
                                      {itemData.map((items) => (
                                        <option value={items.itemId}>
                                          {items.itemName}
                                        </option>
                                      ))}
                                    </AvField> */}
                                  </td>
                                  <td>
                                    <AvField
                                      type="text"
                                      name="invItemQuantity"
                                      value={
                                        props.tableRowData[index]
                                          ? props.tableRowData[index][
                                              "invItemQuantity"
                                            ]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        props.updateRowTotal(index, e)
                                      }
                                      // onBlur={(e) => handleQuantityZero(e)}
                                      // onClick={(e) => onHandleBlur(index, e)}
                                      className="form-control table-input"
                                      placeholder="Eg: 100"
                                      required
                                    ></AvField>
                                  </td>
                                  <td>
                                    <AvField
                                      type="select"
                                      name="invItemUnit"
                                      value={
                                        props.tableRowData[index]
                                          ? props.tableRowData[index][
                                              "invItemUnit"
                                            ]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        props.updateRowTotal(index, e)
                                      }
                                      // onBlur={(e) => handleRateZero(e)}
                                      className="form-control"
                                      placeholder="CBM / SQM"
                                    >
                                      {/* <option selected disabled>
                                        Select Units
                                      </option> */}
                                      <option value="" disabled>
                                        Select Unit
                                      </option>
                                      <option value="CBM">CBM</option>
                                      <option value="SQM">SQM</option>
                                    </AvField>
                                  </td>
                                  <td>
                                    <AvField
                                      type="text"
                                      name="invItemRate"
                                      value={
                                        props.tableRowData[index]
                                          ? props.tableRowData[index][
                                              "invItemRate"
                                            ]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        props.updateRowTotal(index, e)
                                      }
                                      // onBlur={(e) => handleRateZero(e)}
                                      className="form-control"
                                      placeholder="Eg: 0.00"
                                      required
                                    ></AvField>
                                  </td>
                                  <td width="10%">
                                    <div className="table-inputs d-flex align-items-center">
                                      <div>
                                        {" "}
                                        <AvField
                                          type="number"
                                          name="invItemDiscountValue"
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "invItemDiscountValue"
                                                ]
                                              : ""
                                          }
                                          onChange={(e) =>
                                            props.updateRowTotal(index, e)
                                          }
                                          className="form-control"
                                          placeholder="Eg: 0"
                                        ></AvField>
                                      </div>
                                      <div>
                                        <select
                                          name="invItemDiscountType"
                                          id="perc"
                                          value={
                                            props.tableRowData[index]
                                              ? props.tableRowData[index][
                                                  "invItemDiscountType"
                                                ]
                                              : "1"
                                          }
                                          onChange={(e) =>
                                            props.updateRowTotal(index, e)
                                          }
                                        >
                                          <option value="" selected disabled>
                                            select
                                          </option>
                                          <option value="1">%</option>
                                          <option value="2">Rs.</option>
                                        </select>
                                      </div>
                                    </div>
                                  </td>
                                  {/* <td>
                                    <select
                                      className="table-select"
                                      name="invItemTaxPercent"
                                      id="tax"
                                      disabled
                                      value={
                                        props.tableRowData[index]
                                          ? props.tableRowData[index][
                                              "invItemTaxPercent"
                                            ]
                                          : ""
                                      }
                                      onChange={(e) =>
                                        props.updateRowTotal(index, e)
                                      }
                                    >
                                      <option value="%" disabled selected>
                                        Select a tax
                                      </option>
                                    </select>
                                  </td> */}
                                  <td width="15%">
                                    <AvField
                                      disabled
                                      type="text"
                                      name="invItemAmount"
                                      value={
                                        props.tableRowData[index]
                                          ? props.tableRowData[index][
                                              "invItemAmount"
                                            ]
                                          : 0
                                      }
                                      className="form-control text-right"
                                    ></AvField>
                                  </td>

                                  <td className="text-center" width="10px">
                                    <span
                                      className=" btn-outline-danger"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      onClick={(e) => deleteTableRows(index, e)}
                                    >
                                      <i class="bx bx-trash"></i>
                                    </span>
                                    {/* <span
                                      className="btn btn-outline-danger"
                                      onClick={(e) => deleteTableRows(index, e)}
                                    >
                                      x
                                    </span> */}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <Row>
                          <Col md={6} className="table-left">
                            <div>
                              <button
                                type="button"
                                onClick={addTableRows}
                                className="btn btn-default pull-left add-tablerow"
                              >
                                <i class="bx bxs-plus-circle"></i>
                                <span>Add another line</span>
                              </button>
                              {/* <button
                      onClick={this.handleRemoveRow}
                      className="pull-right btn btn-default table-btn"
                    >
                      Clear all rows
                    </button> */}
                            </div>
                            <div className="customernotes">
                              <label>Customer Notes</label>
                              <AvField
                                name="invoiceCustomerNotes"
                                type="textarea"
                                value={data.invoiceCustomerNotes}
                                onChange={(e) => handle(e)}
                              ></AvField>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="table-right">
                              <Row>
                                <Col className="details">
                                  <div>
                                    <span className="detail-head">
                                      Sub Total
                                    </span>
                                  </div>
                                  <div>
                                    <span>{"₹" + props.subTotal}</span>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="d-flex align-items-center">
                                <Col md={4}>
                                  <div>
                                    <span className="detail-head">
                                      Shipping Charges
                                    </span>
                                  </div>
                                </Col>
                                <Col md={5}>
                                  <div className="input-tooltip">
                                    <AvField
                                      type="number"
                                      name="shippingCharge"
                                      onChange={(e) =>
                                        props.calculateShippingCharge(e)
                                      }
                                    ></AvField>
                                  </div>
                                </Col>
                                <Col md={3} className="text-right">
                                  <div>
                                    <span>
                                      {props.shippingCharge
                                        ? props.shippingCharge
                                        : 0}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={4}>
                                  <div>
                                    <span className="detail-head">
                                      Adjustment
                                    </span>
                                  </div>
                                </Col>
                                <Col md={5}>
                                  <div className="input-tooltip">
                                    <AvField
                                      type="number"
                                      name="adjustment"
                                      onChange={(e) =>
                                        props.calculateAdjustmentCharge(e)
                                      }
                                    ></AvField>
                                  </div>
                                </Col>
                                <Col md={3} className="text-right">
                                  <div>
                                    <span>
                                      {" "}
                                      {props.adjustment ? props.adjustment : 0}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              {/* <Row>
                      <Col md={4}>
                        <div>
                          <span className="detail-head">Tcs</span>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="input-tooltip">
                          <select name="selecttax" placeholder="select a tax">
                            <option value="%">Select a Tax</option>
                            <option value="rs">Rs.</option>
                          </select>
                          <i class="bx bx-help-circle"></i>
                        </div>
                      </Col>
                      <Col md={3} className="text-right">
                        <div>
                          <span>0.00</span>
                        </div>
                      </Col>
                    </Row> */}
                              <Row>
                                <Col className="details">
                                  <div>
                                    <span className="total">Total</span>
                                  </div>

                                  <div>
                                    <span className="total">
                                      {"₹" + props.total}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={6}>
                            <label>Terms & Conditions</label>
                            <AvField
                              name="invoiceTermsAndConditions"
                              type="textarea"
                              value={data.invoiceTermsAndConditions}
                              onChange={(e) => handle(e)}
                              placeholder="Enter your terms and conditons of your business to be displayed in your transactions"
                            ></AvField>
                          </Col>
                          {/* <Col md={2}>
                            <label>Attach file(s) to invoice</label>
                            <AvField
                              name="branch"
                              type="file"
                              value={data.branch}
                              onChange={(e) => handle(e)}
                            ></AvField>
                          </Col> */}
                        </Row>
                      </div>
                    </div>
                    <div className="bottom-bar">
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={4}>
                              <Link
                                to={
                                  formType == "add"
                                    ? "/view-invoice"
                                    : formType == "edit"
                                    ? `/singleinvoice/${id}`
                                    : ""
                                }
                              >
                                <button
                                  type="button"
                                  className="btn btn-left ml-3"
                                >
                                  Cancel
                                </button>
                              </Link>
                            </Col>
                            {formType == "add" ? (
                              <Col md={4}>
                                <button
                                  type="button"
                                  onClick={clearForm}
                                  className="btn btn-left"
                                >
                                  Clear
                                </button>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Col>
                        {/* <Col md={4} className="printView">
                          <span className="print">Print or Preview</span>
                        </Col> */}
                        <Col md={8}>
                          <button
                            type="submit"
                            className="btn btn-right float-right mr-3"
                            disabled={reload}
                          >
                            {reload ? (
                              <img
                                className="loader"
                                src={loader}
                                alt="loading..."
                              />
                            ) : formType == "add" ? (
                              "Save"
                            ) : formType == "edit" ? (
                              "Update"
                            ) : (
                              ""
                            )}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { selectedCustomerId, selectedCustomer } = state.Layout;
  const { selectedItemId, selectedItem } = state.ItemDetails;
  const { tableRowData, subTotal, shippingCharge, adjustment, total } =
    state.TableData;

  return {
    selectedCustomerId,
    selectedCustomer,
    selectedItemId,
    selectedItem,
    tableRowData,
    subTotal,
    shippingCharge,
    adjustment,
    total,
    // dataByIndex,
  };
};
export default connect(mapStatetoProps, {
  getCurrentSelectedCustomer,
  getCurrentSelectedItem,
  addTableData,
  updateRowTotal,
  calculateShippingCharge,
  calculateAdjustmentCharge,
  deleteTableRow,
  populateTableData,
  clearCustomerData,
  clearTableData,
  // getTableData,
})(Invoice);
