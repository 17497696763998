import { all } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import retainerSaga from "./retainerInvoice/saga";
import ItemSaga from "./items/saga";
import CustomerSaga from "./customerDetails/saga";
import InitialiseDataSaga from "./dataInitialise/saga";
import CommodityTableSaga from "./commodityTable/saga";
import PalletMergeSaga from "./palletmerge/saga";
import DashboardSaga from "./dashboard/saga";

//import PaymentRecievedSaga from "./paymentsRecieved/saga";
// import PaymentRecievedSaga from "./paymentsRecieved/saga";
// import { watchFetchTableData } from "./paymentsRecieved/saga";

//import TransferSaga from "./goodsTransfer/saga";

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    AuthSaga(),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    retainerSaga(),
    ItemSaga(),
    CustomerSaga(),
    InitialiseDataSaga(),
    CommodityTableSaga(),
    PalletMergeSaga(),
    DashboardSaga(),
    //PaymentRecievedSaga(),

    // PaymentRecievedSaga(),

    //TransferSaga(),
  ]);
}
