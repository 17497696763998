import {
  all,
  call,
  fork,
  takeEvery,
  put,
  takeLatest,
} from "redux-saga/effects";
import {
  ADD_PALLET_TO_CLOSE,
  SET_PALLET_TO_CLOSE,
  REQUEST_PALLET_TO_MERGE,
  SET_PALLET_TO_MERGE,
} from "./actionTypes";
import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";

function* getPalletToCloseData({ payload: data }) {
  try {
    let palletDataToClose = {};
    let palletStatus = "";
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/search_by_pallet/${data}`;
    let resp = apiGet(Url);
    yield resp.then((resp) => {
      palletDataToClose = resp.response.data.data;
      palletStatus = resp.response.data.status;
    });
    // if (palletStatus === "Success") {
    yield put({
      type: "SET_PALLET_TO_CLOSE",
      payload: palletDataToClose,
    });
    // }
    // if (palletStatus === "Error") {
    //   yield put({
    //     type: "SET_PALLET_TO_CLOSE",
    //     payload: {},
    //   });
    // }
  } catch (error) {}
}

function* getPalletToMergeData({ payload: data }) {
  try {
    let palletDataToMerge = {};
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/search_by_pallet/${data}`;
    let resp = apiGet(Url);
    yield resp.then((resp) => {
      palletDataToMerge = resp.response.data.data;
    });
    yield put({
      type: "SET_PALLET_TO_MERGE",
      payload: palletDataToMerge,
    });
  } catch (error) {}
}
export function* watchSetPalletDatatoClose() {
  yield takeLatest(ADD_PALLET_TO_CLOSE, getPalletToCloseData);
}
export function* watchSetPalletDatatoMerge() {
  yield takeLatest(REQUEST_PALLET_TO_MERGE, getPalletToMergeData);
}

function* PalletMergeSaga() {
  yield all([fork(watchSetPalletDatatoClose), fork(watchSetPalletDatatoMerge)]);
}

export default PalletMergeSaga;
