import React, { useMemo, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";
import Axios from "axios";
// import Table from "./Table";
import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import FilterComponent from "./FilterComponent";
import { Container } from "reactstrap";
function BinLocationList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [loadingData, setLoadingData] = useState(true);
  function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }

  function toPages(pages) {
    const results = [];

    for (let i = 1; i < pages; i++) {
      results.push(i);
    }

    return results;
  }

  const columns = [
    {
      name: "Bin Location",
      selector: (data) => data.binLocation,
      sortable: true,
    },
    {
      name: "Barcode",
      selector: (data) => data.barcode,
      sortable: true,
    },

    {
      name: "Using Purpose",
      selector: (data) => data.usingPurpose,
      sortable: true,
      // right: true,
    },
    {
      name: "Capacity",
      selector: (data) => data.capacity,
      sortable: true,
      // right: true,
    },
    {
      name: "Efficiency",
      selector: (data) => data.efficiency,
      sortable: true,
      // right: true,
    },
    {
      name: "Aisle",
      selector: (data) => data.aisle,
      sortable: true,
      // right: true,
    },
    {
      name: "Rack",
      selector: (data) => data.rack,
      sortable: true,
      // right: true,
    },
    {
      name: "Row",
      selector: (data) => data.row,
      sortable: true,
      // right: true,
    },
    {
      name: "Bin",
      selector: (data) => data.bin,
      sortable: true,
      // right: true,
    },

    {
      button: true,
      cell: (data) => (
        <div className="tablebtn">
          <Link to={`/editBinLocation/edit/${data.id}`}>
            <button>Edit</button>
          </Link>
          {/* <Link
            to={{
              pathname: `/singlecustomer/${data.customerId}`,
              // data: data.customerId, // your data array of objects
            }}
          >
            <button>View</button>
          </Link> */}
        </div>
      ),
    },
  ];
  function tConv24(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  }
  const getData = async (page) => {
    setLoading(true);
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/bin_location/org?org_id=${organisationId}&page=${page}&size=${perPage}&sort=id,desc&sort=binLocation,asc&searchText=${filterText}`;

    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      //setTotalRows(resp.response.data.data.totalElements);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    getData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/bin_location/org?org_id=${organisationId}&page=${page}&size=${newPerPage}&sort=id,desc&sort=binLocation,asc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setPerPage(newPerPage);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData(1);
  }, [filterText]);
  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Bin Location" />
        <div className="App">
          <div className="card">
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              subHeaderAlign="left"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}
export default BinLocationList;
