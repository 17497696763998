import React from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Button,
  Row,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default function VendorMails() {
  const [dropdownOpen, setOpen] = React.useState(false);
  const [dropdownOpenI, setOpenI] = React.useState(false);

  const toggle = () => setOpen(!dropdownOpen);
  const toggleI = () => setOpenI(!dropdownOpenI);

  return (
    <React.Fragment>
      <Row>
        <Col md={2} className="formLef ">
          <Col>
            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret>System Mails</DropdownToggle>
              <DropdownMenu>
                {/* <DropdownItem header>item 1</DropdownItem> */}
                {/* <DropdownItem disabled>item 2</DropdownItem> */}
                <DropdownItem>System mails</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>System mail history</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Col>
        <Col md={10} className="formRigt">
          <Col>
            <ButtonDropdown isOpenI={dropdownOpenI} toggleI={toggleI}>
              <DropdownToggle caret>Connect your mail account </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>Outlook</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Zoho mail </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Link with work account</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Col>
      </Row>
    </React.Fragment>
  );
}
