import {
  ADD_COMMODITY_TABLE_DATA,
  UPDATE_COMMODITY_ROW_TOTAL,
  DELETE_TABLE_ROW,
  REQUEST_CHECKOUT_DATA_BY_NUMBER,
  SET_CHECKOUT_TABLE_DATA,
  UPDATE_CHECKOUT_TABLE_DATA,
  CLEAR_COMMODITY_DATA,
  REQUEST_WAREHOUSE_BY_ID,
  SET_WAREHOUSE_DATA,
  ADD_COMMODITY_TABLE_OTHER_CHARGES,
  UPDATE_COMMODITY_OTHER_CHARGES,
  DELETE_OTHER_CHARGE_TABLE_ROW,
  SET_COMMODITY_TABLE_DATA,
  SET_COMMODITY_TABLE_OTHER_CHARGES,
  POPULATE_TABLE_ROW_DATA,
  POPULATE_OTHER_CHARGE_TABLE_ROW_DATA,
  VALIDATE_TABLE_DATA,
  ADD_HANDLING_CHARGE,
  SET_CUSTOMER_CHECKOUT_TABLE_DATA,
  SET_INVALID_CHCEKIN_MESSAGE,
  SET_CHECKOUT_DATA_BY_ID,
} from "./actionTypes";

export const addCommodityTableData = (itemData) => ({
  type: ADD_COMMODITY_TABLE_DATA,
  payload: itemData,
});

export const setCommodityTableData = (itemData) => ({
  type: SET_COMMODITY_TABLE_DATA,
  payload: itemData,
});
export const updateCommodityRowTotal = (index, value) => ({
  type: UPDATE_COMMODITY_ROW_TOTAL,
  payload: { index, value },
});

export const deleteTableRow = (index) => ({
  type: DELETE_TABLE_ROW,
  payload: index,
});
export const deleteOtherChargeTableRow = (index) => ({
  type: DELETE_OTHER_CHARGE_TABLE_ROW,
  payload: index,
});
export const requestCheckoutDataByNumber = (invNumber) => ({
  type: REQUEST_CHECKOUT_DATA_BY_NUMBER,
  payload: invNumber,
});
const setCheckOutTableData = (item) => ({
  type: SET_CHECKOUT_TABLE_DATA,
  payload: { item },
});
export const setCustomerCheckOutTableData = (item) => ({
  type: SET_CUSTOMER_CHECKOUT_TABLE_DATA,
  payload: { item },
});
export const updateCheckOutData = (value, index) => ({
  type: UPDATE_CHECKOUT_TABLE_DATA,
  payload: { value, index },
});
export const clearCommodityData = () => ({
  type: CLEAR_COMMODITY_DATA,
});
export const requestWarehouseData = (id) => ({
  type: REQUEST_WAREHOUSE_BY_ID,
  payload: id,
});
const setWarehouseData = (item) => ({
  type: SET_WAREHOUSE_DATA,
  payload: { item },
});
export const addCommodityTableOtherCharges = (itemData) => ({
  type: ADD_COMMODITY_TABLE_OTHER_CHARGES,
  payload: itemData,
});
export const setCommodityTableOtherCharges = () => ({
  type: SET_COMMODITY_TABLE_OTHER_CHARGES,
});

export const updateCommodityOtherCharges = (index, value) => ({
  type: UPDATE_COMMODITY_OTHER_CHARGES,
  payload: { index, value },
});
export const populateTableRowData = (value) => ({
  type: POPULATE_TABLE_ROW_DATA,
  payload: value,
});
export const populateOtherChargeTableRowData = (value) => ({
  type: POPULATE_OTHER_CHARGE_TABLE_ROW_DATA,
  payload: value,
});
export const validateTableData = () => ({
  type: VALIDATE_TABLE_DATA,
});
export const addHandlingCharge = (handlingData, type) => ({
  type: ADD_HANDLING_CHARGE,
  payload: { handlingData, type },
});
export const setInvalidCheckInMessage = (value) => ({
  type: SET_INVALID_CHCEKIN_MESSAGE,
  payload: value,
});
export const setCheckoutDataById = (value) => ({
  type: SET_CHECKOUT_DATA_BY_ID,
  payload: value,
});
