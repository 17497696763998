import React from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { useCallback } from "react";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet } from "../../components/config/apiConfig";
import FileSaver from "file-saver";

function StockReport() {
  const current = new Date();
  const [warehouseList, setwarehouseList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [typeRequired, setTypeRequired] = useState(false);
  const date = `${current.getFullYear()}-${
    current.getMonth() < 10
      ? `0${current.getMonth() + 1}`
      : current.getMonth() + 1
  }-${current.getDate() < 10 ? `0${current.getDate()}` : current.getDate()}`;
  const [searchenable, setsearchenable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [palletNumber, setPalletNumber] = useState(0);
  const [searchdata, setsearchdata] = useState({
    toDate: "",
    warehouseId: 0,
  });

  const [WarehouseId, setWarehouseId] = useState({
    id: 0,
    name: "",
  });
  const [CustomerId, setCustomerId] = useState({
    id: 0,
    name: "",
  });
  const [billingType, setBillingType] = useState({
    id: 0,
    name: "",
  });
  const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);
  const columnDefs = useMemo(
    () => [
      {
        field: "itemWarehouseName",
        headerName: "Warehouse",
        headerTooltip: "Warehouse",
      },
      {
        field: "itemCommodityName",
        headerName: "Commodity",
        headerTooltip: "Commodity",
      },
      {
        field: "itemInvoiceNumber",
        headerName: "Invoice No",
        width: 120,
        headerTooltip: "Invoice Number",
      },
      {
        field: "itemCustomerName",
        headerName: "Customer",
        headerTooltip: "Customer",
      },
      {
        field: "itemUom",
        headerName: "UOM",
        width: 100,
        headerTooltip: "Unit of Measure",
      },
      {
        field: "itemUnits",
        headerName: "Stocks",
        width: 120,
        headerTooltip: "Stock",
      },
      {
        field: "itemTotalInAllPackage",
        headerName: "Items",
        width: 120,
        headerTooltip: "Items",
      },
      {
        field: "itemPallet",
        headerName: "Pallet No",
        width: 120,
        headerTooltip: "Pallet Number",
      },
      {
        field: "itemTemperature",
        headerName: "Facility",
        width: 90,
        headerTooltip: "Facility",
      },
      {
        field: "itemHandlingInfo",
        headerName: "Handling Info",
        width: 120,
        headerTooltip: "Handling Info",
      },
      {
        field: "itemDateOfExpiry",
        headerName: "DOE",
        headerTooltip: "Date of Expiry",
        width: 130,
      },
      {
        field: "itemBatch",
        headerName: "Batch",
        headerTooltip: "Batch",
        width: 130,
      },
      {
        field: "itemTotalWeight",
        headerName: "Total Weight",
        headerTooltip: "Total Weight",
        width: 130,
      },
      {
        field: "itemWeightUom",
        headerName: "Weight UOM",
        width: 100,
        headerTooltip: "Weight (Unit of Measure)",
      },
      {
        field: "itemLength",
        headerName: "L",
        width: 70,
        headerTooltip: "Length",
      },
      {
        field: "itemBreadth",
        headerName: "W",
        width: 70,
        headerTooltip: "Width",
      },
      {
        field: "itemHeight",
        headerName: "H",
        width: 70,
        headerTooltip: "Height",
      },
      {
        field: "itemArea",
        headerName: "Area",
        width: 100,
        headerTooltip: "Area",
      },
      {
        field: "itemUnitsAvailable",
        headerName: "Available Stock",
        width: 100,
        headerTooltip: "Available Stock",
      },
      {
        field: "itemTotalInAllPackageAvailable",
        headerName: "Available Items",
        width: 100,
        headerTooltip: "Available Items",
      },
      {
        field: "itemUnitsCheckedOut",
        headerName: "CheckedOut Stock",
        width: 100,
        headerTooltip: "Checked-Out Stock",
      },
      {
        field: "itemTotalInAllPackageCheckedOut",
        headerName: "CheckedOut Items",
        width: 100,
        headerTooltip: "Checked-Out Items",
      },
      {
        field: "itemSqmCheckedOut",
        headerName: " SQM CheckedOut",
        width: 100,
        headerTooltip: "SQM Checked-Out",
      },
      {
        field: "itemSqmOccupied",
        headerName: "SQM Available",
        width: 100,
        headerTooltip: "SQM Available",
      },
      {
        field: "itemAreaOccupied",
        headerName: "CBM Available",
        width: 100,
        headerTooltip: "CBM Available",
      },
      {
        field: "itemAreaCheckedOut",
        headerName: "Area CheckedOut",
        width: 100,
        headerTooltip: "Area Checked-Out",
      },
      {
        field: "itemRemarks",
        headerName: "Remarks",
        width: 100,
        headerTooltip: "Remarks",
      },
      {
        field: "itemCargoCondition",
        headerName: "Cargo Condition",
        width: 100,
        headerTooltip: "Cargo Condition",
      },
    ],
    []
  );
  const [rowData, setRowData] = useState();
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    []
  );

  const onGridReady = useCallback((params) => {
    const updateData = (data) => params.api.setRowData(data);
    const Url =
      // BaseUrl.apiUrl.baseUrl + `api/v1/report/warehouse/stock/${date}/0`;
      BaseUrl.apiUrl.baseUrl + `api/v1/report/warehouse/stock/0/0/0`;

    let resp = apiGet(Url);
    resp.then((resp) => {
      updateData(resp.response.data.data);
    });
  }, []);

  function handleChange(e) {
    const newData = { ...searchdata };
    newData[e.target.name] = e.target.value;

    setsearchdata(newData);
  }

  function handleSearch(e) {
    setIsLoading(true);
    let palletNum = palletNumber;
    if (palletNum === "") {
      palletNum = 0;
    }
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/warehouse/stock/${WarehouseId.id}/${CustomerId.id}/${palletNum}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setIsLoading(false);
      setRowData(resp.response.data.data);
    });
  }
  const handleGenerateReport = () => {
    setIsLoading(true);
    let palletNum = palletNumber;
    if (palletNum === "") {
      palletNum = 0;
    }
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/warehouse/stock/export/${WarehouseId.id}/${CustomerId.id}/${palletNum}/${billingType.id}/excel`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setIsLoading(false);
      let sliceSize = 1024;
      let byteCharacters = atob(resp.response.data.data);
      let bytesLength = byteCharacters.length;
      let slicesCount = Math.ceil(bytesLength / sliceSize);
      let byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);
        let bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      FileSaver.saveAs(
        new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
        `${Date.now()}.xlsx`
      );
    });
  };
  const handleDownloadPdf = () => {
    setIsLoading(true);
    let palletNum = palletNumber;
    if (palletNum === "") {
      palletNum = 0;
    }
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/report/warehouse/stock/export/${WarehouseId.id}/${CustomerId.id}/${palletNum}/${billingType.id}/pdf`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setIsLoading(false);
      let sliceSize = 1024;
      let byteCharacters = atob(resp.response.data.data);
      let bytesLength = byteCharacters.length;
      let slicesCount = Math.ceil(bytesLength / sliceSize);
      let byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);
        let bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      FileSaver.saveAs(
        new Blob(byteArrays, { type: "application/pdf" }),
        `${Date.now()}.pdf`
      );
    });
  };
  function getWarehouseData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/warehouse/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setwarehouseList(resp.response.data.data);
    });
  }
  function getCustomerData() {
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/customers/org/${organisationId}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setCustomerList(resp.response.data.data);
    });
  }
  function resetTable() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/report/warehouse/stock/0/0/0`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      setRowData(resp.response.data.data);
    });
  }
  useEffect(() => {
    getWarehouseData();
    getCustomerData();
  }, []);
  useEffect(() => {}, [WarehouseId.id, CustomerId.id]);
  const warehouse = warehouseList.map((item) => {
    return {
      value: item.warehouseId,
      label: item.warehouseName,
    };
  });
  const customer = customerList.map((item) => {
    return {
      value: item.customerId,
      label: item.customerDisplayName,
    };
  });
  const billingTypeOptions = [
    {
      value: "ALL",
      label: "ALL",
    },
    {
      value: "CBM",
      label: "CBM",
    },
    {
      value: "SQM",
      label: "SQM",
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>STOCK REPORT</h6>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody className="tabcard">
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12} className="search-sec">
                          <AvForm className="form">
                            <Row>
                              {/* <Col md={2}>
                                <label>Date</label>
                                <AvField
                                  name="toDate"
                                  type="date"
                                  onChange={handleChange}
                                  required
                                  // value={data.custFn}
                                ></AvField>
                              </Col> */}
                              <Col md={2}>
                                <label>Warehouse</label>
                                <Select
                                  name="checkInWarehouseId"
                                  onChange={(e) =>
                                    setWarehouseId({
                                      id: e.value,
                                      name: e.label,
                                    })
                                  }
                                  options={warehouse}
                                />
                              </Col>
                              <Col md={2}>
                                <label>Customer</label>
                                <Select
                                  name="customerId"
                                  onChange={(e) =>
                                    setCustomerId({
                                      id: e.value,
                                      name: e.label,
                                    })
                                  }
                                  options={customer}
                                />
                              </Col>
                              <Col md={2}>
                                <label>Pallet Number</label>
                                <AvField
                                  name="palletNumber"
                                  type="text"
                                  onChange={(e) =>
                                    setPalletNumber(e.target.value)
                                  }
                                  value={palletNumber}
                                ></AvField>
                              </Col>
                              <Col md={2}>
                                <label>Type</label>
                                <Select
                                  onChange={(e) => {
                                    setTypeRequired(false);
                                    setBillingType({
                                      id: e.value,
                                      name: e.label,
                                    });
                                  }}
                                  options={billingTypeOptions}
                                />
                                {typeRequired ? (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    This field is required
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col>
                                <div className="search-buttons">
                                  <button
                                    class=" btn reportbutton"
                                    type="button"
                                    onClick={(e) => handleSearch(e)}
                                    disabled={isLoading}
                                  >
                                    Get Report
                                  </button>
                                  <button
                                    type="button"
                                    class=" btn reportbutton"
                                    onClick={resetTable}
                                  >
                                    reset
                                  </button>
                                  <button
                                    className=" btn reportbutton"
                                    onClick={handleGenerateReport}
                                    disabled={isLoading || billingType.id === 0}
                                  >
                                    Download Excel
                                  </button>
                                  <button
                                    className=" btn reportbutton"
                                    onClick={handleDownloadPdf}
                                    disabled={isLoading || billingType.id === 0}
                                  >
                                    Download PDF
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12}>
                      <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          enableRangeSelection="true"
                          rowData={rowData}
                          rowSelection="multiple"
                          suppressRowClickSelection="true"
                          suppressRowVirtualisation="true"
                          //   paginationPageSize="11"
                          onGridReady={onGridReady}
                          pagination={true}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default StockReport;
