import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
// import DataTableExtensions from "react-data-table-component-extensions";
// import "react-data-table-component-extensions/dist/index.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
// import Table from "./Table";
import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import FilterComponent from "./FilterComponent";
import { AgGridReact } from "ag-grid-react";

function BillsList() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }

  function toPages(pages) {
    const results = [];

    for (let i = 1; i < pages; i++) {
      results.push(i);
    }

    return results;
  }
  // const conditionalRowStyles = [
  //   {
  //     when: (row) => row.toggleSelected,
  //     style: {
  //       // "&:hover": {
  //       //   backgroundColor: "gray",
  //       // },
  //       backgroundColor: "green",
  //       userSelect: "none",
  //     },
  //   },
  // ];
  // const handleRowClicked = (row) => {
  //   const updatedData = data.map((item) => {
  //     if (row.customerId !== item.customerId) {
  //       return item;
  //     }
  //     return {
  //       ...item,
  //     };
  //   });
  //   // setData(updatedData);
  // };
  const columns = [
    {
      name: "Date",
      selector: (data) => data.billDate,
      sortable: true,
    },
    // {
    //   name: "Branch",
    //   selector: (data) => data.customerCompanyName,
    //   sortable: true,
    // },

    {
      name: "Bill#",
      selector: (data) => (
        <div className="text-opinenumber">
          <Link to={`/singleBill/${data.billId}`}>{data.billNumber}</Link>
        </div>
      ),
      sortable: true,
      // right: true,
    },
    {
      name: "Reference Number#",
      selector: (data) => data.billOrderNumber,
      sortable: true,
      right: true,
    },
    {
      name: "Vendor Name",
      selector: (data) => data.billVendorName,
      sortable: true,
      right: true,
    },
    {
      name: "Status",
      selector: (data) =>
        data.billStatus == 1 ? (
          <div className="text-approved">CREATED</div>
        ) : data.billStatus == 2 ? (
          <div className="text-draft">DRAFT</div>
        ) : data.billStatus == 3 ? (
          <div className="text-overdue">SEND FOR APPROVAL</div>
        ) : data.billStatus == 4 ? (
          <div className="text-approved">APPROVED</div>
        ) : data.billStatus == 5 ? (
          <div className="text-overdue">REJECTED</div>
        ) : data.billStatus == 6 ? (
          <div>FREEZED</div>
        ) : (
          ""
        ),
      sortable: true,
      // right: true,
    },
    {
      name: "Due Date",
      selector: (data) => data.billDueDate,
      sortable: true,
      right: true,
    },
    {
      name: "Amount",
      selector: (data) => data.billTotal,
      sortable: true,
      right: true,
    },
    {
      name: "Customer Number",
      selector: (data) => data.billVendorContactNumber,
      sortable: true,
      right: true,
    },
    // {
    //   name: "Balance Due",
    //   selector: (data) => "₹ 0.0",
    //   sortable: true,
    //   // right: true,
    // },
  ];
  const getData = async (page) => {
    setLoading(true);
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/bills/org?org_id=${organisationId}&page=${page}&size=${perPage}&sort=billId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      console.log("resp bills", resp.response.data.data.content);
      setData(resp.response.data.data.content);
      setTotalRows(resp.response.data.data.totalElements);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    getData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/bills/org?org_id=${organisationId}&page=${page}&size=${newPerPage}&sort=billId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setPerPage(newPerPage);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData(1);
  }, [filterText]);
  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };
  const subHeaderComponent = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const handleRowClick = (row) => {
    console.log("row bill id", row.data.billId);
    history.push(`/singleBill/${row.data.billId}`);
  };

  // aggrid data

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "billNumber",
      headerName: "Bill#",
      headerTooltip: "Bill#",
    },
    {
      field: "billOrderNumber",
      headerName: "Reference Number#",
      headerTooltip: "Reference Number#",
    },
    {
      field: "billVendorName",
      headerName: "Vendor Name",
      headerTooltip: "Vendor Name",
    },

    {
      cellRendererFramework: ({ data }) => {
        const billStatus = data.billStatus;
        return (
          <>
            {data.billStatus == 1 ? (
              <div className="text-approved">CREATED</div>
            ) : data.billStatus == 2 ? (
              <div className="text-draft">DRAFT</div>
            ) : data.billStatus == 3 ? (
              <div className="text-overdue">SEND FOR APPROVAL</div>
            ) : data.billStatus == 4 ? (
              <div className="text-approved">APPROVED</div>
            ) : data.billStatus == 5 ? (
              <div className="text-overdue">REJECTED</div>
            ) : data.billStatus == 6 ? (
              <div>FREEZED</div>
            ) : (
              ""
            )}
          </>
        );
      },
      headerName: "Status",
      headerTooltip: "Status",
    },
    {
      field: "billDueDate",
      headerName: "Due Date",
      headerTooltip: "Due Date",
    },
    // {
    //   field: "invoiceTotal",
    //   headerName: "Amount",
    //   headerTooltip: "Amount",
    // },
    {
      field: "billVendorContactNumber",
      headerName: "Customer Number",
      headerTooltip: "Customer Number",
    },
    {
      field: "billTotal",
      headerName: "Total Amount",
      headerTooltip: "Total Amount",
    },
  ]);
  const gridRef = useRef();
  const defaultColDef = useMemo(
    () => ({
      filter: true,
      sortable: true,
      resizable: true,
    }),
    []
  );
  const onGridReady = useCallback(
    (params) => {
      return data;
    },
    [data]
  );
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        minWidth: 225,
        maxWidth: 225,
        width: 225,
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
        minWidth: 180,
        maxWidth: 400,
        width: 250,
      },
    ],
    position: "right",
    defaultToolPanel: "",
  };
  const gridStyle = useMemo(() => ({ height: "60vh", width: "100%" }), []);

  return (
    <div className="App">
      <div className="card">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
        {/* <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows
          subHeader
          subHeaderComponent={subHeaderComponent}
          subHeaderAlign="left"
          onRowClicked={handleRowClick}
          highlightOnHover
          pointerOnHover
        /> */}

        <div className="ag-theme-alpine" style={gridStyle}>
          {" "}
          <AgGridReact
            columnDefs={columnDefs}
            rowData={filteredItems}
            ref={gridRef}
            pagination
            grid
            onRowClicked={handleRowClick}
            sideBar={sideBar}
            // onPaginationChanged={handlePageChange}
            animateRows={true}
            defaultColDef={defaultColDef}
            subHeaderComponent={subHeaderComponent}
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </div>
  );
}
export default BillsList;
