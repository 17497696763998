import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Input,
  ButtonGroup,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Formik, Field } from "formik";
//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../../components/config/BaseUrl";
import { apiGet } from "../../../components/config/apiConfig";
const Statements = (props) => {
  const [customerData, setCustomerData] = useState({});

  useEffect(() => {
    setCustomerData(props.data);
  }, [props]);
  const [dropdownMonth, setMonth] = React.useState(false);
  const toggleMonth = () => setMonth(!dropdownMonth);
  const [dropdownFilter, setFilter] = React.useState(false);
  const toggleFilter = () => setFilter(!dropdownFilter);
  const [dropdownOpen, setOpen] = React.useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  return (
    <React.Fragment className="statement">
      <Row className="headerButtons">
        <Col md={2} className="formLeft ">
          <Col>
            <ButtonDropdown isOpen={dropdownMonth} toggle={toggleMonth}>
              <DropdownToggle caret>System Mails</DropdownToggle>
              <DropdownMenu>
                {/* <DropdownItem header>item 1</DropdownItem> */}
                {/* <DropdownItem disabled>item 2</DropdownItem> */}
                <DropdownItem>Today</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>This Week</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>This Month</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>This Quarter</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>This Year</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Yesterday</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Last Week</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Last Month</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Last Quarter</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Last Year</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Custom</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>System mail history</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Col>
        <Col md={2} className="formLeft ">
          <Col>
            <ButtonDropdown isOpen={dropdownFilter} toggle={toggleFilter}>
              <DropdownToggle caret>Filter by:</DropdownToggle>
              <DropdownMenu>
                {/* <DropdownItem header>item 1</DropdownItem> */}
                {/* <DropdownItem disabled>item 2</DropdownItem> */}
                <DropdownItem>All</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Outstanding</DropdownItem>
                <DropdownItem divider />
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Col>
        <Col className="formLeft ">
          <Col>
            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret>Branch :</DropdownToggle>
              <DropdownMenu>
                {/* <DropdownItem header>item 1</DropdownItem> */}
                {/* <DropdownItem disabled>item 2</DropdownItem> */}
                <DropdownItem>All Branch</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Head Office</DropdownItem>
                <DropdownItem divider />
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Col>
        {/* <div md={4} className="HeaderButtonRight" style={{ float: "right" }}> */}
        <button
          md={4}
          title="print"
          style={{ border: "0px", borderRadius: "3px", padding: "10px" }}
        >
          Print
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          md={4}
          title="pdf"
          style={{ border: "0px", borderRadius: "3px", padding: "10px" }}
        >
          PDF
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          md={4}
          title="xls"
          style={{ border: "0px", borderRadius: "3px", padding: "10px" }}
        >
          XLS
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          md={4}
          title="Sent Mail"
          style={{
            border: "0px",
            borderRadius: "3px",
            padding: "10px",
            bgColor: "blue",
          }}
        >
          Sent Mail
        </button>
        {/* </div> */}
      </Row>
      <Row className="textCenter">
        <Col className="textCenter">
          <h4>Customer Statement for {customerData.customerDisplayName}</h4>
          <h5>
            <span>From</span>&nbsp;01/06/2022&nbsp;<span>To</span>
            &nbsp;30/06/2022
          </h5>
        </Col>{" "}
      </Row>
      <Row className="detailContainer">
        <Col className="detailContainer">
          <div md={4} class="details-container clearfix">
            <div class="orgdetails-update-frmtxndetails"></div>
            <div id="ember1290" class="ember-view">
              <style media="all" type="text/css"></style>

              <div class="pcs-template ">
                <div class="pcs-template-header pcs-header-content" id="header">
                  <div class="pcs-template-fill-emptydiv"></div>
                </div>

                <div class="pcs-template-body">
                  <table
                    style={{
                      lineHeight: "18px;",
                      cellPadding: "0",
                      cellSpacing: "0",
                      border: "0",
                      width: "100%",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td></td>

                        <td width="50%" class="pcs-orgname text-align-right">
                          {/* <b>test</b> */}
                          <br />
                          <span
                            style={{
                              whiteSpace: "pre-wrap;",
                              id: "tmp_org_address",
                            }}
                          >
                            Arunachal Pradesh <br /> India <br /> GSTIN
                            123456789012457
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <table
                            cellpadding="0"
                            cellspacing="0"
                            border="0"
                            class="title-section"
                          >
                            <tbody>
                              <tr>
                                <td
                                  class="pcs-entity-title"
                                  style={{
                                    paddingTop: "6px",
                                    lineHeight: "30px",
                                  }}
                                >
                                  <b>Statement of Accounts</b>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "12px",
                                    borderTop: "1px solid #000",
                                    borderBottom: " 1px solid #000",
                                    height: "24",
                                    class: "text-align-right",
                                  }}
                                >
                                  01/06/2022 To 30/06/2022
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "20px 0px 0px 5px;" }}>
                          <table
                            cellpadding="0"
                            cellspacing="0"
                            border="0"
                            width="70%"
                          >
                            <tbody>
                              <tr>
                                <td class="pcs-label">
                                  <b>To</b>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span
                                    style={{ whiteSpace: "pre-wrap;" }}
                                    id="tmp_billing_address"
                                  >
                                    <strong>
                                      <span
                                        class="pcs-customer-name"
                                        id="zb-pdf-customer-detail"
                                      >
                                        <a href="">
                                          {customerData.customerDisplayName}
                                        </a>
                                      </span>
                                    </strong>
                                    Address 1 <br /> test city <br /> 999999
                                    Kerala <br /> India
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td
                          style={{
                            padding: "20px 0px 30px 0px",
                            valign: "bottom",
                          }}
                        >
                          <table
                            cellpadding="5"
                            cellspacing="0"
                            width="79%"
                            border="0"
                            class="summary-section"
                          >
                            <tbody>
                              <tr>
                                <td
                                  class="pcs-label"
                                  style={{
                                    padding: "4px 6px 4px 6px",
                                    borderBottom: "1px solid #dcdcdc",
                                    bgColor: "#e8e8e8",
                                    colSpan: "5",
                                  }}
                                >
                                  <b>Account Summary</b>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="pcs-label"
                                  style={{ paddingTop: "6px", width: "50%" }}
                                >
                                  Opening Balance
                                </td>
                                <td
                                  style={{ padding: "6px 0px 0px 6px;" }}
                                  class="text-align-right"
                                >
                                  ₹ 2,22,222.00
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="pcs-label"
                                  style={{ paddingTop: "4px" }}
                                >
                                  Invoiced Amount
                                </td>
                                <td
                                  style={{ padding: "6px 0px 0px 6px" }}
                                  class="text-align-right"
                                >
                                  ₹ 0.00
                                </td>
                              </tr>
                              <tr>
                                <td class="pcs-label">Amount Received</td>
                                <td
                                  style={{ padding: "4px 0px 2px 6px" }}
                                  class="text-align-right"
                                >
                                  ₹ 0.00
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="pcs-label"
                                  style={{
                                    paddingTop: "6px ",
                                    borderTop: "1px solid #000",
                                  }}
                                >
                                  Balance Due
                                </td>
                                <td
                                  style={{
                                    padding: "6px 0px 0px 6px",
                                    borderTop: "1px solid #000;",
                                  }}
                                  class="text-align-right"
                                >
                                  ₹ 2,22,222.00
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    style={{
                      lineHeight: "18px",
                      marginTop: "10px",
                      cellPadding: "2",
                      cellSpacing: "0",
                      border: "0",
                      width: "100%",
                    }}
                    class="trpadding"
                  >
                    <thead>
                      <tr height="26">
                        <th width="15%" class="pcs-itemtable-header">
                          <b>Date</b>
                        </th>
                        <th width="14%" class="pcs-itemtable-header">
                          <b>Transactions</b>
                        </th>
                        <th width="25%" class="pcs-itemtable-header">
                          <b>Details</b>
                        </th>
                        <th
                          width="13%"
                          class="text-align-right pcs-itemtable-header"
                        >
                          <b>Amount</b>
                        </th>
                        <th
                          width="13%"
                          class="text-align-right pcs-itemtable-header"
                        >
                          <b>Payments</b>
                        </th>
                        <th
                          width="20%"
                          class="text-align-right pcs-itemtable-header"
                        >
                          <b>Balance</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="itemBody">
                      <tr class="trclass_oddrow breakrow-inside breakrow-after">
                        <td class="box-padding">01/06/2022</td>
                        <td class="box-padding">***Opening Balance***</td>
                        <td class="box-padding"></td>
                        <td class="text-align-right box-padding">
                          2,22,222.00
                        </td>
                        <td class="text-align-right box-padding"></td>
                        <td class="text-align-right box-padding">
                          2,22,222.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    width="100%"
                    style={{ borderTop: "1px solid #dcdcdc;" }}
                  >
                    <tbody>
                      <tr>
                        <td></td>
                        <td width="50%">
                          <table width="100%">
                            <tbody>
                              <tr>
                                <td
                                  width="50%"
                                  class="box-padding"
                                  align="right"
                                  valign="middle"
                                >
                                  <b>Balance Due</b>
                                </td>
                                <td
                                  class="box-padding"
                                  align="right"
                                  valign="middle"
                                >
                                  ₹ 2,22,222.00
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="pcs-template-footer">
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default Statements;
