import React, { useMemo, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { Link, useHistory } from "react-router-dom";
import * as moment from "moment";
import Axios from "axios";
// import Table from "./Table";
import { apiGet, apiPut } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import dyarco from "../../assets/dyarco/dyarco.jpeg";
import { ToastContainer, toast } from "react-toastify";
import FilterComponent from "./FilterComponent";
function CargoCheckInList() {
  const history = useHistory();
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [printdata, setprintdata] = useState();
  const [declineModal, setdeclineModal] = useState(false);
  const [checkInId, setCheckInId] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }

  function toPages(pages) {
    const results = [];

    for (let i = 1; i < pages; i++) {
      results.push(i);
    }

    return results;
  }
  // const printPDF = (data) => {
  //   console.log("data", data);
  //   const Url = BaseUrl.apiUrl.baseUrl + `api/v1/cargo_checkin/${data}`;
  //   let resp = apiGet(Url);
  //   resp.then((resp) => {
  //     console.log("print data", resp.response.data.data);
  //     setprintdata(resp.response.data.data);
  //     handlePrint();
  //   });
  // };
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   bodyClass: "print-btn",
  //   pageStyle: "margin:50px",
  // });
  const columns = [
    {
      name: "Date",
      selector: (data) => data.checkInDate,
      sortable: true,
    },
    {
      name: "Checkin Time",
      selector: (data) =>
        data.checkInTime === null
          ? ""
          : moment(data.checkInTime, "HH:mm").format("hh:mm A"),
      sortable: true,
    },
    {
      name: "Check-In Number",
      selector: (data) => data.checkInNumber,
      sortable: true,
    },
    {
      name: "Warehouse Name",
      selector: (data) => data.checkInWarehouseName,
      sortable: true,
    },

    {
      name: "Customer Name",
      selector: (data) => data.checkInCustomerName,
      sortable: true,
      // right: true,
    },
    {
      name: "Quotation Number",
      selector: (data) => data.checkInQuotationNumber,
      sortable: true,
      // right: true,
    },

    {
      name: "Rate / CBM",
      selector: (data) => data.checkInRatePerCbm,
      sortable: true,
      // right: true,
    },
    {
      name: "Estimated Commodity Value",
      selector: (data) => data.checkInEstimatedCommodityValue,
      sortable: true,
      // right: true,
    },
    {
      name: "Status",
      selector: (data) =>
        data.checkInStatus == 1 ? (
          <div className="text-approved">New</div>
        ) : data.checkInStatus == 2 ? (
          <div className="text-draft">Closed/Freezed</div>
        ) : data.checkInStatus == 3 ? (
          <div className="text-overdue">Completed</div>
        ) : (
          ""
        ),
      sortable: true,
      // right: true,
    },
    {
      button: true,
      cell: (data) => (
        <>
          <div className="table-actionBtn">
            {data.checkInStatus === 1 ? (
              <div className="tablebtn">
                <button onClick={() => openModal(data)}>Close Checkin</button>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ),
      width: "100px",
    },
    // {
    //   button: true,
    //   cell: (data) => (
    //     <>
    //       <div className="tablebtn">
    //         <Link to={`/viewcheckin/${data.checkInId}`}>
    //           <button>View</button>
    //         </Link>
    //       </div>
    //     </>
    //   ),
    // },
  ];
  const getData = async (page) => {
    setLoading(true);
    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/cargo_checkin/org?org_id=${organisationId}&page=${page}&size=${perPage}&sort=checkInId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setTotalRows(resp.response.data.data.totalElements);
      setLoading(false);
    });
  };

  const handlePageChange = (page) => {
    getData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const organisationId = localStorage.getItem("organisationId");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/cargo_checkin/org?org_id=${organisationId}&page=${page}&size=${newPerPage}&sort=checkInId,desc&searchText=${filterText}`;
    let resp = apiGet(Url);

    resp.then((resp) => {
      setData(resp.response.data.data.content);
      setPerPage(newPerPage);
      setLoading(false);
    });
  };
  useEffect(() => {
    getData(1);
  }, [filterText]);
  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const handleCloseCheckIn = (datas) => {
    console.log("row", datas);
    let userId = localStorage.getItem("Id");
    const Url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/cargo_checkin/${checkInId}/${userId}/freeze`;
    var body = {
      checkInStatus: 2,
    };
    let resp = apiPut(Url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success("Check-In Closed Successfully");
          // getCheckInData();
          getData(1);
          break;
        default:
          toast.error(resp.response.data.data);
      }
    });
    setdeclineModal(false);
  };
  const openModal = (datas) => {
    setdeclineModal(true);
    setCheckInId(datas.checkInId);
  };
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const handleRowClick = (row) => {
    history.push(`/viewcheckin/${row.checkInId}`);
  };
  return (
    <div className="App">
      {/* <ToastContainer
        position="bottom-center"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        // pauseOnFocusLoss
        // pauseOnHover
      /> */}
      <div className="card">
        {/* {loadingData ? (
          <div className="tableLoading">
            <p>Loading Please wait...</p>
          </div>
        ) : ( */}
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows
          subHeader
          subHeaderComponent={subHeaderComponent}
          subHeaderAlign="left"
          onRowClicked={handleRowClick}
          highlightOnHover
          pointerOnHover
        />
        <Modal className="export-modal" isOpen={declineModal}>
          <Row>
            <Col md={12} className="modal-head">
              <div>
                <h5>Are you sure you want to close this Check-In</h5>
              </div>
              <div onClick={() => setdeclineModal(false)}>
                <i class="bx bx-x"></i>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="declineModal">
              <button onClick={() => handleCloseCheckIn()}>Yes</button>
              <button onClick={() => setdeclineModal(false)}>No</button>
            </Col>
          </Row>
        </Modal>
        {/* )} */}
      </div>
    </div>
  );
}
export default CargoCheckInList;
