import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
} from "reactstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
//i18n
import { withTranslation } from "react-i18next";
import VendorComments from "./comments";
import VendorMails from "./mails";
import VendorOverview from "./overview";
import VendorStatements from "./statements";
import VendorTransactions from "./transactions";
import { BaseUrl } from "../../../components/config/BaseUrl";
import { apiGet } from "../../../components/config/apiConfig";
import { useEffect } from "react";
// import ExportModal from "../exportModal";

const SingleViewVendors = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [socialDrp, setsocialDrp] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [vendors, setVendors] = useState({});
  function getVendors() {
    // console.log(vendorsAdvId);
    const Url =
      BaseUrl.apiUrl.baseUrl + `api/v1/vendors/` + props.match.params.id;
    let resp = apiGet(Url);
    resp.then((resp) => {
      console.log("single vendor", resp);
      setVendors(resp.response.data.data);
    });
  }
  useEffect(() => {
    getVendors();
  }, []);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12} className="item-head" style={{ borderBottom: "1em" }}>
              <div>
                <h5>{vendors.vendorDisplayName} </h5>
              </div>
              <div className="bbb">
                <button>
                  <i class="bx bx-pencil"></i>
                </button>
                <Dropdown
                  className="d-inline-block"
                  isOpen={socialDrp}
                  toggle={() => {
                    setsocialDrp(!socialDrp);
                  }}
                >
                  {/* <DropdownToggle
                    className="btn header-item waves-effect ddtoggle"
                    tag="button"
                  >
                    <button> More</button>
                  </DropdownToggle> */}
                  <DropdownMenu right>
                    <DropdownItem tag="a" href="">
                      {" "}
                      <i className="bx bx-copy font-size-16 align-middle mr-1"></i>
                      {props.t("Clone Item")}{" "}
                    </DropdownItem>
                    <hr />
                    <DropdownItem tag="a" href="">
                      {" "}
                      <i className="bx bx-import font-size-16 align-middle mr-1"></i>
                      {props.t("Import Item")}{" "}
                    </DropdownItem>
                    <DropdownItem tag="a" onClick={() => setModalIsOpen(true)}>
                      {" "}
                      <i className="bx bx-export font-size-16 align-middle mr-1"></i>
                      {props.t("Export Item")}{" "}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Modal className="export-modal" isOpen={modalIsOpen}>
                  {/* <ExportModal isClose={() => setModalIsOpen(false)} /> */}
                </Modal>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Overview
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Comments
                  </NavLink>
                </NavItem>
               <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Transactions
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "4",
                    })}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    Mails
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "5",
                    })}
                    onClick={() => {
                      toggle("5");
                    }}
                  >
                    Statements
                  </NavLink>
                </NavItem> */}
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <VendorOverview data={vendors} />
                </TabPane>
                <TabPane tabId="2">
                  <VendorComments />
                </TabPane>
                <TabPane tabId="3">
                  <VendorTransactions />
                </TabPane>
                <TabPane tabId="4">
                  <VendorMails />
                </TabPane>
                <TabPane tabId="5">
                  <VendorStatements data={vendors} />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(SingleViewVendors);
