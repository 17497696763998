import {
  ADD_TABLE_DATA_OT,
  UPDATE_ROW_TOTAL_OT,
  DELETE_TABLE_ROW_OT,
  CALCULATE_SHIPPING_CHARGE_OT,
  CALCULATE_ADJUSTMENT_CHARGE_OT,
  CALCULATE_TOTALS_OT,
  POPULATE_TABLE_DATA_OT,
  CLEAR_DATA_OT,
  UPDATE_ROW_SELECT,
} from "./actionTypes";

export const addTableDataOt = (itemData) => ({
  type: ADD_TABLE_DATA_OT,
  payload: itemData,
});

export const updateRowTotalOt = (index, value) => ({
  type: UPDATE_ROW_TOTAL_OT,
  payload: { index, value },
});
export const updateRowSelectOt = (index, value) => ({
  type: UPDATE_ROW_SELECT,
  payload: { index, value },
});
export const calculateShippingChargeOt = (value) => ({
  type: CALCULATE_SHIPPING_CHARGE_OT,
  payload: value,
});
export const calculateAdjustmentChargeOt = (value) => ({
  type: CALCULATE_ADJUSTMENT_CHARGE_OT,
  payload: value,
});
export const deleteTableRowOt = (index) => ({
  type: DELETE_TABLE_ROW_OT,
  payload: index,
});
export const calculateTotalsOt = () => ({
  type: CALCULATE_TOTALS_OT,
});
export const populateTableDataOt = (
  subtotal,
  shippingcharge,
  adjustment,
  total,
  itemList
) => ({
  type: POPULATE_TABLE_DATA_OT,
  payload: { subtotal, shippingcharge, adjustment, total, itemList },
});
export const clearTableDataOt = () => ({
  type: CLEAR_DATA_OT,
});
