import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  apiGet,
  apiDelete,
  apiPut,
} from "../../../components/config/apiConfig";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../../components/config/BaseUrl";
import FilterComponent from "./FilterComponent";
import DeleteBin from "./DeleteBin";
import { ToastContainer, toast } from "react-toastify";
import EditBin from "./EditBin";

function BinList() {
  const [data, setData] = useState([]);
  const [isSaving, setisSaving] = useState(false);
  const [editValues, seteditValues] = useState({
    bin: "",
  });
  const [Id, setId] = useState("");
  const [deleteModel, setdeleteModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const userId = localStorage.getItem("Id");
  const getData = async () => {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/bin`;
    // const Url = `http://localhost:8080/api/v1/master/location_type`;

    let resp = apiGet(Url);
    resp.then((resp) => {
      setData(resp.response.data.data);
      // setTotalRows(resp.response.data.data.totalElements);
    });
  };

  const handleOpenModal = (id) => {
    setId(id);
    setdeleteModel(true);
  };
  const handleEditModal = (id) => {
    setId(id);
    setEditModel(true);
    getDataByid(id);
  };
  const handleChange = (e) => {
    const newData = { ...editValues };
    newData[e.target.name] = e.target.value;
    seteditValues(newData);
  };
  const getDataByid = async (id) => {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/bin/${id}`;

    let resp = apiGet(Url);
    resp.then((resp) => {
      seteditValues(resp.response.data.data);
      // setTotalRows(resp.response.data.data.totalElements);
    });
  };
  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      name: "Bin",
      selector: (data) => data.bin,
      sortable: true,
    },

    {
      name: "Action",
      button: true,
      center: true,
      cell: (data) => (
        <>
          <button
            className="btn-round edit"
            onClick={() => handleEditModal(data.id)}
          >
            <i class="bx bx-pencil"></i>
          </button>

          <button
            className="btn-round delete"
            onClick={() => handleOpenModal(data.id)}
          >
            <i class="bx bx-trash"></i>
          </button>
        </>
      ),
    },
  ];
  const subHeaderComponent = useMemo(() => {
    return (
      <FilterComponent
        getData={getData}
        // onFilter={(e) => setFilterText(e.target.value)}
        // onClear={handleClear}
        // filterText={filterText}
      />
    );
  }, []);
  const handleDelete = (id) => {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/bin/${id}/${userId}`;
    let resp = apiDelete(Url);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          getData();
          toast.success(resp.response.data.data);
          setdeleteModel(false);
          break;

        default:
          getData();
      }
    });
  };
  const handleEdit = (id) => {
    setisSaving(true);

    var body = {
      bin: editValues.bin,
    };
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/master/bin/${id}`;
    let resp = apiPut(Url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          toast.success(resp.response.data.data);
          getData();
          setEditModel(false);
          setisSaving(false);

          break;

        default:
          toast.error(resp.response.data.data);
      }
    });
  };
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        // autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Bin" />
          <Row>
            <Col>
              <div className="card">
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  paginationServer
                  selectableRows
                  subHeader
                  subHeaderComponent={subHeaderComponent}
                  subHeaderAlign="left"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <EditBin
        openModal={editModel}
        setOpenModel={setEditModel}
        handle={handleChange}
        editValues={editValues}
        handleEdit={handleEdit}
        isSaving={isSaving}
        id={Id}
      />
      <DeleteBin
        openModal={deleteModel}
        setOpenModel={setdeleteModel}
        handleDeleteUom={handleDelete}
        id={Id}
      />
    </React.Fragment>
  );
}

export default BinList;
