import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import loader from "../../assets/loader/load.gif";
import dyarco from "../../assets/dyarco/dyarco.jpeg";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import { BaseUrl } from "../../components/config/BaseUrl";
import { apiGet, apiPost, apiPut } from "../../components/config/apiConfig";

import { ToastContainer, toast } from "react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomCheckbox from "../../components/Features/customcheckbox";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";

import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { useCallback } from "react";
import { useMemo } from "react";
import { useReactToPrint } from "react-to-print";
import {
  requestCheckoutDataByNumber,
  updateCheckOutData,
  clearCommodityData,
  addCommodityTableOtherCharges,
  updateCommodityOtherCharges,
  deleteOtherChargeTableRow,
  addHandlingCharge,
  setCommodityTableOtherCharges,
  setCheckoutDataById,
} from "../../store/actions";
import { useRef } from "react";
const CheckOutByItem = (props) => {
  var id = props.match.params.id;
  var type = props.match.params.type;
  const current = new Date();
  const componentRef = useRef();
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1 < 10
      ? `0${current.getMonth() + 1}`
      : current.getMonth() + 1
  }-${current.getDate() < 10 ? `0${current.getDate()}` : current.getDate()}`;
  const [checkOutHandlingCharge, setcheckOutHandlingCharge] = useState(0);
  const history = useHistory();
  const [reload, setReload] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [jobinvnumber, setjobinvnumber] = useState("");
  const [checkoutnumber, setcheckoutnumber] = useState("");
  const [otherChargeName, setotherChargeName] = useState("");
  const [otherCharge, setOtherCharge] = useState([
    {
      chargeName: "",
      chargeAmount: 0,
    },
  ]);

  const [checkbox, setCheckbox] = useState(false);
  const [checkOutMandatory, setcheckOutMandatory] = useState(false);
  const [checkoutDate, setCheckOutDate] = useState(date);
  const [checkOutTime, setcheckOutTime] = useState(
    new Date().toLocaleTimeString()
  );
  const [checkOutStatus, setCheckOutStatus] = useState(false);
  const addOtherChargeTableRows = () => {
    const rowsInput = {
      chargeName: "",
      chargeAmount: 0,
    };
    setOtherCharge([...otherCharge, rowsInput]);
    setotherChargeName("");
    props.setCommodityTableOtherCharges();
  };
  const deleteOtherChargeTableRow = (index) => {
    props.deleteOtherChargeTableRow(index);
    const rows = [...otherCharge];
    rows.splice(index, 1);
    setOtherCharge(rows);
  };

  const prepareDataWithIndexes = (data) => {
    return data.map((item, index) => ({
      ...item,
      itemIndex: index,
    }));
  };
  const columns = useMemo(
    () => [
      {
        input: true,
        cell: (row) => (
          <input
            type="checkbox"
            name="itemIsSelected"
            // defaultChecked={row.itemIsSelected}
            checked={props.checkOutTableData[row.itemIndex]?.itemIsSelected}
            onChange={(e) => props.updateCheckOutData(e.target, row.itemIndex)}
          />
        ),
        width: "30px",
      },
      {
        name: "Commodity Name",
        selector: (row) => row.itemCommodityName,
        sortable: false,
      },
      {
        name: "HSN Code",
        selector: (row) => row.itemHsnCode,
        sortable: false,
      },

      {
        name: "UOM",
        selector: (row) => row.itemUom,
        sortable: false,
      },
      {
        name: "Stock",
        selector: (row) => row.itemUnitsTotal,
        sortable: false,
      },
      {
        name: "Available Stock",
        selector: (row) => row.itemUnitsAvailable,
        sortable: false,
      },
      {
        name: "Pallet No",
        selector: (row) => row.itemPallet,
        sortable: false,
      },
      {
        name: "Available Items",
        selector: (row) => row.itemTotalInAllPackageAvailable,
        sortable: false,
      },

      {
        name: "Check Out items",
        cell: (row) => (
          <input
            type="number"
            name="itemPackageWiseCheckedOut"
            value={
              row.itemPackageWiseCheckedOut == 0
                ? ""
                : row.itemPackageWiseCheckedOut
            }
            onChange={(e) => props.updateCheckOutData(e.target, row.itemIndex)}
            disabled={!row.itemIsSelected ? true : false}
            placeholder={row.itemIsSelected ? "Enter checkout units" : ""}
            className={
              row.itemIsSelected && !checkOutMandatory
                ? "checkout-input"
                : row.itemIsSelected && checkOutMandatory
                ? "required"
                : "noborder"
            }
            style={{
              // border: "none",
              // borderBottom: row.itemIsSelected
              //   ? "1px solid #052f85"
              //   : row.itemIsSelected && checkOutMandatory
              //   ? "1px solid red !important"
              //   : "none",
              width: "130px",
            }}
          />
        ),
        width: "140px",
      },
      {
        name: "Remarks",
        cell: (row) => (
          <input
            type="text"
            name="itemRemarks"
            onChange={(e) => props.updateCheckOutData(e.target, row.itemIndex)}
            disabled={!row.itemIsSelected ? true : false}
            placeholder={row.itemIsSelected ? "Remarks" : ""}
            style={{
              border: "none",
              borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
              width: "130px",
            }}
            value={row.itemRemarks}
          />
        ),
        width: "140px",
      },
      {
        name: "Cargo Condition",
        cell: (row) => (
          <input
            type="text"
            name="itemCargoCondition"
            onChange={(e) => props.updateCheckOutData(e.target, row.itemIndex)}
            disabled={!row.itemIsSelected ? true : false}
            placeholder={row.itemIsSelected ? "Conditions" : ""}
            style={{
              border: "none",
              borderBottom: row.itemIsSelected ? "1px solid #052f85" : "none",
              width: "130px",
            }}
            value={row.itemCargoCondition}
          />
        ),
        width: "140px",
      },
    ],
    [props.checkOutTableData, checkOutMandatory]
  );
  const preparedData = useMemo(
    () => prepareDataWithIndexes(props.checkOutTableData),
    [props.checkOutTableData]
  );
  const handleCheckOut = (e) => {
    var flag = true;
    props.checkOutTableData.map((items) => {
      if (
        items["itemIsSelected"] &&
        (items["itemPackageWiseCheckedOut"] === 0 ||
          items["itemPackageWiseCheckedOut"] === "")
      ) {
        setcheckOutMandatory(true);
        flag = false;
      }
    });

    if (flag) {
      if (props.checkOutTableData.some((items) => items["itemIsSelected"])) {
        setcheckOutMandatory(false);
        if (type === "add") {
          itemCheckOut();
        }
        if (type === "edit") {
          updateItemCheckOut();
        }
      }
    }

    if (props.checkOutTableData.some((items) => items["itemIsSelected"])) {
      setCheckbox(false);
    } else {
      setCheckbox(true);
      setcheckOutMandatory(false);
    }
  };
  async function checkCheckOut() {
    setReload(true);
    try {
      let url =
        BaseUrl.apiUrl.baseUrl + `api/v1/cargo_checkout/check/${jobinvnumber}`;
      let response = await apiGet(url);

      switch (response.response.data.status) {
        case "Success":
          setCheckOutStatus(false);
          setReload(false);
          break;
        case "Failed":
          setCheckOutStatus(true);
          setReload(false);
          break;
        default:
          setReload(false);
          toast.error(response.response.data.data);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setReload(false);
      toast.error("An error occurred during the API call");
    }
  }
  function itemCheckOut() {
    setReload(true);
    var selectedItemCheckOut = props.checkOutTableData.filter(
      (item) => item.itemIsSelected
    );
    // var checkOutChargeList = props.otherChargeTableData.filter(
    //   (charge) => charge.chargeAmount !== ""
    // );
    let orgId = localStorage.getItem("organisationId");
    let url = BaseUrl.apiUrl.baseUrl + "api/v1/cargo_checkout_itemwise";
    let body = {
      checkOutDate: checkoutDate,
      checkOutTime: checkOutTime,
      checkOutType: "Item-Based",
      checkOutCheckInId: parseInt(props.checkOutCheckInId),
      checkOutHandlingOutCharge:
        checkOutHandlingCharge > 0
          ? parseInt(checkOutHandlingCharge)
          : parseInt(customer.customerHandlingOutCharge),
      organizationId: orgId,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
      itemsList: selectedItemCheckOut,
      // chargeList: checkOutChargeList,
    };
    console.log("body", body);
    let resp = apiPost(url, body);
    resp.then((resp) => {
      switch (resp.response.data.code) {
        case 200:
          setcheckoutnumber(resp.response.data.data);
          // handlePrint();
          toast.success(
            `${resp.response.data.message} ${resp.response.data.status}`
          );
          setTimeout(() => {
            setReload(false);
            history.push("/cargocheckout-list");
          }, 1000);

          break;
        default:
          setReload(false);
          toast.error(resp.response.data.data);
      }
    });
  }
  function updateItemCheckOut() {
    setReload(true);
    var selectedItemCheckOut = props.checkOutTableData.filter(
      (item) => item.itemIsSelected
    );

    let orgId = localStorage.getItem("organisationId");
    let url =
      BaseUrl.apiUrl.baseUrl +
      `api/v1/cargo_checkout_itemwise/${props.checkOutDetails?.checkOutId}`;
    let body = {
      checkOutDate: checkoutDate,
      checkOutTime: checkOutTime,
      checkOutType: "Item-Based",
      checkOutCheckInId: parseInt(props.checkOutDetails?.checkOutCheckInId),
      checkOutHandlingOutCharge:
        checkOutHandlingCharge > 0
          ? parseInt(checkOutHandlingCharge)
          : parseInt(customer.customerHandlingOutCharge),
      organizationId: orgId,
      branchId: 0,
      addedByUser: 1,
      editByUser: 1,
      itemsList: selectedItemCheckOut,
    };
    console.log("body", body);
    let resp = apiPut(url, body);
    resp.then((resp) => {
      setReload(false);
      switch (resp.response.data.code) {
        case 200:
          toast.success(
            `${resp.response.data.message} ${resp.response.data.status}`
          );
          setTimeout(() => {
            history.push("/cargocheckout-list");
          }, 1000);
          break;
        default:
          toast.error(resp.response.data.data);
      }
    });
  }
  const handleCheckOutHandlingCharge = (e) => {
    setcheckOutHandlingCharge(e.target.value);
  };
  const searchCheckOut = () => {
    props.requestCheckoutDataByNumber(jobinvnumber);
    checkCheckOut();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "print-btn",
    pageStyle: "margin:10px",
  });
  // const handleSubmit = () => {
  //   handleCheckOut();
  // };

  useEffect(() => {
    return () => {
      props.clearCommodityData();
    };
  }, [jobinvnumber]);
  const [customer, setCustomer] = useState({});
  useEffect(() => {
    if (customer.customerHandlingOutCharge !== 0) {
      props.addHandlingCharge(customer.customerHandlingOutCharge, "checkout");
      setcheckOutHandlingCharge(customer.customerHandlingOutCharge);
    } else {
      props.setCommodityTableOtherCharges();
    }
  }, [customer.customerHandlingOutCharge]);

  function getCustomer() {
    if (!props.checkOutDetails?.checkInCustomerId == "") {
      var id = props.checkOutDetails.checkInCustomerId;
      const Url = BaseUrl.apiUrl.baseUrl + "api/v1/customers/" + id;
      let resp = apiGet(Url);
      resp.then((resp) => {
        setCustomer(resp.response.data.data);
      });
    }
  }
  useEffect(() => {
    if (!props.checkOutDetails?.checkInCustomerId == "") {
      getCustomer();
    }
  }, [props.checkOutDetails?.checkInCustomerId]);
  useEffect(() => {
    if (checkOutMandatory) {
      props.checkOutTableData.map((items) => {
        if (
          items["itemIsSelected"] &&
          items["itemUnitsCheckedOut"] !== 0 &&
          items["itemAreaSqm"] !== 0 &&
          items["itemArea"] !== 0
        ) {
          setcheckOutMandatory(false);
        } else {
          setcheckOutMandatory(true);
        }
      });
    }
  }, [props.checkOutTableData]);
  function getCheckOutDataById() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/cargo_checkout/${id}`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      var datas = resp.response.data.data;
      console.log("checkout item by id edit", datas);
      props.setCheckoutDataById(datas);
      setCheckOutDate(datas.checkOutDate);
      setcheckOutTime(datas.checkOutTime);
    });
  }
  useEffect(() => {
    if (type === "edit") {
      getCheckOutDataById();
    }
  }, []);
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <h6>
                {type === "edit"
                  ? "EDIT CARGO CHECK-OUT BY ITEM"
                  : "CARGO CHECK-OUT BY ITEM"}
              </h6>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={12} md={12} sm={12} className="">
                      <AvForm
                        className="form"
                        // onValidSubmit={(e) => handleSubmit(e)}
                        // model={data}
                      >
                        <Row className="mb-4">
                          <Col md={6}>
                            <label>Search for Check In Number</label>
                            <AvField
                              name="search"
                              type="text"
                              onChange={(e) => setjobinvnumber(e.target.value)}
                              disabled={type === "edit"}
                            ></AvField>
                          </Col>
                          <Col md={12}>
                            <button
                              class="btn btn-primary"
                              onClick={searchCheckOut}
                              disabled={type === "edit"}
                            >
                              Search
                            </button>
                          </Col>
                        </Row>
                        <hr />
                        {type === "edit" ? (
                          <Row>
                            {/* <Col md={12} className="mt-2">
                              <strong>
                                Date : {props.checkOutDetails?.checkOutDate}
                              </strong>
                            </Col> */}
                            <Col md={12} className="mt-2 mb-2">
                              <strong>
                                Warehouse Name :{" "}
                                {props.checkOutDetails?.checkOutWarehouseName}
                              </strong>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        {props.checkOutDetails.checkInCustomerName ||
                        props.checkOutDetails.checkOutCustomerName ? (
                          <Row>
                            <Col md={12}>
                              <strong>
                                Customer Name :{" "}
                                {props.checkOutDetails.checkInCustomerName ||
                                  props.checkOutDetails.checkOutCustomerName}
                              </strong>
                              <hr />
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        <Row>
                          <Col md={2}>
                            <label>Checkout Date</label>
                            <AvField
                              name="date"
                              type="date"
                              // onChange={(e) => setjobinvnumber(e.target.value)}
                              onChange={(e) => setCheckOutDate(e.target.value)}
                              value={checkoutDate}
                              min={props.checkOutDetails?.checkInDate}
                            ></AvField>
                          </Col>
                          <Col md={2}>
                            <label>CheckOut Time</label>
                            <AvField
                              name="checkOutTime"
                              type="time"
                              onChange={(e) => setcheckOutTime(e.target.value)}
                              value={checkOutTime}
                              required
                            ></AvField>
                          </Col>
                        </Row>
                        <hr />
                        <div>
                          <Row>
                            <Col md={12}>
                              <strong>Cargo details</strong>
                              {checkbox ? (
                                <span
                                  style={{ marginLeft: "1em", color: "red" }}
                                >
                                  Select an item for checkout
                                </span>
                              ) : (
                                ""
                              )}
                              {checkOutMandatory ? (
                                <span
                                  style={{ marginLeft: "1em", color: "red" }}
                                >
                                  Please fill all the required details of
                                  selected item
                                </span>
                              ) : (
                                ""
                              )}
                            </Col>
                            <Col md={12}>
                              <DataTable
                                columns={columns}
                                // data={props.checkOutTableData}
                                data={preparedData}
                                defaultSortFieldID={1}
                                pagination
                                // selectableRows
                                // selectableRowsComponent={BootyCheckbox}
                                // onSelectedRowsChange={handleChange}
                                // selectableRowsHighlight
                                responsive
                              />
                            </Col>
                          </Row>

                          {/* {props.checkOutTableData.length > 0 ? (
                            <Row className="mb-4">
                              <Col md={12}>
                                <Row>
                                  <Col md={6}>
                                    <h6>Other Charges (QAR)</h6>
                                  </Col>
                                  <Col md={6} className="text-right">
                                    <button
                                      type="button"
                                      onClick={addOtherChargeTableRows}
                                      className="btn btn-default"
                                    >
                                      <i
                                        class="bx bxs-plus-circle"
                                        style={{ color: "#052f85" }}
                                      ></i>
                                    </button>
                                  </Col>
                                </Row>

                                <table className="table table-bordered invoice-table ">
                                  <thead>
                                    <tr>
                                      <th className="text-left">
                                        {" "}
                                        Charge Name
                                      </th>
                                      <th className="text-left">
                                        Charge Amount
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                               
                                    {props.otherChargeTableData?.map(
                                      (data, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <input
                                                type="text"
                                                name="chargeName"
                                                className="form-control"
                                                placeholder="Name"
                                              
                                                onChange={(e) => {
                                                  props.updateCommodityOtherCharges(
                                                    index,
                                                    e
                                                  );
                                                  setotherChargeName(
                                                    e.target.value
                                                  );
                                                }}
                                                value={
                                                  props.otherChargeTableData[
                                                    index
                                                  ]
                                                    ? props
                                                        .otherChargeTableData[
                                                        index
                                                      ]["chargeName"]
                                                    : otherChargeName
                                                }
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number"
                                                name="chargeAmount"
                                                className="form-control"
                                                placeholder="Amount"
                                                onChange={(e) =>
                                                  props.updateCommodityOtherCharges(
                                                    index,
                                                    e
                                                  )
                                                }
                                                value={
                                                  props.otherChargeTableData[
                                                    index
                                                  ]
                                                    ? props
                                                        .otherChargeTableData[
                                                        index
                                                      ]["chargeAmount"]
                                                    : ""
                                                }
                                              />
                                            </td>
                                            <td
                                              className="text-center"
                                              width={10}
                                            >
                                              <span
                                                className=" btn-outline-danger"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                                onClick={(e) =>
                                                  deleteOtherChargeTableRow(
                                                    index,
                                                    e
                                                  )
                                                }
                                              >
                                                <i class="bx bx-trash"></i>
                                              </span>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )} */}
                          {props.checkOutTableData.length > 0 ? (
                            <Row>
                              <Col md={12}>
                                <button
                                  class="btn btn-primary"
                                  type="button"
                                  onClick={handleCheckOut}
                                  disabled={reload || checkOutStatus}
                                  // disabled={props.itemCheckoutCheck}
                                >
                                  {type === "edit"
                                    ? "Update Checkout"
                                    : "Confirm Checkout"}
                                </button>
                                {checkOutStatus ? (
                                  <div
                                    className="mt-2"
                                    style={{ color: "red" }}
                                  >
                                    There is an open checkout available for this
                                    CheckIn number. Please close that and try
                                    again
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                        </div>
                      </AvForm>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const {
    checkOutTableData,
    checkOutCheckInId,
    checkOutDetails,
    otherChargeTableData,
    itemCheckoutCheck,
  } = state.CommodityTableData;
  return {
    checkOutTableData,
    checkOutCheckInId,
    checkOutDetails,
    otherChargeTableData,
    itemCheckoutCheck,
  };
};
export default connect(mapStatetoProps, {
  requestCheckoutDataByNumber,
  updateCheckOutData,
  clearCommodityData,
  addCommodityTableOtherCharges,
  updateCommodityOtherCharges,
  deleteOtherChargeTableRow,
  addHandlingCharge,
  setCommodityTableOtherCharges,
  setCheckoutDataById,
})(CheckOutByItem);
