import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
//i18n
import Select from "react-select";
import { apiGet } from "../../components/config/apiConfig";
import { BaseUrl } from "../../components/config/BaseUrl";
// Pages Components

const SalesInfo = (itemdata, formType, vendors) => {
  const [activeTab, setActiveTab] = useState("1");
  const [checkSelling, setCheckSelling] = useState(true);
  const [checkPurchase, setCheckPurchase] = useState(true);
  const [purchaseAccounts, setpurchaseAccounts] = useState([]);
  const [salesAccounts, setSalesAccounts] = useState([]);
  const [salesData, setSalesData] = useState({
    itemSalesSellingPrice: 0,
    itemSalesAccount: "",
    itemsSalesDescription: "",
  });
  const [purchaseData, setPurchaseData] = useState({
    itemPurchaseCostPrice: 0,
    itemPurchaseAccount: "",
    itemsPurchaseDescription: "",
    itemPreferredVendorId: 0,
  });

  function handle(e) {
    const newData = { ...salesData };
    newData[e.target.name] = e.target.value;
    setSalesData(newData);
  }
  function purchaseDataHandle(e) {
    const newBillingData = { ...purchaseData };
    newBillingData[e.target.name] = e.target.value;
    setPurchaseData(newBillingData);
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  // useEffect(() => {
  //   console.log("item wwww purchaseData", purchaseData);
  //   console.log("item wwww salesData", salesData);
  // }, [salesData, purchaseData]);

  useEffect(() => {
    if (formType == "edit" || formType == "clone") {
      setSalesData(itemdata);
      setPurchaseData(itemdata);
    }
  }, [itemdata]);

  function getPurchaseAccountsData() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/accounts/coa/purchase`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      console.log(
        "purchaseAccounts list",
        resp.response.data.data[0].childrens
      );
      setpurchaseAccounts(resp.response.data.data[0].childrens);
    });
  }
  function getSalesAccountsData() {
    const Url = BaseUrl.apiUrl.baseUrl + `api/v1/accounts/coa/sales`;
    let resp = apiGet(Url);
    resp.then((resp) => {
      console.log("purchaseAccounts list", resp);
      setSalesAccounts(resp.response.data.data[0].childrens);
    });
  }
  useEffect(() => {
    getPurchaseAccountsData();
    getSalesAccountsData();
  }, []);

  const salesAccountsOption = [
    { value: "1", label: "Discount" },
    { value: "2", label: "General Income" },
    { value: "3", label: "Interest Income" },
    { value: "4", label: "Late Fee Income" },
    { value: "5", label: "Other Charges" },
    { value: "6", label: "Sales" },
    { value: "7", label: "Shipping Charges" },
  ];
  const purchaseAccountOptions = [
    { value: "1", label: "Cost of Goods Sold" },
    { value: "2", label: "Job Costing" },
    { value: "3", label: "Interest Income" },
    { value: "4", label: "Late Fee Income" },
  ];
  const vendorOptions = [
    ...vendors.map((vendor) => ({
      value: vendor.vendorId,
      label: vendor.vendorDisplayName,
    })),
  ];
  const purchaseOption = purchaseAccounts.map((ca) => ({
    value: ca.headId,
    label: ca.headName,
  }));
  const salesOption = salesAccounts.map((ca) => ({
    value: ca.headId,
    label: ca.headName,
  }));
  return {
    salesData,
    purchaseData,
    checkPurchase,
    checkSelling,
    salesInformation: (
      <React.Fragment>
        {/* <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggle("1");
              }}
            >
              <div className="d-flex">
                <AvField
                  type="checkbox"
                  name="checkSelling"
                  value={checkSelling}
                  // onChange={(e) => handle(e)}
                  onChange={(e) => setCheckSelling(!checkSelling)}
                />
                Sales Information
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                toggle("2");
              }}
            >
              <div className="d-flex">
                <AvField
                  type="checkbox"
                  name="checkPurchase"
                  value={checkPurchase}
                  // onChange={(e) => handle(e)}
                  onChange={(e) => setCheckPurchase(!checkPurchase)}
                />
                Purchase Information
              </div>
            </NavLink>
          </NavItem>
        </Nav> */}
        {/* <TabContent activeTab={activeTab}> */}
        {/* <TabPane tabId="1"> */}
        <Row>
          <Col md={6}>
            <Row>
              <div className="d-flex" style={{ padding: "1em 1em 1em 0em" }}>
                <AvField
                  type="checkbox"
                  name="checkSelling"
                  value={checkSelling}
                  // onChange={(e) => handle(e)}
                  onChange={(e) => setCheckSelling(!checkSelling)}
                />
                <h5> Sales Information</h5>
              </div>
            </Row>
            <Row>
              <Col md={4}>
                <label>Selling Price</label>
              </Col>
              <Col md={6}>
                <AvField
                  name="itemSalesSellingPrice"
                  type="number"
                  placeholder="Enter selling price"
                  onChange={(e) =>
                    setSalesData({
                      ...salesData,
                      itemSalesSellingPrice: e.target.value,
                    })
                  }
                  value={salesData.itemSalesSellingPrice}
                  disabled={checkSelling !== true}
                  required={checkSelling == true}
                ></AvField>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label>Account</label>
              </Col>
              <Col md={6} style={{ marginBottom: "0.5rem" }}>
                <Select
                  name="itemSalesAccount"
                  // value={salesData.itemSalesAccount}
                  value={
                    salesOption
                      ? salesOption.find(
                          (option) =>
                            option.value === salesData.itemSalesAccount
                        )
                      : ""
                  }
                  placeholder="Select selling account"
                  onChange={(selectedOption) =>
                    setSalesData({
                      ...salesData,
                      itemSalesAccount: selectedOption.value,
                    })
                  }
                  isDisabled={checkSelling !== true}
                  required={checkSelling === true}
                  options={salesOption}
                />
                {/* <AvField
                  name="itemSalesAccount"
                  type="select"
                  onChange={(e) =>
                    setSalesData({
                      ...salesData,
                      itemSalesAccount: e.target.value,
                    })
                  }
                  value={salesData.itemSalesAccount}
                  disabled={checkSelling !== true}
                  required={checkSelling == true}
                >
                  <option value="" selected disabled>
                    Select selling account
                  </option>
                  <option value="1">Discount</option>
                  <option value="2">General Income</option>
                  <option value="3">Interest Income</option>
                  <option value="4">Late Fee Income</option>
                  <option value="5">Other Charges</option>
                  <option value="6">Sales</option>
                  <option value="7">Shipping Charges</option>
                </AvField> */}
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label>Description</label>{" "}
              </Col>
              <Col md={6}>
                <AvField
                  name="itemsSalesDescription"
                  type="textarea"
                  onChange={(e) =>
                    setSalesData({
                      ...salesData,
                      itemsSalesDescription: e.target.value,
                    })
                  }
                  disabled={checkSelling !== true}
                  value={salesData.itemsSalesDescription}
                ></AvField>
              </Col>
            </Row>
          </Col>
          {/* </Row> */}
          {/* </TabPane> */}
          {/* <TabPane tabId="2"> */}
          {/* <Row> */}
          <Col md={6}>
            <Row>
              <div className="d-flex" style={{ padding: "1em 1em 1em 0em" }}>
                <AvField
                  type="checkbox"
                  name="checkPurchase"
                  value={checkPurchase}
                  // onChange={(e) => handle(e)}
                  onChange={(e) => setCheckPurchase(!checkPurchase)}
                />
                <h5>Purchase Information</h5>
              </div>
            </Row>
            <Row>
              <Col md={4}>
                {" "}
                <label>Cost Price</label>
              </Col>
              <Col md={6}>
                <AvField
                  name="itemPurchaseCostPrice"
                  type="number"
                  placeholder="Enter cost price"
                  onChange={(e) =>
                    setPurchaseData({
                      ...purchaseData,
                      itemPurchaseCostPrice: e.target.value,
                    })
                  }
                  value={purchaseData.itemPurchaseCostPrice}
                  disabled={checkPurchase !== true}
                  required={checkPurchase == true}
                ></AvField>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label>Account</label>
              </Col>
              <Col md={6} style={{ marginBottom: "0.5rem" }}>
                <Select
                  name="itemPurchaseAccount"
                  placeholder="Select purchase account"
                  // value={purchaseData.itemPurchaseAccount}
                  value={
                    purchaseOption
                      ? purchaseOption.find(
                          (option) =>
                            option.value === purchaseData.itemPurchaseAccount
                        )
                      : ""
                  }
                  onChange={(selectedOption) =>
                    setPurchaseData({
                      ...purchaseData,
                      itemPurchaseAccount: selectedOption.value,
                    })
                  }
                  isDisabled={checkPurchase !== true}
                  options={purchaseOption}
                />
                {/* <AvField
                  name="itemPurchaseAccount"
                  type="select"
                  onChange={(e) =>
                    setPurchaseData({
                      ...purchaseData,
                      itemPurchaseAccount: e.target.value,
                    })
                  }
                  value={purchaseData.itemPurchaseAccount}
                  disabled={checkPurchase !== true}
                  // required={checkPurchase == true}
                >
                  <option value="" selected disabled>
                    Select purchase account
                  </option>

                  <option value="1">Cost of Goods sold</option>
                  <option value="2">Job costing</option>
                  <option value="3">Interest Income</option>
                  <option value="4">Late Fee Income</option>
                </AvField> */}
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label>Description</label>
              </Col>
              <Col md={6}>
                <AvField
                  name="itemsPurchaseDescription"
                  type="textarea"
                  onChange={(e) =>
                    setPurchaseData({
                      ...purchaseData,
                      itemsPurchaseDescription: e.target.value,
                    })
                  }
                  value={purchaseData.itemsPurchaseDescription}
                  disabled={checkPurchase !== true}
                ></AvField>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label>Preferred Vendor</label>{" "}
              </Col>
              <Col md={6} style={{ marginBottom: "0.5rem" }}>
                <Select
                  name="itemPreferredVendorId"
                  placeholder="Select a vendor"
                  // value={purchaseData.itemPreferredVendorId}
                  value={
                    vendorOptions
                      ? vendorOptions.find(
                          (option) =>
                            option.value === purchaseData.itemPreferredVendorId
                        )
                      : ""
                  }
                  onChange={(selectedOption) =>
                    setPurchaseData({
                      ...purchaseData,
                      itemPreferredVendorId: selectedOption.value,
                    })
                  }
                  options={vendorOptions}
                />
                {/* <AvField
                  name="itemPreferredVendorId"
                  type="select"
                  onChange={(e) =>
                    setPurchaseData({
                      ...purchaseData,
                      itemPreferredVendorId: e.target.value,
                    })
                  }
                  value={purchaseData.itemPreferredVendorId}
                >
                  <option value="" selected disabled>
                    Select a vendor
                  </option>
                  {vendors.map((vendor) => (
                    <option value={vendor.vendorId}>
                      {vendor.vendorDisplayName}
                    </option>
                  ))}
                </AvField> */}
              </Col>
            </Row>
          </Col>
        </Row>
        {/* </TabPane> */}
        {/* </TabContent> */}
      </React.Fragment>
    ),
  };
};
export default SalesInfo;
